const Circle = ({ map: { map, maps }, circle }) => {
  const circ = new maps.Circle({
    center: { lng: circle.center[0], lat: circle.center[1] },
    radius: circle.notifRadiusM,
    strokeColor: circle.strokeColor || (circle.active ? "#ffd52b" : "#95e645"),
    strokeOpacity: circle.strokeOpacity || 1,
    strokeWeight: circle.strokeWeight || 1,
    fillColor: circle.fillColor || (circle.active ? "#ffd52b" : "#95e645"),
    fillOpacity: circle.fillOpacity || 0.3,
  })
  circ.setMap(map)

  return circ
}

export default Circle
