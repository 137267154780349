import { forwardRef, memo, useEffect, useId } from "react"
import { Link } from "react-router-dom"

import { eventHub, useGlobalState } from "@guardian/State"
import type { Clip as ClipType } from "@guardian/Types/Clip"

import {
  isClipFlagged,
  isMedicalIncidentClip,
  isUpdateClip,
  selectFlaggingAlgorithm
} from "@guardian/Utils/clips"
import combokeys from "@guardian/Utils/hotkeys"
import { objPick } from "@guardian/Utils/obj-pick"

import Clip from "@guardian/Components/Clip/Clip"
import { Checkbox } from "@guardian/UI/Legacy/Checkbox"

import {
  AuditFieldItem,
  ClipColumn,
  Container,
  FixedItem,
  Item,
} from "./styles"

type RowField = {
  key: string
  value: string
}

type AuditField = {
  key: string
  value: boolean
}

type ContentRowProps = {
  clip: ClipType
  incidentId: string
  rowFields: RowField[]
  auditFields: AuditField[]
  handleAuditFieldChange: (
    clipId: string,
    incidentId: string,
    key: string,
    value: boolean,
  ) => Promise<void>
  isolatedChannel: string | null
}

const updateActiveClip = (payload: any) => {
  eventHub.emit("setActiveClip", payload)
}

export const ContentRow = memo(
  forwardRef<HTMLDivElement, ContentRowProps>((props, ref) => {
    const {
      clip,
      incidentId,
      rowFields,
      auditFields,
      handleAuditFieldChange,
      isolatedChannel,
    } = props

    const clipElementId = useId()

    const {
      permissions,
      user,
      metapod,
      clipPriorityConfig,
      flaggingAlgorithm,
      channel,
      speed,
      activeClip,
      activeClipOverride,
    } = useGlobalState(state => {
      const selectedGlobal = objPick(state.global, [
        "permissions",
        "user",
        "metapod",
        "clipPriorityConfig",
      ])
      const selectedRadio = objPick(state.radio, [
        "speed",
        "activeClip",
        "activeClipOverride",
      ])

      const channel = clip ? state.radio.channels[clip.channel] : null
      return {
        ...selectedGlobal,
        ...selectedRadio,
        channel,
        flaggingAlgorithm: selectFlaggingAlgorithm(channel, state),
      }
    })

    // Register keyboard shortcuts for toggling audit fields (keys 5-9)
    useEffect(() => {
      if (!clip?.id || clip.id !== activeClip?.id) {
        return
      }
      const shortcuts = auditFields.map((auditField, index) => {
        if (index > 4) {
          // Only support 5 shortcuts (keys 5-9)
          return null
        }
        const key = `${index + 5}`
        combokeys.bind(key, () => {
          handleAuditFieldChange(
            clip.id,
            incidentId,
            auditField.key,
            !auditField.value,
          )
        })
        return key
      })
      return () => {
        shortcuts.forEach(shortcut => {
          if (!shortcut) {
            return
          }
          combokeys.unbind(shortcut)
        })
      }
    }, [clip?.id, activeClip?.id, auditFields])

    if (!clip) {
      return null
    }

    return (
      <Container ref={ref}>
        <ClipColumn>
          <Clip
            key={clip.id}
            elementId={clipElementId}
            id={clip.id}
            durationMs={clip.duration_ms}
            played={!!clip.played}
            time={clip.time}
            spectrogram={clip.spectrogram}
            wavUrl={clip.wav_url}
            pin={false}
            clipListenStatus={clip.clipListenStatus || ""}
            metadata={clip.metadata}
            channelHeader={channel?.name || clip.channel}
            activeClipId={activeClipOverride ? "0" : activeClip.id}
            activeClipLoop={activeClip.id === clip.id ? activeClip.loop : false}
            activeClipStatus={
              activeClip.id === clip.id ? activeClip.status : null
            }
            activeClipAnimateStatus={
              activeClip.id === clip.id ? activeClip.animateStatus : null
            }
            updateActiveClip={updateActiveClip}
            attached={false}
            isolateOn={!!isolatedChannel}
            serviceArea={channel?.serviceArea || ""}
            subArea={channel?.subArea || ""}
            speed={activeClip.id === clip.id ? speed : 1}
            dragging=''
            permissions={permissions}
            user={user}
            metapod={metapod}
            isFlagged={isClipFlagged(clip, flaggingAlgorithm, clipPriorityConfig)}
            isUpdateClip={isUpdateClip(clip)}
            isMedicalIncident={isMedicalIncidentClip(clip, clipPriorityConfig)}
            hideAttachmentControls
          />
        </ClipColumn>
        {rowFields.map(rowField => (
          <Item key={rowField.key} padded>
            {rowField.value}
          </Item>
        ))}
        {auditFields.map(auditField => (
          <AuditFieldItem key={auditField.key} padded center>
            <Checkbox
              active={!!auditField.value}
              handleClick={() => {
                handleAuditFieldChange(
                  clip.id,
                  incidentId,
                  auditField.key,
                  !auditField.value,
                )
              }}
              blue
              bold
              highContrast
            />
          </AuditFieldItem>
        ))}
        <FixedItem padded center>
          <Link
            to={`/incidents/${incidentId}`}
            target='_blank'
            title='Go to incident'
          >
            {incidentId}
          </Link>
        </FixedItem>
      </Container>
    )
  }),
)
