import { OptimusAPI } from "@guardian/API/Optimus";

interface VideoCurationCurateVideoData {
  videoStreamId: string
  incidentId: string
  curatorId: string
  trimStart: number
  trimDuration: number
};

interface VideoCurationUpdateBoostedCuratedVideoData {
  boosted: boolean
};

interface VideoCurationUpdatePinnedCuratedVideoData {
  pinned?: boolean
  pinLocation?: string
};

const VideoCuration = {

  getCurationQueue: (options: any = {}) => {
    return OptimusAPI.get(`/v1/video_curation/curation_queue`, options);
  },

  getCuratedVideos: (options: any = {}) => {
    return OptimusAPI.get(`/v1/video_curation/get_curated_videos`, options);
  },

  curateVideo: (data: VideoCurationCurateVideoData, options: any = {}) => {
    return OptimusAPI.post(`/v1/video_curation/curate_video`, data, options);
  },

  unpublishCuratedVideo: (streamId: string, options: any = {}) => {
    return OptimusAPI.delete(`
      /v1/video_curation/${ streamId }/unpublish_video`,
      options
    );
  },

  addVideoToCurationQueue: (streamId: string, options: any = {}) => {
    return OptimusAPI.patch(
      `/v1/video_curation/${ streamId }/update_curated_video`,
      { needsCuration: true },
      options
    );
  },

  removeVideoFromCurationQueue: (streamId: string, options: any = {}) => {
    return OptimusAPI.patch(
      `/v1/video_curation/${ streamId }/update_curated_video`,
      { needsCuration: false },
      options
    );
  },

  updateBoostedCuratedVideo: (
    streamId: string,
    data: VideoCurationUpdateBoostedCuratedVideoData,
    options: any = {}
  ) => {
    return OptimusAPI.patch(
      `/v1/video_curation/${ streamId }/update_curated_video`,
      data,
      options
    );
  },

  updatePinnedCuratedVideo: (
    streamId: string,
    data: VideoCurationUpdatePinnedCuratedVideoData,
    options: any = {}
  ) => {
    return OptimusAPI.patch(
      `/v1/video_curation/${ streamId }/update_curated_video`,
      data,
      options
    );
  }
};

export default VideoCuration;
