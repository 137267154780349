import styled from "styled-components"

export const FloatingControlsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  z-index: 100;
`

export const AuditPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  --clip-column-width: 433px;
`

export const AuditPanelItemStart = styled.div`
  align-self: flex-start;
  min-width: var(--clip-column-width);
  z-index: 3;
`

export const AuditPanelGrid = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
