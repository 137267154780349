import { func, object } from "prop-types"
import { useMemo } from "react"

import consts from "@guardian/Constants"
import { useGlobalState } from "@guardian/State"

import { track } from "@guardian/Analytics/segment"
import { VideoStreams } from "@guardian/API/Optimus"
import { ContentService } from "@guardian/Services/Content"
import { incidentStatus } from "@guardian/Utils/incident"
import { objPick } from "@guardian/Utils/obj-pick"

import style from "./ActionPane.module.css"

const P2P_MOD_BUTTONS = {
  VERIFY: 'verify',
  APPROVE: 'approve',
  NONE: null,
}

const getButtonText = (state, incident, stream) => {
  if (incident) {
    if (stream && stream.videoStream.verifiedLive) {
      return "Send Notification"
    }
    return "Verify"
  }
  if (stream && stream.videoStream.onAir) {
    if (state.notifTurnedOn) {
      return "Merge and Notify"
    } else {
      return "Merge"
    }
  }
  if (state.mergingIncident && state.mergingIncident.id) {
    return "Merge and Verify"
  } else {
    return "Create"
  }
}

const VerifyButton = ({
  state,
  incident,
  clickHandler,
  stream,
  streamHandled,
}) => {
  const { readOnly, tags } = useGlobalState(state =>
    objPick(state.global, ["readOnly", "tags"]),
  )

  const buttonText = useMemo(() => getButtonText(state, incident, stream), [state, incident, stream])

  const handleApproveStream = () => {
    const tag = tags.find(t => t.display_name === state.incidentCategory)

    return ContentService.iglIncidentApproveContent('video_stream', stream.videoStream.id, {
      title: state.incidentTitle,
      incidentUpdate: state.incidentUpdate,
      location: state.location,
      safetyLevel: state.safetyLevel,
      tags: tag && tag.id ? [tag] : [],
    })
  }

  const handleVerifyStream = () => {
    const titleEdits = state.editedNotifTitle ? state.notifTitle : ""
    const messageEdits = state.editedNotifMessage
      ? state.notifMessage
      : ""
    const radiusOverride = state.radiusOverride
      ? parseFloat(state.radiusOverride)
      : 0

    VideoStreams.verifyStream(
      stream.videoStream.id,
      {
        confirmed: true,
        editorsChoice: state.distributionType === "editorsChoice",
        messageEdits: messageEdits,
        titleEdits: titleEdits,
        radiusOverride: radiusOverride
      }
    )
    track("Live Video Verify", {
      videoStreamId: stream.videoStream.id,
      location: "Live Tab",
      type: state.notificationCategory, // VL, EC, Custom
    })
    streamHandled("Verified")
  }

  let moderationType = P2P_MOD_BUTTONS.NONE
  if (incident) {
    const { updateStatus: status } = incidentStatus(incident)
    const author = status?.author
    if (author === consts.AutomatedUsername) {
      moderationType = incident?.unverifiedIGLIncident ? P2P_MOD_BUTTONS.APPROVE : P2P_MOD_BUTTONS.VERIFY
    } else if (author && !incident?.unverifiedIGLIncident) {
      moderationType = P2P_MOD_BUTTONS.VERIFY
    }
  }

  let button = null
  let extraButton = null
  if (moderationType === P2P_MOD_BUTTONS.APPROVE) {
    button = (
      <button
        onClick={handleApproveStream}
        className={style.transparentButton}
        disabled={!state.incidentUpdate || readOnly}
      >
        Approve
      </button>
    )
  } else if (streamHandled) {
    button = (
      <button
        onClick={handleVerifyStream}
        className={style.button}
        disabled={!stream?.videoStream?.incidentId || readOnly}
      >
        Verify
      </button>
    )

    if (moderationType === P2P_MOD_BUTTONS.NONE && buttonText !== "Verify") {
      extraButton = (
        <button
          onClick={clickHandler}
          className={style.button}
          disabled={!state.canSubmit || readOnly}
        >
          {buttonText}
        </button>
      )
    }
  }

  return (
    <div style={{ display: "flex", gap: 15 }}>
      {extraButton}
      {button}
    </div>
  )
}

VerifyButton.propTypes = {
  state: object,
  incident: object,
  clickHandler: func,
  stream: object,
  streamHandled: func,
}

export default VerifyButton
