import React from "react";

import { Hint as FormHint } from "@guardian/UI/Form";

const Hint = React.forwardRef((props, ref) => {
  return (
    <FormHint ref={ref} {...props}/>
  );
});

export default Hint;
