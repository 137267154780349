import { FC, useEffect } from "react"
import { IProps } from "@guardian/UI/Map/components/Route/types"

export const Route: FC<IProps> = ({ mapsApi, routeData }) => {
  useEffect(() => {
    if (mapsApi && routeData) {
      mapsApi.map.data.setStyle({
        strokeColor: "#4da6ff",
        strokeWeight: 4,
      })
      mapsApi.map.data.add({
        geometry: new mapsApi.maps.Data.LineString(routeData),
      })
    }
  }, [mapsApi, routeData])

  return null
}
