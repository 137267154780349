import { OptimusAPI } from "@guardian/API/Optimus";

const Agents = {
  getAuthenticatedAgentFeatures: (options: any = {}) => {
    return OptimusAPI.get(
      `/v1/internal_website_settings`,
      {
        ...options,
        headers: {
          ...options.headers,
          wapp: "pos"
        }
      }
    );
  },

  getAuthenticatedAgentPermissions: (options?: any) => {
    return OptimusAPI.get(`/radio/permissions`, options);
  },

  getAuthenticatedAgentStats: (sinceTs: number, options: any = {}) => {
    return OptimusAPI.get(
      `/gnet/user_stats`,
      {
        ...options,
        params: {
          ...options.params,
          sinceTs
        }
      }
    );
  }
};

export default Agents;
