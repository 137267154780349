import { Radio } from "@guardian/API/Megatron";

interface RadioServiceSetConfigurationData {
  playbackRate?: number
  cutoff?: number
  active?: boolean
  channels: string[]
  doneTutorial?: boolean
  referrerId: string
  mapCenterLat?: number
  mapCenterLng?: number
  flaggedOnly?: boolean
};

const RadioService = {
  getRadioConfiguration: (options?: any) => {
    return new Promise((resolve, reject) => {
      Radio.getRadioConfiguration(options)
        .then(({ data: config }) => {
          resolve(config);
        })
        .catch(reject);
    });
  },

  setRadioConfiguration: (
    data: RadioServiceSetConfigurationData,
    options?: any
  ) => {
    return new Promise((resolve, reject) => {
      Radio.setRadioConfiguration(data, options)
        .then(({ data: config }) => {
          resolve(config);
        })
        .catch(reject);
    });
  },

  getRadioChannelFlagStats: (options?: any) => {
    return new Promise((resolve, reject) => {
      Radio.getRadioChannelFlagStats(options)
        .then(({ data: { channelFlagCount } }) => {
          resolve(channelFlagCount);
        })
        .catch(reject);
    });
  },

  getRadioChannels: (options?: any) => {
    return new Promise((resolve, reject) => {
      Radio.getRadioChannels(options)
        .then(({ data: { channels: responseChannels } }) => {
          const channels = responseChannels || [];

          resolve(channels);
        })
        .catch(reject);
    });
  },

  getRadioSubAreas: (options?: any) => {
    return new Promise((resolve, reject) => {
      Radio.getRadioSubAreas(options)
        .then(({ data: { SubAreas: responseSubAreas } }) => {
          const subAreas = responseSubAreas || [];

          resolve(subAreas);
        })
        .catch(reject);
    });
  }
};

export default RadioService;
