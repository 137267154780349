import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

import { getTooltipPosition } from "../utils/placements";
import {
  positionTransform,
  positionTransformDefault
} from "../utils/tooltipStyles";

const TRANSITION_NAME = `tooltip`;

const StyledTooltip = styled.div.attrs(
  props => {
    const {
      className,
      size
    } = props;

    return {
      className: classNames(className, "tooltip")
    };
  }
)`
  ${({ position, transitionMs }) => css`
    background-color: var(--colors-grey-080);
    border-radius: var(--radii-small);
    box-shadow:
      var(--borders-outer-full) var(--colors-grey-070);
    color: var(--colors-white);
    font-size: var(--font-sizes-small);
    line-height: var(--line-heights-small);
    opacity: 0.0;
    padding: var(--space-xx-small) var(--space-x-small);
    position: absolute;
    transition:
      opacity ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1),
      transform ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-property: opacity, transform;
    width: max-content;

    ${ positionTransform(position) }

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter,
    &.${ TRANSITION_NAME }-exit.${ TRANSITION_NAME }-exit-active,
    &.${ TRANSITION_NAME }-exit-done {
      opacity: 0;
      ${ positionTransform(position) }
    };

    &.${ TRANSITION_NAME }-appear.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter.${ TRANSITION_NAME }-enter-active,
    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done,
    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
      ${ positionTransformDefault(position) }
    };
  `}
`;

StyledTooltip.propTypes = {};

StyledTooltip.defaultProps = {};

const Tooltip = React.forwardRef((props, ref) => {
  const {
    children,
    entered,
    placement,
    transitionMs,
    ...passdownProps
  } = props;

  const position = getTooltipPosition(placement);

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive: `${ TRANSITION_NAME }-appear-active`,
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={entered}
      timeout={{
        appear: transitionMs, enter: transitionMs, exit: transitionMs
      }}
      unmountOnExit
    >
      <StyledTooltip
        ref={ref}
        position={position}
        transitionMs={transitionMs}
        { ...passdownProps }
      >
        { children }
      </StyledTooltip>
    </CSSTransition>
  );
});

Tooltip.propTypes = {
  entered: PropTypes.bool.isRequired,
  placement: PropTypes.string.isRequired,
  transitionMs: PropTypes.number.isRequired
};

Tooltip.defaultProps = {};

export default Tooltip;
