import classNames from "classnames";
import styled from "styled-components";

const VirtualScrollListItem = styled.div.attrs(
  props => {
    const {
      className,
      reverse
    } = props;

    return {
      className: classNames(
        className,
        "virtual-scroll-list-item", {
          "virtual-scroll-list-item--reverse": reverse
        }
      )
    }
  }
)`
  left: 0;
  position: absolute;
  width: 100%;

  ${ ({ translateY }) => `
    transform: translateY(${ translateY }px);
  `}

  &:not(.virtual-scroll-list-item--reverse) {
    top: 0;
  }

  .virtual-scroll-list-item--reverse {
    bottom: 0;
  }
`;

export default VirtualScrollListItem;
