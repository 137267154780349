import classNames from "classnames";
import styled from "styled-components";

const SectionCol = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__session__section-col"
    )
  };
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-x-small);
  width: 50%;
`;

SectionCol.defaultProps = {};

export default SectionCol;
