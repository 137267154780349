import styled from "styled-components";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const UserDropdown = styled.button`
  ${ buttonResets }

  align-items: center;
  color: white;
  display: inline-flex;
  font-size: var(--font-sizes-small);
  font-weight: var(--font-weights-bold);
  height: 100%;
  justify-content: center;
  margin-left: var(--space-xx-large);
  padding: 0 var(--space-x-large);
  position: relative;
  text-align: center;
  user-select: none;
`;

export default UserDropdown;
