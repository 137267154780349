import React, { useId } from "react";
import PropTypes from "prop-types";

import { FieldContext } from "../hooks/useFieldContext";
import StyledField from "../views/Field";

const Field = React.forwardRef((props, ref) => {
  const prefix = useId();

  const inputId = `field--${ prefix }--input`;
  const labelId = `field--${ prefix }--label`;
  const hintId = `field--${ prefix }--hint`;

  const getLabelProps = (
    {
      id = labelId,
      htmlFor = inputId,
      ...other
    } = {}
  ) => {
    return {
      id,
      htmlFor,
      ...other
    };
  };

  const getInputProps = (
    { id = inputId, ...other } = {},
    { isDescribed = false } = {}
  ) => {
    return {
      id,
      "aria-labelledby": labelId,
      "aria-describedby": isDescribed ? hintId : null,
      ...other
    };
  };

  const getHintProps = ({ id = hintId, ...other } = {}) => {
    return {
      id,
      ...other
    };
  };

  const getMessageProps = (messageProps = {}) => {
    return {
      role: "alert",
      ...messageProps
    };
  };

  const fieldProps = {
    getLabelProps,
    getInputProps,
    getHintProps,
    getMessageProps
  };

  return (
    <FieldContext.Provider value={fieldProps}>
      <StyledField {...props} ref={ref}/>
    </FieldContext.Provider>
  );
});

Field.propTypes = {
  id: PropTypes.string
};

Field.defaultProps = {};

export default Field;
