import { useEffect, useMemo } from "react"

import { useAuditToolContext } from "./store"
import { useClipQuery } from "./hooks"
import NoFileSelected from "./NoFileSelected"
import UploadControls from "./UploadControls"
import AuditPanel from "./AuditPanel"
import { Container } from "./styles"

const Dashboard = () => {
  const {
    state: { entries, clips },
    setIsFetchingClips,
  } = useAuditToolContext()

  const clipIds = useMemo(
    () => entries.map(e => e.clipId).filter(Boolean),
    [entries],
  )

  const { isLoading, isFetching } = useClipQuery(clipIds)
  const isClipsLoading = isLoading && isFetching

  useEffect(() => {
    setIsFetchingClips(isClipsLoading)
  }, [isClipsLoading])

  const hasContent = entries.length > 0 && Object.keys(clips).length > 0

  return (
    <Container>
      {hasContent ? (
        <AuditPanel />
      ) : (
        <>
          <NoFileSelected />
          <UploadControls />
        </>
      )}
    </Container>
  )
}

export default Dashboard
