import React from "react"

const NoFileSelected = () => (
  <div className='add-prompt'>
    <img src='/emptyset.svg' alt='point-up' />
    <div className='add-prompt-text'>
      Select a CSV file to start labeling.
    </div>
  </div>
)

export default NoFileSelected
