import classNames from "classnames";
import styled from "styled-components";

const Item = styled.li.attrs(
  props => {
    const {
      className,
      disabled,
      focused
    } = props;

    return {
      "aria-disabled": disabled,
      className: classNames(className, "dropdown__item", {
        "dropdown__item--is-disabled": disabled,
        "dropdown__item--is-focused": focused
      })
    };
  }
)`
  align-items: center;
  color: var(--colors-white);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-medium);
  padding: var(--space-small) var(--space-medium);
  text-decoration: none;
  user-select: none;
  word-wrap: break-word;

  // Allows an item to contain a positioned sub-menu.
  position: relative;

  z-index: 0;

  // Bit of a conusing hack here. We want our dropdown to be able to sometimes
  // only highlight selectable items. This feature is also useful for when
  // we want to auto highlight a previously selected item, in examples such as
  // when using a dropdown as a "Select" UI. However, our components only send
  // this value down via the focused prop in the case that we are using this
  // functionality. If we are'nt we just default to auto behaviour, handled by
  // CSS, here.
  &.dropdown__item--is-focused,
  &:not(.dropdown__item--is-focused):hover,
  &:not(.dropdown__item--is-focused):focus {
    background-color: var(--colors-grey-080);
  }

  // Give disabled styling priority.
  && {
    &.dropdown__item--is-disabled {
      background-color: transparent;
      color: var(--colors-grey-070);
      cursor: default;
      pointer-events: none;
    }
  }
`;

Item.defaultProps = {};

export default Item;
