import classnames from "classnames"
import { bool, func, string } from "prop-types"
import React, { useRef, useState } from "react"

import style from "./Thumbnail.module.css"

const Thumbnail = ({
  image,
  onClick,
  active,
  className,
  onImgLoad,
  onLeaderClick,
  isLeader,
  fullWidth,
}) => {
  const ref = useRef(null)
  const [isHorizontal, setIsHorizontal] = useState(false)

  return (
    <div
      className={classnames(style.container, className, {
        [style.active]: active,
        [style.horizontal]: isHorizontal,
        [style.leader]: isLeader,
        [style.fullWidth]: fullWidth,
      })}
    >
      <button
        className={style.thumbnailButton}
        disabled={!onClick}
        onClick={() => {
          if (onClick) {
            onClick(image)
          }
        }}
      >
        <img
          className={style.thumbnail}
          src={image}
          alt='thumbnail'
          ref={ref}
          onLoad={() => {
            if (onImgLoad) {
              onImgLoad(ref.current.clientWidth, ref.current.clientHeight)
            }
            setIsHorizontal(ref.current.clientWidth > ref.current.clientHeight)
          }}
        />
      </button>
      {(isLeader || onLeaderClick) && (
        <button
          className={style.leaderButton}
          onClick={() => onLeaderClick(image)}
        >
          M
        </button>
      )}
    </div>
  )
}

Thumbnail.propTypes = {
  image: string.isRequired,
  onClick: func,
  active: bool,
  className: string,
  onImgLoad: func,
  onLeaderClick: func,
  isLeader: bool,
  fullWidth: bool,
}

export default Thumbnail
