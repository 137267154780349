import classNames from "classnames";
import styled from "styled-components";

const IconContainer = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__activity__list-item__icon-container"
    )
  };
})`
  align-items: center;
  color: var(--colors-grey-060);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: var(--space-large);
`;

IconContainer.defaultProps = {};

export default IconContainer;
