import Container from "@guardian/Components/Common/Container"
import React, { useState } from "react"
import { IProps } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsButton/types"

export const AgentScriptsButton = ({ onClick, src }: IProps) => {
  const [active, setActive] = useState(false)

  return (
    <div
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Container onClick={onClick}>
        <img alt='activate' src={`${src}${active ? "-active" : ""}.svg`} />
      </Container>
    </div>
  )
}
