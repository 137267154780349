import {
  AuditToolEntryType,
  type AuditToolAddressEntry,
  type AuditToolEntry,
  type AuditToolEntryKey,
  type AuditToolTitleUpdateEntry,
} from "./types"

export const isAddressEntry = (
  entry?: AuditToolEntry,
): entry is AuditToolAddressEntry => !!entry && "address" in entry

export const isTitleUpdateEntry = (
  entry?: AuditToolEntry,
): entry is AuditToolTitleUpdateEntry => !!entry && !isAddressEntry(entry)

export const getEntryType = (entry: AuditToolEntry): AuditToolEntryType =>
  isAddressEntry(entry)
    ? AuditToolEntryType.ADDRESS
    : AuditToolEntryType.TITLE_UPDATE

export const getEntryKey = (entry: AuditToolEntryKey) =>
  `${entry.incidentId}::${entry.clipId}`

export const createAddressEntry = (
  entry: Partial<AuditToolAddressEntry> & AuditToolEntryKey,
): AuditToolAddressEntry => {
  return {
    address: "",
    displayLocation: "",
    aaAddress: "",
    isIncorrect: false,
    auditedBy: "",
    createdAt: "",
    updatedAt: "",
    ...entry,
  }
}

export const createTitleUpdateEntry = (
  entry: Partial<AuditToolTitleUpdateEntry> & AuditToolEntryKey,
): AuditToolTitleUpdateEntry => {
  return {
    title: "",
    update: "",
    isInaccurate: false,
    isPrivacyViolated: false,
    isBadCopy: false,
    isNotReportable: false,
    auditedBy: "",
    createdAt: "",
    updatedAt: "",
    ...entry,
  }
}
