import React, { useMemo, useState } from "react";

import { useGlobalState } from "@guardian/State";
import {
  Dropdown,
  Item,
  Menu,
  Trigger
} from "@guardian/UI/Dropdown";
import { Icon } from "@guardian/UI/Icon";
import { push } from "@guardian/Utils/history";

import StyledUserDropdown from "./views/UserDropdown";
import UserDropdownAction from "./views/UserDropdownAction";
import UserDropdownArrow from "./views/UserDropdownArrow";
import UserDropdownInfo from "./views/UserDropdownInfo";

const UserDropdown = () => {
  const user = useGlobalState(state => state.global.user);

  const [isOpen, setIsOpen] = useState(false);

  const username = useMemo(() => user.username, []);

  const actions = [
    {
      value: "actions-log-out",
      label: (
        <UserDropdownAction>
          {/* @ts-ignore */}
          <Icon.Logout/>
          Log Out
        </UserDropdownAction>
      )
    }
  ];

  const handleLogOut = () => {
    push("/logout");
  };

  const handleSelect = ({ value }: { value: any }) => {
    switch (value) {
      case "actions-log-out":
        handleLogOut();
        break;
      default:
        // Do nothing.
    }
  };

  const handleStateChange = (dropdownState: any) => {
    setIsOpen(dropdownState.isOpen);
  };

  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <Dropdown
        onSelect={handleSelect}
        onStateChange={handleStateChange}
        downshiftProps={{ itemToString: (item: any) => item && item.value }}
      >
        {/* @ts-ignore */}
        <Trigger>
          <StyledUserDropdown>
            { username }
            <UserDropdownArrow active={isOpen}/>
          </StyledUserDropdown>
        </Trigger>
        {/* @ts-ignore */}
        <Menu placement="bottom-end">
          <React.Fragment>
            {
              actions.map((item, i) => (
                <React.Fragment key={i}>
                  {/* @ts-ignore */}
                  <Item key={i} value={item}>
                    { item.label }
                  </Item>
                </React.Fragment>
              ))
            }
						<UserDropdownInfo>
							Version: {import.meta.env.LATEST_TAG} <br />
							Commit: {import.meta.env.LATEST_HASH}
						</UserDropdownInfo>
          </React.Fragment>
        </Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default UserDropdown;
