import React from "react"
import { IPropsPublisher } from "@guardian/Components/ModSOS/components/Video/Twillio/types"
import { MessageAgentMuted } from "@guardian/Components/ModSOS/components/Video/Twillio/components/MessageAgentMuted"
import { MessageAgentVideoDisabled } from "@guardian/Components/ModSOS/components/Video/Twillio/components/MessageAgentVideoDisabled"
import { AgentVideo } from "@guardian/Components/ModSOS/components/Video/Twillio/components/AgentVideo"
import { selectAudioTrack } from "@guardian/Components/ModSOS/store/video/selectors/selectAudioTrack"
import { selectVideoTrack } from "@guardian/Components/ModSOS/store/video/selectors/selectVideoTrack"
import { AgentAudio } from "@guardian/Components/ModSOS/components/Video/Twillio/components/AgentAudio"
import styles from "@guardian/Components/ModSOS/components/Video/Twillio/Twilio.module.css"

export const TwilioPublisher: React.FunctionComponent<IPropsPublisher> = ({
  twilioRoom,
  userDisabledAgentAudio,
  userDisabledAgentVideo,
}) => {
  const audioTrack = selectAudioTrack(twilioRoom)
  const videoTrack = selectVideoTrack(twilioRoom)

  return (
    <div className={styles.agentContainer}>
      <div className={styles.buttonsContainer}>
        <AgentAudio twilioRoom={twilioRoom} audioTrack={audioTrack} />
      </div>
      <AgentVideo twilioRoom={twilioRoom} videoTrack={videoTrack} />
      {userDisabledAgentAudio && <MessageAgentMuted />}
      {userDisabledAgentVideo && <MessageAgentVideoDisabled />}
    </div>
  )
}
