import React from "react";

import useFieldContext from "../hooks/useFieldContext";
import StyledHint from "../views/Hint";

const Hint = React.forwardRef((props, ref) => {
  const fieldContext = useFieldContext();

  let combinedProps = {
    ref,
    ...props
  };

  if (fieldContext) {
    combinedProps = fieldContext.getHintProps(combinedProps);
  }

  return (
    <StyledHint ref={ref} {...combinedProps}/>
  );
});

export default Hint;
