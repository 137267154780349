import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

import { SmallText } from "@guardian/UI/Typography";
import { SmallParagraph } from "@guardian/UI/Paragraph";

import ByLineRow from "./views/ByLineRow";
import ContentContainer from "./views/ContentContainer";
import ContentRow from "./views/ContentRow";
import StyledNotesListItem from "./views/NotesListItem";

const formatDate = (date) => dayjs(date).fromNow();

const NotesListItem = props => {
  const {
    note: {
      agentName,
      createdAt,
      text
    }
  } = props;

  const [formattedDate, setFormattedDate] = useState(() => formatDate(createdAt));

  const syncDate = useCallback(() => {
    setFormattedDate((prevDate) => dayjs(prevDate).fromNow());
  }, []);

  // NOTE: I'm very confused by this. Dayjs config doesnt appear to work unless
  // it's inside of the component function here. It seems to work elsewhere. I'm
  // wondering - and concerned - that having it outside of the function is
  // allowing dayjs usages elsewhere to override the settings here... This
  // doesn't seem ideal. Placing this inside of a useLayoutEffect to at least
  // mitigate this config happening every render.
  useLayoutEffect(() => {
    // Extend dayjs to retrieve time ago, relative to user.
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);

    dayjs.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        m: "1 min ago",
        mm: "%d mins ago",
        h: "1 hr ago",
        hh: "%d hrs ago",
        d: "1 day ago",
        dd: "%d days ago",
        M: "1 month ago",
        MM: "%d months ago",
        y: "1 year ago",
        yy: "%d years ago"
      }
    });
  }, []);

  useEffect(() => {
    if (createdAt == null) { return; }

    let timeout;

    const createdAtDate = dayjs(createdAt);

    const loop = (delay=1000) => {
      timeout = setTimeout(() => {
        syncDate();

        const age = dayjs().diff(createdAtDate, "minute");

        let nextDelay;
        if (age < 1) {
          // Update every second if less than a minute old.
          nextDelay = 1000;
        } else if (age < 60) {
          // Update every minute if less than an hour old.
          nextDelay = 1000 * 60;
        } else {
          // Update every hour if older than an hour old.
          nextDelay = 1000 * 60 * 60;
        }

        loop(nextDelay);
      }, delay);
    }

    syncDate();
    loop();

    return () => {
      clearTimeout(timeout);
    }
  }, [createdAt]);

  return (
    <StyledNotesListItem>
      <ContentContainer>
        <ByLineRow>
          <SmallText truncate>
            { agentName }
          </SmallText>
          <SmallText>
            { `·` }
          </SmallText>
          <SmallText>
            { formattedDate }
          </SmallText>
        </ByLineRow>
        <ContentRow>
          <SmallParagraph>
            { text }
          </SmallParagraph>
        </ContentRow>
      </ContentContainer>
    </StyledNotesListItem>
  );
};

export default NotesListItem;
