import React, { useState, useCallback, useEffect } from "react"
import styles from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.module.css"
import {
  SOS_SESSION_DISABLED,
  SOS_SESSION_MISSED,
} from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.helper"
import { ISessionPayload } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"
import { Sockets } from "@guardian/Sockets";
import classnames from "classnames"
import _ from "lodash"
import { CallNodeLink } from "@guardian/Components/ModSOS/components/CallQueue/CallNodeLink/CallNodeLink"
import { CallQueueNeighbors } from "@guardian/Components/ModSOS/components/CallQueue/CallQueueNeighbors/CallQueueNeighbors"
import { Banner } from "@guardian/Components/ModSOS/components/CallQueue/Banner/Banner"
import { getActiveCalls } from "@guardian/API/Optimus/resources/legacy/resources/WMB"
import OT from "@opentok/client"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { timer } from "rxjs"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import {
  CallTakenModal,
  PromptUserInteractionModal,
} from "@guardian/Components/ModSOS/components/ErrorModals/Modal"
import {
  closeErrorModal,
  updateCallSession,
} from "@guardian/Components/ModSOS/store/dashboard/actions/dashboard"
import { TErrorModal } from '@guardian/Components/ModSOS/store/dashboard/types'

interface IProps {
  activeSessionId?: string
  socket: Sockets
  onUserDisconnected: (sessionId: string) => void
  onVideoSelect: (deviceId: string) => void
}

export const CallQueue: React.FunctionComponent<IProps> = React.memo(
  ({ activeSessionId = "", socket, onUserDisconnected, onVideoSelect }) => {
    const [calls, setCalls] = useState<Array<ISessionPayload>>([])
    const [polledCalls, setPolledCalls] = useState<Array<ISessionPayload>>([])
    const [displayBanner, setDisplayBanner] = useState(!activeSessionId)
    const [availableDevices, setAvailableDevices] = useState<any>([])
    const [selectedDevice, setSelectedDevice] = useState("")
    const { state, dispatch } = useSOSDashboardContext()

    const handleSOSFeedEvent: (e: any) => void = useCallback(
      e => {
        const { status, id, metadata } = _.get(e, "payload.value", {})
        const newPayload = {
          id,
          status,
          metadata,
        }

        if (id === activeSessionId) {
          if (status === SOS_SESSION_DISABLED) {
            onUserDisconnected(id)
          }

          dispatch(
            updateCallSession(
              status === SOS_SESSION_DISABLED ? undefined : newPayload,
            ),
          )
        }

        const indexToUpdate = _.findIndex(calls, { id })
        if (status === SOS_SESSION_DISABLED) {
          if (indexToUpdate > -1) {
            calls.splice(indexToUpdate, 1)
            setCalls([...calls])
          }
          return
        }

        if (indexToUpdate === -1) {
          if (!activeSessionId && status !== SOS_SESSION_MISSED) {
            let notification = new Notification("New SOS Triggered!")
            if (calls.length === 0) setDisplayBanner(true)
            notification.onclick = () => {
              window.open("/sos")
            }
          }
          calls.push(newPayload)
        } else {
          calls.splice(indexToUpdate, 1, newPayload)
        }
        setCalls([...calls])
      },
      [onUserDisconnected, activeSessionId, calls, dispatch],
    )

    useEffect(() => {
      if (
        Notification.permission !== "denied" &&
        Notification.permission === "default"
      ) {
        Notification.requestPermission()
      }
      OT.getDevices((err, value: any) => {
        let availDevices: any = []
        let selectedDeviceSet = false
        value.forEach((val: any) => {
          if (val.kind === "videoInput") {
            availDevices.push({ id: val.deviceId, name: val.label })
            if (val.label.includes("Snap Camera")) {
              setSelectedDevice(val.deviceId)
              selectedDeviceSet = true
              onVideoSelect(val.deviceId)
            }
          }
        })

        if (!selectedDeviceSet && availDevices.length) {
          setSelectedDevice(availDevices[0].id)
          onVideoSelect(availDevices[0].id)
        }
        setAvailableDevices(availDevices)
      })
    }, [])

    const refreshCalls = (initializeCallState: boolean) => {
      getActiveCalls()
        .toPromise()
        .then(({ data }) => {
          let stillActive: boolean = false
          if (data) {
            const existingCalls: Array<ISessionPayload> = []
            data.forEach((payload: ISessionPayload) => {
              if (payload.id === activeSessionId) {
                dispatch(updateCallSession(payload))
                stillActive = true
              }
              existingCalls.push(payload)
            })
            existingCalls.sort((a, b) => {
              const aTime = new Date(a.metadata.createdAt).getTime()
              const bTime = new Date(b.metadata.createdAt).getTime()
              return aTime - bTime
            })
            if (initializeCallState) {
              setCalls([...existingCalls])
            }
            setPolledCalls([...existingCalls])
          }

          if (activeSessionId) {
            if (!stillActive) {
              onUserDisconnected(activeSessionId)
            }
          }
        })
        .catch(error => {
          Sentry.captureException(error)
        })
    }

    useEffect(() => {
      for (let polledCall of polledCalls) {
        const socketCall = _.find(calls, { id: polledCall.id })

        const dateSame = dayjs(socketCall?.metadata.createdAt).isSame(
          dayjs(polledCall.metadata.createdAt),
          "second",
        )

        if (socketCall && dateSame) {
          socketCall.metadata.createdAt = polledCall.metadata.createdAt
        }

        if (!_.isEqual(socketCall, polledCall)) {
          setCalls([...polledCalls])
        }
      }
    }, [polledCalls])

    useEffect(() => {
      if (!activeSessionId) {
        dispatch(updateCallSession(undefined))
      }

      refreshCalls(true)
      const subscription = timer(3000, 3000).subscribe(() => {
        refreshCalls(false)
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [activeSessionId, dispatch])

    useEffect(() => {
      setDisplayBanner(!activeSessionId)

      if (!socket.isOpen && !socket.isOpening) {
        console.log("emergency socket open")
        socket.open()
      }
      socket.sub({ method: "subscribeSOSFeed" })
      socket.onEvent("sosFeedEvent", handleSOSFeedEvent)
      return () => {
        socket.removeEvent("sosFeedEvent", handleSOSFeedEvent)
      }
    }, [activeSessionId, handleSOSFeedEvent, socket])

    return (
      <React.Fragment>
        {displayBanner &&
          calls.length === 1 &&
          !_.get(calls, "[0].metadata.analystName", "") &&
          calls[0].status !== SOS_SESSION_MISSED && (
            <Banner sessionId={_.get(calls, "[0].id", "")} />
          )}
        <div className={classnames(styles.pane)}>
          <div className={styles.paneHeader}>
            Queue
          </div>
          <div className={styles.paneCallNodeListContainer}>
            {calls.map((payload: ISessionPayload) => {
              return (
                <CallNodeLink
                  payload={payload}
                  key={payload.id}
                  currentId={
                    state.activeSessionId && state.is911InSession
                      ? state.activeSessionId
                      : _.get(state.callSession, "id", "")
                  }
                />
              )
            })}
          </div>
          <select
            className={styles.paneVideoInputSelector}
            onChange={({ target: { value } }) => {
              setSelectedDevice(value)
              onVideoSelect(value)
            }}
            value={selectedDevice}
          >
            {availableDevices.map((device: any) => {
              return (
                <option key={device.id ?? ''} value={device.id}>
                  {device.name}
                </option>
              )
            })}
          </select>
        </div>
        {!activeSessionId && <CallQueueNeighbors />}
        <PromptUserInteractionModal
          open={
            !!state.errorModal &&
            state.errorModal.type === TErrorModal.PromptUserInteraction
          }
          onClose={() => {
            dispatch(closeErrorModal())
          }}
          metadata={state.errorModal && state.errorModal.metadata}
        />
        <CallTakenModal
          open={
            !!state.errorModal && state.errorModal.type === TErrorModal.CallTaken
          }
          onClose={() => {
            dispatch(closeErrorModal())
          }}
          metadata={state.errorModal && state.errorModal.metadata}
        />
      </React.Fragment>
    )
  },
)
