const zPositions = {
  base: 0,  // Base
  above: 1, // Use this for all values above the base.
  below: -1 // Use this for all values below the base.
};

export const zPositionsCSSVariables = `
  :root {
    --z-positions-base: ${ zPositions.base };
    --z-positions-above: ${ zPositions.above };
    --z-positions-below: ${ zPositions.below };
  }
`;

export default zPositions;
