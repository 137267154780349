import React, { useEffect, useState } from "react";

import { LoggingService } from "@guardian/Services/Logging";
import { Box } from "@guardian/UI/Box";
import {
  Body,
  BodySeparator,
  Close,
  Header,
  Modal
} from "@guardian/UI/Modal";
import { MediumParagraph } from "@guardian/UI/Paragraph";

import Log from "./components/Log";
import TabBar from "./views/TabBar";
import TabButton from "./views/TabButton";
import TabButtonGroup from "./views/TabButtonGroup";

const getTrackedErrors = () => {
  return JSON.stringify(LoggingService.getTrackedErrors(), undefined, 2);
};

const getTrackedMessages = () => {
  return JSON.stringify(LoggingService.getTrackedMessages(), undefined, 2);
};

const getTrackedWarnings = () => {
  return JSON.stringify(LoggingService.getTrackedWarnings(), undefined, 2);
};

const LogModal = (props) => {
  const {
    onClose,
    open
  } = props;

  const [activeTab, setActiveTab] = useState("errors");
  const [trackedErrors, setTrackedErrors] = useState(getTrackedErrors);
  const [trackedMessages, setTrackedMessages] = useState(getTrackedMessages);
  const [trackedWarnings, setTrackedWarnings] = useState(getTrackedWarnings);

  useEffect(() => {
    const checkTrackedLogs = () => {
      setTrackedErrors(getTrackedErrors());
      setTrackedMessages(getTrackedMessages());
      setTrackedWarnings(getTrackedWarnings());
    };

    checkTrackedLogs();
  }, [open]);

  const handleErrorsTabClicked = () => {
    setActiveTab("errors");
  };

  const handleMessagesTabClicked = () => {
    setActiveTab("messages");
  };

  const handleWarningsTabClicked = () => {
    setActiveTab("warnings");
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
      size={"large"}
    >
      <Header>Log History</Header>
      <Body>
        <Box>
          <MediumParagraph withMeasure>
            When errors or other messages are logged within the application,
            they may be tracked here and persisted across page loads and
            sessions.
          </MediumParagraph>
          <MediumParagraph withMeasure>
            Copy these logs and share them with Citizen support engineers.
          </MediumParagraph>
        </Box>
        <TabBar>
          <TabButtonGroup>
            <TabButton
              isActive={activeTab === "errors"}
              data-text={"Errors"}
              onClick={handleErrorsTabClicked}
            >
              Errors
            </TabButton>
            <TabButton
              isActive={activeTab === "messages"}
              data-text={"Messages"}
              onClick={handleMessagesTabClicked}
            >
              Messages
            </TabButton>
            <TabButton
              isActive={activeTab === "warnings"}
              data-text={"Warnings"}
              onClick={handleWarningsTabClicked}
            >
              Warnings
            </TabButton>
          </TabButtonGroup>
        </TabBar>
        <BodySeparator/>
        {
          activeTab === "errors" && (
            <Log
              content={trackedErrors}
              title={"Error"}
            />
          )
        }
        {
          activeTab === "messages" && (
            <Log
              content={trackedMessages}
              title={"Message"}
            />
          )
        }
        {
          activeTab === "warnings" && (
            <Log
              content={trackedWarnings}
              title={"Warning"}
            />
          )
        }
      </Body>
      <Close/>
    </Modal>
  );
};

LogModal.transitionMs = 250;

export default LogModal;
