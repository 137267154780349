import { createTheme, OutlinedInput, ThemeProvider } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { getDistance } from "geolib"
import { List } from "immutable"
import _ from "lodash"
import { array, func, object } from "prop-types"
import { useEffect, useMemo, useRef } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import { GeocodingService } from "@guardian/Services/Geocoding"
import * as incUtil from "@guardian/Utils/incident"
import { objPick } from "@guardian/Utils/obj-pick"
import { isNearby } from "@guardian/Utils/search_filter"
import { reverseGeoToLocation } from "@guardian/Utils/util"

import LocationInput from "@guardian/Components/Form/Inputs/LocationInput"
import { Checkbox } from "@guardian/Components/ui/Legacy/Checkbox"
import { withErrorBoundary } from "@guardian/Components/ErrorBoundary"

import { ActionTypes } from "../ActionPane.reducer"
import IncidentCategory from "./IncidentCategory"
import style from "./IncidentCreation.module.css"
import { baseStyles, multilineStyles } from "./IncidentCreation.styles"
import MergeIncidentInput from "./MergeIncidentInput"
import NearbyIncidentList from "./NearbyIncidentList"

const StyledInput = withStyles(baseStyles)(OutlinedInput)
const StyledMultiline = withStyles(multilineStyles)(OutlinedInput)

const theme = createTheme({
  palette: {
    text: {
      disabled: "#999999",
      color: "white",
    },
  },
})

const IncidentCreation = ({
  state,
  dispatch,
  stream,
  recentIncidents,
  incident,
  refreshVideoStreams,
}) => {
  const { tags, readOnly } = useGlobalState(globalState =>
    objPick(globalState.global, ["tags", "readOnly"]),
  )

  useEffect(() => {
    if (!state.location) {
      return
    }
    eventHub.emit("set active marker", {
      lat: state.location.lat,
      lng: state.location.lng,
    })
    eventHub.emit("set center", {
      lat: state.location.lat,
      lng: state.location.lng,
    })
  }, [state.location])

  useEffect(() => {
    const getLocFromLatLng = async latLng => {
      const geocodeResp = await GeocodingService.reverseGeocode(
        latLng.lat,
        latLng.lng
      )
      if (geocodeResp) {
        const locState = await reverseGeoToLocation(latLng, geocodeResp)
        dispatch({
          type: ActionTypes.SetIncidentAddress,
          payload: { address: locState },
        })
      }
    }
    eventHub.on("updateIncidentLocation", getLocFromLatLng)

    return () => {
      eventHub.off("updateIncidentLocation", getLocFromLatLng)
    }
  }, [dispatch])

  const currentLocation = useMemo(() => {
    if (stream?.iglStream) {
      return {
        lat: stream.iglStream.lat,
        lng: stream.iglStream.lng,
      }
    }
    if (incident?.location) {
      return {
        lat: incident.location.lat,
        lng: incident.location.lng,
      }
    }
    return null
  }, [stream?.iglStream, incident?.location])

  const nearbyIncidents = useMemo(() => {
    if (!recentIncidents) {
      return null
    }
    if (recentIncidents.length === 0 || !currentLocation) {
      return []
    }
    const NEARBY_THRESHOLD = 4824 // 3 miles
    return _.sortBy(
      recentIncidents
        .filter(i => isNearby(
          [currentLocation.lng, currentLocation.lat],
          { lat: i.location?.lat, lng: i.location?.lng },
          NEARBY_THRESHOLD,
        ))
        .filter(i => i.id !== incident?.id)
        .map(i => ({
          ...i,
          time: incUtil.latestRev(i)?.updatedAt,
          distance: getDistance(
            { latitude: currentLocation.lat, longitude: currentLocation.lng },
            { latitude: i.location?.lat, longitude: i.location?.lng },
          ),
        })),
      ["distance", "time"],
    )
  }, [incident?.id, recentIncidents, currentLocation])

  const incidentMapCache = useRef({})
  useEffect(() => {
    const hasChanged = nearbyIncidents?.some(i => !incidentMapCache.current?.[i.id])
    if (!hasChanged) {
      return
    }

    incidentMapCache.current = nearbyIncidents.reduce((acc, i) => {
      acc[i.id] = {
        ...i,
        center: [i.location?.lng, i.location?.lat],
      }
      return acc
    }, {})

    eventHub.emit("set map", {
      markers: new List(Object.values(incidentMapCache.current)),
      markerType: "incident",
    })
  }, [nearbyIncidents])

  return (
    <ThemeProvider theme={theme}>
      {stream?.videoStream?.onAir ? (
        <MergeIncidentInput
          setMerging={mergingIncident => {
            dispatch({
              type: ActionTypes.SetMergingIncident,
              payload: { incident: mergingIncident, onAir: true },
            })
          }}
          mergingIncident={state.mergingIncident}
        />
      ) : (
        <>
          <div className={style.nearbyIncidents}>
            <NearbyIncidentList
              nearbyIncidents={nearbyIncidents}
              mergingIncident={state.mergingIncident}
              setMerging={mergingIncident => {
                dispatch({
                  type: ActionTypes.SetMergingIncident,
                  payload: { incident: mergingIncident },
                })
              }}
              disabled={readOnly}
              currentIncident={incident}
              refreshVideoStreams={refreshVideoStreams}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <LocationInput
              label='Address or Location'
              onChange={e => {
                dispatch({
                  type: ActionTypes.SetIncidentAddress,
                  payload: { address: e },
                })
              }}
              placeholder='Address or Intersection'
              defaultValue={state.location?.address || ""}
              key='address'
            />
          </div>
          <div className={style.row}>
            <StyledInput
              label='Display Location'
              notched
              style={{ width: "167px", marginRight: "8px" }}
              value={state.location?.location || ""}
              onChange={e =>
                dispatch({
                  type: ActionTypes.SetLocationLocation,
                  payload: { location: e.target.value },
                })
              }
              disabled={readOnly}
            />
            <StyledInput
              label='Police'
              notched
              style={{ width: "167px" }}
              value={state.location?.police || ""}
              disabled={readOnly}
            />
          </div>
          <StyledInput
            label='Title'
            notched
            value={state.incidentTitle}
            onChange={e =>
              dispatch({
                type: ActionTypes.SetIncidentTitle,
                payload: { title: e.target.value },
              })
            }
            disabled={readOnly}
          />
          <StyledMultiline
            label='Update'
            notched
            multiline
            value={state.incidentUpdate}
            onChange={e =>
              dispatch({
                type: ActionTypes.SetIncidentUpdate,
                payload: { update: e.target.value },
              })
            }
            disabled={readOnly}
          />
          <div className={style.row}>
            <StyledInput
              label='Safety Level'
              notched
              style={{ width: "167px" }}
              value={`Level ${state.safetyLevel}`}
              onChange={e =>
                dispatch({
                  type: ActionTypes.SetSafetyLevel,
                  payload: { level: e.target.value.split(" ")[1] },
                })
              }
              disabled={readOnly}
              endAdornment={
                <div className={style.incrementArrows}>
                  <button
                    type="button"
                    className={style.unstyledButton}
                    onClick={() =>
                      dispatch({
                        type: ActionTypes.SetSafetyLevel,
                        payload: {
                          level: Math.min(state.safetyLevel + 1, 3),
                        },
                      })
                    }>
                    <img alt="" src='/UpChevron.svg' />
                  </button>
                  <button
                    type="button"
                    className={style.unstyledButton}
                    onClick={() =>
                      dispatch({
                        type: ActionTypes.SetSafetyLevel,
                        payload: {
                          level: Math.max(state.safetyLevel - 1, 0),
                        },
                      })
                    }
                  >
                    <img alt="" src='/DownChevron.svg' />
                  </button>
                </div>
              }
            />
            <div className={style.prompt911}>
              <Checkbox
                active={state.prompt911}
                handleClick={() =>
                  dispatch({
                    type: ActionTypes.SetCall911,
                    payload: { value: !state.prompt911 },
                  })
                }
                disabled={readOnly}
              />
              <div className={style.prompt911Text}>
                Prompt user to call 911
              </div>
            </div>
          </div>
          <IncidentCategory
            categories={["N/A"].concat((tags || []).map(t => t.display_name))}
            category={state.incidentCategory}
            onSetCategory={val =>
              dispatch({
                type: ActionTypes.SetIncidentCategory,
                payload: { category: val },
              })
            }
            disabled={readOnly}
          />
        </>
      )}
    </ThemeProvider>
  )
}

IncidentCreation.propTypes = {
  state: object,
  dispatch: func,
  stream: object,
  recentIncidents: array,
  refreshVideoStreams: func,
}

export default withErrorBoundary(IncidentCreation, {
  domain: "LiveVideoTab",
  method: "IncidentCreation",
  showDialog: true,
})
