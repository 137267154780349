import React from "react"
import { any, func, bool } from "prop-types"
import style from "./ToggleButton.module.css"
import classnames from "classnames"

const ToggleButton = ({ label, onChange, on, onLabel, disabled }) => {
  return (
    <div className={classnames(style.container, { [style.on]: on })}>
      {!!label && <div className={style.label}>{label}</div>}
      <button onClick={onChange} className={style.toggle} disabled={disabled}>
        <div className={style.circleContainer}>
          <div className={style.circle} />
        </div>
      </button>
      {!!onLabel && <div className={style.offLabel}>{onLabel}</div>}
    </div>
  )
}

ToggleButton.propTypes = {
  label: any,
  onChange: func,
  on: bool,
  onLabel: any,
}

export default ToggleButton
