import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { webSockets } from "@guardian/Sockets";
import { LoggingService } from "@guardian/Services/Logging";

import styles from "./SOSCallStatus.module.css";
import { IProps } from "./SOSCallStatus.types";

dayjs.extend(duration);

const SOSCallStatus = (props: IProps) => {
  const {
    activeSessionId,
    call
  } = props;

  const [connectionId, setConnectionId] = useState<null | any>(null);
  const [callDuration, setCallDuration] = useState("");
  const [callStatus, setCallStatus] = useState(() =>
    // Note: This is a bit buggy at the moment.
    call?.connectionId ? "Call in progress" : ""
  );

  const callCreatedAtRef = useRef<null | any>(null);

  useEffect(() => {
    setConnectionId(call?.connectionId || null);
    callCreatedAtRef.current = call?.createdAt || null;
  }, [call]);

  useEffect(() => {
    let durationAnimationLoop = undefined as any;

    const renderDuration = () => {
      const createdAt = callCreatedAtRef.current;
      const calculatedCallDuration = dayjs.duration(dayjs().diff(dayjs(createdAt)));

      if (calculatedCallDuration.asMilliseconds() > 0) {
        const minutes = calculatedCallDuration.minutes().toString().padStart(2, "0");
        const seconds = calculatedCallDuration.seconds().toString().padStart(2, "0");

        setCallDuration(`${minutes}:${seconds}`);
      }
    };

    const loopDurationTimer = () => {
      renderDuration();
      durationAnimationLoop = requestAnimationFrame(loopDurationTimer);
    };

    const startDurationTimer = () => {
      if (!durationAnimationLoop) {
        loopDurationTimer();
      }
    };

    const stopDurationTimer = () => {
      if (durationAnimationLoop) {
        cancelAnimationFrame(durationAnimationLoop);
        durationAnimationLoop = undefined;
      }
    };

    if (connectionId) {
      setCallDuration("");

      startDurationTimer();
    } else {
      setCallDuration("");

      // Note: Our backend currently appears to have a bug where the call
      // completion events from Twilio are not being properly delivered to our
      // frontend. For now, we simply clear the call status and duration when
      // the call's connectionId gets dropped. This isnt exactly ideal though.
      // If refactoring this code in the future, it'd be worth verifying that
      // the socket events being listened to below are still insufficient, and
      // hopefully removing this line.
      setCallStatus("");

      stopDurationTimer();
    }

    return () => {
      stopDurationTimer();
    }
  }, [connectionId]);

  useEffect(() => {
    const handleChangedOutgoingCall = ({ payload }: any) => {
      const { value: { type, value } } = payload;

      if (type !== "changedOutgoingCall") {
        return;
      }

      if (!value?.status) {
        LoggingService.logWarning("No status found in changedOutgoingCall event", {
          domain: "ModSOS",
          method: "SOSCallStatus",
          trackedData: {
            eventPayload: payload,
            value,
          },
        });
        return;
      }

      let newStatus;
      switch (value.status) {
        case "initiated":
        case "queued":
        case "requested":
          // The call is ready and waiting in line before dialing.
          newStatus = "Dialing...";
          break;
        case "ringing":
          // The call is currently ringing.
          newStatus = "Dialing...";
          break;
        case "in-progress":
          // The call was answered and is currently in progress.
          newStatus = "Call in progress";
          break;
        case "canceled":
          // The call was hung up while it was queued or ringing.
          newStatus = "Call ended";
          break;
        case "completed":
          // The call was answered and has ended normally.
          newStatus = "Call ended";
          break;
        case "busy":
          // The caller received a busy signal.
          newStatus = "Busy";
          break;
        case "no-answer":
          // There was no answer or the call was rejected.
          newStatus = "Call was not answered";
          break;
        case "failed":
          // The call could not be completed as dialed, most likely because the
          // phone number was non-existent.
          newStatus = "Call failed";
          break;
        default:
          newStatus = "";
          return;
      }

      // TODO: Debounce/Throttle.
      setCallStatus(newStatus);
    };

    const subscribeRequest = {
      method: "subscribeProtectFeed",
      value: { sessionId: activeSessionId }
    };

    const subscribeEvent = "protectMessageEvent";

    webSockets.sub(subscribeRequest);
    webSockets.onEvent(subscribeEvent, handleChangedOutgoingCall);

    return () => {
      const unsubscribeRequest = {
        method: "unsubscribeProtectFeed",
        value: { sessionId: activeSessionId }
      };

      webSockets.removeEvent(subscribeEvent, handleChangedOutgoingCall);
      webSockets.removeSub(subscribeRequest, unsubscribeRequest);
    }
  }, [activeSessionId]);

  return (
    <h5 className={styles.status}>
      {
        `${ callStatus }${ callDuration ? ` ${ callDuration }` : "" }`
      }
    </h5>
  );
};

export default SOSCallStatus;
