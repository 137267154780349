import { useEffect, useRef } from "react";

import { Dialog, Flash } from "@guardian/UI/Alert";
import usePrevious from "@guardian/Hooks/usePrevious";
import { LoggingService } from "@guardian/Services/Logging";

import useGetAgentChatUnreadCount from
  "@guardian/Components/Dashboard/components/NavigationBar/hooks/useGetAgentChatUnreadCount";

const AUDIO = new Audio("/sos-beep-2.wav")

export const usePlaySOSAudio = () => {
  const [unreadCount] = useGetAgentChatUnreadCount();

  const hasDisplayedInteractionWarning = useRef(false);
  const previousCount = usePrevious<number>(unreadCount, 0)!;

  const playAudioAlert = () => {
    AUDIO.play().catch(error => {
      let flashThisError = false;

      if (error.message.match("the user didn't interact")) {
        if (hasDisplayedInteractionWarning.current === false) {
          Dialog.error(
            "Failed to play audio alert for new Agent Chat messages. You " +
            "must first interact with the webpage (e.g.; clicking anywhere) " +
            "in order for the browser to allow audio to play automatically!",
            {
              title: "Failed to Play Agent Chat Audio Alert!"
            }
          );

          // Only display this dialog once. Dialogs do not disappear until the
          // user closes them, which will count as an interaction.
          hasDisplayedInteractionWarning.current = true;
        }
      } else {
        // Only flash this error if we didn't already show a Dialog error above.
        flashThisError = true;
      }

      LoggingService.logError(
        `Failed to Play Agent Chat Audio Alert!`,
        {
          domain: "NavigationBar",
          method: "usePlaySOSAudio",
          error,
          flash: flashThisError,
          trackedData: {
            error: error.message
          }
        }
      );
    });
  };

  useEffect(() => {
    if (unreadCount > previousCount) {
      playAudioAlert();
    }
  }, [unreadCount]);
};
