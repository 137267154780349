import React from "react"
import styles from "@guardian/Components/ModSOS/components/ActionBar/EmergencyContactsModal/EmergencyContactsModal.module.css"
import classnames from "classnames"
import Container from "@guardian/Components/Common/Container"
import { notifyProtectEmergencyContacts } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { IProps } from "@guardian/Components/ModSOS/components/ActionBar/EmergencyContactsModal/types"

export const EmergencyContactsModal: React.FunctionComponent<IProps> = ({
  isLeftOrientation,
  offsetLeft,
  offsetTop,
  onClose,
  contacts,
  offPlatformContacts,
  sessionId,
}) => {
  const onClickYes = () => {
    notifyProtectEmergencyContacts(
      sessionId,
      contacts.map(c => c.id),
      offPlatformContacts.map(c => c.contact_id),
    )
    onClose()
  }
  const onClickNo = () => {
    onClose()
  }

  return (
    <div
      className={classnames(styles.modal, { [styles.left]: isLeftOrientation })}
      style={{ left: offsetLeft, top: offsetTop }}
    >
      <div className={styles.actions}>
        <Container fullWidth bottom={6} column leftJustify topAlign>
          {contacts.map(contact => {
            return (
              <Container fullWidth key={contact.id} top={3}>
                <Container fill leftJustify className={styles.text}>
                  {contact.fullName}
                </Container>
                <button
                  className={classnames(styles.button, styles.small)}
                  onClick={() => {
                    notifyProtectEmergencyContacts(sessionId, [contact.id], [])
                    onClose()
                  }}
                >
                  Call
                </button>
              </Container>
            )
          })}
          {offPlatformContacts.map(offPlatformContact => {
            return (
              <Container fullWidth key={offPlatformContact.contact_id} top={3}>
                <Container fill leftJustify className={styles.text}>
                  {offPlatformContact.fullName}
                </Container>
                <button
                  className={classnames(styles.button, styles.small)}
                  onClick={() => {
                    notifyProtectEmergencyContacts(
                      sessionId,
                      [],
                      [offPlatformContact.contact_id],
                    )
                    onClose()
                  }}
                >
                  Call
                </button>
              </Container>
            )
          })}
        </Container>
        <div className={styles.flexRow}>
          <button className={styles.button} onClick={onClickYes}>
            Call All
          </button>
          <button className={styles.button} onClick={onClickNo}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
