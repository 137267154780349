import React, { useMemo } from "react";

import { AgentService } from "@guardian/Services/Agent";
import { Avatar } from "@guardian/UI/Avatar";

import AgentMessage from "./components/AgentMessage";
import AgentImageWrapper from "./views/AgentImageWrapper";
import StyledAgentMessageGroup from "./views/AgentMessageGroup";
import AgentMessages from "./views/AgentMessages";
import AgentName from "./views/AgentName";

const AgentMessageGroup = props => {
  const {
    messageGroup: {
      messages
    },
    userId,
    userInfo: {
      avatarThumbURL,
      fullName,
    }
  } = props;

  // TODO: I dont think this works... The userId from the AgentChat backend is
  // the psuedo ID for an agent (the one that starts with "agent_id_"), where
  // on the frontend I believe we only store the real userId fro the agent.
  // Not sure how we convert that to the pseudo ID at this time but this seems
  // like our only option for successfully getting this check.
  const isOwn = useMemo(() => AgentService.userId === userId, [userId]);
  const displayName = isOwn ? "You" : fullName;

  return (
    <StyledAgentMessageGroup>
      <AgentMessages>
        {
          messages.map((message) => (
            <AgentMessage
              key={`agent-message-${ message.id }`}
              message={message}
            />
          ))
        }
        <AgentName>
          { displayName }
        </AgentName>
      </AgentMessages>
      <AgentImageWrapper isOwn={isOwn}>
        <Avatar
          id={userId}
          fullName={fullName}
          src={avatarThumbURL}
          forceTemp={true} // TODO: Remove and prop.
        />
      </AgentImageWrapper>
    </StyledAgentMessageGroup>
  );
};

export default React.memo(AgentMessageGroup);
