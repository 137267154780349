import classNames from "classnames";
import styled from "styled-components";

import { Textarea as GenericTextarea } from "@guardian/UI/Form";

const MessageInput = styled(GenericTextarea).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__outbound-dm__message-input"
    )
  };
})`
  height: 100px;
  resize: none;
`;

MessageInput.defaultProps = {};

export default MessageInput;
