import { useEffect, useRef } from "react"

const usePrevious = <TValue>(value: TValue, defaultValue?: TValue) => {
  const ref = useRef<TValue | undefined>(defaultValue)

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export default usePrevious
