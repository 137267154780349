const fontSizes = [
  "10px", // xSmall
  "12px", // small
  "14px", // medium
  "16px", // large
  "18px", // xLarge
  "24px", // xxLarge
];

// Aliases
fontSizes.xSmall = fontSizes[0];
fontSizes.small = fontSizes[1];
fontSizes.medium = fontSizes[2];
fontSizes.large = fontSizes[3];
fontSizes.xLarge = fontSizes[4];
fontSizes.xxLarge = fontSizes[5];

export const fontSizesCSSVariables = `
  :root {
    --font-sizes-x-small: ${ fontSizes.xSmall };
    --font-sizes-small: ${ fontSizes.small };
    --font-sizes-medium: ${ fontSizes.medium };
    --font-sizes-large: ${ fontSizes.large };
    --font-sizes-x-large: ${ fontSizes.xLarge };
    --font-sizes-xx-large: ${ fontSizes.xxLarge };
  }
`;

export default fontSizes;
