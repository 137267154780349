import React, { FC } from "react"
import styles from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/SOSIncidentPane.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/components/CreateIncidentSuccess/types"

export const CreateIncidentSuccess: FC<IProps> = ({ incidentId }) => (
  <a
    className={styles.viewIncident}
    href={`/incidents/${incidentId}`}
    target='_blank'
    rel='noopener noreferrer'
  >
    View created incident
  </a>
)
