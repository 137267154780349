import { css, keyframes } from "styled-components";

const animationStyles = (theme, position, options) => {
  let translateValue = css`${ theme.space.xSmall }`;
  let transformFunction;

  if (position === "top") {
    transformFunction = "translateY";
  } else if (position === "right") {
    transformFunction = "translateX";
    translateValue = `-${ translateValue }`;
  } else if (position === "bottom") {
    transformFunction = "translateY";
    translateValue = `-${ translateValue }`;
  } else {
    transformFunction = "translateX";
  }

  const animationName = keyframes`
    0% {
      opacity: 0;
      transform: ${ transformFunction }(${ translateValue });
    }
  `;

  return css`
    &${ options.animationModifier } ${ options.childSelector || "> *" } {
      animation: 200ms cubic-bezier(0.39, 0.575, 0.565, 1) ${ animationName };
    }
  `;
};

const hiddenStyles = options => {
  const transition = "200ms cubic-bezier(0.39, 0.575, 0.565, 1)";

  return css`
    transition: ${ options.animationModifier && transition };
    visibility: hidden;
    opacity: 0;
  `;
};

/*
 * CSS for a `wrapper > menu` at the given position. The wrapper provides
 * absolute positioning (e.g. via Popper). The menu provides basic styling and
 * optional animation.
 *
 * The `position` argument should be one of:
 *  - `"top"`
 *  - `"right"`
 *  - `"bottom"`
 *  - `"left"`
 *
 * The `hidden` `option` determines whether the menu is hidden.
 *
 * The `margin` `option` determines is the amount of margin between menu and
 * trigger.
 *
 * The `zIndex` `option` is the menu wrapper's z-index.
 *
 * The `childSelector` `option` is a CSS selector that identifies the child menu
 * component, (e.g.; `> *`).
 *
 * The `animationModifier` `option` is a CSS class or attribute selector which,
 * when applied, animates the menu"s appearance.
 *
 */
const menuStyles = (theme, position, options = {}) => {
  let marginProperty;

  if (position === "top") {
    marginProperty = "margin-bottom";
  } else if (position === "right") {
    marginProperty = "margin-left";
  } else if (position === "bottom") {
    marginProperty = "margin-top";
  } else {
    marginProperty = "margin-right";
  }

  return css`
    position: absolute;
    z-index: ${ options.zIndex || 0 };
    ${ marginProperty }: ${ options.margin };

    // Popper requires a non-zero font-size to calculate initial placement.
    font-size: 0.01px;

    & ${ options.childSelector || "> *" } {
      background-color: ${ theme.colors.grey090 };
      border-radius: ${ theme.radii.medium };
      box-shadow:
        ${ theme.borders.outer.full } ${ theme.colors.grey085 },
        ${ theme.shadows.medium };
      box-sizing: border-box;
      font-size: ${ theme.fontSizes.medium };
      line-height: ${ theme.lineHeights.medium };
      padding: ${ theme.space.xSmall } 0;

      // Prevent controlling item cursor inheritance.
      cursor: default;

      // Browser list element reset.
      margin: 0;

      // Positioned relative to controlling item.
      position: relative;

      // Prevent controlling item whitespace inheritance.
      white-space: normal;

      :focus {
        outline: none;
      }
    }

    ${ options.animationModifier && animationStyles(theme, position, options) };
    ${ options.hidden && hiddenStyles(options) };
  `;
};

export default menuStyles;
