import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  padding: 40px 40px 20px 40px;
  background: rgb(36, 45, 58);
  border-radius: 8px;
  min-width: 400px;

  & > h4 {
    color: #fff;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgb(76, 86, 105);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgb(76, 86, 105);
  color: #fff;
  font-size: 15px;
`

export const InputLabel = styled.div`
  color: #8f9fbf;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  & > div {
    flex: 1;
  }
`

export const SearchButton = styled.button`
  position: absolute;
  right: 5px;
  top: 0;
  border: none;
  background: none;
  font-size: 15px;

  & > i {
    line-height: 40px;
  }
`

export const ErrorMessage = styled.div`
  color: #ff4d4f;
  margin: -10px 0 10px 0;
`

export const IncidentWrapper = styled.div`
  display: flex;
`
