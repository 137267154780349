import { useEffect } from "react";

import { AgentChatService } from "@guardian/Services/AgentChat";
import { AgentService } from "@guardian/Services/Agent";
import { LoggingService } from "@guardian/Services/Logging";

import { retryPromise } from "@guardian/Utils/util";

interface QueryOptions {
  enabled: string
};

const usePingStatusOnline = (options: QueryOptions) => {
  const {
    enabled
  } = options;

  useEffect(() => {
    if (typeof enabled !== "undefined" && !enabled) { return; }

    const controller = new AbortController();
    const signal = controller.signal;
    let timeout: any;

    const loop = () => {
      const pingStatusOnline = () => AgentChatService.pingStatusOnline(AgentService.userId, { signal });

      retryPromise(pingStatusOnline, 5, 1000, () => !signal.aborted)
        .then(() => {
          timeout = setTimeout(loop, 10000);
        })
        .catch(error => {
          if (signal.aborted) {
            return;
          }

          LoggingService.logError(
            "Error sending online status! Check your network connection and refresh.",
            {
              domain: "AppSystem",
              method: "usePingStatusOnline",
              dialog: true,
              trackedData: {
                userId: AgentService.userId,
                error: error
              }
            }
          );
        }
      );
    };

    loop();

    return () => {
      controller.abort();
      clearTimeout(timeout);
    };
  }, [enabled]);
};

export default usePingStatusOnline;
