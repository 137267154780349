import styled from "styled-components"

export const UploadControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
`

export const UploadControlsTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--colors-blue-040);
  font-size: var(--font-sizes-xx-large);
  font-weight: var(--font-weights-bold);
`

export const UploadControlsDescription = styled.div`
  font-size: var(--font-sizes-medium);
  line-height: var(--line-heights-medium);

  code {
    font-family: var(--fonts-monospace);
  }

  ul {
    list-style-type: circle;

    li {
      margin-left: 20px;
    }
  }
`

export const FileInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  /* Override the default margin for the FileInput component */
  &&& input {
    margin-bottom: 0;
  }
`

export const FileInputError = styled.p`
  color: var(--colors-red-040);
  font-size: var(--font-sizes-medium);
  margin: 0;
`
