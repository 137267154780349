import resetCSS from "styled-reset";
import { createGlobalStyle } from "styled-components";

import borders, { bordersCSSVariables } from "./attributes/borders";
import colors, { colorsCSSVariables } from "./attributes/colors";
import fonts, { fontsCSSVariables } from "./attributes/fonts";
import fontSizes, { fontSizesCSSVariables } from "./attributes/fontSizes";
import fontWeights, { fontWeightsCSSVariables } from "./attributes/fontWeights";
import grid, { gridCSSVariables } from "./attributes/grid";
import lineHeights, { lineHeightsCSSVariables } from "./attributes/lineHeights";
import measures, { measuresCSSVariables } from "./attributes/measures";
import radii, { radiiCSSVariables } from "./attributes/radii";
import shadows, { shadowsCSSVariables } from "./attributes/shadows";
import space, { spaceCSSVariables } from "./attributes/space";
import zPositions, { zPositionsCSSVariables } from "./attributes/zPositions";

import legacyGlobalStyles from "./legacy/legacyGlobalStyles";

export const theme = {
  borders: borders,
  colors: colors,
  fonts: fonts,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  grid: grid,
  lineHeights: lineHeights,
  measures: measures,
  radii: radii,
  shadows: shadows,
  space: space,
  zPositions: zPositions
};

export const gridTheme = {
  grid: grid
};

export const GlobalStyles = createGlobalStyle`
  ${ resetCSS }

  ${ bordersCSSVariables }
  ${ colorsCSSVariables }
  ${ fontsCSSVariables }
  ${ fontSizesCSSVariables }
  ${ fontWeightsCSSVariables }
  ${ gridCSSVariables }
  ${ lineHeightsCSSVariables }
  ${ measuresCSSVariables }
  ${ radiiCSSVariables }
  ${ shadowsCSSVariables }
  ${ spaceCSSVariables }
  ${ zPositionsCSSVariables }

  // Note: We will be slowly hacking away at this over time. Global styles
  // should be minimal outside of global theme variable declaration and styles
  // truly global to the app, such as resets and root level (html/body) styles.
  ${ legacyGlobalStyles }

  /*
   * Note:
   * It's necessary that we override strong and italic stylings due to
   * resetCSS forcing those tags to use "inherit" for their font property.
   *
   */
  strong, b, strong *, b * {
    font-weight: bold;
  }

  em, i, em *, i * {
    font-style: italic;
  }
`;

export default theme;
