import Container from "@guardian/Components/Common/Container"
import React, { useEffect, useState } from "react"
import styles from "@guardian/Components/ModSOS/components/InfoPane/SearchBox/SearchBox.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/InfoPane/SearchBox/types"

export const SearchBox = ({
  onSearch,
  onSearchEnd,
  searchMatches = [],
  onActiveSearchChange,
}: IProps) => {
  const [searchMode, setSearchMode] = useState(false)
  const [activeSearch, setActiveSearch] = useState(0)
  const [query, setQuery] = useState<string>()

  useEffect(() => {
    setActiveSearch(!!searchMatches.length ? 1 : 0)
    if (onActiveSearchChange && !!searchMatches.length) {
      onActiveSearchChange(searchMatches[activeSearch - 1])
    }
  }, [searchMatches])

  useEffect(() => {
    if (onActiveSearchChange && !!searchMatches.length) {
      onActiveSearchChange(searchMatches[activeSearch - 1])
    }
  }, [activeSearch])

  return (
    <Container
      className={styles.container}
      top={1}
      bottom={1}
      left={2}
      right={2}
      fill
      style={
        searchMode ? { backgroundColor: "rgba(77, 166, 255, 0.2)" } : undefined
      }
      leftJustify
    >
      <Container right={2}>
        <img alt="" src='/search.svg' />
      </Container>
      <Container fill leftJustify>
        <input
          className={styles.input}
          placeholder='Search'
          onChange={e => {
            onSearch(e.currentTarget.value)
            setQuery(e.currentTarget.value)
            setSearchMode(true)
          }}
          value={query}
        />
      </Container>
      {searchMode && (
        <>
          <Container className={styles.searchNumber} right={2}>
            {activeSearch} / {searchMatches.length}
          </Container>
          <Container className={styles.divider} fullHeight />
          <Container
            left={2}
            right={2}
            onClick={() =>
              setActiveSearch(Math.min(searchMatches.length, activeSearch + 1))
            }
          >
            <img alt="" src='/Caret.svg' />
          </Container>
          <Container
            left={2}
            right={2}
            onClick={() => setActiveSearch(Math.max(1, activeSearch - 1))}
          >
            <img alt="" src='/Caret.svg' style={{ transform: "rotate(180deg)" }} />
          </Container>
          <Container
            left={2}
            right={2}
            onClick={() => {
              setSearchMode(false)
              setQuery("")
              onSearchEnd()
            }}
          >
            <img alt="" src='/scripts-close.svg' />
          </Container>
        </>
      )}
    </Container>
  )
}
