import { Clips } from "@guardian/API/Megatron";

interface ClipServiceGetClipsData {
  channels: string[]
  start?: string
  stop?: string
  since?: string
  includeClips: string[]
  withFlagStats: boolean
  withMetadata: boolean
};

interface ClipServiceRateClipData {
  clipId: string
  score: number
  comments: string
};

interface ClipServiceSetClipPlayedData {
  clipId: string
  position?: number
  playbackRate?: number
  startTimestamp?: number
  endTimestamp?: number
};

interface ClipServicePinClipData {
  clipId: string
};

interface ClipServiceUnpinClipData {
  clipId: string
};

interface ClipsGetMultiplayerlayerPinnedClipsParams {
  includeClips: string[]
};

interface ClipServiceMultiplayerPinClipData {
  clipId: string
  clipStatus: string
};

const ClipService = {
  getClips: (data: ClipServiceGetClipsData, options?: any) => {
    return new Promise((resolve, reject) => {
      Clips.getClips(data, options)
        .then(({ data: { clips: responseClips } }) => {
          const clips = responseClips || [];

          resolve(clips);
        })
        .catch(reject);
    });
  },

  rateClip: (data: ClipServiceRateClipData, options?: any) => {
    const { score, ...restData } = data;
    const formattedData = { score: score.toString(), ...restData };
    return new Promise((resolve, reject) => {
      return Clips.rateClip(formattedData, options)
        .then(resolve)
        .catch(reject);
    });
  },

  setClipPlayed: (data: ClipServiceSetClipPlayedData, options?: any) => {
    return new Promise((resolve, reject) => {
      return Clips.setClipPlayed(data, options)
        .then(resolve)
        .catch(reject);
    });
  },

  getPinnedClips: (options?: any) => {
    return new Promise((resolve, reject) => {
      Clips.getPinnedClips(options)
        .then(({ data: { clips: responseClips } }) => {
          const clips = responseClips || [];

          resolve(clips);
        })
        .catch(reject);
    });
  },

  pinClip: (data: ClipServicePinClipData, options?: any) => {
    return new Promise((resolve, reject) => {
      return Clips.pinClip(data, options)
        .then(resolve)
        .catch(reject);
    });
  },

  unpinClip: (data: ClipServiceUnpinClipData, options?: any) => {
    return new Promise((resolve, reject) => {
      return Clips.unpinClip(data, options)
        .then(resolve)
        .catch(reject);
    });
  },

  getMultiplayerPinnedClipsBatch: (data: ClipsGetMultiplayerlayerPinnedClipsParams, options?: any) => {
    return new Promise((resolve, reject) => {
      Clips.getMultiplayerPinnedClipsBatch(data, options)
        .then(({ data: { clips: responseClips } }) => {
          const clips = responseClips || [];

          resolve(clips);
        })
        .catch(reject);
    });
  },

  getMultiplayerPinnedClips: (rawParams?: ClipsGetMultiplayerlayerPinnedClipsParams, options?: any) => {
    return new Promise((resolve, reject) => {
      Clips.getMultiplayerPinnedClips(rawParams, options)
        .then(({ data: { clips: responseClips } }) => {
          const clips = responseClips || [];

          resolve(clips);
        })
        .catch(reject);
    });
  },

  multiplayerPinClip: (
    data: ClipServiceMultiplayerPinClipData,
    options?: any
  ) => {
    return new Promise((resolve, reject) => {
      return Clips.multiplayerPinClip(data, options)
        .then(resolve)
        .catch(reject);
    });
  }
};

export default ClipService;
