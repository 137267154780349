import classNames from "classnames";
import styled from "styled-components";

const AgentMessages = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__agent-messages"
      )
    }
  }
)`
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
`;

export default AgentMessages;
