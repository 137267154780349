import React from "react";

import StyledUserMessage from "./views/UserMessage";

const UserMessage = props => {
  const {
    message: {
      body: {
        text
      }
    }
  } = props;

  return (
    <StyledUserMessage>
      { text }
    </StyledUserMessage>
  );
};

export default React.memo(UserMessage);
