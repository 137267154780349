import React, { useContext, useMemo } from "react";

import { VirtualScroll } from "@guardian/UI/VirtualScroll";

import { DMTabContext } from "@guardian/Tabs/ModSOSDirectMessaging";

import QueueListItem from "./components/QueueListItem";
import StyledQueueList from "./views/QueueList";

const QueueList = () => {
  const {
    activeAgentChatSessionId,
    assignedAgentChatSessions
  } = useContext(DMTabContext);

  const sessions = useMemo(() => (
    assignedAgentChatSessions.filter(
      session => session.endedAt == null
    )
  ), [assignedAgentChatSessions]);

  // Estimated size for items used by virtualizer when guessing heights and
  // offsets for items prior to measuring them. In our case these items are
  // a static size, so this won't matter.
  const estimateSize = 72;

  // Keep this a little high. It "fixes" a bug where content may not get
  // adjusted to be visual when initially loading only a small amount of data.
  const overscanSize = 50;

  const getRowKey = (index) => {
    const session = sessions[index];
    return session.updateId;
  };

  const renderRow = (index) => {
    const session = sessions[index];

    return (
      <QueueListItem
        isActive={session.id === activeAgentChatSessionId}
        session={session}
      />
    );
  };

  return (
    <StyledQueueList>
      <VirtualScroll
        estimateSize={estimateSize}
        getRowKey={getRowKey}
        renderRow={renderRow}
        rowCount={sessions.length}
        overscanSize={overscanSize}
      />
    </StyledQueueList>
  );
};

export default QueueList;
