import React from "react"
import { TextField } from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { array, func } from "prop-types"
import classnames from "classnames"

import styles from "./DistributionType.module.css"
import { textfieldStyles, otherStyles } from "./GeographySelector.styles"

const CustomTextField = withStyles(textfieldStyles)(TextField)

const useStyles = makeStyles(otherStyles)

const GeographySelector = ({
  distributionValue,
  setDistributionValue,
  geoConfig,
}) => {
  const classes = useStyles()

  const handleOnChange = (_, values) => {
    setDistributionValue(values)
  }

  const removeValue = value => {
    const newVals = distributionValue.filter(geo => geo.value !== value)
    setDistributionValue(newVals)
  }

  return (
    <Autocomplete
      multiple
      classes={classes}
      options={geoConfig}
      disableCloseOnSelect
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.value === value.value}
      onChange={handleOnChange}
      limitTags={2}
      size='medium'
      renderOption={(option, { selected }) => <div>{option.name}</div>}
      renderTags={(value, _) => {
        return value.map(v => (
          <div key={v.value} className={styles.chip}>
            {v.name !== "Out of Service Areas" ? v.name.split(": ")[1] : v.name}
            <i
              onClick={() => removeValue(v.value)}
              className={classnames("fas fa-times", styles.clearIcon)}
            ></i>
          </div>
        ))
      }}
      value={distributionValue}
      renderInput={params => (
        <CustomTextField {...params} variant='outlined' label='Geography' />
      )}
    />
  )
}

GeographySelector.propTypes = {
  distributionValue: array,
  setDistributionValue: func,
  geoConfig: array,
}

export default GeographySelector
