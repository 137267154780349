import classnames from "classnames"
import { any, bool, func, number, string } from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

import { eventHub } from "@guardian/State"

import { event } from "@guardian/Analytics"
import Events from "@guardian/Analytics/events"
import { ClipService } from "@guardian/Services/Radio"

import ProgressSpectrogram from "@guardian/Components/ProgressSpectrogram"
import Sound from "@guardian/Components/Sound"

import styles from "./PlayBar.module.css"
import "./PlayBar.scss"

const handleOnStalled = () => {
  eventHub.emit("show error banner", new Error("Slow Network Connections"))
}

const handleOnLoadedData = () => {
  eventHub.emit("clear slow network")
}

const handleOnNextClip = e => {
  e.preventDefault()
  e.stopPropagation()
  event({
    category: Events.CATEGORY.INCIDENT_CREATION,
    action: Events.ACTION.NEXT_CLIP,
    label: Events.LABEL.BUTTON,
  })
  eventHub.emit("nextClip", { scrollOnlyIfVis: true })
}

const PlayBar = ({
  showAttach,
  attachOn,
  id,
  status,
  activeClipStatus,
  activeClipAnimateStatus,
  position,
  speed,
  wavUrl,
  handlePlaying,
  handleFinishedPlaying,
  showUpArrow,
  activeClipId,
  className,
  trackPlays,
  spectrogram,
  seek,
  durationMs,
  spectroRef,
}) => {
  const startRef = useRef(null)

  useEffect(() => {
    if (
      trackPlays &&
      startRef.current &&
      (status === Sound.status.STOPPED || status === Sound.status.PAUSED)
    ) {
      ClipService.setClipPlayed({
        clipId: id,
        playbackRate: speed,
        startTimestamp: startRef.current,
        endTimestamp: Date.now(),
        position: position,
      })
    }
  }, [status, id, speed, trackPlays])

  const handleAttachClip = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      event({
        category: Events.CATEGORY.INCIDENT_CREATION,
        action: Events.ACTION.ATTACH_CLIP,
        label: Events.LABEL.BUTTON,
      })
      eventHub.emit("toggle attach clip", id)
    },
    [id],
  )

  const handleUpdateStartRef = useCallback(() => {
    startRef.current = Date.now()
  }, [])

  const attach = !!showAttach && (
    <div className='btn' onClick={handleAttachClip}>
      <i
        className={classnames("fas fa-plus-circle", { "btn-active": attachOn })}
      />
    </div>
  )

  const upArrow = !!showUpArrow && (
    <div
      className={classnames("btn", styles.upArrow)}
      onClick={handleOnNextClip}
    >
      <i className={`fas fa-arrow-circle-up`} />
    </div>
  )

  return (
    <div className={classnames("clip-player", className)}>
      <ProgressSpectrogram
        width={408}
        spectroRef={spectroRef}
        spectrogram={spectrogram}
        seek={seek}
        position={position}
        durationMs={durationMs}
        status={status}
      />
      <div className='clip-controls'>
        {upArrow}
        {attach}
      </div>
      {activeClipAnimateStatus && activeClipStatus === Sound.status.PLAYING && (
        <div className='fading-icon'>
          <i className='fas fa-play' />
        </div>
      )}
      {activeClipAnimateStatus && activeClipStatus === Sound.status.PAUSED && (
        <div className='fading-icon'>
          <i className='fas fa-pause' />
        </div>
      )}
      {activeClipId === id && (
        <Sound
          url={"https://" + wavUrl}
          playStatus={status}
          position={position}
          playbackRate={speed || 1}
          onPlaying={handlePlaying}
          onFinishedPlaying={handleFinishedPlaying}
          onPlay={handleUpdateStartRef}
          onStalled={handleOnStalled}
          onLoadedData={handleOnLoadedData}
        />
      )}
    </div>
  )
}

PlayBar.defaultProps = {
  trackPlays: true,
}

PlayBar.propTypes = {
  showAttach: bool,
  attachOn: bool,
  id: string,
  status: string,
  activeClipStatus: string,
  activeClipAnimateStatus: bool,
  position: number,
  speed: number,
  wavUrl: string,
  handlePlaying: func,
  handleFinishedPlaying: func,
  showUpArrow: bool,
  activeClipId: string,
  className: string,
  trackPlays: bool,
  spectrogram: any,
  seek: func,
  durationMs: number,
  spectroRef: any,
}

export default PlayBar
