import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import React, { useState } from "react"
import styles from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsButtonExpandable/AgentScriptsButtonExpandable.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsButtonExpandable/types"

export const AgentScriptsButtonExpandable = ({
  onExpand,
  agentScriptsShown,
  onClose,
}: IProps) => {
  const [hovered, setHovered] = useState(false)

  const handleMouseEnter = () => setHovered(true)

  const handleMouseLeave = () => setHovered(false)

  return (
    <button
      className={styles.agentScriptsButton}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={agentScriptsShown ? onClose : onExpand}
    >
      <Container top={1} bottom={1} left={2} right={2}>
        <Spacing right={2}>
          {agentScriptsShown ? "Restore Agent Scripts Panel" : "Agent Scripts"}
        </Spacing>
        <img
          alt='expand'
          src={hovered ? "/expand-active.svg" : "/expand.svg"}
        />
      </Container>
    </button>
  )
}
