import React, { useMemo } from "react"
import { Map } from "@guardian/UI/Map"
import { AgentChat } from "@guardian/Lib/QueryClient/hooks"

const MapTab = props => {
  const {
    session: { id: sessionId },
  } = props

  const { data: records } = AgentChat.useGetAssignedChatSessionUserRecords(
    sessionId,
    {
      refetchInterval: 5000,
    },
  )

  const { data: safetyFeed, refetch: refetchSafetyFeed } = AgentChat.useGetSafetyFeed(sessionId)

  const mostRecentRecord = useMemo(() => {
    if (!records || records.length == 0) {
      return null
    }

    return records[0]
  }, [records])

  const location = useMemo(() => {
    if (!mostRecentRecord || !mostRecentRecord.location) {
      return null
    }

    refetchSafetyFeed()

    return {
      lat: mostRecentRecord.location.latitude,
      lng: mostRecentRecord.location.longitude,
    }
  }, [mostRecentRecord])

  const recordsToRouteData = () => {
    if (!records) {
      return []
    }

    return records
      .filter(record => Boolean(record.location))
      .map(record => {
      return {
        lat: record.location.latitude,
        lng: record.location.longitude,
      }
    })
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          background: "black",
          display: "flex",
          flexBasis: "0",
          flexGrow: "1",
          justifyContent: "center",
          maxHeight: "100%",
        }}
      >
        <Map
          defaultCenter={location}
          defaultZoom={16}
          zoom={16}
          draggable
          allowControls={false}
          streetView={false}
          routeData={recordsToRouteData()}
          incidents={safetyFeed?.nearbyIncidents}
          pointOfInterests={safetyFeed?.pois}
        />
      </div>
    </div>
  )
}

export default MapTab
