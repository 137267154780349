import classNames from "classnames";
import styled from "styled-components";

const MessageGroups = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__message-groups"
      )
    }
  }
)`
  height: 100%;
  width: 100%;
`;

export default MessageGroups;
