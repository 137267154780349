import { useEffect } from "react"

import { eventHub } from "@guardian/State"

import { AuditToolContextProvider } from "./store"
import Dashboard from "./Dashboard"

const AuditTool = () => {
  useEffect(() => {
    eventHub.emit("stopUpdatingClips")
    eventHub.emit("stopUpdatingModIncidents")
    eventHub.emit("bindClipKeys")

    return () => {
      eventHub.emit("unbindKeys")
    }
  }, [])

  return (
    <AuditToolContextProvider>
      <Dashboard />
    </AuditToolContextProvider>
  )
}
export default AuditTool
