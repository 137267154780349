import {
  getProtectSession,
  getProtectSessionOwner,
  getProtectSessionRecords,
} from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { getWMBPayload, getWMBUserInfo } from "@guardian/API/Optimus/resources/legacy/resources/WMB"
import { noop } from "lodash"
import React, { useContext, useEffect, useReducer } from "react"
import { interval, Observable, Subscription } from "rxjs"
import { isV2Protect } from "@guardian/Utils/protect"
import { reducer } from "@guardian/Components/ModSOS/store/dashboard/reducers/dashboard.reducers"
import {
  resetDashboardContext,
  updateActiveSessionId,
  updateOwnerData,
  updateSessionRecords,
  updateSessionResponse,
} from "@guardian/Components/ModSOS/store/dashboard/actions/dashboard"
import {
  IAction,
  IResponse,
  IState,
} from "@guardian/Components/ModSOS/store/dashboard/types"

interface IProps {
  children: any
  activeSessionId?: string
}

const DashboardContext = React.createContext<{
  state: IState
  dispatch: (action: IAction) => void
}>({
  state: { activeSessionId: "loading", isV2Protect: false },
  dispatch: noop,
})

interface IRequest {
  activeSessionId: string
  v2Endpoint: (activeSessionId: string) => Observable<any>
  endpoint: (activeSessionId: string) => Promise<any>
  errorMsg: string
}

const makeRequest = async ({
  activeSessionId,
  v2Endpoint,
  endpoint,
  errorMsg,
}: IRequest): Promise<IResponse> => {
  let resp

  if (isV2Protect(activeSessionId)) {
    try {
      resp = await v2Endpoint(activeSessionId)
        .toPromise()
        .catch(e => {
          throw e
        })
    } catch (e: any) {
      return {
        data: undefined,
        error: e.message || errorMsg,
      }
    }
  } else {
    resp = await endpoint(activeSessionId)
  }

  return {
    data: resp ? resp.data : undefined,
    error: !resp || !resp.data ? errorMsg : undefined,
  }
}

export const SOSDashboardContextProvider = ({
  children,
  activeSessionId,
}: IProps) => {
  const [state, dispatch] = useReducer(reducer, {
    activeSessionId,
    isV2Protect: activeSessionId && isV2Protect(activeSessionId),
  })

  const SOSDashboardContextStore = {
    state,
    dispatch,
  }

  const updateResponses = async (id: string) => {
    dispatch(
      updateSessionResponse(
        await makeRequest({
          activeSessionId: id,
          v2Endpoint: getProtectSession,
          endpoint: getWMBPayload,
          errorMsg: "Unable to get response from GET Session Payload",
        }),
      ),
    )
  }

  const getRecords = async (id: string) => {
    dispatch(
      updateSessionRecords(
        await makeRequest({
          activeSessionId: id,
          v2Endpoint: getProtectSessionRecords,
          endpoint: getWMBPayload,
          errorMsg: "Unable to get response from GET Session Records Payload",
        }),
      ),
    )
  }

  const getOwnerData = async (id: string) => {
    dispatch(
      updateOwnerData(
        await makeRequest({
          activeSessionId: id,
          v2Endpoint: getProtectSessionOwner,
          endpoint: getWMBUserInfo,
          errorMsg: "Unable to get response from GET Owner Payload",
        }),
      ),
    )
  }

  useEffect(() => {
    let recordsSubscription: Subscription
    let ownerDataSubscription: Subscription
    if (activeSessionId) {
      dispatch(updateActiveSessionId(activeSessionId))

      updateResponses(activeSessionId)
      recordsSubscription = interval(5000).subscribe(() => {
        getRecords(activeSessionId)
      })

      ownerDataSubscription = interval(3000).subscribe(() => {
        getOwnerData(activeSessionId)
      })
    } else {
      dispatch(resetDashboardContext())
    }

    return () => {
      if (recordsSubscription) {
        recordsSubscription.unsubscribe()
      }

      if (ownerDataSubscription) {
        ownerDataSubscription.unsubscribe()
      }
    }
  }, [activeSessionId])

  return (
    <DashboardContext.Provider value={SOSDashboardContextStore}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useSOSDashboardContext = () => useContext(DashboardContext)
