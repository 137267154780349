import classNames from "classnames";
import styled from "styled-components";

import { XSmallText as GenericXSmallText } from "@guardian/UI/Typography";

const TimeAndPlaceStatus = styled(GenericXSmallText).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__time-and-place-status"
      )
    };
  }
)`
  color: var(--colors-grey-030);
`;

TimeAndPlaceStatus.propTypes = {};

TimeAndPlaceStatus.defaultProps = {
  as: "span"
};

export default TimeAndPlaceStatus;
