import { createContext, useContext } from "react";

export const FieldContext = createContext(undefined);

// Retrieve Field component context
const useFieldContext = () => {
  const fieldContext = useContext(FieldContext);

  if (!fieldContext) {
    throw new Error("This component must be rendered within a `Field` component.");
  }

  return fieldContext;
};

export default useFieldContext;
