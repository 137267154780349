import { getDistance, isPointInside } from "geolib"
import _isEmpty from "lodash/isEmpty"
import _snakeCase from "lodash/snakeCase"

import { hasAuthor } from "@guardian/Utils/incident"
import { pointInPrecinct } from "@guardian/Utils/precinct"

import { getIncidentCategory } from "./util"

export function pointInServiceArea(point, sa) {
  const multipoly = sa.area
  return multipoly.some(poly =>
    isPointInside(
      { latitude: point[1], longitude: point[0] },
      poly[0].map(([longitude, latitude]) => ({ latitude, longitude })),
    ),
  )
}

const DISTANCE_THRESHOLD = 804 // half mile
export const VideoRequired = 1
export const ConfirmedVideoRequired = 2
export const NeedsThumbnail = 3

export const Boosted = 1
export const NotBoosted = 2

export const Assigned = 1
export const NotAssigned = 2

export const Statuses = ["New", "Open", "Closed"]

export const SortOptions = {
  ts: "Most Recent",
  streamsConfirmed: "Verified Streams",
  emojis: "All Emojis",
  usersNotifiedUnique: "Users Notified",
  chats: "comments",
  flaggedAt: "Flagged At",
  streamsCount: "Stream Count",
}

export function latLngToGeoLibLatLong(p) {
  if (Array.isArray(p)) {
    return { latitude: p[1], longitude: p[0] }
  }
  return { latitude: p.lat, longitude: p.lng }
}

export function isNearby(p1, p2, threshold = DISTANCE_THRESHOLD) {
  return (
    getDistance(latLngToGeoLibLatLong(p1), latLngToGeoLibLatLong(p2)) <
    threshold
  )
}

function passLocChecks(inc, filter, serviceAreaCfg) {
  if (
    filter.precinct &&
    filter.serviceArea &&
    filter.address &&
    filter.precinct.length === 0 &&
    filter.serviceArea.length === 0 &&
    filter.address.length === 0
  ) {
    return true
  }

  const incPoint = [inc.location.lng, inc.location.lat]
  if (
    filter.precinct &&
    filter.precinct.length > 0 &&
    filter.precinct.some(pre => pointInPrecinct(incPoint, pre))
  ) {
    return true
  }

  if (
    filter.serviceArea &&
    filter.serviceArea.length > 0 &&
    filter.serviceArea.some(sa =>
      pointInServiceArea(incPoint, serviceAreaCfg[sa]),
    )
  ) {
    return true
  }

  if (
    filter.address &&
    filter.address.length > 0 &&
    filter.address.some(pt => isNearby(incPoint, pt.location))
  ) {
    return true
  }
}

// Is the incident located in any of the service areas you're currently listening to?
function isInActiveServiceArea(inc, serviceAreaCfg, activeServiceAreas) {
  const sasFromChannels = activeServiceAreas
    .map(name => serviceAreaCfg[name])
    .filter(item => !!item)
  return sasFromChannels.find(
    sa => sa.cityCode.toLowerCase() === inc.location.cityCode.toLowerCase(),
  )
}

export function passFilter(
  inc,
  filter,
  user,
  serviceAreaCfg,
  activeServiceAreas,
  selectedInc,
  allowedPilotProgram,
) {
  if (inc.id === selectedInc) {
    return true
  }

  if (!allowedPilotProgram && inc.location.cityCode === "us-nv-lasvegas") {
    return false
  }

  // News Team filtering
  if (filter.boosted === Boosted && !inc.boosted) {
    return false
  } else if (filter.boosted === NotBoosted && inc.boosted) {
    return false
  }
  if (filter.assigned === Assigned && !inc.newsAssigneeId) {
    return false
  } else if (filter.assigned === NotAssigned && inc.newsAssigneeId) {
    return false
  }
  if (
    Statuses.some(
      s =>
        filter[`${s.toLowerCase()}Status`] &&
        inc.newsStatus !== s.toLowerCase(),
    )
  ) {
    return false
  }

  const categories = getIncidentCategory(inc)
  if (
    filter.tag &&
    filter.tag.length > 0 &&
    !filter.tag.includes(_snakeCase(categories))
  ) {
    return false
  }

  if (
    filter.text &&
    !inc.title.toLowerCase().includes(filter.text.toLowerCase()) &&
    !inc.location.address.toLowerCase().includes(filter.text.toLowerCase())
  ) {
    return false
  }

  if (filter.escalated && !inc.escalated) {
    return false
  }
  if (filter.from === "mine" && !hasAuthor(inc, user.username)) {
    return false
  } else if (filter.from === "flagged" && !inc.flagged) {
    return false
  } else if (filter.from === "activeServiceArea") {
    if (!isInActiveServiceArea(inc, serviceAreaCfg, activeServiceAreas)) {
      return false
    }
  }

  if (
    filter.level &&
    filter.level.length > 0 &&
    !filter.level.includes(inc.level)
  ) {
    return false
  }

  if (!passLocChecks(inc, filter, serviceAreaCfg)) {
    return false
  }

  if (filter.videoRequired >= VideoRequired) {
    if (_isEmpty(inc.liveStreamers)) {
      return false
    }
    if (
      filter.videoRequired === ConfirmedVideoRequired &&
      !inc.liveStreamers.some(vs => vs.confirmed)
    ) {
      return false
    }

    const thumbnail = inc.featuredStreamImage || inc.preferredStreamImage
    if (filter.videoRequired === NeedsThumbnail && thumbnail) {
      return false
    }
  }

  if (
    filter.author &&
    filter.author.length > 0 &&
    !filter.author.some(auth => hasAuthor(inc, auth))
  ) {
    return false
  }

  return true
}
