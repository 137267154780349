import React from "react"
import styles from "@guardian/Components/ModSOS/components/Map/AddressModal/AddressModal.module.css"
import classnames from "classnames"
import { IProps } from "@guardian/Components/ModSOS/components/Map/AddressModal/types"

const AddressModalRaw: React.FunctionComponent<IProps> = ({
  address,
  city,
  lat,
  lng,
  hasLocPermissions,
}) => (
  <div className={styles.modal}>
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${address},${city}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      {hasLocPermissions ? (
        <div>
          <p className={classnames(styles.noMargin, styles.address)}>
            {address}
          </p>
          <p className={classnames(styles.noMargin, styles.city)}>{city}</p>
        </div>
      ) : (
        <div>
          <p className={classnames(styles.noMargin, styles.address)}>
            User didn't enable location services.
          </p>
        </div>
      )}
    </a>
    {hasLocPermissions && (
      <a
        href={`https://what3words.com/${lat},${lng}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <p className={classnames(styles.noMargin, styles.latlng)}>
          ({lat.toFixed(5)}, {lng.toFixed(5)})
        </p>
      </a>
    )}
  </div>
)

export const AddressModal = React.memo(AddressModalRaw)
