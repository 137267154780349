import React from "react";

import useFieldContext from "../hooks/useFieldContext";
import StyledInput from "../views/Input";

const Input = React.forwardRef((props, ref) => {
  const fieldContext = useFieldContext();

  let combinedProps = {
    ref,
    ...props
  };

  if (fieldContext) {
    combinedProps = fieldContext.getInputProps(
      combinedProps,
      { isDescribed: true }
    );
  }

  return (
    <StyledInput ref={ref} {...combinedProps}/>
  );
});

export default Input;
