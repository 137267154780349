import React from "react"
import style from "./InfoPane.module.css"
import { string, bool, func, object, array } from "prop-types"

import VideoContent from "./VideoContent"
import PhotoContent from "./PhotoContent"
import MapContent from "./MapContent"

const InfoPane = ({
  id,
  onReject,
  onConfirm,
  confirmed,
  onBlock,
  onVerified,
  block,
  verified,
  prompt911,
  onPrompt911,
  content,
  log,
}) => {
  const label = content.type === "video_stream" ? "Video" : "Image"
  return (
    <div className={style.pane}>
      <h4>{label} Details</h4>
      <div className={style.subpanel}>
        {content.type === "video_stream" && (
          <VideoContent
            id={id}
            onReject={onReject}
            onConfirm={onConfirm}
            confirmed={confirmed}
            onBlock={onBlock}
            onVerified={onVerified}
            block={block}
            verified={verified}
            prompt911={prompt911}
            onPrompt911={onPrompt911}
            content={content}
          />
        )}
        {content.type === "image" && (
          <PhotoContent
            id={id}
            onReject={onReject}
            onConfirm={onConfirm}
            confirmed={confirmed}
            onBlock={onBlock}
            onVerified={onVerified}
            block={block}
            verified={verified}
            prompt911={prompt911}
            onPrompt911={onPrompt911}
            content={content}
          />
        )}
      </div>
      <MapContent log={log} />
    </div>
  )
}

InfoPane.propTypes = {
  id: string,
  onReject: func,
  onConfirm: func,
  confirmed: bool,
  onBlock: func,
  onVerified: func,
  block: bool,
  verified: bool,
  prompt911: bool,
  onPrompt911: func,
  content: object,
  log: array,
}

export default InfoPane
