import { useRef, useSyncExternalStore } from "react"
import { isEqual } from "lodash"

import globalState from "./state"

export const eventHub = globalState.getEventHub()

export function useGlobalState<T>(selector: (state: any) => T): T {
  const prevValueRef = useRef<T>()

  return useSyncExternalStore(
    callback => {
      globalState.on("update", callback)
      return () => globalState.off("update", callback)
    },
    () => {
      const selectedValue = selector(globalState.get())
      if (!isEqual(selectedValue, prevValueRef.current)) {
        prevValueRef.current = selectedValue
      }
      return prevValueRef.current as T
    },
  )
}
