import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const MenuHeader = styled.li.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "dropdown__menu-header")
    };
  }
)`
  color: var(--colors-grey-040);
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-small);
  padding: var(--space-xx-small) var(--space-medium);
`;

MenuHeader.defaultProps = {};

export default MenuHeader;
