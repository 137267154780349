import React, { useState, useEffect, useCallback } from "react"
import { useParams } from "react-router-dom"
import styles from "@guardian/Components/ModSOS/ModSOS.module.css"
import { CallQueue } from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue"
import { SOSDashboard } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard"
import { SOSGeneralActionPane } from "@guardian/Components/ModSOS/components/GeneralActionPane/SOSGeneralActionPane/SOSGeneralActionPane"
import SOSSessionContext from "@guardian/Components/Contexts/SOSSessionContext"
import { SOSDashboardContextProvider } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { webSockets } from "@guardian/Sockets";

const socket = webSockets

const ModSOS: React.FunctionComponent = (props) => {
  const [userDisconnected, setUserDisconnected] = useState(false)
  const [videoDeviceId, setVideoDeviceId] = useState("")

  // @ts-ignore
  const { id: activeSessionId } = useParams();

  useEffect(() => {
    setUserDisconnected(false)
  }, [activeSessionId])

  const onUserDisconnected: (sessionId: string) => void = useCallback(
    sessionId => {
      if (sessionId !== activeSessionId) return
      setUserDisconnected(true)
    },
    [activeSessionId],
  )

  const onVideoDeviceSelect: (deviceId: string) => void = deviceId => {
    setVideoDeviceId(deviceId)
  }

  return (
    <SOSSessionContext.Provider value={userDisconnected}>
      <SOSDashboardContextProvider activeSessionId={activeSessionId}>
        <div className={styles.container}>
          <CallQueue
            socket={socket}
            activeSessionId={activeSessionId}
            onUserDisconnected={onUserDisconnected}
            onVideoSelect={onVideoDeviceSelect}
          />
          {!!activeSessionId ? (
            <SOSDashboard videoDeviceId={videoDeviceId} />
          ) : (
            <SOSGeneralActionPane />
          )}
        </div>
      </SOSDashboardContextProvider>
    </SOSSessionContext.Provider>
  )
}

export default React.memo(ModSOS)
