import classNames from "classnames";
import styled from "styled-components";

const Avatar = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "avatar")
    }
  }
)`
  align-items: center;
  background: var(--colors-grey-090);
  border-radius: var(--radii-circle);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  height: 100%;
  width: 100%;

  ${ ({ size }) => size && `
    height: ${ size };
    width: ${ size };
  `}
`;

export default Avatar;
