import classNames from "classnames";
import styled from "styled-components";

const Body = styled.div.attrs(
  props => {
    const { className } = props;
    return {
      className: classNames(className, "alert__dialog-message__body")
    };
  }
)`
  font-size: var(--font-sizes-x-small);
  line-height: var(--line-heights-x-small);
  padding: 0 var(--space-small) var(--space-small);
`;

Body.propTypes = {};

Body.defaultProps = {};

export default Body;
