import styled from "styled-components";

const NoSelectionMessage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // Keep this unless copy changes or layout changes necessitate. It keeps our
  // toggle states from moving around vertically when they swap, and in the
  // current form is the right size for the content we are dealing with.
  // Essentially, the circle containing the toggled state icons should always
  // sit in the same spot on screen, each the same.
  height: 246px;
  justify-content: flex-start;
`;

export default NoSelectionMessage;

