import React from "react"
import classnames from "classnames"

import style from "./Checkbox.module.scss"
export interface CheckboxProps {
  active: boolean
  handleClick: React.MouseEventHandler<HTMLDivElement>
  blue?: boolean
  disabled?: boolean
  bold?: boolean
  highContrast?: boolean
}

export const Checkbox = ({
  active,
  handleClick,
  blue,
  disabled,
  bold,
  highContrast,
}: CheckboxProps) => {
  return (
    <div
      className={classnames(style.box, {
        [style.active]: active,
        [style.blueActive]: active && blue,
        [style.bold]: bold,
        [style.highContrast]: highContrast,
      })}
      onClick={disabled ? undefined : handleClick}
    >
      {active && (
        <svg
          width='14'
          height='11'
          viewBox='0 0 14 11'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1 4.69231L5.2 9L13 1'
            stroke={blue ? "#4DA6FF" : "white"}
            strokeWidth='2'
          />
        </svg>
      )}
    </div>
  )
}

export default Checkbox
