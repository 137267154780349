import classNames from "classnames";
import styled from "styled-components";

const SystemMessageBody = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__system-message-body"
      )
    }
  }
)`
  align-items: center;
  background: var(--colors-transparent);
  color: var(--colors-grey-060);
  display: flex;
  flex-direction: row;
  font-size: var(--font-sizes-small);
  justify-content: center;
  line-height: var(--line-heights-small);
  position: relative;
  width: 100%;

  &:before, &:after{
    box-shadow: var(--borders-outer-bottom) var(--colors-grey-060);
    content: "";
    flex: 1 1;
    height: 1px;
    margin: auto;
  }
  &:before {
    margin-right: var(--space-x-small);
  }
  &:after {
    margin-left: var(--space-x-small);
  }
`;

export default SystemMessageBody;
