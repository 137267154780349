export const stringToAnyVibrantColor = (str="") => {
  // Convert input string into hash.
  let hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Use hash as a decimal (for example, -1212121212 becomes .1212121212) and
  // use it as an adjustment to our hue range. There is probably a better way to
  // do this than the string conversion and parseFloat but my brain isnt
  // functioning.
  const hue = 360 * parseFloat(`.${Math.abs(hash)}`);
  // Saturation 100 and lightness 72 keeps the generated color within the tonal
  // range we like for these colors.
  let saturation = 100;
  let lightness = 72;

  // Boost colors within the blue range to be brighter, in order to account for
  // the eye's sensitivity to blue and black text on a blue background.
  if(hue > 215 && hue < 265) {
    const gain = 20;
    let blueness = 1 - Math.abs(hue - 240) / 25;
    let change  = Math.floor(gain * blueness);
    lightness += change;
    saturation -= change;
  }

  const hsl = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return hsl;
};

export const stringToThemeColor = (str) => {
  // All colors.
  const colors = [
    "var(--colors-blue-005)",
    "var(--colors-blue-010)",
    "var(--colors-blue-015)",
    "var(--colors-blue-020)",
    "var(--colors-blue-030)",
    "var(--colors-green-005)",
    "var(--colors-green-010)",
    "var(--colors-green-015)",
    "var(--colors-green-020)",
    "var(--colors-green-030)",
    "var(--colors-red-005)",
    "var(--colors-red-010)",
    "var(--colors-red-015)",
    "var(--colors-red-020)",
    "var(--colors-red-030)",
    "var(--colors-yellow-005)",
    "var(--colors-yellow-010)",
    "var(--colors-yellow-015)",
    "var(--colors-yellow-020)",
    "var(--colors-yellow-030)",
  ];

  // Convert input string into hash.
  let hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use hash as a decimal (for example, -1212121212 becomes .1212121212) and
  // use it as an adjustment to our hue range. There is probably a better way to
  // do this than the string conversion and parseFloat but my brain isnt
  // functioning.
  const indexModifier = parseFloat(`.${Math.abs(hash)}`);
  const index = Math.round((colors.length - 1) * indexModifier);
  const color = colors[index];

  return color;
};
