import { OutlinedInput } from "@material-ui/core"
import { styled } from "@mui/material/styles"
import dayjs from "dayjs"
import { useEffect, useReducer } from "react"
import { v1 as uuidv1 } from "uuid"

import { useGlobalState } from "@guardian/State"
import type { VideoStream } from "@guardian/Types/Video"
import { objPick } from "@guardian/Utils/obj-pick"

import { DirectMessages } from "@guardian/API/Optimus"
import { useInterval } from "@guardian/Hooks/useInterval"
import { webSockets } from "@guardian/Sockets"

import { Text } from "@guardian/UI/Legacy/Text"

import style from "./ModBroadcasterCommunication.module.css"
import reducer, {
  ModBroadcasterActionType,
} from "./ModBroadcasterCommunication.reducer"
import ModMessage from "./ModMessage"
import { getAutomatedMessages } from "./consts"
import { Message } from "./types"

interface IProps {
  videoStream: VideoStream
}

const StyledMultiline = styled(OutlinedInput)`
  color: white;
  padding-top: 16px;
  width: 100%;
  :hover {
    color: white;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
    color: white;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
  }

  & legend {
    visibility: visible;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #8c8c8c;
  }
`

const constructMessage = (message: any, vsDate: string): Message => {
  const messageDate = message.createdAt
  const secondsSinceStart = dayjs(messageDate).diff(vsDate, "seconds")
  return {
    text: message.body.text,
    sender: message.authorId,
    secondsSinceStart: secondsSinceStart,
  }
}

const submitMessage = (text: string, groupId: string) => {
  const data = {
    id: uuidv1(),
    groupId: groupId,
    body: {
      text: text,
      type: "moderatorBroadcaster"
    }
  }

  DirectMessages.sendGroupDMMessage(data)
}

const ModBroadcasterCommunication = ({ videoStream }: IProps) => {
  const modMessages = useGlobalState(state =>
    objPick(state.global.modBroadcasterConfig?.modMessages || {}, [
      "nowLiveMessage",
      "automatedModMessage",
    ]),
  )

  const [state, dispatch] = useReducer(reducer, {
    messages: [],
    inputValue: "",
  })

  const handleNewMessage = (e: any) => {
    if (e.payload.value.type === "moderatorBroadcaster") {
      const newMessage = constructMessage(
        e.payload.value.value,
        videoStream.createdAt,
      )
      dispatch({
        type: ModBroadcasterActionType.AddMessage,
        data: { message: newMessage },
      })
    }
  }

  useInterval(() => {
    if (state.inputValue) {
      webSockets.sendMessage({
        method: "sendIsTyping",
        value: {
          recipientId: videoStream.userId,
          source: "moderatorBroadcaster",
        },
      })
    }
  }, 2000)

  useEffect(() => {
    // Fetch any messages that may have been sent already
    const fetchMessages = async () => {
      const { data } = await DirectMessages.getGroupDMs(videoStream.modGroupId)
      const history = data.history
      const newMessages: Message[] = []
      history.forEach((message: any) => {
        const newMessage = constructMessage(message, videoStream.createdAt)
        newMessages.unshift(newMessage)
      })
      dispatch({
        type: ModBroadcasterActionType.SetMessages,
        data: {
          messages: [
            ...getAutomatedMessages(videoStream.username, modMessages),
            ...newMessages,
          ],
        },
      })
    }
    fetchMessages()

    // Subscribe to socket to get any new messages
    const socketSub = {
      method: "subscribeDirectMessage",
      value: { groupId: videoStream.modGroupId },
    }
    webSockets.sub(socketSub)
    webSockets.onEvent("dmEvent", handleNewMessage)

    // Clean up socket subscription
    return () => {
      webSockets.removeEvent("dmEvent", handleNewMessage)
      const unsubReq = {
        method: "subscribeDirectMessage",
        value: {
          groupId: videoStream.modGroupId,
        },
      }
      webSockets.removeSub(socketSub, unsubReq)
    }
  }, [videoStream.id, videoStream.modGroupId, modMessages])

  const reversed = [...state.messages].reverse()
  return (
    <div className={style.container}>
      <div className={style.messageContainer}>
        {reversed.map(message => (
          <ModMessage {...message} />
        ))}
      </div>
      <StyledMultiline
        multiline
        type='text'
        value={state.inputValue}
        onChange={e =>
          dispatch({
            type: ModBroadcasterActionType.SetInputValue,
            data: { inputValue: e.target.value },
          })
        }
        disabled={false}
        onKeyDown={async e => {
          e.stopPropagation()
          if (e.key === "Enter") {
            e.preventDefault()
            submitMessage(state.inputValue, videoStream.modGroupId)
            dispatch({
              type: ModBroadcasterActionType.SetInputValue,
              data: { inputValue: "" },
            })
          }
        }}
        label='Type a message'
        notched
      />
      <Text size='xs' color='grey'>
        {state.inputValue.length} / 120 suggested character limit
      </Text>
    </div>
  )
}

export default ModBroadcasterCommunication
