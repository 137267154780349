import classnames from "classnames"
import { func, object } from "prop-types"
import { useCallback, useEffect, useState } from "react"

import { useGlobalState } from "@guardian/State"

import combokeys from "@guardian/Utils/hotkeys"

import ConfirmModal from "@guardian/Components/Modals/ConfirmModal"

import style from "./LiveTabVideoControls.module.css"

const LiveTabVideoControls = ({
  stream,
  streamHandled,
  toggleRejectionMenu,
  completeVideoStream,
}) => {
  const readOnly = useGlobalState(state => state.global.readOnly)

  const [confirmProps, setConfirmProps] = useState(null)

  const onCompleteVideoStream = useCallback(() => {
    if (readOnly || !completeVideoStream) {
      return
    }
    setConfirmProps({
      text: `Are you sure you want to stop this broadcast?`,
      onConfirm: async () => {
        try {
          await completeVideoStream(stream)
        } finally {
          setConfirmProps(null)
        }
      },
      onCancel: () => setConfirmProps(null),
    })
  }, [readOnly, completeVideoStream, stream])

  useEffect(() => {
    combokeys.bind("b", toggleRejectionMenu)
    combokeys.bind("r", toggleRejectionMenu)
    combokeys.bind("s", onCompleteVideoStream)

    return () => {
      combokeys.unbind("b")
      combokeys.unbind("r")
      combokeys.unbind("s")
    }
  }, [toggleRejectionMenu, stream, streamHandled])

  const hasIncident = !!stream.incidentId

  return (
    <>
      <div className={style.actions}>
        <button
          onClick={!readOnly && toggleRejectionMenu}
          className={classnames(style.roundButton, style.rejectButton)}
          disabled={readOnly}
        >
          {hasIncident ? "Block (b)" : "Reject (r)"}
        </button>
        {!readOnly && !!completeVideoStream && (
          <button
            onClick={onCompleteVideoStream}
            className={classnames(style.roundButton, style.stopButton)}
            disabled={readOnly}
          >
            Stop (s)
          </button>
        )}
        {confirmProps && <ConfirmModal {...confirmProps} />}
      </div>
    </>
  )
}

LiveTabVideoControls.propTypes = {
  stream: object,
  streamHandled: func,
  toggleRejectionMenu: func,
  completeVideoStream: func,
}

export default LiveTabVideoControls
