import React, { useCallback, useContext, useMemo, useReducer } from "react"

import reducer, {
  initialState,
  toggleMergingIncident as toggleMergingIncidentAction,
  updateLocation as updateLocationAction,
  updateProperty as updatePropertyAction,
} from "./IncidentCreation.reducer"

const IncidentCreation = React.createContext({
  state: initialState,
  toggleMergingIncident: data => {},
  updateLocation: data => {},
  updateProperty: (property, value) => {},
})

export const IncidentCreationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const toggleMergingIncident = useCallback(
    data => dispatch(toggleMergingIncidentAction(data)),
    [dispatch],
  )
  const updateLocation = useCallback(
    data => dispatch(updateLocationAction(data)),
    [dispatch],
  )
  const updateProperty = useCallback(
    (property, value) => dispatch(updatePropertyAction(property, value)),
    [dispatch],
  )

  const IncidentCreationStore = useMemo(
    () => ({
      state,
      toggleMergingIncident,
      updateLocation,
      updateProperty,
    }),
    [state, toggleMergingIncident, updateLocation, updateProperty],
  )

  return (
    <IncidentCreation.Provider value={IncidentCreationStore}>
      {children}
    </IncidentCreation.Provider>
  )
}

export const useIncidentCreationContext = () => useContext(IncidentCreation)

export const withIncidentCreationContext = Component => {
  const WithIncidentCreationContext = (props) => (
    <IncidentCreationContextProvider>
      <Component {...props} />
    </IncidentCreationContextProvider>
  )

  WithIncidentCreationContext.displayName = `withIncidentCreationContext(${Component.displayName || Component.name || 'Component'})`
  WithIncidentCreationContext.propTypes = Component.propTypes
  WithIncidentCreationContext.defaultProps = Component.defaultProps
  return WithIncidentCreationContext
}
