import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { IconURL } from "@guardian/UI/Icon";
import { svg } from "@guardian/UI/Utils";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledClear = styled.button.attrs(
  props => {
    const {
      active,
      disabled,
      focused,
      hovered,
      isOpen
    } = props;

    return {
      className: classNames("dropdown__select-clear-button", {
          "dropdown__select-clear-button--is-active": active,
          "dropdown__select-clear-button--is-disabled": disabled,
          "dropdown__select-clear-button--is-focused": focused,
          "dropdown__select-clear-button--is-hovered": hovered,
          "dropdown__select-clear-button--is-open": isOpen
        })
    };
  }
)`
  ${ buttonResets }

  ${ ({ theme: { colors } }) => `
    height: 40px;
    position: absolute;
    right: 40px;
    top: 0;
    width: 40px;

    &:before {
      box-shadow: var(--borders-right) var(--colors-grey-070);
      content: "";
      display: block;
      height: var(--space-medium);
      position: absolute;
      right: 0;
      width: 1px;
    }

    &:after {
      align-items: center;
      background-image: url(${
        svg.replaceColors(IconURL.Close, { fill: colors.grey050 })
      });
      background-origin: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: var(--font-sizes-medium);
      border-radius: var(--radii-circle);
      content: "";
      display: flex;
      height: calc(var(--space-x-small) + var(--line-heights-medium));
      justify-content: center;
      margin: 0 auto;
      transition:
        transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1),
        background-image 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
      width: calc(var(--space-x-small) + var(--line-heights-medium));
    }

    &:active, &.dropdown__select-clear-button--is-active,
    &:hover, &.dropdown__select-clear-button--is-hovered
    {
      &:after {
        background-image: url(${
          svg.replaceColors(IconURL.Close, { fill: colors.grey030 })
        });
      }
    }

    // :focus managed by KeyboardFocusContainer
    &.dropdown__select-clear-button--is-focused
    {
      &:after {
        background-color: ${ colors.grey050 };
        background-image: url(${
          svg.replaceColors(IconURL.Close, { fill: colors.grey030 })
        });
      }
    }

    &.dropdown__select-clear-button--is-open {
      &:after {
        transform: rotate(180deg) translateY(-1px);
      }
    }

    // Give disabled styling priority.
    && {
      &.dropdown__select--is-disabled {
        &:after {
          background-image: url(${
            svg.replaceColors(IconURL.Close, { fill: colors.grey070 })
          });
        }
      }
    }
  `}
`;

const Clear = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledClear
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

Clear.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

Clear.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

export default Clear;
