import React from "react";

import { Col, Row } from "@guardian/UI/Grid";

import ActionsPanel from "./components/ActionsPanel";
import NoSelectionMessage from "./components/NoSelectionMessage";
import StyledEmpty from "./views/Empty";

const Empty = props => {
  return (
    <Row style={{ height: "100%" }}>
      <Col noGutter>
        <StyledEmpty>
          <NoSelectionMessage/>
          <ActionsPanel/>
        </StyledEmpty>
      </Col>
    </Row>
  );
};

export default Empty;
