import { TScriptType } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"
import { ISearchMatch } from "@guardian/Components/ModSOS/components/InfoPane/SearchBox/types"

export enum TContentObjectType {
  Markdown = "markdown",
  Button = "button",
}

export interface IContentObject {
  type: TContentObjectType
  value: string
  action?: string
  id: string
}

export interface IScriptNote {
  type: TScriptType
  id: string
  title: string
  content?: IContentObject[]
  parentId: string
}

export interface IProps {
  matches?: { [id: string]: ISearchMatch }
  activeSearch?: ISearchMatch
  forceRender?: boolean
  onAction: (action: any) => void
}
