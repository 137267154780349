import { Auth } from "@guardian/API/Optimus";
import { SessionService } from "@guardian/Services/Session";

interface AuthServiceAuthWithGoogleData {
  token: string
};

const AuthService = {
  loginWithGoogle: (
    authParams: AuthServiceAuthWithGoogleData,
    options?: any
  ) => {
    return new Promise((resolve, reject) => {
      Auth.authWithGoogle(authParams, options)
        .then(({ data: { userToken } }) => {
          SessionService.setAccessToken(userToken);
          resolve({});
        })
        .catch(reject);
    });
  },

  logout: () => {
    SessionService.clearAccessToken();
  },
};

export default AuthService;
