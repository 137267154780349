import React, { useRef } from "react"
import { string, func, bool } from "prop-types"
import style from "./Input.module.css"
import classnames from "classnames"

const FileInput = ({
  label,
  onChange,
  placeholder,
  defaultValue,
  disabled,
  value,
  onBlur,
  accept,
}) => {
  const input = useRef()

  return (
    <div className={classnames(style.text, style.input)}>
      {label && <h5 className={style.label}>{label}</h5>}
      <input
        ref={input}
        type='file'
        accept={accept ? accept : "*/*"}
        onChange={e => onChange(e.target.files[0])}
        placeholder={placeholder}
        defaultValue={value ? undefined : defaultValue}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        onKeyDown={e => {
          switch (e.key) {
            case "Escape":
              if (input.current) {
                input.current.blur()
              }
              break
            default:
              break
          }
        }}
      />
    </div>
  )
}

FileInput.propTypes = {
  label: string,
  onChange: func,
  placeholder: string,
  defaultValue: string,
  disabled: bool,
  value: string,
  onBlur: func,
}

export default FileInput
