import { analystEndSOSSession, analystJoinWMBSession } from "@guardian/API/Optimus/resources/legacy/resources/WMB"
import { agentJoinSession, endProtectSession } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { push } from "@guardian/Utils/history"
import { isV2Protect } from "@guardian/Utils/protect"

// TODO: move to ModSOS/api

export const analystJoinSideEffects = async (
  pathname: string,
  sessionId: string,
) => {
  try {
    if (isV2Protect(sessionId)) {
      await agentJoinSession(sessionId).toPromise()
    } else {
      await analystJoinWMBSession(sessionId).toPromise()
    }

    push(pathname)
  } catch (error) {
    console.error("analystJoinSideEffects failed with error: ", error)
  }
}

export const joinCall = async (currentCallId: string, newCallId: string) => {
  const pathname = `/sos/${newCallId}`
  await analystJoinSideEffects(pathname, newCallId)

  if (!currentCallId) {
    return
  }

  if (isV2Protect(currentCallId)) {
    await endProtectSession(currentCallId).toPromise()
  } else {
    await analystEndSOSSession(currentCallId).toPromise()
  }
}
