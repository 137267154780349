import classNames from "classnames";
import styled from "styled-components";

const Input = styled.pre.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__user-id-copy-field__input"
    )
  };
})`
  border-radius: var(--radii-small);
  color: var(--colors-grey-010);
  display: flex;
  flex-grow: 1;
  font-family: var(--fonts-monospace);
  font-size: var(--font-sizes-small);
  justify-content: flex-start;
  justify-content: flex-start;
  line-height: 19.5px;
  outline: none;
  padding: var(--space-small);
`;

Input.defaultProps = {};

export default Input;
