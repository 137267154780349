import { OptimusAPI } from "@guardian/API/Optimus"

export interface ContentMergeContentData {
  incidentId: string
}

export interface ContentP2PMergeContentData {
  parentIncidentId: string
  childIncidentId: string
}

export interface ContentRejectContentData {
  reason: string
}

export interface ContentVerifyContentData {
  confirmed: boolean
}

export interface ContentIncidentApproveContentData {
  incidentUpdate: string
  safetyLevel: number
  location: {
    address: string
    lat: number
    lng: number
    location: string
    cityCode: string
    neighborhood?: string
  }
  tags: any[]
}

const Contents = {
  ackContent: (contentType: string, contentId: string, options?: any) => {
    return OptimusAPI.post(
      `/v1/content/${contentType}/${contentId}/ack`,
      {},
      options,
    )
  },

  mergeContent: (
    contentType: string,
    contentId: string,
    data: ContentMergeContentData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/content/${contentType}/${contentId}/merge`,
      data,
      options,
    )
  },

  mergeP2PContent: (
    contentId: string,
    data: ContentP2PMergeContentData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/content/video_stream/${contentId}/merge_approve`,
      data,
      options,
    )
  },

  rejectContent: (
    contentType: string,
    contentId: string,
    data: ContentRejectContentData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/content/${contentType}/${contentId}/reject`,
      data,
      options,
    )
  },

  verifyContent: (
    contentType: string,
    contentId: string,
    data: ContentVerifyContentData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/content/${contentType}/${contentId}/confirm`,
      data,
      options,
    )
  },

  iglIncidentApproveContent: (
    contentType: string,
    contentId: string,
    data: ContentIncidentApproveContentData,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/content/${contentType}/${contentId}/igl_incident_approve`,
      data,
      options,
    )
  },

  isReporterContent: (
    contentType: string,
    contentId: string,
    isReporter: boolean,
    options?: any,
  ) => {
    return OptimusAPI.post(
      `/v1/content/${contentType}/${contentId}/is_reporter`,
      {
        isReporter,
      },
      options,
    )
  },

  getModerationLog: (userId: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/content/by_resolver`, {
      ...options,
      params: {
        ...options.params,
        user_id: userId,
      },
    })
  },

  getUnacknowledgedContent: (options: any = {}) => {
    return OptimusAPI.get(`/v1/content/unacknowledged`, options)
  },

  getUnresolvedContent: (psn: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/content/unresolved`, {
      ...options,
      params: {
        ...options.params,
        psn,
      },
    })
  },

  getContentCreators: (options: any = {}) => {
    return OptimusAPI.get(`/v1/content/creators`, options)
  },

  getMultimediaUploadMetadata: (contentId: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/multimedia_upload_metadata/${contentId}`, options)
  },
}

export default Contents
