import _ from "lodash"

import { GeocodingService } from "@guardian/Services/Geocoding";
import { ServiceAreaService } from "@guardian/Services/ServiceArea";

const map = state => {
  state.on("getActiveServiceAreas", activeChannels => {
    const servAreas = new Set()
    Object.keys(activeChannels).forEach(cid => {
      const chan = state.get().radio.channels[cid]
      servAreas.add(chan.serviceArea)
    })
    return [...servAreas].sort()
  })

  state.on("panIncident", incId => {
    let inc = state
      .get()
      .incidentComposer.incidentFeed.find(inc => inc.id === incId)
    if (inc) {
      state.emit("focusMap", { lat: inc.location.lat, lng: inc.location.lng })
      state.emit("setMapZoom", 12)
    }
  })

  state.on("focusMap", (loc, address) => {
    state.get().incidentComposer.mapLocation.set("marker", { ...loc, address })
  })

  state.on("modFocusMap", (loc, address) => {
    state.get().moderation.mapLocation.set("marker", { ...loc, address })
  })

  state.on("modResetMarker", () =>
    state.get().moderation.mapLocation.set("marker", undefined),
  )

  state.on("fetchServiceAreaConfig", async () => {
    const config = await ServiceAreaService.getServiceAreas()
    state.get().global.set("serviceAreaConfig", config)
  })

  state.on("fetchGeographyPushesConfig", async () => {
    if (!state.get().global.geographyPushesConfig) {
      const config = await ServiceAreaService.getGeographyPushes()
      state.get().global.set("geographyPushesConfig", config)
    }
  })

  state.on("setupEsri", async () => {
    const featuresResp = await GeocodingService.getFeatureLayers()
    state.get().global.set("esriFeatures", featuresResp)
  })
}

export default map
