import { useEffect, useMemo, useState } from "react"

import { LoggingService } from "@guardian/Services/Logging"

import useKeyBindings from "@guardian/Hooks/useKeyBindings"
import { eventHub } from "@guardian/State"

import LogModal from "./components/LogModal"

interface OnErrorEventData {
  message: string
  filename: string
  lineno: number
  colno: number
  error: any
}

const LogAssistant = () => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const onError = (event: OnErrorEventData) => {
      const { message, filename, lineno, colno, error } = event

      LoggingService.logError(message, {
        domain: "window",
        trackedData: {
          filename: filename,
          lineno: lineno,
          colno: colno,
          error: error,
        },
      })
    }

    window.addEventListener("error", onError)
    return () => {
      window.removeEventListener("error", onError)
    }
  }, [])

  useEffect(() => {
    const showLogAssistant = () => {
      setModalOpen(true)
    }
    eventHub.on("showLogAssistant", showLogAssistant)
    return () => {
      eventHub.off("showLogAssistant", showLogAssistant)
    }
  }, [])

  // Open our LogAssistant modal when the user types ctrl+shift-0.
  useKeyBindings(
    useMemo(
      () => ({
        "ctrl+shift+0": () => setModalOpen(true),
      }),
      [],
    ),
  )

  const handleModalClosed = () => {
    setModalOpen(false)
  }

  return <LogModal onClose={handleModalClosed} open={modalOpen} />
}

export default LogAssistant
