import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import React from "react"
import dayjs from "dayjs"
import calendar from "@guardian/Utils/dayjs/calendar"
import styles from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNote/SOSNote.module.css"
import { INote } from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNotesPane/types"

dayjs.extend(calendar)

export const SOSNote = ({ agentName, createdAt, text }: INote) => {
  const paragraphs = text.split(/\n/).filter(p => !!p)

  return (
    <Container column leftJustify fullWidth>
      <Container>
        <span className={styles.name}>{agentName}</span>
        <Spacing left={1}>
          <span className={styles.time}>
            {(dayjs(createdAt) as any).calendar(" ")}
          </span>
        </Spacing>
      </Container>
      <Container top={2} bottom={4} column leftJustify className={styles.text}>
        {paragraphs.map((p, i) => (
          <div key={i}>
            <span>{p}</span>
            {i !== paragraphs.length && <br key={p} />}
          </div>
        ))}
      </Container>
    </Container>
  )
}
