import classNames from "classnames";
import styled from "styled-components";

const SystemMessage = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__system-message"
      )
    }
  }
)`
  width: 100%;
`;

export default SystemMessage;
