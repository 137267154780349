import classnames from "classnames"
import _ from "lodash"
import OT from "opentok-react"
import { bool, func, number, object, string } from "prop-types"
import { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player/file"
import VisibilitySensor from "react-visibility-sensor"
import screenfull from "screenfull"

import config from "@guardian/Config"
import { useGlobalState } from "@guardian/State"

import { VideoStreams } from "@guardian/API/Optimus"
import useVideoPlayerKeyBindings from "@guardian/Hooks/useVideoPlayerKeyBindings"
import { getIncidentAuthor } from "@guardian/Utils/incident"
import { secondsToMin } from "@guardian/Utils/util"

import { CreatorBadge, P2PIncidentVerificationBadge, UserMergedBadge, VerifiedBadge } from "@guardian/Components/Badges"
import { withErrorBoundary } from "@guardian/Components/ErrorBoundary"

import Badge from "./Badge"
import LiveTabVideoControls from "./LiveTabVideoControls"
import style from "./LiveTabVideoPlayer.module.css"
import RejectionMenu from "./RejectionMenu"

const LiveTabVideoPlayer = ({
  url,
  autoPlay,
  onPlay,
  live,
  durationSeconds,
  defaultMuted,
  selected,
  stream,
  incident,
  location,
  streamHandled,
  noLongerLive,
  title,
  videoProvider,
  videoSessionId,
  openTokToken,
}) => {
  const readOnly = useGlobalState(state => state.global.readOnly)

  const [playing, setPlaying] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)
  const [muted, setMuted] = useState(defaultMuted)
  const [seeking, setSeeking] = useState(false)
  const [progress, setProgress] = useState(0)
  const [seekValue, setSeekValue] = useState(0)
  const [showRejectionMenu, setShowRejectionMenu] = useState(false)
  const [otSubscriber, setOTSubscriber] = useState(useRef())

  const playerRef = useRef()
  const isTokbox = videoProvider === "tokbox"
  const play = visible => {
    if (visible && autoPlay) {
      setPlaying(true)
    }
  }

  const getSubscriber = () => {
    if (otSubscriber) {
      setOTSubscriber(otSubscriber.current.getSubscriber())
    }
  }

  const toggleFullScreen = () => {
    if (isTokbox) {
      getSubscriber()
      otSubscriber.current.node.querySelector("video").webkitRequestFullScreen()
    } else {
      screenfull.toggle(playerRef.current.getInternalPlayer())
    }
    setFullscreen(!fullscreen)
  }

  const completeVideoStream = async (stream) => {
    try {
      await VideoStreams.completeStream(stream.id)
    } catch (e) {}
  }

  useVideoPlayerKeyBindings(playerRef, setPlaying, selected)

  useEffect(() => {
    setMuted(!selected)
  }, [selected])

  const subscriberProperties = {
    showControls: false,
    height: "400px",
    width: "100%",
    subscribeToAudio: !muted,
    subscribeToVideo: true,
    fitMode: "contain",
  }

  return (
    <VisibilitySensor onChange={play}>
      <div
        className={classnames(style.container, { [style.selected]: selected })}
      >
        {isTokbox ? (
          <OT.OTSession
            apiKey={config.tokboxApiKey}
            sessionId={videoSessionId}
            token={openTokToken}
            id='tester1'
          >
            <OT.OTStreams>
              <OT.OTSubscriber
                properties={subscriberProperties}
                ref={otSubscriber}
              />
            </OT.OTStreams>
          </OT.OTSession>
        ) : (
          <ReactPlayer
            progressInterval={500}
            ref={playerRef}
            url={url}
            className={classnames(style.player, { [style.selected]: selected })}
            width='100%'
            height='100%'
            controls={false}
            playing={playing}
            onPlay={onPlay}
            onProgress={({ playedSeconds }) => {
              setProgress(playedSeconds)
            }}
            muted={muted}
            config={{
              file: {
                attributes: {
                  volume: 0,
                  loop: true,
                  playsInline: true,
                },
              },
            }}
            pip={false}
          />
        )}
        {showRejectionMenu && (
          <RejectionMenu
            stream={stream}
            streamHandled={streamHandled}
            incidentAuthor={getIncidentAuthor(incident)}
            incidentStreamersCount={incident?.liveStreamers?.length || 0}
          />
        )}
        {noLongerLive ? (
          <div className={style.notLiveIndicator}>
            <img alt="" src='/NoLongerLiveIndicator.svg' />
            No Longer Live
          </div>
        ) : (
          <div className={style.durationBox}>
            {_.padStart(
              isTokbox
                ? secondsToMin(durationSeconds)
                : secondsToMin(
                    !live
                      ? durationSeconds
                      : playerRef.current
                      ? playerRef.current.getDuration()
                      : 0.0,
                  ),
              5,
              "0",
            )}
          </div>
        )}
        <div className={style.badges}>
          <P2PIncidentVerificationBadge incident={incident} />
          {!!stream?.verifiedLive && <VerifiedBadge />}
          {stream?.mergeTag === "selfMerged" && <UserMergedBadge />}
          {!!stream?.isReporter && <CreatorBadge />}
        </div>
        {selected && (
          <>
            <div className={style.fullscreen}>
              <button onClick={toggleFullScreen} className={style.rounded}>
                <img
                  className={style.buttonImage}
                  src='/Expand.png'
                  alt='fullscreen'
                />
              </button>
            </div>
            <div className={style.volume}>
              <button
                onClick={() => {
                  setMuted(!muted)
                }}
                className={style.rounded}
              >
                <img
                  className={style.buttonImage}
                  src={`/Volume${muted ? "Off" : "On"}.png`}
                  alt='volume'
                />
              </button>
            </div>
          </>
        )}

        <div className={style.bottomPortion}>
          {selected && (
            <>
              {videoProvider !== "tokbox" && (
                <>
                  <div className={style.playButton}>
                    <button
                      className={style.play}
                      onClick={() => {
                        setPlaying(!playing)
                      }}
                    >
                      <img
                        className={style.playButtonImage}
                        src={`/${playing ? "Pause" : "Play"}.png`}
                        alt='play'
                      />
                    </button>
                  </div>
                  <div className={style.positionContainer}>
                    {playerRef.current && (
                      <div className={style.position}>
                        <input
                          className={style.scrubber}
                          type='range'
                          min={0}
                          max={
                            !live
                              ? durationSeconds
                              : playerRef.current.getDuration()
                          }
                          value={seeking ? seekValue : progress}
                          readOnly={!seeking}
                          onChange={e => setSeekValue(e.target.value)}
                          onMouseDown={() => setSeeking(true)}
                          onMouseUp={e => {
                            playerRef.current.seekTo(e.target.value)
                            setSeeking(false)
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              <LiveTabVideoControls
                stream={stream}
                streamHandled={streamHandled}
                completeVideoStream={noLongerLive ? undefined : completeVideoStream}
                toggleRejectionMenu={() => {
                  setShowRejectionMenu(!showRejectionMenu && !readOnly)
                }}
              />
            </>
          )}
          {!selected && (
            <>
              <Badge stream={stream} />
              <div className={style.incidentInfo}>
                <div className={style.incidentTitle}>
                  {stream.onAir ? "OnAir Broadcast" : title}
                </div>
                <div className={style.incidentLocation}>
                  {stream.onAir ? "Citizen HQ" : location}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </VisibilitySensor>
  )
}

LiveTabVideoPlayer.propTypes = {
  url: string,
  autoPlay: bool,
  onPlay: func,
  live: bool,
  title: string,
  durationSeconds: number,
  defaultMuted: bool,
  stream: object,
  incident: object,
  selected: bool,
  location: string,
  streamHandled: func,
  toggleRejectionMenu: func,
  noLongerLive: bool,
  videoSessionId: string,
  openTokToken: string,
  videoProvider: string,
}

export default withErrorBoundary(LiveTabVideoPlayer, {
  domain: "LiveTab",
  method: "LiveTabVideoPlayer",
})
