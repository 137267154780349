import { noop } from "lodash"
import React, { useContext, useMemo, useReducer } from "react"

import type { ReturnVoid } from "@guardian/Types/Utils"

import { Actions, type AuditToolAction } from "./actions"
import reducer, { initialState } from "./reducer"
import type { AuditToolState } from "./types"

type AuditToolContextFunctions = {
  [T in keyof typeof Actions]: ReturnVoid<(typeof Actions)[T]>
}

type AuditToolContextType = AuditToolContextFunctions & {
  state: AuditToolState
  dispatch: (action: AuditToolAction) => void
}

const AuditToolContext = React.createContext<AuditToolContextType>({
  state: initialState,
  setIsFetchingClips: noop,
  setIsSyncingEntries: noop,
  setEntries: noop,
  setClips: noop,
  dispatch: noop,
})

export const AuditToolContextProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions: AuditToolContextFunctions = useMemo(
    () => ({
      setIsFetchingClips: (
        ...args: Parameters<typeof Actions.setIsFetchingClips>
      ) => dispatch(Actions.setIsFetchingClips(...args)),
      setIsSyncingEntries: (
        ...args: Parameters<typeof Actions.setIsSyncingEntries>
      ) => dispatch(Actions.setIsSyncingEntries(...args)),
      setEntries: (...args: Parameters<typeof Actions.setEntries>) =>
        dispatch(Actions.setEntries(...args)),
      setClips: (...args: Parameters<typeof Actions.setClips>) =>
        dispatch(Actions.setClips(...args)),
    }),
    [dispatch],
  )

  const AuditToolContextStore: AuditToolContextType = {
    state,
    ...actions,
    dispatch,
  }

  return (
    <AuditToolContext.Provider value={AuditToolContextStore}>
      {children}
    </AuditToolContext.Provider>
  )
}

export const useAuditToolContext = () => useContext(AuditToolContext)
