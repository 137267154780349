import React from "react";

import { XLargeText } from "@guardian/UI/Typography";

import StyledQueueHeader from "./views/QueueHeader";

const QueueHeader = props => {
  return (
    <StyledQueueHeader>
      <XLargeText weight="bold">
        Chats
      </XLargeText>
    </StyledQueueHeader>
  );
};

export default QueueHeader;
