import classNames from "classnames";
import styled from "styled-components";

const ContentContainer = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__activity__list-item__content-container"
    )
  };
})`
  align-items: flex-start;
  color: var(--colors-grey-030);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--space-xx-small);
  justify-content: flex-start;
  min-width: 0;

  & > :first-child {
    color: var(--colors-white);
  }
`;

ContentContainer.defaultProps = {};

export default ContentContainer;
