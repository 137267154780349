import classNames from "classnames";
import styled from "styled-components";

const InfoGroup = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__session__info-group"
    )
  };
})`
  display: flex;
  flex-direction: column;
`;

InfoGroup.defaultProps = {};

export default InfoGroup;
