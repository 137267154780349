import classNames from "classnames";
import styled from "styled-components";

const AgentName = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__agent-name"
      )
    }
  }
)`
  color: var(--colors-grey-060);
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-small);
  margin: var(--space-xx-small) var(--space-medium);
`;

export default AgentName;
