import React from "react";
import PropTypes from "prop-types";

import StyledItem from "../../../views/Menu/Items/Item";

import useItem from "./hooks/useItem";

// AbstractItem
//
// AbstractItem is useful if you need to use a non standard component for a
// selectable menu item while keeping selection working the same as with a
// normal Item (e.g.; keeping selected value tracked in the downshift state).
//
// Here is an example usage with IconButtons inside of a menu.
//
//   {
//     options.map((item, i) => (
//       <AbstractItem key={`options-${i}`} value={item}>
//          <IconButton
//            ref={itemRef}
//            focused={focused}
//          >
//            <item.icon/>
//          </IconButton>
//       </AbstractItem>
//     ))
//   }

const AbstractItem = React.forwardRef((props, ref) => {
  const {
    children,
    ...restProps
  } = props;

  const itemProps = useItem({ itemRef: ref, ...restProps });

  return React.cloneElement(
    React.Children.only(children), { ref: ref, ...itemProps }
  );
});

AbstractItem.displayName = "AbstractItem";

AbstractItem.propTypes = {};

export default AbstractItem;
