import { OptimusAPI } from "@guardian/API/Optimus";

interface RadioGetCityCodesParams {
  serviceArea: string
};

const Radio = {
  getCityCodes: (
    params: RadioGetCityCodesParams,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/radio/city_codes`,
      {
        ...options,
        params: {
          ...options.params,
          ...params
        }
      }
    );
  },

  identifyToSegment: (options?: any) => {
    return OptimusAPI.post(`/radio/identify`, {}, options);
  }
};

export default Radio;
