import React, { CSSProperties, useEffect, useRef, useState } from "react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import { dateToUTC } from "@guardian/Utils/util"
import type { Clip } from "@guardian/Types/Clip"

import "./ClipTimestamp.scss"

/**
 * This function returns a color value that ranges from green (newer) to red (older).
 * @param age The age of the item in seconds.
 * @param redThresholdMin This argument determines the age threshold at which the color shifts from green-ish to red.
 * @returns A string containing a hsl color value.
 */
const getColorForAge = (age: number, redThresholdMin: number) => `hsl(${120 * (1 - Math.min(1, age / (redThresholdMin * 60)))}, 100%, 50%)`

const dateToRelative = (time: string) => dayjs(time).fromNow()

const relativeTimeSettings = {
  future: "in %s",
  past: "%s",
  s: "Now",
  m: "1 min ago",
  mm: "%d min ago",
  h: "1 hr ago",
  hh: "%d hr ago",
  d: "1 day ago",
  dd: "%d days ago",
  M: "1 mo ago",
  MM: "%d mo ago",
  y: "1 yr ago",
  yy: "%d yr ago",
}

interface ClipTimestampProps extends Pick<Clip, "played" | "time"> {
  citMod: boolean
}

const ClipTimestamp: React.FC<ClipTimestampProps> = ({ played, time, citMod }) => {
  const lastColorReached = useRef(false)
  const [componentStyles, setComponentStyles] = useState<CSSProperties>({})

  const redThresholdMin = citMod ? 3 : 10

  useEffect(() => {
    let intervalId: any

    const updateTime = () => {
      const timestamp = new Date(time)
      const now = new Date().getTime()
      const diff = now - timestamp.getTime()
      const ageInSeconds = Math.floor(diff / 1000)

      // If the color has not yet reached red, update the color.
      if (!lastColorReached.current) {
        setComponentStyles({ color: played ? "#a7abaf" : getColorForAge(ageInSeconds, redThresholdMin) })

        // If the color has reached red, keep re-rendering the component every minute in order to update the relative time.
        if (played || ageInSeconds >= redThresholdMin * 60) {
          lastColorReached.current = true
          return
        }
      }
    }

    intervalId = setInterval(updateTime, lastColorReached.current ? 60 * 1000 : 10 * 1000)
    updateTime()
    return () => clearInterval(intervalId)
  }, [time, played, redThresholdMin])

  useEffect(() => {
    dayjs.extend(relativeTime)
    dayjs.updateLocale("en", { relativeTime: relativeTimeSettings })
  }, [])

  return (
    <div className='clip-timestamp-container'>
      <span>{dateToUTC(time).format("HH:mm:ss")}</span>
      <span style={componentStyles}>{dateToRelative(time)}</span>
    </div>
  )
}

export default React.memo(ClipTimestamp)
