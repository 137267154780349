import { useMutation } from "@tanstack/react-query"

import { AuditIncidentService } from "@guardian/Services/AuditIncident"

import { useAuditToolContext } from "@guardian/Components/AuditTool/store"
import {
  AuditToolEntryType,
  type AuditToolEntry,
  type AuditToolEntryKey,
} from "@guardian/Components/AuditTool/types"

type MutationParams = {
  type: AuditToolEntryType
  incidentKey: AuditToolEntryKey
  payload: Partial<AuditToolEntry>
}

const ITEM_PATCH_MAP = {
  [AuditToolEntryType.ADDRESS]: AuditIncidentService.patchAddress,
  [AuditToolEntryType.TITLE_UPDATE]: AuditIncidentService.patchTitleAndUpdate,
}

export const useAuditIncidentPatch = () => {
  const {
    setEntries,
    state: { entries },
  } = useAuditToolContext()

  const auditIncidentMutation = useMutation<
    AuditToolEntry[],
    unknown,
    MutationParams,
    AuditToolEntry | null
  >({
    mutationKey: ["auditIncidentPatch"],
    mutationFn: ({ type, incidentKey, payload }) => {
      return ITEM_PATCH_MAP[type](
        incidentKey.incidentId,
        incidentKey.clipId,
        payload,
      )
    },
    onMutate: ({ incidentKey, payload }) => {
      const entryIdx = entries.findIndex(
        entry =>
          entry.incidentId === incidentKey.incidentId &&
          entry.clipId === incidentKey.clipId,
      )
      if (entryIdx === -1) {
        return null
      }
      const entry = entries[entryIdx]
      const updatedEntry = { ...entry, ...payload }
      setEntries([
        ...entries.slice(0, entryIdx),
        updatedEntry,
        ...entries.slice(entryIdx + 1),
      ])
      return entry
    },
    onSuccess: () => {
      // This is a no-op because the optimistic update is already done
    },
    onError: (error, variables, context) => {
      // If the mutation fails, revert the optimistic update
      console.error(error)
      if (context) {
        setEntries(
          entries.map(entry => {
            if (
              entry.clipId === context.clipId &&
              entry.incidentId === context.incidentId
            ) {
              return context
            }
            return entry
          }),
        )
      }
    },
  })

  return auditIncidentMutation
}
