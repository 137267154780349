import React from "react"
import { string, bool, func } from "prop-types"
import { OutlinedInput } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import NotificationPreview from "@guardian/Components/CustomNotif/NotificationPreview"
import { ActionTypes } from "../ActionPane.reducer"
import { inputStyle } from "./CustomNotifContainer.styles"

const StyledInput = withStyles(inputStyle)(OutlinedInput)

const CustomNotifContainer = ({
  tagName,
  notifTitle,
  notifMessage,
  notifSoundOn,
  showIncidentMetadata,
  dispatch,
  disabled,
}) => {
  return (
    <StyledInput
      label='Custom Notification'
      notched
      disabled={disabled}
      inputComponent={NotificationPreview}
      inputProps={{
        title: notifTitle,
        message: notifMessage,
        sound: notifSoundOn,
        withCharCount: true,
        titleLimit: 28,
        charLimit: showIncidentMetadata ? 110 : 156,
        tagName,
        onChangeTitle: e =>
          dispatch({
            type: ActionTypes.SetNotifTitle,
            payload: { title: e.target.value, edit: true },
          }),

        onChangeMessage: e => {
          dispatch({
            type: ActionTypes.SetNotifMessage,
            payload: { message: e.target.value, edit: true },
          })
        },
      }}
    />
  )
}

CustomNotifContainer.propTypes = {
  tagName: string,
  notifTitle: string,
  notifMessage: string,
  notifSoundOn: bool,
  showIncidentMetadata: bool,
  dispatch: func,
}

export default CustomNotifContainer
