import { shape, string, number, array } from "prop-types"

export const serviceArea = shape({
  code: string,
  name: string,
  center: array,
  area: array,
  zoom: number,
  coverage: number,
})
