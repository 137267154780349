import React from "react"
import ReactDOM from "react-dom"
import usePortal from "@guardian/Hooks/usePortal"

const Modal = ({ children }) => {
  const div = usePortal("modal-root")

  return ReactDOM.createPortal(
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 500,
      }}
    >
      {children}
    </div>,
    div,
  )
}

export default Modal
