import React, { Children } from "react";
import styled from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

const ItemIcon = styled(({ children, ...props }) =>
  React.cloneElement(Children.only(children), props)
).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "dropdown__item-icon")
  }
})`
  align-items: center;
  display: flex;
  height: var(--font-sizes-large)!important;
  justify-content: center;
  min-width: var(--font-sizes-large)!important;
  width: var(--font-sizes-large)!important;
`;

const End = styled(ItemIcon)`
  margin-left: var(--space-small);
`;

const Start = styled(ItemIcon)`
  margin-right: var(--space-small);
`;

ItemIcon.propTypes = {};

ItemIcon.defaultProps = {};

export default {
  End,
  Start
};
