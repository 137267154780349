import React, { useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import PubSub from "pubsub-js";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuid } from "uuid";

import Body from "../views/DialogMessages/Body";
import Close from "../views/DialogMessages/Close";
import DialogMessage, { TYPES } from "../views/DialogMessages/DialogMessage";
import StyledDialogMessages from "../views/DialogMessages/DialogMessages";
import Header from "../views/DialogMessages/Header";

const DialogMessages = props => {
  const [didMount, setDidMount] = useState(false);
  const [dialogMessages, setDialogMessages] = useState([]);
  const dialogMessagesRef = useRef();

  useEffect(() => {
    dialogMessagesRef.current = dialogMessages
  }, [dialogMessages]);

  useEffect(() => {
    let subscriptions = [];

    const subscribeAll = () => {
      subscriptions = Object.values(TYPES).map(alertType => {
        return PubSub.subscribe(
          alertType,
          (alertType, { message, options }) => {
            show(message, alertType, options);
          }
        );
      });
    };

    const unsubscribeAll = () => {
      subscriptions.forEach(subscription => PubSub.unsubscribe(subscription));
    };

    setDidMount(true);
    subscribeAll();

    return () => {
      unsubscribeAll();
    }
  }, []);

  const remove = dialogMessage => {
    setDialogMessages(prevDialogMessages => {
      const nextDialogMessages = prevDialogMessages.filter(a => {
        return a.id !== dialogMessage.id;
      });

      if (dialogMessage.options.onClose) {
        dialogMessage.options.onClose();
      }

      return nextDialogMessages;
    })
  };

  const show = (message, type, options) => {
    const id = `dialog-message--${ uuid() }`;

    const dialogMessageOptions = {
      ...options
    };

    const dialogMessage = {
      id: id,
      message: message,
      onClose: () => remove(dialogMessage),
      options: dialogMessageOptions,
      type: type
    };

    setDialogMessages(prevDialogMessages => {
      return prevDialogMessages.concat(dialogMessage);
    });

    if (dialogMessage.options.onOpen) {
      dialogMessage.options.onOpen();
    }

    return dialogMessage;
  };

  if (!didMount) {
    return null;
  }

  return (
    <Portal>
      <TransitionGroup
        appear
        component={StyledDialogMessages}
        {...props}
      >
        {
          dialogMessages.map(dialogMessage => (
            <DialogMessage key={dialogMessage.id} type={dialogMessage.type}>
              <div>
                <Header>
                  {
                    dialogMessage.options.title && (
                      dialogMessage.options.title
                    )
                  }
                  <Close onClick={dialogMessage.onClose}/>
                </Header>
                <Body>
                  {
                    dialogMessage.message
                  }
                </Body>
              </div>
            </DialogMessage>
          ))
        }
      </TransitionGroup>
    </Portal>
  );
};

DialogMessages.propTypes = {};

DialogMessages.defaultProps = {};

export default DialogMessages;
