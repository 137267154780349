import classNames from "classnames";
import styled, { css } from "styled-components";

const UserAvatarWrapper = styled.span.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__user-avatar-wrapper"
      )
    }
  }
)`

  position: relative;

  & > :first-child {
    clip-path: none;
  }

  ${ ({ avatarClipPath }) => avatarClipPath && css`
    & > :first-child {
      clip-path: ${ avatarClipPath };
    }
  `}
`;

UserAvatarWrapper.defaultProps = {};

export default UserAvatarWrapper;
