import React from "react";

import StyledUserName from "./views/UserName";
import UserNameWrapper from "./views/UserNameWrapper";

const UserName = props => {
  const {
    session: {
      members,
      userId
    }
  } = props;

  // TODO: We should know that this will fail long before this and handle.
  const fullName = members && members[userId] ? members[userId].fullName : `[unknown member for userId ${ userId }]`;

  return (
    <UserNameWrapper>
      <StyledUserName>
        { fullName }
      </StyledUserName>
    </UserNameWrapper>
  );
};

export default UserName;
