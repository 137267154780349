import { createContext, useContext } from "react";

export const ModalContext = createContext(undefined);

const useModalContext = () => {
  const modalContext = useContext(ModalContext);

  return modalContext;
};

export default useModalContext;
