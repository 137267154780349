import React, { Children } from "react";
import styled from "styled-components";
import classNames from "classnames";

const ButtonIcon = styled(({ children, ...props }) =>
  React.cloneElement(Children.only(children), props)
).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "button__icon")
  }
})`
  align-items: center;
  display: flex;
  height: var(--font-sizes-large)!important;
  justify-content: center;
  min-width: var(--font-sizes-large)!important;
  width: var(--font-sizes-large)!important;
  transition:
    color 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
`;

ButtonIcon.End = styled(ButtonIcon)`
  margin-left: var(--space-x-small);
`;

ButtonIcon.Start = styled(ButtonIcon)`
  margin-right: var(--space-x-small);
`;

ButtonIcon.propTypes = {};

ButtonIcon.defaultProps = {};

export default ButtonIcon;
