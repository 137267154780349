import classNames from "classnames";
import styled from "styled-components";

import { Col } from "@guardian/UI/Grid"

const MessagingQueuePanel = styled(Col).attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__messaging-queue-panel")
    }
  }
)`
  background: var(--colors-grey-095);
  box-shadow: var(--borders-right) var(--colors-grey-080);
  color: var(--colors-white);
  flex: 0 0 278px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

MessagingQueuePanel.defaultProps = {
  noGutter: true
};

export default MessagingQueuePanel;
