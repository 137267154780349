import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import { ProtectSession } from "@guardian/Lib/QueryClient/hooks";
import { Button } from "@guardian/UI/Button";
import {
  FauxInput,
  Field
} from "@guardian/UI/Form";

import Input from "./views/Input";
import InputActionsWrapper from "./views/InputActionsWrapper";
import StyledInputField from "./views/InputField";
import InputWrapper from "./views/InputWrapper";

const InputField = props => {
  const { sessionId } = props;

  const performSubmit = ProtectSession.useSendNote();

  const [message, setMessage] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(null);

  const abortControllerRef = useRef(null);
  const sessionIdRef = useRef(sessionId);
  const inputFieldRef = useRef(null);
  const inputWrapperRef = useRef(null);

  const adjustInputHeight = useCallback(() => {
    const inputField = inputFieldRef.current;

    if (inputField) {
      // The minimum scrollHeight of a textarea is always going to be the
      // height. To get an accurate scrollHeight, set the height to 0 first.
      inputField.style.height = "0";
      inputField.style.height = `${inputField.scrollHeight}px`;
    }
  }, []);

  const setInputFieldRef = useCallback((node) => {
    inputFieldRef.current = node;
    adjustInputHeight();
  });

  useLayoutEffect(() => {
    adjustInputHeight();
  }, [message]);

  useEffect(() => {
    const controller = new AbortController();
    abortControllerRef.current = controller;

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const updateSubmitDisabled = () => {
      const disableSubmit = message.trim().length == 0;

      setSubmitDisabled(disableSubmit);
    };

    updateSubmitDisabled();
  }, [message]);

  useEffect(() => {
    if (submitTrigger == null) {
      return;
    };

    const data = {
      sessionId: sessionIdRef.current,
      text: submitTrigger.message
    };

    const options = {
      signal: abortControllerRef.current?.signal
    };

    performSubmit.mutate({ data, options });
  }, [submitTrigger]);

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleInputChanged = ({ target: { value: text } }) => {
    setMessage(text);
  };

  const handleInputFocused = () => {
    setInputFocused(true);
  };

  const handleInputKeyDown = (event) => {
    // If enter key was input without using shift+enter, submit.
    if (
      (event.code === "Enter" || event.code === "NumpadEnter") &&
      !event.shiftKey
    ) {
      event.preventDefault();
      event.stopPropagation();

      handleSubmitMessage();
    }
  };

  const handleSubmitMessage = () => {
    if (!submitDisabled) {
      const formattedMessage = message.trim();

      setSubmitTrigger({
        message: formattedMessage,
        trigger: +new Date()
      });
      setMessage("");
    }
  };

  return (
    <StyledInputField>
      <Field>
        <FauxInput
          style={{ height: "auto" }}
          focused={inputFocused}
        >
          <InputWrapper ref={inputWrapperRef}>
            <Input
              ref={setInputFieldRef}
              onBlur={handleInputBlur}
              onChange={handleInputChanged}
              onFocus={handleInputFocused}
              onKeyDown={handleInputKeyDown}
              placeholder={"Add a note..."}
              value={message}
            />
            <InputActionsWrapper>
              <Button
                disabled={submitDisabled}
                onClick={handleSubmitMessage}
                isPrimary
                tightenX
                tightenY
              >
                Send
              </Button>
            </InputActionsWrapper>
          </InputWrapper>
        </FauxInput>
      </Field>
    </StyledInputField>
  );
};

export default InputField;
