import React from "react";

import InputField from "./components/InputField";
import NotesList from "./components/NotesList";
import StyledNotesTab from "./views/NotesTab";

const NotesTab = props => {
  const { session: { id: sessionId } } = props;

  return (
    <StyledNotesTab>
      <NotesList sessionId={sessionId}/>
      <InputField sessionId={sessionId}/>
    </StyledNotesTab>
  );
};

export default NotesTab;
