import { forwardRef, memo } from "react"
import _startCase from "lodash/startCase"

import {
  AuditFieldItem,
  ClipColumn,
  FixedItem,
  HeaderContainer,
  Item,
} from "./styles"

type HeaderRowProps = {
  rowFieldNames: string[]
  auditFieldNames: string[]
}

export const HeaderRow = memo(
  forwardRef<HTMLDivElement, HeaderRowProps>((props, ref) => {
    const { rowFieldNames, auditFieldNames } = props
    return (
      <HeaderContainer ref={ref}>
        <ClipColumn padded header>
          Clip ID
        </ClipColumn>
        {rowFieldNames.map(rowFieldName => (
          <Item key={rowFieldName} padded>
            {_startCase(rowFieldName)}
          </Item>
        ))}
        {auditFieldNames.map(auditFieldName => (
          <AuditFieldItem key={auditFieldName} padded center>
            {_startCase(auditFieldName)}
          </AuditFieldItem>
        ))}
        <FixedItem padded center>
          Incident ID
        </FixedItem>
      </HeaderContainer>
    )
  }),
)
