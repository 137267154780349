import React, { PureComponent, Suspense } from "react"
import ListenPanel from "@guardian/Components/ListenPanel/ListenPanel"
import ClipTooltip from "@guardian/Components/ClipTooltip/ClipTooltip"
import { shape, string, bool, object } from "prop-types"
import "./styles.scss"

import { useGlobalState, eventHub } from "@guardian/State"
import { objPick } from "@guardian/Utils/obj-pick"
import history from "@guardian/Utils/history"

import ClipContentMask from "./ClipContentMask"

const Moderation = React.lazy(() => import("@guardian/Components/ModAlpha"))

class Clips extends PureComponent {
  async componentDidMount() {
    eventHub.emit("bindClipKeys")
    const {
      selectedIncident,
      panOff,
      preserveAttachedClips,
      user,
      permissions,
      tab,
    } = this.props
    if (!user) {
      history.push("/")
    }

    if (
      user &&
      (!user.username ||
        !(
          permissions.citMod ||
          permissions.trainee ||
          permissions.airwavesAccess ||
          permissions.radioDesk
        ))
    ) {
      // Hacky fix to deal with users who have to completed flow.
      eventHub.emit("logout")
    }
    if (user) {
      await this.setSelectedIncident(
        selectedIncident,
        permissions.citMod || permissions.lafd,
        panOff,
        tab,
        true,
        preserveAttachedClips,
      )
    }
  }

  componentWillUnmount() {
    eventHub.emit("unbindKeys")
  }

  setSelectedIncident = async (
    selectedIncident,
    isMod,
    panOff,
    tab,
    justMounted,
    preserveAttachedClips,
  ) => {
    if (!tab) {
      eventHub.emit("setTab", isMod ? "modalpha" : "clips")
    }
    if (tab === "modalpha" || (!tab && isMod)) {
      eventHub.emit(
        "modSelectIncident",
        selectedIncident || undefined,
        preserveAttachedClips,
      )
    } else {
      if (justMounted) {
        await eventHub.emit("modFetchIncidents")
      }
      await eventHub.emit("selectIncident", selectedIncident || undefined)
      if (selectedIncident && !panOff) {
        eventHub.emit("panIncident", selectedIncident)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedIncident,
      panOff,
      preserveAttachedClips,
      permissions,
      tab,
    } = this.props

    if (selectedIncident !== prevProps.selectedIncident) {
      this.setSelectedIncident(
        selectedIncident,
        permissions.citMod,
        panOff,
        tab,
        undefined,
        preserveAttachedClips,
      )
    }
  }

  render() {
    return (
      <div className='pane'>
        <ClipTooltip />
        <div className='content-subpane'>
          <ListenPanel />
          <Suspense fallback={<div>Loading...</div>}>
            <Moderation selectedIncidentProp={this.props.selectedIncident} />
          </Suspense>
          <ClipContentMask on={!!this.props.dialogMask} />
        </div>
      </div>
    )
  }
}

Clips.propTypes = {
  selectedIncident: string,
  preserveAttachedClips: bool,
  panOff: bool,
  // Global state
  dialogMask: bool,
  permissions: object,
  tab: string,
  user: object,
}

const ClipsHOC = props => {
  const { dialogMask, permissions, tab, user } = useGlobalState(state => {
    const selectedGlobal = objPick(state.global, ["user", "permissions", "tab"])
    const dialogMask = state.moderation.dialogMask
    return { ...selectedGlobal, dialogMask }
  })
  return (
    <Clips
      {...props}
      dialogMask={dialogMask}
      permissions={permissions}
      tab={tab}
      user={user}
    />
  )
}

export default ClipsHOC
