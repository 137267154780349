import React, { useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"

import { eventHub, useGlobalState } from "@guardian/State"

import LoadingScreen from "./LoadingScreen"

const LogoutScreen = () => {
  const history = useHistory()

  const user = useGlobalState(state => state.global.user)

  useEffect(() => {
    if (user && user.username) {
      eventHub.emit("internalLogout")
    } else {
      history.replace("/")
    }
  }, [user, history])

  return <LoadingScreen />
}

export default LogoutScreen
