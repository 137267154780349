import { useEffect } from "react"
import FilePlayer from "react-player/file"

import combokeys from "@guardian/Utils/hotkeys"

const useVideoPlayerKeyBindings = (
  playerRef: React.RefObject<FilePlayer>,
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>,
  predicateValue: boolean = true,
  skipSeconds: number = 3,
) => {
  useEffect(() => {
    combokeys.bind("space", (e: Event) => {
      if (predicateValue) {
        e.preventDefault()
        setPlaying(isPlaying => !isPlaying)
      }
    })
    combokeys.bind("left", () => {
      if (playerRef.current && predicateValue) {
        const currPosition = playerRef.current.getCurrentTime()
        playerRef.current.seekTo(
          Math.max(currPosition - skipSeconds, 0),
          "seconds",
        )
      }
    })
    combokeys.bind("right", () => {
      if (playerRef.current && predicateValue) {
        const currPosition = playerRef.current.getCurrentTime()
        const duration = playerRef.current.getDuration()
        playerRef.current.seekTo(
          Math.min(currPosition + skipSeconds, duration),
          "seconds",
        )
      }
    })

    return () => {
      combokeys.unbind("space")
      combokeys.unbind("left")
      combokeys.unbind("right")
    }
  }, [predicateValue, skipSeconds])
}

export default useVideoPlayerKeyBindings
