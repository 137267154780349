import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const XSmallTextStyles = css`
  font-size: var(--font-sizes-x-small);
  line-height: var(--line-heights-x-small);
`;

const XSmallText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__x-small-text")
    };
  }
)`
  ${ XSmallTextStyles }
`;

XSmallText.propTypes = {
  ...Text.propTypes
};

XSmallText.defaultProps = {};

export default XSmallText;
