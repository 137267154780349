import { groupBy, some } from "lodash"
import { func, string } from "prop-types"
import React, { useEffect, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import { objPick } from "@guardian/Utils/obj-pick"

import GroupingSelector from "@guardian/Components/ListeningGroupChannelSelector/GroupingSelector/GroupingSelector"
import {
  getSubAreas,
  sortSubAreas,
} from "@guardian/Components/ListeningGroupChannelSelector/GroupingSelector/GroupingSelector.helpers"

import "./styles.scss"

const ClipsNav = ({ filterText, handleInputChange }) => {
  const {
    allowedPilotProgram,
    channelSelectionGroup,
    subAreas: subAreaSequence,
    serviceAreas,
    activeGroups,
    listeningGroups,
  } = useGlobalState(state => {
    const selectedGlobal = objPick(state.global, ["allowedPilotProgram"])
    const selectedRadio = objPick(state.radio, [
      "channelSelectionGroup",
      "subAreas",
      "serviceAreas",
      "activeGroups",
    ])
    const { listeningGroups } = state.global.serviceAreaConfig
    return {
      ...selectedGlobal,
      ...selectedRadio,
      listeningGroups,
    }
  })

  const [selectedGrouping, setSelectedGrouping] = useState("")

  const [filtered, setFiltered] = useState([])
  const [groupingData, setGroupingData] = useState()

  useEffect(() => {
    if (!channelSelectionGroup) {
      eventHub.emit("setDialogMask", false)
    }
  }, [channelSelectionGroup])

  useEffect(() => {
    const mapping = {}
    const groupedListeningGroups = groupBy(listeningGroups, "groupingName")
    const filteredGroups = Object.entries(groupedListeningGroups).filter(
      ([grouping]) => !!grouping,
    )
    setFiltered(filteredGroups)
    filteredGroups.forEach(([grouping, listeningGroups]) => {
      mapping[grouping] = {}
      let showGrouping = true
      const sortedMap = {}
      const subAreaMap = {}
      const groupingPasses = !!grouping.toLowerCase().match(filterText)
      let listeningGroupPasses = false
      let subAreaPassesCheck = false
      let subAreaPasses = false
      let allListeningGroupsNoSubAreas = true

      listeningGroups.forEach(listeningGroup => {
        const allSubAreas = getSubAreas(
          Object.keys(listeningGroup.serviceAreas),
          serviceAreas,
        )
        subAreaPasses = some(allSubAreas, (v, sub) =>
          sub.toLowerCase().match(filterText),
        )

        if (subAreaPasses) {
          subAreaPassesCheck = true
        }

        const sorted = sortSubAreas(Object.keys(allSubAreas), subAreaSequence)
        sortedMap[listeningGroup.code] = sorted
        subAreaMap[listeningGroup.code] = allSubAreas
        if (sorted.length) {
          allListeningGroupsNoSubAreas = false
        }

        listeningGroupPasses =
          listeningGroupPasses ||
          some([listeningGroup.name, listeningGroup.longName], v =>
            v.toLowerCase().match(filterText),
          )
      })

      if (allListeningGroupsNoSubAreas) {
        showGrouping = false
      }

      mapping[grouping] = {
        listeningGroupPasses,
        sortedMap,
        subAreaMap,
        showGrouping,
        subAreaPasses: subAreaPassesCheck,
        groupingPasses,
      }
    })
    setGroupingData(mapping)
  }, [filterText])

  const onSelect = grouping => {
    if (grouping === selectedGrouping) {
      setSelectedGrouping("")
    } else {
      setSelectedGrouping(grouping)
    }
  }

  return (
    <div className='subnav'>
      <div className='channel-selectors'>
        {groupingData &&
          filtered.map(([grouping, listeningGroups], index) => {
            const {
              showGrouping,
              groupingPasses,
              listeningGroupPasses,
              subAreaPasses,
              subAreaMap,
              sortedMap,
            } = groupingData[grouping]

            if (
              !showGrouping ||
              !(groupingPasses || listeningGroupPasses || subAreaPasses)
            ) {
              return null
            }
            const spliceIndex = listeningGroups.findIndex(
              channel => channel.longName === "Las Vegas",
            )
            if (
              listeningGroups.length !== 0 &&
              !allowedPilotProgram &&
              spliceIndex !== -1
            ) {
              listeningGroups.splice(spliceIndex, 1)
            }
            return (
              <GroupingSelector
                key={grouping}
                onSelect={onSelect}
                grouping={grouping}
                listeningGroups={listeningGroups}
                selected={
                  selectedGrouping === grouping ||
                  !!(filterText && (listeningGroupPasses || subAreaPasses))
                }
                lastInList={index === filtered.length - 1}
                subAreaMap={subAreaMap}
                sortedMap={sortedMap}
                filterText={filterText}
                groupingPassesFilter={groupingPasses}
                handleInputChange={handleInputChange}
                selectAllSubAreas={activeGroups.has(grouping)}
              />
            )
          })}
      </div>
      <div className='city-stats' />
    </div>
  )
}

ClipsNav.propTypes = {
  filterText: string,
  handleInputChange: func,
}

export default ClipsNav
