import classNames from "classnames";
import styled from "styled-components";

const DialogMessages = styled.div.attrs(
  props => {
    return {
      className: classNames(
        "alert__dialog-messages",
        // NOTE: Be aware of this should we have issues with closing elements
        // that use `react-onclickoutside`. We may want to consider such an
        // approach for a more targeted one. For now we are making things simple
        // by just saying "dont consider it a click outside *ever* if the click
        // happens on this element".
        "ignore-react-onclickoutside",
      )
    };
  }
)`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: var(--space-medium);
  bottom: var(--space-medium);
  max-width: 300px;
  width: 100%;
  z-index: 2000;
`;

DialogMessages.propTypes = {};

DialogMessages.defaultProps = {};

export default DialogMessages;
