const audio = document.createElement("audio")
const playAac = audio.canPlayType("audio/aac") === "probably"

const healthColors = ["#ed2323", "#ff880a", "#ffd52b", "#10cf3b"]

export default {
  Visibility: {
    Hidden: 0,
    Guardian: 2,
    Public: 4,
  },
  playAac,
  healthColors,
  Source: {
    Radio: "radio",
    IGL: "igl",
  },
  ResolvedStatus: {
    Rejected: "REJECTED",
    Merged: "MERGED",
    Confirmed: "CONFIRMED",
  },
  AutomatedUsername: "ai_mod_bot",
  CreatorUploadLabel: "app_uploaded_from_creator",
  GoodVibeCategory: "Other: Good News",
  MileToMeter: 1609.344,
}
