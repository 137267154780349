/*
 * Grid
 *
 * The following theme specification is defined according to the theme
 * specification of styled-bootstrap-grid.
 *
 * See: https://github.com/dragma/styled-bootstrap-grid
 *
 */
const grid = {};

grid.gridColumns = 16;

grid.breakpoints = {
  xxl: 1440,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  // NOTE: We changed this from the default 575 because it seems to be causing
  // odd breakpoint behaviour, as SBG seems to want to do something like
  //`max-width: [breakpoints.xs - 1px]` for the xs breakpoint, leading to a
  // situation where there is a 1 pixel "gap" between the sm and xs breakpoints.
  xs: 576
};

grid.col = {
  padding: 12
};

grid.container = {
  padding: 12,
  maxWidth: {
    veryGiant: 1141,
    giant: 1140,
    desktop: 960,
    tablet: 720,
    phone: 540,
    smaller: 540
  }
};

grid.row = {
  padding: 12
};

export const gridCSSVariables = `
  :root {
    --grid-grid-columns: ${ grid.gridColumns };

    --grid-breakpoints-xxl: ${ grid.breakpoints.xxl };
    --grid-breakpoints-xl: ${ grid.breakpoints.xl };
    --grid-breakpoints-lg: ${ grid.breakpoints.lg };
    --grid-breakpoints-md: ${ grid.breakpoints.md };
    --grid-breakpoints-sm: ${ grid.breakpoints.sm };
    --grid-breakpoints-xs: ${ grid.breakpoints.xs };

    --grid-col-padding: ${ grid.col.padding };

    --grid-container-padding: ${ grid.container.padding };
    --grid-container-max-width-very-giant: ${ grid.container.maxWidth.veryGiant };
    --grid-container-max-width-giant: ${ grid.container.maxWidth.giant };
    --grid-container-max-width-desktop: ${ grid.container.maxWidth.desktop };
    --grid-container-max-width-tablet: ${ grid.container.maxWidth.tablet };
    --grid-container-max-width-phone: ${ grid.container.maxWidth.phone };
    --grid-container-max-width-smaller: ${ grid.container.maxWidth.smaller };

    --grid-row-padding: ${ grid.row.padding };
  }
`;

export default grid;
