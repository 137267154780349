import React from "react";

import { Spinner } from "@guardian/UI/Loader";
import { VirtualScroll } from "@guardian/UI/VirtualScroll";

import MessageGroup from "./components/MessageGroup";
import BeginningOfHistory from "./views/BeginningOfHistory";
import LoadingMore from "./views/LoadingMore";
import StyledMessageGroups from "./views/MessageGroups";
import MessageGroupWrapper from "./views/MessageGroupWrapper";

const MessageGroups = React.memo(function MessageGroups(props) {
  const {
    canLoadMore,
    messageGroups,
    onScrolledToEnd,
    session: {
      members,
      userId
    }
  } = props;

  const fullName = members && members[userId] ? members[userId].fullName : '[unknown member for userId ' + userId + ']'

  // Estimated size for items used by virtualizer when guessing heights and
  // offsets for items prior to measuring them.
  const estimateSize = 74;

  // Keep this a little high. It "fixes" a bug where content may not get
  // adjusted to be visual when initially loading only a small amount of data.
  const overscanSize = 50;

  const getRowKey = (index) => {
    if (index > messageGroups.length - 1) {
      if (canLoadMore) {
        return "load-more-row";
      } else {
        return "beginning-row";
      }
    }

    const messageGroup = messageGroups[index];

    return messageGroup.updateId;
  };

  const renderRow = (index) => {
    if (index > messageGroups.length - 1) {
      if (canLoadMore) {
        return (
          <LoadingMore><Spinner/></LoadingMore>
        );
      } else {
        return (
          <BeginningOfHistory>
            {
              `Beginning of the Chat History with ${ fullName }`
            }
          </BeginningOfHistory>
        );
      }
    }

    const messageGroup = messageGroups[index];

    // isFirst is used to apply padding to the entire list at the very bottom.
    // This is necessary as we want the scroll area to have padding at the
    // bottom, but our virtualized list's sizing is entirely contingent on the
    // total height of its elements, and no additional padding itself. Hence,
    // we must account for this at the level of list elements. Keep in mind that
    // our virtualized list is _reversed_, so the "first" element, is the last
    // message received, and will appear at the bottom of the chat window (our
    // virtualized list).
    return (
      <MessageGroupWrapper isFirst={index === 0}>
        <MessageGroup
          members={members}
          messageGroup={messageGroup}
        />
      </MessageGroupWrapper>
    );
  };

  return (
    <StyledMessageGroups>
      <VirtualScroll
        canLoadMore={true}
        estimateSize={estimateSize}
        getRowKey={getRowKey}
        renderRow={renderRow}
        rowCount={messageGroups.length}
        onScrolledToEnd={onScrolledToEnd}
        overscanSize={overscanSize}
        reverse={true}
        reverseOptions={
          {
            // Make messages sit at top of list until there are enough to fill
            // container, like any other messaging app.
            startAtTop: true
          }
        }
      />
    </StyledMessageGroups>
  );
});

export default MessageGroups;
