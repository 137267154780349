import dayjs from "dayjs"

export default (option, dayjsClass, dayjsFactory) => {
  const options = Object.assign(
    {
      formats: {
        sameDay: "[Today$delimiter] HH:mm",
        lastDay: "[Yesterday$delimiter] HH:mm",
        nextDay: "[Tomorrow$delimiter] HH:mm",
        nextWeek: "dddd[$delimiter] HH:mm",
        lastWeek: "[Last] dddd[$delimiter] HH:mm",
        sameElse: "MM/DD/YYYY",
      },
    },
    option,
  )

  dayjsClass.getCalendarFormat = function(mydayjs, delimiter) {
    var diff = mydayjs.diff(dayjs().startOf("day"), "days", true)
    let key =
      diff < -6
        ? "sameElse"
        : diff < -1
        ? "lastWeek"
        : diff < 0
        ? "lastDay"
        : diff < 1
        ? "sameDay"
        : diff < 2
        ? "nextDay"
        : diff < 7
        ? "nextWeek"
        : "sameElse"
    return options.formats[key || "sameElse"].replace(
      "$delimiter",
      delimiter || "",
    )
  }

  dayjsClass.prototype.calendar = function(delimiter) {
    let format = dayjsClass.getCalendarFormat(this, delimiter)
    return this.format(format)
  }
}
