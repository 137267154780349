import { bool, number, object, string } from "prop-types"
import React from "react"

import { eventHub } from "@guardian/State"

const Channel = ({
  id,
  name,
  starred,
  listeners,
  activeChannels,
  mutes,
  flagged,
}) => {
  const toggle = () => eventHub.emit("toggleChannel", id)
  const isActive = activeChannels[id] || false
  let active = ""
  if (mutes[id]) {
    active = "semi-active"
  } else if (isActive) {
    active = "active"
  }
  const isolateOn = Object.keys(mutes).length > 0

  return (
    <div
      className={"channel " + active + (isolateOn ? " iso-on" : "")}
      onClick={toggle}
    >
      <div className='channel-header'>
        <div className='star'>{starred && <i className='fas fa-star' />}</div>
        <div>{name}</div>
      </div>
      <div>
        {flagged > 0 && (
          <span style={{ color: "#FFD52B" }} className='flagged'>
            {flagged}
            <i className='fas fa-arrow-up' />
          </span>
        )}
      </div>
    </div>
  )
}

Channel.propTypes = {
  id: string,
  name: string,
  starred: bool,
  listeners: number,
  activeChannels: object,
  mutes: object,
  flagged: number,
}

export default Channel
