import { isPointInside } from "geolib"

import { Geo, Signal } from "@guardian/API/Optimus";

export const isPointInChannelPoly = (location, multipoly) => {
  return multipoly.some(poly =>
    isPointInside(
      { latitude: location.lat, longitude: location.lng },
      poly[0].map(([longitude, latitude]) => ({ latitude, longitude })),
    ),
  )
}

const extractLocation = address => {
  return address.split(",")[0]
}

const fetchNeighborhood = async (location, city, nbrhd) => {
  let neighborhood
  try {
    const { lat, lng } = location
    const { data: { results } } = await Geo.getNeighborhoods(
      parseFloat(lat),
      parseFloat(lng)
    )
    const [first] = results
    first.city = first.borough || first.city
    neighborhood = `${first.name}, ${first.city}`
  } catch (e) {
    neighborhood = await extractNeighborhood(city, nbrhd)
  }
  return neighborhood
}

export const fetchServiceAreaCode = async ({ location, ...rest }) => {
  try {
    const { lat, lng } = location
    const { data: results } = await Signal.getServiceAreaCode(
      parseFloat(lat),
      parseFloat(lng)
    )
    const { code } = results
    return code === "" ? null : code
  } catch (e) {
    return null
  }
}

export const fetchPolice = async ({ location, ...rest }) => {
  try {
    let { lat, lng } = location
    const { data: { results } } = await Geo.getPrecincts(
      parseFloat(lat),
      parseFloat(lng)
    )
    const [first] = results
    return first.name
  } catch (e) {
    return null
  }
}

export const extractNeighborhood = (city, nbrhd) => {
  let res = city
  if (nbrhd) {
    res = `${nbrhd}, ${city}`
  }
  return res
}

const getFeatures = async (address, latLng, city, nbrhd) => {
  const [location, neighborhood, cityCode, police] = await Promise.all([
    extractLocation(address),
    fetchNeighborhood(latLng, city, nbrhd),
    fetchServiceAreaCode({ location: latLng }),
    fetchPolice({ location: latLng }),
  ])
  return {
    location,
    neighborhood,
    cityCode,
    police,
  }
}

export const extract = async place => {
  if (!place) {
    return false
  }

  const features = await getFeatures(
    place.address,
    place.location,
    place.attributes.City,
    place.attributes.Nbrhd,
  )
  features.address = place.address

  return features
}

export const extractReverse = async (latLng, reverseGeoRes) => {
  if (!latLng || !reverseGeoRes) {
    return false
  }

  const formattedAddr = reverseGeoRes.LongLabel.substring(
    reverseGeoRes.LongLabel.indexOf(reverseGeoRes.Address),
  )

  const features = await getFeatures(
    reverseGeoRes.Address,
    latLng,
    reverseGeoRes.City,
    reverseGeoRes.Neighborhood,
  )
  features.address = formattedAddr

  return features
}

export default {
  extract,
  extractReverse,
}
