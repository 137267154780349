import styled from "styled-components";

const UserDropdownInfo = styled.span`
  align-items: center;
  display: flex;
  padding: var(--space-x-small) var(--space-medium);
  border-top: 1px solid #333333;
  color: rgb(179, 179, 179);
	font-size: 0.75rem;
`;

export default UserDropdownInfo;
