import React from "react"

import { BadgeContainer } from "./styles"

const GoodVibesBadge = props => (
  <BadgeContainer bgColor='#000000' textColor='#34A853' {...props}>
    <img alt='' src='/IGL-GoodVibes.svg' />
    Good Vibes
  </BadgeContainer>
)

export default GoodVibesBadge
