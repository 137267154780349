import styled from "styled-components";
import classNames from "classnames";
import { flexbox, layout, space } from "styled-system";

export const Box = styled("div").attrs(
  props => ({
    className: classNames("box")
  })
)`
  ${ flexbox }
  ${ layout }
  ${ space }
`;

Box.propTypes = {
  ...flexbox.propTypes,
  ...layout.propTypes,
  ...space.propTypes
};

Box.defaultProps = {};

export default Box;
