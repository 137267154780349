import React from "react";

import SystemMessage from "./components/SystemMessage";
import StyledSystemMessageGroup from "./views/SystemMessageGroup";

const SystemMessageGroup = props => {
  const {
    messageGroup: {
      messages
    }
  } = props;

  return (
    <StyledSystemMessageGroup>
      {
        messages.map((message) => (
          <SystemMessage
            key={`human-message-${ message.id }`}
            message={message}
          />
        ))
      }
    </StyledSystemMessageGroup>
  );
};

export default React.memo(SystemMessageGroup);
