import React, { memo } from "react"
import { string, func, bool } from "prop-types"
import style from "./styles.module.css"
import VideoPlayer from "@guardian/Components/VideoPlayer/VideoPlayer"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { shouldReplace } from "@guardian/Utils/history"

const Stream = ({
  id,
  url,
  onBlock,
  onVerify,
  verified,
  blocked,
  incidentTitle,
  serviceArea,
  analyst,
  incidentId,
  broadcaster,
  isLive,
  showPublicUrl,
}) => {
  const path = `/incidents/${incidentId}`

  return (
    <div className={style.container}>
      <div className={style.video}>
        <VideoPlayer
          key={id}
          url={isLive ? url.replace("ngrp:", "").replace("_all", "_720p") : url}
          live={isLive}
          autoPlay={isLive}
          defaultMuted
        />
        <div className={style.controls}>
          {onBlock && (
            <button
              className={classnames(style.block, { [style.active]: blocked })}
              disabled={blocked}
              onClick={() => onBlock(id)}
            >
              {blocked ? "blocked" : "block"}
            </button>
          )}
          {onVerify && (
            <button
              className={classnames(style.verify, { [style.active]: verified })}
              onClick={() => onVerify(id)}
            >
              {verified ? "Verified" : "Verify"}
            </button>
          )}
          {showPublicUrl && (
            <button
              onClick={() =>
                window.open(`https://www.citizen.com/streams/${id}`, "_blank")
              }
            >
              <i className='fas fa-share' />
            </button>
          )}
        </div>
        <div className={style.broadcaster}>@{broadcaster}</div>
      </div>
      <div className={style.info}>
        <h5>
          {serviceArea || "N/A"}
          {analyst ? " • @" : ""}
          {analyst}
        </h5>
        {incidentTitle ? (
          <Link to={path} replace={shouldReplace(path)}>
            <h3>{incidentTitle}</h3>
          </Link>
        ) : (
          <h3>No incident</h3>
        )}
      </div>
    </div>
  )
}

Stream.propTypes = {
  id: string.isRequired,
  url: string.isRequired,
  isLive: bool.isRequired,
  onBlock: func,
  onVerify: func,
  verified: bool,
  blocked: bool,
  incidentTitle: string,
  serviceArea: string,
  analyst: string,
  incidentId: string,
  broadcaster: string.isRequired,
  showPublicUrl: bool,
}

export default memo(Stream)
