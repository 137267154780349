import React, { useEffect, useRef, useState } from "react";

import { webSockets } from "@guardian/Sockets";
import { AgentService } from "@guardian/Services/Agent";
import { AgentChatService } from "@guardian/Services/AgentChat";
import { LoggingService } from "@guardian/Services/Logging";
import { Dialog } from "@guardian/UI/Alert";

import {
  generateAssignedAgentChatSession,
  generateAssignedAgentChatSessions
} from "./utils/generateAssignedAgentChatSession";
import promiseAllInBatches from "./utils/promiseAllInBatches";
import sortAssignedAgentChatSessions from
  "./utils/sortAssignedAgentChatSessions";

const useAssignedAgentChatSessions = (options) => {
  const {
    enabled
  } = options;

  const [
    assignedAgentChatSessions,
    setAssignedAgentChatSessions
  ] = useState([]);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleSessionId, setVisibleSessionId] = useState(null);

  const assignedAgentChatSessionsRef = useRef(assignedAgentChatSessions);
  const visibleSessionIdRef = useRef(visibleSessionId);

  useEffect(() => {
    visibleSessionIdRef.current = visibleSessionId;
  }, [visibleSessionId]);

  // Extract sessions that are currently ongoing (e.g.; haven't been ended by
  // either the agent or the user), and use these to get an accurate unread
  // count and to alert users when sessions have ended.
  useEffect(() => {
    const previousSessions = assignedAgentChatSessionsRef.current;
    let totalUnreadCount = 0;

    for (const session of assignedAgentChatSessions) {
      if (session.endedAt != null) {
        const previous = previousSessions.find(s => s.id === session.id);
        const endedSinceLastSeen = previous && previous.endedAt == null;

        // If ended since we last saw it - which shouldn't be the case on a page
        // reload, for example - let the agent know.
        if (endedSinceLastSeen) {
          const user = session.members[session.userId];

          let endedAtReason;

          switch (session.endedAtReason) {
            case "endedByUser":
              endedAtReason = "User ended session.";
              break;
            case "endedByAgent":
              endedAtReason = "Agent ended session.";
              break;
            case "liveCallByUser":
              endedAtReason = "User started a live call.";
              break;
            case "liveCallByAgent":
              endedAtReason = "Agent started a live call.";
              break;
            case "reassigned":
              endedAtReason = "Reassigned to another Agent.";
              break;
            case "creationError":
              endedAtReason = "An error occurred while creating session.";
              break;
            case "agentOffline":
              endedAtReason = "Agent went offline.";
              break;
            case "archivingUser":
              endedAtReason = "User was archived.";
              break;
            default:
              endedAtReason =
                `Backend gave unknown reason, "${ session.endedAtReason }".`;
          };

          Dialog.info(
            (
              <div>
                Your conversation with { user.fullName } has ended.<br/>
                Reason: { endedAtReason }
              </div>
            ),
            {
              title: "Chat Ended"
            }
          );
        }

        continue;
      }

      // Account for the fact that the backend doesnt consider a "chat started"
      // event as increasing the unread count, which it should.
      if (session.unreadStatus === "chatStarted") {
        session.unreadCount += 1;
      }

      totalUnreadCount += session.unreadCount;
    }

    setTotalUnreadCount(totalUnreadCount);
    assignedAgentChatSessionsRef.current = assignedAgentChatSessions;
  }, [assignedAgentChatSessions]);

  useEffect(() => {
    if (typeof enabled !== "undefined" && !enabled) { return; }

    const controller = new AbortController();
    const signal = controller.signal;
    const subscription = {
      method: "subscribeAssignedChatSessions",
      value: { agentId: AgentService.userId },
    };

    const setSessionsSorted = (sessions) => {
      const sortedSessions = sortAssignedAgentChatSessions(sessions);

      // TODO: This is for debugging until we have a stable official release.
      // Please delete this once we go to production for real users.
      if (window.DEBUG_ASSIGNED_CHAT_SESSIONS_FORMATTED) {
        console.log("++++++ FORMATTED ASSIGNED CHAT SESSIONS UPDATED ++++++");
        console.log(sortedSessions);
      }

      setAssignedAgentChatSessions(sortedSessions);
    };

    const fetchAssignedChatSessions = () => {
      return AgentChatService.getAssignedChatSessions({ signal });
    };

    const fetchGroupInfo = (groupId) => {
      return AgentChatService.getGroupInfo(
        groupId,
        { signal }
      );
    };

    const fetchAndAddSupplementaryDataToSession = (session) => {
      return new Promise((resolve, reject) => {
        fetchGroupInfo(session.groupId)
          .then(({ members }) => {
            // Add session members to session payload exposed to our components,
            // accessible by their id.
            session.members = members.reduce((memo, member) => {
              memo[member.userId] = member;
              return memo;
            }, {});

            resolve(session);
          })
          .catch(reject)
      });
    };

    const fetchAndAddSupplementaryDataToAllSessions = (sessions) => {
      return promiseAllInBatches(
        fetchAndAddSupplementaryDataToSession,
        sessions,
        10
      );
    };

    const fetchInitialSessions = () => {
      return new Promise((resolve, reject) => {
        fetchAssignedChatSessions()
          .then(({ assignedAgentChatSessions: rawSessions }) => {
            const sessions = generateAssignedAgentChatSessions(rawSessions);
            return fetchAndAddSupplementaryDataToAllSessions(sessions);
          })
          .then(sessions => {
            resolve(sessions);
          })
          .catch(reject);
      });
    };

    const handleUpdates = ({ payload: { value: rawSession } }) => {
      const sessions = assignedAgentChatSessionsRef.current;
      const session = generateAssignedAgentChatSession(rawSession);
      const sessionIndex = sessions.findIndex(({ id }) => id === session.id);
      const visibleSessionId = visibleSessionIdRef.current;

      // TODO: This is for debugging until we have a stable official release.
      // Please delete this once we go to production for real users.
      if (window.DEBUG_ASSIGNED_CHAT_SESSION_RAW) {
        console.log("++++++ RAW ASSIGNED CHAT SESSION UPDATED ++++++");
        console.log(rawSession);
      }

      // Prevent any counts from updating for visible sessions (i.e.; the
      // session an agent may currently have open and visible on their screen).
      // This will not however send a notification to the backend that a given
      // message was read, as that is the responsibility of the code receiving
      // messages and the backend expects to receive read status updates on a
      // per message basis, and not for the entirety of the session.
      if (session.id === visibleSessionId) {
        session.unreadCount = 0;
        session.unreadStatus = null;
      }

      if (sessionIndex == -1) {
        // Note: For now we only fetch supplementary data on newly assigned
        // sessions in order to minimize roundtrips. This does mean that such
        // data wont be updated once loaded, however. Should we need more
        // detailed updates, we should consider detecting types of changes
        // made to already assigned sessions and/or polling.
        fetchAndAddSupplementaryDataToSession(session)
          .then(session => {
            const newSessions = [session, ...sessions];
            setSessionsSorted(newSessions);
          })
          .catch(error => {
            if (signal.aborted) {
              return;
            }

            LoggingService.logError(
              `An error occured fetching assigned chat session with id ${ session.id }!`,
              {
                domain: "AppSystem",
                method: "useAssignedAgentChatSessions",
                dialog: true,
                trackedData: {
                  error: error,
                  session: rawSession
                }
              }
            );
          })
      } else {
        const prevSessionValues = sessions[sessionIndex];
        const updatedSession = { ...prevSessionValues, ...session };
        // Note: While this is a little torturous to read, we are efficiently
        // replacing the previous value for the session with the new value for
        // the session within our assignedAgentChatSessions array.
        const newSessions =
          Object.assign([], sessions, { [sessionIndex]: updatedSession });
        setSessionsSorted(newSessions);
      }
    };

    const listenForUpdatesToSessions = () => {
      webSockets.sub(subscription);
      webSockets.onEvent("chatQueueEvent", handleUpdates);
    };

    const initialize = () => {
      fetchInitialSessions()
        .then(sessions => {
          setSessionsSorted(sessions);
          listenForUpdatesToSessions();
        })
        .catch(error => {
          if (signal.aborted) {
            return;
          }

          LoggingService.logError(
            `An error occured fetching initial assigned chat sessions!`,
            {
              domain: "AppSystem",
              method: "useAssignedAgentChatSessions",
              dialog: true,
              trackedData: {
                error: error
              }
            }
          );

          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        })
    };

    initialize();

    return () => {
      // Abort any open request.
      controller.abort();

      // Close listeners.
      webSockets.removeEvent("chatQueueEvent", handleUpdates)
      webSockets.removeSub(subscription, subscription)
    }
  }, [enabled]);

  return {
    assignedAgentChatSessions,
    isError,
    isLoading,
    setVisibleSessionId,
    totalUnreadCount,
    visibleSessionId
  };
};

export default useAssignedAgentChatSessions;
