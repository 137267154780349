import { TSOSDashboardContextActionType } from "@guardian/Components/ModSOS/store/dashboard/types"

export const updateSessionResponse = (data: any) => ({
  type: TSOSDashboardContextActionType.UpdateSessionResp,
  data,
})

export const update911InSession = (data: any) => ({
  type: TSOSDashboardContextActionType.Update911InSession,
  data,
})

export const resetDashboardContext = (data?: any) => ({
  type: TSOSDashboardContextActionType.Reset,
  data,
})

export const updateSessionRecords = (data: any) => ({
  type: TSOSDashboardContextActionType.UpdateSessionRecords,
  data,
})

export const updateOwnerData = (data: any) => ({
  type: TSOSDashboardContextActionType.UpdateOwnerData,
  data,
})

export const updateActiveSessionId = (data: any) => ({
  type: TSOSDashboardContextActionType.UpdateActiveSessionId,
  data,
})

export const updateCallSession = (data?: {
  id?: any
  status?: any
  metadata?: any
}) => ({
  type: TSOSDashboardContextActionType.UpdateCallSession,
  data,
})

export const showErrorModal = (data: { type: any; metadata: any }) => ({
  type: TSOSDashboardContextActionType.ShowErrorModal,
  data,
})

export const closeErrorModal = () => ({
  type: TSOSDashboardContextActionType.CloseErrorModal,
})
