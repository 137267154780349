import React from "react"
import { array, string } from "prop-types"

import consts from "@guardian/Constants"
import useSmoothScrollToActive from "@guardian/Hooks/useSmoothScrollToActive"

import { composeUrl, getContentId } from "../ModIGL.helper"
import style from "./ContentList.module.css"
import ContentPreview from "./ContentPreview"

const ContentList = ({ content, activeContentId }) => {
  const { listRef, activeContentRef } = useSmoothScrollToActive(activeContentId)

  return (
    <div ref={listRef} className={style.list}>
      {content.map(c => {
        switch (c.type) {
          case "video_stream": {
            const contentId = getContentId(c)
            const isActive = activeContentId === contentId
            return (
              <ContentPreview
                key={contentId}
                ref={isActive ? activeContentRef : null}
                type='video_stream'
                username={c.ugcMeta.username || c.videoStream.username}
                address={c.ugcMeta.address}
                isDone={
                  !!(c.videoStream.broadcastDone || c.videoStream.hlsDone)
                }
                isUpload={c.ugcMeta.label === consts.CreatorUploadLabel}
                isCreator={!!c.videoStream.isReporter}
                isAnonymous={c.ugcMeta.isAnonymous}
                isGoodVibes={
                  c.videoStream.reportCategory === consts.GoodVibeCategory
                }
                createdAt={c.videoStream.createdAt}
                thumbUrl={c.videoStream.hlsLiveThumbUrl}
                active={isActive}
                id={contentId}
                durationSeconds={Math.round(c.videoStream.durationSeconds)}
                title={c.videoStream.reportCategory}
                contentUrl={composeUrl("video_stream", contentId)}
              />
            )
          }
          case "image": {
            const contentId = getContentId(c)
            const isActive = activeContentId === contentId
            return (
              <ContentPreview
                key={contentId}
                ref={isActive ? activeContentRef : null}
                type='image'
                username={c.image.username}
                address={c.ugcMeta.address}
                createdAt={c.ugcMeta.createdAt}
                thumbUrl={c.image.thumbUrl}
                active={isActive}
                id={contentId}
                contentUrl={composeUrl("image", contentId)}
              />
            )
          }
        }
      })}
    </div>
  )
}

ContentList.propTypes = {
  content: array,
  activeContentId: string,
}

export default ContentList
