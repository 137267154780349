import React from "react"
import _ from "lodash"
import { OTSubscriber } from "@guardian/Components/ModSOS/components/Video/OTP/OTSubscriber"
import { TwilioSubscriber } from "@guardian/Components/ModSOS/components/Video/Twillio/TwilioSubscriber"
import { useSOSVideoContext } from "@guardian/Components/ModSOS/store/video/VideoContext"
import { sendTwilioMessage } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.helper"
import { patchVideoControl } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { IPropsVideoSubscriber } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.types"
import { updateUserMicDisabled } from "@guardian/Components/ModSOS/store/video/actions/video"
import { handleAudiError } from "@guardian/Components/ModSOS/store/helpers"

export const SOSVideoSubscriber: React.FunctionComponent<IPropsVideoSubscriber> = ({
  tokboxSession,
  tokboxStream,
  twilioParticipant,
  userMicDisabled,
  passthroughProps,
}) => {
  const { state, dispatch } = useSOSVideoContext()
  const muteUserMic: (connection: string, hasAudio: boolean) => void = async (
    connection,
    hasAudio,
  ) => {
    const message = {
      type: "mute",
      to: connection,
      data: hasAudio ? "disabled" : "enabled", // if hasAudio, send signal to turn off audio
    }
    if (state.videoProvider === "twilio") {
      await sendTwilioMessage(state.twilioRoom, message)
    } else if (state.videoProvider === "tokbox") {
      state.tokboxSession.session.signal(message)
    }

    patchVideoControl(state.activeSessionId, {
      userMicOn: !hasAudio,
    }).toPromise().catch(handleAudiError('microphone', !hasAudio))

    dispatch(updateUserMicDisabled(hasAudio))
  }

  if (!_.isEmpty(twilioParticipant)) {
    return (
      <TwilioSubscriber
        participant={twilioParticipant}
        isUserMicrophoneDisabled={userMicDisabled}
        muteUserMicrophone={muteUserMic}
        {...passthroughProps}
        callToNumber={passthroughProps.ownerData?.call?.to}
        isUserMicrophoneEnabled={_.get(
          passthroughProps.ownerData,
          "ownerData.userSettings.microphone_allowed",
        )}
        isUserCameraEnabled={_.get(
          passthroughProps.ownerData,
          "ownerData.userSettings.camera_allowed",
        )}
      />
    )
  }

  if (!_.isEmpty(tokboxStream)) {
    return (
      <OTSubscriber
        tokboxSession={tokboxSession}
        stream={tokboxStream}
        muteUserMic={muteUserMic}
        {...passthroughProps}
      />
    )
  }

  return null
}
