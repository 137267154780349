import { MegatronAPI } from "@guardian/API/Megatron";

const Device = {
  getDeviceClips: (deviceId: string, options?: any) => {
    return MegatronAPI.get(`/radio/${ deviceId }/clips`, options);
  },

  getDeviceStatus: (deviceId: string, options?: any) => {
    return MegatronAPI.get(`/radio/${ deviceId }/status`, options);
  },

  getNetworkStatus: (deviceId: string, options?: any) => {
    return MegatronAPI.get(`/radio/${ deviceId }/network`, options);
  }
};

export default Device;
