import React from "react";

import AgentMessageGroup from "./components/AgentMessageGroup";
import UserMessageGroup from "./components/UserMessageGroup";

const HumanMessageGroup = props => {
  const { members, messageGroup } = props;

  const renderAgentMessageGroup = (messageGroup) => {
    return (
      <AgentMessageGroup
        messageGroup={messageGroup}
        userId={messageGroup.authorId}
        userInfo={members[messageGroup.authorId]}
      />
    );
  };

  const renderUserMessageGroup = (messageGroup) => {
    return (
      <UserMessageGroup
        messageGroup={messageGroup}
        userId={messageGroup.authorId}
        userInfo={members[messageGroup.authorId]}
      />
    );
  };

  const renderMessageGroup = messageGroup => {
    const isAgentMessage = messageGroup.authorId.startsWith("agent_id_");

    let renderedMessageGroup;
    if (isAgentMessage) {
      renderedMessageGroup = renderAgentMessageGroup(messageGroup);
    } else {
      renderedMessageGroup = renderUserMessageGroup(messageGroup);
    }

    return renderedMessageGroup;
  };

  return (
    <React.Fragment>
      {
        renderMessageGroup(messageGroup)
      }
    </React.Fragment>
  );
};

export default React.memo(HumanMessageGroup);
