import classNames from "classnames";
import styled from "styled-components";

const ContentRow = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__activity__list-item__content-row"
    )
  };
})`
  display: flex;
  flex-direction: row;
  gap: var(--space-xx-small);
  min-width: 0;
  width: 100%;

  & > :not(:first-child) {
    flex-shrink: 0;
  }
`;

ContentRow.defaultProps = {};

export default ContentRow;
