import React, { useState, memo, useEffect } from "react"
import { string, bool } from "prop-types"
import { timer, defer } from "rxjs"
import Thumbnail from "@guardian/Components/Thumbnail"

import { VideoStreams } from "@guardian/API/Optimus";
import { IncidentService } from "@guardian/Services/Incident";
import { getThumbUrl } from "@guardian/Utils/util"

const ThumbnailList = ({
  id,
  broadcastDone,
  hlsDone,
  selectedThumb,
  incidentId,
  incidentThumb,
}) => {
  const [thumbs, setThumbs] = useState([])
  const [selected, setSelected] = useState(selectedThumb)
  const [leaderThumb, setLeaderThumb] = useState(getThumbUrl(incidentThumb))

  useEffect(() => {
    if (!selected) {
      setSelected(selectedThumb)
    }
  }, [selected, selectedThumb])

  useEffect(() => {
    setSelected(selectedThumb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const subscription = defer(() =>
      VideoStreams.getStreamThumbs(id)
    ).subscribe(({ data: { images } }) => {
      setThumbs(images)
    })

    let liveSubscription
    if (!broadcastDone || !hlsDone) {
      liveSubscription = timer(0, 5000).subscribe(() => {
        defer(() =>
          VideoStreams.getStreamThumbs(id)
        ).subscribe(({ data: { images } }) => {
          setThumbs(images)
        })
      })
    }

    return () => {
      subscription.unsubscribe()
      if (liveSubscription) {
        liveSubscription.unsubscribe()
      }
    }
  }, [id, broadcastDone, hlsDone])

  return (
    <div>
      {thumbs.map(thumb => (
        <Thumbnail
          key={thumb}
          image={thumb}
          onClick={async url => {
            setSelected(url)
            await VideoStreams.setStreamThumb(id, { image: url })
          }}
          onLeaderClick={async url => {
            if (url !== leaderThumb) {
              await IncidentService.updateIncidentThumbnail(incidentId, {
                verticalThumbnail: url,
                horizontalThumbnail: url,
                y: 0,
              })
              setLeaderThumb(url)
            }
          }}
          active={selected === thumb}
          isLeader={leaderThumb === thumb}
        />
      ))}
    </div>
  )
}

ThumbnailList.propTypes = {
  id: string,
  broadcastDone: bool,
  hlsDone: bool,
  selectedThumb: string,
  incidentId: string,
  incidentThumb: string,
}

export default memo(ThumbnailList)
