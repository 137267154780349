import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const MenuSeparator = styled.li.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "dropdown__menu-separator")
    };
  }
)`
  background-image:
    linear-gradient(
      to right,
      var(--colors-grey-085) 0%,
      var(--colors-grey-085) 100%,
      var(--colors-transparent) 100%
    );
  background-origin: border-box;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  height: var(--space-x-small);
`;

MenuSeparator.defaultProps = {};

export default MenuSeparator;
