import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledToggle = styled.button.attrs(
  props => {
    const {
      active,
      disabled,
      focused,
      hovered,
      isOpen
    } = props;

    return {
      className: classNames("dropdown__select-toggle-button", {
          "dropdown__select-toggle-button--is-active": active,
          "dropdown__select-toggle-button--is-disabled": disabled,
          "dropdown__select-toggle-button--is-focused": focused,
          "dropdown__select-toggle-button--is-hovered": hovered,
          "dropdown__select-toggle-button--is-open": isOpen
        })
    };
  }
)`
  ${ buttonResets }

  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;

  &::before, &::after {
    background-color: transparent;
    border-bottom: 7px solid var(--colors-grey-050);
    content: "";
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(0);
    transition: 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 1.5px;
  }

  &::before {
    transform: rotate(-45deg);
    left: 50%;
    top: calc(50% - 3px);
  }

  &::after {
    transform: rotate(45deg);
    left: calc(50% - 4px);
    top: calc(50% - 3px);
  }

  &:active, &.dropdown__select-toggle-button--is-active,
  &:hover, &.dropdown__select-toggle-button--is-hovered
  {
    &::before {
      border-bottom: 7px solid var(--colors-grey-030);
    }

    &::after {
      border-bottom: 7px solid var(--colors-grey-030);
    }
  }

  // :focus managed by KeyboardFocusContainer
  &.dropdown__select-toggle-button--is-focused
  {
    &::before {
      border-bottom: 7px solid var(--colors-grey-030);
    }

    &::after {
      border-bottom: 7px solid var(--colors-grey-030);
    }
  }

  &.dropdown__select-toggle-button--is-open {
    &::before {
      transform: rotate(-135deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }

  // Give disabled styling priority.
  && {
    &.dropdown__select--is-disabled {
      &::before {
        border-bottom: 7px solid var(--colors-grey-070);
      }

      &::after {
        border-bottom: 7px solid var(--colors-grey-070);
      }
    }
  }
`;

const Toggle = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledToggle
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

Toggle.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

Toggle.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

export default Toggle;
