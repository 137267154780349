import React from "react";

import BaseUserInfo from "./components/BaseUserInfo";
import TabsPanel from "./components/TabsPanel";
import StyledUserInfoPanel from "./views/UserInfoPanel";

const UserInfoPanel = props => {
  const { session } = props;

  // TODO: This is sizing really strangely. Not sure yet. Seems to pop back into
  // flex position randomly when overflow happens. See parent row's inline
  // styles. Feels like styled-bootstrap-grid is competing with my code here or
  // something.
  return (
    <StyledUserInfoPanel>
      <BaseUserInfo session={session}/>
      <TabsPanel session={session}/>
    </StyledUserInfoPanel>
  );
};

export default UserInfoPanel;
