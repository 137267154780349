import { AuditToolActionType, type AuditToolState } from "./types"
import type { AuditToolAction } from "./actions"

export const initialState: AuditToolState = {
  isFetchingClips: false,
  isSyncingEntries: false,
  entries: [],
  clips: {},
}

const reducer = (state: AuditToolState, action: AuditToolAction) => {
  switch (action.type) {
    case AuditToolActionType.SET_FETCHING_CLIPS:
      return { ...state, isFetchingClips: action.data }
    case AuditToolActionType.SET_SYNCING_ENTRIES:
      return { ...state, isSyncingEntries: action.data }
    case AuditToolActionType.SET_ENTRIES:
      return { ...state, entries: action.data }
    case AuditToolActionType.SET_CLIPS:
      return { ...state, clips: action.data }
    default:
      return state
  }
}

export default reducer
