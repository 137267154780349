import { OptimusAPI } from "@guardian/API/Optimus";

const ServiceAreas = {
  getGeographyPushes: (options?: any) => {
    return OptimusAPI.get(`/gnet/geography_pushes`, options);
  },

  getPoliceAreas: (code: string, options: any = {}) => {
    return OptimusAPI.get(
      `/gnet/police_areas`,
      {
        ...options,
        params: {
          ...options.params,
          code
        }
      }
    );
  },

  getPoliceSubAreas: (code: string, options: any = {}) => {
    return OptimusAPI.get(
      `/gnet/police_sub_areas`,
      {
        ...options,
        params: {
          ...options.params,
          code
        }
      }
    );
  },

  getServiceAreas: (options?: any) => {
    return OptimusAPI.get(`/gnet/service_areas`, options);
  }
};

export default ServiceAreas;
