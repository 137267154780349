import React, { useEffect } from "react"
import styles from "@guardian/Components/ModSOS/components/Chat/Message/Message.module.css"
import classnames from "classnames"
import { MessageType } from "@guardian/Components/ModSOS/components/Chat/Chat/Chat.helper"
import { IProps } from "@guardian/Components/ModSOS/components/Chat/Message/types"

export const Message: React.FunctionComponent<IProps> = ({
  author,
  timestamp,
  message,
  type,
  onAuthorUndefined,
}) => {
  useEffect(() => {
    if (!author) {
      onAuthorUndefined()
    }
  }, [author, onAuthorUndefined])

  return (
    <div className={styles.container}>
      <span
        className={classnames(styles.message, {
          [styles.userMessage]: type === MessageType.Chat,
        })}
      >
        {author ? `${author}: ` : ""}
        {message}
      </span>
      <span className={styles.timestamp}>{timestamp}</span>
    </div>
  )
}
