import axios from "axios";
import * as jose from "jose";

import appConfig from "@guardian/Config";
import { SessionService } from "@guardian/Services/Session";
import { eventHub } from "@guardian/State";

const OptimusAPI = axios.create({ baseURL: appConfig.optimusHost });

OptimusAPI.interceptors.request.use(
  async config => {

    // TODO: Are these generic tokens, based on a client secret, even used in
    // Optimus or any other backend? Can we just send null for access token if
    // we don't have one for an authenticated user?
    const clientSecret = appConfig.clientSecret || "";
    const genericToken = await new jose.SignJWT({})
      .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
      .setIssuedAt()
      .setExpirationTime('1h')
      .sign(new TextEncoder().encode(clientSecret));
    const accessToken = SessionService.accessToken || genericToken;

    // Append access tokens to all requests. When null it can be assumed that
    // either the API does not require authentication for the given request, or
    // that subsequent interceptors or implementations of requests will handle
    // authentication failure.
    config.headers["x-access-token"] = accessToken;

    return config;
  },
  error => Promise.reject(error)
);

OptimusAPI.interceptors.response.use(
  res => res,
  err => {
    // Handle base errors.
    if (err && err.response) {
      // This smells. Need to verify the API will always return the correct HTTP
      // response codes for bad auth but we should get rid of this.
      const isAuthFailure = (
        [400, 401, 403].includes(err.response.status) &&
        err.response.data.error.match("invalid token supplied")
      );

      if (isAuthFailure) {
        eventHub.emit("logout");
      }
    }

    throw err;
  }
);

export default OptimusAPI;
