import React, { FC, useState } from "react"
import style from "@guardian/Components/DialPad/styles.module.scss"
import { symbols } from "@guardian/Components/DialPad/data"
import { ISymbol } from "@guardian/Components/DialPad/types"
import { useSendSymbol } from "@guardian/Components/DialPad/useSendSymbol"

export const DialPad: FC = () => {
  const [dialPadState, setDialPadState] = useState("")
  const { sendSymbol, isLoadingSymbol } = useSendSymbol()

  const addSymbol = (symbol: ISymbol) =>
    setDialPadState(currentState => `${currentState}${symbol.value}`)

  const handleSymbolClick = (symbol: ISymbol) => async () => {
    try {
      await sendSymbol(symbol)
      addSymbol(symbol)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={style.container}>
      <div className={style.addressLine}>{dialPadState}</div>
      <div className={style.symbols}>
        {symbols.map((symbol, i) => (
          <button
            key={`dial-pad__key-${i}`}
            className={style.symbol}
            disabled={symbol.options?.disabled || isLoadingSymbol(symbol)}
            onClick={handleSymbolClick(symbol)}
          >
            {symbol.value}
          </button>
        ))}
      </div>
    </div>
  )
}
