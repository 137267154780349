import React from "react"
import classNames from "classnames"

import { Icon } from "@guardian/UI/Icon"

import { CATEGORY_ICON_MAP } from "./constants"
import {
  Container,
  Logo,
  Title,
  Body,
  Info,
  NotifTime,
  NotifIcon,
  CharCountContainer,
} from "./styles"

interface IProps {
  sound: boolean
  title: string
  message: string
  onChangeTitle?: any
  onChangeMessage?: any
  withCharCount?: boolean
  titleLimit?: number
  charLimit?: number
  tagName?: string
  disabled?: boolean
}

const NotificationPreview = ({
  title,
  message,
  withCharCount,
  titleLimit,
  charLimit,
  tagName,
  sound,
  onChangeTitle,
  onChangeMessage,
  disabled,
}: IProps) => {
  const iconUrl =
    tagName && tagName in CATEGORY_ICON_MAP
      ? CATEGORY_ICON_MAP[tagName as keyof typeof CATEGORY_ICON_MAP]
      : ""

  const messageLength = message?.length ?? 0
  const titleLength = title?.length ?? 0

  return (
    <>
      <Container>
        <Logo>
          <img src='/android-icon-192x192.png' alt='citizen-logo' />
        </Logo>
        <Title
          type='text'
          value={title || ''}
          onChange={onChangeTitle}
          disabled={disabled}
        />
        <Body value={message || ''} onChange={onChangeMessage} disabled={disabled} />
        <Info>
          <NotifTime>
            now
            {sound ? <Icon.VolumeUp /> : <Icon.VolumeOff />}
          </NotifTime>
          <NotifIcon>
            {iconUrl && <img src={iconUrl} alt={tagName} />}
          </NotifIcon>
        </Info>
      </Container>
      {!!withCharCount && (
        <CharCountContainer>
          <div
            className={classNames({
              "over-limit": messageLength > (charLimit ?? 0),
            })}
          >
            {messageLength} / {charLimit ?? 0} Suggested Character Limit
          </div>
          <div
            className={classNames({
              "over-limit": titleLength > (titleLimit ?? 0),
            })}
          >
            {titleLength} / {titleLimit} title
          </div>
        </CharCountContainer>
      )}
    </>
  )
}

export default NotificationPreview
