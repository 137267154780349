import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const FIXED_HEIGHT_PX = 110;

const INITIAL_TRANSITION_MS = 225;
const PRIMARY_TRANSITION_MS = 225;

const TOTAL_TRANSITION_MS = INITIAL_TRANSITION_MS + PRIMARY_TRANSITION_MS

const TRANSITION_NAME = `alert__dialog-message`;

export const TYPES = {
  ERROR: "guardian-dialog-message__error",
  GENERIC: "guardian-dialog-message__generic",
  INFO: "guardian-dialog-message__info",
  SUCCESS: "guardian-dialog-message__success",
  WARNING: "guardian-dialog-message__warning"
};

const StyledDialogMessage = styled.div.attrs(
  props => {
    const {
      className,
      type
    } = props;

    return {
      className: classNames(
        className,
        "alert__dialog-message", {
          "alert__dialog-message--error": type == TYPES.ERROR,
          "alert__dialog-message--generic": type == TYPES.GENERIC,
          "alert__dialog-message--info": type == TYPES.INFO,
          "alert__dialog-message--success": type == TYPES.SUCCESS,
          "alert__dialog-message--warning": type == TYPES.WARNING,
        }
      )
    };
  }
)`
  background: var(--colors-grey-085);
  border-radius: var(--radii-medium);
  box-shadow: var(--shadows-small);
  color: var(--colors-white);
  display: block;
  display: box;
  display: -webkit-box;
  font-size: var(--font-sizes-x-small);
  line-height: var(--line-heights-x-small);
  margin: var(--space-x-small) 0;
  max-height: ${ FIXED_HEIGHT_PX }px;
  overflow: auto;
  position: relative;
  vertical-align: "center";
  width: 100%;
  will-change: opacity;

  &.alert__dialog-message--error {
    background: var(--colors-red-050);
  }

  &.alert__dialog-message--info {
    background: var(--colors-blue-050);
  }

  &.alert__dialog-message--success {
    background: var(--colors-green-050);
  }

  &.alert__dialog-message--warning {
    background: var(--colors-yellow-050);
  }

  &.${ TRANSITION_NAME }-appear,
  &.${ TRANSITION_NAME }-enter {
    max-height: 0;
    opacity: 0;
    transform: scale(0.975);
    transition:
      max-height ${ INITIAL_TRANSITION_MS }ms cubic-bezier(0.39, 0.575, 0.565, 1),
      opacity ${ PRIMARY_TRANSITION_MS }ms cubic-bezier(0.39, 0.575, 0.565, 1) ${ INITIAL_TRANSITION_MS }ms,
      transform ${ PRIMARY_TRANSITION_MS }ms cubic-bezier(0.39, 0.575, 0.565, 1) ${INITIAL_TRANSITION_MS}ms;
  };

  &.${ TRANSITION_NAME }-appear.${ TRANSITION_NAME }-appear-active,
  &.${ TRANSITION_NAME }-enter.${ TRANSITION_NAME }-enter-active,
  &.${ TRANSITION_NAME }-appear-done,
  &.${ TRANSITION_NAME }-enter-done,
  &.${ TRANSITION_NAME }-exit {
    max-height: ${ FIXED_HEIGHT_PX }px;
    opacity: 1;
    transform: scale(1.0);
  };

  &.${ TRANSITION_NAME }-exit.${ TRANSITION_NAME }-exit-active,
  &.${ TRANSITION_NAME }-exit-done {
    opacity: 0;
    transform: scale(0.975);
    transition:
      opacity ${ PRIMARY_TRANSITION_MS }ms cubic-bezier(0.39, 0.575, 0.565, 1),
      transform ${ PRIMARY_TRANSITION_MS }ms cubic-bezier(0.39, 0.575, 0.565, 1);
  };
`;

StyledDialogMessage.propTypes = {};

StyledDialogMessage.defaultProps = {};

const DialogMessage = props => {
  const { children, ...transitionProps } = props;

  return (
    <CSSTransition
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      timeout={{
        appear: TOTAL_TRANSITION_MS,
        enter: TOTAL_TRANSITION_MS,
        exit: TOTAL_TRANSITION_MS
      }}
      { ...transitionProps }
    >
      <StyledDialogMessage>
        { children }
      </StyledDialogMessage>
    </CSSTransition>
  );
};

DialogMessage.propTypes = {
  type: PropTypes.oneOf(
    [
      TYPES.ERROR,
      TYPES.GENERIC,
      TYPES.INFO,
      TYPES.SUCCESS,
      TYPES.WARNING
    ]
  )
};

DialogMessage.defaultProps = {
  type: TYPES.GENERIC
};

export default DialogMessage;
