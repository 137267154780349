import { Agents, Users } from "@guardian/API/Optimus";
import { SessionService } from "@guardian/Services/Session";

const AgentService = {
  get userId() {
    return SessionService.userId;
  },

  // Returns a Promise either containing the user payload from our backend or
  // an error.
  getAuthenticatedAgent: (options?: any) => {
    return new Promise((resolve, reject) => {
      const userId = SessionService.userId;

      if (userId == null) {
        const error = new Error(
          "AgentService#getAuthenticatedAgent: No authenticated user found"
        );

        reject(error);
        return;
      }

      Users.getUser(userId, options)
        .then(({ data: user }) => {
          resolve(user);
        })
        .catch(reject);
    });
  },

  // Returns a Promise either containing the authenticated Agent's feature flags
  // from our backend or an error.
  getAuthenticatedAgentFeatures: (options?: any) => {
    return new Promise((resolve, reject) => {
      Agents.getAuthenticatedAgentFeatures(options)
        .then(({ data: { feature_flags: responseFeatureFlags } }) => {
          const featureFlags = responseFeatureFlags || {};

          resolve(featureFlags);
        })
        .catch(reject);
    });
  },

  // Returns a Promise either containing the authenticated Agent's permissions
  // from our backend or an error.
  getAuthenticatedAgentPermissions: (options?: any) => {
    return new Promise((resolve, reject) => {
      Agents.getAuthenticatedAgentPermissions(options)
        .then(({ data: responsePermissions }) => {
          const permissions = responsePermissions || {};

          resolve(permissions);
        })
        .catch(reject);
    });
  },

  // Returns a Promise either containing the authenticated Agent's stats from
  // our backend or an error.
  getAuthenticatedAgentStats: (sinceHrsAgo: number = 12, options?: any) => {
    return new Promise((resolve, reject) => {
      const hrsAgo = Math.max(sinceHrsAgo, 0);
      const ts = Math.round(new Date().getTime() / 1000);
      const sinceTs = ts - (hrsAgo * 3600);

      Agents.getAuthenticatedAgentStats(sinceTs, options)
        .then(({ data }) => {
          const { stats: responseStats, incidents_by_hour: responseIncidentsByHour } = data || {};

          const stats = responseStats || [];
          const incidentsByHour = responseIncidentsByHour || {};

          resolve({ stats, incidentsByHour });
        })
        .catch(reject);
    });
  }
};

export default AgentService;
