import React from "react";
import PropTypes from "prop-types";

import useTabListContext from "../hooks/useTabListContext";

import StyledTab from "../views/Tab";

const Tab = props => {
  const {
    text,
    tab
  } = props;

  const {
    onChange,
    selectedTab
  } = useTabListContext();

  const isActive = selectedTab === tab;

  const handleClicked = () => {
    if (typeof onChange === "function") {
      onChange(tab);
    }
  };

  return (
    <StyledTab data-text={text} isActive={isActive} onClick={handleClicked}>
      { text }
    </StyledTab>
  );
};

Tab.propTypes = {
  text: PropTypes.string,
  tab: PropTypes.string
};

export default Tab;
