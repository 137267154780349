const space = [
  "2px",  // xxxSmall
  "4px",  // xxSmall
  "8px",  // xSmall
  "12px", // small
  "16px", // medium
  "20px", // large
  "24px", // xLarge
  "32px", // xxLarge
  "48px", // xxxLarge
];

// Aliases
space.xxxSmall = space[0];
space.xxSmall = space[1];
space.xSmall = space[2];
space.small = space[3];
space.medium = space[4];
space.large = space[5];
space.xLarge = space[6];
space.xxLarge = space[7];
space.xxxLarge = space[8];

export const spaceCSSVariables = `
  :root {
    --space-xxx-small: ${ space.xxxSmall };
    --space-xx-small: ${ space.xxSmall };
    --space-x-small: ${ space.xSmall };
    --space-small: ${ space.small };
    --space-medium: ${ space.medium };
    --space-large: ${ space.large };
    --space-x-large: ${ space.xLarge };
    --space-xx-large: ${ space.xxLarge };
    --space-xxx-large: ${ space.xxxLarge };
  }
`;

export default space;
