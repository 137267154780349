import React, { useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import PubSub from "pubsub-js";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuid } from "uuid";

import Body from "../views/FlashMessages/Body";
import Close from "../views/FlashMessages/Close";
import FlashMessage, { TYPES } from "../views/FlashMessages/FlashMessage";
import StyledFlashMessages from "../views/FlashMessages/FlashMessages";
import Header from "../views/FlashMessages/Header";

const FlashMessages = props => {
  const [didMount, setDidMount] = useState(false);
  const [flashMessages, setFlashMessages] = useState([]);
  const flashMessagesRef = useRef();

  useEffect(() => {
    flashMessagesRef.current = flashMessages
  }, [flashMessages]);

  useEffect(() => {
    let subscriptions = [];

    const subscribeAll = () => {
      subscriptions = Object.values(TYPES).map(alertType => {
        return PubSub.subscribe(
          alertType,
          (alertType, { message, options }) => {
            show(message, alertType, options);
          }
        );
      });
    };

    const unsubscribeAll = () => {
      subscriptions.forEach(subscription => PubSub.unsubscribe(subscription));
    };

    const clearFlashMessageTimeouts = () => {
      const flashMessages = flashMessagesRef.current;

      flashMessages.forEach(flashMessage => {
        clearTimeout(flashMessage.timeoutId);
      });
    };

    setDidMount(true);
    subscribeAll();

    return () => {
      unsubscribeAll();
      clearFlashMessageTimeouts();
    }
  }, []);

  const remove = flashMessage => {
    setFlashMessages(prevFlashMessages => {
      const nextFlashMessages = prevFlashMessages.filter(a => {
        return a.id !== flashMessage.id;
      });

      clearTimeout(flashMessage.timeoutId);

      if (flashMessage.options.onClose) {
        flashMessage.options.onClose();
      }

      return nextFlashMessages;
    })
  };

  const show = (message, type, options) => {
    const id = `flash-message--${ uuid() }`;

    const flashMessageOptions = {
      timeout: FlashMessages.DEFAULT_TIMEOUT_MS,
      ...options
    };

    const flashMessage = {
      id: id,
      message: message,
      onClose: () => remove(flashMessage),
      options: flashMessageOptions,
      type: type
    };

    if (flashMessage.options.timeout) {
      flashMessage.timeoutId = setTimeout(() => {
        remove(flashMessage);
      }, flashMessage.options.timeout);
    }

    setFlashMessages(prevFlashMessages => {
      return prevFlashMessages.concat(flashMessage);
    });

    if (flashMessage.options.onOpen) {
      flashMessage.options.onOpen();
    }

    return flashMessage;
  };

  if (!didMount) {
    return null;
  }

  return (
    <Portal>
      <TransitionGroup
        appear
        component={StyledFlashMessages}
        {...props}
      >
        {
          flashMessages.map(flashMessage => (
            <FlashMessage key={flashMessage.id} type={flashMessage.type}>
              <div>
                <Header>
                  {
                    flashMessage.options.title && (
                      flashMessage.options.title
                    )
                  }
                  <Close onClick={flashMessage.onClose}/>
                </Header>
                <Body>
                  {
                    flashMessage.message
                  }

                </Body>
              </div>
            </FlashMessage>
          ))
        }
      </TransitionGroup>
    </Portal>
  );
};

FlashMessages.DEFAULT_TIMEOUT_MS = 5000;

FlashMessages.propTypes = {};

FlashMessages.defaultProps = {};

export default FlashMessages;
