import { bool } from "prop-types";
import { useCallback, useEffect, useMemo, useReducer } from "react";

import { useGlobalState } from "@guardian/State";

import { VideoStreams } from "@guardian/API/Optimus";
import { IncidentService } from "@guardian/Services/Incident";
import combokeys from "@guardian/Utils/hotkeys";
import { pointInServiceArea } from "@guardian/Utils/search_filter";

import ActionPane from "./ActionPane/ActionPane";
import styles from "./LiveVideoTab.module.css";
import {
  ActionTypes,
  reducer as LiveVideoTabReducer,
} from "./LiveVideoTab.reducer";
import StreamPanel from "./StreamPanel/StreamPanel";

const initialState = {
  streams: [],
  selectedStreamId: "",
  selectedStream: {},
  streamIncidents: {},
  streamIncidentIds: [],
  toastText: "",
  serviceAreaFilters: [{ code: "_all", title: "All Service Areas" }],
  lastStreamActioned: "",
}

const LiveVideoTab = ({ lafd }) => {
  const { streetTeamUsers, serviceAreas } = useGlobalState(state => {
    const {
      streetTeamUsers,
      serviceAreaConfig: { serviceAreas },
    } = state.global
    return { streetTeamUsers, serviceAreas }
  })

  const [state, dispatch] = useReducer(LiveVideoTabReducer, initialState)

  const filteredStreams = useMemo(() => {
    return state.streams.filter(stream => {
      if (
        state.serviceAreaFilters.length === 1 &&
        state.serviceAreaFilters[0].code === "_all"
      ) {
        return true
      }

      if (stream.videoStream.incidentId) {
        const incident = state.streamIncidents[stream.videoStream.incidentId]
        if (!incident) {
          return false
        }
        return state.serviceAreaFilters.some(
          serviceArea =>
            incident.location.cityCode.toLowerCase() ===
            serviceArea.code.toLowerCase(),
        )
      }
      return state.serviceAreaFilters.some(serviceArea =>
        pointInServiceArea(
          [stream.iglStream.lng, stream.iglStream.lat],
          serviceAreas[serviceArea.code],
        ),
      )
    })
  }, [
    serviceAreas,
    state.streams,
    state.streamIncidents,
    state.serviceAreaFilters,
  ])

  const refreshVideoStreams = useCallback(async () => {
    const { data } = await VideoStreams.getLiveStreams()
    const streams = data.streams || []
    dispatch({
      type: ActionTypes.SetStreams,
      payload: { streams, streetTeamUsers },
    })
  }, [streetTeamUsers])

  useEffect(() => {
    refreshVideoStreams()

    const intervalId = setInterval(refreshVideoStreams, 1000)
    return () => clearInterval(intervalId)
  }, [refreshVideoStreams])

  const streamHandled = useCallback(
    action => {
      const actionedStream = state.selectedStreamId
      const streamIndex = filteredStreams.findIndex(
        s => s.videoStream.id === state.selectedStreamId,
      )
      const newStreams = [...filteredStreams]
      newStreams.splice(streamIndex, 1)
      let streamIdToSelect
      if (newStreams.length === 0) {
        streamIdToSelect = ""
      } else {
        streamIdToSelect =
          newStreams[Math.min(streamIndex, newStreams.length - 1)].videoStream
            .id
      }
      dispatch({
        type: ActionTypes.SelectStream,
        payload: { id: streamIdToSelect },
      })
      dispatch({
        type: ActionTypes.SetStreams,
        payload: { streams: newStreams, streetTeamUsers },
      })
      dispatch({
        type: ActionTypes.SetToastText,
        payload: { text: action },
      })
      dispatch({
        type: ActionTypes.SetLastActioned,
        payload: { id: actionedStream },
      })
      setTimeout(() => {
        dispatch({
          type: ActionTypes.SetToastText,
          payload: { text: "" },
        })
      }, 500)
    },
    [filteredStreams, streetTeamUsers, state.selectedStreamId],
  )

  useEffect(() => {
    const fetchIncidents = async () => {
      let mapping = {}
      if (state.streamIncidentIds.length > 0) {
        const incidents = await IncidentService.getIncidents({
          incidentIds: state.streamIncidentIds
        })
        const noPSNs = incidents.filter(incident => !incident.psnId)
        noPSNs.forEach(incident => (mapping[incident.id] = incident))
      }
      dispatch({ type: ActionTypes.SetIncidents, payload: { mapping } })
    }
    fetchIncidents()
  }, [state.streamIncidentIds])

  useEffect(() => {
    if (filteredStreams.length > 0) {
      combokeys.bind("d", () => {
        const streamIndex = filteredStreams.findIndex(
          s => s.videoStream.id === state.selectedStreamId,
        )
        dispatch({
          type: ActionTypes.SelectStream,
          payload: {
            id:
              filteredStreams[
                Math.min(streamIndex + 1, filteredStreams.length - 1)
              ].videoStream.id,
          },
        })
      })
      combokeys.bind("a", () => {
        const streamIndex = filteredStreams.findIndex(
          s => s.videoStream.id === state.selectedStreamId,
        )
        dispatch({
          type: ActionTypes.SelectStream,
          payload: {
            id: filteredStreams[Math.max(streamIndex - 1, 0)].videoStream.id,
          },
        })
      })
      combokeys.bind("esc", () => {
        dispatch({
          type: ActionTypes.SelectStream,
          payload: { id: "" },
        })
      })

      return () => {
        combokeys.unbind("d")
        combokeys.unbind("a")
        combokeys.unbind("esc")
      }
    }
  }, [filteredStreams, state.selectedStreamId])

  const handleSelectStream = useCallback((id) => {
    dispatch({ type: ActionTypes.SelectStream, payload: { id } })
  }, [])

  const handleSetServiceAreaFilters = useCallback((filters) => {
    dispatch({
      type: ActionTypes.SetServiceAreaFilters,
      payload: { filters },
    })
  }, [])

  const handleClearStreamSelection = useCallback(() => {
    dispatch({ type: ActionTypes.SelectStream, payload: { id: "" } })
  }, [])

  return (
    <div className={styles.container}>
      <StreamPanel
        streams={filteredStreams}
        selectedStreamId={state.selectedStreamId}
        selectStream={handleSelectStream}
        incidents={state.streamIncidents}
        streamHandled={streamHandled}
        serviceAreaFilters={state.serviceAreaFilters}
        setServiceAreaFilters={handleSetServiceAreaFilters}
      />
      <ActionPane
        stream={filteredStreams.find(
          s => s.videoStream.id === state.selectedStreamId,
        )}
        streamHandled={streamHandled}
        incident={
          state.streamIncidents[
            state.selectedStream && state.selectedStream.videoStream
              ? state.selectedStream.videoStream.incidentId
              : ""
          ]
        }
        activeStreamId={state.selectedStreamId}
        clearStreamSelection={handleClearStreamSelection}
        lafd={lafd}
        refreshVideoStreams={refreshVideoStreams}
      />
      {state.toastText && <div className={styles.toast}>{state.toastText}</div>}
    </div>
  )
}

LiveVideoTab.propTypes = {
  lafd: bool,
}

export default LiveVideoTab
