import classNames from "classnames";
import styled from "styled-components";

const ActivityList = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__activity__list"
    )
  };
})`
`;

ActivityList.defaultProps = {};

export default ActivityList;
