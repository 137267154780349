import styled from "styled-components";

const TabButtonGroup = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
`;

export default TabButtonGroup;
