import type { Clip } from "@guardian/Types/Clip"

import type { AuditToolEntry } from "@guardian/Components/AuditTool/types"

export enum AuditToolActionType {
  SET_FETCHING_CLIPS,
  SET_SYNCING_ENTRIES,
  SET_ENTRIES,
  SET_CLIPS,
}

export type AuditToolState = {
  isFetchingClips: boolean
  isSyncingEntries: boolean
  entries: AuditToolEntry[]
  clips: { [key: string]: Clip }
}
