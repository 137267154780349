import React from "react";
import PropTypes from "prop-types";

import StyledItem from "../../../views/Menu/Items/Item";
import StyledSelected from "../../../views/Menu/Items/Selected";

import useItem from "./hooks/useItem";

const Item = React.forwardRef((props, ref) => {
  const {
    children,
    ...restProps
  } = props;

  const itemProps = useItem({ itemRef: ref, ...restProps });

  return (
    <StyledItem ref={ref} { ...itemProps }>
      {
        itemProps.selected && (
          <StyledSelected
            disabled={itemProps.disabled}
            visible={itemProps.selected}
          />
        )
      }
      { children }
    </StyledItem>
  );
});

Item.displayName = "Item";

Item.propTypes = {};

export default Item;
