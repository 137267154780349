import _ from "lodash"
import Sound from "@guardian/Components/Sound"
import { CLIP_PLAYED_THRESHOLD, CLIP_SKIP_MS, CLIP_MAX_AGE } from "./consts"

import * as analytics from "@guardian/Analytics"
import Events from "@guardian/Analytics/events"
import { track } from "@guardian/Analytics/segment"
import { GNet } from "@guardian/API/Optimus"
import { ClipService, RadioService } from "@guardian/Services/Radio"
import { regenClipView, currentView } from "@guardian/Utils/state"
import { sortClips, filterClips, getIncidentSuggestions } from "@guardian/Utils/clips"
import { push } from "@guardian/Utils/history"

const clips = state => {
  function activeVisible() {
    const activeClip = state.get().radio.activeClip
    const divElem = document.getElementById("clip-list")
    const chElem = document.getElementById(activeClip.id)
    if (!chElem) {
      return
    }
    const clipTop = chElem.offsetTop
    const clipBot = clipTop + chElem.offsetHeight
    const listTop = divElem.scrollTop
    const listBot = divElem.scrollTop + divElem.clientHeight
    return clipBot > listTop && clipTop < listBot
  }

  state.on("setActiveClipPlayed", () => {
    const activeClip = state.get().radio.activeClip
    const clip =
      state.get().radio.clips.find(c => c.id === activeClip.id) ||
      state.get().radio.pinnedClips.find(c => c.id === activeClip.id)
    if (clip) {
      if (!clip.played) {
        clip.set("played", true)
        regenClipView(state)
        const played = state
          .get()
          .global.stats.find(s => s.key === "clips_listened")
        if (played) {
          played.set("value", played.value + 1)
        }
      }
      // we alredy have setClipPlayed in PlayBar.js
      // if (state.get().global.user) {
      //   ClipService.setClipPlayed({
      //     clipId: activeClip.id,
      //     position: activeClip.position,
      //     playbackRate: parseFloat(state.get().radio.speed),
      //   })
      // }
    }
  })

  state.on("toggleMultiplayerPin", (clipId, clipListenStatus) => {
    const clip =
      state.get().radio.multiplayerPinnedClips.find(cpc => cpc.id === clipId) ||
      state.get().radio.clips.find(c => c.id === clipId)

    if (clip) {
      const nextClipListenStatus =
        clip.clipListenStatus === clipListenStatus
          ? "unplayed"
          : clipListenStatus
      const updatedClip = { ...clip, clipListenStatus: nextClipListenStatus }

      const pins = state
        .get()
        .radio.multiplayerPinnedClips.filter(c => c.id !== clipId)
        .slice()
      pins.push(updatedClip)
      state.get().radio.set("multiplayerPinnedClips", pins)

      if (state.get().global.user) {
        ClipService.multiplayerPinClip({
          clipId: clipId,
          clipStatus: nextClipListenStatus,
        })
      }
    }

    analytics.event({
      category: "Clips",
      action: "MultiplayerPin",
      clip: clipId,
      status: clipListenStatus,
    })
    regenClipView(state)
  })

  state.on("togglePin", clipId => {
    if (state.get().radio.pinFollowUsername) {
      // No-op
      return
    }
    const pinnedClip = state
      .get()
      .radio.pinnedClips.find(pc => pc.id === clipId)
    if (pinnedClip) {
      state.get().radio.set(
        "pinnedClips",
        state.get().radio.pinnedClips.filter(pc => pc.id !== clipId),
      )
      ClipService.unpinClip({ clipId })
    } else {
      const clip = state.get().radio.clips.find(c => c.id === clipId)
      if (clip) {
        const pins = state.get().radio.pinnedClips.slice()
        pins.push(
          Object.assign(
            { ...clip },
            { personalPinTime: new Date().toISOString() },
          ),
        )
        state.get().radio.set("pinnedClips", pins)
        if (state.get().global.user) {
          ClipService.pinClip({ clipId })
        }
        analytics.event({
          category: "Clips",
          action: "Pin",
        })
        track("personal pin", {
          category: "Clips",
          action: "Pin",
          clipId: clipId,
          userId: state.get().global.user.id,
        })
      }
    }
    regenClipView(state)
  })

  state.on("setActiveClip", props => {
    if (
      state
        .get()
        .global.errorBanner?.message.toLowerCase()
        .match("slow network")
    ) {
      return
    }

    const oldActiveClip = state.get().radio.activeClip
    const activeClip = Object.assign({}, oldActiveClip, props)
    if (activeClip.id !== oldActiveClip.id) {
      activeClip.hitTop = false
      if (!("position" in props)) {
        activeClip.position = 0
      }
      if (oldActiveClip.position > CLIP_PLAYED_THRESHOLD) {
        state.emit("setActiveClipPlayed")
      }

      const clip = state.get().radio.clips.find(c => c.id === activeClip.id)
      if (clip) {
        activeClip.time = clip.time
      }
      activeClip.loop = undefined
    }
    if (props.status !== undefined) {
      activeClip.animateStatus = props.animateStatus
    }
    state.emit("setActiveClipOverride", false)
    state.get().radio.activeClip.reset(activeClip)
    state.emit("getClipFlags", activeClip.id)
  })

  state.on("setActiveClipOverride", isOverride => {
    state.get().radio.set("activeClipOverride", isOverride)
  })

  state.on("toggle unplayed only", () => {
    state.get().radio.set("unplayedOnly", !state.get().radio.unplayedOnly)
    regenClipView(state)
  })

  state.on("getClipFlags", async id => {
    let { data: flags } = await GNet.getClipFlags(id)
    const clip = state.get().radio.clips.find(c => c.id === id)

    let hasMetadata = false
    if (clip && clip.metadata && clip.metadata.address) {
      hasMetadata = true
      flags.flags = (flags.flags || []).concat([
        {
          ...clip.metadata.geometry.location,
          address: clip.metadata.address,
          location: clip.metadata.address,
          userName: "detected from clip",
          detected: true,
        },
      ])
    }

    if (
      clip &&
      clip.metadata &&
      clip.metadata.tags &&
      clip.metadata.tags.phrases
    ) {
      hasMetadata = true
      flags.flags = (flags.flags || []).concat(
        clip.metadata.tags.phrases.map(t => ({
          title: t,
          userName: "detected from clip",
          detected: true,
        })),
      )
    }

    if (hasMetadata) {
      analytics.event({
        category: Events.CATEGORY.INCIDENT_CREATION,
        action: Events.ACTION.METADATA_SHOWN,
      })
    }

    state.get().radio.set("flags", flags)
  })

  state.on("nextClip", (opts = {}) => {
    if (
      state
        .get()
        .global.errorBanner?.message.toLowerCase()
        .match("slow network")
    ) {
      return
    }

    state.emit("setActiveClipPlayed")
    const { clipsView, activeClip } = state.get().radio
    const clipId = activeClip.id
    const index = clipsView.findIndex(c => c.id === clipId)
    if (index === 0) {
      state.get().radio.activeClip.set({
        status: Sound.status.STOPPED,
        hitTop: true,
      })
      return
    }
    // index could be -1 if the active clip fell off
    let nextIndex = (index > 0 ? index : clipsView.length) - 1
    if (opts.unplayed) {
      for (; nextIndex > 0; nextIndex--) {
        if (!clipsView[nextIndex].played) {
          break
        }
      }
    }

    const nextClip = clipsView[nextIndex]
    if (!nextClip) {
      return
    }

    const shouldScrollToActive = !opts.scrollOnlyIfVis || activeVisible()

    state.emit("setActiveClip", {
      id: nextClip.id,
      position: 0,
      status: Sound.status.PLAYING,
    })
    if (shouldScrollToActive) {
      state.emit("scrollToActive")
    }
  })

  state.on("prevClip", (opts = {}) => {
    if (
      state
        .get()
        .global.errorBanner?.message.toLowerCase()
        .match("slow network")
    ) {
      return
    }

    const { clipsView, activeClip } = state.get().radio
    const clipId = activeClip.id
    const index = clipsView.findIndex(c => c.id === clipId)
    if (index === clipsView.length - 1) {
      state.get().radio.activeClip.set("position", 0)
      return
    }
    let nextIndex = index + 1
    if (opts.unplayed) {
      for (; nextIndex < clipsView.length - 1; nextIndex++) {
        if (!clipsView[nextIndex].played) {
          break
        }
      }
    }
    const nextClip = clipsView[nextIndex]
    state.emit("setActiveClip", {
      id: nextClip.id,
      position: 0,
      status: Sound.status.PLAYING,
    })
    state.emit("scrollToActive")
  })

  const dbSetSpeed = _.debounce(RadioService.setRadioConfiguration, 500)
  state.on("radioSpeed:set", speed => {
    const speedValue = Math.max(1, parseFloat(speed))
    state.get().radio.set("speed", speedValue)
    if (state.get().global.user) {
      dbSetSpeed({ playbackRate: speedValue })
    }
  })

  state.on("radioSpeed:increment", increment => {
    const speedValue = parseFloat(state.get().radio.speed) + increment
    return state.emit("radioSpeed:set", speedValue)
  })

  const dbSetCutoff = _.debounce(RadioService.setRadioConfiguration, 500)
  state.on("radioCutoff:set", cutoff => {
    state.get().radio.set("cutoff", parseFloat(cutoff))
    regenClipView(state)
    if (state.get().global.user) {
      dbSetCutoff({ cutoff: parseFloat(cutoff) })
    }
  })

  state.on("toggleActive", () => {
    const active = state.get().global.active
    state.get().global.set("activeTimestamp", active ? 0 : Date.now())
    window.localStorage.setItem(
      "gnet:activeTimestamp",
      state.get().global.activeTimestamp,
    )
    state.get().global.set("active", !active)
    RadioService.setRadioConfiguration({ active: !active })
  })

  state.on("togglePlaying", () => {
    const { activeClip } = state.get().radio
    if (!activeClip.id) {
      return state.emit("nextClip")
    }
    state.emit("silenceClip")
    let newStatus = Sound.status.PLAYING
    if (activeClip.status === Sound.status.PLAYING) {
      newStatus = Sound.status.PAUSED
    }
    state.emit("setActiveClip", {
      status: newStatus,
      animateStatus: true,
    })
  })

  state.on("silenceClip", () => {
    const { activeClip } = state.get().radio
    if (activeClip.status === Sound.status.PLAYING) {
      state.emit("setActiveClip", {
        status: Sound.status.PAUSED,
        hitTop: null,
      })
    }
  })

  state.on('setKbHandlers', handlers => {
    state.get().radio.set('kbHandlers', handlers)
  })

  state.on("rewindClip", (step = CLIP_SKIP_MS) => {
    const { activeClip, kbHandlers } = state.get().radio
    if (kbHandlers) {
      return kbHandlers.advance(-step)
    }
    if (!activeClip.id) return

    state.emit("setActiveClip", {
      position: Math.max(0, activeClip.position - step),
    })
  })

  state.on("advanceClip", (step = CLIP_SKIP_MS) => {
    const { clips, activeClip, kbHandlers } = state.get().radio
    if (kbHandlers) {
      return kbHandlers.advance(step)
    }
    if (!activeClip.id) return

    const clip = clips.find(c => c.id === activeClip.id)
    if (clip) {
      state.emit("setActiveClip", {
        position: Math.min(clip.duration_ms - 1, activeClip.position + step),
      })
    }
  })

  let clipInterval
  let multiplayerPinInterval
  state.on("startUpdatingClips", async () => {
    const updateClips = async () => {
      await state.emit("updateChannelAllowlist")
      const {
        activeChannels,
        pinnedClips,
        multiplayerPinnedClips,
        channels: channelCfg,
        activeClipOverride,
        isFetchingClips,
      } = state.get().radio

      if (isFetchingClips) {
        return
      }
      state.get().radio.set("isFetchingClips", true)

      const { attachedClips } = state.get().incidentComposer
      let channels = Object.keys(activeChannels).filter(cid => channelCfg[cid])
      const { metapod } = state.get().global

      try {
        let { clips: oldClips, activeClip } = state.get().radio
        let clips
        if (channels.length === 0) {
          clips = []
        } else {
          clips = await ClipService.getClips({
            channels,
            withFlagStats: state.get().global.metapod,
            start: metapod
              ? new Date(Date.now() - CLIP_MAX_AGE).toISOString()
              : undefined,

            withMetadata: metapod,
            includeClips: attachedClips.map(c => c.id).toArray(),
          })
        }
        // Refetch this in case something changed during clips fetch
        oldClips = state.get().radio.clips
        activeClip = state.get().radio.activeClip

        const ac =
          activeClip.id &&
          (oldClips.find(c => c.id === activeClip.id) ||
            pinnedClips.find(pc => pc.id === activeClip.id) ||
            multiplayerPinnedClips.find(mpc => mpc.id === activeClip.id))

        oldClips = oldClips.toJS()
        let newClips = filterClips(state, clips).map(clip => {
          const oldClip = oldClips.find(c => c.id === clip.id)

          if (oldClip) {
            oldClip.played = clip.played || oldClip.played
            oldClip.spectrogram = clip.spectrogram
            if (metapod) {
              oldClip.flags = clip.flags
              oldClip.plays = clip.plays
              oldClip.metadata = clip.metadata
            }
            return oldClip
          }

          return clip
        })
        const sortingMethod = state.get().radio.sortingMethod
        newClips = _.uniqBy(sortClips(newClips, sortingMethod), c => c.id)
        state.get().radio.set("clips", newClips)
        const clipsView = currentView(state, newClips)
        state.get().radio.set("clipsView", clipsView)

        if (activeClip.id) {
          const index = clipsView.findIndex(c => c.id === activeClip.id)
          // Either it's playing and fell off, or it's not playing and we want the next one.
          if (
            activeClip.status !== Sound.status.PLAYING &&
            activeClip.hitTop &&
            !activeClipOverride
          ) {
            return state.emit("nextClip")
          } else if (
            (ac && activeClip.status === Sound.status.PLAYING && index === -1)
            || (index === -1 && state.get().radio.duplicateClips.has(activeClip.id))
          ) {
            // Find closest next clip in time
            for (let i = clipsView.length - 1; i >= 0; i--) {
              const c = clipsView[i]
              if (new Date(c.time).getTime() > new Date(ac.time).getTime()) {
                state.emit("setActiveClip", { id: c.id })
                return
              }
            }
            state.get().radio.activeClip.reset({})
          } else if (index === -1) {
            state.get().radio.activeClip.reset({})
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      } finally {
        state.get().radio.set({
          pendingClipsFetch: false,
          isFetchingClips: false,
        })
      }
    }

    const updateMultiplayerPins = async () => {
      try {
        const multiplayerPins = await ClipService.getMultiplayerPinnedClips()
        state.get().radio.set("multiplayerPinnedClips", multiplayerPins)
      } catch (error) {
        console.log("Error fetching clips:", error)
      }
    }

    try {
      await Promise.all([updateClips(), updateMultiplayerPins()])
    } catch (error) {
      console.log("Error fetching clips:", error)
    }

    const safeUpdateClips = async () => {
      try {
        await updateClips()
      } catch (error) {
        console.log("Error fetching clips:", error)
      }
    }

    clipInterval = setInterval(safeUpdateClips, 5000)
    multiplayerPinInterval = setInterval(updateMultiplayerPins, 5000)
  })

  state.on("stopUpdatingClips", () => {
    clearInterval(clipInterval)
    clearInterval(multiplayerPinInterval)
  })

  state.on("updateClips", (newClips) => {
    state.get().radio.set("clips", newClips)
    regenClipView(state)
  })

  state.on("setMultiplayerPinnedClips", (multiplayerPins) => {
    state.get().radio.set("multiplayerPinnedClips", multiplayerPins)
  })

  state.on("setClipTooltip", (clip, elem, position, status) => {
    state.get().global.set("clipTooltip", { clip, elem, position, status })
  })

  state.on("setClipTooltipStatus", (position, status) => {
    state
      .get()
      .global.clipTooltip.set("position", position)
      .set("status", status)
  })

  state.on("toggleLoop", () => {
    const { kbHandlers, activeClip } = state.get().radio
    if (kbHandlers) {
      return kbHandlers.loop()
    }
    const looping = activeClip.loop
    if (!looping) {
      state.emit("showFlash", "looping clip")
    }
    state.get().radio.activeClip.set("loop", !looping)
  })

  const dbSetFlaggedOnly = _.debounce(RadioService.setRadioConfiguration, 500)
  state.on("set flagged only", flaggedOnly => {
    state.get().radio.set("flaggedOnly", flaggedOnly)
    dbSetFlaggedOnly({ flaggedOnly })
    regenClipView(state)
  })

  state.on("setFlagAlgorithm", algorithm => {
    // TODO: how to set the initial state to `legacy`?
    state.get().radio.set("flagAlgorithm", algorithm)
    regenClipView(state)
  })

  state.on("setSortingMethod", sortingMethod => {
    state.get().radio.set("sortingMethod", sortingMethod)
    regenClipView(state)
  })

  state.on("filterMultiplayerPinsOnly", multiplayerPinOnly => {
    state.get().radio.set("multiplayerPinOnly", multiplayerPinOnly)
    regenClipView(state)
  })

  state.on("clearRateClip", () => {
    state.get().radio.set("rateClip", null)
  })

  state.on("attach current clip", focus => {
    const { activeClip } = state.get().radio
    const { attachedClips } = state.get().incidentComposer
    const { readOnly } = state.get().global
    if (!activeClip.id || readOnly) {
      return
    }

    if (focus) {
      state.emit("focusOnAttach")
    }

    if (attachedClips.findIndex(c => c.id === activeClip.id) === -1) {
      state.emit("show incident pane")
      state.emit("toggle attach clip", activeClip.id)
    }

    const clip = state.get().radio.clipsView.find(c => c.id === activeClip.id)

    const incidentSuggestions = getIncidentSuggestions(clip)
    const { selectedIncident } = state.get().moderation
    if (!selectedIncident && incidentSuggestions.length > 0) {
      const incidentSuggestion = incidentSuggestions[0]
      push(`/incidents/${incidentSuggestion.incidentId}`)
    }

    if (clip && clip.metadata && clip.metadata.address) {
      state.emit("updateIncidentComposer", {
        location: {
          ...clip.metadata.geometry.location,
          address: clip.metadata.address,
          location: clip.metadata.address,
          userName: "detected from clip",
          detected: true,
          title: undefined,
          description: undefined,
        },
      })

      analytics.event({
        category: Events.CATEGORY.INCIDENT_CREATION,
        action: Events.ACTION.METADATA_SHOWN,
      })
    }
  })

  state.on("modifyListeningGroup", (grouping, action) => {
    if (action === "add") {
      state.get().radio.activeGroups.add(grouping)
    } else if (action === "delete") {
      state.get().radio.activeGroups.delete(grouping)
    }
    window.localStorage.setItem(
      "guardian:activeGroups",
      JSON.stringify([...state.get().radio.activeGroups]),
    )
  })

  state.on("clearListeningGroups", () => {
    const { radio } = state.get()
    if (typeof radio?.clear === "function") {
      radio.clear()
    }
  })

  state.on("openClipContextMenu", ({ id, top, left }) => {
    state.get().radio.set("clipContextMenu", { id, top, left })
  })
}

export default clips
