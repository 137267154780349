import { useState } from "react"
import { sendDTMFToneToProtectSession } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { ISymbol, ISymbolDTMFType } from "@guardian/Components/DialPad/types"

export const useSendSymbol = () => {
  const { state } = useSOSDashboardContext()
  const [loadingSymbol, setLoadingSymbol] = useState<ISymbol | undefined>()

  const sendSymbol = async (symbol: ISymbol) => {
    try {
      setLoadingSymbol(symbol)

      let dtmfToneParams

      switch(symbol.dtmfType) {
        case ISymbolDTMFType.DIGIT:
          dtmfToneParams = { dtmfDigit: symbol.value }
          break
        case ISymbolDTMFType.CHAR:
          dtmfToneParams = { dtmfChar: symbol.value }
          break
        default:
          throw new Error(
            `Attempted to send DTMF d tone for unsupported type '${symbol.dtmfType}'`
          )
      }

      await sendDTMFToneToProtectSession(
        state.activeSessionId,
        dtmfToneParams
      ).toPromise()
    } catch (error) {
      console.error("Error sending DTMF tone", error)
    } finally {
      setLoadingSymbol(undefined)
    }
  }

  const isLoadingSymbol = (symbol: ISymbol) =>
    loadingSymbol?.value === symbol.value

  return { loadingSymbol, sendSymbol, isLoadingSymbol }
}
