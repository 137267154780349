import {
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";

import { ProtectSessionService } from "@guardian/Services/ProtectSession";

interface UseSendNoteMutation {
  data: {
    sessionId: string
    text: string
  }
  options: any
}

const useGetNotes = (
  sessionId: string,
  queryOptions: any = {},
) => {
  return useQuery(
    ["protectSessionNotes", sessionId],
    ({ signal }) => {
      const request = ProtectSessionService.getNotes(
        sessionId,
        { signal }
      );

      return request;
    },
    {
      ...queryOptions
    }
  );
};

const useSendNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (mutation: UseSendNoteMutation) => {
      return ProtectSessionService.sendNote(
        mutation.data,
        mutation.options
      );
    },
    {
      onSuccess: (data: any, mutation: UseSendNoteMutation) => {
        queryClient.invalidateQueries({
          queryKey: ["protectSessionNotes", mutation.data.sessionId]
        })
      }
    }
  );
};

const ProtectSession = {
  useGetNotes,
  useSendNote
};

export default ProtectSession;
