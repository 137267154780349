import classNames from "classnames";
import styled from "styled-components";

import { MediumText as GenericMediumText } from "@guardian/UI/Typography";

const UserName = styled(GenericMediumText).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__user-name"
      )
    };
  }
)`
  color: var(--colors-white);
`;

UserName.propTypes = {};

UserName.defaultProps = {
  as: "span",
  truncate: true
};

export default UserName;
