import { bool, number, string } from "prop-types"
import React, { useRef } from "react"

import { eventHub } from "@guardian/State"

import useClickOutside from "@guardian/Hooks/useClickOutside"
import { copyToClipboard } from "@guardian/Utils/util"

import "./ClipContextMenu.scss"

const ClipContextMenu = ({ pin, left, top, wav_url, id }) => {
  const popupRef = useRef()

  useClickOutside([popupRef], () => eventHub.emit("closePopups"))

  return (
    <div
      className='clip-context-menu'
      style={{ left, top }}
      onContextMenu={e => e.stopPropagation()}
      ref={popupRef}
    >
      <div
        className='menu-item'
        onClick={() => {
          copyToClipboard("https://" + wav_url)
          eventHub.emit("showFlash", "link copied")
        }}
      >
        Copy Link
      </div>
      <div className='menu-item' onClick={() => eventHub.emit("togglePin", id)}>
        {pin ? "Unpin" : "Pin"}
      </div>
    </div>
  )
}

ClipContextMenu.propTypes = {
  id: string,
  left: number,
  top: number,
  wav_url: string,
  pin: bool,
}

export default ClipContextMenu
