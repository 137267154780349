import React, { Component } from "react"
import ReactGA from "react-ga"

export function withTracking(WrappedComponent) {
  if (!import.meta.env.PROD) {
    return WrappedComponent
  }

  const Tracker = class extends Component {
    trackPage(page) {
      ReactGA.set({ page })
      ReactGA.pageview(page)
    }
    componentDidMount() {
      const page = this.props.location.pathname
      this.trackPage(page)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        this.trackPage(nextPage)
      }
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  return Tracker
}

export function set(props) {
  // if (config.production) {
  //   ReactGA.set(props)
  // }
}

export function event(props) {
  console.log("Record Event", props)
}
