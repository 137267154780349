import React, { useState } from "react"
import Container from "@guardian/Components/Common/Container"
import styles from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/AgentScripts.module.css"
import { IProps, IScriptZingtree } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptZingtree/types"

export const AgentScriptZingtree = ({
  title,
  text,
  id,
  url,
  matches,
  forceRender,
  activeSearch,
  onAction,
  sessionId,
}: IScriptZingtree & IProps) => {
  const [expanded, setExpanded] = useState(false)

  if (matches && !forceRender) {
    return null
  }

  let searchedTitle
  if (activeSearch && activeSearch.id === id) {
    searchedTitle = [
      title.slice(0, activeSearch.start),
      <span className={styles.searchMatch}>
        {title.slice(activeSearch.start, activeSearch.end)}
      </span>,
      title.slice(activeSearch.end),
    ].filter(e => !!e)
  }

  return (
    <Container column fullWidth top={2}>
      <Container fullWidth onClick={() => setExpanded(!expanded)} leftJustify>
        <Container right={2}>
          <img
            alt='caret'
            src='/Caret.svg'
            style={expanded ? { transform: "rotate(180deg)" } : undefined}
          />
        </Container>
        {searchedTitle || title}
      </Container>
      {expanded && (
        <Container left={4} fullWidth column>
          {text}
          <iframe
            title='zingree script'
            src={
              url ||
              `https://zingtree.com/show/${id}?agent_mode=1&source=pOS&session_id=${id}-${sessionId}`
            }
            frameBorder={0}
            style={{ width: "100%", height: 500 }}
          ></iframe>
        </Container>
      )}
    </Container>
  )
}
