import styled from "styled-components"

export const Container = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: #4da6ff;
  border: 4px solid white;
  position: relative;
  transform: rotate(220deg) translate(17px, 0px);
  transform-style: preserve-3d;
`
