import { eventHub, useGlobalState } from "@guardian/State";

interface QueryOptions {
  enabled: string
};

const useModerator = (options: QueryOptions) => {
  const { enabled } = options;

  const active = useGlobalState(state => state.global.active);

  const toggleActive = () => {
    if (enabled) {
      eventHub.emit("toggleActive");
    }
  };

  return {
    active,
    toggleActive
  };
};

export default useModerator;
