import { Message } from "./types"

export interface IState {
  messages: Message[]
  inputValue: string
}

export enum ModBroadcasterActionType {
  SetMessages = "Set Messages",
  AddMessage = "Add Message",
  SetInputValue = "Set Input Value",
}

export interface IAction {
  type: ModBroadcasterActionType
  data?: any
}

const reducer = (state: IState, action: IAction) => {
  let returnState = { ...state }
  switch (action.type) {
    case ModBroadcasterActionType.SetMessages:
      if (action.data.messages.length > 0) {
        returnState.messages = [...action.data.messages]
      }
      break
    case ModBroadcasterActionType.AddMessage:
      returnState.messages.push(action.data.message)
      break
    case ModBroadcasterActionType.SetInputValue:
      returnState.inputValue = action.data.inputValue
      break
  }
  return returnState
}

export default reducer
