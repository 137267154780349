import React, { useEffect } from "react"
import style from "./ErrorModal.module.css"
import { object, func } from "prop-types"

const ErrorModal = ({ error, onClose }) => {
  useEffect(() => {
    let timeout
    if (error) {
      timeout = setTimeout(onClose, 2000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [error, onClose])

  if (!error) {
    return null
  }

  return (
    <div className={style.container}>
      <div className={style.modal}>
        <span>{error.message}</span>
        <button onClick={onClose} className={style.close}>
          <i className='fas fa-times'></i>
        </button>
      </div>
    </div>
  )
}

ErrorModal.propTypes = {
  error: object,
  onClose: func,
}

export default React.memo(ErrorModal)
