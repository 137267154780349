/**
 * Get the raw metadata of a clip.
 *
 * @param {Object} clip - The clip object containing metadata.
 * @returns {?Object} - The parsed clip raw metadata or null if not available or an error occurs.
 */
export function getClipRawMetadata(clip) {
  const clipRaw = clip?.metadata?.raw

  if (!clipRaw) {
    return null
  }

  try {
    return JSON.parse(clipRaw)
  } catch (error) {
    console.error(`Error parsing raw clip metadata. raw=${clipRaw}, Error=${error}`)
    return null
  }
}

/**
 * Get the transcription text from the raw metadata of a clip.
 *
 * @param {Object} clip - The clip object containing metadata.
 * @returns {?string} - The transcription text or null if not available or an error occurs.
 */
export function getClipTranscriptionText(clip) {
  const parsedClipRaw = getClipRawMetadata(clip)
  return parsedClipRaw?.transcription?.text ?? null
}
