import classNames from "classnames";
import styled from "styled-components";

const ChatTab = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__chat-tab")
    }
  }
)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default ChatTab;
