import classNames from "classnames";
import styled from "styled-components";

const BodySeparator = styled.div.attrs(
  props => {
    return {
      className: classNames("modal__body-separator")
    };
  }
)`
  background-image:
    linear-gradient(
      to right,
      var(--colors-grey-080) 0%,
      var(--colors-grey-080) 100%,
      var(--colors-transparent) 100%
    );
  background-origin: border-box;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 1px;

  margin-left: calc(-1.0 * var(--space-x-large));
  margin-right: calc(-1.0 * var(--space-x-large));
  padding: var(--space-medium) 0;
`;

BodySeparator.propTypes = {};

BodySeparator.defaultProps = {};

export default BodySeparator;
