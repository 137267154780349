import { ProtectSessions } from "@guardian/API/Optimus";

interface ProtectSessionServiceSendNoteData {
  sessionId: string
  text: string
};

const ProtectSessionService = {
  getNotes: (sessionId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      ProtectSessions.getNotes(sessionId, options)
        .then(({ data: { notes } }) => {
          resolve(notes);
        })
        .catch(reject);
    });
  },

  sendNote: (data: ProtectSessionServiceSendNoteData, options: any = {}) => {
    return new Promise((resolve, reject) => {
      ProtectSessions.sendNote(data, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },
};

export default ProtectSessionService;
