import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const SmallParagraphStyles = css`
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-small);

  & + & {
    margin-top: var(--line-heights-small);
  }

  &.paragraph--with-measure {
    max-width: var(--measures-small);
    width: 100%;
  }
`;

const SmallParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__small-paragraph")
    };
  }
)`
  ${ SmallParagraphStyles }
`;

SmallParagraph.propTypes = {
  ...Paragraph.propTypes
};

SmallParagraph.defaultProps = {};

export default SmallParagraph;
