import React from "react"
import { object, func } from "prop-types"
import classnames from "classnames"

import { ContentService } from "@guardian/Services/Content";

import style from "./IncidentContentPane.module.css"
import PhotoMenu from "../PhotoMenu"

const PhotoContent = ({ image, setShowBlock, setShowVerify }) => {
  const { id, blocked, confirmed, imageUrl, username, userId } = image.image
  return (
    <>
      <div className={classnames(style.subpane, style.image)}>
        <img alt="" src={imageUrl} />
        <div className={style.controls}>
          <button
            className={classnames(style.block, {
              [style.active]: blocked,
            })}
            onClick={() => {
              setShowBlock(true)
            }}
          >
            <i className='fas fa-ban' /> {blocked ? "" : "Block (b) (r)"}
          </button>
          <button
            className={classnames(style.verify, {
              [style.active]: confirmed,
            })}
            onClick={() => {
              setShowVerify(true)
            }}
          >
            <i className='fas fa-check' /> {confirmed ? "" : "Verify (v)"}
          </button>
        </div>

        <PhotoMenu
          id={id}
          userId={userId}
          onReject={() => {
            ContentService.ackContent("image", id)
          }}
          username={username}
        />
      </div>
    </>
  )
}

PhotoContent.propTypes = {
  image: object,
  setShowBlock: func,
  setShowVerify: func,
}

export default PhotoContent
