import { bool, func, number, string } from "prop-types"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"

import consts from "@guardian/Constants"
import { eventHub } from "@guardian/State"

import { ContentService } from "@guardian/Services/Content"
import { IncidentService } from "@guardian/Services/Incident"
import { shouldReplace } from "@guardian/Utils/history"
import combokeys from "@guardian/Utils/hotkeys"
import { copyToClipboard } from "@guardian/Utils/util"

import CheckboxInput from "@guardian/Components/Form/Inputs/CheckboxInput"
import TextAreaInput from "@guardian/Components/Form/Inputs/TextAreaInput"
import BlockModal from "@guardian/Components/Modals/BlockModal"
import P2PMergeIncidentModal from "@guardian/Components/VideoMod/P2PMergeIncidentModal"

import InAppVideos from "./InAppVideos"
import style from "./IncidentPane.module.css"
import IncidentThumbnail from "./IncidentThumbnail"

const IncidentPane = ({
  title,
  address,
  lastUpdateText,
  level,
  id,
  streamersCount,
  author,
  activeContent,
  activeContentId,
  activeContentType,
  onResolve,
  selectedThumb,
  onSubmit,
  resolved,
  featuredStreamImageCropY,
}) => {
  const [showBlock, setShowBlock] = useState(false)
  const [editing, setEditing] = useState(false)
  const [update, setUpdate] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [isReporter, setIsReporter] = useState(
    activeContentType === "video_stream"
      ? activeContent.videoStream.isReporter
      : false,
  )

  const onResolveRef = useRef(onResolve)

  const isBlocked = activeContentType === "video_stream" && activeContent.videoStream.blocked
  const userReport = activeContentType === "video_stream" && activeContent.videoStream.userReport || null

  useEffect(() => {
    onResolveRef.current = onResolve
  }, [onResolve])

  useEffect(() => {
    combokeys.bind("a", async () => {
      await ContentService.ackContent(activeContentType, activeContentId)
      onResolveRef.current(activeContentId)
    })
    combokeys.bind("b", () => {
      setShowBlock(true)
    })
    combokeys.bind("r", () => {
      setShowBlock(true)
    })

    return () => {
      combokeys.unbind("a")
      combokeys.unbind("b")
      combokeys.unbind("r")
    }
  }, [activeContentId, activeContentType])

  useEffect(() => {
    setUpdate("")
  }, [editing])

  const handleBlock = useCallback(async (reason) => {
    try {
      await ContentService.blockContent(
        activeContentType,
        activeContentId,
        "Content Mod",
        { reason },
      )
      if (id && author === consts.AutomatedUsername && streamersCount <= 1) {
        // Also archive the incident if it's a P2P incident and not Citizen Approved
        // (i.e.: Unmoderated OR Community Approved)
        try {
          await IncidentService.updateIncident(id, { deleted: true })
        } catch (error) {
          console.error("Error blocking incident in incident update: ", error)
        }
      }
    } catch (error) {
      console.error("Error blocking incident in content service: ", error)
    }

    setShowBlock(false)
    onResolve(activeContentId)
  }, [activeContentType, activeContentId, id, streamersCount, author, onResolve])

  const handleP2PMergeSuccess = useCallback(() => {
    setShowModal(false)
    onResolve(activeContentId)
  }, [onResolve, activeContentId])

  const relativePath = `/incidents/${id}`
  return (
    <div className={style.pane}>
      <Link
        to={relativePath}
        replace={shouldReplace(relativePath)}
        target='_blank'
      >
        <h4 className={style.title}>
          {title}
          <i
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              copyToClipboard(
                `${window.location.protocol}${window.location.host}${relativePath}`,
              )
              eventHub.emit("showFlash", "link copied")
            }}
            className='fas fa-link'
          />
        </h4>
      </Link>
      {userReport?.resolved === false && !!userReport?.message && (
        <div className={style.reportReason}>
          <div className={style.reportTitle}>Reason for report</div>
          <p>{userReport.message}</p>
        </div>
      )}
      <div className={style.level}>
        <CheckboxInput
          label='Is Reporter?'
          value={isReporter}
          onChange={val => {
            setIsReporter(val)
            ContentService.isReporterContent(
              activeContentType,
              activeContentId,
              val,
            )
          }}
        />
      </div>

      <div className={style.detail}>
        <p>Address</p>
        <h5>{address}</h5>
      </div>
      {(lastUpdateText || editing) && (
        <div className={style.detail}>
          <p>Last Update</p>
          {!editing && <h5>{lastUpdateText}</h5>}
          {editing && (
            <TextAreaInput
              onChange={val => setUpdate(val)}
              placeholder='Describe what’s Happening…'
              defaultValue={update || ""}
              key='update'
              onEnter={() => {
                onSubmit(update)
                setEditing(false)
              }}
            />
          )}
        </div>
      )}

      <div className={style.level}>
        <p>Level:</p>
        <h5>{level}</h5>
      </div>
      <div className={style.controls}>
        {!editing && (
          <button
            className={style.approve}
            onClick={async () => {
              await ContentService.ackContent(
                activeContentType,
                activeContentId,
              )
              onResolve(activeContentId)
            }}
            disabled={resolved}
          >
            Approve (a)
          </button>
        )}
        {!editing && !isBlocked && (
          <button
            className={style.block}
            onClick={() => setShowBlock(true)}
            disabled={resolved}
          >
            Block (b) (r)
          </button>
        )}
        <button
          className={style.create}
          onClick={() => {
            if (editing) {
              onSubmit(update)
            }

            setEditing(!editing)
          }}
          disabled={editing && !update}
        >
          {editing ? "Send Update" : "Create Update"}
        </button>
        {editing && (
          <button
            className={style.cancel}
            onClick={() => {
              setEditing(false)
            }}
          >
            Cancel
          </button>
        )}
      </div>

      <div className={style.controls}>
        <button className={style.create} onClick={() => setShowModal(true)}>
          Enter a farther incident
        </button>
        {showModal && (
          <P2PMergeIncidentModal
            currentStreamId={activeContentId}
            currentIncidentId={id}
            onClose={() => setShowModal(false)}
            onSuccess={handleP2PMergeSuccess}
          />
        )}
      </div>
      <div className={style.subpane}>
        <IncidentThumbnail
          selectedThumb={selectedThumb}
          incidentId={id}
          featuredStreamImageCropY={featuredStreamImageCropY}
        />
      </div>
      <InAppVideos id={id} activeContentId={activeContentId} />
      {showBlock && (
        <BlockModal
          onBlock={handleBlock}
          onCancel={() => setShowBlock(false)}
        />
      )}
    </div>
  )
}

IncidentPane.propTypes = {
  title: string,
  address: string,
  lastUpdateText: string,
  level: number,
  id: string,
  streamersCount: number,
  author: string,
  activeContentId: string,
  onResolve: func,
  selectedThumb: string,
  onSubmit: func,
  resolved: bool,
  featuredStreamImageCropY: number,
  activeContentType: string,
}

export default memo(IncidentPane)
