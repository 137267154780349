import styled from "styled-components";

interface NavigationItemNotificationProps {
  visible: boolean
};

const NavigationItemNotification = styled.span<NavigationItemNotificationProps>`
  align-items: center;
  display: flex;
  background: var(--colors-red-040);
  border-radius: var(--radii-circle);
  font-size: var(--font-sizes-x-small);
  height: 18px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  right: 3px;
  transform: scale(90%) translateY(-45%);
  transition: all 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
  width: 18px;

  ${({ visible }: NavigationItemNotificationProps) => visible && `
    opacity: 1;
    transform: scale(100%) translateY(-50%);
  `}

`;

export default NavigationItemNotification;
