import Combokeys from "combokeys"

import GlobalBind from "combokeys/plugins/global-bind"
import Pause from "combokeys/plugins/pause"

const combokeys = new Combokeys(document)
GlobalBind(combokeys)
Pause(combokeys)

export default combokeys

export const suppressEvent = callback => {
  return e => {
    e.stopPropagation()
    e.preventDefault()

    callback(e)
  }
}
