import React from "react"
import { string, number } from "prop-types"
import dayjs from "dayjs"
import ErrorMessage from "./ErrorMessage"

const NetworkStatus = ({
  name = null,
  frequency = null,
  wifiStrength = null,
  connectionType = null,
  className,
  healthcheck = null,
  limeSdrStatus,
}) => {
  const displayConnectionType = !connectionType ? "n/a" : connectionType
  const displayFreq = frequency !== null && displayConnectionType !== "n/a"
  return (
    <div className={className}>
      <h4>Network Status</h4>
      <div>
        <p>SDR usb cable</p>
        <h5>{limeSdrStatus}</h5>
        {limeSdrStatus === "disconnected" && (
          <ErrorMessage message='Check usb cables are connected and power cycle device.' />
        )}
      </div>
      {
        <div>
          <p>Connected to</p>
          <h5>{displayConnectionType}</h5>
        </div>
      }
      {!!name && (
        <div>
          <p>Network Name</p>
          <h5>{name}</h5>
        </div>
      )}
      {displayFreq && (
        <div>
          <p>Frequency</p>
          <h5>{frequency} GHz</h5>
          {frequency < 5 && <ErrorMessage message='5GHz network suggested' />}
        </div>
      )}
      {wifiStrength !== null && displayConnectionType === "wifi" && (
        <div>
          <p>Network Strength</p>
          <h5>{wifiStrength}%</h5>
          {displayConnectionType !== "n/a" && wifiStrength < 60 && (
            <ErrorMessage message='Move closer to router' />
          )}
          {displayConnectionType === "n/a" && (
            <ErrorMessage message='not connected' />
          )}
        </div>
      )}
      {healthcheck !== null && (
        <div>
          <p>Last Healthcheck</p>
          <h5>{dayjs(healthcheck).fromNow()}</h5>
        </div>
      )}
    </div>
  )
}

NetworkStatus.propTypes = {
  name: string,
  frequency: number,
  wifiStrength: number,
  connectionType: string,
  className: string,
  healthcheck: string,
  limeSdrStatus: string,
}

export default NetworkStatus
