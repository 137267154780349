import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const XLargeTextStyles = css`
  font-size: var(--font-sizes-x-large);
  line-height: var(--line-heights-x-large);
`;

const XLargeText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__x-large-text")
    };
  }
)`
  ${ XLargeTextStyles }
`;

XLargeText.propTypes = {
  ...Text.propTypes
};

XLargeText.defaultProps = {};

export default XLargeText;
