import classnames from "classnames"
import { get } from "lodash"
import React, { useRef, useState } from "react"

import { useGlobalState } from "@guardian/State"

import { joinCall } from "@guardian/Components/ModSOS/api"
import { ConfirmModal } from "@guardian/Components/ModSOS/components/ActionBar/ConfirmModal/ConfirmModal"
import { CallNode } from "@guardian/Components/ModSOS/components/CallQueue/CallNode/CallNode"
import { getInitialSeconds } from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.helper"
import { useEventListener } from "@guardian/Components/ModSOS/hooks/useEventListener"
import { showErrorModal } from "@guardian/Components/ModSOS/store/dashboard/actions/dashboard"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { TErrorModal } from '@guardian/Components/ModSOS/store/dashboard/types'

import styles from "./CallNodeLink.module.css"
import {
  IProps,
  SensorType,
} from "./CallNodeLink.types"
import { INCIDENT_UPSELL } from "./data"

export const CallNodeLink: React.FunctionComponent<IProps> = ({
  payload,
  currentId,
}) => {
  const userId = useGlobalState(state => state.global.user?.id)

  const containerRef = useRef<any>()
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const { dispatch, state: dashboardState } = useSOSDashboardContext()

  const toggleModalVisibility = () => {
    if (currentId !== payload.id) {
      setIsConfirmModalVisible(currentValue => !currentValue)
    }
  }

  const isOccupied = () =>
    payload.metadata.analystName &&
    payload.metadata.agentUserId !== userId

  const handleClick = (e: MouseEvent) => {
    if (!containerRef.current || containerRef.current.contains(e.target as Node)) {
      return
    }

    setIsConfirmModalVisible(false)
  }

  useEventListener("mousedown", handleClick, window)

  const handleJoinNodeSession = () => {
    if (currentId === payload.id) {
      return
    }

    if (isOccupied()) {
      dispatch(
        showErrorModal({
          type: TErrorModal.CallTaken,
          metadata: {
            // TODO: !! this has to be removed
            joinCall: () => joinCall(currentId, payload.id),
          },
        }),
      )
    } else {
      joinCall(currentId, payload.id)
    }
  }

  const handleClickYesAndToggle = () => {
    if (isOccupied()) {
      dispatch(
        showErrorModal({
          type: TErrorModal.CallTaken,
          metadata: {
            // TODO: !! this has to be removed
            joinCall: () => {
              handleJoinNodeSession()
              toggleModalVisibility()
            },
          },
        }),
      )
    } else {
      handleJoinNodeSession()
      toggleModalVisibility()
    }
  }

  return (
    <div className={classnames(styles.modal)} ref={containerRef}>
      {payload.id !== currentId && isConfirmModalVisible && currentId && (
        <ConfirmModal
          text={"Starting this call will resolve your current call"}
          onClickYes={handleClickYesAndToggle}
          onClickNo={toggleModalVisibility}
          offsetLeft={128}
          offsetTop={-25}
          isLeftOrientation
          confirmText='Ok'
          declineText='Cancel'
        />
      )}
      <div
        id={payload.id}
        onClick={currentId ? toggleModalVisibility : handleJoinNodeSession}
      >
        <CallNode
          key={payload.id}
          fullName={payload.metadata.fullName}
          initialDuration={getInitialSeconds(payload.metadata.createdAt)}
          analyst={payload.metadata.analystName}
          is911={payload.metadata.active911Call === "yes"}
          isFirstCall={payload.metadata.isFirstCall || false}
          isActive={payload.id === currentId}
          isFromIncident={payload.metadata.entryPointType === INCIDENT_UPSELL}
          isFromNonSubscriber={payload.metadata.isNonSubscriber || false}
          status={payload.status}
          mode={payload.metadata.mode}
          entryPoint={get(
            dashboardState.sessionResp,
            "data.entryPoint.type",
            "default",
          )}
          sensorType={
            payload.metadata.entryPointType &&
            payload.metadata.entryPointType in SensorType
              ? SensorType[
                  payload.metadata.entryPointType as keyof typeof SensorType
                ]
              : undefined
          }
        />
      </div>
    </div>
  )
}
