import classNames from "classnames";
import styled from "styled-components";

const Footer = styled.div.attrs(
  props => {
    return {
      className: classNames("modal__footer")
    };
  }
)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-top: auto;
  padding:
    0
    var(--space-x-large)
    var(--space-small);
`;

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
