import React from "react";

import { Button, ButtonIcon } from "@guardian/UI/Button";
import { Icon } from "@guardian/UI/Icon";
import {
  Dropdown,
  Item,
  Menu,
  Trigger
} from "@guardian/UI/Dropdown";

import { AgentChat } from "@guardian/Lib/QueryClient/hooks";

const MacroButton = props => {
  const {
    disabled,
    onSelect
  } = props;

  const { data: macros, isLoading } = AgentChat.useGetMacros();

  const handleSelect = (macro) => {
    onSelect(macro.content);
  };

  if (isLoading) {
    return <span/>;
  }

  return (
    <Dropdown
      onSelect={handleSelect}
      downshiftProps={{ itemToString: item => item && item.name }}
    >
      <Trigger>
        <Button tightenX tightenY disabled={disabled}>
          <ButtonIcon.Start>
            <Icon.Bolt/>
          </ButtonIcon.Start>
          Apply Macro
        </Button>
      </Trigger>
      <Menu placement="top-start">
        <React.Fragment>
          {
            macros.map((item, i) => (
              <Item key={i} value={item}>
                { item.name }
              </Item>
            ))
          }
        </React.Fragment>
      </Menu>
    </Dropdown>
  );
};

export default MacroButton;
