import { useEffect } from "react"
// NOTE: For the time being our app still uses the outdated "history" object
// commonly used in older versions of react-router-dom. In reality, we should
// be updating all of these to use `useHistory`. However, it is believed this
// effort would need to be done all at once, where every usage of our internal
// "history" object and its subsequent "push", "replace", etc calls, be updated
// together, allowing us to swap to react-router-dom's "BrowserHistory" method.
import { Router } from "react-router-dom"
import queryString from "query-string"

import { loadAnalytics } from "@guardian/Analytics/segment"
import config from "@guardian/Config"
import { eventHub } from "@guardian/State"
import { Main, ThemeProvider } from "@guardian/UI/Theme"
import { DialogMessages, FlashMessages } from "@guardian/UI/Alert"
import history from "@guardian/Utils/history"

import Dashboard from "@guardian/Components/Dashboard"
import LogAssistant from "@guardian/Workflows/LogAssistant"

import { QueryClientSystem } from "@guardian/Lib/QueryClient"

import ErrorBoundary from "./components/ErrorBoundary"

const App = () => {
  useEffect(() => {
    if (window.location.host.match("guardian")) {
      window.location.replace(
        window.location.href.replace("guardian", "protect"),
      )
    }

    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.assign(`https://${window.location.host}`)
    }

    const env = config.production ? "PRODUCTION" : "STAGING"
    loadAnalytics(env)

    eventHub.emit("processURLQuery", queryString.parse(window.location.search))

    document.title = import.meta.env.PROD ? "ProtectOS" : "ProtectOS | STAGING"

    if (
      !window.location.pathname.match("caterpie") &&
      !window.location.pathname.match("modalpha")
    ) {
      const script = document.createElement("script")
      // Note the &language=en-US: This overrides whatever the user's browser setting is and requests the map in English (US)
      // This is important, because requesting the map/places in other languages will cause the localized place names to appear
      // in-app for all users.
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places&language=en-US`
      script.async = true
      document.body.appendChild(script)
    }
  }, [])

  return (
    <Router history={history}>
      <ThemeProvider theme={Main}>
        <LogAssistant />
        <ErrorBoundary>
          <QueryClientSystem>
            <DialogMessages />
            <FlashMessages />
            <Dashboard />
          </QueryClientSystem>
        </ErrorBoundary>
      </ThemeProvider>
    </Router>
  )
}

export default App
