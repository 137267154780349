import React, { FC } from "react"
import Container from "@guardian/Components/Common/Container"
import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import Spacing from "@guardian/Components/Common/Spacing"
import { IProps } from "@guardian/Components/ModSOS/components/Video/VideoStats/Stat/types"

export const VideoStat: FC<IProps> = ({ displayName, value }) => (
  <Spacing right={2} key={displayName}>
    <Container style={{ width: 72 }} leftJustify column>
      <h2 className={styles.statNumber}>{value}</h2>
      <Spacing top={1}>
        <h4 className={styles.statName}>{displayName}</h4>
      </Spacing>
    </Container>
  </Spacing>
)
