import { getPOSConfig } from "@guardian/API/Optimus/resources/legacy/resources/Protect";
import { IGL } from "@guardian/API/Optimus";
import { ContentService } from "@guardian/Services/Content"

const app = state => {
  state.on("setTab", tab => {
    if (state.get().global.tab !== tab) {
      state.get().global.set("tab", tab)
      if (["data_labeling", "audit_tool"].includes(tab)) {
        state.get().radio.set({ clips:[], clipsView: [] })
      }
    }
  })

  state.on("scrollToActive", () => {
    const activeClip = state.get().radio.activeClip
    const divElem = document.getElementById("clip-list")
    const chElem = document.getElementById(activeClip.id)
    if (!chElem) {
      return
    }
    const topPos = chElem.offsetTop
    divElem.scrollTop = Math.max(0, topPos - window.innerHeight / 3)
  })

  state.on("closePopups", () => {
    state.get().radio.set({
      channelSelectionGroup: undefined,
      clipContextMenu: undefined,
    })
    state.get().moderation.set({
      dialogMask: false,
      mediaPopup: undefined,
      thumbnailsPopup: undefined,
    })
  })

  state.on("dragStart", id => {
    state.get().global.set("dragging", id)
  })
  state.on("dragEnd", () => {
    state.get().global.set("dragging", false)
  })

  state.on("processURLQuery", q => {
    if (q.channels) {
      const channels = {}
      q.channels.split(",").forEach(cid => (channels[cid] = true))
      // Sad flag to prevent active channels being set from user configuration fetched after auth
      state.get().radio.set("activeChannelOverride", true)
      state.emit("saveActiveChannels", channels)
    }
    if (q.invite_code) {
      state.get().global.set("referrerId", q.invite_code)
    }
  })

  state.on("setMediaPopup", mediaPopup => {
    state.get().moderation.set("mediaPopup", mediaPopup)
  })

  state.on("setDialogMask", dialogMask => {
    state.get().moderation.set("dialogMask", dialogMask)
  })

  state.on("closeThumbnailsPopup", () => {
    state.get().moderation.set("thumbnailsPopup", undefined)
  })

  state.on("openThumbnailsPopup", (props) => {
    state.get().moderation.set("thumbnailsPopup", props)
  })

  state.on("toggleThumbnailsPopup", props => {
    const popup = state.get().moderation.thumbnailsPopup
    if (popup && popup.stream.id === props.stream.id) {
      state.emit("closeThumbnailsPopup")
    } else {
      state.emit("openThumbnailsPopup", props)
    }
  })

  state.on("mapInit", () => {
    if (!state.get().global.mapInit) {
      state.get().global.set("mapInit", true)
    }
  })

  state.on("unset metapod", () => {
    if (state.get().global.metapod) {
      state.get().global.set("metapod", false)
    }
  })

  state.on("show error modal", err => {
    state.get().global.set("error", err)
  })

  state.on("close error modal", () => {
    state.get().global.set("error", undefined)
  })

  state.on("show error banner", err => {
    state.get().global.set("errorBanner", err)
  })

  state.on("clear slow network", () => {
    if (
      state.get().global.errorBanner &&
      state
        .get()
        .global.errorBanner.message.toLowerCase()
        .match("slow network")
    ) {
      state.get().global.set("errorBanner", undefined)
    }
  })

  state.on("fetchRejectionChoices", async () => {
    const { data: { reasons } } = await IGL.getRejectionReasons()
    const choices = {}
    reasons.forEach(reason => {
      choices[reason.posString] = reason.reason
    })

    state.get().moderation.set("rejectionChoices", choices)
  })

  state.on("fetchPOSConfig", async () => {
    const resp = await getPOSConfig()

    state.get().global.set({
      customNotifConfig: resp.customNotifConfig,
      streetTeamUsers: resp.streetTeamUsers,
      chronicAddresses: resp.chronicAddresses,
      modBroadcasterConfig: resp.modBroadcasterConfig,
      showClipsConfig: resp.showClipsConfig,
      clipPriorityConfig: resp.clipPriorityConfig,
      autoExpansionExperimentCityCodes: resp.autoExpansionExperimentCityCodes,
      transcriptionConfig: resp.transcriptionConfig,
      duplicateDetectionConfig: resp.duplicateDetectionConfig,
      transcriptionsDisabled: resp.transcriptionsDisabled,
    })
  })

  state.on("fetchCreators", async () => {
    try {
      const creators = await ContentService.getContentCreators()

      state.get().global.set({
        creators: creators || [],
      })
    } catch (err) {
      console.error(err)
    }
  })
}

export default app
