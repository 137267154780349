import React from "react"
import { Button } from "@material-ui/core"
import Container from "@guardian/Components/Common/Container"
import { IProps } from "@guardian/Components/ModSOS/components/ErrorModals/UserInteractionModalBody/types"

export const UserInteractionModalBody = React.forwardRef(
  (props: IProps, ref) => {
    const { onClose } = props
    return (
      <Container
        style={{ backgroundColor: "white", color: "#000" }}
        column
        top={4}
        bottom={4}
        left={6}
        right={6}
        leftJustify
      >
        <Container>
          <h2>Incoming call sound failed to play.</h2>
        </Container>
        <Container bottom={8}>
          Please interact with page (such as clicking on OK on this modal) to
          allow incoming call sound to play.
        </Container>
        <Container fullWidth rightJustify>
          <Button onClick={onClose} color='primary' variant='contained'>
            Ok
          </Button>
        </Container>
      </Container>
    )
  },
)
