const ActionTypes = {
  SetNotificationCategory: "setNotificationCategory",
  SetNotifType: "setNotifType",
  SetNotifSentiment: "setNotifSentiment",
  SetNotifSound: "setNotifSound",
  SetNotifTitle: "setNotifTitle",
  SetNotifMessage: "setNotifMessage",
  SetRenotify: "setRenotify",
  SetShowIncidentMetadata: "setShowIncidentMetadata",
  SetNotifSoundOn: "setNotifSoundOn",
  SetIncidentCreationFields: "setIncidentCreationFields",
  SetIncidentCategory: "setIncidentCategory",
  SetIncidentAddress: "setIncidentAddress",
  SetIncidentTitle: "setIncidentTitle",
  SetIncidentUpdate: "setIncidentUpdate",
  SetCall911: "setCall911",
  SetInitialStreamData: "setInitialStreamData",
  SetMergingIncident: "setMergingIncident",
  SetDistributionType: "setDistributionType",
  SetDistributionValue: "setDistributionValue",
  SetSendToInactive: "setSendToInactive",
  SetLocationLocation: "setLocationLocation",
  SetRadiusOverride: "setRadiusOverride",
  SetCanSubmit: "setCanSubmit",
  SetSendNotifInternalOnly: "setSendNotifInternalOnly",
  SetNotifTurnedOn: "setNotifTurnedOn",
  SetSafetyLevel: "setSafetyLevel",
  SetLargeMap: "setLargeMap",
}

const reducer = (state, action) => {
  const returnState = { ...state }
  switch (action.type) {
    case ActionTypes.SetNotificationCategory:
      returnState.notificationCategory = action.payload.type
      if (action.payload.type === "custom") {
        returnState.radiusOverride = 0
      } else {
        returnState.radiusOverride = undefined
      }
      break
    case ActionTypes.SetNotifType: {
      const { type, defaults } = action.payload
      returnState.notifType = type
      returnState.notifSentiment = defaults[type].defaultSentiment
      returnState.notifSound = defaults[type].defaultSound
      break
    }
    case ActionTypes.SetNotifSentiment:
      returnState.notifSentiment = action.payload.sentiment
      break
    case ActionTypes.SetNotifSound:
      returnState.notifSound = action.payload.sound
      break
    case ActionTypes.SetNotifTitle:
      returnState.notifTitle = action.payload.title
      if (action.payload.edit) {
        returnState.editedNotifTitle = true
      }
      break
    case ActionTypes.SetNotifMessage:
      returnState.notifMessage = action.payload.message
      if (action.payload.edit) {
        returnState.editedNotifMessage = true
      }
      break
    case ActionTypes.SetRenotify:
      returnState.renotify = action.payload.renotify
      break
    case ActionTypes.SetShowIncidentMetadata:
      returnState.showIncidentMetadata = action.payload.showIncidentMetadata
      break
    case ActionTypes.SetNotifSoundOn:
      returnState.notifSoundOn = action.payload.soundOn
      break
    case ActionTypes.SetIncidentCreationFields:
      returnState.incidentTitle = action.payload.title
      returnState.incidentUpdate = action.payload.update
      returnState.mergingIncident = undefined
      break
    case ActionTypes.SetIncidentCategory:
      returnState.incidentCategory = action.payload.category
      break
    case ActionTypes.SetIncidentAddress:
      returnState.location = action.payload.address
      break
    case ActionTypes.SetIncidentTitle:
      returnState.incidentTitle = action.payload.title
      returnState.notifMessage = action.payload.title
      break
    case ActionTypes.SetIncidentUpdate:
      returnState.incidentUpdate = action.payload.update
      break
    case ActionTypes.SetCall911:
      returnState.prompt911 = action.payload.value
      break
    case ActionTypes.SetInitialStreamData:
      returnState.location = action.payload.location
      returnState.originalLocation = action.payload.location
      returnState.incidentTitle = action.payload.title
      returnState.originalTitle = action.payload.title
      returnState.notifTitle = action.payload.notifTitle
      returnState.mergingIncident = undefined
      break
    case ActionTypes.SetMergingIncident:
      if (action.payload.incident.id) {
        returnState.mergingIncident = action.payload.incident
        returnState.location = action.payload.incident.location
        returnState.incidentTitle = action.payload.incident.title
        if (!action.payload.onAir) {
          returnState.notifTitle = action.payload.incident.title
        } else {
          returnState.notifMessage = `BREAKING NOW: ${returnState.incidentTitle}. Tap to watch.`
        }
      } else {
        returnState.location = state.originalLocation
        returnState.incidentTitle = state.originalTitle
        returnState.notifTitle = state.originalTitle
        returnState.mergingIncident = undefined
      }
      break
    case ActionTypes.SetDistributionType:
      returnState.distributionType = action.payload.type
      if (action.payload.type === "radius") {
        returnState.distributionValue = "3.25"
      }
      if (action.payload.type === "geography") {
        returnState.distributionValue = []
      }
      break
    case ActionTypes.SetDistributionValue:
      returnState.distributionValue = action.payload.value
      break
    case ActionTypes.SetSendToInactive:
      returnState.sendToInactive = action.payload.send
      break
    case ActionTypes.SetLocationLocation:
      returnState.location.location = action.payload.location
      break
    case ActionTypes.SetRadiusOverride:
      returnState.radiusOverride = action.payload.radius
      break
    case ActionTypes.SetCanSubmit:
      returnState.canSubmit = action.payload.canSubmit
      break
    case ActionTypes.SetSendNotifInternalOnly:
      returnState.sendNotifInternalOnly = action.payload.send
      break
    case ActionTypes.SetNotifTurnedOn:
      returnState.notifTurnedOn = action.payload.turnedOn
      break
    case ActionTypes.SetSafetyLevel:
      returnState.safetyLevel = action.payload.level
      break
    case ActionTypes.SetLargeMap:
      returnState.largeMap = !state.largeMap
      break
    default:
      break
  }
  return returnState
}

export { ActionTypes, reducer }
