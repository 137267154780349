export const inputStyles = {
  root: {
    paddingLeft: "16px",
    minHeight: "52px",
    height: "52px",
    width: "100%",
    color: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
  },
  notchedOutline: {
    "& > legend": {
      visibility: "visible",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "16px",
      color: "#8C8C8C !important",
    },
  },
}
