import { Container } from './styled'

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  icon: any
  isActive: boolean
  activeColor: string
  inactiveColor: string
}

const IconToggle: React.FC<IProps> = ({
  icon: IconComponent,
  isActive,
  inactiveColor,
  activeColor,
  ...rest
}) => (
  <Container {...rest}>
    <IconComponent fill={isActive ? activeColor : inactiveColor} />
  </Container>
)

export default IconToggle
