import React from "react";

import { AppContext } from "@guardian/Core";
import { useGlobalState } from "@guardian/State";
import { objPick } from "@guardian/Utils/obj-pick";

import useAgentChat from "./hooks/useAgentChat";
import useModerator from "./hooks/useModerator";

const AppSystem = props => {
  const {
    children
  } = props;

  const { readOnly, permissions } = useGlobalState(state => {
    return objPick(state.global, [
      "readOnly",
      "permissions"
    ]);
  });

  const allowances = {
    agentChat: permissions.citMod && permissions.sosMod && !readOnly,
    moderator: (permissions.citMod || permissions.trainee) && !readOnly,
  };

  const agentChat = useAgentChat({ enabled: allowances.agentChat });
  const moderator = useModerator({ enabled: allowances.moderator });

  const system = {
    app: {
      allowances
    },
    agentChat,
    moderator
  };

  return (
    <AppContext.Provider value={system}>
      { children }
    </AppContext.Provider>
  );
};

export default AppSystem;
