import React from "react";

import { Label as FormLabel } from "@guardian/UI/Form";
import { event as eventUtils } from "@guardian/UI/Utils";

import useDropdownContext from "../../hooks/useDropdownContext";
import useFieldContext from "../../hooks/useFieldContext";

// Must be nested with a `<Field>` component.
const Label = React.forwardRef(
  ({ onMouseEnter, onMouseLeave, ...other }, ref) => {
    const {
      downshift: { getLabelProps }
    } = useDropdownContext();
    const { setIsLabelHovered } = useFieldContext();

    const labelProps = getLabelProps({
      onMouseEnter: eventUtils.callAllEventHandlers(onMouseEnter, () => {
        setIsLabelHovered(true);
      }),
      onMouseLeave: eventUtils.callAllEventHandlers(onMouseLeave, () => {
        setIsLabelHovered(false);
      }),
      ...other
    });

    return (
      <FormLabel ref={ref} {...labelProps}/>
    );
  }
);

export default Label;
