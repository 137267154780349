import classNames from "classnames";
import styled from "styled-components";

const QueueList = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__queue-list"
      )
    };
  }
)`
  flex-grow: 1;
  height: 100%;
  min-height: 0;
  scroll-padding: 0px 0px var(--space-small) 0px;
  width: 100%;
`;

QueueList.propTypes = {};

QueueList.defaultProps = {};

export default QueueList;
