import { MouseEvent, MouseEventHandler, PureComponent } from 'react';

import Flag from '@guardian/Assets/Flag.svg?react';
import IconToggle from '@guardian/Components/IconToggle';

import FlagContextMenu from "./FlagContextMenu";

interface FlagOnlyToggleProps {
  flaggedOnly: boolean;
  onFlagToggle: Function;
}

interface FlagOnlyToggleState {
  contextMenuVisible: boolean;
  contextMenuX: number;
  contextMenuY: number;
}

class FlagOnlyToggle extends PureComponent<FlagOnlyToggleProps, FlagOnlyToggleState> {
  constructor(props: FlagOnlyToggleProps) {
    super(props);
    this.state = {
      contextMenuVisible: false,
      contextMenuX: 0,
      contextMenuY: 0,
    };
  }

  handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    this.setState({ contextMenuVisible: true, contextMenuX: e.clientX, contextMenuY: e.clientY });
  };

  closeContextMenu = () => {
    this.setState({ contextMenuVisible: false });
  };

  render() {
    const { onFlagToggle, flaggedOnly } = this.props;
    const { contextMenuVisible, contextMenuX, contextMenuY } = this.state;
    return (
      <>
        <IconToggle
          className="icon-toggle"
          icon={Flag}
          onClick={onFlagToggle as MouseEventHandler}
          onContextMenu={this.handleContextMenu}
          isActive={flaggedOnly}
          inactiveColor="#8C8C8C"
          activeColor="#FFC800"
          title='Flagged Only'
        />
        {contextMenuVisible && (
          <FlagContextMenu
            x={contextMenuX}
            y={contextMenuY}
            onClose={this.closeContextMenu}
          />
        )}
      </>
    );
  }
}

export default FlagOnlyToggle;
