import classNames from "classnames";
import styled from "styled-components";

const NotesTab = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__notes-panel__notes"
    )
  };
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

NotesTab.defaultProps = {};

export default NotesTab;
