import classNames from "classnames";
import styled from "styled-components";

const Header = styled.div.attrs(
  props => {
    return {
      className: classNames("modal__header")
    };
  }
)`
  align-items: center;
  box-shadow: var(--borders-bottom) var(--colors-grey-080);
  color: var(--colors-white);
  display: flex;
  font-size: var(--font-sizes-large);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-large);
  min-height: calc(var(--line-heights-large) + (var(--space-small) * 2.0));
  padding:
    var(--space-small)
    var(--space-xxx-large)
    var(--space-small)
    var(--space-x-large);
`;

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
