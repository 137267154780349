import React from "react"
import { soundManager } from "soundmanager2"
import { string, number, func } from "prop-types"

soundManager.setup({
  debugMode: false,
})

const pendingCalls = []

function createSound(options, cb) {
  if (soundManager.ok()) {
    cb(soundManager.createSound(options))
    return () => {}
  } else {
    const call = () => {
      cb(soundManager.createSound(options))
    }

    pendingCalls.push(call)

    return () => {
      pendingCalls.splice(pendingCalls.indexOf(call), 1)
    }
  }
}

soundManager.onready(() => {
  pendingCalls.slice().forEach(cb => cb())
})

function noop() {}

const playStatuses = {
  PLAYING: "PLAYING",
  STOPPED: "STOPPED",
  PAUSED: "PAUSED",
}

export default class Sound extends React.Component {
  static status = playStatuses

  static defaultProps = {
    playFromPosition: 0,
    onLoading: noop,
    onPlaying: noop,
    onFinishedPlaying: noop,
    onPlay: noop,
    onPause: noop,
  }

  componentDidMount() {
    const { onStalled, onLoadedData } = this.props
    this.createSound(sound => {
      // If the audio stalls and the clip is not fully
      // loaded, reload the clip
      const HAVE_ENOUGH_DATA = 4

      sound._a.addEventListener("stalled", function() {
        if (this.readyState !== HAVE_ENOUGH_DATA) {
          onStalled()
        }
      })

      sound._a.addEventListener("suspended", function() {
        if (this.readyState !== HAVE_ENOUGH_DATA) {
          this.load()
          this.play()
        }
      })

      sound._a.addEventListener("loadeddata", function() {
        onLoadedData()
      })

      if (this.props.playStatus === playStatuses.PLAYING) {
        sound.play()
      }
    })
  }

  componentWillUnmount() {
    this.removeSound()
  }

  componentDidUpdate(prevProps) {
    const withSound = sound => {
      if (!sound) {
        return
      }

      if (this.props.playStatus === playStatuses.PLAYING) {
        // Playback rate seems to revert to first play when looping. Force set on each play.
        sound.setPlaybackRate(prevProps.playbackRate || 1)
        if (sound.playState === 0) {
          sound.play()
        }

        if (sound.paused) {
          sound.resume()
        }
      } else if (this.props.playStatus === playStatuses.STOPPED) {
        if (sound.playState !== 0) {
          sound.stop()
        }
      } else {
        // this.props.playStatus === playStatuses.PAUSED
        if (!sound.paused) {
          sound.pause()
        }
      }

      if (this.props.playFromPosition !== prevProps.playFromPosition) {
        sound.setPosition(this.props.playFromPosition)
      }

      if (this.props.position != null) {
        if (
          sound.position !== this.props.position &&
          Math.round(sound.position) !== Math.round(this.props.position)
        ) {
          sound.setPosition(this.props.position)
        }
      }

      if (this.props.playbackRate !== prevProps.playbackRate) {
        if (this.props.playbackRate) {
          sound.setPlaybackRate(this.props.playbackRate)
        } else {
          sound.setPlaybackRate(1)
        }
      }
    }

    if (this.props.url !== prevProps.url) {
      this.createSound(withSound)
    } else {
      withSound(this.sound)
    }
  }

  createSound(callback) {
    this.removeSound()

    const props = this.props

    if (!props.url) {
      return
    }

    this.stopCreatingSound = createSound(
      {
        url: this.props.url,
        whileloading() {
          props.onLoading(this)
        },
        whileplaying() {
          props.onPlaying(this)
        },
        onfinish() {
          props.onFinishedPlaying()
        },
        onplay() {
          props.onPlay()
        },
        onpause() {
          props.onPause()
        },
      },
      sound => {
        this.sound = sound
        sound.setPlaybackRate(props.playbackRate || 1)
        callback(sound)
      },
    )
  }

  removeSound() {
    if (this.stopCreatingSound) {
      this.stopCreatingSound()
      delete this.stopCreatingSound
    }

    if (this.sound) {
      try {
        this.sound.destruct()
      } catch (e) {} // eslint-disable-line

      delete this.sound
    }
  }

  render() {
    return null
  }
}

Sound.propTypes = {
  url: string,
  playbackRate: number,
  position: number,
  playStatus: string,
  playFromPosition: number,
  onStalled: func,
  onLoadedData: func,
}
