import React, { PureComponent } from "react"
import { eventHub } from "@guardian/State"
import styles from "./styles.module.css"

class Flash extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      text: "",
    }
  }
  componentDidMount() {
    eventHub.on("showFlash", this.setText)
  }
  setText = text => {
    this.setState({ text, visible: true })
    setTimeout(() => this.setState({ visible: false }), 1000)
  }
  render() {
    if (!this.state.visible) {
      return null
    }
    return <div className={styles.flash}>{this.state.text}</div>
  }
}

export default Flash
