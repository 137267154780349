import { OptimusAPI } from "@guardian/API/Optimus";

interface IncidentTemplatesGetIncidentTemplateDraftsParams {
  templateName: string
};

interface IncidentTemplatesGetModuleDataData {
  templateName: string
  dataFile: any
};

interface IncidentTemplatesUploadTemplateJsonData {
  templateJson: any
  templateName: string | undefined
};

interface IncidentTemplatesUploadTemplateDraftJsonData {
  templateJson: any
  templateName: string | undefined
  draftName: string | undefined
};

const IncidentTemplates = {
  getIncidentTemplates: (options: any = {}) => {
    return OptimusAPI.get(`/incident_templates/fetch`, options);
  },

  getIncidentTemplateDrafts: (
    params: IncidentTemplatesGetIncidentTemplateDraftsParams,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/incident_templates/drafts`,
      {
        ...options,
        params: {
          ...options.params,
          ...params
        }
      }
    );
  },

  getModuleData: (
    data: IncidentTemplatesGetModuleDataData,
    options?: any
  ) => {
    return OptimusAPI.post(`incident_templates/get_data`, data, options);
  },

  uploadTemplateJson: (
    data: IncidentTemplatesUploadTemplateJsonData,
    options?: any
  ) => {
    return OptimusAPI.post(`/incident_templates/upload`, data, options);
  },

  uploadTemplateDraftJson: (
    data: IncidentTemplatesUploadTemplateDraftJsonData,
    options?: any
  ) => {
    return OptimusAPI.post(`/incident_templates/save_draft`, data, options);
  }
};

export default IncidentTemplates;
