import { OptimusAPI } from "@guardian/API/Optimus"

interface VideoStreamBoostStreamData {
  boostOrder: number
}

interface VideoStreamHighlightStreamData {
  highlighted: boolean
}

interface VideoStreamSetStreamThumbsData {
  image: string
}

interface VideoStreamTrimStreamData {
  start: number
  duration: number
}

interface VideoStreamUploadFromAssetsData {
  incidentId: string
  thumbUrl: string
  mp4Url: string
  isReporter?: boolean
}

interface VideoStreamVerifyStreamData {
  confirmed: boolean
  editorsChoice: boolean
  messageEdits: string
  titleEdits: string
  radiusOverride: number
}

const VideoStreams = {
  getDefaultVideos: (options: any = {}) => {
    return OptimusAPI.get(`/default_videos/fetch`, options)
  },

  getLiveStreams: (options: any = {}) => {
    return OptimusAPI.get(`/v1/video_streams/get_all_live`, options)
  },

  getStream: (streamId: string, options: any = {}) => {
    return OptimusAPI.get(`/v2/video_stream/${streamId}`, options)
  },

  getStreamThumbs: (streamId: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/video_stream/${streamId}/images`, options)
  },

  boostStream: (
    streamId: string,
    data: VideoStreamBoostStreamData,
    options: any = {},
  ) => {
    return OptimusAPI.patch(`/v1/video_stream/${streamId}/boost`, data, options)
  },

  highlightStream: (
    streamId: string,
    data: VideoStreamHighlightStreamData,
    options: any = {},
  ) => {
    return OptimusAPI.patch(
      `/v1/video_stream/${streamId}/highlight`,
      data,
      options,
    )
  },

  trimStream: (
    streamId: string,
    data: VideoStreamTrimStreamData,
    options: any = {},
  ) => {
    return OptimusAPI.post(`/v1/video_stream/${streamId}/trim`, data, options)
  },

  setStreamThumb: (
    streamId: string,
    data: VideoStreamSetStreamThumbsData,
    options: any = {},
  ) => {
    return OptimusAPI.patch(`/v1/video_stream/${streamId}/image`, data, options)
  },

  uploadFromAssets: (
    data: VideoStreamUploadFromAssetsData,
    options: any = {},
  ) => {
    return OptimusAPI.post(`/v1/video_stream/from_assets`, data, options)
  },

  uploadFromFormData: (data: FormData, options: any = {}) => {
    return OptimusAPI.post(`/v1/video_stream/from_form_data`, data, options)
  },

  verifyStream: (
    streamId: string,
    data: VideoStreamVerifyStreamData,
    options: any = {},
  ) => {
    return OptimusAPI.post(
      `/v1/video_stream/${streamId}/confirm`,
      data,
      options,
    )
  },

  completeStream: (
    streamId: string,
    options: any = {},
  ) => {
    return OptimusAPI.put(
      `/v1/video_stream/${streamId}/complete`,
      undefined,
      options,
    )
  },
}

export default VideoStreams
