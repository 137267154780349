import { OptimusAPI } from "@guardian/API/Optimus";

interface UsersUpdateUserData {
  username: string
  email: string
};

interface UsersUpdateUsePermissionsData {
  permissions: { [key: string]: number }
};

const Users = {
  getUser: (userId: string, options?: any) => {
    return OptimusAPI.get(`/v1/user/${ userId }`, options);
  },

  getUserStreams: (userId: string, options: any = {}) => {
    return OptimusAPI.get(`/v2/users/${ userId }/video_streams`, options);
  },

  checkUsername: (username: string, options: any = {}) => {
    return OptimusAPI.get(
      `/v1/users/check_username`,
      {
        ...options,
        params: {
          ...options.params,
          username
        }
      }
    );
  },

  updateUser: (userId: string, data: UsersUpdateUserData, options?: any) => {
    return OptimusAPI.post(`/v1/user/${ userId }`, data, options);
  },

  updateUserPermissions: (
    userId: string,
    data: UsersUpdateUsePermissionsData,
    options?: any
  ) => {
    return OptimusAPI.patch(`/v1/users/${ userId }/permissions`, data, options);
  }
};

export default Users;
