import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Reference } from "react-popper";
import { useCombinedRefs } from "@zendeskgarden/container-utilities";

import { event as eventUtils } from "@guardian/UI/Utils";

import useDropdownContext from "../../hooks/useDropdownContext";
import useFieldContext from "../../hooks/useFieldContext";
import { SelectContext } from "../../hooks/useSelectContext";
import StyledInput from "../../views/Input";
import StyledSelect from "../../views/Select/Select";

import Clear from "./Clear";
import Toggle from "./Toggle";

// Applies state and a11y attributes to its children. Must be nested within a
// `<Field>` component.
const Select = React.forwardRef(({ children, ...props }, ref) => {

  const {
    downshift: {
      getInputProps,
      getToggleButtonProps,
      isOpen,
    },
    popperReferenceElementRef,
    selectedItem
  } = useDropdownContext();
  const { isLabelHovered } = useFieldContext();

  const [isClearHovered, setIsClearHovered] = useState(false);
  const [isSelectHovered, setIsSelectHovered] = useState(false);
  const [isToggleFocused, setIsToggleFocused] = useState(false);

  const hiddenInputRef = useRef(null);
  const triggerRef = useCombinedRefs(ref, popperReferenceElementRef);
  const previousIsOpenRef = useRef(undefined);

  useEffect(() => {
    // Focus internal input when Menu is opened.
    if (isOpen && !previousIsOpenRef.current) {
      hiddenInputRef.current && hiddenInputRef.current.focus();
    }

    // Focus trigger when Menu is closed.
    if (!isOpen && previousIsOpenRef.current) {
      triggerRef.current && triggerRef.current.focus();
    }

    previousIsOpenRef.current = isOpen;
  }, [isOpen, triggerRef]);

  const selectProps = getToggleButtonProps({
    focused: isToggleFocused || isOpen,
    // Because we place additional elements into our `StyledSelect`, we cannot
    // rely on the underlying `FauxInput` to automatically populate the
    // `placeholder` when the actual selected content is empty. Hence, we pass the
    // `forcePlaceholder` prop dependant on if there is a `selectedItem` in our
    // `Dropdown` context.
    forcePlaceholder: selectedItem == null,
    hovered: isLabelHovered && !isOpen,
    isOpen: isOpen,
    onMouseEnter: eventUtils.callAllEventHandlers(props.onMouseEnter, () => {
      setIsSelectHovered(true);
    }),
    onMouseLeave: eventUtils.callAllEventHandlers(props.onMouseLeave, () => {
      setIsSelectHovered(false);
    }),
    tabIndex: props.disabled ? undefined : 0,
    ...props
  });

  const toggleButtonProps = {
    disabled: props.disabled,
    hovered: (isSelectHovered && !isClearHovered) || isLabelHovered,
    isOpen: isOpen
  };

  return (
    <Reference>
      {
        ({ ref: popperReference }) => (
          <SelectContext.Provider
            value={
              {
                isClearHovered,
                isToggleFocused,
                setIsClearHovered,
                setIsToggleFocused
              }
            }>
            <StyledSelect
              {...selectProps}
              ref={selectRef => {
                // Pass ref to popperJS for positioning.
                popperReference(selectRef);
                // Store ref locally to return focus on close.
                triggerRef.current = selectRef;
                // Apply Select ref to global Dropdown context.
                popperReferenceElementRef.current = selectRef;
              }}
            >
              { children }
              <StyledInput
                {
                  ...getInputProps({
                    readOnly: true,
                    isHidden: true,
                    tabIndex: -1,
                    ref: hiddenInputRef,
                    value: ""
                  })
                }
              />
              {
                props.isClearable && (
                  <Clear
                    disabled={props.disabled}
                  />
                )
              }
              <Toggle {...toggleButtonProps}/>
            </StyledSelect>
          </SelectContext.Provider>
        )
      }
    </Reference>
  );
});

Select.displayName = "Select";

Select.propTypes = {
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  validation: PropTypes.oneOf(["error", "info", "success", "warning"])
};

export default Select;
