import { useEffect } from "react"

import combokeys from "@guardian/Utils/hotkeys"

export type KeyBindings = Record<string, (event: KeyboardEvent) => void>

export default function useKeyBindings(keybindings: KeyBindings) {
  useEffect(() => {
    Object.entries(keybindings).forEach(([key, fn]) => {
      combokeys.bind(key, fn)
    })
    return () => {
      Object.keys(keybindings).forEach(key => {
        combokeys.unbind(key)
      })
    }
  }, [keybindings])
}
