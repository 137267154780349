import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const Toggle = styled.span.attrs(
  props => {
    const {
      isOpen
    } = props;

    return {
      className: classNames("dropdown__toggle-button__toggle", {
          "dropdown__toggle-button__toggle--is-open": isOpen
        })
    };
  }
)`
  position: relative;

  &::before, &::after {
    background-color: transparent;
    border-bottom: 7px solid currentColor;
    content: "";
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(0);
    transition: 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 1.5px;
  }

  &::before {
    transform: rotate(-135deg);
    left: calc(50% + 1.5px);
    top: calc(50% - 3px);
  }

  &::after {
    transform: rotate(135deg);
    left: calc(50% - 2.5px);
    top: calc(50% - 3px);
  }

  &.dropdown__toggle-button__toggle--is-open {
    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
`;

Toggle.propTypes = {
  isOpen: PropTypes.bool
};

Toggle.defaultProps = {
  isOpen: false
};

export default Toggle;
