import classNames from "classnames";
import styled from "styled-components";

import { XSmallText as GenericXSmallText } from "@guardian/UI/Typography";

const MessageStatus = styled(GenericXSmallText).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__message-status"
      )
    };
  }
)`
  color: var(--colors-white);
  max-width: 100%;
`;

MessageStatus.propTypes = {};

MessageStatus.defaultProps = {
  as: "span",
  truncate: true
};

export default MessageStatus;
