import React, { useEffect, useState } from "react"

import { dateToUTC } from "@guardian/Utils/util"

import Stat from "./Stat"

const CurrentTime = () => {
  const [timeInUtc, setTimeInUtc] = useState(dateToUTC().format("HH:mm:ss"))

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeInUtc(dateToUTC().format("HH:mm:ss"))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Stat name='UTC' value={timeInUtc} centerName />
  )
}

export default CurrentTime
