import classNames from "classnames";
import styled from "styled-components";

const InputArea = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__chat-tab__input-area")
    }
  }
)`
  background: var(--colors-grey-095);
  flex: 0 0 100px;
  padding: 0 var(--space-medium) var(--space-medium);
`;

export default InputArea;
