import classNames from "classnames";
import styled from "styled-components";

import { Button as GenericButton } from "@guardian/UI/Button";

const OutboundMessagingButton = styled(GenericButton).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "sos-dms__outbound-dm__button")
    };
  }
)`
  display: inline-flex;
`;

OutboundMessagingButton.propTypes = {
  ...GenericButton.propTypes
};

OutboundMessagingButton.defaultProps = {
  ...GenericButton.defaultProps,
  isPrimary: true,
  pill: true
};

export default OutboundMessagingButton;
