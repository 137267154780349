import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const XLargeParagraphStyles = css`
  font-size: var(--font-sizes-x-large);
  line-height: var(--line-heights-x-large);

  & + & {
    margin-top: var(--line-heights-x-large);
  }

  &.paragraph--with-measure {
    max-width: var(--measures-x-large);
    width: 100%;
  }
`;

const XLargeParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__x-large-paragraph")
    };
  }
)`
  ${ XLargeParagraphStyles }
`;

XLargeParagraph.propTypes = {
  ...Paragraph.propTypes
};

XLargeParagraph.defaultProps = {};

export default XLargeParagraph;
