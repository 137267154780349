import React, { useContext, useEffect, useReducer } from "react"
import _, { noop } from "lodash"
import {
  ITokboxSession,
  VideoControls,
  VideoResponse,
} from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.types"
import { getOwnerID } from "@guardian/Utils/protect"
import * as Video from "twilio-video"
import OT from "opentok-react"
import { TOKBOX_API_KEY } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.helper"
import {
  addTokboxWatcherStream,
  addTwilioContact,
  removeTwilioContact,
  resetVideoContext,
  updateTokbox911Stream,
  updateTokboxOwnerStream,
  updateTokboxSession,
  updateTwilioDialOutParticipant,
  updateTwilioOwnerParticipant,
  updateTwilioRoom,
  updateUserDisabledAgentAudio,
  updateUserDisabledAgentVideo,
  updateUserMicDisabled,
  updateUserSpeakerMuted,
  updateVideoDeviceId,
  updateVideoProvider,
} from "@guardian/Components/ModSOS/store/video/actions/video"
import { IAction, IState } from "@guardian/Components/ModSOS/store/video/types"
import { reducer } from "@guardian/Components/ModSOS/store/video/reducers/video.reducers"
import { Debugger } from "@guardian/Utils/debugger"

const videoContext = new Debugger("VideoContext")

//

interface IProps {
  children: any
  activeSessionId?: string
  videoControls?: VideoControls
  videoResponse?: VideoResponse
  videoDeviceId: string
}

const VideoContext = React.createContext<{
  state: IState
  dispatch: (action: IAction) => void
}>({
  state: {
    activeSessionId: "loading",
    videoProvider: "loading",
    userSpeakerMuted: false,
    userMicDisabled: false,
    userDisabledAgentAudio: false,
    userDisabledAgentVideo: false,
    ownerID: "loading",
    videoDeviceId: "loading",
    twilioContacts: [],
    tokboxWatcherStreams: [],
  },
  dispatch: noop,
})

export const SOSVideoContextProvider = ({
  children,
  activeSessionId,
  videoControls,
  videoResponse,
  videoDeviceId,
}: IProps) => {
  const [state, dispatch] = useReducer(reducer, {
    activeSessionId,
    ownerID: getOwnerID(activeSessionId),
    videoDeviceId: videoDeviceId,
    videoProvider: videoResponse?.videoProvider || "loading",
    userSpeakerMuted: videoControls ? !videoControls.userSpeakerOn : false,
    userMicDisabled: videoControls ? !videoControls.userMicOn : false,
    userDisabledAgentAudio: videoControls
      ? !videoControls.userSpeakerOn
      : false,
    userDisabledAgentVideo: videoControls
      ? !videoControls.agentCameraOn
      : false,
    twilioContacts: [],
    tokboxWatcherStreams: [],
  })

  const SOSVideoContextStore = {
    state,
    dispatch,
  }

  const twilioParticipantConnected = (participant: any) => {
    if (participant.identity === state.ownerID) {
      dispatch(updateTwilioOwnerParticipant(participant))
    } else if (_.startsWith(participant.identity, "dialOut")) {
      dispatch(updateTwilioDialOutParticipant(participant))
    } else {
      dispatch(addTwilioContact(participant))
    }
  }

  const twilioParticipantDisconnected = (participant: any) => {
    if (participant.identity === state.ownerID) {
      dispatch(updateTwilioOwnerParticipant(undefined))
    } else if (_.startsWith(participant.identity, "dialOut")) {
      dispatch(updateTwilioDialOutParticipant(undefined))
    } else {
      dispatch(removeTwilioContact(participant))
    }
  }

  const setTwilioVideoData = async (videoResponse: any) => {
    videoContext.startMeasure()

    if (!videoResponse.videoToken) {
      console.error("no video token")
      return
    }

    videoContext.addBreakPoint("videoTokenCollectedAt")

    const tracks = await Video.createLocalTracks({
      audio: true,
      video: {
        deviceId: videoDeviceId,
      },
    })

    videoContext.addBreakPoint("localTrackCreatedAt")

    const dataTrack = new Video.LocalDataTrack()
    let room
    try {
      room = await Video.connect(videoResponse.videoToken, {
        name: activeSessionId,
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
        tracks: [...tracks, dataTrack],
      })
    } catch (error: any) {
      console.error("Failed to acquire media:", error.name, error.message)
    }
    if (!room) {
      console.error("no room")
      return
    }
    videoContext.addBreakPoint("connectedToVideoAt")
    videoContext.logResult("twillio-video connected")

    dispatch(updateTwilioRoom(room))
    room.participants.forEach(twilioParticipantConnected)
    room.on("participantConnected", twilioParticipantConnected)
    room.on("participantDisconnected", twilioParticipantDisconnected)

    room.on("participantReconnected", twilioParticipantConnected)
    room.on("participantReconnecting", twilioParticipantDisconnected)
  }

  const setTokboxVideoData = async (videoResponse: any) => {
    const session: ITokboxSession = {
      apiKey: TOKBOX_API_KEY,
      sessionId: videoResponse.videoSessionId,
      token: videoResponse.videoToken,
      onStreamsUpdated: (streams: any) => {
        let watchers: Array<any> = []
        let found911Stream: any = {}
        streams.forEach((stream: any) => {
          const data = JSON.parse(_.get(stream, "connection.data", "{}"))
          const userID = _.get(data, "userId", "")
          if (userID === state.ownerID && !_.isEmpty(stream)) {
            dispatch(updateTokboxOwnerStream(stream))
            return
          }
          if (_.startsWith(userID, "dialOut")) {
            found911Stream = stream
            return
          }
          watchers.push(stream)
        })
        dispatch(updateTokbox911Stream(found911Stream))
        _.each(watchers, (watcher: any) => {
          dispatch(addTokboxWatcherStream(watcher))
        })
      },
    }
    const newSession = await OT.createSession(session)
    newSession.session.on("signal:muteSpeaker", (e: any) => {
      dispatch(updateUserSpeakerMuted(e.data === "disabled"))
      dispatch(updateUserDisabledAgentAudio(e.data === "disabled"))
    })
    newSession.session.on("signal:muteAgentVideo", (e: any) => {
      dispatch(updateUserDisabledAgentVideo(e.data === "disabled"))
    })
    newSession.session.on("streamDestroyed", (e: any) => {
      if (e.stream.id === _.get(state.tokboxOwnerStream, "id", "")) {
        dispatch(updateTokboxOwnerStream(undefined))
      }
    })

    dispatch(updateTokboxSession(newSession))
  }

  const cleanupTokboxSession = (tokboxSession: any) => {
    tokboxSession.session.off("signal:muteSpeaker")
    tokboxSession.session.off("signal:muteAgentVideo")
    tokboxSession.session.off("streamDestroyed")
    tokboxSession.disconnect()
  }

  useEffect(() => {
    dispatch(updateVideoDeviceId(videoDeviceId))
    if (videoResponse) {
      if (videoResponse.videoProvider === "twilio") {
        setTwilioVideoData(videoResponse)
      } else if (videoResponse.videoProvider === "tokbox") {
        setTokboxVideoData(videoResponse)
      }
    }
  }, [videoDeviceId])

  useEffect(() => {
    if (videoResponse) {
      dispatch(updateVideoProvider(videoResponse.videoProvider))
      if (videoResponse.videoProvider === "twilio") {
        setTwilioVideoData(videoResponse)
      } else if (videoResponse.videoProvider === "tokbox") {
        setTokboxVideoData(videoResponse)
      }
    }
  }, [videoResponse])

  useEffect(() => {
    if (state.activeSessionId !== activeSessionId) {
      dispatch(
        resetVideoContext({
          activeSessionId,
          ownerID: getOwnerID(activeSessionId),
          videoDeviceId: videoDeviceId,
          twilioContacts: [],
          tokboxWatcherStreams: [],
        }),
      )
    }
  }, [activeSessionId])

  useEffect(() => {
    if (!videoControls) {
      return
    }
    dispatch(
      updateUserSpeakerMuted(!_.get(videoControls, "userSpeakerOn", true)),
    )
    dispatch(
      updateUserDisabledAgentAudio(
        !_.get(videoControls, "userSpeakerOn", true),
      ),
    )
    dispatch(updateUserMicDisabled(!_.get(videoControls, "userMicOn", true)))
    dispatch(
      updateUserDisabledAgentVideo(
        !_.get(videoControls, "agentCameraOn", true),
      ),
    )
  }, [videoControls])

  useEffect(() => {
    return () => {
      if (state.twilioRoom) {
        state.twilioRoom.disconnect()
      }
      if (state.tokboxSession) {
        cleanupTokboxSession(state.tokboxSession)
      }
    }
  }, [state.tokboxSession, state.twilioRoom])

  return (
    <VideoContext.Provider value={SOSVideoContextStore}>
      {children}
    </VideoContext.Provider>
  )
}

export const useSOSVideoContext = () => useContext(VideoContext)
