import React, { useState } from "react"
import { Checkbox, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import styles from "./ServiceAreaDropdown.module.css"
import { string, object, func, arrayOf } from "prop-types"
import _ from "lodash"

const useStyles = makeStyles({
  clearIndicator: {
    color: "white",
  },
  popupIndicator: {
    color: "white",
  },
})

const CustomTextField = withStyles({
  root: {
    "& label": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "& input": {
        backgroundColor: "transparent",
      },
    },
  },
})(TextField)

const ServiceAreaDropdown = ({
  selectedServiceAreas,
  serviceAreaConfig,
  onServiceAreaSelect,
}) => {
  const classes = useStyles()
  const [selectedAll, setSelectedAll] = useState(false)
  const options = _.values(serviceAreaConfig).map(sa => ({
    code: sa.code,
    title: sa.name,
  }))
  options.unshift({ code: "_all", title: "Select All" })

  const handleOnChange = (_, value) => {
    const allChosen = value.find(sa => sa.code === "_all")
    if (allChosen && !selectedAll) {
      setSelectedAll(true)
      onServiceAreaSelect(options)
    } else if (!allChosen && selectedAll) {
      onServiceAreaSelect([])
      setSelectedAll(false)
    } else {
      onServiceAreaSelect(value)
    }
  }

  return (
    <Autocomplete
      multiple
      classes={{
        ...styles,
        clearIndicator: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
      }}
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option.title}
      getOptionSelected={(option, value) => option.code === value.code}
      onChange={handleOnChange}
      limitTags={4}
      size='small'
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize='small' />}
            checkedIcon={<CheckBox fontSize='small' />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </>
      )}
      value={selectedServiceAreas}
      renderInput={params => (
        <CustomTextField
          {...params}
          variant='outlined'
          label='Active Service Areas'
          placeholder='Choose service areas'
        />
      )}
    />
  )
}

ServiceAreaDropdown.propTypes = {
  selectedServiceAreas: arrayOf(string),
  serviceAreaConfig: object,
  onServiceAreaSelect: func,
}

export default ServiceAreaDropdown
