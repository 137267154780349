import { useEffect } from "react"

import { eventHub } from "@guardian/State"

import DashboardPage from "./Dashboard"

const DataLabeling = () => {
  useEffect(() => {
    eventHub.emit("stopUpdatingClips")
    eventHub.emit("bindClipKeys")

    return () => {
      eventHub.emit("unbindKeys")
    }
  }, [])

  return <DashboardPage />
}

export default DataLabeling
