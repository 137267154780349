import classnames from "classnames"
import styles from "@guardian/Components/ModSOS/components/Video/Twillio/Twilio.module.css"
import React, { FC, useEffect, useRef } from "react"
import { IProps } from "@guardian/Components/ModSOS/components/Video/Twillio/components/AgentVideo/types"
import _ from "lodash"

export const AgentVideo: FC<IProps> = ({ videoTrack, twilioRoom }) => {
  const videoRef = useRef<HTMLVideoElement>(null!)

  useEffect(() => {
    if (videoTrack && !_.isEmpty(videoTrack)) {
      const $video = videoRef.current
      $video.muted = true

      videoTrack.attach($video)
    }

    return () => {
      if (videoTrack && twilioRoom) {
        if (!_.isEmpty(videoTrack)) {
          videoTrack.stop()
          videoTrack.disable()

          twilioRoom.localParticipant.unpublishTrack(videoTrack)
        }
      }
    }
  }, [twilioRoom, videoTrack])

  return (
    <video
      ref={videoRef}
      className={classnames(styles.twilioVideo, styles.isLocal)}
    />
  )
}
