import React from "react"
import { string, array } from "prop-types"
import dayjs from "dayjs"
import ErrorMessage from "./ErrorMessage"

const DeviceStatus = ({ className, sites, lastClip = null }) => {
  return (
    <div className={className}>
      <h4>Radio System Status</h4>
      {!!sites &&
        sites.map(site => (
          <div key={site.name}>
            <p>{site.name}</p>
            <h5>{site.packets || 0}/40</h5>
            {site.packets < 40 && <ErrorMessage message='40/40 required' />}
          </div>
        ))}
      {lastClip !== null && (
        <div>
          <p>Last Clip</p>
          <h5>{dayjs(lastClip).fromNow()}</h5>
        </div>
      )}
    </div>
  )
}

DeviceStatus.propTypes = {
  className: string,
  sites: array,
  lastClip: string,
}

export default DeviceStatus
