import React from "react"
import { spacing } from "./Container"

interface Props {
  top?: number
  right?: number
  bottom?: number
  left?: number

  className?: string

  fullWidth?: boolean

  children: any
  style?: any
}

const Spacing: React.FC<Props> = ({
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  children,
  fullWidth,
  style = {},
}) => {
  return (
    <div
      style={{
        marginTop: spacing(top),
        marginRight: spacing(right),
        marginBottom: spacing(bottom),
        marginLeft: spacing(left),
        width: fullWidth ? "100%" : undefined,

        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Spacing
