import _ from "lodash"
import Address from "./address"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(timezone)

export function stopProp(e) {
  e.stopPropagation()
}

export function getIncidentCategory(incident) {
  let category = "N/A"
  try {
    const cats = JSON.parse(atob(incident.categories))
    if (cats) {
      category = cats[0]
    }
  } catch (e) {}
  return category
}

export async function reverseGeoToLocation(latLng, reverseGeoRes) {
  const parsed = await Address.extractReverse(latLng, reverseGeoRes)
  let { address, location, neighborhood, cityCode, police } = parsed
  return {
    cityCode,
    location,
    address,
    neighborhood,
    police: police || "",
    lat: latLng.lat,
    lng: latLng.lng,
  }
}

export async function suggestionToLocation(suggestion) {
  const parsed = await Address.extract(suggestion)
  let { address, location, neighborhood, cityCode, police } = parsed
  return {
    cityCode,
    location,
    address,
    neighborhood,
    police: police || "",
    lat: suggestion.location.lat,
    lng: suggestion.location.lng,
  }
}

export function copyToClipboard(str) {
  const el = document.createElement("textarea")
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

const canonicalOrder = [
  "NYC",
  "NJ",
  "LA",
  "SF",
  "US-MD-BALTIMORE",
  "US-MI-FLINT",
  "MY",
]
export function sortServiceAreas(areas) {
  return sortCanonical(areas, canonicalOrder)
}

export function sortCanonical(items, order) {
  return items.sort((a, b) => {
    const idx1 = order.indexOf(a)
    const idx2 = order.indexOf(b)
    if (idx1 === idx2) {
      if (a < b) {
        return -1
      } else {
        return 1
      }
    }
    if (idx1 === -1) {
      return 1
    }
    if (idx2 === -1) {
      return -1
    }
    return idx1 - idx2
  })
}

export function coverageToHealthLevel(coverage) {
  switch (true) {
    case !coverage:
    case coverage < 0.25:
      return 0
    case coverage < 0.5:
      return 1
    case coverage < 0.75:
      return 2
    default:
      return 3
  }
}

export function timeString(time) {
  let fmt
  if (new Date(time) < new Date(Date.now() - 12 * 60 * 60 * 1000)) {
    fmt = "MMMM Do, HH:mm"
  } else {
    fmt = "HH:mm"
  }
  return dayjs(time).format(fmt)
}

export function getSpectroWidth(width, duration_ms) {
  return Math.min(width, (duration_ms / 1000) * 25)
}

export const secondsToMin = s => {
  const seconds = Math.floor(s)
  return `${Math.floor(seconds / 60)}:${
    seconds % 60 >= 10 ? "" : "0"
  }${seconds % 60}`
}

export const getThumbUrl = (url = "") => {
  return url.split("-crop").join("")
}

export const toHHMMSS = secs => {
  var sec_num = parseInt(secs, 10)
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor(sec_num / 60) % 60
  var seconds = sec_num % 60

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":")
}

export const round = (p, decimal) => {
  const tenth = 10 ** decimal
  return Math.round(p * tenth) / tenth
}

export const formatNumbers = n => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const dateToUTC = date => {
  return dayjs(date).utc()
}

export const dateToTimezone = (date, tz) => {
  return dayjs(date).tz(tz)
}

export const cleanIncidentUpdate = text => {
  text = text.trim()
  let re = /(^|[^.][a-z][!?.]\s+)([a-z])/g
  text = text.replace(re, (m, $1, $2) => $1 + $2.toUpperCase())

  if (
    // is alphanumeric
    text.charAt(text.length - 1).match(/^[a-z0-9]+$/i) !== null
  ) {
    text += "."
  }

  return text
}

/**
 * @param {string} string String to check for keywords
 * @param {string[]} keywords List of keywords to check for
 * @returns {boolean} Whether or not the string contains any of the keywords
 */
export const hasKeyword = (string, keywords) => {
  const mappedKeywords = keywords.map((keyword) => keyword.replace("*", "\\w*"))
  const regexKeywords = new RegExp(`\\b${mappedKeywords.join('|')}\\b`, 'i')

  return regexKeywords.test(string)
}

/**
 * This function takes 2 sets and checks if they are equal
 * @param {Set} set1
 * @param {Set} set2
 * @returns {boolean}
 */
export const equalSets = (set1, set2) => {
  if (set1.size !== set2.size) {
    return false
  }
  for (let a of set1) {
    if (!set2.has(a)) {
      return false
    }
  }
  return true
}

/**
 * This function takes a promise and a timeout in milliseconds
 * @param {number} ms
 * @param {Promise} promise
 * @returns {Promise}
 */
export const promiseTimeout = (ms, promise) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("timeout"))
    }, ms)
    promise.then(resolve, reject)
  })
}

/**
 * Retry a promise until it resolves or the number of retries is exhausted
 * @param {() => Promise<T>} promise
 * @param {number} retriesLeft
 * @param {number} interval
 * @param {(error: any) => bool} shouldRetry
 * @returns {Promise<T>}
 */
export function retryPromise(
  promise,
  retriesLeft = 5,
  interval = 1000,
  shouldRetry = () => true
) {
  return new Promise((resolve, reject) => {
    promise()
      .then(resolve)
      .catch(error => {
        if (retriesLeft === 0 || !shouldRetry(error)) {
          reject(error);
          return;
        }

        setTimeout(() => {
          retryPromise(promise, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
