import React from "react"
import { v4 as uuid } from "uuid"

import type { Entry } from "@guardian/Types/Utils"

import { Dialog, Flash } from "@guardian/UI/Alert"

interface LogOptionsInput {
  domain?: string
  method?: string
  consoleLog?: boolean
  dialog?: boolean
  dialogOptions?: { [key: string]: any }
  flash?: boolean
  flashOptions?: { [key: string]: any }
  trackedData?: { [key: string]: any }
  error?: Error
}

interface LogOptions {
  domain: string
  method: string
  consoleLog: boolean
  dialog: boolean
  dialogOptions: { [key: string]: any }
  flash: boolean
  flashOptions: { [key: string]: any }
  trackedData: { [key: string]: any }
  error?: Error
}

interface TrackedMessageData {
  trackingKey: string
  type: string
  message: string
  data: { [key: string]: any }
  timestamp: string
  origin?: string
}

class LoggingService {
  static MAX_TRACKED_MESSAGES = 500

  static MESSAGE_TYPE = {
    ERROR: "error",
    MESSAGE: "message",
    WARNING: "warning",
  }

  static MESSAGE_TYPE_STORAGE_KEY = {
    ERROR: "guardiannet:loggingService:error",
    MESSAGE: "guardiannet:loggingService:message",
    WARNING: "guardiannet:loggingService:warning",
  }

  static logError(message: string, options: LogOptionsInput = {}) {
    this.#log(this.MESSAGE_TYPE.ERROR, message, options)
  }

  static logMessage(message: string, options: LogOptionsInput = {}) {
    this.#log(this.MESSAGE_TYPE.MESSAGE, message, options)
  }

  static logWarning(message: string, options: LogOptionsInput = {}) {
    this.#log(this.MESSAGE_TYPE.WARNING, message, options)
  }

  static getTrackedErrors() {
    return this.#getTrackedMessages(this.MESSAGE_TYPE.ERROR)
  }

  static getTrackedMessages() {
    return this.#getTrackedMessages(this.MESSAGE_TYPE.MESSAGE)
  }

  static getTrackedWarnings() {
    return this.#getTrackedMessages(this.MESSAGE_TYPE.WARNING)
  }

  static clearTrackedErrors() {
    return this.#clearTrackedMessages(this.MESSAGE_TYPE.ERROR)
  }

  static clearTrackedMessages() {
    return this.#clearTrackedMessages(this.MESSAGE_TYPE.MESSAGE)
  }

  static clearTrackedWarnings() {
    return this.#clearTrackedMessages(this.MESSAGE_TYPE.WARNING)
  }

  static #log(
    messageType: string,
    message: string,
    rawOptions: LogOptionsInput = {},
  ) {
    const options = this.#getOptionsWithDefaults(rawOptions)
    const trackingKey = this.#generateTrackingKey()

    this.#consoleLog(messageType, trackingKey, message, options)

    this.#dialog(messageType, trackingKey, message, options)

    this.#flash(messageType, trackingKey, message, options)

    this.#track(messageType, trackingKey, message, options)
  }

  static #consoleLog(
    messageType: string,
    trackingKey: string,
    message: string,
    options: LogOptions,
  ) {
    const { consoleLog } = options

    if (consoleLog) {
      const consoleLogMethod = this.#getConsoleLogMethod(messageType)
      const consoleLogMessage = this.#formatConsoleLogMessage(
        message,
        options,
        trackingKey,
      )

      consoleLogMethod(consoleLogMessage, options)
    }
  }

  static #dialog(
    messageType: string,
    trackingKey: string,
    message: string,
    options: LogOptions,
  ) {
    const { dialog } = options

    if (dialog) {
      const dialogMethod = this.#getDialogMethod(messageType)
      const dialogMessage = this.#formatDialogMessage(
        message,
        options,
        trackingKey,
      )

      dialogMethod(dialogMessage, options)
    }
  }

  static #flash(
    messageType: string,
    trackingKey: string,
    message: string,
    options: LogOptions,
  ) {
    const { flash } = options

    if (flash) {
      const flashMethod = this.#getFlashMethod(messageType)
      const flashMessage = this.#formatFlashMessage(
        message,
        options,
        trackingKey,
      )

      flashMethod(flashMessage, options)
    }
  }

  static #track(
    messageType: string,
    trackingKey: string,
    message: string,
    options: LogOptions,
  ) {
    // Create new tracked message for type.
    const newTrackedMessage = this.#formatTrackedMessage(
      messageType,
      trackingKey,
      message,
      options,
    )

    // Get existing tracked messages for type.
    let trackedMessages = this.#getTrackedMessages(messageType)

    // Prepend new tracked message to existing tracked messages. Note that this
    // means items at the beginning of the array are always the latest messages
    // logged.
    trackedMessages = [newTrackedMessage].concat(trackedMessages)

    // If new tracked messages exceed our max length, cut out the additionals at
    // the end.
    if (trackedMessages.length > this.MAX_TRACKED_MESSAGES) {
      trackedMessages.splice(this.MAX_TRACKED_MESSAGES)
    }

    // Update in storage.
    this.#setTrackedMessages(messageType, trackedMessages)
  }

  // For now our tracked messages, no matter what type, are just an array. This
  // is fine but feels like maybe it'd be nice to instead deliver each type of
  // messages as an object, noting the type, and an array of the messages
  // therein. Just adding this comment in case anyone thinks that's useful
  // sometime to add.
  static #getDefaultTrackedMessages(): TrackedMessageData[] {
    return []
  }

  static #getOptionsWithDefaults(options: LogOptionsInput = {}): LogOptions {
    const defaultOptions: LogOptions = {
      domain: "",
      method: "",
      consoleLog: true,
      dialog: false,
      dialogOptions: {},
      flash: false,
      flashOptions: {},
      trackedData: {},
      error: undefined,
    }

    const entries = Object.entries(options) as ReadonlyArray<Entry<LogOptionsInput>>
    return entries.reduce((memo: LogOptions, [option, value]) => {
      if (value && option in defaultOptions) {
        return { ...memo, [option]: value }
      }

      return memo
    }, defaultOptions)
  }

  static #getTrackedMessages(messageType: string): TrackedMessageData[] {
    const storageKey = this.#getStorageKey(messageType)
    const trackedMessages = localStorage.getItem(storageKey)

    if (!trackedMessages) {
      return this.#getDefaultTrackedMessages()
    }

    return JSON.parse(trackedMessages)
  }

  static #setTrackedMessages(messageType: string, data?: TrackedMessageData[]) {
    const storageKey = this.#getStorageKey(messageType)
    const trackedMessages = JSON.stringify(
      data || this.#getDefaultTrackedMessages(),
    )

    localStorage.setItem(storageKey, trackedMessages)
  }

  static #clearTrackedMessages(messageType: string) {
    this.#setTrackedMessages(messageType)
  }

  static #consoleLogError(message: string, options: LogOptions) {
    // eslint-disable-next-line no-console
    console.error(message, options.error)
  }

  static #consoleLogMessage(message: string, options: LogOptions) {
    console.log(message)
  }

  static #consoleLogWarning(message: string, options: LogOptions) {
    // eslint-disable-next-line no-console
    console.warn(message)
  }

  static #dialogError(message: React.ReactNode, options: LogOptions) {
    Dialog.error(message, {
      title: "Error",
      ...options.dialogOptions,
    })
  }

  static #dialogMessage(message: React.ReactNode, options: LogOptions) {
    Dialog.info(message, {
      title: "Info",
      ...options.dialogOptions,
    })
  }

  static #dialogWarning(message: React.ReactNode, options: LogOptions) {
    Dialog.warning(message, {
      title: "Warning",
      ...options.dialogOptions,
    })
  }

  static #flashError(message: React.ReactNode, options: LogOptions) {
    Flash.error(message, {
      title: "Error",
      ...options.flashOptions,
    })
  }

  static #flashMessage(message: React.ReactNode, options: LogOptions) {
    Flash.info(message, {
      title: "Info",
      ...options.flashOptions,
    })
  }

  static #flashWarning(message: React.ReactNode, options: LogOptions) {
    Flash.warning(message, {
      title: "Warning",
      ...options.flashOptions,
    })
  }

  static #formatConsoleLogMessage(
    message: string,
    options: LogOptions,
    trackingKey: string,
  ): string {
    const { domain, method } = options
    const key = `Log Key: ${trackingKey}`
    const consoleLogMessage = `${domain}${method ? `#${method}` : ""}: ${message}\n${key}`

    return consoleLogMessage
  }

  static #formatDialogMessage(
    message: string,
    options: LogOptions,
    trackingKey: string,
  ): React.ReactNode {
    const dialogMessage = (
      <span>
        {message}
        <br />
        Log Key:
        <br />
        {trackingKey}
      </span>
    )

    return dialogMessage
  }

  static #formatFlashMessage(
    message: string,
    options: LogOptions,
    trackingKey: string,
  ): React.ReactNode {
    const flashMessage = (
      <span>
        {message}
        <br />
        Log Key:
        <br />
        {trackingKey}
      </span>
    )

    return flashMessage
  }

  static #formatTrackedMessage(
    messageType: string,
    trackingKey: string,
    message: string,
    options: LogOptions,
  ): TrackedMessageData {
    const { domain, method, trackedData } = options
    const timestamp = new Date().toISOString()

    const trackedMessage: TrackedMessageData = {
      trackingKey,
      type: messageType,
      message,
      data: trackedData,
      timestamp,
    }

    if (domain || method) {
      trackedMessage.origin = `${domain}${method ? `#${method}` : ""}`
    }

    return trackedMessage
  }

  static #generateTrackingKey(): string {
    return uuid()
  }

  static #getConsoleLogMethod(
    messageType: string,
  ): (message: string, options: LogOptions) => void {
    let logMethod

    switch (messageType) {
      case this.MESSAGE_TYPE.ERROR:
        logMethod = this.#consoleLogError
        break
      case this.MESSAGE_TYPE.MESSAGE:
        logMethod = this.#consoleLogMessage
        break
      case this.MESSAGE_TYPE.WARNING:
        logMethod = this.#consoleLogWarning
        break
      default:
        throw new Error(
          `LoggingService#getConsoleLogMethod: Attempted log for unsupported message type, ${messageType}`,
        )
    }

    return logMethod
  }

  static #getDialogMethod(
    messageType: string,
  ): (message: React.ReactNode, options: LogOptions) => void {
    let dialogMethod

    switch (messageType) {
      case this.MESSAGE_TYPE.ERROR:
        dialogMethod = this.#dialogError
        break
      case this.MESSAGE_TYPE.MESSAGE:
        dialogMethod = this.#dialogMessage
        break
      case this.MESSAGE_TYPE.WARNING:
        dialogMethod = this.#dialogWarning
        break
      default:
        throw new Error(
          `LoggingService#getDialogMethod: Attempted dialog for unsupported message type, ${messageType}`,
        )
    }

    return dialogMethod
  }

  static #getFlashMethod(
    messageType: string,
  ): (message: React.ReactNode, options: LogOptions) => void {
    let flashMethod

    switch (messageType) {
      case this.MESSAGE_TYPE.ERROR:
        flashMethod = this.#flashError
        break
      case this.MESSAGE_TYPE.MESSAGE:
        flashMethod = this.#flashMessage
        break
      case this.MESSAGE_TYPE.WARNING:
        flashMethod = this.#flashWarning
        break
      default:
        throw new Error(
          `LoggingService#getFlashMethod: Attempted flash for unsupported message type, ${messageType}`,
        )
    }

    return flashMethod
  }

  static #getStorageKey(messageType: string): string {
    let storageKey

    switch (messageType) {
      case this.MESSAGE_TYPE.ERROR:
        storageKey = this.MESSAGE_TYPE_STORAGE_KEY.ERROR
        break
      case this.MESSAGE_TYPE.MESSAGE:
        storageKey = this.MESSAGE_TYPE_STORAGE_KEY.MESSAGE
        break
      case this.MESSAGE_TYPE.WARNING:
        storageKey = this.MESSAGE_TYPE_STORAGE_KEY.WARNING
        break
      default:
        throw new Error(
          `LoggingService#getStorageKey: Attempted storage key lookup for unsupported message type, ${messageType}`,
        )
    }

    return storageKey
  }
}

export default LoggingService
