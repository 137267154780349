import _ from "lodash"
import { Room, LocalVideoTrack } from "twilio-video"

export const selectVideoTrack = (
  twilioRoom?: Room,
): LocalVideoTrack | undefined => {
  const videoTracks = _.get(
    twilioRoom,
    "localParticipant.videoTracks",
    new Map(),
  )
  return _.get(videoTracks.values().next(), "value.track", undefined)
}
