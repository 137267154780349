import React, { useMemo, useRef } from "react"

import { OutlinedInput } from "@material-ui/core"
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import { baseStyles, Customization } from "./TextInput.styles"

const useStyles = makeStyles(Customization)

const BaseInput = withStyles(baseStyles)(OutlinedInput)

const TextInput = (props: OutlinedInputProps) => {
  const originalValue = useRef(props.value)

  const textColor = useMemo(() => {
    return originalValue.current !== props.value ? "white" : "#8c8c8c"
  }, [props.value])

  const classes = useStyles({
    textColor,
  })

  return <BaseInput {...props} classes={{ input: classes.input }} />
}

export default TextInput
