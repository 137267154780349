import React, { useState } from "react"
import { object, string, func, bool } from "prop-types"
import classnames from "classnames"

import VideoPlayer from "@guardian/Components/VideoPlayer/VideoPlayer"
import VideoMenu from "@guardian/Components/VideoPlayer/VideoMenu"
import style from "./IncidentContentPane.module.css"
import ThumbnailList from "./ThumbnailList"

import { VideoCuration, VideoStreams } from "@guardian/API/Optimus";
import { ContentService } from "@guardian/Services/Content";

const VideoContent = ({
  stream,
  setShowBlock,
  setShowVerify,
  incidentId,
  incidentThumb,
  unverifiedIGLIncident,
}) => {
  const {
    id,
    hlsLiveUrl,
    hlsLiveImageUrl,
    hlsVodUrl,
    hlsVodMp4Url,
    broadcastDone,
    hlsDone,
    selectedThumb,
    userId,
    username,
    durationSeconds,
    originalDurationSeconds,
    confirmed,
    trimDuration,
    trimStart,
    blocked,
    needsCuration,
  } = stream.videoStream
  const [flagState, setFlagState] = useState(needsCuration)
  const [isLandscape, setIsLandscape] = useState(null)
  const isDone = broadcastDone || hlsDone

  return (
    <>
      <img
        className={style.videoImage}
        src={hlsLiveImageUrl}
        onLoad={(event) => setIsLandscape(event.target.width >= event.target.height)}
      />
      <div className={classnames(style.subpane, style.video)}>
        <VideoPlayer
          key={id}
          url={isDone ? hlsVodUrl : hlsLiveUrl}
          isLandscape={isLandscape}
          originalUrl={hlsVodMp4Url}
          live={!isDone}
          autoPlay
          menu={
            <VideoMenu
              id={id}
              username={username}
              userId={userId}
              onReject={() => {
                ContentService.ackContent("video_stream", id)
              }}
              location='Content Mod'
            />
          }
          onTrimStream={(trimStart, trimEnd) =>
            VideoStreams.trimStream(
              id,
              {
                start: Math.round(trimStart),
                duration: Math.round(trimEnd - trimStart)
              }
            )
          }
          durationSeconds={trimDuration}
          originalDuration={originalDurationSeconds}
          trimmed={!!trimDuration && trimDuration !== durationSeconds}
          trimStartingPoint={trimStart ?? 0}
          isTrimmable={isDone}
        />
        <div className={style.controls}>
          <button
            className={classnames(style.block, {
              [style.active]: blocked,
            })}
            onClick={() => {
              setShowBlock(true)
            }}
          >
            <i className='fas fa-ban' /> {blocked ? "" : "Block (b) (r)"}
          </button>
          {!unverifiedIGLIncident && (
            <button
              className={classnames(style.verify, {
                [style.active]: confirmed,
              })}
              onClick={() => {
                setShowVerify(true)
              }}
            >
              <i className='fas fa-check' /> {confirmed ? "" : "Verify (v)"}
            </button>
          )}
          <button
            className={classnames(style.flag, {
              [style.flagged]: flagState,
            })}
            onClick={async () => {
              if (flagState) {
                await VideoCuration.removeVideoFromCurationQueue(id)
                setFlagState(false)
              } else {
                await VideoCuration.addVideoToCurationQueue(id)
                setFlagState(true)
              }
            }}
          >
            <i className='fas fa-flag' />{" "}
            {flagState ? "De-Flag Video" : "Flag Video"}
          </button>
        </div>
      </div>
      <h4>Select a thumbnail</h4>
      <ThumbnailList
        id={id}
        broadcastDone={broadcastDone}
        hlsDone={hlsDone}
        selectedThumb={selectedThumb}
        incidentId={incidentId}
        incidentThumb={incidentThumb}
      />
    </>
  )
}

VideoContent.propTypes = {
  stream: object,
  incidentId: string,
  incidentThumb: string,
  setShowBlock: func,
  setShowVerify: func,
  blocked: bool,
  unverifiedIGLIncident: bool,
}

export default VideoContent
