import React, { useCallback, useEffect, useMemo } from "react";

import { Spinner } from "@guardian/UI/Loader";

import MessageGroups from "./components/MessageGroups";
import useAgentChatFeed from "./hooks/useAgentChatFeed";
import StyledChatArea from "./views/ChatArea";
import Loading from "./views/Loading";

const ChatArea = React.memo(function ChatArea(props) {
  const { session } = props;

  const groupId = useMemo(() => session.groupId, []);
  const sessionId = useMemo(() => session.id, []);

  const [
    {
      messageGroups,
    },
    canLoadMore,
    isError,
    isLoading,
    isLoadingMore,
    loadMore,
    setVisible
  ] = useAgentChatFeed({ groupId, sessionId });

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleLoadMore = useCallback(() => {
    if (canLoadMore && !isLoadingMore) {
      loadMore();
    }
  }, [canLoadMore, isLoadingMore, loadMore]);

  return (
    <StyledChatArea>
      {
        (isLoading || isError) ? (
          <Loading>
            <Spinner/>
          </Loading>
        ) : (
          <MessageGroups
            canLoadMore={canLoadMore}
            messageGroups={messageGroups}
            onScrolledToEnd={handleLoadMore}
            session={session}
          />
        )
      }
    </StyledChatArea>
  );
});

export default ChatArea;
