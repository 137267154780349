import React, { useEffect, useRef, useState } from "react";

import { AgentChatService } from "@guardian/Services/AgentChat";
import { LoggingService } from "@guardian/Services/Logging";

import useMenuContext from "../../../../hooks/useMenuContext";
import { MENU_ITEM_KEY } from "../../constants";

import ReassignModal from "./components/ReassignModal";

const ReassignHandler = props => {
  const { selection, session } = useMenuContext();

  const [agentUserId, setAgentUserId] = useState("");

  const [modalLoading, setModalLoading] = useState(false);
  const [modalLocked, setModalLocked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [reassignError, setReassignError] = useState(null);
  const [reassignTrigger, setReassignTrigger] = useState(null);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const agentUserIdRef = useRef(agentUserId);
  const sessionRef = useRef(session);

  useEffect(() => {
    agentUserIdRef.current = agentUserId;
  }, [agentUserId]);

  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  useEffect(() => {
    if (selection == null) { return; }

    if (selection.value === MENU_ITEM_KEY) {
      setModalOpen(true);
    }
  }, [selection]);

  useEffect(() => {
    if (reassignTrigger == null) {
      return;
    }

    let artificialDelay;

    const controller = new AbortController();
    const signal = controller.signal;

    const submit = () => {
      const data = {
        reassignToAgentUserId: agentUserIdRef.current,
        sessionId: session.id,
      };

      const options = { signal };

      setReassignError(null);
      setModalLoading(true);
      setModalLocked(true);

      artificialDelay = setTimeout(() => {
        AgentChatService.reassignAgentChatSession(data, options)
          .then(() => {
            setModalOpen(false);
            setModalLoading(false);
          })
          .catch(error => {
            if (signal.aborted) {
              return;
            }

            setReassignError(error);
            setModalLoading(false);
            setModalLocked(false);
          });
      }, 250);
    };

    submit();

    return () => {
      clearTimeout(artificialDelay);
      controller.abort();
    }
  }, [reassignTrigger]);

  useEffect(() => {
    if (reassignError == null) {
      return;
    }

    LoggingService.logError(
      "Unable to reassign Agen Chat Session.",
      {
        domain: "ReassignHandler",
        flash: true,
        error: reassignError,
        trackedData: {
          error: reassignError,
          session: sessionRef.current
        }
      }
    );
  }, [reassignError]);

  const handleChangeAgentUserId = (id) => {
    const disableSubmit = id.trim().length == 0;
    setSubmitDisabled(disableSubmit)
    setAgentUserId(id);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmReassign = () => {
    setReassignTrigger(+new Date());
  };

  const handleExitModal = () => {
    setAgentUserId("");
    setReassignError(null);
    setModalLoading(false);
    setModalLocked(false);
  };

  return (
    <ReassignModal
      agentUserId={agentUserId}
      locked={modalLocked}
      loading={modalLoading}
      onChangeAgentUserId={handleChangeAgentUserId}
      onClose={handleCloseModal}
      onConfirm={handleConfirmReassign}
      onExit={handleExitModal}
      open={modalOpen}
      submitDisabled={submitDisabled}
    />
  );
};

export default ReassignHandler;
