import React from "react";

import { Avatar } from "@guardian/UI/Avatar";

import UserInfo from "./components/UserInfo";
import AvatarWrapper from "./views/AvatarWrapper";
import StyledBaseUserInfo from "./views/BaseUserInfo";
import UserInfoWrapper from "./views/UserInfoWrapper";

const BaseUserInfo = props => {
  const {
    session
  } = props;

  const {
    id: sessionId,
    members,
    userId
  } = session;

  // TODO: This check should not occur here. This should fail much earlier in
  // the agent chat flow, likely at the moment we set members on each session
  // as they come in.
  const user = members && members[userId] ? members[userId] : {
    fullName: '[unknown member for userId ' + userId + ']',
    avatarURL: ''
  };

  return (
    <StyledBaseUserInfo>
      <UserInfoWrapper>
        <UserInfo session={session}/>
      </UserInfoWrapper>
      <AvatarWrapper>
        <Avatar
          id={user.userId}
          fullName={user.fullName}
          size={"56px"}
          src={user.avatarURL}
        />
      </AvatarWrapper>
    </StyledBaseUserInfo>
  );
};

export default BaseUserInfo;
