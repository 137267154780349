import { AgentChats } from '@guardian/API/Optimus';
import { AgentService } from "@guardian/Services/Agent";

interface AgentChatServiceGetGroupMessagesParams {
  limit?: number
  before?: number
};

interface AgentChatServiceReassignChatSessionData {
  reassignToAgentUserId: string,
  sessionId: string
};

interface AgentChatServiceSendAgentActionData {
  sessionId: string,
  action: { [key: string]: any }
};

interface AgentChatServiceUpdateAssignedChatSessionLifecycleData {
  sessionId: string,
  eventType: string,
  eventValue: { [key: string]: any }
};

interface AgentChatServiceUpdateAvailabilityData {
  available: boolean
};

interface AgentChatServiceSendGroupMessageData {
  id: string
  groupId: string
  body: { [key: string]: any }
};

interface AgentChatServiceSendOutboundMessageToUsersData {
  text: string
  userIds: string[]
};

const AgentChatService = {
  endAssignedChatSession: (sessionId: string, options: any={}) => {
    return new Promise((resolve, reject) => {
      AgentChats.endAssignedChatSession(sessionId, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  getSafetyFeed: (sessionId: string) =>
    AgentChats.getSafetyFeed(sessionId).then((response) => response.data),

  getAssignedChatSessions: (options: any={}): Promise<any> => {
    return new Promise((resolve, reject) => {
      const agentId = AgentService.userId;

      AgentChats.getAssignedChatSessions(agentId, options)
        .then(({ data: agentChatSessionsInfo }) => {
          resolve(agentChatSessionsInfo);
        })
        .catch(reject);
    });
  },

  getAssignedChatSessionUserInfo: (sessionId: string, options: any={}) => {
    return new Promise((resolve, reject) => {
      AgentChats.getAssignedChatSessionUserInfo(sessionId, options)
        .then(({ data: userInfo }) => {
          resolve(userInfo);
        })
        .catch(reject);
    });
  },

  getAssignedChatSessionUserRecords: (sessionId: string, options: any={}) => {
    return new Promise((resolve, reject) => {
      AgentChats.getAssignedChatSessionUserRecords(sessionId, options)
        .then(({ data: { records } }) => {
          resolve(records);
        })
        .catch(reject);
    });
  },

  getGroupInfo: (groupId: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      AgentChats.getGroupInfo(groupId, options)
        .then(({ data: info }) => {
          resolve(info);
        })
        .catch(reject);
    });
  },

  getGroupMessages: (
    groupId: string,
    rawParams: AgentChatServiceGetGroupMessagesParams,
    options: any = {}
  ) => {
    return new Promise((resolve, reject) => {
      let params: { [key: string]: any } = {};
      if (rawParams.limit) {
        params.limit = rawParams.limit.toString();
      }
      if (rawParams.before) {
        params.before = rawParams.before.toString();
      }

      AgentChats.getGroupMessages(groupId, params, options)
        .then(({ data: { history: messages } }) => {
          resolve(messages);
        })
        .catch(reject);
    });
  },

  getMacros: (options: any = {}) => {
    return new Promise((resolve, reject) => {
      AgentChats.getMacros(options)
        .then(({ data: macros }) => {
          resolve(macros);
        })
        .catch(reject);
    });
  },

  reassignAgentChatSession: (
    data: AgentChatServiceReassignChatSessionData,
    options?: any
  ) => {
    return new Promise((resolve, reject) => {
      AgentChats.reassignAgentChatSession(data, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  sendGroupMessage: (
    data: AgentChatServiceSendGroupMessageData,
    options?: any
  ) => {
    return new Promise((resolve, reject) => {
      AgentChats.sendGroupMessage(data, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  sendOutboundMessageToUsers: (
    data: AgentChatServiceSendOutboundMessageToUsersData,
    options?: any
  ) => {
    return new Promise((resolve, reject) => {
      AgentChats.sendOutboundMessageToUsers(data, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  sendAgentAction: (
    data: AgentChatServiceSendAgentActionData,
    options: any = {}
  ) => {
    return new Promise((resolve, reject) => {
      AgentChats.sendAgentAction(data, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  updateAssignedChatSessionLifecycle: (
    data: AgentChatServiceUpdateAssignedChatSessionLifecycleData,
    options: any = {}
  ) => {
    return new Promise((resolve, reject) => {
      AgentChats.updateAssignedChatSessionLifecycle(data, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  updateAvailability: (
    data: AgentChatServiceUpdateAvailabilityData,
    options: any = {}
  ) => {
    return new Promise((resolve, reject) => {
      const agentId = AgentService.userId;
      const formattedData = { agentId, ...data };

      AgentChats.updateAvailability(formattedData, options)
        .then(() => {
          resolve(true);
        })
        .catch(reject);
    });
  },

  pingStatusOnline: (
    agentId: string,
    options: any = {}
  ) =>
    AgentChats.pingStatusOnline(agentId, options),
};

export default AgentChatService;

