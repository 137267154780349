import React from "react"

import { LoggingService } from "@guardian/Services/Logging"

import ErrorView from "./ErrorView"

type ErrorBoundaryState = {
  error: Error | null
}

export type ErrorBoundaryProps = {
  domain: string
  method: string
  showDialog?: boolean
  children: React.ReactNode
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error) {
    const { domain, method, showDialog } = this.props
    LoggingService.logError(`${error.name}: ${error.message}`, {
      domain,
      method,
      consoleLog: true,
      dialog: !!showDialog,
      error,
      dialogOptions: {
        title: `${domain}#${method} Rendering Error`,
      },
      trackedData: {
        error,
      },
    })
  }

  render() {
    const { error } = this.state

    if (error) {
      return <ErrorView error={error} />
    }

    const { children } = this.props
    return children
  }
}
