import ModUpdateInputs from "@guardian/Components/ModUpdateInputs"
import styles from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/SOSIncidentPane.module.css"
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import titleize from "@guardian/Utils/titleize"
import _ from "lodash"
import { suggestionToLocation } from "@guardian/Utils/util"
import {
  ICreateIncidentLocation,
  ICreateIncidentSelectedTag,
  IProps,
} from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/components/CreateIncidentForm/types"

import { Signal } from "@guardian/API/Optimus";

// Copied over from a bad import from earlier code.
export async function geocodeValue(
  value: string
): Promise<any | null> {
  const { data: { suggestions } } = await Signal.getLocationSuggestions(value)
  if (!suggestions || suggestions.length === 0) {
    return {}
  }

  const { data: { candidates } } = await Signal.getGeocodeLocationSuggestions(
    suggestions[0].text,
    suggestions[0].magicKey,
  )

  const geocodeRes = candidates[0];

  // Convert to lat/lng pair for interoperability with other code
  geocodeRes.location.lat = geocodeRes.location.y
  geocodeRes.location.lng = geocodeRes.location.x

  return geocodeRes
}

export const CreateIncidentForm: FC<IProps> = ({
  bounds,
  address,
  onSubmit,
}) => {
  const [title, setTitle] = useState("Police Activity")
  const [update, setUpdate] = useState(
    "Police have received a report of a person in need of assistance.",
  )
  const [level, setLevel] = useState(0)
  const [autoFormatTitle, setAutoFormatTitle] = useState(true)
  const [dontNotify, setDontNotify] = useState(false)
  const [dontShowClips, setDontShowClips] = useState(false)
  const [tags, setTags] = useState([])
  const [location, setLocation] = useState<ICreateIncidentLocation>({
    location: "",
    address: "",
    police: "",
    cityCode: "",
  })
  const [selectedTag, setSelectedTag] = useState<ICreateIncidentSelectedTag>()
  const [citywideGeoCode, setCitywideGeoCode] = useState<string>("")

  const loadAddress = useCallback(
    async (address: any) => {
      const geo = await geocodeValue(address)
      onChooseLocation(geo)
    },
    [],
  )

  useEffect(() => {
    if (address === "") {
      return
    }

    loadAddress(address)
  }, [address])

  const getTags = async () => {
    const { data: { results: tags }} = await Signal.getTags()
    setTags(tags)
    autoSuggestTag(title)
  }

  useEffect(() => {
    getTags()
  }, [])

  const autoSuggestTag = async (title: string) => {
    const { data: { results } } =  await Signal.getAutoSuggestTags(title);

    if (results.length) {
      setSelectedTag(results[0])
    }
  }

  const onChooseLocation = async (geolocation: any) => {
    if (!_.isEmpty(geolocation)) {
      const location = await suggestionToLocation(geolocation)
      setLocation(location)
    }
  }

  const handleOnBlurTitle = async () => {
    await autoSuggestTag(title)
    setTitle(autoFormatTitle ? titleize(title) : title)
  }

  const handleChangeTitle = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setTitle(value)
  }

  const handleChangeUpdate = ({
    target: { name, value },
  }: {
    target: { name: string; value: string }
  }) => {
    if (name === "update") {
      setUpdate(value)
    }
  }

  const handleChangeLevel = (delta: number) => {
    setLevel(Math.max(0, Math.min(1, level + delta)))
  }

  const handleChangeDisplayLocation = ({
    target: { value },
  }: {
    target: { value: string }
  }) => {
    setLocation({
      ...location,
      location: value,
    })
  }

  const handleChangeTag = (value: string) => {
    const index = tags.findIndex((t: any) => {
      return t.display_name === value
    })
    setSelectedTag(index === -1 ? undefined : tags[index])
  }

  const handleAutoFormatTitleChange = (value: boolean) => {
    setAutoFormatTitle(value)
  }

  const handleDontNotifyChange = (value: boolean) => {
    setDontNotify(value)
  }

  const handleDontShowClipsChange = (value: boolean) => {
    setDontShowClips(value)
  }

  const handleChangeVoid = () => {}

  const handleOnSubmit = () => {
    onSubmit(level, dontNotify, dontShowClips, title, location, update, selectedTag)
  }

  const handleChangeServiceArea = (area: {name: string, value: string}) => {
    setCitywideGeoCode(area.value)
  }

  return (
    <>
      <ModUpdateInputs
        title={title}
        location={location}
        update={update}
        level={level}
        newsLevel={null}
        onChooseLocation={onChooseLocation}
        onChangeTitle={handleChangeTitle}
        onChangeUpdate={handleChangeUpdate}
        onChangeLevel={handleChangeLevel}
        onChangeDisplayLocation={handleChangeDisplayLocation}
        onChangePolice={handleChangeVoid}
        onBlurTitle={handleOnBlurTitle}
        onBlurUpdate={handleChangeVoid}
        onSubmit={handleOnSubmit}
        newIncident
        clipsLength={0}
        mapInit={true}
        autoFocus={false}
        bounds={bounds}
        tags={tags}
        tag={selectedTag?.display_name}
        onChangeTag={handleChangeTag}
        onAutoFormatTitleChange={handleAutoFormatTitleChange}
        autoFormatTitle={autoFormatTitle}
        dontNotify={dontNotify}
        onChangeDontNotify={handleDontNotifyChange}
        dontShowClips={dontShowClips}
        onChangeDontShowClips={handleDontShowClipsChange}
        onChangeServiceArea={handleChangeServiceArea}
        citywideGeoCode={citywideGeoCode}
        canCitywide={false}
      />
      <button className={styles.submit} onClick={handleOnSubmit}>
        Create Incident
      </button>
    </>
  )
}
