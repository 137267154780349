import _sortBy from "lodash/sortBy"

export const getSubAreas = (serviceAreas, subAreaMapping) => {
  const subAreas = {}

  serviceAreas.forEach(sa => {
    const subareas = subAreaMapping[sa] || {}
    Object.keys(subareas).forEach(subarea => {
      const departments = subareas[subarea]

      if (!subAreas[subarea]) {
        subAreas[subarea] = {}
      }

      Object.keys(departments).forEach(department => {
        const currChannels = subAreas[subarea][department] || []

        subAreas[subarea][department] = currChannels.concat(
          departments[department],
        )
      })
    })
  })

  return subAreas
}

export const sortSubAreas = (subAreas, subAreaSequence) => {
  return _sortBy(subAreas, s => {
    const pos = subAreaSequence.indexOf(s)
    return pos > -1 ? pos : 0
  })
}
