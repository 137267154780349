import React, { useState, useEffect } from "react"
import style from "./Dropdown.module.css"
import { string, array, func, bool } from "prop-types"
import classnames from "classnames"

import combokeys from "@guardian/Utils/hotkeys"

const Dropdown = ({
  placeholder,
  options,
  onChange,
  value,
  autoOpen,
  hideArrow,
  className,
  open: dropdownOpen,
  onOpenToggle,
  disableHotkeys,
}) => {
  const [open, setOpen] = useState(autoOpen)
  useEffect(() => {
    if (!disableHotkeys) {
      options.forEach((option, i) => {
        combokeys.bind(`${i + 1}`, () => {
          onChange(option)
        })
      })
    }

    return () => {
      if (!disableHotkeys) {
        options.forEach((option, i) => {
          combokeys.unbind(`${i + 1}`)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classnames(style.dropdown, className)}>
      <button
        className={style.input}
        onClick={() => {
          if (onOpenToggle) {
            onOpenToggle()
          } else {
            setOpen(!open)
          }
        }}
      >
        <input
          type='text'
          readOnly
          placeholder={placeholder}
          value={value}
          onMouseDown={e => e.preventDefault()}
          style={{ border: "none" }}
        />
        {!hideArrow && (
          <div className={style.arrow}>
            <i className='fas fa-sort-down' />
          </div>
        )}
      </button>
      <div
        className={classnames(style.options, {
          [style.open]: dropdownOpen !== undefined ? dropdownOpen : open,
        })}
      >
        {options.map((option, i) => {
          return (
            <button
              className={style.option}
              key={option}
              onClick={() => {
                onChange(option)
                setOpen(false)
              }}
            >
              {option} {disableHotkeys ? "" : `(${i + 1})`}
            </button>
          )
        })}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  placeholder: string,
  options: array,
  onChange: func,
  value: string,
  autoOpen: bool,
  hideArrow: bool,
  className: string,
  open: bool,
  onOpenToggle: func,
  disableHotkeys: bool,
}

export default Dropdown
