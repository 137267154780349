// Import Sentry instrumentation before anything else
import "./instrument"

import { createRoot } from 'react-dom/client'

import App from '@guardian/Layouts/App'

import { unregisterServiceWorker } from './registerServiceWorker'

unregisterServiceWorker()

const render = Component => {
  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(<Component />)
}

// Render the application.
render(App)
