import { NavLink } from "react-router-dom";
import styled from "styled-components";

import NavigationItem from "./NavigationItem";

const NavigationLink = styled(NavLink)`
  display: inline-block;
  height: 100%;

  &.active ${ NavigationItem } {
    background-color: var(--colors-blue-090);
    box-shadow: var(--borders-highlight-bottom) var(--colors-blue-040);

    &.navigation-item--warn {
      background-color: var(--colors-red-090);
      box-shadow: var(--borders-highlight-bottom) var(--colors-red-040);
    }
  }
`;

export default NavigationLink;
