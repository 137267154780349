import classNames from "classnames";
import styled from "styled-components";

import { Col } from "@guardian/UI/Grid"

const Loading = styled(Col).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__loading"
    )
  };
})`
  align-items: center;
  background: var(--colors-black);
  color: var(--colors-grey-070);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

Loading.defaultProps = {};

export default Loading;
