import React, { useRef } from "react"
import classnames from "classnames"
import { string, func, bool } from "prop-types"
import style from "./Input.module.css"

const TextAreaInput = ({
  label,
  onChange,
  placeholder,
  defaultValue,
  disabled,
  onEnter,
}) => {
  const input = useRef()
  return (
    <div className={classnames(style.textarea, style.input)}>
      {label && <h5 className={style.label}>{label}</h5>}
      <textarea
        ref={input}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        onKeyDown={e => {
          switch (e.key) {
            case "Escape":
              if (input.current) {
                input.current.blur()
              }
              break
            case "Enter":
              if (onEnter) {
                e.preventDefault()
                onEnter()
              }
              break
            default:
              break
          }
        }}
      />
    </div>
  )
}

TextAreaInput.propTypes = {
  label: string,
  onChange: func,
  placeholder: string,
  defaultValue: string,
  disabled: bool,
  onEnter: func,
}

export default TextAreaInput
