import React, { useState, FC } from "react"
import classnames from "classnames"
import Container from "@guardian/Components/Common/Container"
import { timer } from "rxjs"
import { sendCallbackToUserID } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import styles from "@guardian/Components/ModSOS/components/GeneralActionPane/SOSGeneralAddCallPane/SOSGeneralAddCallPane.module.css"

// TODO: create shared error type
type Error = any

export const SOSGeneralAddCallPane: FC = React.memo(() => {
  const [userID, setUserID] = useState("")
  const [triggeredCall, setTriggeredCall] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleTriggerCall = async () => {
    setTriggeredCall(true)
    setErrorMessage("")

    try {
      await Promise.all([sendCallbackToUserID(userID), timer(5000).toPromise()])

      setTriggeredCall(false)
      setUserID("")
    } catch (error: Error) {
      setTriggeredCall(false)
      setErrorMessage(error.message)
    }
  }

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault()
        await handleTriggerCall()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUserID(event.currentTarget.value)

  return (
    <Container
      top={4}
      bottom={4}
      right={4}
      left={4}
      fullHeight
      flex
      column
      leftJustify
    >
      <h5 className={styles.label}>User ID</h5>
      <div
        className={classnames(styles.input, {
          [styles.disabled]: triggeredCall,
        })}
      >
        <input
          type='text'
          value={userID}
          disabled={triggeredCall}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <button
          className={classnames(styles.buttonDefault, styles.callButton)}
          disabled={triggeredCall}
          onClick={handleTriggerCall}
        >
          Send notification
        </button>
      </div>
      {triggeredCall && (
        <div className={styles.header}>Call Notification Sent</div>
      )}
      {errorMessage && <div className={styles.header}>{errorMessage}</div>}
    </Container>
  )
})
