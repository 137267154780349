import React from "react";

import StyledButtonIcon from "../views/ButtonIcon";
import StyledIconButton from "../views/IconButton";

const IconButton = React.forwardRef((props, ref) => {
  const { children, ...buttonProps } = props;

  return (
    <StyledIconButton ref={ref} { ...buttonProps }>
      <StyledButtonIcon>
        { children }
      </StyledButtonIcon>
    </StyledIconButton>
  );
});

IconButton.propTypes = {
  ...StyledIconButton.propTypes
};

IconButton.defaultProps = {
  ...StyledIconButton.defaultProps,
  pill: true
};

export default IconButton;
