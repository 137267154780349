import classnames from "classnames"
import dayjs from "dayjs"
import _ from "lodash"
import { bool, func, object, string } from "prop-types"
import React, { useEffect, useState } from "react"

import consts from "@guardian/Constants"
import { useGlobalState } from "@guardian/State"

import { GNet } from "@guardian/API/Optimus"
import { dateToTimezone, dateToUTC } from "@guardian/Utils/util"

import "./IncidentNotif.scss"

const IncidentNotif = ({
	notif: {
		incident_id,
		created_at,
		transcriber,
		title,
		message,
		ctr,
		sound,
		users_targeted,
		users_received,
		user_radius,
		targeting_type,
		user_label,
		dry_run,
		geography_code,
		geography_name,
		geography_type,
		cohort_begin,
		cohort_end,
		tracking,
		trigger_type,
		notif_sentiment,
		min_radius,
		magic_sauce_radius,
	},
	user,
	onCollapse,
	collapseText,
	expandNotifs,
	inLiveTab,
	timezone,
}) => {
	const { geographyPushesConfig, isMagicSauceTester } = useGlobalState(
		state => {
			const {
				geographyPushesConfig,
				permissions: { isMagicSauceTester },
			} = state.global
			return { geographyPushesConfig, isMagicSauceTester }
		},
	)

	const [expanded, setExpanded] = useState(true)
	const [geoName, setGeoName] = useState("")
	const [cohortLabel, setCohortLabel] = useState("")
	const [cohortStyle, setCohortStyle] = useState({})
	const [stages, setStages] = useState([])
	const [reported, setReported] = useState(false)

	// apparently it's only available on geography for now
	const isTrackingEnabled =
		targeting_type === "Geography" &&
		_.get(user, "features.notifTracking.value", false)

	useEffect(() => {
		const geoValue = [geography_type, geography_name, geography_code]
			.filter(v => v)
			.join(":")
		const val = geographyPushesConfig?.find(target => target.value === geoValue)
		if (val) {
			setGeoName(val.name)
		}
	}, [geography_type, geography_name, geography_code])

	useEffect(() => {
		if (!tracking) return
		tracking.forEach(t => {
			t.percentage = t.percentage >= 95 ? 100 : t.percentage
		})
		if (!reported) {
			if (tracking[2].percentage === 0) {
				if (dayjs().diff(dayjs(tracking[0].createdAt), "minute") > 5) {
					setReported(true)
					GNet.sendMetrics({
						component: "Incident Notification",
						label: "Notification Delivery Delayed",
						value: "5+ minutes",
					})
				}
			}
		}
		setStages(tracking)
	}, [tracking])

	useEffect(() => {
		const cohortBegin = cohort_begin || 0
		const cohortEnd = cohort_end || 0

		if (cohortEnd > 0 && cohortBegin < cohortEnd) {
			// TODO: when we give ability to set custom cohort, remove label a / b
			if (cohortBegin === 0 && cohortEnd === 50) {
				setCohortLabel("A")
				setCohortStyle({ "cohort-A": true })
			} else if (cohortBegin === 50 && cohortEnd === 100) {
				setCohortLabel("B")
				setCohortStyle({ "cohort-B": true })
			} else {
				setCohortLabel(`[${cohortBegin}, ${cohortEnd})`)
			}
		}
	}, [cohort_begin, cohort_end])

	useEffect(() => {
		setExpanded(expandNotifs)
	}, [expandNotifs])

	const utcTime = dateToUTC(created_at * 1000).format("MM/DD • HH:mm")
	let localTime = ""

	if (timezone) {
		localTime = dateToTimezone(created_at * 1000, timezone).format(" (HH:mm z)")
	}

	let msRadius = null
	if (isMagicSauceTester && targeting_type === "MagicSauce") {
		const minRadiusMiles = min_radius ? Math.round((min_radius * 100) / consts.MileToMeter) / 100 : null
		const msRadiusMiles = magic_sauce_radius ? Math.round((magic_sauce_radius * 100) / consts.MileToMeter) / 100 : null

		if (minRadiusMiles && msRadiusMiles) {
			msRadius = <div>Radius: [{minRadiusMiles} - {msRadiusMiles}] Miles</div>
		} else if (minRadiusMiles) {
			msRadius = <div>Min Radius: {minRadiusMiles} Miles</div>
		} else if (msRadiusMiles) {
      msRadius = <div>Radius: {msRadiusMiles} Miles</div>
    }
	}

	const { name, username } = transcriber || {};

	return (
		<li className={`connected-li update incident-notif ${inLiveTab ? "inLiveTab" : ""}`}>
			<div className='update-header'>
				<div onClick={() => !inLiveTab && setExpanded(!expanded)}>
					{[
						utcTime + localTime,
						// Split into two conditions so we are not nesting ternary operators
						// Following two lines are mutually exclusive
						name && username && `${name} (@${username})`,
						!name && username && `@${username}`,
						!inLiveTab && (expanded ? '(Click to hide)' : '(Click to expand)')
					].filter(Boolean).join(' • ')}
				</div>
				{onCollapse && !inLiveTab && (
					<button onClick={onCollapse}>{collapseText || "+"}</button>
				)}
			</div>
			{expanded && (
				<>
					<div className={classnames("notif-preview", { "dry-run": dry_run })}>
						<div className='header placed-apart'>
							<div className='notif-logo'>
								<img src='/android-icon-192x192.png' alt='citizen-logo' />
								CITIZEN{dry_run ? " [TEST]" : ""}
							</div>
							<div className='header-rhs'>
								<i className={`fas fa-volume-${sound ? "up" : "off"}`} />
							</div>
						</div>
						<div className='notif-title'>{title}</div>
						<div className='notif-body'>{message}</div>
					</div>
					<div className='stats'>
						<div>
							{!!cohortLabel && (
								<>
									<div className={classnames("cohort-base", cohortStyle)}>
										{cohortLabel}
									</div>
									{"• "}
								</>
							)}
							{users_received} Users notified
						</div>
						{trigger_type === "auto_expand" && <div>Auto Expanded</div>}
						<div>Targeting: {targeting_type}</div>
						{targeting_type === "Geography" && <div>{geoName}</div>}
						{user_radius && (
							<div>
								{Math.round((user_radius / consts.MileToMeter) * 100) / 100} Miles
							</div>
						)}
						{msRadius}
						{targeting_type === "User Label" && (
							<div>User Label: {user_label}</div>
						)}
						{notif_sentiment && (
							<div>Notif Sentiment: {_.startCase(notif_sentiment)}</div>
						)}
						{isTrackingEnabled && stages.length > 0 && (
							<div className='notif-tracking'>
								{_.map(stages, ({ stage, percentage }) => (
									<div
										key={stage}
										className={classnames({
											start: percentage > 0 && percentage < 100,
											end: percentage === 100,
										})}
									>
										{stage} ({Math.round(percentage)}%)
									</div>
								))}
							</div>
						)}
						<div>CTR: {Math.round(ctr * 10000) / 100} %</div>
					</div>
				</>
			)}
		</li>
	)
}

IncidentNotif.propTypes = {
	notif: object,
	onCollapse: func,
	collapseText: string,
	expandNotifs: bool,
	inLiveTab: bool,
	timezone: string,
}

export default IncidentNotif
