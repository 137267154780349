import React, { FC, useState } from "react"
import { IProps, IMapsApi } from "@guardian/UI/Map/types"
import GoogleMapReact from "google-map-react"
import { Container } from "@guardian/UI/Map/styled"
import { Route } from "@guardian/UI/Map/components/Route"
import { IncidentMarker } from "@guardian/UI/Map/components/IncidentMarker"
import { UserMarker } from "@guardian/UI/Map/components/UserMarker"
import { style } from "@guardian/UI/Map/map-style"
import { PointOfInterestMarker } from "@guardian/UI/Map/components/PointOfInterestMarker"

const MAP_API_KEY = import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

export const Map: FC<IProps> = ({
  defaultCenter,
  defaultZoom,
  zoom,
  draggable,
  allowControls,
  streetView,
  routeData,
  onBoundsChange,
  incidents,
  pointOfInterests,
}) => {
  const userPosition = routeData ? routeData[0] : undefined
  const center = userPosition || defaultCenter

  const [mapsApi, setMapsApi] = useState<IMapsApi>()

  const handleOnGoogleApiLoaded = (mapsApi: IMapsApi) => setMapsApi(mapsApi)

  const handleOnDrag = () => {
    if (onBoundsChange && mapsApi) {
      onBoundsChange(
        mapsApi.map.getBounds(),
        mapsApi.map.getCenter(),
        mapsApi.map.getZoom(),
      )
    }
  }

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: MAP_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        zoom={zoom}
        center={center}
        options={{
          disableDefaultUI: !allowControls,
          streetViewControl: streetView,
          styles: style,
        }}
        draggable={draggable}
        onGoogleApiLoaded={handleOnGoogleApiLoaded}
        onDrag={handleOnDrag}
        yesIWantToUseGoogleMapApiInternals
      >
        {incidents?.map(marker => (
          <IncidentMarker
            incidentId={marker.incidentId}
            lat={marker.latitude}
            lng={marker.longitude}
            coverage={marker.level}
            title={marker.title}
          />
        ))}
        {pointOfInterests?.map(marker => (
          <PointOfInterestMarker
            address={marker.address}
            name={marker.name}
            lat={marker.latitude}
            lng={marker.longitude}
          />
        ))}
        {center && <UserMarker lat={center.lat} lng={center.lng} />}
        <Route routeData={routeData} mapsApi={mapsApi} />
      </GoogleMapReact>
    </Container>
  )
}
