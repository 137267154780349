import classNames from "classnames"
import styled from "styled-components"

export const BadgeContainer = styled.div
  .withConfig({
    shouldForwardProp: prop => !["bgColor", "textColor", "pill"].includes(prop),
  })
  .attrs(({ pill }) => ({
    className: classNames("badge", { "badge--pill": pill }),
  }))`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: var(--space-xx-small);
  padding: var(--space-xx-small);
  border-radius: var(--radii-small);
  font-family: var(--fonts-sans-serif);
  font-weight: var(--font-weights-bold);
  font-size: var(--font-sizes-x-small);

  line-height: 1;
  text-transform: uppercase;
  text-wrap: nowrap;
  background: ${({ bgColor }) => bgColor || "var(--colors-grey-020)"};
  color: ${({ textColor }) => textColor || "var(--colors-white)"};

  &.badge--pill {
    border-radius: var(--radii-pill);
    padding: var(--space-xx-small) var(--space-x-small);
  }
`

export const LiveIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--colors-white);
`
