import styled from "styled-components"

export const Label = styled.div`
  background: #000000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  white-space: nowrap;
  font-family: -apple-system, -BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.08px;
  color: #ffffff;
  position: absolute;
  padding: 8px;
  transform: translateY(calc(-100% - 12px));
  left: 13px;
  backdrop-filter: blur(75px);

  display: none;
`

export const Container = styled.div`
  position: relative;

  &:hover {
    ${Label} {
      display: block;
    }
  }
`

// TODO: remove this shouldn't be necessary
interface ISignalProps {
  health?: number
}

export const Signal = styled.div<ISignalProps>`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background: white;

  ${(props: ISignalProps) =>
    props.health === 0 &&
    `
    background: #ed2323;
    box-shadow: 0px 0px 1px rgba(79, 59, 0, 0.1), 0px 0px 15px #ed2323;
  `}
  
  ${(props: ISignalProps) =>
    props.health === 1 &&
    `
    background: #ff880a;
    box-shadow: 0px 0px 1px rgba(79, 59, 0, 0.1), 0px 0px 15px #ff880a;
  `}
  
  ${(props: ISignalProps) =>
    props.health === 2 &&
    `
    background: #ffd52b;
    box-shadow: 0px 0px 1px rgba(79, 59, 0, 0.1), 0px 0px 15px #ffd52b;
  `}
  
  ${(props: ISignalProps) =>
    props.health === 3 &&
    `
    background: #10cf3b;
    box-shadow: 0px 0px 1px rgba(79, 59, 0, 0.1), 0px 0px 15px #10cf3b;
  `}
`
