import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useObservable } from "rxjs-hooks"
import style from "./DeviceConfig.module.css"
import { timer, from } from "rxjs"
import { map } from "rxjs/operators"
import NetworkStatus from "./NetworkStatus"
import DeviceStatus from "./DeviceStatus"
import ClipList from "./ClipList/ClipList"
import { useGlobalState, eventHub } from "@guardian/State"

import { Device } from "@guardian/API/Megatron";

const DeviceConfig = () => {
  const { id: deviceId } = useParams();

  const channels = useGlobalState((state) => state.radio.channels)

  const [clips, setClips] = useState([])
  const [network, setNetwork] = useState({})
  const [status, setStatus] = useState({})
  useObservable(() =>
    timer(0, 5000).pipe(
      map(() => {
        return from(
          Device.getDeviceClips(deviceId)).subscribe(({ data: { clips } }) => {
            setClips(clips)
          }
        )
      }),
    ),
  )

  useObservable(() =>
    timer(0, 5000).pipe(
      map(() => {
        return from(
          Device.getNetworkStatus(deviceId)).subscribe(({ data: network }) => {
            setNetwork(network)
          }
        )
      }),
    ),
  )

  useObservable(() =>
    timer(0, 5000).pipe(
      map(() => {
        return from(
          Device.getDeviceStatus(deviceId)).subscribe(({ data: status }) => {
            setStatus(status)
          }
        )
      }),
    ),
  )

  useEffect(() => {
    eventHub.emit("updateChannels")
  }, [])

  return (
    <div className={style.container}>
      <ClipList className={style.pane} clips={clips} channels={channels} />
      <NetworkStatus
        className={style.pane}
        name={network.name}
        frequency={network.frequency}
        wifiStrength={network.wifiStrength}
        connectionType={network.connectionType}
        healthcheck={status.lastPing}
        limeSdrStatus={network.limeSdrStatus}
      />
      <DeviceStatus
        className={style.pane}
        sites={status.sites}
        lastClip={status.lastClip}
      />
    </div>
  )
}

export default React.memo(DeviceConfig)
