import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    "logo title info"
    "logo body  info";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  gap: 0 8px;
  align-self: normal;
  border-radius: 13px;
  padding: 10px;
  padding-bottom: 0px;
  margin: 8px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4)
  );

  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
`

export const Logo = styled.div`
  grid-area: logo;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 10px;

  img {
    width: 32px;
    height: 32px;
    background-color: black;
    border-radius: 7px;
  }
`

export const Title = styled.input`
  grid-area: title;
  font-weight: bold;

  &&& {
    height: auto;
    background-color: transparent;
    color: black;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    border: 0;
  }
`

export const Body = styled.textarea`
  grid-area: body;
  height: calc(3lh + 10px);
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  resize: none;
  width: 100%;
  padding: 0;
  border: 0;
  outline: none;
  color: black;
`

export const Info = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
`

export const NotifTime = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  color: #323232;
  font-size: 11px;

  svg {
    fill: black;
    height: 14px;
    width: 14px;
  }
`

export const NotifIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 5px;
  width: 28px;
  height: 28px;

  img {
    width: 20px;
    height: 20px;
  }
`

export const CharCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
  margin: 8px 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #8c8c8c;

  > .over-limit {
    color: red;
  }
`
