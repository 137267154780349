import PropTypes from "prop-types";
import classNames from "classnames";
import styled, { css } from "styled-components";

const VALIDATION_TYPES = {
  ERROR: "error",
  GENERIC: "generic",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning"
};

const FauxInput = styled.div.attrs(
  props => {
    const {
      className,
      active,
      disabled,
      focused,
      forcePlaceholder,
      hovered,
      placeholder,
      validation
    } = props;

    return {
      "aria-disabled": disabled,
      className: classNames(className, "form__faux-input", {
        "form__faux-input--is-active": active,
        "form__faux-input--is-disabled": disabled,
        "form__faux-input--is-focused": focused,
        "form__faux-input--is-hovered": hovered,
        "form__faux-input--force-placeholder": forcePlaceholder,

        "form__faux-input--validation-error":
          validation == VALIDATION_TYPES.ERROR,
        "form__faux-input--validation-generic":
          validation == VALIDATION_TYPES.GENERIC,
        "form__faux-input--validation-info":
          validation == VALIDATION_TYPES.INFO,
        "form__faux-input--validation-success":
          validation == VALIDATION_TYPES.SUCCESS,
        "form__faux-input--validation-warning":
          validation == VALIDATION_TYPES.WARNING,
      }),
      "data-placeholder": placeholder
    };
  }
)`
  ${({ theme: { borders, colors }}) => css`
    align-items: center;
    background: var(--colors-grey-095);
    border-radius: var(--radii-large);
    color: var(--colors-white);
    display: flex;
    font-size: var(--font-sizes-x-small);
    height: 40px;
    line-height: var(--line-heights-x-small);
    outline: none;
    position: relative;
    transition:
      background 100ms cubic-bezier(0.39, 0.575, 0.565, 1),
      color 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 100%;

    &::after {
      bottom: 0;
      border: none;
      border-radius: var(--radii-large);
      box-shadow:
        0px 0px 0px transparent,
        ${ borders.full } ${ colors.grey070 };
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition:
        box-shadow 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    // Provide a faux placeholder when element is empty.
    &:empty:before, &.form__faux-input--force-placeholder:before {
      align-items: center;
      content: attr(data-placeholder);
      color: var(--colors-grey-030);
      display: flex;
      font-family: var(--fonts-sans-serif);
      font-size: var(--fonts-size-medium);
      height: 100%;
      line-height: var(--lines-heights-medium);
      padding: 0 var(--space-small);
    }

    &:active, &.form__faux-input--is-active,
    &:hover, &.form__faux-input--is-hovered
    {
      &::after {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.grey030 };
      }
    }

    &:focus, &.form__faux-input--is-focused
    {
      &::after {
        box-shadow:
          ${ borders.outer.highlight.full } ${ colors.grey070 },
          ${ borders.full } ${ colors.grey030 };
      }
    }


    &.form__faux-input--validation-error {
      &::after {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.red040 };
      }

      &:active, &.form__faux-input--is-active,
      &:hover, &.form__faux-input--is-hovered
      {
        &::after {
          box-shadow:
            0px 0px 0px transparent,
            ${ borders.full } ${ colors.red030 };
        }
      }

      &:focus, &.form__faux-input--is-focused
      {
        &::after {
          box-shadow:
            ${ borders.outer.highlight.full } ${ colors.red040 },
            ${ borders.full } ${ colors.red030 };
        }
      }
    }

    &.form__faux-input--validation-info {
      &::after {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.blue040 };
      }

      &:active, &.form__faux-input--is-active,
      &:hover, &.form__faux-input--is-hovered
      {
        &::after {
          box-shadow:
            0px 0px 0px transparent,
            ${ borders.full } ${ colors.blue030 };
        }
      }

      &:focus, &.form__faux-input--is-focused
      {
        &::after {
          box-shadow:
            ${ borders.outer.highlight.full } ${ colors.blue040 },
            ${ borders.full } ${ colors.blue030 };
        }
      }
    }

    &.form__faux-input--validation-success {
      &::after {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.green040 };
      }

      &:active, &.form__faux-input--is-active,
      &:hover, &.form__faux-input--is-hovered
      {
        &::after {
          box-shadow:
            0px 0px 0px transparent,
            ${ borders.full } ${ colors.green030 };
        }
      }

      &:focus, &.form__faux-input--is-focused
      {
        &::after {
          box-shadow:
            ${ borders.outer.highlight.full } ${ colors.green040 },
            ${ borders.full } ${ colors.green030 };
        }
      }
    }

    &.form__faux-input--validation-warning {
      &::after {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.yellow040 };
      }

      &:active, &.form__faux-input--is-active,
      &:hover, &.form__faux-input--is-hovered
      {
        &::after {
          box-shadow:
            0px 0px 0px transparent,
            ${ borders.full } ${ colors.yellow030 };
        }
      }

      &:focus, &.form__faux-input--is-focused
      {
        &::after {
          box-shadow:
            ${ borders.outer.highlight.full } ${ colors.yellow040 },
            ${ borders.full } ${ colors.yellow030 };
        }
      }
    }

    // Give disabled styling priority.
    && {
      &.form__faux-input--is-disabled {
        background: var(--colors-grey-090);
        color: var(--colors-grey-070);
        cursor: default;

        &::after {
          box-shadow:
            0px 0px 0px transparent,
            ${ borders.full } ${ colors.grey040 };
        }
      }
    }
  `}
`;

FauxInput.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool,
  placeholder: PropTypes.string,
  validation: PropTypes.oneOf(
    [
      VALIDATION_TYPES.ERROR,
      VALIDATION_TYPES.GENERIC,
      VALIDATION_TYPES.INFO,
      VALIDATION_TYPES.SUCCESS,
      VALIDATION_TYPES.WARNING
    ]
  )
};

FauxInput.defaultProps = {
  active: false,
  disabled: false,
  focused: false,
  hovered: false,
  placeholder: "",
  validation: VALIDATION_TYPES.GENERIC
};

export default FauxInput;
