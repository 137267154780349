import { Signal } from "@guardian/API/Optimus";

const GeocodingService = {
  getFeatureLayers: (options: any = {}) => {
    return new Promise((resolve, reject) => {
      Signal.getFeatureLayers(options)
        .then(({ data: { layers } }) => {
          resolve(layers);
        })
        .catch(reject);
    });
  },

  queryFeatureLayer: (layerId: string, query: string, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Signal.queryFeatureLayer(layerId, query, options)
        .then(({ data: { features } }) => {
          resolve(features);
        })
        .catch(reject);
    });
  },

  reverseGeocode: (lat: number, long: number, options: any = {}) => {
    return new Promise((resolve, reject) => {
      Signal.reverseGeocode(lat, long, options)
        .then(({ data: { address } }) => {
          resolve(address);
        })
        .catch(reject);
    });
  },

  emergencyGeocode: (
    address: string,
    lat: number,
    long: number,
    options: any = {}
  ) => {
    return new Promise((resolve, reject) => {
      Signal.emergencyGeocode(address, lat, long, options)
        .then(({ data: { candidates } }) => {
          resolve(candidates);
        })
        .catch(reject);
    });
  }
};

export default GeocodingService;
