import { useEffect, useRef, useState } from "react";

import { webSockets } from "@guardian/Sockets";
import { AgentCalls } from "@guardian/API/Optimus";
import { LoggingService } from "@guardian/Services/Logging";

interface QueryOptions {
  enabled: string
};

const useSubscribeSOSFeedCount = (options: QueryOptions) => {
  const {
    enabled
  } = options;

  const [newUnreadMessageCount, setNewUnreadMessageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [ids, setIds] = useState(new Set());

  const idsRef = useRef(ids);

  useEffect(() => {
    setCount(ids.size);
    idsRef.current = ids;
  }, [ids]);

  useEffect(() => {
    // NOTE: We want to treat our hook as enabled by default. Hence, the lack of
    // an enabled value in our hooks options should be considered a go-ahead to
    // treat it as enabled. Hence, we only treat it as not enabled if the value
    // of enabled is falsy _and_ defined.
    if (typeof enabled !== "undefined" && !enabled) { return; }

    const controller = new AbortController();
    const signal = controller.signal;

    const subscription = {
      method: "subscribeSOSFeed"
    };

    const handleNewMessage = ({ payload: { value } }: { payload: { value: any } }) => {
      const { status, id } = value;

      const ids = new Set(idsRef.current);

      switch (status) {
        case "active":
          if(!ids.has(id)) {
            setNewUnreadMessageCount(count => count + 1);
          }
          ids.add(id);
          break;
        case "disabled":
          ids.delete(id);
          break;
        default:
      }

      setIds(ids);
    };

    const listenForNewRecords = () => {
      webSockets.sub(subscription);
      webSockets.onEvent("sosFeedEvent", handleNewMessage);
    };

    const fetchInitialRecords = () => {
      return new Promise((resolve, reject) => {
        AgentCalls.getActiveSessions({ signal })
          .then(({ data }: { data: any[] }) => {
            const sessions = data || [];
            const ids = new Set(idsRef.current);
            sessions.forEach((session: any) => {
              ids.add(session.id);
            });
            setIds(ids);
            resolve(true);
          })
          .catch(reject)
      });
    };

    const initialize = () => {
      fetchInitialRecords()
        .then(listenForNewRecords)
        .catch(error => {
          if (signal.aborted) {
            return;
          }

          LoggingService.logError(
            `An error occured fetching Protect feed count!"`,
            {
              domain: "NavigationBar",
              method: "useSubscribeSOSFeedCount",
              flash: true,
              trackedData: {
                error: error
              }
            }
          );

        });
    };

    initialize();

    return () => {
      controller.abort();

      webSockets.removeEvent("sosFeedEvent", handleNewMessage);
      webSockets.removeSub(subscription, subscription);

      // TODO: This is legacy code. Unsure if necessary.
      webSockets.close();
    };
  }, [enabled]);

  return [
    count,
    newUnreadMessageCount
  ];
};

export default useSubscribeSOSFeedCount;
