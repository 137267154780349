import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import StyledTooltip from "../views/Tooltip";
import TooltipWrapper from "../views/TooltipWrapper";

const TooltipContent = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    placement,
    style,
    zIndex
  } = props;

  const [entered, setEntered] = useState(open);
  const [mounted, setMounted] = useState(open);

  const lastOpenStateRef = useRef();

  useEffect(() => {
    const lastOpenState = lastOpenStateRef.current;

    let toggleTimeout;

    const closeTooltip = () => {
      setEntered(false);

      toggleTimeout = setTimeout(() => {
        setMounted(false);
      }, TooltipContent.TRANSITION_MS);
    };

    const openTooltip = () => {
      setEntered(true);
      setMounted(true);
    };

    if (lastOpenState !== open) {
      open ? openTooltip() : closeTooltip();
    }

    lastOpenStateRef.current = open;

    return () => {
      clearTimeout(toggleTimeout);
    };
  }, [open]);

  return (
    <TooltipWrapper
      ref={ref}
      placement={placement}
      style={style}
      zIndex={zIndex}
    >
      <StyledTooltip
        entered={entered}
        placement={placement}
        transitionMs={TooltipContent.TRANSITION_MS}
      >
        { children }
      </StyledTooltip>
    </TooltipWrapper>
  );
});

TooltipContent.TRANSITION_MS = 150;

export default TooltipContent;
