import React, { useCallback, useState } from "react"
import { array, object, func, bool } from "prop-types"

import { latestRev } from "@guardian/Utils/incident"
import { ContentService } from "@guardian/Services/Content"

import P2PMergeIncidentModal from "@guardian/Components/VideoMod/P2PMergeIncidentModal"
import P2PNearbyIncident from "@guardian/Components/VideoMod/P2PNearbyIncident"

import NearbyIncidentPreview from "./NearbyIncidentPreview"
import style from "./styles.module.css"

const NearbyIncidentList = ({
  nearbyIncidents,
  mergingIncident,
  setMerging,
  disabled,
  currentIncident,
  refreshVideoStreams,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [mergingP2PIncidentId, setMergingP2PIncidentId] = useState(null)

  const handleP2PMerge = useCallback(async (parentIncidentId) => {
    setMergingP2PIncidentId(parentIncidentId)

    try {
      await ContentService.mergeP2PContent(currentIncident.preferredStreamId, {
        childIncidentId: currentIncident.id,
        parentIncidentId,
      })
      if (refreshVideoStreams && typeof refreshVideoStreams === 'function') {
        await refreshVideoStreams()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setMergingP2PIncidentId(null)
    }
  }, [currentIncident, refreshVideoStreams])

  const handleP2PMergeSuccess = useCallback(async () => {
    if (refreshVideoStreams && typeof refreshVideoStreams === 'function') {
      await refreshVideoStreams()
    }
    setShowModal(false)
  }, [refreshVideoStreams])

  const farthestIncidentPrompt = (
    <>
      <button className={style.button} onClick={() => setShowModal(true)}>
        Enter a farther incident
      </button>
      {showModal && (
        <P2PMergeIncidentModal
          currentStreamId={currentIncident.preferredStreamId}
          currentIncidentId={currentIncident.id}
          onClose={() => setShowModal(false)}
          onSuccess={handleP2PMergeSuccess}
        />
      )}
    </>
  )

  if (!nearbyIncidents) {
    return (
      <>
        Loading
        {farthestIncidentPrompt}
      </>
    )
  }
  if (nearbyIncidents && nearbyIncidents.length === 0) {
    return (
      <>
        No nearby incidents detected
        {farthestIncidentPrompt}
      </>
    )
  }

  return (
    <>
      Nearby Incidents Detected
      <div className={style.container}>
        {nearbyIncidents.map(incident => {
          if (currentIncident?.unverifiedIGLIncident) {
            const latest = latestRev(incident)
            const timeLatest = latest?.occurredAt ?? incident.createdAt
            return (
              <P2PNearbyIncident
                key={incident.id}
                distance={incident.distance}
                address={incident.location.address}
                title={incident.title}
                time={timeLatest}
                onMerge={() => handleP2PMerge(incident.id)}
                isMerging={mergingP2PIncidentId === incident.id}
                disabled={disabled || !!mergingP2PIncidentId}
              />
            )
          }

          return (
            <NearbyIncidentPreview
              key={incident.id}
              incident={incident}
              mergingIncident={mergingIncident}
              setMerging={setMerging}
              disabled={disabled}
            />
          )
        })}
      </div>
      {farthestIncidentPrompt}
    </>
  )
}

NearbyIncidentList.propTypes = {
  nearbyIncidents: array,
  mergingIncident: object,
  setMerging: func,
  disabled: bool,
  refreshVideoStreams: func,
}

export default NearbyIncidentList
