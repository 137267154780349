import React, { useEffect, useRef, useState } from "react";

import { LoggingService } from "@guardian/Services/Logging";
import { Anchor } from "@guardian/UI/Button";
import { Tooltip } from "@guardian/UI/Tooltip";

import CallConfirmationModal from "./components/CallConfirmationModal";

const PhoneNumber = props => {
  const { number } = props;

  const [callModalLoading, setCallModalLoading] = useState(null);
  const [callModalLocked, setCallModalLocked] = useState(null);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [callTrigger, setCallTrigger] = useState(null);

  const numberRef = useRef(number);

  useEffect(() => {
    numberRef.current = number;
  }, [number]);

  useEffect(() => {
    if (callTrigger == null) { return; }

    const number = numberRef.current;

    let artificialDelay;

    const submit = () => {
      setCallModalLoading(true);
      setCallModalLocked(true);

      artificialDelay = setTimeout(() => {
        const encodedNumber = encodeURIComponent(number);
        const voiceUrl =
          `https://voice.google.com/u/0/calls?a=nc,${encodedNumber}`;

        window.open(voiceUrl, "_blank");

        setCallModalOpen(false);
        setCallModalLoading(false);
      }, 250);
    };

    submit();

    return () => {
      clearTimeout(artificialDelay);
    }
  }, [callTrigger]);

  const handleCloseCallModal = () => {
    setCallModalOpen(false);
  };

  const handleConfirmCall = () => {
    setCallTrigger(+new Date());
  };

  const handleExitCallModal = () => {
    setCallModalLocked(false);
  };

  const handleOpenCallModal = (e) => {
    e && e.preventDefault();
    setCallModalOpen(true);
  };

  return (
    <React.Fragment>
      <Tooltip content="Call number">
        <Anchor href="#" onClick={handleOpenCallModal}>
          { number }
        </Anchor>
      </Tooltip>
      <CallConfirmationModal
        locked={callModalLocked}
        loading={callModalLoading}
        onClose={handleCloseCallModal}
        onConfirm={handleConfirmCall}
        onExit={handleExitCallModal}
        open={callModalOpen}
      />
    </React.Fragment>
  );
};

export default PhoneNumber;
