import React from "react"
import { string, number, func, bool } from "prop-types"

import { dateToUTC } from "@guardian/Utils/util"
import { Spinner } from "@guardian/UI/Loader"

import {
  Incident,
  TextRow,
  Emphasis,
  Time,
  MergeButton,
} from './styles'

const P2PNearbyIncident = ({ title, distance, address, time, onMerge, isMerging, disabled }) => (
  <Incident>
    <TextRow>{title}</TextRow>
    <TextRow>
    {distance && `(${distance}m) `}
      <Emphasis>{address}</Emphasis>
    </TextRow>
    <Time>{dateToUTC(time).calendar(",")}</Time>
    <MergeButton onClick={onMerge} disabled={isMerging || disabled}>
      {isMerging ? <Spinner /> : "Merge P2P"}
    </MergeButton>
  </Incident>
)

P2PNearbyIncident.propTypes = {
  title: string.isRequired,
  distance: number,
  address: string.isRequired,
  time: string.isRequired,
  onMerge: func.isRequired,
  isMerging: bool.isRequired,
  disabled: bool,
}

export default P2PNearbyIncident
