const NoFileSelected = () => (
  <div className='listen-panel'>
    <div className='add-prompt'>
      <img src='/emptyset.svg' alt='No file selected' />
      <div className='add-prompt-text'>
        Select a CSV file to start auditing.
      </div>
    </div>
  </div>
)

export default NoFileSelected
