import * as Sentry from "@sentry/react"

import config from "@guardian/Config"

import history from "@guardian/Utils/history"

const SENTRY_ENV = config.production ? "PRODUCTION" : "STAGING"
const IS_LOCALHOST = window.location.hostname.match("localhost")

Sentry.init({
  dsn: `https://${config.sentryKey}@o136556.ingest.sentry.io/1317205`,
  environment: SENTRY_ENV,
  // Disable Sentry on localhost
  enabled: !IS_LOCALHOST,
  // Ignore abort errors
  ignoreErrors: ["AbortError", "CanceledError"],
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    Sentry.browserApiErrorsIntegration(),
  ],
  tracesSampleRate: 0.1,
})
