import React, { useState } from "react";
import nameInitials from "name-initials";
import PropTypes from "prop-types";

import Fallback from "./Fallback";
import StyledAvatar from "../views/Avatar";
import Image from "../views/Image";

const Avatar = React.memo(React.forwardRef(function Avatar(props, ref) {
  const {
    id,
    fullName,
    size,
    src: initialSrc,
    forceTemp
  } = props;

  // At the time of this writing, Jan 31st, 2023, we are in the process of a new
  // design that supports the new avatar formats represented in this code, that
  // includes falling back to initials/colors. However, at this time the backend
  // still sends a default avatar URL for users with no set avatar URL. This
  // means we can't show the new format for these cases. In order to contain the
  // impact of this, we've decided to temporarily have code here to check if the
  // given src is the same are our backend's default image, and unsets it these
  // cases.
  let src = initialSrc;
  if (src && !forceTemp) {
    // Remove the analystDefaultImageURLRegex regex below if you want to also disallow the default analyst images.
    const defaultImageURLRegex = new RegExp(/default-(\w|-)*.png$/i);
    const analystDefaultImageURLRegex = new RegExp(/default-(\w|-)*analyst.png$/i);
    if (src.match(defaultImageURLRegex) && !src.match(analystDefaultImageURLRegex)) {
      src = "";
    }
  }

  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
    console.error(`Avatar: Failed to load image with src: ${ src }`);
  };

  const renderFallback = () => {
    return (
      <Fallback
        id={id}
        fullName={fullName}
      />
    );
  };

  const renderImage = () => {
    const alt = `An image of ${ fullName }.`;

    return (
      <Image alt={alt} src={src} onError={handleImageError}/>
    );
  };

  const renderImageOrFallback = () => {
    // NOTE:
    // In most cases this should work fine, but there is the chance that the
    // image will fail to load its src and fall back to the fallback. In such
    // cases there will be an iniital <img/> element rendered, which will be
    // replaced after failure with the fallback. Just worth knowing.
    return (
      error ? renderFallback() : renderImage()
    );
  };

  return (
    <StyledAvatar ref={ref} size={size}>
      {
        src ? renderImageOrFallback() : renderFallback()
      }
    </StyledAvatar>
  );
}));

Avatar.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  size: PropTypes.string,
  src: PropTypes.string.isRequired
}

Avatar.defaultProps = {};

export default Avatar;
