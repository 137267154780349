import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import history from "@guardian/Utils/history";

import { IconButton } from "@guardian/UI/Button";
import { Icon } from "@guardian/UI/Icon";

import ActionsButton from "./components/ActionsButton";
import ResolveButton from "./components/ResolveButton";
import LifecycleButtonGroup from "./views/LifecycleButtonGroup";
import StyledTabBar from "./views/TabBar";
import TabButton from "./views/TabButton";
import TabButtonGroup from "./views/TabButtonGroup";

const handleCloseTab = () => {
  history.push("/sos_dms");
};

const TabBar = props => {
  const { session } = props;

  const { sessionId, tab } = useParams();

  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    if (tab != null) {
      // When these pages are being navigated away from, the `tab` param will
      // become undefined. In these cases we dont want to cause our tab
      // transitioner logic to trigger any new renders, hence we ignore these
      // updates. When this view is truly in view, it will always have a tab
      // param.
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <StyledTabBar>
      <TabButtonGroup>
        <TabButton
          isActive={activeTab === "chat"}
          data-text={"Chat"}
          to={`/sos_dms/${ sessionId }/chat`}
        >
          Chat
        </TabButton>
        <TabButton
          isActive={activeTab === "map"}
          data-text={"Map"}
          to={`/sos_dms/${ sessionId }/map`}
        >
          Map
        </TabButton>
      </TabButtonGroup>
      <LifecycleButtonGroup>
        <ActionsButton
          session={session}
        />
        <ResolveButton
          session={session}
        />
        <IconButton onClick={handleCloseTab} size="small">
          <Icon.Close />
        </IconButton>
      </LifecycleButtonGroup>
    </StyledTabBar>
  );
};

export default TabBar;
