import React from "react"
import { number } from "prop-types"

import { coverageToHealthLevel } from "@guardian/Utils/util"

import "./ProgressCircle.scss"

const ProgressCircle = ({ percent, width }) => {
  const innerCircleWidth = width - 4
  const innerCircleMargin = Math.floor(innerCircleWidth / 2) * -1
  const formatted = Math.round(percent * 100)

  return (
    <div
      className={`progress-circle progress-${formatted}`}
      style={{ width, height: width }}
    >
      <span
        className={`health-${coverageToHealthLevel(formatted / 100)}`}
        style={{
          width: `${innerCircleWidth}px`,
          height: `${innerCircleWidth}px`,
          lineHeight: `${innerCircleMargin}px`,
          marginLeft: `${innerCircleMargin}px`,
          marginTop: `${innerCircleMargin}px`,
        }}
      >
        {formatted}%
      </span>
    </div>
  )
}

ProgressCircle.propTypes = {
  percent: number.isRequired,
  width: number.isRequired,
}

export default ProgressCircle
