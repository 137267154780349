import classNames from "classnames";
import styled from "styled-components";

import { IconURL } from "@guardian/UI/Icon";
import { svg } from "@guardian/UI/Utils";

const Selected = styled.div.attrs(
  props => {
    const {
      className,
      disabled,
      visible
    } = props;

    return {
      "aria-disabled": disabled,
      className: classNames(className, "dropdown__item-selected", {
        "dropdown__item-selected--is-disabled": disabled,
        "dropdown__item-selected--is-visible": visible
      })
    };
  }
)`
  ${({ theme: { colors } }) => `
    align-items: center;
    background-image: url(${
      svg.replaceColors(IconURL.Check, { stroke: colors.grey030 })
    });
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: var(--font-sizes-small);
    color: var(--colors-grey-030);
    display: flex;
    height: calc((var(--space-x-small) * 2) + var(--line-heights-small));
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.1s ease-in-out;
    width: var(--space-medium);

    &.dropdown__item-selected--is-visible {
      opacity: 1;
    }

    // Give disabled styling priority.
    && {
      &.dropdown__item-selected--is-disabled {
        background-image: url(${
          svg.replaceColors(IconURL.Check, { stroke: colors.grey070 })
        });
      }
    }
  `}
`;

Selected.defaultProps = {};

export default Selected;
