import classNames from "classnames";
import styled from "styled-components";

const AvatarWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__base-user-info__avatar-wrapper"
    )
  };
})`
  align-self: start;
  margin-left: var(--space-x-small);
`;

AvatarWrapper.defaultProps = {};

export default AvatarWrapper;
