import PubSub from "pubsub-js";

import { TYPES } from "../views/DialogMessages/DialogMessage";

const error = (message, options) =>
  PubSub.publish(TYPES.ERROR, { message, options });
const generic = (message, options) =>
  PubSub.publish(TYPES.GENERIC, { message, options });
const info = (message, options) =>
  PubSub.publish(TYPES.INFO, { message, options });
const success = (message, options) =>
  PubSub.publish(TYPES.SUCCESS, { message, options });
const warning = (message, options) =>
  PubSub.publish(TYPES.WARNING, { message, options });

const Dialog = {
  error,
  generic,
  info,
  success,
  warning
};

export default Dialog;
