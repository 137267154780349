import axios from "axios"
import classnames from "classnames"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _uniqBy from "lodash/uniqBy"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { combineLatest, from, Observable, of, timer } from "rxjs"
import { catchError, mergeMap } from "rxjs/operators"

import config from "@guardian/Config"
import { eventHub } from "@guardian/State"

import { getProtectSafetyFeed, getProtectSessionRecords } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { getWMBPayload, getWMBSafetyFeed, getWMBSafetyPOIs } from "@guardian/API/Optimus/resources/legacy/resources/WMB"
import { IncidentService } from "@guardian/Services/Incident"

import Container from "@guardian/Components/Common/Container"
import Map from "@guardian/Components/Map/Map"
import { ICoords } from "@guardian/Components/Map/Route/types"
import { AddressModal } from "@guardian/Components/ModSOS/components/Map/AddressModal/AddressModal"
import { HomeModal } from "@guardian/Components/ModSOS/components/Map/HomeModal/HomeModal"
import { IncidentModal } from "@guardian/Components/ModSOS/components/Map/IncidentModal/IncidentModal"
import { SOSMapPosition } from "@guardian/Components/ModSOS/components/Map/MapPosition"
import { NearbyModal } from "@guardian/Components/ModSOS/components/Map/NearbyModal/NearbyModal"

import { useSOSMap } from "./SOSMap.hooks"
import styles from "./SOSMap.module.css"
import { useSOSMapV1 } from "./SOSMapV1.hooks"
import {
  IGeoBounds,
  ILocation,
  INearbyIncident,
  IPropsV2,
} from "./types"

const LOCATION_DEFAULT: ILocation = {
  longitude: -98.5795,
  latitude: 39.8283,
  addressSpecific: "Loading...",
  addressGeneral: "",
  isNotInRoute: true,
}

const WMB_LAYERS = [
  {
    id: "route",
    type: "line",
    source: "wmb-route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#FFF",
      "line-width": 2,
    },
  },
]

const WMB_SOURCE = {
  type: "geojson",
  data: {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  },
}

const ZOOM_LEVEL_WHEN_LOADING = 3
const ZOOM_LEVEL_WHEN_NO_LOCATION = 3

const SOSMapRaw: React.FunctionComponent<IPropsV2> = ({
  onBoundsChange,
  onAddressChange,
  activeSessionId,
  isV2Protect,
  sessionResp,
  homeLocation,
  ownerData,
}) => {
  const [mapStyle, setMapStyle] = useState<any>({})
  const [location, setLocation] = useState<ILocation>(LOCATION_DEFAULT)
  const [centerCoords, setCenterCoords] = useState<Array<number>>([])
  const [address, setAddress] = useState("")
  const [activePOI, setActivePOI] = useState<string>()
  const [activeIncident, setActiveIncident] = useState<INearbyIncident>()
  const [hoveredPOI, setHoveredPOI] = useState<string>()
  const prevLocation = useRef(location)

  const hasLocationPermission = _get(ownerData, "hasLocationPermission")

  const [defaultZoom, setDefaultZoom] = useState(
    hasLocationPermission || homeLocation ? 17 : ZOOM_LEVEL_WHEN_LOADING,
  )

  useEffect(() => {
    setDefaultZoom(
      hasLocationPermission || homeLocation ? 17 : ZOOM_LEVEL_WHEN_NO_LOCATION,
    )
  }, [hasLocationPermission, homeLocation])

  const getLocation = () => {
    if (hasLocationPermission) {
      return location
    }

    if (homeLocation) {
      return homeLocation
    }

    return {
      ...LOCATION_DEFAULT,
      addressSpecific: "Location Services Disabled",
      addressGeneral: "Home Address Not Found",
    }
  }

  const getCenterCoords = () => {
    if (hasLocationPermission) {
      return centerCoords
    }

    if (homeLocation) {
      return [homeLocation.longitude, homeLocation.latitude]
    }

    return [LOCATION_DEFAULT.longitude, LOCATION_DEFAULT.latitude]
  }

  const { onSuccessV2, pois, nearbyIncidents } = useSOSMap({
    updateMapStyles: setMapStyle,
    activeSessionId,
    isV2Protect,
    activePOI,
    updateAddress: setAddress,
    updateLocation: setLocation,
    updateActiveIncident: setActiveIncident,
    updateActivePOI: setActivePOI,
    updateHoveredPOI: setHoveredPOI,
    hoveredPOI,
    location,
  })
  const { onSuccessV1 } = useSOSMapV1({
    updateMapStyles: setMapStyle,
    updateAddress: setAddress,
    updateLocation: setLocation,
    activeSessionId,
    isV2Protect,
    activePOI,
    updateActiveIncident: setActiveIncident,
    updateActivePOI: setActivePOI,
    updateHoveredPOI: setHoveredPOI,
    hoveredPOI,
    location,
  })

  const setCenter = (coords: number[]) => {
    setCenterCoords(coords)
    eventHub.emit("set center", { lat: coords[1], lng: coords[0] })
  }

  const initializer = useCallback(() => {
    if (isV2Protect) {
      return combineLatest([
        getProtectSessionRecords(activeSessionId),
        getProtectSafetyFeed(activeSessionId).pipe(
          catchError(() =>
            of({
              data: {
                nearbyIncidents: [],
                pois: [],
              },
            }),
          ),
        ),
      ])
    } else {
      return combineLatest([
        from(getWMBPayload(activeSessionId)),
        getWMBSafetyFeed(activeSessionId),
        getWMBSafetyPOIs(activeSessionId),
      ])
    }
  }, [activeSessionId, isV2Protect])

  useEffect(() => {
    const subscription = timer(0, 5000)
      .pipe(
        mergeMap((): Observable<any> => initializer()),
        mergeMap(data => {
          isV2Protect ? onSuccessV2(data) : onSuccessV1(data)

          return of([])
        }),
      )
      .subscribe()

    return () => {
      subscription.unsubscribe()
    }
  }, [initializer])

  useEffect(() => {
    const setIncident = async (incident: string) => {
      const incs = (await IncidentService.getIncidents({ incidentIds: [incident] })) as any
      const inc = (incs || {})[0]
      if (inc) {
        setActiveIncident({
          incidentId: inc.id,
          ...inc,
        } as any)
      }
    }

    const incident = _get(
      sessionResp || {},
      "data.entryPoint.metadata.incidentID",
      undefined,
    )
    if (incident) {
      setIncident(incident)
    }
  }, [sessionResp])

  const pullMapStyles = async () => {
    const url: string = config.sosMapUrl || ""
    const res = await axios.get(url)
    const style = res.data as any

    style.sources["wmb-route"] = WMB_SOURCE
    style.layers = style.layers.concat(WMB_LAYERS)

    setMapStyle(style)
  }

  useEffect(() => {
    if (onAddressChange) {
      onAddressChange(address)
    }
  }, [address])

  useEffect(() => {
    ;(initializer() as Observable<any[]>).toPromise().then(res => {
      const data = res[0].data
      let location: ILocation = _get(data, "locations[0]", {})
      if (isV2Protect) {
        location = _get(data, "records[0].location", {})
      }
      if (hasLocationPermission) {
        setCenter([location.longitude, location.latitude])
      }
    })
    pullMapStyles()
  }, [initializer])

  const onMapBoundsChange = useCallback((bounds: any) => {
    if (!onBoundsChange) {
      return
    }

    const NELat = bounds.getNorthEast().lat()
    const NELng = bounds.getNorthEast().lng()
    const SWLat = bounds.getSouthWest().lat()
    const SWLng = bounds.getSouthWest().lng()

    let geoBounds: IGeoBounds = {
      north: NELat,
      south: SWLat,
      east: NELng,
      west: SWLng,
    }
    onBoundsChange(geoBounds)
  }, [])

  useEffect(() => {
    const location = getLocation()
    if (
      prevLocation.current.longitude !== location.longitude ||
      prevLocation.current.latitude !== location.latitude
    ) {
      setCenter([location.longitude, location.latitude])
    }
    prevLocation.current = location
  }, [getLocation()])

  const usersNearby = _get(
    sessionResp,
    "data.lastUserRecord.location.usersNearby",
    0,
  )

  const getRouteCoords = () => {
    if (mapStyle.sources) {
      const items = mapStyle.sources["wmb-route"].data.geometry.coordinates.map(
        (item: number[]) => ({
          lat: item[1],
          lng: item[0],
        }),
      )
      return _uniqBy(items, (item: ICoords) => `${item.lat}${item.lng}`)
    }
  }

  const renderMap = () => {
    const location = getLocation()
    const centerCoords = getCenterCoords()

    if (_isEmpty(mapStyle) || centerCoords.length === 0 || location == null) {
      return null
    }
    if (location.latitude === 0 && location.longitude === 0) {
      return (
        <div className={styles.missingMapContainer}>
          <div className={styles.missingMapText}>No Location</div>
        </div>
      )
    }

    return (
      <>
        <Container className={styles.bottomBar} fullWidth left={2} right={2}>
          {homeLocation && (
            <HomeModal
              address={`${homeLocation.addressSpecific}, ${homeLocation.addressGeneral}`}
              onClick={() => {
                setCenter([homeLocation.longitude, homeLocation.latitude])
              }}
            />
          )}
          <AddressModal
            address={location.addressSpecific}
            city={location.addressGeneral}
            lat={location.latitude}
            lng={location.longitude}
            hasLocPermissions={hasLocationPermission}
          />
        </Container>
        <Map
          draggable
          allowControls={false}
          streetView={false}
          defaultStyle
          defaultCenter={{ lat: centerCoords[1], lng: centerCoords[0] }}
          defaultZoom={defaultZoom}
          onBoundsChange={onMapBoundsChange}
          routeCoords={hasLocationPermission ? getRouteCoords() : []}
        >
          {homeLocation && (
            <SOSMapPosition
              lat={homeLocation.latitude}
              lng={homeLocation.longitude}
              key={`home-${homeLocation.addressSpecific}`}
            >
              <img
                alt=""
                src='/home-pin.png'
                style={{ width: 30, marginBottom: 20 }}
              />
            </SOSMapPosition>
          )}
          {defaultZoom !== ZOOM_LEVEL_WHEN_LOADING &&
            pois &&
            pois.map(poi => {
              return (
                <SOSMapPosition
                  lat={poi.latitude}
                  lng={poi.longitude}
                  key={poi.id}
                >
                  <div
                    className={classnames(styles.poiMarker, {
                      [styles.selected]:
                        (activePOI && activePOI === poi.id) ||
                        (hoveredPOI && hoveredPOI === poi.id),
                      [styles.dim]: !!hoveredPOI && hoveredPOI !== poi.id,
                    })}
                    onMouseEnter={() => {
                      setHoveredPOI(poi.id)
                    }}
                    onMouseLeave={() => {
                      setHoveredPOI(undefined)
                    }}
                    onClick={() => {
                      if (activePOI === poi.id) {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${poi.name}%20${poi.address}`,
                          "_blank",
                        )
                      }
                      setActivePOI(poi.id)
                    }}
                  >
                    {(activePOI === poi.id || hoveredPOI === poi.id) && (
                      <p>{poi.name}</p>
                    )}
                  </div>
                </SOSMapPosition>
              )
            })}
          {defaultZoom !== ZOOM_LEVEL_WHEN_LOADING &&
            nearbyIncidents &&
            nearbyIncidents.map(inc => {
              const classMap = {
                yellow: styles.yellow,
                red: styles.red,
              }
              return (
                <SOSMapPosition
                  lat={inc.latitude}
                  lng={inc.longitude}
                  key={inc.incidentId}
                >
                  <div
                    className={`${styles.incidentMarker} ${
                      classMap[inc.severity]
                    }`}
                    onClick={() => setActiveIncident(inc)}
                  />
                </SOSMapPosition>
              )
            })}
          {hasLocationPermission && (
            <SOSMapPosition
              lat={location.latitude}
              lng={location.longitude}
              key={location.addressSpecific}
            >
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className={styles.marker} />
              </a>
            </SOSMapPosition>
          )}
        </Map>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <Container className={styles.modals} fullWidth column>
        {!!usersNearby && <NearbyModal usersNearby={usersNearby} />}
        {activeIncident && (
          <IncidentModal
            {...activeIncident}
            onClose={() => setActiveIncident(undefined)}
          />
        )}
      </Container>
      {renderMap()}
    </div>
  )
}

export const SOSMap = React.memo(SOSMapRaw)
