import { array, bool, func, string } from "prop-types"
import React from "react"

import CodeRow from "./CodeRow"
import styles from "./CodeSection.module.css"

const CodeSection = ({ rows, header, expanded, onClick }) => (
  <div>
    <div
      key={rows[0].serviceArea + header}
      className={styles.subheader}
      onClick={onClick}
    >
      {header} <i className={`fas fa-caret-${expanded ? "up" : "down"}`} />
    </div>
    {expanded && rows.map(row => <CodeRow key={row.id} {...row} />)}
  </div>
)

CodeSection.propTypes = {
  rows: array,
  header: string,
  expanded: bool,
  onClick: func,
}

export default CodeSection
