import React from "react";

import { Box } from "@guardian/UI/Box";
import { Button, ButtonIcon, ButtonGroup } from "@guardian/UI/Button";
import {
  Field,
  Fieldset,
  Hint,
  Input,
  Label
} from "@guardian/UI/Form";
import { Icon } from "@guardian/UI/Icon";
import { Spinner } from "@guardian/UI/Loader";
import {
  Body,
  Close,
  Footer,
  Header,
  Modal
} from "@guardian/UI/Modal";
import { MediumParagraph } from "@guardian/UI/Paragraph";

import MessageInput from "./views/MessageInput";

const OutboundMessagingModal = props => {
  const {
    inputIds,
    inputMessage,
    locked,
    loading,
    onInputIdsChange,
    onInputMessageChange,
    onClose,
    onExit,
    onSubmit,
    open,
    submitDisabled
  } = props;

  const handleIdsChanged = ({ target: { value } }) => {
    // Format to alphanumeric and commas only, split on commas, and then reduce
    // ids array, returning only values with valid format.
    // TODO: For the time being "valid format" is only if each id has some
    // length. In the future it would be nice to improve this to include other
    // checks if we have hard restrictions, such as length or format.
    const ids = value
      .replace(/[^a-zA-Z0-9,]/gi, "")
      .split(",")
      .reduce((memo, id, i, ids) => {
        const isLastAndNotFirst = i !== 0 && i === ids.length - 1;
        const isEmptyCommaSplitArray =
          ids.length === 2 && ids[0].length === 0 && ids[1].length === 0;

        if (
          id.length === 0 &&
          !(isLastAndNotFirst && !isEmptyCommaSplitArray)
        ) {
          return memo;
        }

        memo.push(id);

        return memo;
      }, []);

    onInputIdsChange(ids);
  };

  const handleMessageChanged = ({ target: { value } }) => {
    const message = value;

    onInputMessageChange(message);
  };

  return (
    <Modal
      backdropProps={
        {
          closeDisabled: locked
        }
      }
      onClose={onClose}
      onExit={onExit}
      open={open}
    >
      <Header>Outbound Messaging</Header>
      <Body>
        <Box mt="small">
          <MediumParagraph>
            Use this form to send outbound messages en masse to a one or more
            users. When users reply, their inbound message will be assigned to
            any available Agent, as within the normal messaging flow (e.g.;
            their inbound message may not be delivered to <i>you</i>).
          </MediumParagraph>
        </Box>
        <Box mt="large">
          <Fieldset>
            <Field>
              <Label>
                User IDs
              </Label>
              <Hint>
                Comma separated list of Users IDs for users to whom you wish to
                message.
              </Hint>
              <Input
                disabled={locked}
                onChange={handleIdsChanged}
                placeholder={"User IDs"}
                value={inputIds.join(",")}
              />
            </Field>
          </Fieldset>

          <Fieldset>
            <Field>
              <Label>
                Outbound Message
              </Label>
              <Hint>
                Outbound message to send to all users represented in the field
                above. Formatting support is currently limited, so please keep
                your messages simple and text based for now.
              </Hint>
              <MessageInput
                disabled={locked}
                onChange={handleMessageChanged}
                placeholder={"Outound Message"}
                value={inputMessage}
              />
            </Field>
          </Fieldset>
        </Box>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button disabled={locked} isLink onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={
              locked ||
              submitDisabled
            }
            isPrimary
            onClick={onSubmit}
          >
            Send
            <ButtonIcon.End>
              {
                loading ? (
                  <Spinner/>
                ) : (
                  <Icon.Send/>
                )
              }
            </ButtonIcon.End>
          </Button>
        </ButtonGroup>
      </Footer>
      <Close disabled={locked}/>
    </Modal>
  );
};

export default OutboundMessagingModal;
