import classNames from "classnames";
import styled from "styled-components";

const Hint = styled.div.attrs(
  props => {
    return {
      className: classNames("form__hint")
    };
  }
)`
  color: var(--colors-grey-030);
  display: block;
  font-size: var(--font-sizes-x-small);
  line-height: var(--line-heights-x-small);
  margin-bottom: var(--space-small);
  max-width: var(--measures-x-small);
`;

Hint.propTypes = {};

Hint.defaultProps = {};

export default Hint;
