import classNames from "classnames";
import styled from "styled-components";

const TabButtonGroup = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__tab-bar__tab-button-group")
    }
  }
)`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
`;

export default TabButtonGroup;
