import classnames from "classnames"
import dayjs from "dayjs"
import _ from "lodash"
import { bool, func, object, string } from "prop-types"
import { memo, useCallback, useEffect, useReducer, useState } from "react"
import { v4 as uuid } from "uuid"

import consts from "@guardian/Constants"
import { eventHub, useGlobalState } from "@guardian/State"

import { Notifications, Signal } from "@guardian/API/Optimus"
import { ContentService } from "@guardian/Services/Content"
import { GeocodingService } from "@guardian/Services/Geocoding"
import { IncidentService } from "@guardian/Services/Incident"
import combokeys from "@guardian/Utils/hotkeys"
import * as incUtil from "@guardian/Utils/incident"
import { objPick } from "@guardian/Utils/obj-pick"
import { reverseGeoToLocation } from "@guardian/Utils/util"

import IncidentStatusSelect from "@guardian/Components/IncidentStatus/IncidentStatusSelect"
import Map from "@guardian/Components/Map/Map"
import ModBroadcasterCommunication from "@guardian/Components/ModBroadcasterCommunication/ModBroadcasterCommunication"

import styles from "./ActionPane.module.css"
import { reducer as ActionPaneReducer, ActionTypes } from "./ActionPane.reducer"
import ActionPaneHeader from "./ActionPaneHeader"
import IncidentCreation from "./IncidentCreation/IncidentCreation"
import NotifArea from "./NotifArea/NotifArea"
import VerifyButton from "./VerifyButton"

const initialState = {
  notifType: "",
  notifSentiment: "",
  notifSound: "",
  notificationCategory: "",
  notifTitle: "",
  notifMessage: "",
  notifSoundOn: true,
  renotify: true,
  showIncidentMetadata: true,
  incidentTitle: "",
  incidentUpdate: "",
  incidentCategory: "N/A",
  safetyLevel: 0,
  prompt911: false,
  location: {},
  mergingIncident: undefined,
  initialStreamLocation: {},
  editedNotifTitle: false,
  editredNotifMessage: false,
  distributionType: "",
  distributionValue: undefined,
  sendToInactive: false,
  radiusOverride: undefined,
  canSubmit: false,
  sendNotifInternalOnly: false,
  notifTurnedOn: false,
  largeMap: false,
}

const ActionPane = ({
  stream,
  incident,
  streamHandled,
  lafd,
  activeStreamId,
  clearStreamSelection,
  refreshVideoStreams,
}) => {
  const { videoDesk, tags, user, active, sentimentTypeDefaults } =
    useGlobalState(state => {
      const selectedGlobal = objPick(state.global, [
        "videoDesk",
        "tags",
        "user",
      ])
      const {
        modBroadcasterConfig: { active },
        customNotifConfig: { sentimentTypeDefaults },
      } = state.global
      return { ...selectedGlobal, active, sentimentTypeDefaults }
    })

  const [state, dispatch] = useReducer(ActionPaneReducer, initialState)
  const [recentIncidents, setRecentIncidents] = useState()
  const [selectVal, setSelectVal] = useState(
    stream?.videoStream?.reportCategory === consts.GoodVibeCategory
      ? "GoodVibes"
      : "Active",
  )
  const DEFAULT_CENTER = { lat: 40.73143691741254, lng: -74.0129354316012 }

  useEffect(() => {
    dispatch({
      type: ActionTypes.SetNotifType,
      payload: { type: "breaking_news", defaults: sentimentTypeDefaults },
    })
    dispatch({
      type: ActionTypes.SetNotificationCategory,
      payload: { type: "verifiedLive" },
    })
    eventHub.emit("fetchGeographyPushesConfig")
  }, [])

  useEffect(() => {
    const suggestTag = async () => {
      const { data: { results } } =
        await Signal.getAutoSuggestTags(state.incidentTitle);

      if (results.length) {
        dispatch({
          type: ActionTypes.SetIncidentCategory,
          payload: { category: results[0].display_name },
        })
      }
    }
    suggestTag()
  }, [state.incidentTitle])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const incidentIds = await IncidentService.queryIncidents({
          since: dayjs().subtract(2, "hour").toDate(),
        })
        const incidents = await IncidentService.getIncidents({ incidentIds })

        // Filter incidents that have bad location or are deleted
        const filteredIncidents = incidents.filter(i => i.location?.lat && i.location?.lng && !i.deleted)
        setRecentIncidents(filteredIncidents)
      } catch (error) {
        console.error('Error fetching incidents:', error)
      }
    }

    fetchData()
    const intervalId = setInterval(fetchData, 5000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    const run = async () => {
      if (stream) {
        let location
        let title = ""
        if (stream.videoStream.incidentId && incident) {
          location = incident.location
          title = incident.title
        } else if (stream.iglStream) {
          const latLng = {
            lat: stream.iglStream.lat,
            lng: stream.iglStream.lng,
          }
          const geocodeResp = await GeocodingService.reverseGeocode(
            latLng.lat,
            latLng.lng
          )
          if (geocodeResp) {
            const locState = await reverseGeoToLocation(latLng, geocodeResp)
            location = locState
          }
        }

        let notifTitle = `📷 LIVE VIDEO`

        if (stream.videoStream.onAir) {
          dispatch({
            type: ActionTypes.SetNotificationCategory,
            payload: { type: "onAir" },
          })
          dispatch({
            type: ActionTypes.SetDistributionType,
            payload: { type: "radius" },
          })
          dispatch({
            type: ActionTypes.SetNotifSound,
            payload: { sound: "on_air_notification.wav" },
          })
          notifTitle = "📡 Citizen is OnAir"
        }

        if (stream.videoStream.verifiedLive) {
          dispatch({
            type: ActionTypes.SetNotificationCategory,
            payload: { type: "custom" },
          })
          dispatch({
            type: ActionTypes.SetRenotify,
            payload: { renotify: false },
          })
        }

        dispatch({
          type: ActionTypes.SetInitialStreamData,
          payload: {
            location,
            title,
            notifTitle,
          },
        })
        dispatch({
          type: ActionTypes.SetNotifMessage,
          payload: {
            message: `${title}\n`,
          },
        })

        const textUpdate = ""

        dispatch({
          type: ActionTypes.SetIncidentUpdate,
          payload: {
            update: textUpdate,
          },
        })
        setSelectVal(
          stream?.videoStream?.reportCategory === consts.GoodVibeCategory
            ? "GoodVibes"
            : "Active",
        )
      }
    }
    run()
  }, [activeStreamId])

  useEffect(() => {
    const satisfiesUpdate = (!incident && !!state.incidentUpdate) || !!incident
    const canSubmit =
      state.incidentTitle &&
      state.location &&
      state.location.location &&
      state.location.address &&
      state.location.cityCode &&
      (state.radiusOverride === undefined ||
        (!!state.radiusOverride && parseFloat(state.radiusOverride) !== 0.0)) &&
      (state.mergingIncident || satisfiesUpdate)

    dispatch({ type: ActionTypes.SetCanSubmit, payload: { canSubmit } })
  }, [
    state.incidentTitle,
    state.location,
    state.radiusOverride,
    state.mergingIncident,
    state.incidentUpdate,
    incident,
  ])

  useEffect(() => {
    combokeys.bind("m", () => {
      dispatch({
        type: ActionTypes.SetLargeMap,
      })
    })

    return () => {
      combokeys.unbind("m")
    }
  }, [])

  const clickVerifyHandler = useCallback(async () => {
    if (!streamHandled) {
      return
    }

    let spec

    if (state.canSubmit) {
      // Video has an incident already
      if (incident) {
        spec = {
          id: uuid(),
          text: state.incidentUpdate || null,
          occurredAt: new Date(),
          visibility: consts.Visibility.Public,
          updateSequence: 0,
          incidentId: incident.id,
          rootRevId: incUtil.initialRevId(incident),
          source: consts.Source.Radio,
        }
      } else {
        // Signal video
        const tag = tags.find(t => t.display_name === state.incidentCategory)

        spec = {
          id: uuid(),
          text: state.incidentUpdate || null,
          title: state.incidentTitle || null,
          level: state.level,
          location:
            !incident ||
            !_.isEqual(state.location, state.mergingIncident.location)
              ? state.location
              : undefined,
          occurredAt: new Date(),
          visibility: consts.Visibility.Public,
          iglStreamId: stream.videoStream.id,
          source: consts.Source.IGL,
          prompt911: state.prompt911,
          psnId: lafd ? import.meta.env.REACT_APP_LAFD_PSN : undefined,
          clearanceLevel: lafd ? 1 : undefined,
          tagIds: [],
          tags: tag && tag.id ? [tag] : [],
        }
        if (state.mergingIncident) {
          spec.updateSequence = 0
          spec.incidentId = state.mergingIncident.id
          spec.rootRevId = incUtil.initialRevId(state.mergingIncident)
          spec.iglStreamId = undefined
          if (state.incidentTitle === state.mergingIncident.title) {
            spec.title = undefined
          }
          if (state.level === state.mergingIncident.level) {
            spec.level = undefined
          }
        }
      }

      if (!incident) {
        streamHandled("Created")
      }
      incident = await eventHub.emit("createUpdateRev", spec)
      IncidentStatusSelect.setClosureStatus(selectVal, incident)

      if (state.mergingIncident) {
        await ContentService.mergeContent(
          "video_stream",
          stream.videoStream.id,
          "Live Tab",
          {
            incidentId: state.mergingIncident.id
          }
        )
      }

      const isOnAir = stream.videoStream.onAir

      if (isOnAir && state.notifTurnedOn) {
        let payloads = []
        // Construct base payload
        const basePayload = {
          incidentId: state.mergingIncident.id,
          cityCode: state.mergingIncident.location.cityCode,
          renotify: state.renotify,
          hideIncidentMetadata: !state.showIncidentMetadata,
          title: state.notifTitle,
          message: state.notifMessage,
          type: state.distributionType,
          triggerType: "OnAir",
          sound: state.notifSoundOn,
          transcriberId: user ? user.id : null,
          notifType: state.notifType,
          notifSentiment: state.notifSentiment,
          notifSound: state.notifSound,
          streamId: stream.videoStream.id,
        }
        if (
          basePayload.notifSound &&
          !basePayload.notifSound.endsWith(".wav")
        ) {
          basePayload.notifSound = basePayload.notifSound + ".wav"
        }
        // Create payloads according to distribution type
        if (
          state.distributionType === "radius" ||
          state.sendNotifInternalOnly
        ) {
          const payload = { ...basePayload }
          payload.radius = parseFloat(state.distributionValue)
          // If we send notif internally, we want to create a targeting that is a very small radius and then add additional users
          // I.E. dont send notif to general public, but only to certain users (Frame, LB, etc)
          if (state.sendNotifInternalOnly) {
            payload.radius = 0.01
          }
          payload.type = "radius"
          payloads.push(payload)
        } else if (state.distributionType === "standard") {
          const payload = { ...basePayload }
          payload.geography = {
            type: "service_areas",
            name: "code",
            code: state.mergingIncident.location.cityCode,
          }
          payload.type = "geography"
          payloads.push(payload)
        } else if (state.distributionType === "geography") {
          // Support multiple geographies by sending multiple geo notifs
          state.distributionValue.forEach(geo => {
            const [geoType, geoName, geoCode] = geo.value.split(":")
            const payload = { ...basePayload }
            payload.geography = {
              type: geoType,
              name: geoName,
              code: geoCode,
            }
            payloads.push(payload)
          })
        }
        payloads.forEach(p => Notifications.sendCustomNotification(p))
      }
    }
  }, [tags, user, streamHandled, stream, incident, state, lafd])

  return (
    <div className={styles.container}>
      {stream && (
        <div>
          <div className={state.largeMap ? styles.largeMap : styles.smallMap}>
            <Map
              draggable
              allowControls
              defaultCenter={DEFAULT_CENTER}
              defaultZoom={14}
              defaultStyle
              streetView
            />
          </div>
          <img
            alt=""
            src={state.largeMap ? "/UnexpandButton.png" : "/ExpandButton.png"}
            onClick={() => {
              dispatch({
                type: ActionTypes.SetLargeMap,
              })
            }}
            className={
              state.largeMap ? styles.mapButtonExpanded : styles.mapButton
            }
          />
        </div>
      )}
      <div className={styles.topBar}>
        {stream && (
          <>
            {!!clearStreamSelection && (
              <button
                onClick={clearStreamSelection}
                className={styles.closeButton}
              >
                <img src='/CloseIcon.svg' alt='close' />
              </button>
            )}
            <ActionPaneHeader
              stream={stream}
              incident={incident}
              state={state}
            />
          </>
        )}
        {stream &&
          // Dynaconf modBroadcasterConfig must be on and must have videoDesk tag in regulator
          active && videoDesk && (
            <>
              <hr className={styles.separator} />
              <ModBroadcasterCommunication videoStream={stream.videoStream} />
              <hr className={styles.separator} />
            </>
          )}
        {stream && (
          <IncidentCreation
            state={state}
            dispatch={dispatch}
            stream={stream}
            recentIncidents={recentIncidents}
            incident={incident}
            refreshVideoStreams={refreshVideoStreams}
          />
        )}
        {stream && (!stream.videoStream.onAir || state.mergingIncident) && (
          <NotifArea
            verifiedLive={stream.videoStream.verifiedLive}
            state={state}
            dispatch={dispatch}
            onAir={stream.videoStream.onAir}
            incident={incident}
            stream={stream.videoStream}
          />
        )}
        {stream && (
          <IncidentStatusSelect
            setSelectStatus={incident == null ? setSelectVal : undefined}
            value={incident == null ? selectVal : undefined}
            incident={incident}
            autoSet={incident != null}
          />
        )}
      </div>

      <div
        className={classnames(styles.buttonContainer, {
          [styles.streamNotSelected]: !stream,
        })}
      >
        {stream && (
          <VerifyButton
            state={state}
            stream={stream}
            incident={incident}
            clickHandler={clickVerifyHandler}
            streamHandled={streamHandled}
          />
        )}
      </div>
    </div>
  )
}

ActionPane.propTypes = {
  stream: object,
  incident: object,
  streamHandled: func,
  lafd: bool,
  activeStreamId: string,
  clearStreamSelection: func,
  refreshVideoStreams: func,
}

export default memo(ActionPane)
