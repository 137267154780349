import _ from "lodash"

import config from "@guardian/Config"
import consts from "@guardian/Constants"

export function isRevPublic(rev) {
  return (
    rev && rev.visibility >= consts.Visibility.Public && !isRevRejected(rev)
  )
}
export function isRevRejected(rev) {
  return rev?.rejectionAt
}
export function isRevPending(rev) {
  return !isRevPublic(rev) && !isRevRejected(rev)
}
export function pendingRevs(incident) {
  return incident.updates.map(up => _.first(up.revisions)).filter(isRevPending)
}
export function publicRevs(incident) {
  return incident.updates.map(up => _.first(up.revisions)).filter(isRevPublic)
}
export function rejectedRevs(incident) {
  return incident.updates.map(up => _.first(up.revisions)).filter(isRevRejected)
}
export function isUpdateAccepted(up) {
  const revs = up.revisions
  if (revs.length === 1 && revs[0].authorMod) {
    return false
  }
  return isRevPublic(_.first(revs))
}
export function analyzeIncident(incident) {
  const pubRevs = publicRevs(incident)
  const pendRevs = pendingRevs(incident)
  const rejRevs = rejectedRevs(incident)
  const derivedFields = ["title", "location", "level"]
  const view = {
    id: incident.id,
    pubRevs,
    pendRevs,
    rejRevs,
  }
  derivedFields.forEach(
    field =>
      (view[field] = (pubRevs.find(u => u[field] !== undefined) ||
        pendRevs.find(u => u[field] !== undefined) ||
        rejRevs.find(u => u[field] !== undefined) ||
        {})[field]),
  )
  return view
}

export function incidentStatus(incident, userId) {
  return {
    incidentStatus: updateStatus(_.last(incident.updates), userId),
    updateStatus: updateStatus(_.first(incident.updates), userId),
    singleUpdate: incident.updates.length === 1,
    isPublic: isRevPublic(_.first(_.last(incident.updates)?.revisions)),
  }
}

function latestNonModRev(update) {
  for (let rev of update.revisions) {
    if (!rev.authorMod) {
      return rev
    }
  }
}
function findAuthorRev(update, userId) {
  return update?.revisions.find(r => r.authorId === userId)
}
export function updateStatus(update, userId) {
  if (!update) return {}

  let myRev
  if (userId) {
    myRev = findAuthorRev(update, userId)
  }
  const latestRev = _.first(update.revisions)
  let authorRev = myRev || latestNonModRev(update) || latestRev
  let status
  if (isRevPublic(latestRev)) {
    if (myRev && latestRev.authorId !== userId) {
      status = "approved-modified"
    } else if (myRev) {
      status = "approved"
    } else {
      status = "normal"
    }
  } else if (isRevRejected(latestRev)) {
    status = "rejected"
  } else {
    status = "pending"
  }
  return {
    author: authorRev.authorUsername,
    authorName: authorRev.authorName,
    authorThumb: authorRev.authorThumbnailURL,
    status,
    isAuthor: !!myRev,
    authorMod: authorRev.authorMod,
  }
}

export function latestRev(incident) {
  return _.first(_.first(incident?.updates)?.revisions)
}

export function latestOfficial(incident) {
  const { pubRevs, pendRevs, rejRevs } = analyzeIncident(incident)
  return _.first(pubRevs) || _.first(pendRevs) || _.first(rejRevs)
}
export function latestOfficialWithText(incident) {
  const { pubRevs, pendRevs, rejRevs } = analyzeIncident(incident)
  const fwText = revs => _.first(revs.filter(r => r.text))
  return fwText(pubRevs) || fwText(pendRevs) || fwText(rejRevs)
}

export function isPublic(incident) {
  const initUp = _.last(incident.updates)
  const initRev = _.first(initUp?.revisions)
  return isRevPublic(initRev)
}
export function isPublicOrAuthors(incident, userId) {
  const initUp = _.last(incident.updates)
  return isPublic(incident) || findAuthorRev(initUp, userId)
}

export function hasPending(incident) {
  return !isRejectedIncident(incident) && findUpdate(incident, isProposedUpdate)
}

export function isRejectedIncident(incident) {
  const initUp = _.last(incident.updates)
  return isRevRejected(_.first(initUp?.revisions))
}

export function isRelevantUpdate(update) {
  const rev = _.first(update.revisions)
  if (rev.text) {
    return (
      !rev.text.includes("is live.") &&
      !rev.text.includes("has stopped broadcasting.")
    )
  }
  return true
}

export function relatedClips(update) {
  let clips = []
  for (let rev of update.revisions) {
    if (rev.clips && rev.clips.length > 0) {
      clips = clips.concat(rev.clips)
    }
  }
  return clips
}

export function withinBounds(
  incident,
  { upperLatitude, upperLongitude, lowerLatitude, lowerLongitude },
) {
  const {
    location: { lat, lng },
  } = analyzeIncident(incident)
  return (
    lowerLatitude <= lat &&
    lat <= upperLatitude &&
    lowerLongitude <= lng &&
    lng <= upperLongitude
  )
}

export function initialRevId(incident) {
  // Ignore revs created from API
  const nonAPIUpdates = incident.updates.filter(
    up => !_.first(up.revisions).fromAPI,
  )
  if (nonAPIUpdates.length > 0) {
    return _.last(_.last(nonAPIUpdates).revisions).id
  }
  return null
}

export function invariantId(incident) {
  return initialRevId(incident) || incident.id
}

export function isLiveStreaming(inc) {
  const { liveStreamers } = inc
  if (liveStreamers) {
    for (let s in liveStreamers) {
      if (!liveStreamers[s].hlsDone) {
        return true
      }
    }
  }
  return false
}

export function sortIncidents(incidents, incidentSortBy) {
  incidents.sort((a, b) => {
    if (isHardFlagged(a) && !isHardFlagged(b)) {
      return -1
    }
    if (!isHardFlagged(a) && isHardFlagged(b)) {
      return 1
    }
    if (isSoftFlagged(a) && !isSoftFlagged(b)) {
      return -1
    }
    if (!isSoftFlagged(a) && isSoftFlagged(b)) {
      return 1
    }
    if (hasActiveExpansionSuggestion(a) && !hasActiveExpansionSuggestion(b)) {
      return -1
    }
    if (!hasActiveExpansionSuggestion(a) && hasActiveExpansionSuggestion(b)) {
      return 1
    }

    if (hasPending(a) && !hasPending(b)) {
      return -1
    }
    if (!hasPending(a) && hasPending(b)) {
      return 1
    }
    if (isLiveStreaming(a) && !isLiveStreaming(b)) {
      return -1
    }
    if (isLiveStreaming(b) && !isLiveStreaming(a)) {
      return 1
    }

    if (incidentSortBy === "emojis") {
      const bTotal =
        b.stats.angers + b.stats.whoas + b.stats.thanks + b.stats.hearts
      const aTotal =
        a.stats.angers + a.stats.whoas + a.stats.thanks + a.stats.hearts

      return bTotal - aTotal
    }

    if (incidentSortBy === "flaggedAt") {
      if (a.flaggedAt && b.flaggedAt) {
        return new Date(b.flaggedAt).getTime() - new Date(a.flaggedAt).getTime()
      } else if (a.flaggedAt) {
        return -1
      } else if (b.flaggedAt) {
        return 1
      } else {
        return 0
      }
    }

    if (incidentSortBy !== "ts") {
      // for now everything is in incident.stats so we don't need to do anything special
      return b.stats[incidentSortBy] - a.stats[incidentSortBy]
    }

    const latestRevA = latestRev(a)
    const latestRevB = latestRev(b)
    if (!latestRevA || !latestRevB) {
      return (latestRevA == null) - (latestRevB == null)
    }

    return (
      new Date(latestRevB.occurredAt).getTime() -
      new Date(latestRevA.occurredAt).getTime()
    )
  })
}
export function isProposedUpdate(up) {
  const rev = _.first(up?.revisions)
  return isRevPending(rev)
}
export function findUpdate(inc, pred) {
  for (let up of inc.updates) {
    if (pred(up)) {
      return up
    }
  }
}
export function findRev(inc, pred) {
  for (let up of inc.updates) {
    for (let rev of up.revisions) {
      if (pred(rev)) {
        return rev
      }
    }
  }
}

export function latestRevWithClips(inc) {
  return findRev(inc, r => !_.isEmpty(r.clips))
}

export function latestRevWithText(inc) {
  return findRev(inc, r => !!r.text)
}

export function isTextDeleted(up) {
  const revs = up.revisions
  return revs.length > 1 && !revs[0].text && revs[1].text
}

export function isPublicAndSynced(inc) {
  return isPublic(inc) && inc.id.length < 36
}
export function isUpdatePublicAndSynced(up) {
  return up.id.length < 36
}

export function revAge(rev) {
  return Date.now() - new Date(rev.occurredAt).getTime()
}

export function getCreatedAt(incident) {
  return _.last(_.last(incident.updates).revisions).createdAt
}

/**
 *
 * @param {import('@guardian/Types/Incident').EnrichedIncident2} incident
 * @returns {"expansion" | "softFlag" | "hardFlag" | null}
 */
export function getIncidentNotifFlagType(incident) {
  const notifExpansion = incident.notifExpansion || null
  if (!notifExpansion) {
    return null
  }
  // legacy expansion flag
  if (!notifExpansion.autoExpansionID && notifExpansion.shouldExpand) {
    return 'expansion'
  }
  // soft / hard flag
  const flagTypeMap = {
    soft: 'softFlag',
    hard: 'hardFlag',
  }
  return flagTypeMap[notifExpansion.isFlagged] || null
}

export function hasActiveExpansionSuggestion(incident) {
  return getIncidentNotifFlagType(incident) === "expansion"
}

export function isSoftFlagged(incident) {
  return getIncidentNotifFlagType(incident) === "softFlag"
}

export function isHardFlagged(incident) {
  return getIncidentNotifFlagType(incident) === "hardFlag"
}

export function getAssociatedIncidents(username, incidents) {
  const inc = []
  incidents.forEach(incident =>
    incident.updates.forEach(update =>
      update.revisions.forEach(revision =>
        revision.clips.forEach(clip => {
          if (clip.flaggers.indexOf(username) !== -1) {
            inc.push({
              ...incident,
              time: clip.time,
              type: "incident",
              clipId: clip.id,
            })
          }
        }),
      ),
    ),
  )

  return inc
}

export function getFlaggers(username, incident) {
  let flaggers = []
  incident.updates.forEach(update =>
    update.revisions
      .filter(r => !!r.clips)
      .forEach(revision =>
        revision.clips.forEach(clip => {
          flaggers = flaggers.concat(clip.flaggers)
        }),
      ),
  )

  return {
    flaggers: _.uniq(flaggers).filter(f => f !== username),
    userFlagged: flaggers.indexOf(username) > -1,
  }
}

export function getClips(update) {
  return _.flatten(update.revisions.map(r => r.clips))
}

export function hasAuthor(incident, name) {
  return incident.updates.some(up =>
    up.revisions.some(
      rev =>
        rev.authorUsername.toLowerCase() === name.toLowerCase() ||
        rev.authorName.toLowerCase() === name.toLowerCase(),
    ),
  )
}

export function getAllAuthors(inc) {
  const authors = {}
  for (let up of inc.updates) {
    for (let rev of up.revisions) {
      authors[rev.authorUsername] = true
      authors[rev.authorName] = true
    }
  }
  return Object.keys(authors)
}

export function getAllClips(inc) {
  return _.concat(...inc.updates.map(up => getClips(up)))
}

const notifTargetingTypes = ["UserRadius", "Geography", "ReplyAll"]

export function incidentNotifsToCtr(notifs) {
  const ctrs = notifs.map(n =>
    notifTargetingTypes.includes(n.targeting_type) ? n.ctr : 0.0,
  )
  ctrs.push(0.0) // Hack to make sure math.max doesnt return -infinity when no arguments
  return Math.max(...ctrs)
}

/**
 * Legacy function to check if a notification is a custom notification for the expansion model.
 */
export function isCustomRadiusNotif(notif) {
  return (
    notif.targeting_type == "UserRadius" &&
    (notif.trigger_type == "Incident Upgraded to L2" ||
      notif.trigger_type == "live_video_verified_default" ||
      notif.trigger_type == "Geography")
  )
}

/**
 * Check if a notification is a custom notification for a Magic Sauce Expansion.
 * Targeting type is UserRadius OR MagicSauce and the notif has a transcriber.
 */
export function isCustomNotifMS(notif) {
  return (
    ["UserRadius", "MagicSauce"].includes(notif.targeting_type) &&
    !!notif?.transcriber?.transcriber_id
  )
}

export function isP2PCommunityApprovedIncident(incident) {
  const updatesLength = incident?.updates?.length
  if (updatesLength <= 1) {
    return false
  }

  const isP2P = incident.updates.slice(-2).every(update => {
    const author = updateStatus(update)?.author
    const source = _.first(update?.revisions)?.source
    return author === consts.AutomatedUsername && source === "igl"
  })

  const hasVideo = incident.liveStreamers?.length > 0
  return incident?.unverifiedIGLIncident === false && isP2P && hasVideo
}

export function isAutoGeneratedIncident(incident) {
  const updatesLength = incident?.updates?.length
  if (!updatesLength) {
    return false
  }

  const latestUpdate = incident.updates[updatesLength - 1]
  const author = updateStatus(latestUpdate)?.author
  const source = _.first(latestUpdate?.revisions)?.source
  return author === consts.AutomatedUsername && source === "radio"
}

export function getIncidentAuthor(incident) {
  if (!incident) {
    return
  }
  const { updateStatus: status } = incidentStatus(incident)
  return status?.author
}

export function getIncidentThumbnailUrl(incidentId) {
  if (!incidentId) {
    return
  }
  const timestamp = new Date().getTime()
  const attachmentBucket = config.production ? "citizen-notif-attachment" : "citizen-staging-notif-attachment"
  return `https://storage.googleapis.com/${attachmentBucket}/${incidentId}-landscape.png?rnd=${timestamp}`
}

export function getIncidentWebUrl(incidentId) {
  if (!incidentId) {
    return
  }
  const domain = config.production ? "citizen.com" : "staging.sp0n.io"
  return `https://${domain}/${incidentId}`
}
