import PropTypes from "prop-types"
import React from "react"

import { eventHub } from "@guardian/State"

const ClipContentMask = ({ on }) => (
  <div
    className={on ? "clip-pane-mask" : ""}
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()
      eventHub.emit("closePopups")
    }}
  />
)

ClipContentMask.propTypes = {
  on: PropTypes.bool,
}

export default ClipContentMask
