import React, { useEffect, useRef, useState } from "react";

import { LoggingService } from "@guardian/Services/Logging";
import { Flash } from "@guardian/UI/Alert";
import { Tooltip } from "@guardian/UI/Tooltip";
import { XLargeText } from "@guardian/UI/Typography";
import { copyToClipboard } from "@guardian/Utils/Window";

import IconButton from "./views/IconButton";
import LinkIcon from "./views/LinkIcon";
import StyledUserName from "./views/UserName";

const UserName = props => {
  const { id, fullName } = props;

  const [copyTrigger, setCopyTrigger] = useState(null);

  const idRef = useRef(id);

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  useEffect(() => {
    if (copyTrigger == null) { return; }

    const id = idRef.current;

    const handleCopied = () => {
      Flash.generic(
        `The user's ID was copied to your clipboard.`,
        {
          title: "Copied"
        }
      );
    };

    const handleFailedToCopy = () => {
      LoggingService.logError(
        "Unable to copy user ID.",
        {
          domain: "AgentChat",
          method: "UserInfo",
          flash: true,
          trackedData: {
            userId: id
          }
        }
      );
    };

    if (id == null) {
      handleFailedToCopy();
      return;
    }

    const copy = () => {
      if (copyToClipboard(id)) {
        handleCopied();
      } else {
        handleFailedToCopy();
      }
    };

    copy();
  }, [copyTrigger]);

  const handleCopy = () => {
    setCopyTrigger(+new Date());
  };

  return (
    <StyledUserName>
      <XLargeText as="span">
        { fullName }
      </XLargeText>
      <Tooltip content="Copy user ID">
        <IconButton onClick={handleCopy}>
          <LinkIcon/>
        </IconButton>
      </Tooltip>
    </StyledUserName>
  );
};

export default UserName;
