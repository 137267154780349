import Color from "color";

import colors from "./colors";

const shadows = [
  `0 0.0625rem 0.1875rem ${ Color(colors.grey095).alpha(0.06) }, 0 0.0625rem 0.125rem ${ Color(colors.grey095).alpha(0.11) }`,
  `0 0.1875rem 0.375rem ${ Color(colors.grey095).alpha(0.07) }, 0 0.1875rem 0.375rem ${ Color(colors.grey095).alpha(0.105) }`,
  `0 0.75rem 1.125rem ${ Color(colors.grey095).alpha(0.08) }, 0 0.375rem 0.375rem ${ Color(colors.grey095).alpha(0.1) }`,
  `0 0.875rem 1.75rem ${ Color(colors.grey095).alpha(0.09) }, 0 0.75rem 0.75rem ${ Color(colors.grey095).alpha(0.085) }`,
  `0 1.125rem 2.25rem ${ Color(colors.grey095).alpha(0.1) }, 0 1rem 0.75rem ${ Color(colors.grey095).alpha(0.09) }`
];

// Aliases
shadows.xSmall = shadows[0];
shadows.small = shadows[1];
shadows.medium = shadows[2];
shadows.large = shadows[3];
shadows.xLarge = shadows[4];

export const shadowsCSSVariables = `
  :root {
    --shadows-x-small: ${ shadows.xSmall };
    --shadows-small: ${ shadows.small };
    --shadows-medium: ${ shadows.medium };
    --shadows-large: ${ shadows.large };
    --shadows-xLarge: ${ shadows.xLarge };
  }
`;

export default shadows;
