import React, { useState, useEffect } from "react"
import { Observable, timer, Subscription } from "rxjs"

interface InitializerProps {
  children: any
  initializer: () => Observable<any>
  onSuccess: (data: any) => void
  onError?: (err: any) => void
  onFetching?: () => void
  interval?: number
  getInitializer?: () => Observable<any>
  ignoreLoading?: boolean
}
const Initializer = ({
  children,
  initializer,
  onSuccess,
  onError,
  interval,
  onFetching,
  getInitializer,
  ignoreLoading,
}: InitializerProps) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    let subscription: Subscription
    const init = () =>
      (getInitializer ? getInitializer() : initializer()).subscribe(
        data => {
          if (!ignoreLoading || loading) {
            setLoading(false)
          }

          onSuccess(data)
        },
        err => {
          if (!ignoreLoading || loading) {
            setLoading(false)
          }

          onError && onError(err)
        },
      )
    if (interval) {
      subscription = timer(0, interval).subscribe(() => {
        onFetching && onFetching()
        init()
      })
    } else {
      subscription = init()
    }
    return () => subscription.unsubscribe()
  }, [getInitializer, onSuccess, interval, onError, onFetching, initializer])

  if (loading) {
    return <React.Fragment />
  }

  return children
}

export default Initializer
