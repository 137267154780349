import React, { useEffect, useRef, useState } from "react";

import { AgentChatService } from "@guardian/Services/AgentChat";
import { Flash } from "@guardian/UI/Alert";

import OutboundMessagingModal from "./components/OutboundMessagingModal";
import StyledOutboundMessagingButton from "./views/OutboundMessagingButton";

const OutboundMessagingButton = props => {
  const [ids, setIds] = useState([]);
  const [message, setMessage] = useState("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitTrigger, setSubmitTrigger] = useState(null);

  const idsRef = useRef(ids);
  const messageRef = useRef(message);

  useEffect(() => {
    idsRef.current = ids;
    messageRef.current = message;

    const disableSubmit = ids.length === 0 || message.length === 0;
    setSubmitDisabled(disableSubmit);
  }, [ids, message]);

  useEffect(() => {
    if (submitTrigger == null) {
      return;
    }

    const ids = idsRef.current;
    const message = messageRef.current;

    const controller = new AbortController();
    const signal = controller.signal;

    let artificialDelay;

    const submit = () => {
      // There may be cases where there are extra empty items in the ids array,
      // which is a side effect of the modal's input currently keeping empty
      // items at the end of the ids input array to support comma separation.
      // It's not the most ideal situaton but works.
      const formattedIds = ids.filter(id => id.length > 0);

      const data = {
        userIds: formattedIds,
        text: message
      };

      const options = { signal };

      setError(null);
      setLoading(true);
      setLocked(true);

      artificialDelay = setTimeout(() => {
        AgentChatService.sendOutboundMessageToUsers(data, options)
          .then(() => {
            setModalOpen(false);
            setLoading(false);
          })
          .catch(error => {
            if (signal.aborted) {
              return;
            }

            setError(error);
            setLoading(false);
            setLocked(false);
          });
      }, 250);
    };

    submit();

    return () => {
      clearTimeout(artificialDelay);
      controller.abort();
    }
  }, [submitTrigger]);

  useEffect(() => {
    if (error == null) {
      return;
    }

    console.error({
      message: "AgentChat: an error occured while sending outbound DMs",
      error: error
    });

    Flash.error(
      "Something went wrong while sending outbound DMs.",
      {
        title: "Error"
      }
    );
  }, [error]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    setSubmitTrigger(+new Date());
  };

  const handleExit = () => {
    setIds([]);
    setMessage("");

    setError(null);
    setLoading(false);
    setLocked(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleIdsChange = (ids) => {
    setIds(ids);
  };

  const handleMessageChange = (message) => {
    setMessage(message);
  };

  return (
    <React.Fragment>
      <StyledOutboundMessagingButton onClick={handleOpen}>
        Send Outbound Messages
      </StyledOutboundMessagingButton>
      <OutboundMessagingModal
        inputIds={ids}
        inputMessage={message}
        locked={locked}
        loading={loading}
        onInputIdsChange={handleIdsChange}
        onInputMessageChange={handleMessageChange}
        onClose={handleClose}
        onExit={handleExit}
        onSubmit={handleSubmit}
        open={modalOpen}
        submitDisabled={submitDisabled}
      />
    </React.Fragment>
  );
};

export default OutboundMessagingButton;
