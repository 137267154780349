import { OptimusAPI } from "@guardian/API/Optimus"
import dayjs from 'dayjs'

interface DirectMessagesSendGroupDMMessageData {
  id: string
  groupId: string
  body: { [key: string]: any }
}

export interface IDirectMessage {
  id: string
  groupId: string
  authorId: string
  body: {
    type: string | null
    text: string
    userIds: string[]
  }
  readBy: unknown
  createdAt: string
}

export interface IGetGroupDMsResponse {
  history: IDirectMessage[]
}

const DirectMessages = {
  getGroupDMs: (groupId: string, options: any = {}, hoursAgo = 48) => {
    const at48HoursAgo = dayjs().subtract(hoursAgo, "hours").unix()

    return OptimusAPI.get(`/direct_message/group/${groupId}?after=${at48HoursAgo}`, {
      ...options,
      transformResponse: (data: string) => {
        const parsedData: IGetGroupDMsResponse = JSON.parse(data)

        parsedData.history = parsedData.history.filter(
          message => {
            const isUnwantedMessage = message.authorId !== 'system' && Boolean(message.body.type)

            return !isUnwantedMessage
          },
        )

        return parsedData
      },
    })
  },

  getGroupDMInfo: (groupId: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/users/group/${ groupId }/info`, options);
  },

  sendGroupDMMessage: (
    data: DirectMessagesSendGroupDMMessageData,
    options?: any
  ) => {
    return OptimusAPI.post(`/direct_message/group`, data, options);
  }
};

export default DirectMessages;
