import React from "react";
import PropTypes from "prop-types";

import useTabsContext from "../hooks/useTabsContext";

import StyledTabPanel from "../views/TabPanel";

const Tab = props => {
  const {
    children,
    tab
  } = props;

  const {
    selectedTab
  } = useTabsContext();

  const isActive = selectedTab === tab;

  if (!isActive) {
    return null;
  }

  return (
    <StyledTabPanel>
      { children }
    </StyledTabPanel>
  );
};

Tab.propTypes = {
  tab: PropTypes.string
};

export default Tab;
