import classnames from "classnames"
import React, { MouseEvent, PureComponent } from "react"

import { eventHub } from "@guardian/State"
import type { Clip as ClipType } from "@guardian/Types/Clip"

import ClipPlayer from "@guardian/Components/ClipPlayer/ClipPlayer"
import Sound from "@guardian/Components/Sound"

import "./Clip.scss"
import ClipPriority from "./ClipPriority"
import ClipTimestamp from "./ClipTimestamp"
import Transcription from "./Transcription"

interface Permissions {
  airwavesAccess: boolean
  allowedSendGlobals: boolean
  allowedPilotProgram: boolean
  citMod: boolean
  flag: boolean
  lafd: boolean
  manualClipAdvancement: boolean
  mod: boolean
  radioDesk: boolean
  safetyDesk: boolean
  canArchive: boolean
  sosMod: boolean
  trainee: boolean
  videoDesk: boolean
}

interface ClipProps
  extends Pick<
    ClipType,
    | "id"
    | "durationMs"
    | "played"
    | "spectrogram"
    | "time"
    | "clipListenStatus"
    | "metadata"
  > {
  wavUrl: ClipType["wav_url"]
  isolateOn: boolean
  dragging: string // This is a string somehow...
  permissions: Permissions
  user: object
  attached: boolean
  channelHeader: string
  activeClipId: string
  activeClipLoop: boolean
  activeClipStatus: string
  activeClipAnimateStatus: boolean
  serviceArea: string
  subArea: string
  speed: number
  metapod: boolean
  isFlagged: boolean
  isUpdateClip?: boolean
  isMedicalIncident: boolean
  hideAttachmentControls: boolean
  pin: boolean
  updateActiveClip: Function
  elementId?: string
}

class Clip extends PureComponent<ClipProps> {
  handleFinishedPlaying = () => {
    const { permissions } = this.props
    if (this.props.activeClipLoop || permissions.manualClipAdvancement) {
      eventHub.emit("setActiveClipPlayed")
      return eventHub.emit("setActiveClip", {
        status: permissions.manualClipAdvancement
          ? Sound.status.PAUSED
          : Sound.status.PLAYING,
        position: 0,
      })
    }

    eventHub.emit("nextClip", { scrollOnlyIfVis: true })
  }

  onDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData(
      "clip",
      JSON.stringify({
        id: this.props.id,
        channelHeader: this.props.channelHeader,
        time: this.props.time,
        serviceArea: this.props.serviceArea,
        durationMs: this.props.durationMs,
      }),
    )
    // @ts-ignore
    e.target.classList.add("dragged")
    eventHub.emit("dragStart", this.props.id)
  }

  onDragEnd = (e: React.DragEvent) => {
    eventHub.emit("dragEnd")
    // @ts-ignore
    e.target.classList.remove("dragged")
  }

  onContextMenu = (e: MouseEvent) => {
    eventHub.emit("closePopups")
    e.preventDefault()
    const { clientX, clientY } = e
    eventHub.emit("openClipContextMenu", {
      id: this.props.id,
      left: clientX,
      top: clientY,
    })
  }

  togglePlay = () => {
    const { activeClipId, id, activeClipStatus, updateActiveClip } = this.props
    let status = Sound.status.PLAYING
    if (activeClipId === id && activeClipStatus === Sound.status.PLAYING) {
      status = Sound.status.PAUSED
    }
    const activeClip = {
      id: this.props.id,
      status,
      animateStatus: true,
    }
    updateActiveClip(activeClip)
  }

  render() {
    const {
      id,
      elementId,
      durationMs,
      played,
      time,
      spectrogram,
      wavUrl,
      speed,
      user,
      activeClipId,
      activeClipStatus,
      activeClipAnimateStatus,
      isolateOn,
      dragging,
      permissions,
      attached,
      metapod,
      isFlagged,
      isUpdateClip,
      isMedicalIncident,
      hideAttachmentControls,
      updateActiveClip,
      pin,
      clipListenStatus,
      metadata,
    } = this.props
    const beingDragged = dragging === id
    const duration = durationMs / 1000

    const personalPinBorderStatus = pin ? "pin" : ""
    const multiplayerPinBorderStatus =
      clipListenStatus === "new"
        ? "pin-new"
        : clipListenStatus === "update"
          ? "pin-update"
          : ""
    const borderColor = multiplayerPinBorderStatus || personalPinBorderStatus

    return (
      <div
        id={elementId || id}
        className={classnames("clip-container", borderColor, {
          "active-clip": id === activeClipId,
          "played-clip": played,
          attached,
          dragged: beingDragged,
          "iso-on": isolateOn,
          dismissed: metapod && played,
          flagged: metapod && isFlagged,
          "update-clip": metapod && isUpdateClip,
        })}
        draggable={!hideAttachmentControls}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onContextMenu={this.onContextMenu}
      >
        <div className='clip-container-top' onClick={this.togglePlay}>
          <div className='clip-content'>
            <ClipPlayer
              id={id}
              attached={attached}
              activeClipId={activeClipId}
              activeClipStatus={activeClipStatus}
              activeClipAnimateStatus={activeClipAnimateStatus}
              wavUrl={wavUrl}
              durationMs={durationMs}
              spectrogram={spectrogram}
              speed={speed}
              hasUser={!!user}
              updateActiveClip={updateActiveClip}
              permissions={permissions}
              played={played}
              handleFinishedPlaying={this.handleFinishedPlaying}
              attachControl={!metapod && !hideAttachmentControls}
              className={classnames({ "metapod-clip-player": metapod })}
            />
            <div className='clip-info full-width'>
              <div className='clip-info__col'>
                <ClipPriority
                  raw={metadata?.raw}
                  isMedicalIncident={isMedicalIncident}
                />
                <div className='clip-channel'>
                  <div className='clip-channel-header'>
                    <span className='clip-channel-name'>
                      {this.props.channelHeader}
                    </span>
                    <span className='clip-channel-subheader'>
                      {this.props.subArea}
                    </span>
                  </div>
                </div>
              </div>
              <div className='clip-info__col'>
                <div className='clip-time-info'>
                  <div className='clip-duration'>{duration.toFixed(1)}s</div>
                  <ClipTimestamp
                    time={time}
                    played={played}
                    citMod={permissions.citMod}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Transcription
          clipId={id}
          raw={metadata?.raw}
          updateActiveClip={updateActiveClip}
        />
      </div>
    )
  }
}

export default Clip
