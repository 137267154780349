import React, { useCallback, useEffect, useState } from "react"
import _ from "lodash"
import styles from "@guardian/Components/ModSOS/components/Map/SOSMap/SOSMap.module.css"
import classnames from "classnames"
import { IPropsV1, ILocation, INearbyIncident, ISafetyPOI } from '@guardian/Components/ModSOS/components/Map/SOSMap/types';

export const useSOSMapV1 = ({
  updateMapStyles,
  activeSessionId,
  isV2Protect,
  activePOI,
  updateAddress,
  updateLocation,
  updateActiveIncident,
  updateActivePOI,
  updateHoveredPOI,
  hoveredPOI,
  location,
}: IPropsV1) => {
  const [data, setData] = useState<any>()

  const [markers, setMarkers] = useState<Array<any>>([])

  const buildLocationMarker: (loc: ILocation) => any = loc => {
    return {
      longitude: loc.longitude,
      latitude: loc.latitude,
      body: (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${loc.latitude},${loc.longitude}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className={styles.marker} />
        </a>
      ),
    }
  }

  const buildIncidentMarkers: (
    nearbyIncidents: INearbyIncident[],
  ) => any = nearbyIncidents => {
    return _.map(nearbyIncidents, incident => {
      const classMap = {
        yellow: styles.yellow,
        red: styles.red,
      }
      return {
        longitude: incident.longitude,
        latitude: incident.latitude,
        body: (
          <div
            className={`${styles.incidentMarker} ${
              classMap[incident.severity]
            }`}
            onClick={() => updateActiveIncident(incident)}
          />
        ),
      }
    })
  }

  const buildPOIMarkers: (pois: ISafetyPOI[]) => any = pois => {
    return _.map(pois, (poi, i) => {
      return {
        key: poi.name,
        id: poi.id,
        longitude: poi.longitude,
        latitude: poi.latitude,
        body: (
          <div
            className={classnames(styles.poiMarker, {
              [styles.selected]:
                (activePOI && activePOI === poi.id) ||
                (hoveredPOI && hoveredPOI === poi.id),
              [styles.dim]: !!hoveredPOI && hoveredPOI !== poi.id,
            })}
            onMouseEnter={() => {
              updateHoveredPOI(poi.id)
            }}
            onMouseLeave={() => {
              updateHoveredPOI(undefined)
            }}
            onClick={() => {
              if (activePOI === poi.id) {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${poi.name}%20${poi.address}`,
                  "_blank",
                )
              }
              updateActivePOI(poi.id)
            }}
          >
            {(activePOI === poi.id || hoveredPOI === poi.id) && (
              <p>{poi.name}</p>
            )}
          </div>
        ),
      }
    })
  }

  const updateMap = (data: any) => {
    const locationData = data[0].data
    const lastLocIndex =
      _.get(
        locationData,
        "records.length",
        _.get(locationData, "locations.length", -1),
      ) - 1
    if (lastLocIndex < 0) return
    let loc: ILocation
    if (isV2Protect) {
      loc = locationData.records[0].location
    } else {
      loc = locationData.locations[lastLocIndex]
    }
    const newLocation: ILocation = {
      longitude: loc.longitude,
      latitude: loc.latitude,
      addressSpecific: loc.addressSpecific,
      addressGeneral: loc.addressGeneral,
    }

    let nearbyIncidentMarks = []
    if (data.length > 1) {
      nearbyIncidentMarks = buildIncidentMarkers(
        data[1].data.nearbyIncidents.filter(
          (i: INearbyIncident) => !i.isAreaAlert,
        ),
      )
    }
    let poiMarkers = []
    if (data.length > 2 && !isV2Protect) {
      poiMarkers = buildPOIMarkers(data[2].data.pois)
    } else if (data.length > 1 && isV2Protect) {
      poiMarkers = buildPOIMarkers(data[1].data.pois)
    }

    setMarkers([
      buildLocationMarker(newLocation),
      ...nearbyIncidentMarks,
      ...poiMarkers,
    ])

    updateAddress(
      `${newLocation.addressSpecific}, ${newLocation.addressGeneral}`,
    )
    updateLocation(newLocation)
    updateMapStyles((prevStyle: any) => {
      if (_.isEmpty(prevStyle)) return prevStyle
      // react-map-gl library is INCREDIBLY finicky and relies on recursive deep comparison to rerender
      const prevLineData = [
        ...prevStyle.sources["wmb-route"].data.geometry.coordinates,
      ]
      prevLineData.push([location.longitude, location.latitude])
      const newSource = _.cloneDeep(prevStyle.sources["wmb-route"])
      newSource.data.geometry.coordinates = prevLineData
      prevStyle.sources["wmb-route"] = newSource
      return { ...prevStyle }
    })
  }

  useEffect(() => {
    if (data) {
      updateMap(data)
    }
  }, [activePOI, hoveredPOI, data, isV2Protect, activeSessionId])

  const onSuccessV1 = useCallback(
    (data: any) => {
      if (!data) {
        return
      }

      const buildPOIId = (pois: any[]) =>
        pois.map((poi: any) => ({
          ...poi,
          id: `${poi.name}-${poi.latitude}-${poi.longitude}-${poi.address}`,
        }))

      if (data.length > 2 && !isV2Protect) {
        data[2].data.pois = buildPOIId(data[2].data.pois)
      }

      setData(data)
    },
    [isV2Protect],
  )

  return {
    onSuccessV1,
    markers,
  }
}
