import React, { memo } from "react"

import { useGlobalState } from "@guardian/State"
import type { Clip } from "@guardian/Types/Clip"

import Sound from "@guardian/Components/Sound"

import PlayBar from "./PlayBar"

interface ClipPlayerProps
  extends Pick<Clip, "id" | "durationMs" | "played" | "spectrogram"> {
  wavUrl: Clip["wav_url"]
  attached: boolean
  activeClipId: string
  activeClipStatus: string
  activeClipAnimateStatus: boolean
  attachControl: boolean
  handleFinishedPlaying: (...args: any[]) => any
  speed: number
  hasUser: boolean
  updateActiveClip: Function
  permissions: any
  className: string
}

const ClipPlayer: React.FC<ClipPlayerProps> = ({
  attached,
  activeClipId,
  activeClipStatus,
  activeClipAnimateStatus,
  id,
  wavUrl,
  durationMs,
  spectrogram,
  attachControl,
  handleFinishedPlaying,
  speed,
  hasUser,
  permissions,
  played,
  className,
  updateActiveClip,
}) => {
  const position = useGlobalState(state => {
    const { position } = state.radio.activeClip
    if (id === state.radio.activeClip.id) {
      return position
    }
    return 0
  })

  const handlePlaying = ({ position }: { position: number }) => {
    updateActiveClip({ id, position })
  }

  const seek = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!e.currentTarget) {
      return
    }

    const clientX = e.clientX
    const target = e.currentTarget
    const rect = target.getBoundingClientRect()
    const offsetX = clientX - rect.left
    const position = (offsetX / target.scrollWidth) * durationMs
    updateActiveClip({
      id,
      position,
      status: Sound.status.PLAYING,
    })
  }

  const status = activeClipId === id ? activeClipStatus : Sound.status.STOPPED
  const showAttach = hasUser && attachControl

  return (
    <PlayBar
      className={className}
      id={id}
      showUpArrow={
        permissions.manualClipAdvancement &&
        activeClipId === id &&
        showAttach &&
        played
      }
      showAttach={
        permissions.manualClipAdvancement
          ? activeClipId === id && showAttach
          : showAttach
      }
      attachOn={!!attached}
      status={status}
      activeClipId={activeClipId}
      activeClipStatus={activeClipStatus}
      activeClipAnimateStatus={activeClipAnimateStatus}
      position={position}
      speed={speed}
      wavUrl={wavUrl}
      durationMs={durationMs}
      spectrogram={spectrogram}
      handlePlaying={handlePlaying}
      handleFinishedPlaying={handleFinishedPlaying}
      seek={seek}
    />
  )
}

export default memo(ClipPlayer)
