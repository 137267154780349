import _ from "lodash"
import { IStat } from "@guardian/Components/ModSOS/store/dashboard/selectors/useLatestRecordStats/types"
import { useLatestRecord } from "@guardian/Components/ModSOS/store/dashboard/selectors/useLatestRecord"

export const useLatestRecordStats = (): IStat | undefined => {
  const latestRecord = useLatestRecord()

  const getBatteryLevel = () => {
    const level = latestRecord?.batteryLevel || 0
    const value = parseFloat(`${level * 100}`).toFixed(0)

    return `${value} %`
  }

  const getSpeed = () => {
    const speed = latestRecord?.location.speed || 0

    if (speed < 0) {
      return "--"
    }

    return parseFloat(`${speed * 2.237}`).toFixed(1)
  }

  const getHeartRate = () => {
    const beats = latestRecord?.heartRate

    if (!beats) {
      return "--"
    }

    return _.round(beats, 2).toString()
  }

  if (!latestRecord) {
    return undefined
  }

  return {
    batteryLevel: getBatteryLevel(),
    speed: getSpeed(),
    heartRate: getHeartRate(),
  }
}
