import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

export const WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold"
};

const Text = styled.div.attrs(
  props => {
    const {
      noHighlight,
      truncate,
      weight
    } = props;

    return {
      className: classNames("text", {
          "text--no-highlight": noHighlight,
          "text--truncated": truncate,

          "text--weight-bold": weight === WEIGHTS.BOLD,
        })
    };
  }
)`
  font: inherit;
  font-family: inherit;
  font-weight: var(--font-weights-normal);
  letter-spacing: normal;

  &.text--no-highlight {
    user-select: none;
  }

  &.text--truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.text--weight-bold {
    font-weight: var(--font-weights-bold);
  }
`;

Text.propTypes = {
  noHighlight: PropTypes.bool,
  truncate: PropTypes.bool,
  weight: PropTypes.oneOf(
    [
      WEIGHTS.NORMAL,
      WEIGHTS.BOLD
    ]
  )
};

Text.defaultProps = {
  noHighlight: false,
  truncate: false,
  weight: WEIGHTS.NORMAL
};

export default Text;
