import classNames from "classnames";
import styled from "styled-components";

const UserMessageGroup = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__user-message-group"
      )
    }
  }
)`
  --user-message-min-height: calc(
    var(--line-heights-medium) + (var(--space-x-small) * 2.0)
  );

  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--space-large) var(--space-x-large);
`;

export default UserMessageGroup;
