import React from "react"
import { IProps } from "@guardian/Components/ModSOS/components/Map/CloseIcon/types"

export const CloseIcon = ({ fill = "#fff", size = 12 }: IProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.2928 0.292893C10.6833 -0.0976311 11.3165 -0.0976311 11.707 0.292893C12.0975 0.683417 12.0975 1.31658 11.707 1.70711L7.41403 6.00006L11.7069 10.2929C12.0974 10.6834 12.0974 11.3166 11.7069 11.7071C11.3163 12.0976 10.6832 12.0976 10.2927 11.7071L5.99982 7.41427L1.70698 11.7071C1.31646 12.0976 0.683295 12.0976 0.292771 11.7071C-0.097754 11.3166 -0.097754 10.6834 0.292771 10.2929L4.5856 6.00006L0.292649 1.70711C-0.0978752 1.31658 -0.0978752 0.683418 0.292649 0.292893C0.683173 -0.0976311 1.31634 -0.0976311 1.70686 0.292893L5.99982 4.58585L10.2928 0.292893Z'
      fill={fill}
    />
  </svg>
)
