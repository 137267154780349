import React from "react";

import ChatArea from "./components/ChatArea";
import InputArea from "./components/InputArea";
import StyledChatTab from "./views/ChatTab";

const ChatTab = props => {
  const { session } = props;

  return (
    <StyledChatTab>
      <ChatArea session={session}/>
      <InputArea session={session}/>
    </StyledChatTab>
  );
};

export default ChatTab;
