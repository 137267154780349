import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import React from "react"
import { SOSNote } from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNote/SOSNote"
import { useSOSNotesPane } from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNotesPane/SOSNotesPane.hooks"
import styles from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNotesPane/SOSNotesPane.module.css"
import { INote } from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNotesPane/types"
import { IProps } from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNote/types"

export const SOSNotesPane: React.FunctionComponent<IProps> = ({ activeSessionId }) => {
  const { notes, onTextAreaChange, onSaveNote, currentNote } = useSOSNotesPane(
    activeSessionId,
  )

  return (
    <Container
      left={4}
      right={4}
      top={4}
      className={styles.container}
      column
      fullHeight
      topAlign
    >
      <Container fullWidth column rightJustify bottom={4}>
        <textarea
          className={styles.noteArea}
          placeholder='Enter text'
          onChange={onTextAreaChange}
          value={currentNote}
        />
        <Spacing top={2}>
          <button onClick={onSaveNote}>
            <Container className={styles.button} left={3} right={3}>
              Save note
            </Container>
          </button>
        </Spacing>
      </Container>
      <Container column leftJustify fullWidth>
        {notes.map((note: INote) => {
          return <SOSNote key={`${note.agentId}-${note.createdAt}`} {...note} />
        })}
      </Container>
    </Container>
  )
}
