import styled from "styled-components";

const Empty = styled.div`
  align-items: center;
  background: var(--colors-grey-085);
  color: var(--colors-grey-070);
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export default Empty;
