import { OptimusAPI } from "@guardian/API/Optimus"

export type AuditIncidentKey = {
  clipId: string
  incidentId: string
}

export type AuditIncidentAddress = AuditIncidentKey & {
  isIncorrect: boolean
  auditedBy: string
  createdAt: string
  updatedAt: string
}

export type AuditIncidentTitleAndUpdate = AuditIncidentKey & {
  isInaccurate: boolean
  isPrivacyViolated: boolean
  isBadCopy: boolean
  isNotReportable: boolean
  auditedBy: string
  createdAt: string
  updatedAt: string
}

const AuditIncident = {
  batchSyncAddress: (items: AuditIncidentKey[], options: any = {}) => {
    return OptimusAPI.post(
      "/v1/audit_incident/address/batch",
      { items },
      options,
    )
  },

  batchSyncTitleAndUpdate: (items: AuditIncidentKey[], options: any = {}) => {
    return OptimusAPI.post(
      "/v1/audit_incident/title_update/batch",
      { items },
      options,
    )
  },

  patchAddress: (
    incidentId: AuditIncidentKey["incidentId"],
    clipId: AuditIncidentKey["clipId"],
    data: Partial<AuditIncidentAddress>,
    options: any = {},
  ) => {
    // clipId may contain slashes, so we need to replace them with backslashes
    // before escaping the path with encodeURIComponent
    const escapedClipId = encodeURIComponent(clipId.replaceAll("/", "\\"))
    return OptimusAPI.patch(
      `/v1/audit_incident/address/${incidentId}/${escapedClipId}`,
      data,
      options,
    )
  },

  patchTitleAndUpdate: (
    incidentId: AuditIncidentKey["incidentId"],
    clipId: AuditIncidentKey["clipId"],
    data: Partial<AuditIncidentTitleAndUpdate>,
    options: any = {},
  ) => {
    // clipId may contain slashes, so we need to replace them with backslashes
    // before escaping the path with encodeURIComponent
    const escapedClipId = encodeURIComponent(clipId.replaceAll("/", "\\"))
    return OptimusAPI.patch(
      `/v1/audit_incident/title_update/${incidentId}/${escapedClipId}`,
      data,
      options,
    )
  },
}

export default AuditIncident
