import { getSessionNotes, postSessionNote } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { useCallback, useEffect, useState } from "react"

export const useSOSNotesPane = (activeSessionId: string) => {
  const [notes, setNotes] = useState([])
  const [currentNote, setCurrentNote] = useState<string>()
  const getNotes = useCallback(() => {
    getSessionNotes(activeSessionId)
      .toPromise()
      .then(({ data }) => {
        setNotes(data.notes)
      })
  }, [activeSessionId])

  useEffect(() => {
    getNotes()
  }, [activeSessionId, getNotes])

  const onSaveNote = () => {
    postSessionNote(activeSessionId, currentNote)
      .toPromise()
      .then(() => {
        setCurrentNote("")
        getNotes()
      })
  }

  const onTextAreaChange = (e: any) => {
    setCurrentNote(e.currentTarget.value)
  }

  return {
    notes,
    currentNote,
    onTextAreaChange,
    onSaveNote,
  }
}
