import * as Sentry from "@sentry/react"

export class Debugger {
  public startsAt: number = 0
  public event: string = ""
  private hasCapturedResult = false

  public breakPoints: {
    [key: string]: number | undefined
  } = {}

  constructor(event: string) {
    this.event = event
  }

  public startMeasure() {
    if (!this.startsAt) {
      this.startsAt = Date.now()
    }
  }

  public addBreakPoint(name: string) {
    if (!this.breakPoints[name]) {
      this.breakPoints[name] = Date.now()
    }
  }

  public getResult() {
    const result: {
      [key: string]: string
    } = {}

    for (const point in this.breakPoints) {
      const measuredAt = this.breakPoints[point]
      if (measuredAt && measuredAt > this.startsAt) {
        result[point] = `${measuredAt - this.startsAt}ms`
      }
    }

    return result
  }

  public getOSInformation() {
    const os = window.navigator.platform
    const userAgent = window.navigator.userAgent
    const vendor = window.navigator.vendor

    return { os, userAgent, vendor }
  }

  public getBrowserInformation() {
    const browser = window.navigator.appCodeName
    const browserVersion = window.navigator.appVersion
    const browserLanguage = window.navigator.language

    return { browser, browserVersion, browserLanguage }
  }

  public logResult(message: string) {
    if (!this.hasCapturedResult) {
      console.log(`Debugger [${message}]`, {
        os: this.getOSInformation(),
        browser: this.getBrowserInformation(),
        result: this.getResult(),
      })

      Sentry.captureEvent({
        message: `Debugger [${message}]`,
        extra: {
          os: this.getOSInformation(),
          browser: this.getBrowserInformation(),
          result: this.getResult(),
        },
        level: "debug",
      })
      this.hasCapturedResult = true
    }
  }
}
