import React from "react"
import PropTypes from "prop-types"

import { Modal, Body, Footer } from "@guardian/UI/Modal"
import { LargeText, MediumText } from "@guardian/UI/Typography"
import { Button, ButtonIcon, ButtonGroup } from "@guardian/UI/Button"
import { Spinner } from "@guardian/UI/Loader"

const ConfirmModal = ({
  text,
  onConfirm,
  onCancel,
  children,
  isLoading,
  open = true,
}) => (
  <Modal
    backdropProps={{ closeDisabled: true }}
    onClose={onCancel}
    onExit={onCancel}
    open={open}
  >
    <Body>
      <LargeText>{text}</LargeText>
      {!!children && <MediumText>{children}</MediumText>}
    </Body>
    <Footer>
      <ButtonGroup>
        <Button
          onClick={onConfirm}
          isPrimary
          tightenY
          disabled={isLoading}
        >
          Confirm
          {!!isLoading && (
            <ButtonIcon.End>
              <Spinner />
            </ButtonIcon.End>
          )}
        </Button>
        <Button
          onClick={onCancel}
          tightenY
          disabled={isLoading}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Footer>
  </Modal>
)

ConfirmModal.defaultProps = {
  children: null,
  isLoading: false,
}

ConfirmModal.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isLoading: PropTypes.bool,
}

export default React.memo(ConfirmModal)
