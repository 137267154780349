import classNames from "classnames";
import styled from "styled-components";

const SubInfo = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__base-user-info__sub-info"
    )
  };
})`
  color: var(--colors-grey-040);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
`;

SubInfo.defaultProps = {};

export default SubInfo;
