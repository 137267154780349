import classNames from "classnames";
import styled from "styled-components";

import { StyledTabPanel } from "@guardian/UI/Tabs";

const TabsPanel = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel"
    )
  };
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;

  & > ${ StyledTabPanel } {
    flex: 1;
    min-height: 0;
  }
`;

TabsPanel.defaultProps = {};

export default TabsPanel;
