const measures = [
  "324px", // xxxSmall
  "360px", // xxSmall
  "394px", // xSmall
  "424px", // small
  "492px", // medium
  "658px", // large
  "858px", // xLarge
];

// Aliases
measures.xxxSmall = measures[0];
measures.xxSmall = measures[1];
measures.xSmall = measures[2];
measures.small = measures[3];
measures.medium = measures[4];
measures.large = measures[5];
measures.xLarge = measures[6];

export const measuresCSSVariables = `
  :root {
    --measures-xxx-small: ${ measures.xxxSmall };
    --measures-xx-small: ${ measures.xxSmall };
    --measures-x-small: ${ measures.xSmall };
    --measures-small: ${ measures.small };
    --measures-medium: ${ measures.medium };
    --measures-large: ${ measures.large };
    --measures-x-large: ${ measures.xLarge };
  }
`;

export default measures;

