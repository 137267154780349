import { MegatronAPI } from "@guardian/API/Megatron";

interface RadioSetRadioConfigurationData {
  playbackRate?: number
  cutoff?: number
  active?: boolean
  channels: string[]
  doneTutorial?: boolean
  referrerId: string
  mapCenterLat?: number
  mapCenterLng?: number
  flaggedOnly?: boolean
};

const Radio = {
  getRadioConfiguration: (options?: any) => {
    return MegatronAPI.get(`/radio/configuration`, options);
  },

  setRadioConfiguration: (
    data: RadioSetRadioConfigurationData,
    options?: any
  ) => {
    return MegatronAPI.post(`/radio/configuration`, data, options);
  },

  getRadioChannelFlagStats: (options?: any) => {
    return MegatronAPI.get(`/gnet/channel_flag_stats`, options);
  },

  getRadioChannels: (options?: any) => {
    return MegatronAPI.get(`/radio/channels`, options);
  },

  getRadioSubAreas: (options?: any) => {
    return MegatronAPI.get(`/radio/sub_areas`, options);
  }
};

export default Radio;
