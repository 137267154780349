export const baseStyles = {
  root: {
    marginBottom: "16px",
    height: "44px",
    width: "100%",
    color: "#ffffff !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
  },
  notchedOutline: {
    "& > legend": {
      visibility: "visible",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "16px",
      color: "#8C8C8C !important",
    },
  },
}

export const multilineStyles = {
  root: {
    marginBottom: "16px",
    height: "68px",
    minHeight: "68px",
    paddingTop: "8px",
    width: "100%",
    color: "#ffffff !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
  },
  notchedOutline: {
    "& > legend": {
      visibility: "visible",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "16px",
      color: "#8c8c8c !important",
    },
  },
}
