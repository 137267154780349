import styles from "@guardian/Components/ModSOS/components/Video/Twillio/Twilio.module.css"
import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import React from "react"

export const MessageAgentMuted = () => (
  <Container className={styles.agentMuted} style={{ height: "auto" }}>
    <Container
      className={styles.agentMutedPill}
      top={1}
      bottom={1}
      left={2}
      right={2}
    >
      <img src='/agent-muted.svg' alt='thumbnails' />{" "}
      <Spacing left={1}>
        <span>User muted you</span>
      </Spacing>
    </Container>
  </Container>
)
