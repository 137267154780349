import React, { useRef } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import useClickOutside from "@guardian/Hooks/useClickOutside"

interface SortingContextMenuProps {
  x: number
  y: number
  onClose: () => void
}

enum SortingMethod {
  Confidence = "confidence",
  Timestamp = "timestamp",
}

const sortingMethodLabels: { [key in SortingMethod]: string } = {
  [SortingMethod.Confidence]: "Override: Filtering Confidence",
  [SortingMethod.Timestamp]: "Default (Timestamp)",
}

const handleSortingMethodChange = (
  algorithm: string | null,
  callback?: () => any,
) => {
  eventHub.emit("setSortingMethod", algorithm)
  callback?.()
}

const SortingContextMenu: React.FC<SortingContextMenuProps> = ({
  x,
  y,
  onClose,
}) => {
  const menuRef = useRef<HTMLDivElement>(null)

  const sortingMethod = useGlobalState(state => state.radio.sortingMethod)

  useClickOutside([menuRef], onClose)

  return (
    <div
      ref={menuRef}
      className='clip-context-menu'
      style={{
        position: "fixed",
        top: y,
        left: x,
        zIndex: 100,
      }}
    >
      <ul>
        <li className='menu-title'>
          Sorting Method: {sortingMethod || "Timestamp"}
        </li>
        {Object.entries(sortingMethodLabels).map(([key, label]) => (
          <li
            key={key}
            className='menu-item'
            onClick={() => handleSortingMethodChange(key, onClose)}
          >
            {label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SortingContextMenu
