import styled from "styled-components";

interface UserDropdownArrowProps {
  active: boolean
};

const UserDropdownArrow = styled.span<UserDropdownArrowProps>`
  display: inline-block;
  height: 12px;
  margin-left: var(--space-xx-small);
  position: relative;
  text-align: left;
  width: 12px;

  &::before, &::after {
    background-color: transparent;
    border-bottom: 7px solid white;
    content: "";
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(0);
    transition: 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 1.5px;
  }

  &::before {
    transform: rotate(-45deg);
    left: 3px;
    top: 3px;
  }

  &::after {
    transform: rotate(45deg);
    left: 7px;
    top: 3px;
  }

  ${({ active }: UserDropdownArrowProps) => active && `
    &::before {
      transform: rotate(-135deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  `}
`;

export default UserDropdownArrow;
