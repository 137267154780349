import React, { memo } from "react"
import LiveBadge from "@guardian/Components/Badges/LiveBadge"
import dayjs from "dayjs"
import { bool, string, number } from "prop-types"
import classnames from "classnames"
import style from "./InAppContent.module.css"
import { Link } from "react-router-dom"
import PhotoBadge from "@guardian/Components/Badges/PhotoBadge"

import { VideoStreams } from "@guardian/API/Optimus";
import { shouldReplace } from "@guardian/Utils/history"
import calendar from "@guardian/Utils/dayjs/calendar"

dayjs.extend(calendar)

function timeString(time) {
  const date = new Date(time)
  if (Date.now() - date.getTime() > 24 * 60 * 60 * 1000) {
    return dayjs(date).calendar(" at")
  }
  return dayjs(date).fromNow()
}

const InAppContent = ({
  username,
  createdAt,
  broadcastDone,
  hlsDone,
  showControls,
  active,
  durationSeconds,
  thumbUrl,
  id,
  maxBoostOrder,
  verified,
  blocked,
  type,
}) => {
  const isDone = type === "image" || broadcastDone || hlsDone
  let durationString = ""
  if (durationSeconds) {
    durationString = `${Math.floor(durationSeconds / 60)}:${
      durationSeconds % 60 < 10 ? "0" : ""
    }${Math.round(durationSeconds) % 60}`
  }
  const pathname = `/content-moderation/${type}s/${id}`

  return (
    <Link to={pathname} replace={shouldReplace(pathname)} target='_blank'>
      <div
        className={classnames(style.container, {
          [style.active]: active,
        })}
      >
        <div
          className={style.background}
          style={{ backgroundImage: `url(${thumbUrl})` }}
        />
        <div className={style.info}>
          <div className={style.username}>@{username}</div>
          <div className={style.time}>{timeString(createdAt)}</div>
          <div className={style.live}>
            {!isDone && <LiveBadge />}
            {type === "image" && <PhotoBadge />}
            {durationString}
          </div>
        </div>
        {showControls && type !== "image" && (
          // Not doing boosting of photos for now
          <button
            className={style.boost}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              VideoStreams.boostStream(id, { boostOrder: maxBoostOrder + 1 })
            }}
          >
            Boost
          </button>
        )}
        <div className={style.badges}>
          {verified && (
            <div className={style.verified}>
              <i className='fas fa-check' />
            </div>
          )}
          {blocked && (
            <div className={style.blocked}>
              <i className='fas fa-ban' />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

InAppContent.propTypes = {
  username: string,
  createdAt: string,
  broadcastDone: bool,
  hlsDone: bool,
  showControls: bool,
  active: bool,
  id: string,
  durationSeconds: number,
  thumbUrl: string,
  maxBoostOrder: number,
  verified: bool,
  blocked: bool,
  type: string,
}

export default memo(InAppContent)
