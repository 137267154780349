import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledTabButton = styled(
  ({ isActive, isDisabled, isFocused, isHovered, ...props }) => {
    // Ensure Link component doesn't receive the props for our styled component.
    return <Link {...props}/>
  }
).attrs(
  props => {
    const {
      isActive,
      isDisabled,
      isFocused,
      isHovered
    } = props;

    return {
      "aria-disabled": isDisabled,
      className: classNames("sos-dms__tab-bar__tab-button", {
          "sos-dms__tab-bar__tab-button--is-active": isActive,
          "sos-dms__tab-bar__tab-button--is-disabled": isDisabled,
          "sos-dms__tab-bar__tab-button--is-focused": isFocused,
          "sos-dms__tab-bar__tab-button--is-hovered": isHovered,
        })
    };
  }
)`
  ${ buttonResets }

  align-items: center;
  background: var(--colors-transparent);
  box-shadow: none;
  color: var(--colors-transparent);
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  outline: none;
  padding: 0 var(--space-small);
  position: relative;
  text-decoration: none;

  // NOTE: If you're curious about the use of the pseudo element here, it's
  // because our tab bar links change font weight when active or hovered. As
  // font weight changes adjust the rendered width of the texts, this would
  // cause the tab items to move around when this weight changes. To address
  // this elegantly, we make the tab items have their text, but transparent, so
  // that they are rendered in the browser at the right size for their normal
  // weight text. Then we use the pseudo element, placed _over_ the transparent
  // text, to actually _display_ the text. When font weight changes it simply
  // then sits, centered, over its normal text space.
  &:after {
    align-items: center;
    bottom: 0;
    color: var(--colors-grey-050);
    display: flex;
    content: attr(data-text);
    font-size: var(--font-sizes-medium);
    font-weight: var(--font-weights-normal);
    justify-content: center;
    left: var(--space-small);
    position: absolute;
    right: var(--space-small);
    top: 0;
  }

  &:active, &.sos-dms__tab-bar__tab-button--is-active,
  &:hover, &.sos-dms__tab-bar__tab-button--is-hovered
  {
    color: var(--colors-transparent);
    text-decoration: none;

    &:after {
      color: var(--colors-white);
      font-weight: var(--font-weights-bold);
    }
  }

  &:active, &.sos-dms__tab-bar__tab-button--is-active
  {
    &:after {
      box-shadow: var(--borders-highlight-bottom) var(--colors-white);
    }
  }

  // :focus managed by KeyboardFocusContainer
  &.sos-dms__tab-bar__tab-button--is-focused
  {
    color: var(--colors-transparent);
    text-decoration: none;

    &:after {
      color: var(--colors-white);
      font-weight: var(--font-weights-bold);
    }
  }

  &.sos-dms__tab-bar__tab-button--is-disabled {
    color: var(--colors-transparent);
    pointer-events: none;

    &:after {
      color: var(--colors-grey-070);
      font-weight: var(--font-weights-bold);
    }
  }
`;

const TabButton = React.forwardRef(
  ({ children, isFocused, ...other }, ref) => (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <StyledTabButton
          {...getFocusProps({
            ref,
            ...other,
            isFocused: isFocused || keyboardFocused
          })}
        >
          { children }
        </StyledTabButton>
      )}
    </KeyboardFocusContainer>
  )
);

TabButton.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isHovered: PropTypes.bool
};

TabButton.defaultProps = {
  isActive: false,
  isDisabled: false,
  isFocused: false,
  isHovered: false
};

export default TabButton;

