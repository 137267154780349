import { useContext } from "react";

import { AppContext } from "@guardian/Core";

const useGetAgentChatUnreadCount = () => {
  const {
    agentChat: { totalUnreadCount: count }
  } = useContext(AppContext);

  return [
    count
  ];
};

export default useGetAgentChatUnreadCount;
