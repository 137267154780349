import { createBrowserHistory } from "history"

const history = createBrowserHistory()

export const push = (pathname, state) => {
  if (!history.location.pathname.endsWith(pathname) || state) {
    history.push(pathname, state)
  }
}

export const replace = history.replace

export const shouldReplace = pathname => {
  if (history.location.pathname.endsWith(pathname)) {
    return true
  }
  return false
}

export default history
