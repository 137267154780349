import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import FocusLock from "react-focus-lock";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  FILL: "fill"
};

const TRANSITION_NAME = `modal`;

const StyledModal = styled.div.attrs(
  props => {
    const {
      className,
      size
    } = props;

    return {
      className: classNames(className, "modal", {
          "modal--small": size == SIZES.SMALL,
          "modal--medium": size == SIZES.MEDIUM,
          "modal--large": size == SIZES.LARGE,
          "modal--fill": size == SIZES.FILL,
        })
    };
  }
)`
  ${({ transitionMs }) => `
    background: var(--colors-grey-085);
    border-radius: var(--radii-x-large);
    display: flex;
    flex-direction: column;
    opacity: 1.0;
    position: relative;
    transition:
      opacity ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1),
      transform ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-property: opacity, transform;

    &.modal--small {
      max-width: 440px;
      width: 100%;
    }

    &.modal--medium {
      max-width: 600px;
      width: 100%;
    }

    &.modal--large {
      max-width: 900px;
      width: 100%;
    }

    &.modal--fill {
      max-width: 100%;
      width: 100%;
    }

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      transition-delay: ${ transitionMs }ms;
    }

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      opacity: 0;
      transform: translateY(25%);
    };

    &.${ TRANSITION_NAME }-appear.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter.${ TRANSITION_NAME }-enter-active,
    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done,
    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
      transform: translateY(0%);
    };

    &.${ TRANSITION_NAME }-exit.${ TRANSITION_NAME }-exit-active,
    &.${ TRANSITION_NAME }-exit-done {
      opacity: 0;
      transform: translateY(25%);
    };

    &:before {
      border-radius: var(--radii-x-large);
      bottom: 0;
      box-shadow: var(--shadows-medium);
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1);
      will-change: opacity;
      z-index: -1;
    }
  `}
`;

StyledModal.propTypes = {};

StyledModal.defaultProps = {};

const Modal = props => {
  const { children, entered, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive: `${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={ entered }
      timeout={{
        appear: transitionMs, enter: transitionMs, exit: transitionMs
      }}
    >
      <StyledModal transitionMs={ transitionMs } { ...passdownProps }>
        <FocusLock autoFocus={false}>
          { children }
        </FocusLock>
      </StyledModal>
    </CSSTransition>
  );
};

Modal.propTypes = {
  entered: PropTypes.bool.isRequired,
  size: PropTypes.oneOf([
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.FILL
  ]).isRequired,
  transitionMs: PropTypes.number.isRequired
};

Modal.defaultProps = {
  size: SIZES.MEDIUM
};

export default Modal;
