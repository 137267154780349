import classNames from "classnames";
import styled from "styled-components";

import { FauxInput } from "@guardian/UI/Form";

const Select = styled(FauxInput).attrs(
  props => {
    const {
      className,
      disabled,
      isOpen
    } = props;

    return {
      className: classNames(className, "dropdown__select", {
        "dropdown__select--is-disabled": disabled,
        "dropdown__select--is-open": isOpen
      })
    };
  }
)`
  cursor: pointer;
  height: 40px;
  overflow: hidden;
  padding: 0 80px 0 var(--space-small);
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  // Override FauxInput's placeholder styling.
  && {
    &:before {
      padding: 0;
    }
  }
`;

Select.propTypes = {
  ...FauxInput.propTypes
};

Select.defaultProps = {
  ...FauxInput.propTypes.defaultProps
};

export default Select;
