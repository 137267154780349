import { List } from "immutable"
import _groupBy from "lodash/groupBy"
import _uniq from "lodash/uniq"
import { memo, useEffect, useMemo, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import { getFlaggingAlgorithm, getUnplayedClipsCount, isClipFlagged } from "@guardian/Utils/clips"
import { objPick } from "@guardian/Utils/obj-pick"
import { sortServiceAreas } from "@guardian/Utils/util"

import CityCodes from "@guardian/Components/CityCodes/CityCodes"
import ClipsNav from "@guardian/Components/ClipsNav"
import MetapodListenControls from "@guardian/Components/ListenControls/MetapodListenControls"
import { MultiLabelTextInput } from "@guardian/UI/Legacy/MultiLabelTextInput"

import AddPrompt from "./AddPrompt"
import ClipContextMenu from "./ClipContextMenu"
import ClipList from "./ClipList"
import ClipRating from "./ClipRating"
import EmptyPrompt from "./EmptyPrompt"
import "./ListenPanel.scss"

const MIN_IN_MS = 60 * 1000

const ListenPanel = () => {
  const {
    flaggedOnly,
    activeChannels,
    pendingClipsFetch,
    clipsView: clips,
    multiplayerPinnedClips,
    channels,
    flaggedCount,
    activeClip,
    mutes,
    clipContextMenu,
    speed,
    rateClip,
    activeClipOverride,
    unplayedOnly,
    metapod,
    tab,
    permissions,
    activeTimestamp,
    allowClipContextMenu,
    serviceAreas,
    attachedClips,
  } = useGlobalState(state => {
    const selectedGlobal = objPick(state.global, [
      "metapod",
      "tab",
      "permissions",
      "activeTimestamp",
    ])
    const selectedRadio = objPick(state.radio, [
      "flaggedOnly",
      "activeChannels",
      "pendingClipsFetch",
      "multiplayerPinnedClips",
      "activeClip",
      "mutes",
      "clipContextMenu",
      "speed",
      "rateClip",
      "activeClipOverride",
      "unplayedOnly",
    ])
    const {
      groupSettings: { allowClipContextMenu },
      serviceAreaConfig: { serviceAreas },
      metapod,
      clipPriorityConfig,
    } = state.global
    const { clipsView, channels, flaggedConfig, flagAlgorithm } = state.radio

    const flaggedCount = clipsView.filter(c => {
      const channel = channels[c.channel]
      const flaggingAlgorithm = getFlaggingAlgorithm(
        channel,
        flaggedConfig,
        flagAlgorithm,
      )
      return (
        metapod &&
        !c.plays &&
        isClipFlagged(c, flaggingAlgorithm, clipPriorityConfig)
      )
    }).length

    return {
      ...selectedGlobal,
      ...selectedRadio,
      clipsView,
      channels,
      flaggedCount,
      allowClipContextMenu,
      serviceAreas,
      attachedClips: state.incidentComposer.attachedClips,
    }
  })

  const [showChannelSelection, setShowChannelSelection] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const handleFlagToggle = () => {
    eventHub.emit("set flagged only", !flaggedOnly)
  }

  const hasActiveChannels = Object.keys(activeChannels || {}).length > 0
  useEffect(() => {
    if (!hasActiveChannels) {
      return undefined
    }
    eventHub.emit("startUpdatingClips")
    return () => {
      eventHub.emit("stopUpdatingClips")
    }
  }, [hasActiveChannels])

  // Clips on the isolated channel (if set) that are below cutoff duration

  const multiplayerPinCount = useMemo(() => {
    return multiplayerPinnedClips.filter(
      mpc => activeChannels[mpc.channel] && mpc.clipListenStatus !== "unplayed",
    ).length
  }, [multiplayerPinnedClips, activeChannels])

  const shouldShowUnplayedClipsCount = !!permissions.citMod && Date.now() - activeTimestamp > 20 * MIN_IN_MS
  const unplayedClipsCount = useMemo(() => {
    if (shouldShowUnplayedClipsCount) {
      return getUnplayedClipsCount(clips, activeClip)
    }
    return 0
  }, [shouldShowUnplayedClipsCount, clips, activeClip])

  const options = useMemo(() => {
    const activeChannelObjs = Object.entries(channels)
      .filter(([id]) => !!activeChannels[id])
      .map(([id, obj]) => obj)

    const activeSubAreas = _groupBy(activeChannelObjs, "subArea")
    return Object.entries(activeSubAreas).map(([subArea, arr]) => ({
      key: subArea,
      channels: arr,
    }))
  }, [channels, activeChannels])

  const uniqSas = useMemo(() => {
    const chans = Object.keys(activeChannels).filter(cid => channels[cid])
    return sortServiceAreas(_uniq(chans.map(cid => channels[cid].serviceArea)))
  }, [activeChannels, channels])

  let listBody
  if (clips.length === 0 && uniqSas.length === 0) {
    listBody = <AddPrompt />
  } else if (clips.length === 0 && !pendingClipsFetch) {
    listBody = <EmptyPrompt />
  } else if (pendingClipsFetch) {
    listBody = <h2>loading...</h2>
  } else {
    listBody = (
      <ClipList
        clips={clips}
        channels={channels}
        activeClipId={activeClipOverride ? "0" : activeClip.id}
        activeClipStatus={activeClip.status}
        activeClipAnimateStatus={activeClip.animateStatus}
        activeClipLoop={activeClip.loop}
        speed={speed}
        mutes={mutes}
        attachedClips={attachedClips || new List()}
        unplayedClipsCount={unplayedClipsCount}
        unplayedOnly={unplayedOnly}
      />
    )
  }
  let contextClip
  if (clipContextMenu?.id && allowClipContextMenu) {
    const clickedClip = clips.find(c => c.id === clipContextMenu.id) || {}
    contextClip = objPick(clickedClip, ["wav_url", "pin"])
  }

  return (
    <div className='listen-panel'>
      <div
        onClick={() => {
          if (!showChannelSelection) {
            setShowChannelSelection(true)
          }
        }}
        className='autocomplete-container'
      >
        <MultiLabelTextInput
          options={options}
          inputValue={inputValue}
          onOpen={() => setShowChannelSelection(true)}
          onClose={() => setShowChannelSelection(false)}
          onClear={() => {
            options.forEach(option => {
              option.channels.forEach(channel =>
                eventHub.emit("toggleChannel", channel.id),
              )
            })

            eventHub.emit("clearListeningGroups")
          }}
          open={showChannelSelection}
          handleInputChange={val => setInputValue(val)}
          chipLabel={option => (
            <div style={{ display: "flex" }}>
              {option.key}
              <div className='input-chip-count'>({option.channels.length})</div>
            </div>
          )}
          onDeleteOption={option =>
            option.channels.forEach(channel =>
              eventHub.emit("toggleChannel", channel.id),
            )
          }
          getOptionSelected={(option, value) => option.key === value.key}
          inputLabel={`Coverage Selection ${
            options.length ? `(${options.length})` : ""
          }`}
        />
      </div>
      {showChannelSelection && (
        <ClipsNav filterText={inputValue} handleInputChange={setInputValue} />
      )}
      {!showChannelSelection && (
        <>
          {contextClip && (
            <ClipContextMenu {...clipContextMenu} {...contextClip} />
          )}
          {((tab === "modalpha" && permissions.citMod) ||
            tab === "data_labeling") && (
            <MetapodListenControls
              onFlagToggle={handleFlagToggle}
              flagCount={flaggedCount}
              multiplayerPinCount={multiplayerPinCount}
            />
          )}
          {listBody}
          {rateClip && (
            <ClipRating
              clipId={rateClip.id}
              channelName={channels[rateClip.channel]?.name}
            />
          )}
          {uniqSas.length && !rateClip && (
            <CityCodes
              serviceAreaCfg={serviceAreas}
              serviceAreas={uniqSas}
            />
          )}
        </>
      )}
    </div>
  )
}

export default memo(ListenPanel)
