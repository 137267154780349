import classNames from "classnames";
import styled, { css } from "styled-components";

const MobileStateIndicator = styled.span.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__mobile-state-indicator"
      )
    }
  }
)`
  bottom: 0;
  height: 12px;
  position: absolute;
  right: 0;
  width: 12px;

  &:after {
    background: var(--colors-yellow-030);
    border-radius: 100%;
    content: "";
    height: 8px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 8px;
  }

  ${ ({ isForegrounded }) => isForegrounded && css`
    &:after {
      background: var(--colors-green-030);
    }
  `}
`;

MobileStateIndicator.defaultProps = {};

export default MobileStateIndicator;
