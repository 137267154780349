import React from "react";

import styles from "./SOSLocationBasedNumber.module.css";
import { IProps } from "./SOSLocationBasedNumber.types";

const SOSLocationBasedNumber = (props: IProps) => {
  const {
    keyNum,
    setPhoneNumber
  } = props;

  const handleNumberClicked = () => {
    setPhoneNumber(keyNum.phoneNumber);
  };

  return (
    <div className={styles.keyNumberBlock}>
      <span className={styles.title}>{keyNum.title}</span>
      <span>
        <span
          className={styles.number}
          onClick={handleNumberClicked}
        >
          Fill
        </span>
        <a
          className={styles.number}
          href={`https://voice.google.com/u/0/calls?a=nc,${keyNum.phoneNumber}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Open in Voice
        </a>
      </span>
    </div>
  );
};

export default SOSLocationBasedNumber;
