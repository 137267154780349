import classNames from "classnames";
import styled from "styled-components";

import { Col } from "@guardian/UI/Grid"

const UserInfoPanel = styled(Col).attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__user-info-panel")
    }
  }
)`
  background: var(--colors-grey-095);
  box-shadow: var(--borders-left) var(--colors-grey-080);
  color: var(--colors-white);
  display: flex;
  flex: 0 0 356px;
  flex-direction: column;
  height: 100%;
  width: 356px;
`;

UserInfoPanel.defaultProps = {
  noGutter: true
};

export default UserInfoPanel;
