import React from "react"

import { NumberInput } from "@guardian/UI/Legacy/NumberInput"

interface IProps {
  onChangeLevel: (delta: number) => void
  disabled: boolean
  level: number
}

const LevelInput = ({ onChangeLevel, disabled, level }: IProps) => (
  <div style={{ width: "70px", marginRight: "8px" }}>
    <NumberInput
      notched
      label='Safety'
      value={level}
      onChangeUp={() => !disabled && onChangeLevel(1)}
      onChangeDown={() => !disabled && onChangeLevel(-1)}
    />
  </div>
)

export default LevelInput
