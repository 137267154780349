import React, { Children } from "react";
import styled from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

const MenuRowChild = styled.div.attrs(props => {
  const {
    className,
    toggleWithRowContentsSelectable
  } = props;

  return {
    className: classNames(className, "dropdown__menu__row__child", {
      "dropdown__menu__row__child--toggle-with-row-contents-selectable":
        toggleWithRowContentsSelectable
    })
  }
})`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
`;

MenuRowChild.propTypes = {
  toggleWithRowContentsSelectable: PropTypes.bool
};

MenuRowChild.defaultProps = {
  toggleWithRowContentsSelectable: true
};

MenuRowChild.End = styled(MenuRowChild)`
  margin-left: auto;
  padding-left: var(--space-small);
`;

MenuRowChild.Start = styled(MenuRowChild)`
  margin-right: auto;
  padding-right: var(--space-small);
`;

export default MenuRowChild;
