import { OptimusAPI } from "@guardian/API/Optimus";

interface AuthWithGoogleData {
  token: string
};

const Auth = {
  authWithGoogle: (data: AuthWithGoogleData, options?: any) => {
    return OptimusAPI.post(`/v2/auth/validate_gtoken`, data, options);
  }
};

export default Auth;
