import styled from "styled-components";
import classNames from "classnames";

import Box from "./Box";

export const Flex = styled(Box).attrs(
  props => ({
    className: classNames("box__flex")
  })
)`
  display: flex;
`;

Flex.propTypes = {
  ...Box.propTypes
};

Flex.defaultProps = {};

export default Flex;
