import { getAgentScripts } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { flattenDeep, keyBy } from "lodash"
import { useEffect, useState } from "react"
import { TScriptType } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"
import { IScriptNote } from '@guardian/Components/ModSOS/components/InfoPane/AgentScriptNote/types';
import { IScriptSection } from '@guardian/Components/ModSOS/components/InfoPane/AgentScriptSection/types';
import { ISearchMatch } from '@guardian/Components/ModSOS/components/InfoPane/SearchBox/types';

const getMatches = (
  str: string,
  query: string,
  id: string,
  property: string = "title",
) => {
  if (!query) {
    return []
  }

  const matched = []
  const removeCase = str.toLowerCase()
  for (let i = 0; i < removeCase.length; i += 1) {
    const match = removeCase.indexOf(query.toLowerCase(), i)
    if (match === -1) {
      break
    }
    matched.push({ id, start: match, end: query.length + match, property })
    i = match
  }

  return matched
}

export const getAllMatches = (
  script: IScriptSection | IScriptNote,
  query: string,
): any => {
  if (script.type === TScriptType.Note) {
    const note = script as IScriptNote

    return getMatches(note.title, query, note.id, "title").concat(
      (note.content || []).map(c =>
        getMatches(c.value, query, c.id, "content"),
      ) as any,
    )
  } else {
    const section = script as IScriptSection
    return getMatches(section.title, query, section.id, "title").concat(
      (section.children || []).map(c => getAllMatches(c, query)),
    )
  }
}

export const useAgentScripts = (sessionId?: string) => {
  const [scripts, setScripts] = useState<Array<IScriptSection | IScriptNote>>([])
  const [searching, setSearching] = useState(false)
  const [searchMatches, setSearchMatches] = useState<ISearchMatch[]>()
  const [query, setQuery] = useState<string>()
  const [activeSearch, setActiveSearch] = useState<ISearchMatch>()
  const [matches, setMatches] = useState<{ [id: string]: ISearchMatch }>()

  useEffect(() => {
    getAgentScripts(sessionId).toPromise().then(({ data }) => {
      setScripts(data)
    })
  }, [sessionId])

  useEffect(() => {
    if (searching && !!query) {
      setActiveSearch(undefined)
      setSearchMatches(flattenDeep(scripts.map(s => getAllMatches(s, query))))
    }
  }, [query, scripts, searching])

  useEffect(() => {
    setMatches(keyBy(searchMatches, s => s.id))
  }, [searchMatches])

  return {
    setSearching,
    setQuery,
    searchMatches,
    setActiveSearch,
    searching,
    scripts,
    matches,
    activeSearch,
  }
}
