import React from "react"
import { string, bool, func, object } from "prop-types"
import ContentControls from "./ContentControls"

import { ContentService } from "@guardian/Services/Content";

import PhotoMenu from "@guardian/Components/IncidentContentMod/PhotoMenu"

const PhotoContent = ({ content, ...props }) => {
  const { id, username, userId, imageUrl } = content.image
  return (
    <>
      <img alt="" src={imageUrl} width='100%' height='100%' />
      <ContentControls
        onReject={props.onReject}
        onConfirm={props.onConfirm}
        confirmed={props.confirmed}
        onBlock={props.onBlock}
        onVerified={props.onVerified}
        block={props.block}
        verified={props.verified}
        id={props.id}
        prompt911={props.prompt911}
        onPrompt911={props.onPrompt911}
        type='image'
      />
      <PhotoMenu
        id={id}
        username={username}
        userId={userId}
        onReject={() => {
          ContentService.ackContent("image", id)
        }}
        igl
      />
    </>
  )
}

PhotoContent.propTypes = {
  id: string,
  onReject: func,
  onConfirm: func,
  confirmed: bool,
  onBlock: func,
  onVerified: func,
  block: bool,
  verified: bool,
  prompt911: bool,
  onPrompt911: func,
  content: object,
}
export default PhotoContent
