import React from "react"

import { Checkbox } from "@guardian/UI/Legacy/Checkbox"
import { Text } from "@guardian/UI/Legacy/Text"

import style from "./LabeledCheckbox.module.css"

export interface LabeledCheckboxProps {
  label: string
  active: boolean
  onClick: () => void
  disabled?: boolean
  blue?: boolean
}

const LabeledCheckbox = ({
  label,
  active,
  onClick,
  disabled,
  blue,
}: LabeledCheckboxProps) => (
  <div className={style.container}>
    <Checkbox
      active={active}
      handleClick={() => !disabled && onClick()}
      blue={blue}
    />
    <Text size='small' color='grey'>
      {label}
    </Text>
  </div>
)

export default LabeledCheckbox
