import { css } from "styled-components";

const legacyGlobalStyles = css`

  @keyframes fade-opacity {
      0% { filter: opacity(100%); }
      100% { filter: opacity(0%); }
  }

  @keyframes fade {
      0% { color: white; transform: scale(1, 1); }
      100% { color: rgba(255, 255, 255, 0); transform: scale(1.5, 1.5); }
  }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }

  @keyframes pulse-pending-opacity {
      0% { filter: opacity(40%); }
      100% { filter: opacity(100%); }
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: black;
    color: #fff;
    font-family: "Lato", "Open Sans", "helvetica", sans-serif;
    font-size: 10pt;
    -webkit-font-smoothing: antialiased;
  }

  img[alt] {
    font-size: 8px;
  }

  div {
    box-sizing: border-box;
  }

  nav .flagged-count {
    display: inline-block;
    border-radius: 20px;
    background: red;
    width: 19px;
    height: 19px;
    text-align: center;
    padding: 1px 0;
  }

  button {
    font-family: "Open Sans", "helvetica", "sans-serif";
    -webkit-border-radius: 2;
    -moz-border-radius: 2;
    color: #ccc;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  button:hover {
    color: white;
  }

  button:disabled {
    color: #555;
  }

  button:disabled:hover {
    cursor: auto;
  }

  form label.input-label {
    display: block;
    text-align: center;
    font-size: 12px;
    margin: 20px 0 5px;
  }

  textarea {
    border: 1px solid #ddd;
    width: 280px;
    height: 50px;
    font-size: 10pt;
    outline: none;
  }

  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input[type="number"]:not(.MuiInputBase-input),
  input[type="text"]:not(.MuiInputBase-input) {
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;
    height: 40px;
    background-color: black;
    color: white;
    padding: 10px 16px;
    font-size: 12px;
    border: solid 1px #0e151d;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  h3 {
    color: #62708c;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin: 0;
  }

  #modal-root {
    position: fixed;
    left: 0;
    top: 0;
  }

  .btn {
    cursor: pointer;
  }

  .nav-right-menu {
    position: absolute;
    right: 24px;
    top: 0;
    z-index: 10;
  }

  .avatar-thumb {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .mobile-menu {
    .nav-link {
      display: block;
    }

    &.side-panel {
      z-index: 5;
    }
  }

  nav .menu {
    display: inline-block;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .play-control {
    float: right;
    padding: 10px 1px;
  }

  .play-control .btn {
    display: inline-block;
    padding: 2px 5px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .citizen-logo {
    height: 18px;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #666;
  }

  .pane {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-corner {
    background: #0e151d;
  }
  ::-webkit-scrollbar-track {
    background: #0e151d;
  }
  ::-webkit-scrollbar-thumb {
    background: #555;
  }

  .channel {
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0px;
    padding-right: 5px;
    height: 32px;
    color: #8c8c8c;

    &:hover {
      background-color: #1a2633;
      color: white;
    }

    &.active {
      background-color: #072833;
      box-shadow: inset -4px 0 0 0 #22c9ff;
      color: white;

      &:hover {
        background-color: #006888;
      }
    }

    .channel-header {
      display: flex;
      flex: 1;
      flex-direction: row;

      > div:last-child {
        flex: 1;
      }
    }

    .star {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      color: #ffd52b;
      font-size: 10px;
    }

    &.active.iso-on {
      background-color: #cc8800;

      &:hover {
        background-color: #ffb300;
      }
    }

    .semi-active {
      background-color: #072833;
      box-shadow: inset -4px 0 0 0 #22c9ff;
      color: white;

      &:hover {
        background-color: #006888;
      }
    }

    .listeners {
      color: #c7c7c7;
      margin-right: 16px;

      i {
        font-size: 12px;
        margin-right: 4px;
      }
    }

    .listen {
      float: right;
      color: red;
    }

    i {
      margin-left: 3px;
    }
  }

  .moderation {
    display: flex;
    flex-grow: 1;
  }

  .reset {
    clear: both;
  }

  .gnet-button {
    border: 1px solid #006888;
    border-radius: 4px;
    background-color: #072833;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
  }

  .geosuggest__item--active {
    background-color: #006888;
    color: #fff;
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    display: none;
    border-width: 0;
  }

  .geosuggest {
    ul {
      padding: 0;
      width: 100%;
    }

    li {
      list-style: none;
      padding: 0.192em 0.618em;
      line-height: 1.2;
      font-size: 1em;
      margin-bottom: 0.192em;
    }
  }

  .incident {
    position: relative;

    & + & {
      box-shadow: inset 0 2px 0 0 #262a32;
    }

    .update-editor {
      position: relative;
      display: flex;
      padding: 0px 8px;
      padding-left: 12px;

      &.multi-clips {
        display: block;

        input[type="text"] {
          margin-bottom: 4px;
        }
      }
    }

    .error {
      font-size: 12px;
      color: red;
      padding-left: 12px;
      margin-bottom: 16px;
    }

    &.dragging {
      .expanded-target {
        position: absolute;
        width: 100%;
        height: 800%;
        left: 0px;
        top: -150%;
      }

      .update-editor {
        position: relative;
        z-index: 102;
        background-color: #1d2026;
      }
    }

    .geosuggest__input {
      width: 288px;
      color: #22c9ff;
      padding: 4px;
      height: 24px;

      &::placeholder {
        color: #22c9ff;
      }
    }

    .geosuggest ul {
      padding: 0;
      width: 288px;
    }
  }

  .incident-location {
    color: #22c9ff;
    cursor: pointer;
  }

  ul.updates {
    padding-left: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  li.update {
    padding-bottom: 16px;
  }
  li.connected-li {
    position: relative;
    list-style: none;

    &:before {
      /* lines */
      content: "";
      position: absolute;
      left: -12px; /* adjust manually */
      border-left: 1px solid #5e656f;
      height: 100%;
      width: 1px;
    }

    &:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f111";
      position: absolute;
      top: 4px;
      left: -16px;
      font-size: 9px;
      color: #5e656f;
    }
  }

  li.inLiveTab {
    &:before {
      color: transparent;
      height: 0px;
    }

    &:after {
      color: transparent;
      height: 0px;
    }
  }

  li.update.approved {
    box-shadow: inset -4px 0 0 0 #10cf3b;
  }
  li.update.approved-modified {
    box-shadow: inset -4px 0 0 0 #10cf3b;
  }
  li.update.pending {
    box-shadow: inset -4px 0 0 0 #ffd52b;
    color: white;
  }
  li.update.rejected {
    box-shadow: inset -4px 0 0 0 #ed2323;
    color: #ed2323;
  }

  .update {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;

    .update-header {
      color: #a7abaf;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 8px;
    }

    .update-body {
      font-size: 12px;
    }

    .update-stream,
    .update-image-content {
      margin-top: 4px;
      padding-right: 24px;
      max-width: 290px;
    }

    .update-webview {
      @extend .update-stream;
      max-width: 500px;
      max-height: 500px;
    }

    .update-footer {
      font-size: 11px;
      color: #a7abaf;
    }

    .update-clips {
      margin-top: 4px;
    }

    .update-header {
      .delete {
        margin-right: 16px;
        cursor: pointer;
      }
    }

    .update-clips {
      display: flex;
    }
  }

  .updates li.update:last-child {
    padding-bottom: 16px;
  }

  li.connected-li:first-child:before {
    /* first li's line */
    top: 10px; /* moves the line down so that it disappears under the bullet. Adjust manually */
  }

  li.connected-li:last-child:before {
    /* last li's line */
    height: 10px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
  }
  li.inLiveTab:last-child:before {
    /* last li's line */
    height: 0px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
  }
  li.connected-li:first-child:last-child:before {
    display: none;
  }

  input[type="range"] {
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid transparent;

    &::-webkit-slider-runnable-track {
      height: 2px;
      background: white;
      border: none;
      cursor: pointer;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: white;
      margin-top: -6px;
      cursor: pointer;
    }

    &:focus {
      border: 1px solid blue;

      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: white;
      }
    }

    &::-moz-range-track {
      height: 2px;
      background: white;
      border: none;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      border: none;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }

    &::-ms-track {
      height: 2px;

      background: transparent;

      border-color: transparent;

      color: transparent;
      cursor: pointer;
    }

    &::-ms-fill-lower {
      background: white;
    }

    &::-ms-fill-upper {
      background: white;
    }

    &::-ms-thumb {
      border: none;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }
  }

  .sub-area + .sub-area {
    box-shadow: inset 0 2px 0 0 #262a32;
  }

  .spinner {
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }

  @media only screen and (max-width: 480px) {
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    select {
      border: 1px solid #ccc;
      padding: 3px;
      border-radius: 0px;
      background-color: #111;
      color: white;
      -webkit-appearance: none;
    }
  }

  select {
    padding: 4px 8px;
    padding-right: 24px;
    height: 24px;
    color: #a7abaf;
    font-size: 12px;
    border: 0;
    border-radius: 4px;
    background: url(/down-white.svg) 96% / 15% no-repeat;
    background-color: black;
    background-size: 15px;

    /* Removes the default <select> styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  label.select-wrapper {
    position: relative;

    &:after {
      content: "\f107";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: white;
      right: 8px;
      top: 0px;
      padding: 0 0 2px;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      content: "";
      right: 4px;
      top: 0px;
      width: 23px;
      height: 18px;
      background: transparent;
      position: absolute;
      pointer-events: none;
      display: block;
    }
  }

  .notif {
    width: 240px;
    color: white;
    border-radius: 8px;
    font-size: 12px;
    overflow: hidden;
    margin-bottom: 4px;
    cursor: pointer;

    .notif-header {
      width: 100%;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      padding: 8px;
    }

    .notif-body {
      padding: 8px;
    }

    &.updateRejected {
      .notif-header {
        background-color: #ed2323;
      }

      .notif-body {
        background-color: #6e0004;
      }
    }

    &.updatedApproved {
      .notif-header {
        background-color: #10cf3b;
      }

      .notif-body {
        background-color: #0a6e14;
      }
    }
  }

  .green {
    color: #10cf3b;
  }

  .location-search {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 348px;
    z-index: 1;

    .exit {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 10px;
      font-size: 16px;
      color: #a7abaf;
    }

    .address-search {
      background: black;
    }

    input[type="text"] {
      width: 347px;
      height: 40px;
      border: 1px solid #006888;
      border-radius: 4px;
      box-shadow: 0 0 8px 0 #000000;
      background-color: #0e151d;
    }

    .moderation & input[type="text"] {
      box-shadow: none;
    }

    ul {
      margin: 0px;
      font-size: 12px;
    }
  }

  input::placeholder {
    font-size: 12px;
    font-weight: normal;
    color: #5e656f;
  }

  .full-width {
    width: 100%;
  }

  .placed-apart {
    display: flex;
    justify-content: space-between;
  }
  .side-by-side {
    display: flex;

    > div + div {
      margin-left: 8px;
    }
  }

  .align-bottom {
    align-items: flex-end;
  }

  .align-center {
    align-items: center;
  }

  .location-input {
    width: 158px;
  }

  .clip-icon {
    border-radius: 4px;
    color: #5e656f;
    border: solid 2px #5e656f;
    box-sizing: border-box;
    padding: 0px 3px;
    width: 24px;
    background-color: #1d2026;
    font-size: 18px;
    cursor: pointer;
  }

  .tooltip-container .clip-tooltip {
    left: -50%;
    margin-left: 22px;
  }

  .clip-icon.fa-volume-down {
    color: #22c9ff;
    border: solid 2px #22c9ff;
  }

  .chat {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .chat-history {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    will-change: transform;
    height: 100%;
    padding: 16px 0px;
    font-size: 14px;
    word-wrap: break-word;
    width: 100%;
  }

  .chat-message {
    position: relative;
    color: #a7abaf;
    width: 100%;
    padding: 0px 16px;
    transition: background-color 1s ease;

    & + & {
      margin-top: 4px;
    }

    .chat-text {
      display: inline-block;
      width: 100%;
      padding-right: 40px;
    }

    .username {
      color: white;

      &.nearby {
        color: rgb(255, 99, 0);
      }
    }

    .mention {
      color: white;
      font-weight: bold;
    }

    &.highlighted {
      background-color: #00465c;
    }

    &.mod .username {
      color: #10cf3b;
    }

    &.self {
      color: #22c9ff;

      .chat-timestamp {
        color: #22c9ff;
      }

      .username {
        color: #22c9ff;
      }
    }

    .chat-timestamp {
      position: absolute;
      right: 16px;
      top: 0px;
      color: #a7abaf;
    }
  }

  .chat-input {
    position: relative;
    height: 60px;
    padding: 8px;
  }

  .new-chat .chat-input {
    .mention-typeahead {
      background: #171c26;
      color: #8f9fbf;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
    }
  }

  .full {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 0;
  }

  @media (max-width: 949px) {
    .hide-narrow {
      display: none;
    }
  }

  .blue {
    color: #22c9ff;
  }

  .logo-footer {
    margin-bottom: 40px;
  }

  .hidden {
    display: none;
  }

  .invisible {
    visibility: hidden;
    position: absolute;
    z-index: -1000;
    top: 0px;
    left: 0px;
    height: 0px;
    width: 0px;

    .twitter-share-button {
      visibility: hidden !important;
    }
  }

  .slider {
    width: 40px;
    height: 24px;
    border-radius: 40px;
    background-color: #5e656f;
    padding: 2px;
    padding-left: 2px;
    transition: padding-left 0.2s, color 0.2s, background-color 0.2s;
    color: #5e656f;
    position: relative;

    &.enabled {
      padding-left: 18px;
      background-color: #0285d4;
      color: #0099cc;

      .slider-inner {
        background-color: white;
      }
    }

    .slider-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: #a7abaf;
      border-radius: 10px;
      transition: color 0.2s, background-color 0.2s;
    }
  }

  .mod-panel-big {
    width: 694px;
    min-width: 694px;
    background-color: #040e15;
    margin-right: 16px;
  }

  .mod-panel {
    width: 348px;
    min-width: 348px;
    background-color: #0e151d;
    border-right: 1px solid #333333;
    display: flex;
    height: 100%;
    overflow-y: scroll;
  }

  .mod-panel::-webkit-scrollbar {
    display: none;
  }

  .gray {
    color: #5e656f;
  }

  .update-editor .clips {
    margin-top: 16px;
  }

  .dialog {
    z-index: 5;
    position: absolute;
    background: #1d2026;
    box-shadow: 0 0 8px 0 #000000;
  }

  .incident-level {
    color: white;
  }

  .submit-panel {
    width: 100%;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: row;

    > * + * {
      margin-left: 8px;
    }

    .error {
      position: absolute;
      bottom: 0px;
    }

    button {
      font-size: 12px;
      font-weight: bold;
      text-transform: none;
      height: 32px;
      border-radius: 20px;
      background-color: #072833;
      border: 1px solid #22c9ff;
      color: white;
      padding: 0 8px;

      &.approve {
        width: 80px;
        background-color: #03290c;
        border: 1px solid #10cf3b;
      }

      &.reject {
        width: 80px;
        background-color: #330a0a;
        border: 1px solid #ed2323;
      }

      &.submit {
        width: 128px;
      }

      &:disabled {
        background-color: #1d2026;
        color: #5e656f;
        border: 1px solid #1d2026;
      }
    }
  }

  .update-stream .stream-mod-controls,
  .update-image-content .stream-mod-controls {
    display: flex;
    max-width: 290px;

    > button,
    > .mod-control-item {
      flex-grow: 1;
      font-size: 12px;
      height: 24px;
      background-color: #072833;
      border: solid 1px #006888;
    }
  }

  .rounded-left {
    border-radius: 4px 0 0 4px;
  }
  .rounded-right {
    border-radius: 0 4px 4px 0;
  }

  .Dropdown-root {
    display: inline-block;
    width: 100px !important;
    height: 1.5em !important;
    margin: 10px !important;
    background-color: #aaaaaa !important;
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .rjsf-form-class {
    .field {
      margin-bottom: 15px
    }
    .field > fieldset {
      border: none;
    }
    label {
      display: none;
    }
    .form-group.field.field-number {
      width: 100%;
    }
  }
`;

export default legacyGlobalStyles;
