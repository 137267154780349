import classNames from "classnames";
import styled from "styled-components";

const QueueHeader = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__messaging-queue-panel__queue-header")
    }
  }
)`
  align-items: center;
  color: var(--colors-white);
  display: flex;
  flex: 0 0 56px;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--space-small) var(--space-medium);
  z-index: 1;
`;

QueueHeader.defaultProps = {};

export default QueueHeader;
