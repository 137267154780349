import { useEffect, useRef, useState } from "react"

const LENGTH = 5000

export const useCountdown = () => {
  const [isOn, setIsOn] = useState(false)
  const id = useRef(0)

  useEffect(() => {
    return () => {
      if (id.current) {
        window.clearInterval(id.current)
      }
    }
  }, [])

  const startCountDown = () => {
    setIsOn(true)

    if (id.current) {
      clearTimeout(id.current)
    }

    id.current = window.setTimeout(() => {
      setIsOn(false)
    }, LENGTH)
  }

  const clearCountDown = () => {
    setIsOn(false)
    clearTimeout(id.current)
  }

  return {
    isOn,
    startCountDown,
    clearCountDown,
  }
}
