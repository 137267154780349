import dayjs from "dayjs"
import { isEqual } from "lodash"

const ActionTypes = {
  SetStreams: "setStreams",
  SelectStream: "selectStream",
  SetIncidents: "setIncidents",
  SetToastText: "setToastText",
  SetServiceAreaFilters: "setServiceAreaFilters",
  SetLastActioned: "setLastActioned",
}

const sortFn = streetTeamMembers => (a, b) => {
  // Sort in order of Street Team first then by created at
  if (
    !streetTeamMembers.includes(a.videoStream.userId) &&
    streetTeamMembers.includes(b.videoStream.userId)
  ) {
    return 1
  }
  if (
    streetTeamMembers.includes(a.videoStream.userId) &&
    !streetTeamMembers.includes(b.videoStream.userId)
  ) {
    return -1
  }
  return dayjs(a.videoStream.createdAt).isAfter(dayjs(b.videoStream.createdAt))
    ? 1
    : -1
}

const reducer = (state, action) => {
  let returnState = { ...state }
  switch (action.type) {
    case ActionTypes.SetStreams: {
      const { streams, streetTeamUsers } = action.payload
      const filteredStreams = streams.filter(
        s => s.videoStream && s.videoStream.id !== state.lastStreamActioned,
      )

      const currentSelectedStream =
        state.selectedStreamId &&
        state.streams.find(s => s.videoStream.id === state.selectedStreamId)
      const isCurrentSelectedStreamInStreams =
        !!currentSelectedStream &&
        !!filteredStreams.find(s => s.videoStream.id === state.selectedStreamId)
      if (currentSelectedStream && !isCurrentSelectedStreamInStreams) {
        currentSelectedStream.noLongerLive = true
        filteredStreams.push(currentSelectedStream)
      }

      const newStreamsList = filteredStreams.sort(sortFn(streetTeamUsers))
      returnState.streams = newStreamsList
      returnState.streamIncidentIds = newStreamsList
        .filter(s => s.videoStream.incidentId)
        .map(s => s.videoStream.incidentId)
      break
    }
    case ActionTypes.SelectStream:
      returnState.selectedStreamId = action.payload.id
      returnState.selectedStream = state.streams.find(
        s => s.videoStream.id === action.payload.id,
      )

      break
    case ActionTypes.SetIncidents:
      returnState.streamIncidents = action.payload.mapping
      break
    case ActionTypes.SetToastText:
      if (action.payload.text) {
        returnState.toastText = "Video " + action.payload.text
      } else {
        returnState.toastText = ""
      }
      break
    case ActionTypes.SetServiceAreaFilters:
      returnState.serviceAreaFilters = action.payload.filters
      break
    case ActionTypes.SetLastActioned:
      returnState.lastStreamActioned = action.payload.id
      break
  }

  return isEqual(state, returnState) ? state : returnState
}

export { reducer, ActionTypes }
