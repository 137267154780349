import PropTypes from "prop-types";
import classNames from "classnames";
import styled, { css } from "styled-components";

const VALIDATION_TYPES = {
  ERROR: "error",
  GENERIC: "generic",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning"
};

const Input = styled.input.attrs(
  props => {
    const {
      active,
      disabled,
      focused,
      hovered,
      validation
    } = props;

    return {
      "aria-disabled": disabled,
      className: classNames("form__input", {
        "form__input--is-active": active,
        "form__input--is-disabled": disabled,
        "form__input--is-focused": focused,
        "form__input--is-hovered": hovered,

        "form__input--validation-error":
          validation == VALIDATION_TYPES.ERROR,
        "form__input--validation-generic":
          validation == VALIDATION_TYPES.GENERIC,
        "form__input--validation-info":
          validation == VALIDATION_TYPES.INFO,
        "form__input--validation-success":
          validation == VALIDATION_TYPES.SUCCESS,
        "form__input--validation-warning":
          validation == VALIDATION_TYPES.WARNING,
      })
    };
  }
)`
  ${({ theme: { borders, colors } }) => css`
    background: var(--colors-grey-095);
    border: none;
    box-shadow:
      0px 0px 0px transparent,
      var(--borders-full) var(--colors-grey-080);
    border-radius: var(--radii-large);
    color: var(--colors-white);
    display: block;
    font-size: var(--font-sizes-medium);
    height: 40px;
    line-height: var(--line-heights-medium);
    outline: none;
    padding: 0 var(--space-small);
    transition:
      background 125ms cubic-bezier(0.39, 0.575, 0.565, 1),
      box-shadow 125ms cubic-bezier(0.39, 0.575, 0.565, 1),
      color 125ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 100%;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: var(--colors-grey-030);
      font-family: var(--fonts-sans-serif);
    }
    &::-ms-input-placeholder {
      color: var(--colors-grey-030);
      font-family: var(--fonts-sans-serif);
    }

    &:active, &.form__input--is-active,
    &:hover, &.form__input--is-hovered
    {
      box-shadow:
        ${ borders.full } ${ colors.grey030 };
    }

    &:focus, &.form__input--is-focused
    {
      box-shadow:
        ${ borders.outer.highlight.full } ${ colors.grey070 },
        ${ borders.full } ${ colors.grey030 };
    }

    &.form__input--validation-error {
      box-shadow:
        0px 0px 0px transparent,
        ${ borders.full } ${ colors.red040 };

      &:active, &.form__input--is-active,
      &:hover, &.form__input--is-hovered
      {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.red030 };
      }

      &:focus, &.form__input--is-focused
      {
        box-shadow:
          ${ borders.outer.highlight.full } ${ colors.red040 },
          ${ borders.full } ${ colors.red030 };
      }
    }

    &.form__input--validation-info {
      box-shadow:
        0px 0px 0px transparent,
        ${ borders.full } ${ colors.blue040 };

      &:active, &.form__input--is-active,
      &:hover, &.form__input--is-hovered
      {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.blue030 };
      }

      &:focus, &.form__input--is-focused
      {
        box-shadow:
          ${ borders.outer.highlight.full } ${ colors.blue040 },
          ${ borders.full } ${ colors.blue030 };
      }
    }

    &.form__input--validation-success {
      box-shadow:
        0px 0px 0px transparent,
        ${ borders.full } ${ colors.green040 };

      &:active, &.form__input--is-active,
      &:hover, &.form__input--is-hovered
      {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.green030 };
      }

      &:focus, &.form__input--is-focused
      {
        box-shadow:
          ${ borders.outer.highlight.full } ${ colors.green040 },
          ${ borders.full } ${ colors.green030 };
      }
    }

    &.form__input--validation-warning {
      box-shadow:
        0px 0px 0px transparent,
        ${ borders.full } ${ colors.yellow040 };

      &:active, &.form__input--is-active,
      &:hover, &.form__input--is-hovered
      {
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.yellow030 };
      }

      &:focus, &.form__input--is-focused
      {
        box-shadow:
          ${ borders.outer.highlight.full } ${ colors.yellow040 },
          ${ borders.full } ${ colors.yellow030 };
      }
    }

    // Give disabled styling priority.
    && {
      &.form__input--is-disabled {
        background: var(--colors-grey-090);
        box-shadow:
          0px 0px 0px transparent,
          ${ borders.full } ${ colors.grey085 };
        color: var(--colors-grey-070);
        cursor: default;
      }
    }
  `}
`;

Input.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool,
  validation: PropTypes.oneOf(
    [
      VALIDATION_TYPES.ERROR,
      VALIDATION_TYPES.GENERIC,
      VALIDATION_TYPES.INFO,
      VALIDATION_TYPES.SUCCESS,
      VALIDATION_TYPES.WARNING
    ]
  )
};

Input.defaultProps = {
  active: false,
  disabled: false,
  focused: false,
  hovered: false,
  validation: VALIDATION_TYPES.GENERIC
};

export default Input;
