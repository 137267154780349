import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Popper } from "react-popper";

import useDropdownContext from "../../hooks/useDropdownContext";
import { MenuContext } from "../../hooks/useMenuContext";
import { getPopperPlacement } from "../../utils/placements";
import StyledMenu from "../../views/Menu/Menu";
import StyledMenuWrapper from "../../views/Menu/MenuWrapper";

const Menu = props => {
  const {
    placement,
    popperModifiers,
    eventsEnabled,
    isAnimated,
    maxHeight,
    style: menuStyle,
    zIndex,
    children,
    ...otherProps
  } = props;

  const {
    itemIndexRef,
    previousIndexRef,
    nextItemsHashRef,
    popperReferenceElementRef,
    downshift: { isOpen, getMenuProps }
  } = useDropdownContext();

  const scheduleUpdateRef = useRef(undefined);

  useEffect(() => {
    // Recalculate popper placement while open to allow animations to complete.
    // This must be ran every render to allow for the number of items to change
    // and still be placed correctly.
    if (isOpen) {
      scheduleUpdateRef.current && scheduleUpdateRef.current();
    }
  });

  const [isVisible, setVisible] = useState(isOpen);

  useEffect(() => {
    let timeout;

    if (isOpen) {
      setVisible(true);
    } else if (isAnimated) {
      // Match the duration of the menu fade out transition.
      timeout = setTimeout(() => setVisible(false), 200);
    } else {
      setVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [isOpen, isAnimated]);

  // Reset Downshift refs on every render
  itemIndexRef.current = 0;
  nextItemsHashRef.current = {};
  previousIndexRef.current = undefined;

  const popperPlacement = getPopperPlacement(placement);

  return (
    <MenuContext.Provider value={{ itemIndexRef }}>
      <Popper
        // Disable position updating on scroll events while menu is closed
        eventsEnabled={isOpen && eventsEnabled}
        modifiers={popperModifiers}
        placement={popperPlacement}
      >
        {
          ({ ref, style, scheduleUpdate, placement: currentPlacement }) => {
            const popperReferenceElement = popperReferenceElementRef.current;
            let computedStyle = menuStyle;

            scheduleUpdateRef.current = scheduleUpdate;

            // Calculate custom width if ref is provided from Select or
            // Autocomplete
            if (
              popperReferenceElement &&
              popperReferenceElement.getBoundingClientRect
            ) {
              computedStyle = {
                width: popperReferenceElement.getBoundingClientRect().width,
                ...menuStyle
              };
            }

            const menuProps = getMenuProps({
              isAnimated: isAnimated && (isOpen || isVisible),
              placement: currentPlacement,
              ...otherProps
            });

            return (
              <StyledMenuWrapper
                ref={isOpen ? ref : undefined}
                placement={menuProps.placement}
                style={style}
                isHidden={!isOpen}
                isAnimated={menuProps.isAnimated}
                zIndex={zIndex}
              >
                <StyledMenu
                  maxHeight={maxHeight}
                  style={computedStyle}
                  {...menuProps}
                >
                  {
                    (isOpen || isVisible) && children
                  }
                </StyledMenu>
              </StyledMenuWrapper>
            );
          }
        }
      </Popper>
    </MenuContext.Provider>
  );
};

Menu.propTypes = {
  ...StyledMenu.propTypes,
  eventsEnabled: PropTypes.bool,
  isAnimated: PropTypes.bool,
  maxHeight: PropTypes.string,
  // These placements differ from the default naming of Popper.JS placements to
  // help assist with RTL layouts.
  placement: PropTypes.oneOf([
    "auto",
    "bottom",
    "bottom-end",
    "bottom-start",
    "end",
    "end-bottom",
    "end-top",
    "start",
    "start-bottom",
    "start-top",
    "top",
    "top-end",
    "top-start"
  ]),
  popperModifiers: PropTypes.any,
  style: PropTypes.object,
  zIndex: PropTypes.number
};

Menu.defaultProps = {
  ...StyledMenu.defaultProps,
  eventsEnabled: true,
  isAnimated: true,
  maxHeight: "400px",
  placement: "bottom-start",
  zIndex: 1000
};

export default Menu;
