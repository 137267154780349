import React from "react";

import { Box } from "@guardian/UI/Box";
import { Button, ButtonIcon, ButtonGroup } from "@guardian/UI/Button";
import {
  Input,
  Field
} from "@guardian/UI/Form";
import { Icon } from "@guardian/UI/Icon";
import { Spinner } from "@guardian/UI/Loader";
import {
  Body,
  Close,
  Footer,
  Header,
  Modal
} from "@guardian/UI/Modal";
import { MediumParagraph } from "@guardian/UI/Paragraph";

const ReassignFormModal = props => {
  const {
    agentUserId,
    locked,
    loading,
    onChangeAgentUserId,
    onClose,
    onConfirm,
    onExit,
    open,
    submitDisabled
  } = props;

  const handleChange = ({ target: { value } }) => {
    onChangeAgentUserId(value);
  };

  return (
    <Modal
      backdropProps={
        {
          closeDisabled: locked
        }
      }
      onClose={onClose}
      onExit={onExit}
      open={open}
    >
      <Header>Reassign Agent Chat Session</Header>
      <Body>
        <Box>
          <MediumParagraph>
            Enter the alphanumeric User ID for the Agent to which whom you wish
            to reassign this Agent Chat Session.
          </MediumParagraph>
          <MediumParagraph>
            Please ensure the Agent has been made aware of this action on your
            part before reassigning the Agent Chat Session.
          </MediumParagraph>
        </Box>
        <Box mt="medium">
          <Field>
            <Input
              onChange={handleChange}
              placeholder={"Agent User ID"}
              value={agentUserId}
            />
          </Field>
        </Box>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button disabled={locked} onClick={onClose} tightenY>
            Cancel
          </Button>
          <Button
            disabled={locked || submitDisabled}
            isPrimary
            onClick={onConfirm}
            tightenY
          >
            Reassign
            <ButtonIcon.End>
              {
                loading ? (
                  <Spinner/>
                ) : (
                  <Icon.Check/>
                )
              }
            </ButtonIcon.End>
          </Button>
        </ButtonGroup>
      </Footer>
      <Close disabled={locked}/>
    </Modal>
  );
};

export default ReassignFormModal;
