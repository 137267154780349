export enum ISymbolDTMFType {
  DIGIT = "digit",
  CHAR = "char"
}

export interface ISymbol {
  value: number | string,
  dtmfType: ISymbolDTMFType,
  options?: {
    disabled?: boolean
  }
}
