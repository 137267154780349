import classNames from "classnames";
import styled from "styled-components";

const Body = styled.div.attrs(
  props => {
    return {
      className: classNames("modal__body")
    };
  }
)`
  color: var(--colors-white);
  padding: var(--space-medium) var(--space-x-large) var(--space-x-large);
`;

Body.propTypes = {};

Body.defaultProps = {};

export default Body;
