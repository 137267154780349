import React, { useState } from "react"
import { number, func, string } from "prop-types"
import classnames from "classnames"
import style from "./Input.module.css"

const NumberIteratorInput = ({ defaultValue, onChange, label, min, max }) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <div className={classnames(style.numberIterator, style.input)}>
      <h5 className={style.label}>
        {label}: <span>{value}</span>
      </h5>
      <div className={style.iterator}>
        <button
          className={style.iterate}
          onClick={() => {
            const val = Math.min(max, value + 1)
            onChange(val)
            setValue(val)
          }}
        >
          <i className='fas fa-chevron-up' />
        </button>
        <button
          className={style.iterate}
          onClick={() => {
            const val = Math.max(min, value - 1)
            onChange(val)
            setValue(val)
          }}
        >
          <i className='fas fa-chevron-down' />
        </button>
      </div>
    </div>
  )
}

NumberIteratorInput.propTypes = {
  defaultValue: number,
  onChange: func,
  label: string,
  min: number,
  max: number,
}

export default NumberIteratorInput
