import styled from "styled-components";

const MessageWrapper = styled.div`
  align-items: center;
  color: var(--colors-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--space-small);
  text-align: center;
  width: 100%;
`;

export default MessageWrapper;
