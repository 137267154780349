import React from "react";

import TabBar from "./components/TabBar";
import Tabs from "./components/Tabs";
import StyledTabsPanel from "./views/TabsPanel"

const TabsPanel = props => {
  const { session } = props;

  return (
    <StyledTabsPanel>
      <TabBar session={session}/>
      <Tabs session={session}/>
    </StyledTabsPanel>
  );
};

export default TabsPanel;
