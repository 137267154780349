import React, { createContext, useContext } from "react";

export const MenuContext = createContext(undefined);

const useMenuContext = () => {
  const menuContext = useContext(MenuContext);
  return menuContext;
};

export default useMenuContext;
