import { OptimusAPI } from "@guardian/API/Optimus";
import { ISafetyFeedIncident, ISafetyFeedPointOfInterest } from '@guardian/UI/Map/types';

interface AgentChatsGetGroupMessagesParams {
  limit?: string
  before?: string
};

interface AgentChatsReassignChatSessionData {
  reassignToAgentUserId: string,
  sessionId: string
};

interface AgentChatsSendAgentActionData {
  sessionId: string,
  action: { [key: string]: any }
};

interface AgentChatsUpdateAssignedChatSessionLifecycleData {
  sessionId: string,
  eventType: string,
  eventValue: { [key: string]: any }
};

interface AgentChatsUpdateAvailabilityData {
  agentId: string
  available: boolean
};

interface AgentChatsSendGroupMessageData {
  id: string
  groupId: string
  body: { [key: string]: any }
};

interface ISafetyFeed {
  nearbyIncidents: ISafetyFeedIncident[]
  pois: ISafetyFeedPointOfInterest[]
}

interface AgentChatsSendOutboundMessageToUsersData {
  text: string
  userIds: string[]
};

const AgentChats = {
  endAssignedChatSession: (sessionId: string, options: any = {}) => {
    return OptimusAPI.delete(
      `/v2/protect/agent_chat/${ sessionId }`,
      options
    );
  },

  getSafetyFeed: (sessionId: string, options: any={}) => {
    return OptimusAPI.get<ISafetyFeed>(
      `/v2/protect/agent_chat/${ sessionId }/safety_feed`,
      options
    );
  },

  getAssignedChatSessions: (agentId: string, options: any={}) => {
    return OptimusAPI.get(
      `/v2/protect/agents/${ agentId }/agent_chat`,
      options
    );
  },

  getAssignedChatSessionUserInfo: (sessionId: string, options: any={}) => {
    return OptimusAPI.get(
      `/v2/protect/agent_chat/${ sessionId }/owner`,
      options
    );
  },

  getAssignedChatSessionUserRecords: (sessionId: string, options: any={}) => {
    return OptimusAPI.get(
      `/v2/protect/agent_chat/${ sessionId }/records`,
      options
    );
  },

  getGroupInfo: (groupId: string, options: any = {}) => {
    return OptimusAPI.get(`/v1/users/group/${ groupId }/info`, options);
  },

  getGroupMessages: (
    groupId: string,
    rawParams: AgentChatsGetGroupMessagesParams,
    options: any = {}
  ) => {
    const params = new URLSearchParams({ ...rawParams }).toString();
    return OptimusAPI.get(
      `/direct_message/group/${ groupId }?${ params }`,
      options
    );
  },

  getMacros: (options: any = {}) => {
    return OptimusAPI.get(`/v2/protect/agent_chat_macros`, options);
  },

  reassignAgentChatSession: (
    data: AgentChatsReassignChatSessionData,
    options: any = {}
  ) => {
    const { sessionId, ...restData } = data;
    return OptimusAPI.put(
      `/v2/protect/agent_chat/${ sessionId }/agent`,
      restData,
      options
    );
  },

  sendGroupMessage: (
    data: AgentChatsSendGroupMessageData,
    options?: any
  ) => {
    return OptimusAPI.post(`/direct_message/group`, data, options);
  },

  sendOutboundMessageToUsers: (
    data: AgentChatsSendOutboundMessageToUsersData,
    options?: any
  ) => {
    return OptimusAPI.post(
      `/v2/protect/agent_chat/outbound_message`,
      data,
      options
    );
  },

  sendAgentAction: (
    data: AgentChatsSendAgentActionData,
    options: any = {}
  ) => {
    const { sessionId, action } = data;
    return OptimusAPI.post(
      `/v2/protect/agent_chat/${ sessionId }/agent/action`,
      action,
      options
    );
  },

  updateAssignedChatSessionLifecycle: (
    data: AgentChatsUpdateAssignedChatSessionLifecycleData,
    options: any = {}
  ) => {
    const { sessionId, ...restData } = data;
    return OptimusAPI.post(
      `/v2/protect/agent_chat/${ sessionId }/lifecycle`,
      restData,
      options
    );
  },

  updateAvailability: (
    data: AgentChatsUpdateAvailabilityData,
    options: any = {}
  ) => {
    const { agentId, ...restData } = data;
    return OptimusAPI.put(
      `/v2/protect/agents/${ agentId }/agent_chat/availability`,
      restData,
      options
    );
  },

  pingStatusOnline: (
    agentId: string,
    options: any = {}
  ) => OptimusAPI.post(
    `/v2/protect/agents/${agentId}/agent_chat/online`,
    undefined,
    options
  ),
};

export default AgentChats;
