import useAssignedAgentChatSessions from "./hooks/useAssignedAgentChatSessions";
import useAvailableStatus from "./hooks/useAvailableStatus";
import usePingStatusOnline from "./hooks/usePingStatusOnline";

interface QueryOptions {
  enabled: string
};

const useAgentChat = (options: QueryOptions) => {
  const {
    enabled
  } = options;

  const {
    assignedAgentChatSessions,
    isError,
    isLoading,
    setVisibleSessionId,
    totalUnreadCount,
    visibleSessionId
  } = useAssignedAgentChatSessions({ enabled: enabled });

  const {
    available,
    toggleAvailable
  } = useAvailableStatus({ enabled: enabled });

  usePingStatusOnline({ enabled: enabled });

  return {
    assignedAgentChatSessions,
    available,
    totalUnreadCount,
    isError,
    isLoading,
    setVisibleSessionId,
    toggleAvailable,
    visibleSessionId
  };
};

export default useAgentChat;
