import React, { useEffect, useRef, useState } from "react";

import { AgentChatService } from "@guardian/Services/AgentChat";
import { LoggingService } from "@guardian/Services/Logging";

import useMenuContext from "../../../../hooks/useMenuContext";
import { MENU_ITEM_KEY } from "../../constants";

import LiveCallModal from "./components/LiveCallModal";

const LiveCallHandler = props => {
  const { selection, session } = useMenuContext();

  const [callError, setCallError] = useState(null);
  const [callTrigger, setCallTrigger] = useState(null);

  const [modalLoading, setModalLoading] = useState(false);
  const [modalLocked, setModalLocked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const sessionRef = useRef(session);

  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  useEffect(() => {
    if (selection == null) { return; }

    if (selection.value === MENU_ITEM_KEY) {
      setModalOpen(true);
    }
  }, [selection]);

  useEffect(() => {
    if (callTrigger == null) {
      return;
    }

    let artificialDelay;

    const controller = new AbortController();
    const signal = controller.signal;

    const submit = () => {
      const data = {
        sessionId: sessionRef.current.id,
        action: {
          "requestLiveCall": true
        }
      };

      const options = { signal };

      setCallError(null);
      setModalLoading(true);
      setModalLocked(true);

      artificialDelay = setTimeout(() => {
        AgentChatService.sendAgentAction(data, options)
          .then(() => {
            setModalOpen(false);
            setModalLoading(false);
          })
          .catch(error => {
            if (signal.aborted) {
              return;
            }

            setCallError(error);
            setModalLoading(false);
            setModalLocked(false);
          });
      }, 250);
    };

    submit();

    return () => {
      clearTimeout(artificialDelay);
      controller.abort();
    }
  }, [callTrigger]);

  useEffect(() => {
    if (callError == null) {
      return;
    }

    LoggingService.logError(
      "Unable to initiate Live Call.",
      {
        domain: "LiveCallHandler",
        error: callError,
        flash: true,
        trackedData: {
          error: callError,
          session: sessionRef.current
        }
      }
    );
  }, [callError]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmCall = () => {
    setCallTrigger(+new Date());
  };

  const handleExitModal = () => {
    setCallError(null);
    setModalLoading(false);
    setModalLocked(false);
  };

  return (
    <LiveCallModal
      locked={modalLocked}
      loading={modalLoading}
      onClose={handleCloseModal}
      onConfirm={handleConfirmCall}
      onExit={handleExitModal}
      open={modalOpen}
    />
  );
};

export default LiveCallHandler;
