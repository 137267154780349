import React, { useMemo } from "react";
import nameInitials from "name-initials";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import { stringToThemeColor } from "../utils/stringToColor";
import textToSize from "../utils/textToSize";
import StyledFallback from "../views/Fallback";

const Fallback = React.memo(function Fallback(props) {
  const {
    id,
    fullName,
    theme: {
      colors,
      fonts,
      fontWeights
    }
  } = props;

  // Pad should be a decimal number representing the amount of padding to
  // place around text within the Avatar when scaling. Think of it as a
  // percentage (just in decimal form) of the whole avatar, no matter what size
  // it scales to. Consider an Avatar that was 100px in diameter, in which you
  // wanted 20px of "padding" all around. In this case, you would set pad to
  // 0.2, as 20px of padding is 20%, or .2, of the whole 100px.
  const pad = 0.2; // As percent of container.
  const size = 100;
  const paddedSize = size - ((size * pad) * 2);

  const initials = useMemo(() => (nameInitials(fullName)), [fullName]);

  const backgroundColor = useMemo(() => (stringToThemeColor(id)), [id]);
  const foregroundColor = colors.black;

  const { w: tWidth, h: tHeight } = useMemo(() => {
    return textToSize(initials, {
      fontFamily: fonts.sansSerif,
      fontWeight: fontWeights.normal
    });
  }, [initials]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${ size } ${ size }`}
    >
      {
        // The visual Avatar circle, taking full size of container. Viewbox just
        // keeps things in place during scaling.
      }
      <circle
        cx="50%"
        cy="50%"
        r="50%"
        style={{ fill: `${ backgroundColor }` }}
      />
      {
        // Container atop (visually within) Avatar circle, representing the
        // available space given padding.
      }
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${ paddedSize }`}
        height={`${ paddedSize }`}
        viewBox={`0 0 ${ paddedSize } ${ paddedSize }`}
        x={`${ pad * 100 }%`}
        y={`${ pad * 100 }%`}
        style={
          {
            width: "100%",
            height: "100%"
          }
        }
      >
        {
          // Container around text, keeping bounding box sized according to our
          // computed width and height of our pre-rendered (virtual) text. This
          // ensures that during resizing the text's bounding box will match
          // its width/height ratio.
        }
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${ tWidth } ${ tHeight }`}
        >
          {
            // The text within our Avatar.
          }
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            dy="0.1em"
            textAnchor="middle"
            fill={foregroundColor}
          >
            { initials }
          </text>
        </svg>
      </svg>
    </svg>
  );
});

Fallback.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  theme: PropTypes.any
};

Fallback.defaultProps = {};

export default withTheme(Fallback);
