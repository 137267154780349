import _ from "lodash"
import {
  IAction,
  IState,
  TSOSVideoContextActionType,
} from "@guardian/Components/ModSOS/store/video/types"

export const reducer = (state: IState, action: IAction) => {
  let returnState = { ...state }
  switch (action.type) {
    case TSOSVideoContextActionType.UpdateUserSpeakerMuted:
      returnState.userSpeakerMuted = action.data
      break
    case TSOSVideoContextActionType.UpdateUserDisabledAgentAudio:
      returnState.userDisabledAgentAudio = action.data
      break
    case TSOSVideoContextActionType.UpdateUserDisabledAgentVideo:
      returnState.userDisabledAgentVideo = action.data
      break
    case TSOSVideoContextActionType.UpdateUserMicDisabled:
      returnState.userMicDisabled = action.data
      break
    case TSOSVideoContextActionType.UpdateVideoProvider:
      returnState.videoProvider = action.data
      break
    case TSOSVideoContextActionType.UpdateVideoDeviceId:
      returnState.videoDeviceId = action.data
      break
    case TSOSVideoContextActionType.UpdateTwilioRoom:
      returnState.twilioRoom = action.data
      break
    case TSOSVideoContextActionType.UpdateTwilioOwnerParticipant:
      returnState.twilioOwnerParticipant = action.data
      break
    case TSOSVideoContextActionType.UpdateTwilioDialOutParticipant:
      returnState.twilioDialOutParticipant = action.data
      break
    case TSOSVideoContextActionType.AddTwilioContact:
      returnState.twilioContacts = _.unionBy(
        state.twilioContacts,
        [action.data],
        (comp: any) => comp.identity,
      )
      break
    case TSOSVideoContextActionType.RemoveTwilioContact:
      returnState.twilioContacts = _.filter(
        state.twilioContacts,
        (contact: any) => contact.identity !== action.data.identity,
      )
      break
    case TSOSVideoContextActionType.UpdateTokboxOwnerStream:
      returnState.tokboxOwnerStream = action.data
      break
    case TSOSVideoContextActionType.UpdateTokbox911Stream:
      returnState.tokbox911Stream = action.data
      break
    case TSOSVideoContextActionType.AddTokboxWatcherStream:
      returnState.tokboxWatcherStreams = _.unionBy(
        state.tokboxWatcherStreams,
        [action.data],
        (comp: any) => comp.id,
      )
      break
    case TSOSVideoContextActionType.RemoveTokboxWatcherStream:
      returnState.tokboxWatcherStreams = _.filter(
        state.tokboxWatcherStreams,
        (watcher: any) => watcher.id !== action.data.id,
      )
      break
    case TSOSVideoContextActionType.UpdateTokboxSession:
      returnState.tokboxSession = action.data
      break
    case TSOSVideoContextActionType.Reset:
      returnState = action.data
      break
  }
  return returnState
}
