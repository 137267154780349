import React, { useRef, useState } from "react";

import { Flash } from "@guardian/UI/Alert";
import {
  FauxInput,
  Field
} from "@guardian/UI/Form";

import CopyButton from "./components/CopyButton";
import InnerWrapper from "./views/InnerWrapper";
import Input from "./views/Input";

const Log = props => {
  const {
    content,
    title
  } = props;

  const fieldRef = useRef(null);

  const [focused, setFocused] = useState(false);

  const select = () => {
    if (fieldRef.current == null) { return false; }

    const field = fieldRef.current;
    const range = document.createRange();
    const sel = window.getSelection();

    range.selectNodeContents(field);

    sel.removeAllRanges();
    sel.addRange(range);

    return true;
  };

  const handleFocused = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const copyToClipboard = () => {
    if (select()) {
      document.execCommand("copy");
      Flash.generic(
        `The ${ title } log history was copied to your clipboard.`,
        {
          title: "Copied"
        }
      );
    }
  };

  return (
    <Field>
      <FauxInput style={{ height: "400px" }} focused={focused}>
        <InnerWrapper>
          <Input
            ref={fieldRef}
            onBlur={handleBlur}
            onFocus={handleFocused}
          >
            { content }
          </Input>
          <CopyButton onClick={copyToClipboard}/>
        </InnerWrapper>
      </FauxInput>
    </Field>
  );
};

export default Log;
