import classNames from "classnames";
import styled from "styled-components";

const AgentMessage = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__agent-message"
      )
    }
  }
)`
  align-items: center;
  background: var(--colors-white);
  color: var(--colors-grey-100);
  display: inline-flex;
  flex-direction: row;
  font-size: var(--font-sizes-large);
  justify-content: flex-start;
  padding: var(--space-x-small) var(--space-medium);
  white-space: pre-wrap; // Support line breaks in text.

  // Note: We aren't using our theme line heights here. We decided that messages
  // could use their own one off line heights to better match the condensed
  // (as opposed to our theme line heights) line heights people are more used to
  // in apps like iMessage (which uses 1.25).
  line-height: 1.25;

  // NOTE: We want our messages to appear with "pill" style border radii when
  // displayed in a single line, but to have equally rounded borders when the
  // message body has to group vertically to contain larger/line-wrapped
  // content. To handle this we calculate the min height (a single line) for our
  // messsages using the line height and vertical padding, and then use this
  // value for our border radii. It's worth adding that this loses some reliance
  // on consistency with our other border radii. If we want to have a preset
  // radii for only expanded messages, we may need to revert to using JS.
  border-radius: calc(var(--agent-message-min-height) / 2.0);
  min-height: var(--agent-message-min-height);
  max-width: min(100%, 340px);

  & + & {
    margin-bottom: var(--space-xxx-small);
  }
`;

export default AgentMessage;
