import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "./Button";

const SIZES = {
  SMALL: "small",
  MEDIUM: "medium"
};

export const IconButton = styled(Button).attrs(
  props => {
    const {
      className,
      size
    } = props;

    return {
      className: classNames(className, "icon-button", {
        "icon-button--small": size == SIZES.SMALL,
        "icon-button--medium": size == SIZES.MEDIUM
      })
    };
  }
)`
  padding: 0;

  &.icon-button--small {
    height: 32px;
    width: 32px;
  }

  &.icon-button--medium {
    height: 42px;
    width: 42px;
  }
`;

IconButton.propTypes = {
  ...Button.propTypes,
  size: PropTypes.oneOf([
    SIZES.SMALL,
    SIZES.MEDIUM
  ]).isRequired
};

IconButton.defaultProps = {
  ...Button.defaultProps,
  pill: true,
  size: SIZES.MEDIUM
};

export default IconButton;
