import { useEffect, useRef } from "react"

export default function useClickOutside(
  refs: React.RefObject<HTMLElement>[],
  callback: (e: MouseEvent) => void,
  enabled = true,
) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (!enabled) {
      return
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (refs.some(ref => ref.current?.contains(e.target as Node))) {
        return
      }
      callbackRef.current(e)
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [enabled])
}
