import { useQuery } from "@tanstack/react-query"

import { ClipService } from "@guardian/Services/Radio"
import type { Clip } from "@guardian/Types/Clip"

import { useAuditToolContext } from "@guardian/Components/AuditTool/store"

export const useClipQuery = (clipIds: string[]) => {
  const { setClips } = useAuditToolContext()

  return useQuery({
    queryKey: ["clips", clipIds],
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      ClipService.getClips(
        {
          channels: [""],
          withFlagStats: true,
          withMetadata: true,
          includeClips: clipIds,
        },
        { signal },
      ) as Promise<Clip[]>,
    enabled: clipIds.length > 0,
    onSuccess: clips => {
      setClips(clips)
    },
  })
}
