const fontWeights = [
  400,
  500,
  600,
  700
];

fontWeights.normal = fontWeights[0];
fontWeights.medium = fontWeights[1];
fontWeights.semiBold = fontWeights[2];
fontWeights.bold = fontWeights[3];

export const fontWeightsCSSVariables = `
  :root {
    --font-weights-normal: ${ fontWeights.normal };
    --font-weights-medium: ${ fontWeights.medium };
    --font-weights-semi-bold: ${ fontWeights.semiBold };
    --font-weights-bold: ${ fontWeights.bold };
  }
`;

export default fontWeights;
