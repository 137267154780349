import styled from "styled-components";
import { Col as sbgCol } from "styled-bootstrap-grid";

const Col = styled(sbgCol)`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export default Col;
