import React, { forwardRef } from "react"
import { ButtonProps } from "@material-ui/core/Button"
import style from "./CalloutButton.module.css"

export interface StyledButtonProps extends ButtonProps {
  primary?: boolean
  label?: string
}

const CalloutButton = forwardRef<HTMLButtonElement, StyledButtonProps>(
  ({ onClick, label, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        {...props}
        style={{ textTransform: "none" }}
        className={style.label}
      >
        {label || children}
      </button>
    )
  },
)

export default CalloutButton
