import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "@guardian/Components/ModSOS/components/ActionBar/ButtonWithModal/ButtonWithModal.module.css"
import classnames from "classnames"
import { ConfirmModal } from "@guardian/Components/ModSOS/components/ActionBar/ConfirmModal/ConfirmModal"
import { IProps } from "@guardian/Components/ModSOS/components/ActionBar/ButtonWithModal/types"

export const ButtonWithModal: React.FunctionComponent<IProps> = ({
  confirmText,
  label,
  onClickYes,
  buttonStyles,
  helperLabel,
  helperLabelStyles,
  disabled,
  forceOpen,
  modal,
  onExpanded,
  expanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(forceOpen || false)
  const [computedExpanded, setComputedExpanded] =
    expanded !== undefined && onExpanded
      ? [expanded, onExpanded]
      : [isExpanded, setIsExpanded]
  const containerRef = useRef<any>()

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (containerRef.current.contains(e.target as Node)) return
      setComputedExpanded(false)
    },
    [setComputedExpanded],
  )

  useEffect(() => {
    if (forceOpen !== undefined) setComputedExpanded(forceOpen)
  }, [forceOpen, setComputedExpanded])

  useEffect(() => {
    if (computedExpanded) {
      window.addEventListener("mousedown", handleClick)
    }

    return () => {
      if (computedExpanded) {
        window.removeEventListener("mousedown", handleClick)
      }
    }
  }, [computedExpanded, handleClick])

  const togglePane: () => void = () => {
    setComputedExpanded(!computedExpanded)
  }

  const clickAndToggle: () => void = () => {
    onClickYes()
    togglePane()
  }

  return (
    <div className={styles.buttonContainer} ref={containerRef}>
      {computedExpanded &&
        !disabled &&
        (modal ? (
          modal
        ) : (
          <ConfirmModal
            text={confirmText}
            onClickYes={clickAndToggle}
            onClickNo={togglePane}
          />
        ))}
      <button
        className={classnames(styles.buttonBase, buttonStyles, {
          [styles.disabled]: disabled,
        })}
        onClick={togglePane}
      >
        <div className={styles.flexCol}>
          <div>{label}</div>
          <div className={helperLabelStyles}>{helperLabel}</div>
        </div>
      </button>
    </div>
  )
}

ButtonWithModal.defaultProps = {
  buttonStyles: "",
  helperLabelStyles: "",
  helperLabel: "",
  disabled: false,
}
