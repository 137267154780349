import { FC, useEffect } from 'react'
import { IProps } from './types'

export const Route: FC<IProps> = ({ map, routeData }) => {
  useEffect(() =>{
    if(map && routeData) {
      map.map.data.setStyle({
        strokeColor: "#4da6ff",
        strokeWeight: 2
      });
      map.map.data.add({
        geometry: new map.maps.Data.LineString(routeData)
      });
    }
  }, [map, routeData])

  return null
}
