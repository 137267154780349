import PropTypes from "prop-types"
import React from "react"

import { eventHub } from "@guardian/State"

import { LoggingService } from "@guardian/Services/Logging"
import { Button } from "@guardian/UI/Button";

import style from "./styles/ErrorBoundary.module.css"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // Log the error
    LoggingService.logError(`${error.name}: ${error.message}`, {
      domain: "ErrorBoundary",
      method: "componentDidCatch",
      consoleLog: true,
      error,
      trackedData: {
        errorInfo,
        error,
      },
    })
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div className={style.container}>
          <div className={style.info}>
            <div className={style.heading}>
              <i className='fas fa-exclamation-triangle' />
              <h1>Something went wrong!</h1>
            </div>
            <h2>Error Message: {error.message}</h2>
            <h3>Follow instructions below help the team debug:</h3>
            <div className={style.instructions}>
              <ol>
                <li>
                  Open your console by right-clicking &gt; Inspect &gt; Console.
                </li>
                <li>Scroll to bottom of your console.</li>
                <li>
                  Take a screenshot of your display, making sure your console is
                  displayed. (You should ideally see some red boxes)
                </li>
                <li>Post screenshot in slack.</li>
                <li>
                  Hard refresh to continue workflow (if crash isn&apos;t
                  persistent)
                </li>
              </ol>

              <hr />

              <Button onClick={() => eventHub.emit("showLogAssistant")}>
                Open Log Tool
              </Button>
            </div>
          </div>
        </div>
      )
    }

    // Render the children if there is no error
    const { children } = this.props
    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ErrorBoundary
