import { MegatronAPI } from "@guardian/API/Megatron";

interface ClipsGetClipsData {
  channels: string[]
  start?: string
  stop?: string
  since?: string
  includeClips: string[]
  withFlagStats: boolean
  withMetadata: boolean
};

interface ClipsRateClipData {
  clipId: string
  score: string
  comments: string
};

interface ClipsSetClipPlayedData {
  clipId: string
  position?: number
  playbackRate?: number
  startTimestamp?: number
  endTimestamp?: number
};

interface ClipsPinClipData {
  clipId: string
};

interface ClipsUnpinClipParams {
  clipId: string
};

interface ClipsGetMultiplayerlayerPinnedClipsParams {
  includeClips?: string[]
};

interface ClipsMultiplayerPinClipData {
  clipId: string
  clipStatus: string
};

const Clips = {
  getClips: (data: ClipsGetClipsData, options?: any) => {
    return MegatronAPI.post(`/radio/clips`, data, options);
  },

  rateClip: (data: ClipsRateClipData, options?: any) => {
    return MegatronAPI.post(`/radio/clip_quality`, data, options);
  },

  setClipPlayed: (data: ClipsSetClipPlayedData, options?: any) => {
    return MegatronAPI.post(`/radio/clips/played`, data, options);
  },

  getPinnedClips: (options?: any) => {
    return MegatronAPI.get(`/radio/pinned_clips`, options);
  },

  pinClip: (data: ClipsPinClipData, options?: any) => {
    return MegatronAPI.post(`/radio/pinned_clips`, data, options);
  },

  unpinClip: (params: ClipsUnpinClipParams, options: any = {}) => {
    return MegatronAPI.delete(
      `/radio/pinned_clips`,
      {
        ...options,
        params: {
          ...options.params,
          ...params
        }
      }
    );
  },

  getMultiplayerPinnedClipsBatch: (data: ClipsGetMultiplayerlayerPinnedClipsParams, options?: any) => {
    return MegatronAPI.post(`/radio/multiplayer_pinned_clips_batch`, data, options);
  },

  getMultiplayerPinnedClips: (rawParams?: ClipsGetMultiplayerlayerPinnedClipsParams, options?: any) => {
    const params = rawParams && new URLSearchParams({
      ...rawParams,
      includeClips: rawParams?.includeClips?.join(",") ?? '',
    }).toString();
    return MegatronAPI.get(`/radio/multiplayer_pinned_clips?${params || ''}`, options);
  },

  multiplayerPinClip: (data: ClipsMultiplayerPinClipData, options?: any) => {
    return MegatronAPI.post(`/radio/multiplayer_pinned_clips`, data, options);
  }
};

export default Clips;
