import { string, bool, number, func } from "prop-types"
import React, { forwardRef } from "react"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import calendar from "@guardian/Utils/dayjs/calendar"
import { shouldReplace } from "@guardian/Utils/history"

import {
  LiveBadge,
  PhotoBadge,
  AnonymousBadge,
  CreatorBadge,
  GoodVibesBadge,
  UserMergedBadge,
  VerifiedBadge,
  UserReportedBadge,
  P2PIncidentVerificationBadge,
  UploadBadge,
} from "@guardian/Components/Badges"

import {
  CardContainer,
  Header,
  HeaderText,
  Badges,
  Info,
  Footer,
} from "./ContentPreview.styles"

dayjs.extend(relativeTime)
dayjs.extend(calendar)

const timeString = time => {
  const date = new Date(time)
  // If the time is more than 24 hours ago, show the date
  if (Date.now() - date.getTime() > 24 * 60 * 60 * 1000) {
    return dayjs(date).calendar(" at")
  }
  return `${dayjs(date).fromNow()} ago`
}

const ContentPreview = forwardRef((props, ref) => {
  const {
    id,
    type,
    title,
    username,
    address,
    createdAt,
    thumbUrl,
    active,
    durationSeconds,
    contentUrl,
    isDone,
    isCreator,
    isUpload,
    isAnonymous,
    isReported,
    isSelfMerged,
    isGoodVibes,
    isVerified,
    incident,
    className,
  } = props
  const isLive = type === "video_stream" && !isDone

  let durationString = ""
  if (isDone && durationSeconds) {
    const parts = [
      Math.floor(durationSeconds / 60), // minutes
      Math.round(durationSeconds % 60), // seconds
    ]
    durationString = parts.map(part => String(part).padStart(2, "0")).join(":")
  }
  return (
    <Link ref={ref} to={contentUrl} replace={shouldReplace(contentUrl)}>
      <CardContainer isActive={active} bgUrl={thumbUrl} className={className}>
        <Header>
          {!!title && <div>{title}</div>}
          <HeaderText style={{ wordBreak: "break-all" }}>
            @{username}
          </HeaderText>
          <HeaderText>Reported {timeString(createdAt)}</HeaderText>
        </Header>
        <Badges>
          {type === "video_stream" && (
            <P2PIncidentVerificationBadge incident={incident} />
          )}
          {isVerified && <VerifiedBadge />}
          {isUpload && <UploadBadge />}
          {isCreator && <CreatorBadge />}
          {isAnonymous && <AnonymousBadge />}
          {isGoodVibes && <GoodVibesBadge />}
          {isSelfMerged && <UserMergedBadge />}
          {isReported && <UserReportedBadge />}
        </Badges>
        <Info>{address}</Info>
        <Footer>
          {isLive && <LiveBadge />}
          {type === "image" && <PhotoBadge />}
          {durationString && <span>{durationString}</span>}
        </Footer>
      </CardContainer>
    </Link>
  )
})

ContentPreview.propTypes = {
  id: string,
  type: string,
  username: string,
  address: string,
  createdAt: string,
  broadcastDone: bool,
  hlsDone: bool,
  thumbUrl: string,
  active: bool,
  durationSeconds: number,
  title: string,
  isCreator: bool,
  isUpload: bool,
  isAnonymous: bool,
  contentUrl: string,
}

export default React.memo(ContentPreview)
