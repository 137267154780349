// Convert Popper.JS placement to corresponding menu position.
export const getMenuPosition = popperPlacement => {
  if (popperPlacement === "auto") {
    return "bottom";
  }

  return popperPlacement ? popperPlacement.split("-")[0] : "bottom";
};

// Convert Garden RTL aware placement to Popper.JS valid placement
export const getPopperPlacement = placement => {
  switch (placement) {
    case "end":
      return "right";
    case "end-top":
      return "right-start";
    case "end-bottom":
      return "right-end";
    case "start":
      return "left";
    case "start-top":
      return "left-start";
    case "start-bottom":
      return "left-end";
    default:
      return placement;
  }
};

export default {
  getMenuPosition,
  getPopperPlacement
};
