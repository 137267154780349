import styled from "styled-components";

const NavigationBarTrailingContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-left: var(--space-xx-large);
  width: 100%;
`;

export default NavigationBarTrailingContent;
