import React from "react"
import classnames from "classnames"

import type { IProps } from "./types"
import styles from "./ConfirmModal.module.css"

export const ConfirmModal: React.FunctionComponent<IProps> = ({
  text,
  onClickYes,
  onClickNo,
  isLeftOrientation,
  offsetLeft,
  offsetTop,
  confirmText,
  declineText,
}) => (
  <div
    className={classnames(styles.modal, { [styles.left]: isLeftOrientation })}
    style={{ left: offsetLeft, top: offsetTop }}
  >
    <div className={styles.actions}>
      <p>{text}</p>
      <div className={styles.flexRow}>
        <button className={styles.button} onClick={onClickYes}>
          {confirmText || "Yes"}
        </button>
        <button className={styles.button} onClick={onClickNo}>
          {declineText || "No"}
        </button>
      </div>
    </div>
  </div>
)
