import styled from "styled-components";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const CopyButton = styled.button`
  ${ buttonResets }

  align-items: center;
  border: none;
  bottom: 0;
  color: var(--colors-blue-040);
  display: flex;
  font-weight: var(--font-weights-bold);
  justify-content: center;
  margin-top: auto;
  padding: var(--space-small);
  position: absolute;
  right: 0;
  transition:
    background-image 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
`;

CopyButton.defaultProps = {};

export default CopyButton;
