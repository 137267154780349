interface TextProperties {
  fontFamily: string
  fontWeight: string
};

interface ComputedTextSizeProperties {
  w: string
  h: string
};

const textToSize = (
  text: string,
  properties: TextProperties
): ComputedTextSizeProperties => {
  const tempTextEl = document.createElement("span");
  tempTextEl.innerHTML = text;

  // NOTE: The font size here is irrelevant and hence not taken as an argument.
  // For the purposes of these functions, we just need to know _a_ width and
  // height to use on the bounding box we will apply to the SVG containing our
  // text, so that the SVG may be sized in proportion to that text's
  // bounding box's _proportions_.
  tempTextEl.style.fontSize   = "18px!important";
  tempTextEl.style.lineHeight = "27px!important";
  tempTextEl.style.position   = "fixed";
  tempTextEl.style.width      = "max-content";

  tempTextEl.style.fontFamily = `${ properties.fontFamily }!important`;
  tempTextEl.style.fontWeight = `${ properties.fontWeight }!important`;

  document.body.appendChild(tempTextEl);

  const rect = tempTextEl.getBoundingClientRect();

  // Low precision is useful here, as the browser tends to round these sizes to
  // two decimal places in reality.
  const size = {
    w: rect.width.toFixed(2),
    h: rect.height.toFixed(2)
  };

  document.body.removeChild(tempTextEl);

  return size;
};

export default textToSize;
