import { AuditIncident } from "@guardian/API/Optimus"
import {
  AuditIncidentKey,
  AuditIncidentAddress,
  AuditIncidentTitleAndUpdate,
} from "@guardian/API/Optimus/resources/AuditIncident"

const AssistedAuthoringService = {
  batchSyncAddress: async (items: AuditIncidentKey[], options: any = {}) => {
    const { data } = await AuditIncident.batchSyncAddress(items, options)
    return data?.items || []
  },

  batchSyncTitleAndUpdate: async (
    items: AuditIncidentKey[],
    options: any = {},
  ) => {
    const { data } = await AuditIncident.batchSyncTitleAndUpdate(items, options)
    return data?.items || []
  },

  patchAddress: async (
    incidentId: AuditIncidentKey["incidentId"],
    clipId: AuditIncidentKey["clipId"],
    payload: Partial<AuditIncidentAddress>,
    options: any = {},
  ) => {
    const { data } = await AuditIncident.patchAddress(
      incidentId,
      clipId,
      payload,
      options,
    )
    return data
  },

  patchTitleAndUpdate: async (
    incidentId: AuditIncidentKey["incidentId"],
    clipId: AuditIncidentKey["clipId"],
    payload: Partial<AuditIncidentTitleAndUpdate>,
    options: any = {},
  ) => {
    const { data } = await AuditIncident.patchTitleAndUpdate(
      incidentId,
      clipId,
      payload,
      options,
    )
    return data
  },
}

export default AssistedAuthoringService
