import { string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { eventHub } from "@guardian/State"

import { ClipService } from "@guardian/Services/Radio"
import combokeys from "@guardian/Utils/hotkeys"

import style from "./ClipRating.module.css"

const ratings = new Map([
  [1, "Bad"],
  [2, "Poor"],
  [3, "Fair"],
  [4, "Good"],
  [5, "Excellent"],
])

const ClipRating = ({ clipId, channelName }) => {
  const [rating, setRating] = useState(0)
  const textarea = useRef()

  const submit = async () => {
    if (rating > 0 && textarea.current) {
      await ClipService.rateClip({
        clipId,
        score: rating,
        comments: textarea.current.value,
      })
      eventHub.emit("clearRateClip")
    }
  }

  useEffect(() => {
    const handleRateClip = r => {
      setRating(r)
      textarea.current?.focus()
    }

    eventHub.on("rateClip", handleRateClip)
    combokeys.bind("esc", () => eventHub.emit("clearRateClip"))

    return () => {
      eventHub.off("rateClip", handleRateClip)
      combokeys.unbind("esc")
    }
  }, [])

  return (
    <div className={style.container}>
      <button
        className={style.close}
        onClick={() => eventHub.emit("clearRateClip")}
      >
        <i className='fas fa-times' />
      </button>
      <h4 className={style.heading}>
        Rate Clip from <div>{channelName}</div>
      </h4>
      <div className={style.ears}>
        {[...ratings.keys()].map(r => (
          <button
            key={r}
            className={style.ear}
            onClick={() => {
              setRating(r)
              textarea.current?.focus()
            }}
          >
            {rating >= r && (
              <img
                alt='selected'
                src={`/ear-selected${r > 3 ? `-${r}` : ""}.svg`}
              />
            )}
            {rating < r && <img alt='unselected' src='/ear-unselected.svg' />}
          </button>
        ))}
      </div>
      <div className={style.info}>{ratings.get(rating) || null}</div>
      <textarea
        ref={textarea}
        className={style.textarea}
        onKeyDown={e => {
          if (e.key === "Enter") {
            submit()
          }
        }}
      />
      <button className={style.submit} onClick={submit}>
        Submit
      </button>
    </div>
  )
}

ClipRating.propTypes = {
  clipId: string,
  channelName: string,
}

export default React.memo(ClipRating)
