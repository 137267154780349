import _ from "lodash"
import React, { useMemo, useState } from "react"

import { useGlobalState } from "@guardian/State"

import { Signal } from "@guardian/API/Optimus"

import LocationSuggest from "@guardian/Components/LocationSuggest"
import { Select } from "@guardian/UI/Legacy/Select"
import { Text } from "@guardian/UI/Legacy/Text"

import styles from "./AddressOrAreaInput.module.css"
import ServiceAreaInput from "./ServiceAreaInput"
import useCitywideAreas from "./useCitywideAreas"

export async function geocodeValue(
  value: string,
  lat?: number,
  lng?: number,
): Promise<any | null> {
  const {
    data: { suggestions },
  } = await Signal.getLocationSuggestions(value, lat, lng)
  if (!suggestions || suggestions.length === 0) {
    return {}
  }

  const {
    data: { candidates },
  } = await Signal.getGeocodeLocationSuggestions(
    suggestions[0].text,
    suggestions[0].magicKey,
  )

  const geocodeRes = candidates[0]

  // Convert to lat/lng pair for interoperability with other code
  geocodeRes.location.lat = geocodeRes.location.y
  geocodeRes.location.lng = geocodeRes.location.x

  return geocodeRes
}

interface Props {
  disabled?: boolean
  onChangeServiceArea: (value: any) => void
  citywideGeoCode: string
  locClassName: string
  locInitialValue: string
  locOnSelect: (value: any) => void
  locOnChange: () => void
  autoFocus: boolean
  locRef: React.RefObject<typeof LocationSuggest>
  mapInit: any
  attachedClips: any
  showAddressWarning: boolean
  useFuzzySearch: boolean
  onFuzzyChange: () => void
}

const AddressOrAreaInput: React.FunctionComponent<Props> = ({
  disabled,
  locClassName,
  locInitialValue,
  locOnSelect,
  locOnChange,
  autoFocus,
  locRef,
  mapInit,
  onChangeServiceArea,
  citywideGeoCode,
  attachedClips,
  showAddressWarning,
  useFuzzySearch,
  onFuzzyChange,
}) => {
  const attached = attachedClips ? attachedClips.toArray() : []

  const { esriFeatures, isChronicAddress, channel } = useGlobalState(state => {
    const { esriFeatures, chronicAddresses } = state.global
    const { channels } = state.radio

    return {
      esriFeatures,
      isChronicAddress:
        locInitialValue !== "" &&
        chronicAddresses?.some(
          (address: string) => address === locInitialValue,
        ),
      channel: attached.length > 0 ? channels[attached[0].channel] : undefined,
    }
  })

  const citywideAreas = useCitywideAreas()

  const [isCitywide, setIsCitywide] = useState(!!citywideGeoCode)
  const [selectValue, setSelectValue] = useState(
    citywideGeoCode ? "service_area" : "address",
  )

  const selectOptions = useMemo(() => {
    const options =  [
      {
        label: "Address",
        value: "address",
      },
      {
        label: "Service Area",
        value: "service_area",
      },
    ]
    if (!esriFeatures?.length) {
      return options
    }
    return [
      ...options,
      ...esriFeatures.map((feature: any) => ({
        value: feature.id,
        label: _.startCase(feature.name),
      })),
    ]
  }, [esriFeatures])

  const handleSelectChange = async (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const val = e.target.value as string
    if (val === "service_area") {
      const cityString = locInitialValue && locInitialValue.split(",")[1]
      let city = cityString && cityString.trim()

      const areaPairs = Object.entries(citywideAreas)
      // fisrt attempt finding the exact string match
      let knownArea = areaPairs.find(areaPair => areaPair[1] === city)
      // second attempt solving discrepancies e.g. city === "New York" and areaPair[1] === "New York City"
      if (!knownArea)
        knownArea = areaPairs.find(areaPair => areaPair[1].includes(city))

      if (knownArea && knownArea[0]) {
        const geocoded = await geocodeValue(citywideAreas[knownArea[0]])
        if (geocoded && Object.keys(geocoded).length > 0)
          locOnSelect({ value: geocoded })
        onChangeServiceArea({
          name: knownArea[1],
          value: knownArea[0],
        })
      } else {
        onChangeServiceArea({
          name: citywideAreas["__OOSA__"],
          value: "__OOSA__",
        })
      }
      setIsCitywide(true)
    } else {
      setIsCitywide(false)
      onChangeServiceArea({
        name: "",
        value: "NOT_CITYWIDE",
      })
    }

    setSelectValue(val)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          {(isCitywide && (
            <ServiceAreaInput
              disabled={disabled}
              onChangeServiceArea={onChangeServiceArea}
              citywideGeoCode={citywideGeoCode}
              onSelect={locOnSelect}
            />
          )) || (
            <LocationSuggest
              disabled={disabled}
              className={locClassName}
              initialValue={locInitialValue}
              placeholder='[Address or Intersection]'
              onSelect={locOnSelect}
              onChange={locOnChange}
              autoFocus={autoFocus}
              stopPropagation
              featureLayer={
                selectValue !== "address" && selectValue !== "service_area"
              }
              featureId={selectValue}
              ref={locRef}
              mapInit={mapInit}
              attachedClips={attachedClips}
              useFuzzySearch={useFuzzySearch}
              onFuzzyChange={onFuzzyChange}
              location='IncidentCreation'
            />
          )}
        </div>
        <div className={styles.selectContainer}>
          <Select
            options={selectOptions}
            value={selectValue}
            disabled={disabled}
            onChange={handleSelectChange}
            label='Type'
          />
        </div>
      </div>
      {showAddressWarning && channel && (
        <div className={styles.warningText}>
          <Text size='medium' color='red'>
            Warning: Address is outside of precinct {channel.name}
          </Text>
        </div>
      )}
      {isChronicAddress && (
        <div className={styles.warningText}>
          <Text size='medium' color='red'>
            Warning: Address is a known chronic caller. Follow guidelines on
            Slite.
          </Text>
        </div>
      )}
    </>
  )
}

export default React.memo(AddressOrAreaInput)
