import classNames from "classnames";
import styled from "styled-components";

const ActivityTab = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__activity"
    )
  };
})`
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: var(--space-large);
`;

ActivityTab.defaultProps = {};

export default ActivityTab;
