import classNames from "classnames";
import styled from "styled-components";

const ChatArea = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat"
      )
    }
  }
)`
  align-tems: center;
  background: var(--colors-grey-095);
  color: var(--colors-grey-070);
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
  min-height: 0; // Flex height fix.
  max-height: 100%;
`;

export default ChatArea;
