import { from } from "rxjs";
import { v4 as uuid } from "uuid";

import { isV2Protect } from "@guardian/Utils/protect";
import { OptimusAPI } from "@guardian/API/Optimus";

export async function getWMBPayload(sessionId) {
  if (isV2Protect(sessionId)) {
    return { data:"" }
  }

  const resp = OptimusAPI.get(`/v1/wmb/${sessionId}`)
  // NOTE: unlike ther endpoints, WMB endpoints return resp
  // so need to get data object in caller (resp.data)
  return resp
}

export function analystJoinWMBSession(sessionId) {

  return from(
    OptimusAPI.patch(
      `/v1/wmb/${sessionId}/sos_accept`,
      {},
    ),
  )
}

export function getWMBUserInfo(sessionId) {
  const resp = OptimusAPI.get(`/v1/wmb/${sessionId}/owner_info`)
  return resp
}

export function inviteWMBEmergencyContacts(sessionId) {
  return from(
    OptimusAPI.post(
      `/v1/wmb/${sessionId}/invite_priority_friends`,
      {},
    ),
  )
}

export function dialWMB911(sessionId) {
  return from(
    OptimusAPI.post(
      `/v1/wmb/${sessionId}/dial_out`,
      {},
    ),
  )
}

export function getActiveCalls() {
  const resp = OptimusAPI.get(`/v1/protect/sos_active_sessions`)
  return from(resp)
}

export function postWMBMessage(message, sessionId) {
  return from(
    OptimusAPI.post(
      `/v1/wmb/${sessionId}/messages`,
      { message, id: uuid() }
    ),
  )
}

export function analystEndSOSSession(sessionId) {
  return from(
    OptimusAPI.patch(
      `/v1/wmb/${sessionId}/sos_resolve`,
      {}
    ),
  )
}

export function getWMBSafetyFeed(sessionId) {
  if (isV2Protect(sessionId)) {
    return from([{}])
  }

  return from(
    OptimusAPI.get(`/v1/wmb/${sessionId}/safety_feed`),
  )
}

export function getWMBSafetyPOIs(sessionId) {
  if (isV2Protect(sessionId)) {
    return from([{}])
  }

  return from(
    OptimusAPI.get(`/v1/wmb/${sessionId}/safety_pois`),
  )
}

export default {
  getWMBPayload,
  analystJoinWMBSession,
  getWMBUserInfo,
  inviteWMBEmergencyContacts,
  dialWMB911,
  getActiveCalls,
  postWMBMessage,
  analystEndSOSSession,
  getWMBSafetyFeed,
  getWMBSafetyPOIs
};
