import classnames from "classnames"
import _inRange from "lodash/inRange"
import React, { useMemo } from "react"

import { useGlobalState } from "@guardian/State"
import type { ClipMetadata } from "@guardian/Types/Clip"

import Sound from "@guardian/Components/Sound"

interface IProps {
  clipId: string
  raw?: ClipMetadata["raw"]
  updateActiveClip: Function
}

interface TranscriptionSegment {
  avg_logprob?: number
  compression_ratio?: number
  end?: number
  id?: number
  no_speech_prob?: number
  seek?: number
  start?: number
  temperature?: number
  tokens?: number[]
  text: string
}

const Transcription = ({ clipId, raw, updateActiveClip }: IProps) => {
  const { transcriptionsDisabled, position } = useGlobalState(state => {
    const { transcriptionsDisabled } = state.global
    const { activeClip } = state.radio
    return {
      transcriptionsDisabled,
      position: activeClip.id === clipId ? activeClip.position : 0,
    }
  })

  const transcriptionParts = useMemo<TranscriptionSegment[]>(() => {
    if (!raw) {
      return []
    }

    const parsedRaw = JSON.parse(raw)
    const transcriptionSegments = parsedRaw?.transcription?.segments || []
    if (transcriptionSegments.length > 0) {
      return transcriptionSegments
    }
    const transcriptionText = parsedRaw?.transcription?.text
    return transcriptionText ? [{ text: transcriptionText }] : []
  }, [raw])

  if (transcriptionParts.length === 0 || transcriptionsDisabled) {
    return null
  }

  return (
    <div className='clip-channel-transcription'>
      {transcriptionParts.map((part, i) => (
        <span
          key={i}
          className={classnames("clip-channel-transcription__segment", {
            active:
              position &&
              _inRange(position / 1000, part.start ?? 0, part.end ?? 0),
          })}
          onClick={() => {
            updateActiveClip({
              id: clipId,
              position: (part?.start || 0) * 1000,
              status: Sound.status.PLAYING,
            })
          }}
        >
          {part.text}
        </span>
      ))}
    </div>
  )
}

export default React.memo(Transcription)
