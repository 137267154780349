import React from "react";

import OutboundMessagingButton from "./components/OutboundMessagingButton";
import StyledActionsPanel from "./views/ActionsPanel";

const ActionsPanel = props => {
  return (
    <StyledActionsPanel>
      <OutboundMessagingButton/>
    </StyledActionsPanel>
  );
};

export default ActionsPanel;
