import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useObservable } from "rxjs-hooks"
import style from "./ModIGL.module.css"
import OverviewPane from "./OverviewPane/OverviewPane"
import { string, object, bool } from "prop-types"
import InfoPane from "./InfoPane/InfoPane"
import { timer, from, defer } from "rxjs"
import { map } from "rxjs/operators"
import IncidentPane from "./IncidentPane/IncidentPane"
import { latestRev } from "@guardian/Utils/incident"
import _ from "lodash"
import { getDistance } from "geolib"
import { useGlobalState, eventHub } from "@guardian/State"
import MapContext from "@guardian/Components/Contexts/MapContext"
import { getContentId, composeUrl } from "./ModIGL.helper"
import dayjs from "dayjs"

import { ContentService } from "@guardian/Services/Content"
import { IncidentService } from "@guardian/Services/Incident"
import { GeocodingService } from "@guardian/Services/Geocoding"
import { push } from "@guardian/Utils/history"
import combokeys from "@guardian/Utils/hotkeys"
import { isNearby, pointInServiceArea } from "@guardian/Utils/search_filter"
import { reverseGeoToLocation } from "@guardian/Utils/util"

const LAFD_PSN = import.meta.env.REACT_APP_LAFD_PSN

const sortFns = [
  c =>
    c.videoStream && !c.videoStream.broadcastDone && !c.videoStream.hlsDone
      ? -1
      : 1,
  c => -new Date(c.ugcMeta.createdAt),
  c => getContentId(c),
]

const setupContent = c => {
  const { lat, lng } = c.ugcMeta
  eventHub.emit("set active marker", { lat, lng })
  eventHub.emit("set center", { lat, lng })
}

const ModIGL = ({ activeFromUrl, lafd, mapInit }) => {
  const { user, serviceAreas: serviceAreaConfig } = useGlobalState(state => {
    const { user, serviceAreaConfig: { serviceAreas } } = state.global
    return { user, serviceAreas }
  })

  // New stuff
  const [content, setContent] = useState([])
  const [activeContentId, setActiveContentId] = useState(activeFromUrl || "")
  const [log, setLog] = useState([])

  // Might have to change a bit
  const [resolved, setResolved] = useState({})

  // Not new, prob doesnt have to change too much/be removed
  const [incidents, setIncidents] = useState([])
  const [confirmed, setConfirmed] = useState(false)
  const [block, setBlock] = useState(false)
  const [verified, setVerified] = useState(false)
  const [prompt911, setPrompt911] = useState(false)
  const [location, setLocation] = useState(null)
  const [serviceAreas, setServiceAreas] = useState([])

  const addResolved = useCallback(
    id => setResolved(prevResolved => ({ ...prevResolved, [id]: true })),
    [],
  )
  const activeContent = useMemo(() => {
    return content
      .filter(c => !resolved[getContentId(c)])
      .find(c => getContentId(c) === activeContentId)
  }, [content, resolved, activeContentId])

  const isInServiceArea = useCallback(
    c => {
      if (!serviceAreas || serviceAreas.length === 0) {
        return true
      }
      const point = [c.ugcMeta.lng, c.ugcMeta.lat]
      return serviceAreas.some(serviceArea =>
        pointInServiceArea(point, serviceAreaConfig[serviceArea.code]),
      )
    },
    [serviceAreas, serviceAreaConfig],
  )

  const contentView = useMemo(() => {
    return _.sortBy(
      content.filter(c => !resolved[getContentId(c)] && isInServiceArea(c)),
      sortFns,
    )
  }, [content, resolved, isInServiceArea])

  const nearbyIncidents = useMemo(() => {
    if (!incidents || !activeContent?.ugcMeta) {
      return []
    }
    const { lat, lng } = activeContent.ugcMeta
    return _.sortBy(
      incidents
        .filter(i =>
          isNearby(
            [lng, lat],
            { lat: i.location.lat, lng: i.location.lng },
            4828, // 3 miles
          ),
        )
        .map(i => ({
          ...i,
          time: latestRev(i).updatedAt,
          distance: getDistance(
            { latitude: lat, longitude: lng },
            { latitude: i.location.lat, longitude: i.location.lng },
          ),
        })),
      ["distance", "time"],
    )
  }, [incidents, activeContent])

  const handleConfirmVideo = useCallback(async () => {
    const { lat, lng } = activeContent.ugcMeta

    const geocodeResp = await GeocodingService.reverseGeocode(lat, lng)
    if (geocodeResp) {
      const locState = await reverseGeoToLocation({ lat, lng }, geocodeResp)
      setLocation(locState)
    }
    setConfirmed(true)
  }, [activeContent])

  useEffect(() => {
    let logSubscription
    if (!lafd) {
      logSubscription = timer(0, 10000).subscribe(() => {
        defer(() => ContentService.getModerationLog(user.id)).subscribe(val =>
          setLog(val),
        )
      })
    }

    const contentSubscription = timer(0, 10000).subscribe(() => {
      defer(() =>
        ContentService.getUnresolvedContent(
          lafd ? import.meta.env.REACT_APP_LAFD_PSN : undefined,
        ),
      ).subscribe(val => {
        const c = val || []
        setContent(c)
      })
    })

    return () => {
      if (logSubscription) {
        logSubscription.unsubscribe()
      }
      contentSubscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useObservable(() =>
    timer(0, 5000).pipe(
      map(() => {
        return from(
          IncidentService.queryIncidents({
            since: dayjs()
              .subtract(2, "hour")
              .toDate(),
          }),
        ).subscribe(ids => {
          return from(
            IncidentService.getIncidents({ incidentIds: ids }),
          ).subscribe(val => {
            // Filter incidents that have bad location or are deleted
            const filtered = val.filter(i => {
              return i.location?.lat && i.location?.lng && !i.deleted
            })
            setIncidents(filtered)
          })
        })
      }),
    ),
  )

  const onResolve = useCallback(
    id => {
      addResolved(id)
      setBlock(false)
      setPrompt911(false)
      setVerified(false)

      const index = contentView.findIndex(s => getContentId(s) === id)
      const nextContent = contentView[index + 1] || _.last(contentView)
      if (nextContent) {
        const nextContentUrl = composeUrl(
          nextContent.type,
          getContentId(nextContent),
        )
        push(nextContentUrl)
      }

      setContent(prevContent =>
        prevContent.filter(
          c =>
            id !== getContentId(c) &&
            !resolved[getContentId(c)] &&
            (!lafd ? !c.psn : true), // TODO: Psn field?
        ),
      )
    },
    [resolved, contentView, lafd, setContent, addResolved],
  )

  useEffect(() => {
    if (user && !user.username) {
      eventHub.emit("logout")
    }
  }, [user])

  useEffect(() => {
    if (activeContent && mapInit) {
      setupContent(activeContent)
    }
  }, [mapInit, setupContent, activeContent])

  useEffect(() => {
    if (activeFromUrl && activeFromUrl !== activeContentId) {
      setActiveContentId(activeFromUrl)
    }
  }, [activeFromUrl, activeContentId])

  useEffect(() => {
    setConfirmed(false)
    setBlock(false)
    setPrompt911(false)
    setVerified(false)
  }, [activeContentId])

  useEffect(() => {
    if (!activeContentId) {
      const content = contentView[0]
      if (content) {
        const contentUrl = composeUrl(content.type, getContentId(content))
        push(contentUrl)
      }
    }
  }, [contentView, activeContentId])

  useEffect(() => {
    combokeys.bind("up", e => {
      e.preventDefault()
      const index = contentView.findIndex(
        c => getContentId(c) === activeContentId,
      )
      const c = contentView[index - 1] || _.first(contentView)
      if (c) {
        const prevContentUrl = composeUrl(c.type, getContentId(c))
        push(prevContentUrl)
      }
    })
    combokeys.bind("down", e => {
      e.preventDefault()
      const index = contentView.findIndex(
        c => getContentId(c) === activeContentId,
      )
      const c = contentView[index + 1] || _.last(contentView)
      if (c) {
        const nextContentUrl = composeUrl(c.type, getContentId(c))
        push(nextContentUrl)
      }
    })

    return () => {
      combokeys.unbind("up")
      combokeys.unbind("down")
    }
  }, [contentView, activeContentId])

  useEffect(() => {
    const getLogs = async () => {
      const l = await ContentService.getModerationLog(user.id)
      setLog(l)
    }

    eventHub.on("fetch mod log", getLogs)
    return () => {
      eventHub.off("fetch mod log", getLogs)
    }
  }, [user.id])

  return (
    <div className={style.modIGL}>
      <OverviewPane
        content={contentView}
        activeContentId={activeContentId}
        selectedServiceAreas={serviceAreas}
        serviceAreaConfig={serviceAreaConfig}
        onServiceAreaSelect={value => setServiceAreas(value)}
      />

      {activeContent && (
        <InfoPane
          key={`info-${getContentId(activeContent)}`}
          id={getContentId(activeContent)}
          onConfirm={handleConfirmVideo}
          onReject={onResolve}
          confirmed={confirmed}
          onBlock={() => setBlock(!block)}
          onVerified={() => setVerified(!verified)}
          block={block}
          verified={verified}
          prompt911={prompt911}
          onPrompt911={() => setPrompt911(!prompt911)}
          content={activeContent}
          log={log}
        />
      )}
      {activeContent && (
        <IncidentPane
          key={`incident-${getContentId(activeContent)}`}
          confirmed={confirmed}
          block={block}
          verified={verified}
          prompt911={prompt911}
          onPrompt911={() => setPrompt911(!prompt911)}
          onClose={() => setConfirmed(false)}
          activeContentId={activeContentId}
          activeContent={activeContent}
          onSubmit={onResolve}
          location={location}
          psnId={lafd ? LAFD_PSN : undefined}
          incidents={nearbyIncidents}
        />
      )}
    </div>
  )
}

ModIGL.propTypes = {
  activeFromUrl: string,
  lafd: bool,
  mapInit: bool,
}

const Wrapper = props => {
  const { id } = useParams()

  return (
    <MapContext.Consumer>
      {mapInit => <ModIGL activeFromUrl={id} mapInit={mapInit} {...props} />}
    </MapContext.Consumer>
  )
}

export default Wrapper
