import styled from "styled-components";

const UserDropdownAction = styled.span`
  align-items: center;
  display: flex;

  svg {
    display: inline-block;
    font-size: inherit!important;
    line-height: inherit!important;
    margin-right: var(--space-xx-small);
  }
`;

export default UserDropdownAction;
