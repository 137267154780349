import React from "react";

import QueueHeader from "./components/QueueHeader";
import QueueList from "./components/QueueList";
import StyledMessagingQueuePanel from "./views/MessagingQueuePanel";

const MessagingQueuePanel = React.memo(() => {
  return (
    <StyledMessagingQueuePanel>
      <QueueHeader/>
      <QueueList/>
    </StyledMessagingQueuePanel>
  );
});

export default MessagingQueuePanel;
