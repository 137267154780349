import React, { useEffect } from "react"
import { string, func, bool } from "prop-types"
import classnames from "classnames"
import _ from "lodash"

import { OutlinedInput } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import combokeys from "@guardian/Utils/hotkeys"

import style from "./NotificationCategory.module.css"
import { inputStyles } from "./NotificationCategory.styles"

const StyledInput = withStyles(inputStyles)(OutlinedInput)

const nonOnAirOptions = {
  verifiedLive: { label: "Verify (V)", shortcut: "v" },
  editorsChoice: { label: "Editor's Choice (E)", shortcut: "e" },
  custom: { label: "Custom (C)", shortcut: "c" },
}
const onAirOptions = {
  onAir: { label: "OnAir (O)", shortcut: "o" },
}
const vlOptions = {
  editorsChoice: { label: "Editor's Choice (E)", shortcut: "e" },
  custom: { label: "Custom (C)", shortcut: "c" },
}

const NotificationCategory = ({
  category,
  setCategory,
  onAir,
  verifiedLive,
  disabled,
}) => {
  let buttonOptions = {}
  if (onAir) {
    buttonOptions = { ...onAirOptions }
  } else if (verifiedLive) {
    buttonOptions = { ...vlOptions }
  } else {
    buttonOptions = { ...nonOnAirOptions }
  }

  useEffect(() => {
    _.forEach(buttonOptions, ({ shortcut }, key) => {
      combokeys.bind(shortcut, () => setCategory(key))
    })

    return () => {
      _.forEach(buttonOptions, ({ shortcut }) => {
        combokeys.unbind(shortcut)
      })
    }
  }, [setCategory, buttonOptions])

  const Buttons = () => (
    <>
      {_.map(buttonOptions, (option, key) => {
        return (
          <button
            key={key}
            className={classnames(style.button, {
              [style.selected]: category === key,
            })}
            onClick={() => setCategory(key)}
            disabled={disabled}
          >
            {option.label}
          </button>
        )
      })}
    </>
  )
  return (
    <StyledInput
      label='Notification Categories'
      notched
      inputComponent={Buttons}
      disabled={disabled}
    />
  )
}

NotificationCategory.propTypes = {
  category: string,
  setCategory: func,
  onAir: bool,
  verifiedLive: bool,
  disabled: bool,
}

export default NotificationCategory
