import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import React, { useState } from "react"
import styles from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptNote/AgentScriptNote.module.css"
import classnames from "classnames"
import { some } from "lodash"
import ReactMarkdown from "react-markdown"
import { TContentObjectType, IProps, IScriptNote } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptNote/types"

export const AgentScriptNote = ({
  title,
  content = [],
  id,
  matches,
  forceRender,
  activeSearch,
  onAction,
}: IScriptNote & IProps) => {
  const [expanded, setExpanded] = useState(false)

  if (
    matches &&
    !matches[id] &&
    !some(content, c => matches[c.id]) &&
    !forceRender
  ) {
    return null
  }

  let searchedTitle
  if (activeSearch && activeSearch.id === id) {
    let searched
    searched = [
      title.slice(0, activeSearch.start),
      <span className={styles.searchMatch}>
        {title.slice(activeSearch.start, activeSearch.end)}
      </span>,
      title.slice(activeSearch.end),
    ].filter(e => !!e)

    if (activeSearch.property === "title") {
      searchedTitle = searched
    }
  }

  return (
    <Container column fullWidth top={2}>
      <Container
        fullWidth
        onClick={() => setExpanded(!expanded)}
        leftJustify
        bottom={2}
      >
        <Container right={2}>
          <img
            alt='caret'
            src='/Caret.svg'
            style={expanded ? { transform: "rotate(180deg)" } : undefined}
          />
        </Container>
        {searchedTitle || title}
      </Container>
      {expanded &&
        content.map(({ type, value, action, id }) => {
          let body
          switch (type) {
            case TContentObjectType.Markdown:
              body = (
                <Container
                  fullWidth
                  className={classnames(styles.body, {
                    [styles.searchMatch]:
                      !!activeSearch && activeSearch.id === id,
                  })}
                  top={2}
                  bottom={2}
                  left={2}
                  right={2}
                  leftJustify
                >
                  <ReactMarkdown>{value}</ReactMarkdown>
                </Container>
              )
              break
            case TContentObjectType.Button:
              body = (
                <button
                  className={styles.button}
                  onClick={() => action && onAction({ [action]: id })}
                >
                  <Container top={1} bottom={1} left={2} right={2}>
                    {value}
                  </Container>
                </button>
              )
              break
            default:
              return null
          }

          return (
            <Spacing fullWidth left={2} bottom={2} key={id}>
              {body}
            </Spacing>
          )
        })}
    </Container>
  )
}
