import React from "react";

import { Row } from "@guardian/UI/Grid";
import { SmallText } from "@guardian/UI/Typography";

import StyledError from "./views/Error";

const Error = () => {
  return (
    <Row style={{ height: "100%" }}>
      <StyledError>
        <SmallText>An error occured.</SmallText>
      </StyledError>
    </Row>
  );
};

export default Error;
