import Sound from "@guardian/Components/Sound"
import { CLIP_SKIP_MS } from "@guardian/State/consts"

const clipListReducer = (state, action) => {
  let newState

  switch (action.type) {
    case "setNewActive":
      newState = {
        ...state,
        active: action.data,
      }
      break
    case "play":
      newState = {
        ...state,
        status: Sound.status.PLAYING,
      }
      break
    case "pause":
      newState = {
        ...state,
        status: Sound.status.PAUSED,
      }
      break
    case "setPosition":
      newState = {
        ...state,
        clipPositions: {
          ...state.clipPositions,
          [action.data.id]: [action.data.position],
        },
      }
      break
    case "finished":
      newState = {
        ...state,
        status: Sound.status.STOPPED,
        clipPositions: {
          ...state.clipPositions,
          [state.active]: undefined,
        },
      }
      break
    case "rewind":
      newState = {
        ...state,
        clipPositions: {
          ...state.clipPositions,
          [action.data.id]: Math.max(
            0,
            (state.clipPositions[action.data.id] || 0) -
              (action.data.step || CLIP_SKIP_MS),
          ),
        },
      }
      break
    case "advance":
      newState = {
        ...state,
        clipPositions: {
          ...state.clipPositions,
          [action.data.id]: Math.min(
            action.data.duration_ms - 1,
            (state.clipPositions[action.data.id] || 0) +
              (action.data.step || CLIP_SKIP_MS),
          ),
        },
      }
      break
    case "next clip":
      const nextIndex = state.active
        ? Math.max(
            -1,
            state.clips.findIndex(({ id }) => id === state.active) - 1,
          )
        : -1
      newState = {
        ...state,
        active: nextIndex === -1 ? undefined : state.clips[nextIndex].id,
        status: nextIndex === -1 ? Sound.status.STOPPED : Sound.status.PLAYING,
      }
      break
    case "previous clip":
      const prevIndex = state.active
        ? Math.min(
            state.clips.length,
            state.clips.findIndex(({ id }) => id === state.active) + 1,
          )
        : state.clips.length
      newState = {
        ...state,
        active:
          prevIndex === state.clips.length
            ? undefined
            : state.clips[prevIndex].id,
        status:
          prevIndex === state.clips.length
            ? Sound.status.STOPPED
            : Sound.status.PLAYING,
      }
      break
    case "new clips":
      newState = {
        ...state,
        clips: action.data,
      }
      break
    case "toggle active status":
      newState = {
        ...state,
        status:
          state.status === Sound.status.PLAYING
            ? Sound.status.PAUSED
            : Sound.status.PLAYING,
      }
      break
    default:
      break
  }

  return newState
}

export default clipListReducer
