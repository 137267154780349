import React, { useMemo } from "react"

import { Icon } from "@guardian/UI/Icon"

import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import Container from "@guardian/Components/Common/Container"
import { IProps } from "@guardian/Components/ModSOS/components/Video/VideoStats/types"
import { VideoStat } from "@guardian/Components/ModSOS/components/Video/VideoStats/Stat"
import { useLatestRecordStats } from "@guardian/Components/ModSOS/store/dashboard/selectors/useLatestRecordStats"

export const VideoStats: React.FunctionComponent<IProps> = props => {
  const { networkQuality } = props
  const latestRecordStats = useLatestRecordStats()

  const formattedNetworkQuality: string | React.ReactNode = useMemo(() => {

    const value = {
      // @ts-ignore
      1: (<Icon.SignalCellular1Bar/>),
      // @ts-ignore
      2: (<Icon.SignalCellular2Bar/>),
      // @ts-ignore
      3: (<Icon.SignalCellular3Bar/>),
      // @ts-ignore
      4: (<Icon.SignalCellular4Bar/>),
      // @ts-ignore
      5: (<Icon.SignalCellular4Bar/>)
    }[networkQuality] || "--";

    return value;
  }, [networkQuality])

  if (!latestRecordStats) {
    return null
  }

  return (
    <Container
      className={styles.statsContainer}
      style={{
        width: "auto",
        height: "auto",
      }}
      bottomAlign
      leftJustify
      bottom={6}
      left={6}
    >
      <VideoStat displayName='Battery' value={latestRecordStats.batteryLevel} />
      <VideoStat displayName='MPH' value={latestRecordStats.speed} />
      <VideoStat displayName='BPM' value={latestRecordStats.heartRate} />
      <VideoStat displayName='Signal' value={formattedNetworkQuality} />
    </Container>
  )
}
