import styled from "styled-components";

const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & + & {
    margin-left: var(--space-x-large);
  }
`;

export default StatusItem;
