import classNames from "classnames";
import styled from "styled-components";

const TrailingContent = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__trailing-content"
      )
    };
  }
)`
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: var(--space-xx-small);
`;

TrailingContent.propTypes = {};

TrailingContent.defaultProps = {};

export default TrailingContent;
