export const CATEGORY_ICON_MAP = {
  "air_quality": "/notif-icons/air-quality.png",
  "animal_related": "/notif-icons/animal-related.png",
  "assault_fight": "/notif-icons/assault-fight.png",
  "auto_igl_incident": "/notif-icons/auto-igl-incident.png",
  "barricade": "/notif-icons/barricade.png",
  "break_in": "/notif-icons/break-in.png",
  "community": "/notif-icons/community.png",
  "earthquake": "/notif-icons/earthquake.png",
  "election": "/notif-icons/election.png",
  "fire": "/notif-icons/fire.png",
  "fire_ems_activity": "/notif-icons/fire-ems-activity.png",
  "gun_related": "/notif-icons/gun-related.png",
  "harassment": "/notif-icons/harassment.png",
  "hazardous_condition": "/notif-icons/hazardous-condition.png",
  "helicopter": "/notif-icons/helicopter.png",
  "hurricane": "/notif-icons/hurricane.png",
  "medical": "/notif-icons/medical.png",
  "missing_person": "/notif-icons/missing-person.png",
  "missing_pet": "/notif-icons/missing-pet.png",
  "police_related": "/notif-icons/police-related.png",
  "protest": "/notif-icons/protest.png",
  "pursuit_search": "/notif-icons/pursuit-search.png",
  "rescue": "/notif-icons/rescue.png",
  "robbery_theft": "/notif-icons/robbery-theft.png",
  "santa": "/notif-icons/santa.png",
  "traffic_related": "/notif-icons/traffic-related.png",
  "weapon": "/notif-icons/weapon.png",
  "weather": "/notif-icons/weather.png",
  "wildfire": "/notif-icons/wildfire.png",
} as const;
