import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useGlobalState } from "@guardian/State"

import { VideoStreams } from "@guardian/API/Optimus"
import calendar from "@guardian/Utils/dayjs/calendar"

import VideoPlayer from "@guardian/Components/VideoPlayer/VideoPlayer"

import style from "./RejectedStream.module.css"

dayjs.extend(advancedFormat)
dayjs.extend(calendar)

const RejectedStream = () => {
  const { id } = useParams();

  const rejectionCopy = useGlobalState(state => {
    const { rejectionChoices } = state.moderation;
    return Object.keys(rejectionChoices).reduce((rejectionCopy, c) => {
      rejectionCopy[rejectionChoices[c]] = c;
      return rejectionCopy;
    }, {});
  });

  const [stream, setStream] = useState(null)
  // Note: The below implmentation looks very bad. This code was updated only to
  // work with a major refactor of all API handlers, and should be better
  // updated by the next dev working here.
  const fetchStream = async () => setStream(await (async () => {
    const { data } = await VideoStreams.getStream(id);
    return data;
  })())
  useEffect(() => {
    fetchStream()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!stream) {
    return null
  }

  const {
    broadcastDone,
    hlsDone,
    hlsVodUrl,
    hlsLiveUrl,
    username,
    durationSeconds,
    createdAt,
  } = stream.videoStream
  const rejectionReason = _.get(stream, "iglStream.rejectionReason", null)
  const resolvedAt = _.get(stream, "iglStream.resolvedAt", null)

  const isDone = broadcastDone || hlsDone

  return (
    <div className={style.pane}>
      <div className={style.container}>
        <div className={style.topRow}>
          <div className={style.info}>
            {rejectionReason && <span>Reject</span>} a report by @{username}
          </div>
          <div>{dayjs(resolvedAt || createdAt).calendar(",")}</div>
        </div>
        {rejectionReason && (
          <div className={style.reason}>
            Reason: {rejectionCopy[rejectionReason]}
          </div>
        )}
        <VideoPlayer
          url={isDone ? hlsVodUrl : hlsLiveUrl}
          autoPlay
          live={!isDone}
          durationSeconds={durationSeconds}
        />
      </div>
    </div>
  )
}

export default RejectedStream
