import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const TopRow = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__top-row"
      )
    };
  }
)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 0;
`;

TopRow.propTypes = {};

TopRow.defaultProps = {};

export default TopRow;
