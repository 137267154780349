import React from "react"

const AddPrompt = () => (
  <div className='add-prompt'>
    <img src='/finger-up.svg' alt='point-up' />
    <div className='add-prompt-text'>
      Select the channels you want to listen to from the cities above.
    </div>
  </div>
)

export default AddPrompt
