import { useCallback, useEffect, useRef, useState } from "react"
import {
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Participant,
} from "twilio-video"
import {
  updateUserDisabledAgentAudio,
  updateUserDisabledAgentVideo,
  updateUserSpeakerMuted,
} from "@guardian/Components/ModSOS/store/video/actions/video"
import _ from "lodash"
import { useSOSVideoContext } from "@guardian/Components/ModSOS/store/video/VideoContext"
import { IOptions } from "@guardian/Components/ModSOS/components/Video/Twillio/hooks/useHandleTwilioSubscriptions/types"

// TODO: long term this should probably live in video context or in separate context
// TODO: extract audio html element related logic into UserAudio component
// TODO: optimize videoDisabled logic possibly remove and use only isUserCameraEnabled
export const useHandleTwilioSubscriptions = ({
  participant,
  isUserDisconnected,
}: IOptions) => {
  const { dispatch } = useSOSVideoContext()

  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>()
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()

  const [videoDisabled, setVideoDisabled] = useState(true)

  const audioRef = useRef<HTMLAudioElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleTrackSubscribed = useCallback(
    async (trackParticipant: Participant, track: LocalTrack) => {
      if (trackParticipant.identity === participant.identity) {
        switch (track.kind) {
          case "video": {
            if (!_.isEmpty(videoTrack)) {
              setVideoDisabled(true)
            }

            setVideoTrack(track)

            if (track.isEnabled) {
              setVideoDisabled(false)
            }
            break
          }
          case "audio": {
            setAudioTrack(track)

            if (track.isEnabled) {
              track.attach(audioRef.current!)
            }

            track.on("enabled", () => {
              track.attach(audioRef.current!)
            })
            track.on("disabled", () => {
              track.detach(audioRef.current!)
            })
            break
          }
          case "data": {
            track.on("message", (event: any) => {
              try {
                const message = JSON.parse(event)
                const shouldMute = message.data === "disabled"

                if (message.type === "muteSpeaker") {
                  dispatch(updateUserSpeakerMuted(shouldMute))
                  dispatch(updateUserDisabledAgentAudio(shouldMute))
                }
                if (message.type === "muteAgentVideo") {
                  dispatch(updateUserDisabledAgentVideo(shouldMute))
                }
              } catch (error) {
                console.error(error)
              }
            })
          }
        }
      }
    },
    [participant.identity],
  )

  useEffect(() => {
    const $audio = audioRef.current

    if (!isUserDisconnected) {
      participant.tracks.forEach((trackPublication: any) => {
        if (trackPublication.isSubscribed) {
          handleTrackSubscribed(participant, trackPublication.track)
        }
      })

      participant.on("trackSubscribed", async (track: any) => {
        await handleTrackSubscribed(participant, track)
      })

      participant.on("trackEnabled", (track: any) => {
        switch (track.kind) {
          case "video": {
            setVideoDisabled(false)
            break
          }
        }
      })
      participant.on("trackDisabled", (track: any) => {
        switch (track.kind) {
          case "video": {
            setVideoDisabled(true)
            break
          }
        }
      })
      participant.on("trackStarted", (track: any) => {
        switch (track.kind) {
          case "video": {
            setVideoDisabled(false)
            break
          }
        }
      })
    }

    return () => {
      if (audioTrack && $audio && !_.isEmpty(audioTrack)) {
        audioTrack.detach($audio)
      }
    }
  }, [participant, isUserDisconnected, handleTrackSubscribed])

  return { audioRef, videoRef, audioTrack, videoTrack, videoDisabled }
}
