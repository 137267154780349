import React, { useMemo } from "react";

import { isBrowser } from "@guardian/Utils/Window";

import StyledCopyButton from "./views/CopyButton";

const CopyButton = props => {
  const {
    onClick
  } = props;

  const browserSupportsCopy = useMemo(() => {
    return isBrowser() && document.queryCommandSupported("copy");
  }, []);

  if (!browserSupportsCopy) {
    return;
  }

  return (
    <StyledCopyButton onClick={onClick}>
      Copy All
    </StyledCopyButton>
  );
};

export default CopyButton;
