import React from "react"
import { string, func, array, bool } from "prop-types";
import { Select, OutlinedInput, MenuItem } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import style from "./IncidentCategory.module.css"
import { inputStyles, selectStyles } from "./IncidentCategory.styles"

const StyledInput = withStyles(inputStyles)(OutlinedInput)
const StyledSelect = withStyles(selectStyles)(Select)

const IncidentCategory = ({ categories, category, onSetCategory, disabled }) => {
  return (
    <StyledSelect
      label='Incident Category'
      value={category}
      style={{ marginRight: "8px" }}
      onChange={e => onSetCategory(e.target.value)}
      input={<StyledInput notched label='Incident Category' />}
      variant='outlined'
      renderValue={selected => <div className={style.chip}>{selected}</div>}
      disabled={disabled}
    >
      {categories.map(cat => (
        <MenuItem key={cat} value={cat}>
          {cat}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

IncidentCategory.propTypes = {
  categories: array,
  category: string,
  onSetCategory: func,
  disabled: bool,
}
export default IncidentCategory
