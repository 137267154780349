const borders = {};

borders.full = "inset 0 0 0 1px";
borders.bottom = "inset 0 -1px 0 0";
borders.left = "inset 1px 0 0 0";
borders.right = "inset -1px 0 0 0";
borders.top = "inset 0 1px 0 0";

borders.highlight = {};
borders.highlight.full = "inset 0 0 0 2px";
borders.highlight.bottom = "inset 0 -2px 0 0";
borders.highlight.left = "inset 2px 0 0 0";
borders.highlight.right = "inset -2px 0 0 0";
borders.highlight.top = "inset 0 2px 0 0";

borders.outer = {};
borders.outer.full = "0 0 0 1px";
borders.outer.bottom = "0 1px 0 0";
borders.outer.left = "-1px 0 0 0";
borders.outer.right = "1px 0 0 0";
borders.outer.top = "0 -1px 0 0";

borders.outer.highlight = {};
borders.outer.highlight.full = "0 0 0 2px";
borders.outer.highlight.bottom = "0 2px 0 0";
borders.outer.highlight.left = "-2px 0 0 0";
borders.outer.highlight.right = "2px 0 0 0";
borders.outer.highlight.top = "0 -2px 0 0";

export const bordersCSSVariables = `
  :root {
    --borders-full: ${ borders.full };
    --borders-bottom: ${ borders.bottom };
    --borders-left: ${ borders.left };
    --borders-right: ${ borders.right };
    --borders-top: ${ borders.top };

    --borders-highlight-full: ${ borders.highlight.full };
    --borders-highlight-bottom: ${ borders.highlight.bottom };
    --borders-highlight-left: ${ borders.highlight.left };
    --borders-highlight-right: ${ borders.highlight.right };
    --borders-highlight-top: ${ borders.highlight.top };

    --borders-outer-full: ${ borders.outer.full };
    --borders-outer-bottom: ${ borders.outer.bottom };
    --borders-outer-left: ${ borders.outer.left };
    --borders-outer-right: ${ borders.outer.right };
    --borders-outer-top: ${ borders.outer.top };

    --borders-outer-highlight-full: ${ borders.outer.highlight.full };
    --borders-outer-highlight-bottom: ${ borders.outer.highlight.bottom };
    --borders-outer-highlight-left: ${ borders.outer.highlight.left };
    --borders-outer-highlight-right: ${ borders.outer.highlight.right };
    --borders-outer-highlight-top: ${ borders.outer.highlight.top };
  }
`;

export default borders;
