import React, { useEffect, useState } from "react"
import style from "./InAppVideos.module.css"
import { string } from "prop-types"
import InAppContent from "./InAppContent"
import _ from "lodash"

import { IncidentService } from "@guardian/Services/Incident";

const InAppVideos = ({ id, activeContentId }) => {
  const [content, setContent] = useState([])
  const [maxBoostOrder, setMaxBoostOrder] = useState(0)
  const [updateTrigger, setUpdateTrigger] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const data = await IncidentService.getIncidentContent(id, true)
      const filtered = data.filter(c =>
        ["stream", "image"].includes(c.type),
      )
      setContent(filtered)
      if (data.length) {
        const maxBy = _.maxBy(filtered, c =>
          c.type === "stream" ? c.stream.boostOrder : undefined,
        )
        setMaxBoostOrder(maxBy.stream ? maxBy.stream.boostOrder : 0)
      }
      setTimeout(() => {
        setUpdateTrigger(!updateTrigger)
      }, 1000)
    }
    fetchData()
  }, [id, updateTrigger])

  return (
    <div className={style.subpane}>
      <h5>In App Content</h5>
      <div>
        {content.map(c => {
          if (c.type === "stream") {
            return (
              <InAppContent
                key={c.stream.id}
                username={c.stream.username}
                createdAt={c.stream.createdAt}
                id={c.stream.id}
                durationSeconds={c.stream.durationSeconds}
                hlsDone={c.stream.hlsDone}
                thumbUrl={c.stream.hlsLiveThumbUrl}
                active={c.stream.id === activeContentId}
                showControls={c.stream.id === activeContentId}
                maxBoostOrder={maxBoostOrder}
                verified={c.stream.confirmed}
                blocked={c.stream.blocked}
                type='stream'
              />
            )
          }
          if (c.type === "image") {
            return (
              <InAppContent
                key={c.image.id}
                username={c.image.username}
                createdAt={c.image.createdAt}
                id={c.image.id}
                active={c.image.id === activeContentId}
                showControls={c.image.id === activeContentId}
                maxBoostOrder={maxBoostOrder}
                verified={c.image.confirmed}
                blocked={c.image.blocked}
                thumbUrl={c.image.thumbUrl}
                type='image'
              />
            )
          }
        })}
      </div>
    </div>
  )
}

InAppVideos.propTypes = {
  id: string,
  activeContentId: string,
}

export default InAppVideos
