import classNames from "classnames";
import styled from "styled-components";

const UserNameWrapper = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__user-name-wrapper"
      )
    };
  }
)`
  align-items: center;
  display: flex;
  height: var(--line-heights-medium);
  width: 100%;
`;

UserNameWrapper.propTypes = {};

UserNameWrapper.defaultProps = {};

export default UserNameWrapper;
