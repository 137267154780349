const CATEGORY = {
  INCIDENT_CREATION: "Incident Creation",
  INCIDENT_UPDATE: "Incident Update",
}

const ACTION = {
  CREATE_INCIDENT: "Create Incident",
  NEXT_CLIP: "Next Clip",
  ATTACH_CLIP: "Attach Clip",
  TITLE_EDIT: "Title Edited",
  ADDRESS_EDIT: "Address Edited",
  DESCRIPTION_EDIT: "Description Edited",
  TWITTER_EDIT: "Autotweet Edited",
  DISPLAY_ADDRESS_EDIT: "Display Address Edited",
  LEVEL_EDIT: "Incident Level Edited",
  NEWS_LEVEL_EDIT: "News Level Edited",
  ANNOTATION_CLICKED: "Annotation Clicked",
  METADATA_SHOWN: "Metadata Shown",
  ASSIST_AUTHORING: "Assist Authoring",
}

const LABEL = {
  BUTTON: "Button",
  KEYBOARD: "Keyboard",
  AUTOGENERATED: "Autogenerated",
}

const VALUE = {
  ON: 1,
  OFF: 0,
}

export default {
  CATEGORY,
  ACTION,
  LABEL,
  VALUE,
}
