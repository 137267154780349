import { Flash } from "@guardian/UI/Alert"

type Device = "microphone" | "speaker"

export const handleAudiError = (device: Device, targetState: boolean) => (
  error: any,
) => {
  const targetAction = targetState
    ? `Could not unmute ${device}.`
    : `Could not mute ${device}.`

  let errorMessage
  if (error.response.data.error) {
    errorMessage = error.response.data.error
  } else if (error.message) {
    errorMessage = error.message
  } else {
    errorMessage = "An error occurred"
  }

  Flash.error(
    `PATCH /video_control failed. ${targetAction} Reason: ${errorMessage}`,
    {
      title: "Error",
    },
  )
}
