import { List, Map as ImmutableMap } from "immutable"
import _map from "lodash/map"

export const DEFAULT_VIEWPORT = {
  center: { lat: 39.8283, lng: -94.5795 },
  zoom: 5,
  markers: new List(),
  markerType: null,
  polygons: new ImmutableMap(),
  activePolygon: null,
  radii: new ImmutableMap(),
}

const viewportReducer = (state, action) => {
  let newState = state
  switch (action.type) {
    case "updateViewport": {
      const viewport = action.data
      let diff = false

      _map(viewport, (val, key) => {
        if (state[key] !== val && val !== undefined) {
          diff = true
        }
      })

      if (diff) {
        newState = {
          ...state,
          ...viewport,
        }
      }
      break
    }
    case "setActivePolygon": {
      const { polygon, center } = action.data
      newState = { ...state, activePolygon: polygon || null }

      if (center) {
        newState.center = center
      }
      break
    }
    case "addPolygon":
      if (action.data.polygon) {
        newState = {
          ...state,
          polygons: state.polygons.set(action.data.key, action.data.polygon),
        }
      }
      break
    case "removePolygon":
      if (state.polygons.get(action.data.key)) {
        newState = {
          ...state,
          polygons: state.polygons.delete(action.data.key),
        }
      }
      break
    case "togglePolygon": {
      const { key } = action.data
      if (action.data.polygon && !state.polygons.get(key)) {
        newState = {
          ...state,
          polygons: state.polygons.set(key, action.data.polygon),
        }
      } else {
        newState = {
          ...state,
          polygons: state.polygons.delete(key),
        }
      }
      break
    }
    case "addRadius": {
      const { radius } = action.data
      if (radius && action.data.key) {
        newState = {
          ...state,
          radii: state.radii.set(action.data.key, radius),
        }
      }
      break
    }
    case "removeRadius":
      if (state.radii.get(action.data.key)) {
        newState = {
          ...state,
          radii: state.radii.delete(action.data.key),
        }
      }
      break
    case "setCenter":
      newState = {
        ...state,
        center: action.data,
      }
      break
    case "setZoom":
      newState = {
        ...state,
        zoom: action.data,
      }
      break
    case "updateSelected":
      newState = {
        ...state,
        selected: action.data,
      }
      break
    default:
      break
  }

  return newState
}

export default viewportReducer
