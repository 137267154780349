import dayjs from "dayjs"

import { track } from "@guardian/Analytics/segment"
import { Contents } from "@guardian/API/Optimus"
import {
  ContentMergeContentData as ContentServiceMergeContentData,
  ContentP2PMergeContentData as ContentServiceP2PMergeContentData,
  ContentRejectContentData as ContentServiceRejectContentData,
  ContentVerifyContentData as ContentServiceVerifyContentData,
  ContentIncidentApproveContentData as ContentServiceIncidentApproveContentData,
} from "@guardian/API/Optimus/resources/Contents"

const ContentService = {
  ackContent: (contentType: string, contentId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Contents.ackContent(contentType, contentId, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  blockContent: (
    contentType: string,
    contentId: string,
    location: string,
    data: ContentServiceRejectContentData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.rejectContent(contentType, contentId, data, options)
        .then(() => {
          if (contentType === "video_stream") {
            track("Video Blocked", { videoStreamId: contentId, location })
          }

          resolve(true)
        })
        .catch(reject)
    })
  },

  mergeContent: (
    contentType: string,
    contentId: string,
    location: string,
    data: ContentServiceMergeContentData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.mergeContent(contentType, contentId, data, options)
        .then(() => {
          if (contentType === "video_stream") {
            track("Video Merged", { videoStreamId: contentId, location })
          }

          resolve(true)
        })
        .catch(reject)
    })
  },

  mergeP2PContent: (
    contentId: string,
    data: ContentServiceP2PMergeContentData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.mergeP2PContent(contentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  rejectContent: (
    contentType: string,
    contentId: string,
    location: string,
    data: ContentServiceRejectContentData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.rejectContent(contentType, contentId, data, options)
        .then(() => {
          if (contentType === "video_stream") {
            track("Video Rejected", { videoStreamId: contentId, location })
          }

          resolve(true)
        })
        .catch(reject)
    })
  },

  verifyContent: (
    contentType: string,
    contentId: string,
    data: ContentServiceVerifyContentData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.verifyContent(contentType, contentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  iglIncidentApproveContent: (
    contentType: string,
    contentId: string,
    data: ContentServiceIncidentApproveContentData,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.iglIncidentApproveContent(contentType, contentId, data, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  isReporterContent: (
    contentType: string,
    contentId: string,
    isReporter: boolean,
    options?: any,
  ) => {
    return new Promise((resolve, reject) => {
      Contents.isReporterContent(contentType, contentId, isReporter, options)
        .then(() => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  getModerationLog: (userId: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Contents.getModerationLog(userId, options)
        .then(({ data: { content: moderationLog } }) => {
          resolve(moderationLog)
        })
        .catch(reject)
    })
  },

  getUnacknowledgedContent: (options?: any) => {
    return new Promise((resolve, reject) => {
      Contents.getUnacknowledgedContent(options)
        .then(({ data: { content: responseContent } }) => {
          const unacknowledgedContent = responseContent.filter((s: any) => {
            const createdAt =
              s.type === "video_stream"
                ? s.videoStream.createdAt
                : s.image.createdAt

            return dayjs(createdAt).isAfter(dayjs().subtract(1, "day"))
          })

          resolve(unacknowledgedContent)
        })
        .catch(reject)
    })
  },

  getUnresolvedContent: (psn: string, options?: any) => {
    return new Promise((resolve, reject) => {
      Contents.getUnresolvedContent(psn, options)
        .then(({ data: { unresolved: unresolvedContent } }) => {
          resolve(unresolvedContent)
        })
        .catch(reject)
    })
  },

  getContentCreators: (options?: any) => {
    return new Promise((resolve, reject) => {
      Contents.getContentCreators(options)
        .then(({ data: { creators } }) => {
          resolve(creators)
        })
        .catch(reject)
    })
  },

  getMultimediaUploadMetadata(contentId: string, options?: any) {
    return new Promise((resolve, reject) => {
      Contents.getMultimediaUploadMetadata(contentId, options)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(() => {
          resolve({})
        })
    })
  },
}

export default ContentService
