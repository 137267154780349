import classNames from "classnames";
import styled from "styled-components";

import { ButtonGroup } from "@guardian/UI/Button";

const LifecycleButtonGroup = styled(ButtonGroup).attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__tab-bar__lifecycle-button-group"
      )
    }
  }
)`
  margin-left: auto;
  margin-right: var(--space-medium);
  align-items: center;

  > * + * {
    margin-left: var(--space-small);
  }
`;

export default LifecycleButtonGroup;
