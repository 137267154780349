import React from "react"
import styles from "@guardian/Components/ModSOS/components/CallQueue/Banner/Banner.module.css"
import { analystJoinSideEffects } from "@guardian/Components/ModSOS/api"
import { IProps } from "@guardian/Components/ModSOS/components/CallQueue/Banner/types"

export const Banner: React.FunctionComponent<IProps> = ({ sessionId }) => {
  const handleClick = () =>
    analystJoinSideEffects(`/sos/${sessionId}`, sessionId)

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.header}>CALL INCOMING</div>
      <div className={styles.nonHeader}>Click to answer</div>
    </div>
  )
}
