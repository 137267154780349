import { ISymbol, ISymbolDTMFType } from "@guardian/Components/DialPad/types"

export const symbols: ISymbol[] = [
  {
    value: 1,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 2,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 3,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 4,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 5,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 6,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 7,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 8,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: 9,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: "*",
    dtmfType: ISymbolDTMFType.CHAR
  },
  {
    value: 0,
    dtmfType: ISymbolDTMFType.DIGIT
  },
  {
    value: "#",
    dtmfType: ISymbolDTMFType.CHAR
  },
]
