import classNames from "classnames";
import styled from "styled-components";

import Hint from "./Hint";

const Label = styled.label.attrs(
  props => {
    return {
      className: classNames("form__label")
    };
  }
)`
  color: var(--colors-white);
  display: block;
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-small);
  margin-bottom: var(--space-small);
  max-width: var(--measures-small);

  & + ${ Hint } {
    // Semi-cancel-out margin bottom for our Label if succeeded by a Hint.
    margin-top: calc(-1 * var(--space-x-small));
  }
`;

Label.propTypes = {};

Label.defaultProps = {};

export default Label;
