import React, { useEffect, useState } from "react"
import classNames from "classnames"

import { eventHub } from "@guardian/State"
import { ClipService } from "@guardian/Services/Radio"
import type { Clip } from "@guardian/Types/Clip"

import FileInput from "@guardian/Components/Form/Inputs/FileInput"

import styles from "./styles.module.css"

interface UploadControlsProps {
  setIsFetchingClips: (isFetching: boolean) => void
}

const UploadControls: React.FC<UploadControlsProps> = ({
  setIsFetchingClips,
}) => {
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined)
  const [clipIds, setClipIds] = useState<string[]>([])

  useEffect(() => {
    if (csvFile) {
      const reader = new FileReader()
      reader.onload = async e => {
        const csvData = e.target?.result as string

        const columnToRead = 0 // Change this to the index of the column you want to read (0-based index)

        const lines = csvData.split("\n")

        lines.forEach((line, index) => {
          lines[index] = line.replace(/\r?\n|\r/g, "")
        })

        let columnValues: string[] = []
        lines.forEach(line => {
          const columns = line.split(",")
          if (columns.length > columnToRead) {
            columnValues.push(columns[columnToRead])
          }
        })

        // ignore first line
        // ignore empty strings
        setClipIds(
          columnValues.slice(1, 4000).filter(id => id != null && id != ""),
        )
      }
      reader.readAsText(csvFile)
    }
  }, [csvFile])

  useEffect(() => {
    const fetchClipIds = async () => {
      setIsFetchingClips(true)
      try {
        const [clipsToLabel, multiplayerPins] = await Promise.allSettled([
          ClipService.getClips({
            channels: [""],
            withFlagStats: true,
            withMetadata: true,
            includeClips: clipIds,
          }),
          ClipService.getMultiplayerPinnedClipsBatch({ includeClips: clipIds }),
        ])
        if (multiplayerPins.status === "fulfilled") {
          // getMultiplayerPinnedClipsBatch returns clips that are
          // 1) played by the user within last 20 min or
          // 2) specified in `includeClips`
          // Here we only need clips in the `includedClips` list
          eventHub.emit(
            "setMultiplayerPinnedClips",
            (multiplayerPins.value as { id: string }[]).filter(p =>
              clipIds.includes(p.id),
            ),
          )
        }
        if (clipsToLabel.status === "fulfilled") {
          const parsedClips = (clipsToLabel.value as Clip[])?.map(clip => {
            if (!clip) {
              return null
            }
            // NOTE: We delete clip files older than 30 days in staging.
            // In order to allow labeling of clips older than 30 days,
            // we need to point the clip URL directly to the production bucket.
            const clipUrl = `storage.googleapis.com/citizen-production/${clip.id}.wav`
            return { ...clip, url: clipUrl, wav_url: clipUrl }
          }).filter(Boolean)
          eventHub.emit('updateClips', parsedClips)
        }
      } finally {
        setIsFetchingClips(false)
      }
    }

    if (clipIds.length > 0) {
      fetchClipIds()
    }
  }, [clipIds])

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>
          <i className={classNames("fas fa-upload", styles.icon)} />
          Upload CSV
        </h1>
      </div>
      <div className={styles.row}>
        <p>
          NOTE: File should be in a CSV format, with the clip_id as the first
          column
        </p>
      </div>
      <div>
        <FileInput accept='.csv' onChange={setCsvFile} />
      </div>
    </div>
  )
}

export default UploadControls
