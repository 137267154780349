import Container from "@guardian/Components/Common/Container"
import React, { useState } from "react"
import { CloseIcon } from "@guardian/Components/ModSOS/components/Map/CloseIcon/CloseIcon"
import styles from "@guardian/Components/ModSOS/components/Map/HomeModal/HomeModal.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/Map/HomeModal/types"

export const HomeModal = ({ address, onClick }: IProps) => {
  const [showTooltip, setShowTooltip] = useState(true)

  return (
    <>
      {showTooltip && (
        <Container
          fullWidth
          left={2}
          right={2}
          className={styles.tooltipWrapper}
        >
          <Container
            fullWidth
            className={styles.tooltip}
            top={2}
            bottom={2}
            left={3}
            right={3}
          >
            <Container className={styles.address}>
              Home address: {address}
            </Container>
            <button
              className={styles.close}
              onClick={() => setShowTooltip(false)}
            >
              <CloseIcon />
            </button>
          </Container>
        </Container>
      )}
      <Container className={styles.modal}>
        <button
          className={styles.noStyle}
          onClick={() => {
            onClick()
            setShowTooltip(true)
          }}
        >
          <Container
            top={3}
            bottom={3}
            left={3}
            right={3}
            className={styles.button}
          >
            <img alt='home' src='/home.svg' />
          </Container>
        </button>
      </Container>
    </>
  )
}
