import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const XSmallParagraphStyles = css`
  font-size: var(--font-sizes-x-small);
  line-height: var(--line-heights-x-small);

  & + & {
    margin-top: var(--line-heights-x-small);
  }

  &.paragraph--with-measure {
    max-width: var(--measures-x-small);
    width: 100%;
  }
`;

const XSmallParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__x-small-paragraph")
    };
  }
)`
  ${ XSmallParagraphStyles }
`;

XSmallParagraph.propTypes = {
  ...Paragraph.propTypes
};

XSmallParagraph.defaultProps = {};

export default XSmallParagraph;
