import { ISessionPayload } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"

export enum SensorType {
  safe_word_detection = "Safeword",
  scream_detection = "Scream",
  glass_detection = "Glass",
  shake_detection = "Shake",
  crash_detection = "Crash",
}

export const chatOnlyMode = "chatOnly"
export const videoCallMode = "videoCall"

export interface IProps {
  payload: ISessionPayload
  currentId: string
}
