import React from "react";

import useFieldContext from "../hooks/useFieldContext";
import StyledMessage from "../views/Message";

const Message = React.forwardRef((props, ref) => {
  const fieldContext = useFieldContext();

  let combinedProps = {
    ref,
    ...props
  };

  if (fieldContext) {
    combinedProps = fieldContext.getMessageProps(combinedProps);
  }

  return (
    <StyledMessage ref={ref} {...combinedProps}/>
  );
});

export default Message;
