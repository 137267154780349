import classNames from "classnames";
import styled from "styled-components";

const SystemMessageGroup = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__system-message-group"
      )
    }
  }
)`
  padding: var(--space-large) var(--space-x-large);
`;

export default SystemMessageGroup;
