const radii = [
  "2px",
  "4px",
  "8px",
  "16px",
  "24px"
];

// Aliases
radii.xSmall = radii[0];
radii.small = radii[1];
radii.medium = radii[2];
radii.large = radii[3];
radii.xLarge = radii[4];

radii.circle = "100%";
radii.oval = "50%";
radii.pill = "9999px";

export const radiiCSSVariables = `
  :root {
    --radii-x-small: ${ radii.xSmall };
    --radii-small: ${ radii.small };
    --radii-medium: ${ radii.medium };
    --radii-large: ${ radii.large };
    --radii-x-large: ${ radii.xLarge };

    --radii-circle: ${ radii.circle };
    --radii-oval: ${ radii.oval };
    --radii-pill: ${ radii.pill };
  }
`;

export default radii;
