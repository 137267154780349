import React from "react"

import { BadgeContainer } from "./styles"

const WarningBadge = ({ children, ...props }) => (
  <BadgeContainer
    bgColor='color-mix(in srgb, currentColor, transparent 90%)'
    textColor='var(--colors-yellow-050)'
    {...props}
  >
    <span>!</span>
    {children}
  </BadgeContainer>
)

export default WarningBadge
