import React, { FC } from "react"
import Container from "@guardian/Components/Common/Container"
import classnames from "classnames"
import { SOSGeneralAddCallPane } from "@guardian/Components/ModSOS/components/GeneralActionPane/SOSGeneralAddCallPane/SOSGeneralAddCallPane"
import styles from "@guardian/Components/ModSOS/components/GeneralActionPane/SOSGeneralActionPane/SOSGeneralActionPane.module.css"

export const SOSGeneralActionPane: FC = React.memo(() => (
  <Container fullHeight topAlign column style={{ width: "25%" }}>
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.tabs}>
          <div className={classnames(styles.tab, styles.active)}>Call User</div>
        </div>
        <div className={styles.wrapper}>
          <SOSGeneralAddCallPane />
        </div>
      </div>
    </div>
  </Container>
))
