import React, { useEffect } from "react";
import PropTypes from "prop-types";

import useDropdownContext from "../../../../hooks/useDropdownContext";
import useMenuContext from "../../../../hooks/useMenuContext";

const useItem = props => {
  const {
    disabled,
    autoFocusSelected = false,
    itemRef,
    value
  } = props;

  const {
    selectedItems,
    downshift: {
      getItemProps: getDownshiftItemProps,
      highlightedIndex,
      isOpen,
      itemToString,
      selectedItem,
      setHighlightedIndex
    }
  } = useDropdownContext();

  const { itemIndexRef } = useMenuContext();

  if ((value === undefined || value === null) && !disabled) {
    throw new Error("All Item components require a `value` prop");
  }

  const currentIndex = itemIndexRef.current;

  // Bit of a conusing hack here. We want our dropdown to be able to sometimes
  // only highlight selectable items. This feature is also useful for when
  // we want to auto highlight a previously selected item, in examples such as
  // when using a dropdown as a "Select" UI. However, our components only send
  // this value down via the focused prop in the case that we are using this
  // functionality. If we are'nt we just default to auto behaviour, handled by
  // CSS, here.
  const focused = autoFocusSelected && (highlightedIndex === currentIndex);

  let selected;
  // Calculate selection if provided value is an `object`.
  if (value) {
    if (selectedItems) {
      selected = selectedItems.some(item => {
        return itemToString(item) === itemToString(value);
      });
    } else {
      selected = itemToString(selectedItem) === itemToString(value);
    }
  } else {
    selected = false;
  }

  useEffect(() => {
    // Highlight selected item when Select/Autocomplete is open.
    if (isOpen && !disabled && !selectedItems && selected) {
      setHighlightedIndex(currentIndex);
    }
  }, [
    currentIndex,
    disabled,
    isOpen,
    selected,
    selectedItems,
    setHighlightedIndex
  ]);

  const getItemProps = () => {
    let itemProps;

    if (disabled) {
      itemProps = {
        focused,
        selected,
        ...props
      };
    } else {
      itemProps = {
        ...getDownshiftItemProps({
          item: value,
          focused: focused,
          selected: selected,
          ref: itemRef,
          ...props
        })
      };
    }

    return itemProps;
  };

  if (!disabled) {
    // Only increment current item index if the `Item` is not disabled
    itemIndexRef.current++;
  }

  return getItemProps();
};

export default useItem;
