import styled from "styled-components"

export const CardContainer = styled.div.withConfig({
  shouldForwardProp: prop => !["bgUrl", "isActive"].includes(prop),
})`
  min-height: 140px;
  width: 100%;
  background: #0d131ab2;
  border-radius: var(--radii-large);
  overflow: hidden;
  position: relative;
  text-align: left;
  border: 2px solid transparent;

  font-family: Lato;
  padding: 17px 24px;
  padding-right: 12px;

  ${({ isActive }) => isActive && `border-color: #CEDAF2;`};

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header badges"
    "info   badges"
    "footer badges";
  gap: var(--space-x-small);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    background-image: url(${({ bgUrl }) => bgUrl});
    filter: blur(4px);
  }

  & > div {
    z-index: var(--z-positions-above);
  }
`

export const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;

  font-weight: bold;
  font-size: var(--font-sizes-medium);
  line-height: 16px;
`

export const HeaderText = styled.div`
  color: #cedaf2;
`

export const Badges = styled.div`
  grid-area: badges;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
`

export const Info = styled.div`
  grid-area: info;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`

export const Footer = styled.div`
  grid-area: footer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  font-weight: bold;
`
