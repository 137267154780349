import React, { useCallback, useState, useEffect } from "react"
import style from "./IncidentContentPane.module.css"
import { object, string, func, bool, number } from "prop-types"

import VerifyModal from "@guardian/Components/Modals/VerifyModal"
import BlockModal from "@guardian/Components/Modals/BlockModal"

import VideoContent from "./VideoContent"
import PhotoContent from "./PhotoContent"

import { getContentId } from "@guardian/Components/ModIGL/ModIGL.helper"

import consts from "@guardian/Constants"
import { track } from "@guardian/Analytics/segment"
import { ContentService } from "@guardian/Services/Content"
import { IncidentService } from "@guardian/Services/Incident"
import combokeys from "@guardian/Utils/hotkeys"

const IncidentContentPane = ({
  activeContent,
  onResolve,
  incidentId,
  incidentThumb,
  incidentStreamersCount,
  incidentAuthor,
  unverifiedIGLIncident,
}) => {
  const [showVerify, setShowVerify] = useState(false)
  const [showBlock, setShowBlock] = useState(false)

  const confirmed =
    activeContent.type === "video_stream"
      ? activeContent.videoStream.confirmed
      : activeContent.image.confirmed

  useEffect(() => {
    combokeys.bind("v", () => {
      setShowVerify(true)
    })
    combokeys.bind("b", () => {
      setShowBlock(true)
    })
    combokeys.bind("r", () => {
      setShowBlock(true)
    })

    return () => {
      combokeys.unbind("v")
      combokeys.unbind("b")
      combokeys.unbind("r")
    }
  }, [])

  const handleBlock = useCallback(async (reason) => {
    await ContentService.blockContent(
      activeContent.type,
      getContentId(activeContent),
      "Content Mod",
      { reason }
    )
    if (incidentId && incidentAuthor === consts.AutomatedUsername && incidentStreamersCount <= 1) {
      // Also archive the incident if it's a P2P incident and not Citizen Approved
      // (i.e.: Unmoderated OR Community Approved)
      try {
        await IncidentService.updateIncident(incidentId, { deleted: true })
      } catch (error) {
        console.error(error)
      }
    }
    setShowBlock(false)
    onResolve()
  }, [activeContent, incidentId, incidentAuthor, incidentStreamersCount, onResolve])

  return (
    <div className={style.pane}>
      <h4>Media Details</h4>
      {activeContent.type === "video_stream" && (
        <VideoContent
          stream={activeContent}
          incidentId={incidentId}
          incidentThumb={incidentThumb}
          setShowBlock={setShowBlock}
          setShowVerify={setShowVerify}
          unverifiedIGLIncident={unverifiedIGLIncident}
        />
      )}
      {activeContent.type === "image" && (
        <PhotoContent
          image={activeContent}
          setShowBlock={setShowBlock}
          setShowVerify={setShowVerify}
        />
      )}

      {showVerify && (
        <VerifyModal
          verified={confirmed}
          onVerify={async () => {
            await ContentService.verifyContent(
              activeContent.type,
              getContentId(activeContent),
              { confirmed: !confirmed }
            )
            if (activeContent.type === "video_stream") {
              track("Live Video Verify", {
                videoStreamId: getContentId(activeContent),
                location: "Content Mod",
                type: "verified_live", // VL, EC, Custom
              })
            }
            setShowVerify(false)
          }}
          onCancel={() => setShowVerify(false)}
          contentType={activeContent.type}
        />
      )}
      {showBlock && (
        <BlockModal
          onBlock={handleBlock}
          onCancel={() => setShowBlock(false)}
          contentType={activeContent.type}
        />
      )}
    </div>
  )
}

IncidentContentPane.propTypes = {
  activeContent: object,
  onResolve: func,
  incidentId: string,
  incidentThumb: string,
  incidentStreamersCount: number,
  incidentAuthor: string,
  unverifiedIGLIncident: bool,
}

export default IncidentContentPane
