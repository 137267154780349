import React, { FC } from "react"
import { Participant } from "twilio-video"
import styles from "@guardian/Components/ModSOS/components/Video/Twillio/Twilio.module.css"
import { MuteButton } from "@guardian/Components/ModSOS/components/Video/Twillio/components/MuteButton"

export interface IProps {
  muteUserSpeaker?: (shouldMute: boolean) => void
  isUserSpeakerMuted?: boolean
  muteUserMicrophone?: (
    identity: Participant.Identity,
    isDisabled: boolean
  ) => void
  isUserMicrophoneDisabled?: boolean
  participant: Participant
}

export const VideoControls: FC<IProps> = ({
  muteUserSpeaker,
  isUserSpeakerMuted,
  muteUserMicrophone,
  isUserMicrophoneDisabled,
	participant,
}) => (
  <div className={styles.buttonsContainer}>
    {muteUserSpeaker && (
      <MuteButton
        onClick={() => muteUserSpeaker(!isUserSpeakerMuted)}
        isMuted={Boolean(isUserSpeakerMuted)}
        type='audio'
      />
    )}
    {muteUserMicrophone && (
      <MuteButton
        onClick={() => {
          muteUserMicrophone(participant.identity, !isUserMicrophoneDisabled)
        }}
        isMuted={Boolean(isUserMicrophoneDisabled)}
        type='mic'
      />
    )}
  </div>
)
