import React from "react";
import PropTypes from "prop-types";

import { TabsContext } from "../hooks/useTabsContext";

const Tabs = props => {
  const {
    children,
    onChange,
    selectedTab
  } = props;

  return (
    <TabsContext.Provider value={{ selectedTab, onChange }}>
      { children }
    </TabsContext.Provider>
  );
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  selectedTab: PropTypes.string
};

export default Tabs;
