import React, { useEffect } from "react"
import classNames from "classnames"
import { isMobileOnly } from "react-device-detect"
import { Helmet } from "react-helmet"

import LoginFlow from "./LoginFlow"

import "./LandingPage.scss"

const LandingPage = () => {
  useEffect(() => {
    if (isMobileOnly) {
      const timeoutId = setTimeout(() => window.scroll(0, 0), 1000)
      return () => clearTimeout(timeoutId)
    }
  }, [])

  return (
    <div className={classNames("landing-page", { mobile: isMobileOnly })}>
      {
        isMobileOnly && (
          <Helmet>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
            />
          </Helmet>
        )
      }
      <div className='landing-right'>
        <LoginFlow/>
      </div>
    </div>
  )
}

export default LandingPage
