import React from "react";

import useModalContext from "../hooks/useModalContext";
import StyledClose from "../views/Close";

const Close = React.forwardRef((props, ref) => {
  const modalContext = useModalContext();

  return (
    <StyledClose ref={ref} {...modalContext.getCloseProps(props)}/>
  );
});

export default Close;
