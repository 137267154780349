import dayjs from "dayjs"

import { IncidentTemplates } from "@guardian/API/Optimus";
import config from "@guardian/Config";
import { IncidentService } from "@guardian/Services/Incident";

const withinTenMinutes = timestamp => {
  return dayjs().diff(dayjs.unix(timestamp), "minute") <= 10
}

export const drawHelicopterPath = async (map, incidentId) => {
  const path = await IncidentService.getIncidentHelicopterFlightPath(incidentId)
  if (path && path.GetHistoricalTrackResult) {
    const data = path.GetHistoricalTrackResult.data
    const filtered = data.filter(update => withinTenMinutes(update.timestamp))
    const mapped = filtered.map(up => ({ lat: up.latitude, lng: up.longitude }))

    map.map.data.add({
      geometry: new map.maps.Data.LineString(mapped),
      id: incidentId,
    })

    map.map.data.setStyle({ strokeColor: "deepskyblue" })
  }
}

export const linkHelicopterToIncident = async (
  helicopterIncident,
  baseIncident,
) => {
  const incidentScrollTemplate = await getIncidentScrollTemplate()
  await createOrUpdateRelatedIncident(
    incidentScrollTemplate,
    helicopterIncident,
    baseIncident,
    1,
  )
  await createOrUpdateRelatedIncident(
    incidentScrollTemplate,
    baseIncident,
    helicopterIncident,
    0,
  )
}

const createOrUpdateRelatedIncident = async (
  incidentScrollTemplate,
  incident,
  incidentToLink,
  spliceIndex,
) => {
  let modules = []
  try {
    modules = JSON.parse(atob(incident.incidentModuleInfo)).modules
  } catch (err) {}

  const relatedIndex = modules.findIndex(
    module => module.template && module.template.name === "incident_scroll",
  )
  const relatedModule = relatedIndex !== -1 ? modules[relatedIndex] : undefined

  // Link incident
  let finalData = {}
  if (!relatedModule) {
    // No incident scroll module on incident yet, so no related incidents yet
    const jsonData = { ...relatedIncidentsJsonFormat }
    jsonData.filters.incidentsList = [incidentToLink.id]
    finalData = { ...jsonData }
  } else {
    // Already an incident scroll, so edit list of incidents
    const existing = await getExistingModuleData(relatedModule)
    finalData = { ...existing.respData }
    finalData.filters.incidentsList.push(incidentToLink.id)
  }
  const newModule = await prepareUpload(
    finalData,
    incidentScrollTemplate.assetsBaseUrl,
    incidentScrollTemplate.gcsBaseUrl,
  )
  newModule.type = "web"

  if (!relatedModule) {
    modules.splice(spliceIndex, 0, newModule)
  } else {
    modules[relatedIndex] = newModule
  }

  const preparedModules = modules.map((module, index) => {
    return {
      ...module,
      rank: index,
    }
  })

  IncidentService.createIncidentModules(
    incident.id,
    { modules: preparedModules }
  )
    .catch(
      console.error,
    )
}

const relatedIncidentsJsonFormat = {
  filters: {
    Incidents: "IncidentIds",
    incidentsList: [],
    branch_link: "https://go.citizen.com/WzZI8wD78ab",
    city_code: null,
    created_at: null,
    keywords: null,
    "level.sign": "greater than or equal to",
    neighborhood: null,
    prod_or_staging: config.production
      ? "Production"
      : "Staging",
    "ranking.level": 0,
    updated_at: null,
  },
  title: "incident_scroll",
  url: "",
}

const getIncidentScrollTemplate = async () => {
  const { data: { templates } } = await IncidentTemplates.getIncidentTemplates()
  return templates.find(template => template.templateName === "incident_scroll")
}

const getExistingModuleData = async module => {
  const templateName = module.template.name
  const jsonFile = module.template.gcsUrl.split("jsons/")[1]

  const { data } = await IncidentTemplates.getModuleData({
    templateName: templateName,
    dataFile: jsonFile
  });

  return {
    respData: data,
    respName: templateName,
  }
}

const prepareUpload = async (dataJson, url, selectedTemplateBaseUrl) => {
  // URL is Assets URL
  // selectedTemplateBaseUrl is GCS url
  let gcsUrl
  const keys = Object.keys(dataJson)
  const noTemplate =
    keys.length === 2 && keys.includes("title") && keys.includes("url")

  if (!noTemplate) {
    const { data: { relativeLocation } } =
      await IncidentTemplates.uploadTemplateJson({
        templateJson: dataJson,
        templateName: "incident_scroll"
      })
    const relString = "index.html?jsonUrl=" + relativeLocation
    gcsUrl = selectedTemplateBaseUrl + relString
    url += relString
  }

  let returnVal = {
    url,
    title: "RELATED INCIDENTS",
    isHero: false,
    template: {},
  }

  if (gcsUrl) {
    returnVal.template = {
      name: "incident_scroll",
      gcsUrl,
    }
  }
  return returnVal
}
