import React, { Fragment, useRef, useEffect } from "react"
import Modal from "@guardian/Components/Modals/Modal"
import { func, bool, string } from "prop-types"
import style from "./VerifyModal.module.css"

const VerifyModal = ({ onVerify, onCancel, verified, contentType }) => {
  const modal = useRef()

  useEffect(() => {
    if (modal.current) {
      modal.current.focus()
    }
  }, [])

  return (
    <Fragment>
      <div className={style.overlay} />
      <Modal>
        <div
          className={style.modal}
          ref={modal}
          tabIndex={-1}
          onKeyDown={e => {
            let handled = false
            switch (e.key) {
              case "Enter":
                onVerify()
                handled = true
                break
              case "Escape":
                onCancel()
                handled = true
                break
              default:
                break
            }

            if (handled) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        >
          <div className={style.circle}>
            <i className='fas fa-check' />
          </div>
          <div>
            <h4>
              Are you sure you want to {verified ? "unverify" : "verify"} this
              {contentType === "video_stream" ? "video" : "image"}?
            </h4>
            <div className={style.buttons}>
              <button className={style.verify} onClick={onVerify}>
                {verified ? "Unverify" : "Verify"}
              </button>
              <button onClick={onCancel} className={style.cancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

VerifyModal.propTypes = {
  onVerify: func,
  onCancel: func,
  verified: bool,
  contentType: string,
}

export default VerifyModal
