import GoogleMapReact from "google-map-react"
import { Set } from "immutable"
import { array, bool, func, node, number, object, string } from "prop-types"
import React, { useEffect, useReducer, useRef, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import consts from "@guardian/Constants"
import { getIncidentCategory } from "@guardian/Utils/util"

import Circle from "./Circle"
import { drawHelicopterPath } from "./HelicopterHelpers"
import CoverageAreaMarker from "./Markers/CoverageAreaMarker"
import HelicopterMarker from "./Markers/HelicopterMarker"
import IncidentMarker from "./Markers/IncidentMarker"
import ServiceAreaMarker from "./Markers/ServiceAreaMarker"
import Polygon from "./Polygon"
import { Route } from "./Route"
import styles from "./style.json"
import viewportReducer, { DEFAULT_VIEWPORT } from "./viewportReducer"

const Map = ({
  defaultCenter,
  draggable,
  allowControls,
  defaultZoom,
  onBoundsChange,
  defaultStyle,
  streetView,
  selected,
  children,
  routeCoords,
}) => {
  const readOnly = useGlobalState(state => state.global.readOnly)

  const shapeRef = useRef({
    polygons: [],
    radii: [],
  })
  const mapRef = useRef({})
  const [helicopterSet, setHelicopterSet] = useState(new Set())
  const [helicopterIncidents, setHelicopterIncidents] = useState([])
  const [showMarkerPopupId, setShowMarkerPopupId] = useState("")
  const [map, setMap] = useState(null)
  const [viewport, dispatch] = useReducer(viewportReducer, {
    ...DEFAULT_VIEWPORT,
    center: defaultCenter || DEFAULT_VIEWPORT.center,
    zoom: defaultZoom || DEFAULT_VIEWPORT.zoom,
  })

  useEffect(() => {
    dispatch({
      type: "setZoom",
      data: defaultZoom,
    })
  }, [defaultZoom])

  const [dragging, setDragging] = useState(false)
  const [activeMarker, setActiveMarker] = useState(null)

  const handleDragStart = (index, { model }) => {
    if (model && (model.id === selected || model.active)) {
      setDragging(true)
    }
  }
  const handleDragging = (index, marker, position) => {
    if (dragging && !readOnly) {
      setActiveMarker({
        ...activeMarker,
        center: [position.lng, position.lat],
        active: true,
      })
    }
  }
  const handleDragEnd = () => {
    if (activeMarker) {
      eventHub.emit("updateIncidentLocation", {
        lat: activeMarker.center[1],
        lng: activeMarker.center[0],
      })
    }

    setDragging(false)
  }

  useEffect(() => {
    const drawPolygons = () => {
      const { polygons, activePolygon } = viewport

      if (map) {
        shapeRef.current.polygons.forEach(p => p.setMap(null))
        const polys = activePolygon
          ? polygons.set("active", activePolygon)
          : polygons

        shapeRef.current.polygons = Set(polys.values()).map(p =>
          Polygon({ map, polygon: p }),
        )
      }
    }

    const drawRadii = () => {
      const { markers, markerType } = viewport
      if (map) {
        shapeRef.current.radii.forEach(r => r.setMap(null))
        if (markerType === "incident") {
          shapeRef.current.radii = markers
            .filter(m => selected === m.id)
            .concat(activeMarker?.notifRadiusM ? [activeMarker] : [])
            .concat(viewport.radii.valueSeq().toArray())
            .map(m => Circle({ map, circle: m }))
        }
      }
    }

    drawPolygons()
    drawRadii()
  }, [activeMarker, map, selected, viewport])

  useEffect(() => {
    const handleSetActiveMarker = marker => {
      if (marker === undefined) {
        setActiveMarker(null)
      } else if (marker) {
        setActiveMarker({
          ...marker,
          type: "incident",
          center: [marker.lng, marker.lat],
          active: true,
        })
      }
    }
    const handleNotifRadius = radius => {
      const incident =
        activeMarker || viewport.markers.find(m => selected === m.id)

      if (incident) {
        setActiveMarker({
          ...incident,
          active: true,
          type: "incident",
          notifRadiusM: radius * consts.MileToMeter,
        })
      }
    }

    let unmounted = false
    const bindings = [
      {
        name: "set map",
        cb: map =>
          !unmounted && dispatch({ type: "updateViewport", data: map }),
      },
      {
        name: "set active polygon",
        cb: (polygon, center) =>
          !unmounted &&
          dispatch({ type: "setActivePolygon", data: { polygon, center } }),
      },
      {
        name: "toggle polygon",
        cb: (key, polygon) =>
          !unmounted &&
          dispatch({ type: "togglePolygon", data: { key, polygon } }),
      },
      {
        name: "add polygon",
        cb: (key, polygon) =>
          !unmounted &&
          dispatch({ type: "addPolygon", data: { key, polygon } }),
      },
      {
        name: "remove polygon",
        cb: key =>
          !unmounted && dispatch({ type: "removePolygon", data: { key } }),
      },
      {
        name: "add radius",
        cb: (key, radius) =>
          !unmounted &&
          dispatch({
            type: "addRadius",
            data: {
              key,
              radius: {
                ...radius,
                notifRadiusM: radius.radiusMi
                  ? radius.radiusMi * consts.MileToMeter
                  : radius.radiusM,
              },
            },
          }),
      },
      {
        name: "remove radius",
        cb: key =>
          !unmounted && dispatch({ type: "removeRadius", data: { key } }),
      },
      {
        name: "setNotifRadius",
        cb: radius => !unmounted && handleNotifRadius(radius),
      },
      {
        name: "set active marker",
        cb: location => !unmounted && handleSetActiveMarker(location),
      },
      {
        name: "set center",
        cb: center =>
          !unmounted && dispatch({ type: "setCenter", data: center }),
      },
    ]

    bindings.forEach(b => eventHub.on(b.name, b.cb))

    // Make a list of currently drawn paths for deletion later
    const drawnPathIds = []
    if (map) {
      map.map.data.forEach(feature => {
        drawnPathIds.push(feature.getId())
      })
    }

    // If helicopter incidents, draw paths and add to state
    const helicopterIds = []
    const helicopterIncs = []
    viewport.markers.toArray().forEach(m => {
      const category = getIncidentCategory(m)

      if (category === "Helicopter") {
        const landed = m.title.toLowerCase().includes("landed")
        if (map && !landed) {
          drawHelicopterPath(map, m.id)
        }
        if (landed && drawnPathIds.includes(m.id)) {
          // Remove path if the helicopter has landed since last drawing path
          map.map.data.remove(map.map.data.getFeatureById(m.id))
        }
        helicopterIds.push(m.id)
        helicopterIncs.push(m)
      }
    })
    setHelicopterSet(new Set(helicopterIds))
    setHelicopterIncidents(helicopterIncs)

    return () => {
      unmounted = true
      bindings.forEach(b => eventHub.off(b.name, b.cb))
    }
  }, [activeMarker, selected, viewport.markers, map])

  const { center, zoom, markers, markerType } = viewport

  return (
    <GoogleMapReact
      options={{
        styles: defaultStyle ? [] : styles,
        center,
        zoom,
        disableDefaultUI: !allowControls,
        streetViewControl: streetView,
      }}
      bootstrapURLKeys={{
        key: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: "geometry,drawing,places",
      }}
      onGoogleApiLoaded={m => {
        eventHub.emit("mapInit")

        if (onBoundsChange) {
          m.maps.event.addListener(m.map, "bounds_changed", () => {
            onBoundsChange(
              m.map.getBounds(),
              m.map.getCenter(),
              m.map.getZoom(),
            )
          })
        }

        setMap(m)
      }}
      center={center}
      zoom={zoom}
      draggable={draggable && !dragging}
      yesIWantToUseGoogleMapApiInternals
      ref={mapRef}
      onChildMouseDown={handleDragStart}
      onChildMouseMove={handleDragging}
      onChildMouseUp={handleDragEnd}
    >
      {markers.push(activeMarker).map((m, i) => {
        if (!m) {
          return null
        }
        const markerProps = {
          model: m,
          lat: m.center[1],
          lng: m.center[0],
          selected: selected === m.id,
        }
        let Marker = null
        switch (m.type || markerType) {
          case "serviceArea":
            Marker = ServiceAreaMarker
            break
          case "incident":
            if (helicopterSet.has(m.id)) {
              Marker = HelicopterMarker
            } else {
              Marker = IncidentMarker
              markerProps.helicopterIncidents = helicopterIncidents
              markerProps.setShowMenu = setShowMarkerPopupId
              markerProps.showMenu = showMarkerPopupId === m.id
            }

            break
          case "coverageArea":
            Marker = CoverageAreaMarker
            break
          default:
            break
        }
        return Marker && <Marker key={i} {...markerProps} />
      })}
      {children}
      <Route map={map} routeData={routeCoords} />
    </GoogleMapReact>
  )
}

Map.propTypes = {
  defaultCenter: object,
  draggable: bool,
  allowControls: bool,
  defaultZoom: number,
  onBoundsChange: func,
  defaultStyle: bool,
  streetView: bool,
  selected: string,
  children: node,
  routeCoords: array,
}

export default React.memo(Map)
