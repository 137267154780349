import React, { useRef, useEffect, useState } from "react"
import { number, string, func, bool } from "prop-types"
import PlayBar from "@guardian/Components/ClipPlayer/PlayBar"
import style from "./Clip.module.css"
import ClipTimestamp from "@guardian/Components/Clip/ClipTimestamp"
import classnames from "classnames"

const Clip = ({
  id,
  wav_url,
  spectrogram,
  duration_ms,
  channelName,
  onSeek,
  status,
  time,
  onTogglePlay,
  position,
  onPlaying,
  onFinishedPlaying,
  active,
}) => {
  const spectroRef = useRef()
  const [animate, setAnimate] = useState(false)
  const duration = duration_ms / 1000

  useEffect(() => {
    setAnimate(true)
    const timeout = setTimeout(() => {
      setAnimate(false)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [status])

  return (
    <div className='clip-player'>
      <PlayBar
        id={id}
        wav_url={wav_url}
        spectrogram={spectrogram}
        duration_ms={duration_ms}
        spectroRef={spectroRef}
        channelName={channelName}
        seek={e => {
          e.preventDefault()
          e.stopPropagation()
          const clientX = e.clientX
          const target = spectroRef.current
          const rect = target.getBoundingClientRect()
          const offsetX = clientX - rect.left
          let position = (offsetX / target.scrollWidth) * duration_ms

          onSeek(position)
        }}
        status={status}
        togglePlay={onTogglePlay}
        position={position}
        handlePlaying={onPlaying}
        handleFinishedPlaying={onFinishedPlaying}
        activeClipAnimateStatus={animate}
        activeClipStatus={active ? status : undefined}
        trackPlays={false}
      />
      <div className={style.overlay}>
        <div className={classnames("clip-channel-header", style.channel)}>
          {channelName}
        </div>
        <div className='clip-time-info'>
          <div className='clip-duration'>{duration.toFixed(1)}s</div>
          <ClipTimestamp time={time} citMod={false} />
        </div>
      </div>
    </div>
  )
}

Clip.propTypes = {
  id: string,
  wav_url: string,
  spectrogram: string,
  duration_ms: number,
  channelName: string,
  onSeek: func,
  status: string,
  time: string,
  onTogglePlay: func,
  position: number,
  onPlaying: func,
  onFinishedPlaying: func,
  active: bool,
}

export default React.memo(Clip)
