import React, { useCallback, useEffect, useRef, useState } from "react"
import OT from "opentok-react"
import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import { IPropsPublisher } from "@guardian/Components/ModSOS/components/Video/OTP/types"

export const OTPublisher: React.FunctionComponent<IPropsPublisher> = ({
  tokboxSession,
  videoDeviceId,
  userDisabledAgentAudio,
  userDisabledAgentVideo,
}) => {
  const pubRef = useRef<any>(null)
  const [muted, setMuted] = useState<boolean>(false)

  const updateTokboxData = useCallback(async () => {
    tokboxSession.session.on("sessionConnected", (e: any) => {
      if (
        !pubRef.current ||
        !pubRef.current.state.session ||
        !pubRef.current.props.session
      ) {
        return
      }
      if (pubRef.current.state.session.id !== pubRef.current.props.session.id) {
        pubRef.current.setState({ session: tokboxSession.session })
      }
    })
  }, [pubRef, tokboxSession.session])

  useEffect(() => {
    updateTokboxData()
  }, [updateTokboxData])

  return (
    <>
      <button
        className={styles.muteButton}
        onClick={() => {
          setMuted(!muted)
        }}
      >
        {!muted ? (
          <img src='/protect-mic.svg' alt='thumbnails' />
        ) : (
          <img src='/protect-mic-muted.svg' alt='thumbnails' />
        )}
      </button>
      <OT.OTPublisher
        ref={pubRef}
        session={tokboxSession.session}
        properties={{
          insertMode: "before",
          height: "100%",
          width: "100%",
          resolution: "320x240",
          showControls: false,
          publishAudio: !muted,
          videoSource: videoDeviceId,
        }}
      />
      {userDisabledAgentAudio && (
        <Container className={styles.agentMuted} style={{ height: "auto" }}>
          <Container
            className={styles.agentMutedPill}
            top={1}
            bottom={1}
            left={2}
            right={2}
          >
            <img src='/agent-muted.svg' alt='thumbnails' />{" "}
            <Spacing left={1}>
              <span>User muted you</span>
            </Spacing>
          </Container>
        </Container>
      )}
      {userDisabledAgentVideo && (
        <Container className={styles.agentVideoDisabled}>
          User turned off your video
        </Container>
      )}
    </>
  )
}
