import React from "react"
import styles from "./styles.module.css"
import { string, func, any } from "prop-types"

const Banner = ({ title, onClick, style }) => {
  return (
    <div className={styles.banner} onClick={onClick} style={style}>
      {title}
    </div>
  )
}

Banner.propTypes = {
  title: string,
  onClick: func,
  style: any,
}

export default Banner
