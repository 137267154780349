import { useEffect, useState } from "react";

import { VideoStreams } from "@guardian/API/Optimus";
import { LoggingService } from "@guardian/Services/Logging";

interface QueryOptions {
  enabled: string
};

const usePollLiveStreamCount = (options: QueryOptions) => {
  const {
    enabled
  } = options;

  const [count, setCount] = useState(0);

  useEffect(() => {
    // NOTE: We want to treat our hook as enabled by default. Hence, the lack of
    // an enabled value in our hooks options should be considered a go-ahead to
    // treat it as enabled. Hence, we only treat it as not enabled if the value
    // of enabled is falsy _and_ defined.
    if (typeof enabled !== "undefined" && !enabled) { return; }

    const controller = new AbortController();
    const signal = controller.signal;
    let timeout: any;

    const loop = () => {
      VideoStreams.getLiveStreams({ signal })
        .then(({ data: { streams } }) => {
          setCount(streams.length);
          timeout = setTimeout(loop, 2000);
        })
        .catch(error => {
          if (signal.aborted) {
            return;
          }

          LoggingService.logError(
            "Error fetching Live Stream Count.",
            {
              domain: "NavigationBar",
              method: "usePollLiveStreamCount",
              trackedData: {
                error: error
              }
            }
          );

          // TODO: We are disabling this error message until the underlying
          // error can be solved. Observing the screens of analysts when these
          // errors flash have thus far appeared to be CORS errors, and likely
          // have been erroring quietly for some time.
          // Flash.error(
          //   "Something went wrong fetching Live Stream count.",
          //   {
          //     title: "Error"
          //   }
          // );
        });
    }

    loop();

    return () => {
      controller.abort();
      clearTimeout(timeout);
    };
  }, [enabled]);

  return [
    count
  ];
};

export default usePollLiveStreamCount;
