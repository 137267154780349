import React, { useState } from "react"
import style from "./IncidentThumbnail.module.css"
import Thumbnail from "@guardian/Components/Thumbnail"
import { string, number } from "prop-types"
import classnames from "classnames"

import { Moderation } from "@guardian/API/Optimus";
import { IncidentService } from "@guardian/Services/Incident";
import { getThumbUrl } from "@guardian/Utils/util"

const IncidentThumbnail = ({
  selectedThumb,
  incidentId,
  featuredStreamImageCropY,
}) => {
  const cropHeight = 58 // height where width is 103.5 using aspect ratio 1280x720
  const maxHeight = 184
  const actualHeight = 1280 // height of full size image
  const [isLandscape, setIsLandscape] = useState(null)
  const [dragging, setDragging] = useState(null)
  const [y, setY] = useState(
    featuredStreamImageCropY || featuredStreamImageCropY === 0
      ? (featuredStreamImageCropY / actualHeight) * maxHeight
      : 63, // top of crop viewbox: (imageHeight / 2) - (cropHeight / 2)
  )
  let thumbUrl = selectedThumb ? getThumbUrl(selectedThumb) : ""

  return (
    <>
      <img
        className={style.hiddenImage}
        src={thumbUrl}
        onLoad={(event) => setIsLandscape(event.target.width >= event.target.height)}
      />
      <h5 className={style.heading}>Selected Thumbnail</h5>
      <div className={style.container}>
        <div className={style.thumbnailContainer} style={{ height: isLandscape ? 'auto' : maxHeight }}>
          <Thumbnail
            className={style.thumbnail}
            image={thumbUrl}
            onImgLoad={(width, height) => setIsLandscape(width > height)}
            fullWidth={isLandscape}
            active
          />
          {!isLandscape && (
            <>
              <div className={style.overlay}>
                <div className={style.dim} style={{ height: y }} />
                <div
                  style={{ height: cropHeight }}
                  className={style.crop}
                  onMouseDown={e => {
                    setDragging(e.clientY)
                  }}
                  onMouseMove={e => {
                    if (dragging !== null) {
                      setY(
                        dragging - e.clientY < 0
                          ? Math.min(
                              y - (dragging - e.clientY),
                              maxHeight - cropHeight,
                            )
                          : Math.max(y - (dragging - e.clientY), 0),
                      )
                      setDragging(e.clientY)
                    }
                  }}
                  onMouseUp={e => {
                    setDragging(null)
                  }}
                  onMouseLeave={() => {
                    setDragging(null)
                  }}
                />
                <div className={classnames(style.dim, style.flex)} />
              </div>
              {featuredStreamImageCropY !== y && (
                <div className={style.bottom}>
                  <button
                    className={style.submit}
                    onClick={async () => {
                      const computedY = Math.floor((y / maxHeight) * actualHeight)

                      const { data: { path }} = await Moderation.cropThumbnail({
                        source: thumbUrl,
                        y: computedY
                      });

                      IncidentService.updateIncidentThumbnail(incidentId, {
                        horizontalThumbnail: path,
                        y: computedY,
                      });
                    }}
                  >
                    <i className='fas fa-check'></i>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

IncidentThumbnail.propTypes = {
  selectedThumb: string,
  incidentId: string,
  featuredStreamImageCropY: number,
}

export default IncidentThumbnail
