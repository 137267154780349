import React from "react"
import { geocodeValue } from "./AddressOrAreaInput"
import useCitywideAreas from "./useCitywideAreas"
import { Select } from "@guardian/UI/Legacy/Select"

interface Props {
  disabled?: boolean,
  onChangeServiceArea: (value: any) => void,
  citywideGeoCode: string,
  onSelect: ({ value }: { value: string }) => void
}

const ServiceAreaInput: React.FunctionComponent<Props> = ({
  disabled,
  onChangeServiceArea,
  citywideGeoCode,
  onSelect
}) => {
  const citywideAreas = useCitywideAreas()

  return (
    <Select
      options={Object.entries(citywideAreas).map((areaPair) => ({ label: areaPair[1], value: areaPair[0]}))}
      value={citywideGeoCode}
      disabled={disabled}
      onChange={async ({ target: { value } }) => {
        if (!value) return
        const geocoded = await geocodeValue(citywideAreas[value as string])
        onSelect({ value: geocoded })
        onChangeServiceArea({
          name: citywideAreas[value as string],
          value: value,
        })
      }}
      label="Service Area"
    />
  )
}

export default React.memo(ServiceAreaInput)
