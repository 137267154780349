import React, { useCallback, useEffect, useRef, useState } from "react";

import { AgentChat } from "@guardian/Lib/QueryClient/hooks";
import { Flash } from "@guardian/UI/Alert";
import { Tooltip } from "@guardian/UI/Tooltip";
import { copyToClipboard } from "@guardian/Utils/Window";

import InfoGroup from "./views/InfoGroup";
import InfoGroupBody from "./views/InfoGroupBody";
import InfoGroupHeader from "./views/InfoGroupHeader";
import Section from "./views/Section";
import SectionBody from "./views/SectionBody";
import SectionCol from "./views/SectionCol";
import SectionColGroup from "./views/SectionColGroup";
import SectionHeader from "./views/SectionHeader";
import StyledSessionTab from "./views/SessionTab";

const SessionTab = props => {
  const {
    session: {
      id: sessionId,
    }
  } = props;

  const formatSessionState = useCallback((record) => {
    const sessionState = {};

    if (record) {
      const {
        batteryLevel,
        location
      } = record;

      sessionState.battery =
        batteryLevel && `${ Math.round(batteryLevel * 100) }%`;
      sessionState.coordinates =
        location && `${location.latitude}, ${location.longitude}`;
    }

    return sessionState;
  }, []);

  const [sessionState, setSessionState] = useState(formatSessionState);

  const [locationCopyTrigger, setLocationCopyTrigger] = useState(null);
  const [sessionIdCopyTrigger, setSessionIdCopyTrigger] = useState(null);

  const sessionIdRef = useRef(sessionId);
  const sessionStateRef = useRef(sessionState);

  const {
    data: records,
    error: recordsFetchError,
    isLoading: recordsLoading,
  } = AgentChat.useGetAssignedChatSessionUserRecords(
    sessionId,
    {
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    if (recordsFetchError == null) {
      return;
    }

    LoggingService.logError(
      "Error fetching records user records.",
      {
        domain: "AgentChat",
        method: "SessionTab",
        flash: true,
        trackedData: {
          sessionId: sessionIdRef.current,
          error: recordsFetchError
        }
      }
    );
  }, [recordsFetchError]);

  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  useEffect(() => {
    sessionStateRef.current = sessionState;
  }, [sessionState]);

  useEffect(() => {
    if (records == null) { return; }
    setSessionState(formatSessionState(records[0]));
  }, [records]);

  useEffect(() => {
    if (locationCopyTrigger == null) { return; }

    const sessionState = sessionStateRef.current;

    const handleCopied = () => {
      Flash.generic(
        `The user's coordinates were copied to your clipboard.`,
        {
          title: "Copied"
        }
      );
    };

    const handleFailedToCopy = () => {
      LoggingService.logError(
        "Unable to copy user's coordinates.",
        {
          domain: "AgentChat",
          method: "SessionTab",
          flash: true,
          trackedData: {
            sessionId: sessionIdRef.current
          }
        }
      );
    };

    if (sessionState == null || sessionState.coordinates == null) {
      handleFailedToCopy();
      return;
    }

    const copy = () => {
      if (copyToClipboard(sessionState.coordinates)) {
        handleCopied();
      } else {
        handleFailedToCopy();
      }
    };

    copy();
  }, [locationCopyTrigger]);


  useEffect(() => {
    if (sessionIdCopyTrigger == null) { return; }

    const sessionId = sessionIdRef.current;

    const handleCopied = () => {
      Flash.generic(
        `The session ID was copied to your clipboard.`,
        {
          title: "Copied"
        }
      );
    };

    const handleFailedToCopy = () => {
      LoggingService.logError(
        "Unable to copy session ID.",
        {
          domain: "AgentChat",
          method: "SessionTab",
          flash: true,
          trackedData: {
            sessionId: sessionId
          }
        }
      );
    };

    if (sessionId == null) {
      handleFailedToCopy();
      return;
    }

    const copy = () => {
      if (copyToClipboard(sessionId)) {
        handleCopied();
      } else {
        handleFailedToCopy();
      }
    };

    copy();
  }, [sessionIdCopyTrigger]);

  const handleCopyLocation = e => {
    e.preventDefault();
    setLocationCopyTrigger(+new Date());
  };

  const handleCopySessionId = e => {
    e.preventDefault();
    setSessionIdCopyTrigger(+new Date());
  };

  // return (
  //   <StyledSessionTab>
  //     <Section>
  //       <SectionBody>
  //         <SectionColGroup>
  //           <SectionCol>
  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Version
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 1115
  //               </InfoGroupBody>
  //             </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Activation Source
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 tab button
  //               </InfoGroupBody>
  //             </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Permission
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 --
  //               </InfoGroupBody>
  //             </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Subscription Expires
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 11/05/2023
  //               </InfoGroupBody>
  //             </InfoGroup>
  //           </SectionCol>

  //           <SectionCol>
  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Platform
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 iOS
  //               </InfoGroupBody>
  //             </InfoGroup>

  //            <InfoGroup>
  //              <InfoGroupHeader>
  //                Has Location
  //              </InfoGroupHeader>
  //              {
  //                recordsLoading ? (
  //                  <InfoGroupBody>
  //                    --
  //                  </InfoGroupBody>
  //                ) : (
  //                  (sessionState.coordinates == null) ? (
  //                   <InfoGroupBody>
  //                      No
  //                    </InfoGroupBody>
  //                  ) : (
  //                    <Tooltip content="Copy user's coordinates">
  //                      <InfoGroupBody
  //                        as="a"
  //                        href="#"
  //                        onClick={handleCopyLocation}
  //                      >
  //                        Yes
  //                      </InfoGroupBody>
  //                    </Tooltip>
  //                  )
  //                )
  //              }
  //            </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Session Id
  //               </InfoGroupHeader>
  //               <Tooltip content="Copy session ID">
  //                 <InfoGroupBody
  //                   as="a"
  //                   href="#"
  //                   onClick={handleCopySessionId}
  //                   truncate
  //                 >
  //                   { sessionId }
  //                 </InfoGroupBody>
  //               </Tooltip>
  //             </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Subscription Renews
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 11/06/2023
  //               </InfoGroupBody>
  //             </InfoGroup>
  //           </SectionCol>
  //         </SectionColGroup>
  //       </SectionBody>
  //     </Section>
  //     <Section>
  //       <SectionHeader>
  //         Device Information
  //       </SectionHeader>
  //       <SectionBody>
  //         <SectionColGroup>
  //           <SectionCol>
  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Hardware
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 24%
  //               </InfoGroupBody>
  //             </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Heart Rate
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 80BPM
  //               </InfoGroupBody>
  //             </InfoGroup>
  //           </SectionCol>

  //           <SectionCol>
  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Battery
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 94%
  //               </InfoGroupBody>
  //             </InfoGroup>

  //             <InfoGroup>
  //               <InfoGroupHeader>
  //                 Network
  //               </InfoGroupHeader>
  //               <InfoGroupBody>
  //                 Stable
  //               </InfoGroupBody>
  //             </InfoGroup>
  //           </SectionCol>
  //         </SectionColGroup>
  //       </SectionBody>
  //     </Section>
  //   </StyledSessionTab>
  // );

  return (
    <StyledSessionTab>
      <Section>
        <SectionBody>
          <SectionColGroup>
            <SectionCol>
              <InfoGroup>
                <InfoGroupHeader>
                  Session Id
                </InfoGroupHeader>
                {
                  recordsLoading ? (
                    <InfoGroupBody>
                      --
                    </InfoGroupBody>
                  ) : (
                    <Tooltip content="Copy session ID">
                      <InfoGroupBody
                        as="a"
                        href="#"
                        onClick={handleCopySessionId}
                        truncate
                      >
                        { sessionId }
                      </InfoGroupBody>
                    </Tooltip>
                  )
                }
              </InfoGroup>
            </SectionCol>

            <SectionCol>
              <InfoGroup>
                <InfoGroupHeader>
                  Has Location
                </InfoGroupHeader>
                {
                  recordsLoading ? (
                    <InfoGroupBody>
                      --
                    </InfoGroupBody>
                  ) : (
                    (sessionState.coordinates == null) ? (
                     <InfoGroupBody>
                        No
                      </InfoGroupBody>
                    ) : (
                      <Tooltip content="Copy user's coordinates">
                        <InfoGroupBody
                          as="a"
                          href="#"
                          onClick={handleCopyLocation}
                        >
                          Yes
                        </InfoGroupBody>
                      </Tooltip>
                    )
                  )
                }
              </InfoGroup>
            </SectionCol>
          </SectionColGroup>
        </SectionBody>
      </Section>
      <Section>
        <SectionHeader>
          Device Information
        </SectionHeader>
        <SectionBody>
          <SectionColGroup>
            <SectionCol>
              <InfoGroup>
                <InfoGroupHeader>
                  Battery
                </InfoGroupHeader>
                <InfoGroupBody>
                  { recordsLoading ? "--" : sessionState.battery }
                </InfoGroupBody>
              </InfoGroup>
            </SectionCol>
          </SectionColGroup>
        </SectionBody>
      </Section>
    </StyledSessionTab>
  );
};

export default SessionTab;
