import React from "react";

import { Row } from "@guardian/UI/Grid"
import { Spinner } from "@guardian/UI/Loader";

import StyledLoading from "./views/Loading";

const Loading = () => {
  return (
    <Row style={{ height: "100%" }}>
      <StyledLoading>
        <Spinner delayMs={1000}/>
      </StyledLoading>
    </Row>
  );
};

export default Loading;
