import React, { useEffect, useMemo } from "react";

import { AgentChat } from "@guardian/Lib/QueryClient/hooks";
import { LoggingService } from "@guardian/Services/Logging";
import { Flash } from "@guardian/UI/Alert";
import { ButtonGroup, ButtonIcon, IconButton } from "@guardian/UI/Button";
import {
  AbstractItem,
  MenuRow,
  MenuRowChild
} from "@guardian/UI/Dropdown";
import { Icon } from "@guardian/UI/Icon";
import { Tooltip } from "@guardian/UI/Tooltip";
import { isBrowser } from "@guardian/Utils/Window";

import useMenuContext from "../../../../hooks/useMenuContext";
import {
  MENU_ITEM_KEY,
  MENU_ITEM_CALL_KEY,
  MENU_ITEM_COPY_KEY
} from "../../constants";

const GeoNumbersMenuItems = props => {
  const {
    session: {
      id: sessionId,
    }
  } = useMenuContext();

  const {
    data: {
      geocodedNumbers
    },
    error,
    isLoading
  } = AgentChat.useGetAssignedChatSessionUserInfo(
    sessionId,
    {
      refetchInterval: 7500
    }
  );

  useEffect(() => {
    if (error == null) {
      return;
    }

    LoggingService.logError(
      "Something went wrong fetching geo numbers.",
      {
        domain: "GeoNumbersMenuItems",
        flash: true,
        trackedData: {
          error: error
        }
      }
    );
  }, [error]);

  const numbers = useMemo(() => {
    // Collator is just a minor performance improvement for sorting. May as well
    // just be a localeCompare. In the moment decision :).
    const collator =
      new Intl.Collator("en", { numeric: true, sensitivity: "base" });

    const groupedNumbers = geocodedNumbers
      .sort((a, b) => {
        return collator.compare(a.name, b.name);
      })
      .reduce((memo, details) => {
        // menuItemKey is used by GeoNumbersHandler to detect when a geo number
        // was clicked from the menu.
        const formattedDetails = {
          label: details.name,
          value: details.number,
          menuItemKey: MENU_ITEM_KEY
        };

        // Group numbers initially by nena vs other number types.
        details.type === "nena" ?
          memo.nena.push(formattedDetails) :
          memo.other.push(formattedDetails);

        return memo;
      }, { nena: [], other: [] });

      // Set numbers back into single array, with nena numbers first.
      return [...groupedNumbers.nena, ...groupedNumbers.other];
  }, [geocodedNumbers]);

  const browserSupportsCopy = useMemo(() => {
    return isBrowser() && document.queryCommandSupported("copy");
  }, []);

  if (isLoading) {
    // TODO:
    // Improve once we have a standardized loading component.
    return (
      <MenuRow>
        ...
      </MenuRow>
    );
  }

  if (numbers.length === 0 || error != null) {
    return (
      <MenuRow>
        No Available Geo Numbers
      </MenuRow>
    );
  }

  return (
    numbers.map((number, i) => (
      <MenuRow key={`number-actions-group-${i}`} contentsSelectable>
        { number.label }
        <MenuRowChild.End>
          <ButtonGroup>
            {
              browserSupportsCopy && (
                <Tooltip content="Copy number">
                  <AbstractItem
                    value={{ actionKey: MENU_ITEM_COPY_KEY, ...number }}
                  >
                    <IconButton outline={false} size="small">
                      <Icon.ContentCopy/>
                    </IconButton>
                  </AbstractItem>
                </Tooltip>
              )
            }
            <Tooltip content="Call number">
              <AbstractItem
                value={{ actionKey: MENU_ITEM_CALL_KEY, ...number }}
              >
                <IconButton outline={false} size="small">
                  <Icon.PhoneForwardedTwoTone/>
                </IconButton>
              </AbstractItem>
            </Tooltip>
          </ButtonGroup>
        </MenuRowChild.End>
      </MenuRow>
    ))
  );
};

export default GeoNumbersMenuItems;
