import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const BottomRow = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__bottom-row"
      )
    };
  }
)`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  height: 16px;
  justify-content: flex-start;
  min-width: 0;
`;

BottomRow.propTypes = {};

BottomRow.defaultProps = {};

export default BottomRow;
