import styled from "styled-components"
import React, { FC } from "react"
import { Message } from "@guardian/Components/ModSOS/components/Chat/Chat/Chat.helper"
import dayjs from "dayjs"

const DividerContainer = styled.div`
  position: relative;
  padding: 10px 0;
`

const DividerText = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #0c1116;
  padding: 0 10px 0 0;
`

interface IProps {
  message: Message
}

export const SessionDivider: FC<IProps> = ({ message }) => (
  <DividerContainer>
    <DividerText>{dayjs(message.timestamp).format("MM/DD h:mm A")}</DividerText>
    <hr />
  </DividerContainer>
)
