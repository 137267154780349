import classNames from "classnames";
import styled from "styled-components";

const SystemMessageTimestamp = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__system-message-timestamp"
      )
    }
  }
)`
  align-items: center;
  background: var(--colors-transparent);
  color: var(--colors-grey-060);
  display: flex;
  flex-direction: row;
  font-size: var(--font-sizes-small);
  justify-content: center;
  line-height: var(--line-heights-small);
  width: 100%;
`;

export default SystemMessageTimestamp;
