import React, { useContext } from "react";

import { AppContext } from "@guardian/Core";
import { XSmallText } from "@guardian/UI/Typography";

import ToggleSwitch from "./components/ToggleSwitch";
import StyledStatusConfig from "./views/StatusConfig";
import StatusItem from "./views/StatusItem";
import ToggleSwitchWrapper from "./views/ToggleSwitchWrapper";

const StatusConfig = () => {
  const {
    app: { allowances },
    agentChat: { available, toggleAvailable },
    moderator: { active, toggleActive }
  } = useContext(AppContext);

  const showActiveToggle = allowances.moderator;
  const showAvailableToggle = allowances.agentChat;

  if (!showActiveToggle && !showAvailableToggle) {
    return null;
  }

  return (
    <StyledStatusConfig>
      {
        showActiveToggle && (
          <StatusItem>
            <ToggleSwitchWrapper>
              <ToggleSwitch onChange={toggleActive} toggled={active}/>
            </ToggleSwitchWrapper>
            <XSmallText>
              Active
            </XSmallText>
          </StatusItem>
        )
      }
      {
        showAvailableToggle && (
          <StatusItem>
            <ToggleSwitchWrapper>
              <ToggleSwitch onChange={toggleAvailable} toggled={available}/>
            </ToggleSwitchWrapper>
            <XSmallText>
              Available
            </XSmallText>
          </StatusItem>
        )
      }
    </StyledStatusConfig>
  );
};

export default StatusConfig;
