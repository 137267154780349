import { OptimusAPI } from "@guardian/API/Optimus";

interface IGLGetUnresolvedIGLParams {
  psn: string | undefined
};

const IGL = {
  getRejectionReasons: (options: any = {}) => {
    return OptimusAPI.get(`/igl/rejection_reasons`, options);
  },

  getUnresolvedIGL: (
    params: IGLGetUnresolvedIGLParams,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/igl/unresolved`,
      {
        ...options,
        params: {
          ...options.params,
          ...params
        }
      }
    );
  }
};

export default IGL;
