export const coverageToHealthLevel = (coverage?: number) => {
  if (!coverage || coverage < 0.25) {
    return 0
  }

  if (coverage < 0.5) {
    return 1
  }

  if (coverage < 0.75) {
    return 2
  }

  return 3
}
