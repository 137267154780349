import React, { useEffect, useContext, useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";

import { AppContext } from "@guardian/Core";
import { Col, Container, Row } from "@guardian/UI/Grid";
import history from "@guardian/Utils/history";

import DirectMessaging from "./components/DirectMessaging";
import Error from "./components/Error";
import Loading from "./components/Loading";
import DMTabContext from "./contexts/DMTabContext";

// NOTE: This flag is used to determine if we should redirect the user back to
// the main DM tab when the session they are viewing has ended.
const REDIRECT_ON_SESSION_END = false;

const ModSOSDirectMessaging = props => {
  const {
    agentChat: {
      assignedAgentChatSessions,
      isError,
      isLoading
    }
  } = useContext(AppContext);

  const { sessionId } = useParams();

  const activeAgentChatSession = useMemo(() => {
    if (!sessionId) {
      return null;
    }

    return assignedAgentChatSessions.find(session => {
      return session.id === sessionId;
    });
  }, [assignedAgentChatSessions, sessionId]);

  useEffect(() => {
    if (activeAgentChatSession == null) {
      return;
    }
    // Note: You may notice there is also a check for `shouldRedirect` within
    // this file, which includes a use of `react-router-dom`'s `<Navigate/>`
    // component and wonder why this check is here as well. Essentially, these
    // are two similar but separately treated checks for two cases. In the case
    // of the use of `shouldRedirect`, we are checking if the Session ID in the
    // URL is one known to our assigned sessions payload, and _immediately_
    // redirecting if it is not, without any rendering of any components within
    // this tab. There is another case where we want to redirect though, and
    // that is when an assigned session suddenly becomes "ended" while it is in
    // view. In that case, we dont just want to immediately remove it from the
    // DOM by the use of `<Navigate/>`, opting instead to programatically
    // trigger a navigation so that the already rendered views may transition
    // off screen in the same way as we may handle them (e.g.; animated) when
    // swapping between sessions using the side panel.
    if (REDIRECT_ON_SESSION_END && activeAgentChatSession.endedAt != null) {
      history.push("/sos_dms");
    }
  }, [activeAgentChatSession]);

  useEffect(() => {
    if (activeAgentChatSession == null || !assignedAgentChatSessions.length) {
      return;
    }
    // If there's a new active session for the same user, redirect to that
    // session.
    const newSession = assignedAgentChatSessions.find(session => (
      session.userId === activeAgentChatSession.userId
      && !session.endedAt
      && session.id !== activeAgentChatSession.id
    ));
    if (newSession && activeAgentChatSession.endedAt != null) {
      history.push(`/sos_dms/${newSession.id}/chat`);
    }
  }, [activeAgentChatSession, assignedAgentChatSessions]);

  const shouldRedirect = !!(sessionId && activeAgentChatSession == null);

  const system = {
    activeAgentChatSession: activeAgentChatSession,
    activeAgentChatSessionId: activeAgentChatSession?.id,
    assignedAgentChatSessions: assignedAgentChatSessions
  };

  // TODO: Improve
  if (isLoading) {
    return (
      <Container fluid style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col>
            <Loading/>
          </Col>
        </Row>
      </Container>
    );
  }

  // TODO: Improve
  if (isError) {
    return (
      <Container fluid style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col>
            <Error/>
          </Col>
        </Row>
      </Container>
    );
  }

  if (shouldRedirect) {
    return (
      <Redirect push to="/sos_dms"/>
    );
  }

  return (
    <DMTabContext.Provider value={system}>
      <Container fluid style={{ height: "100%" }}>
        <DirectMessaging/>
      </Container>
    </DMTabContext.Provider>
  );
};

export { default as DMTabContext } from "./contexts/DMTabContext";
export default ModSOSDirectMessaging;
