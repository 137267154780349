import classNames from "classnames";
import styled from "styled-components";

const ActivityListItem = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__activity__list-item"
    )
  };
})`
  display: flex;
  flex-direction: row;
  gap: var(--space-x-small);
  padding: var(--space-small) var(--space-medium);
`;

ActivityListItem.defaultProps = {};

export default ActivityListItem;
