import Papa from "papaparse"

type Params<T> = Parameters<typeof Papa.parse<T>>
type InputParam<T> = Params<T>[0]
type ConfigParam<T> = Params<T>[1]

export function parseCSV<T>(
  input: InputParam<T>,
  configOverrides: Omit<ConfigParam<T>, "complete">,
): Promise<T[]> {
  return new Promise((resolve, reject) => {
    const config: ConfigParam<T> = {
      skipEmptyLines: true,
      ...configOverrides,
      complete: (results: Papa.ParseResult<T>) => {
        if (results.errors.length > 0) {
          reject(results.errors[0])
        } else {
          resolve(results.data)
        }
      },
    } as ConfigParam<T>
    Papa.parse<T>(input, config)
  })
}
