import Container from "@guardian/Components/Common/Container"
import React from "react"
import styles from "@guardian/Components/ModSOS/components/Map/IncidentModal/IncidentModal.module.css"
import classnames from "classnames"
import { CloseIcon } from "@guardian/Components/ModSOS/components/Map/CloseIcon/CloseIcon"
import { IProps } from "@guardian/Components/ModSOS/components/Map/IncidentModal/types"

const IncidentModalRaw = ({ incidentId, severity, title, onClose }: IProps) => {
  return (
    <a
      href={`/incidents/${incidentId}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Container left={2} right={2} top={2} fullWidth>
        <Container
          className={styles.container}
          top={3}
          bottom={3}
          left={3}
          right={3}
          leftJustify
          fullWidth
        >
          <Container className={classnames(styles.marker, styles[severity])} />
          <Container className={styles.title} left={3} leftJustify fill>
            {title}
          </Container>
          <button
            className={styles.button}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onClose()
            }}
          >
            <CloseIcon />
          </button>
        </Container>
      </Container>
    </a>
  )
}

export const IncidentModal = React.memo(IncidentModalRaw)
