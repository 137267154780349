import React, { useEffect, useState } from "react"
import { array } from "prop-types"

import { eventHub } from "@guardian/State"

import styles from "./styles.module.css"
import Stat from "./Stat"
import CurrentTime from "./CurrentTime"
import IncidentsMetrics from "./IncidentsMetrics"

const INCIDENT_STATUS_REFRESH_INTERVAL_MS = 30 * 1000 // 30s

interface StatType {
  key: string | number
  name: string | number
  value: string | number
}

interface Stats {
  stats: StatType[]
  incidentsByHour: { [key: number]: number }
}

const UserStats = ({ stats, incidentsByHour }: Stats) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (!isOpen) {
      return undefined
    }

    const updateUserStats = () => {
      eventHub.emit("syncUserStats")
    }

    const interval = setInterval(
      updateUserStats,
      INCIDENT_STATUS_REFRESH_INTERVAL_MS,
    )
    return () => {
      clearInterval(interval)
    }
  }, [isOpen])

  return (
    <div className={styles.container}>
      <button
        className={styles.toggler}
        title={`${isOpen ? "Hide" : "Show"} User Stats`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className={`fas fa-chevron-${isOpen ? "right" : "left"}`} />
      </button>
      <CurrentTime />
      {isOpen && (
        <>
          {stats.map((stat: StatType) => (
            <Stat centerName={false} {...stat} />
          ))}
          <IncidentsMetrics incidentsByHour={incidentsByHour} />
        </>
      )}
    </div>
  )
}

UserStats.propTypes = {
  stats: array,
}

export default UserStats
