import { useCallback, useEffect, useRef, useState } from "react";

import { AgentChatService } from "@guardian/Services/AgentChat";
import { LoggingService } from "@guardian/Services/Logging";

import { retryPromise } from "@guardian/Utils/util";

interface QueryOptions {
  enabled: string
};

const LOCAL_STORAGE_KEY = "AgentChat__availabileStatus";

const getAvailableFromLocalStorageRaw = () => {
  if (typeof window === "undefined") {
    return undefined;
  }

  return localStorage?.getItem(LOCAL_STORAGE_KEY);
}

const getAvailableFromLocalStorage = () => {
  return getAvailableFromLocalStorageRaw() === "true";
};

const useAvailableStatus = (options: QueryOptions) => {
  const {
    enabled
  } = options;

  const [available, setAvailable] = useState(getAvailableFromLocalStorage);
  const [internalEnabled, setInternalEnabled] = useState(true);
  const [trigger, setTrigger] = useState(null);

  const availableRef = useRef(available);

  // Fetch initial available state from API and poll.
  // Listen to changes to localstorage value to update our tracked available
  // status. This will sync our toggle state across tabs.
  useEffect(() => {
    // NOTE: We want to treat our hook as enabled by default. Hence, the lack of
    // an enabled value in our hooks options should be considered a go-ahead to
    // treat it as enabled. Hence, we only treat it as not enabled if the value
    // of enabled is falsy _and_ defined.
    if (typeof enabled !== "undefined" && !enabled) { return; }

    // Sometimes we may want to pause the polling and other listeners here when
    // performing updates made by the user. Hence, "internal enabled" state.
    if (internalEnabled === false) { return; }

    const controller = new AbortController();
    const signal = controller.signal;
    let timeout: any;

    const checkAvailableStatus = () => {
      const newStatus = getAvailableFromLocalStorageRaw();
      // If the value is unknown (null/undefined), rehydrate from the API.
      if (newStatus == null) { return; }
      setAvailable(newStatus === "true");
    };

    const listenForLocalStorageUpdates = () => {
      window.addEventListener("storage", checkAvailableStatus);
    };

    const loop = () => {
      // Unfortunately this is the only endpoint we have to get the
      // current availablity status for the agent, so we have to set this
      // value with this endpoint. This endpoint probably hits the backend
      // a little too heavy for this.
      const getAssignedChatSessions = () => AgentChatService.getAssignedChatSessions({ signal });

      retryPromise(getAssignedChatSessions, 5, 1000, () => !signal.aborted)
        .then(({ available }) => {
          setAvailable(available);
          timeout = setTimeout(loop, 15000);
        })
        .catch(error => {
          if (signal.aborted) {
            return;
          }

          LoggingService.logError(
            "Something went wrong polling Agent Chat availability.",
            {
              domain: "AppSystem",
              method: "useAvailableStatus",
              flash: true,
              trackedData: {
                error: error
              }
            }
          );
        });
    };

    listenForLocalStorageUpdates();
    loop();

    return () => {
      controller.abort();
      clearTimeout(timeout);
      window.removeEventListener("storage", checkAvailableStatus);
    };
  }, [enabled, internalEnabled]);

  // Store available state to local storage and track reference.
  useEffect(() => {
    const storedValue = available ? "true" : "false"
    localStorage.setItem(LOCAL_STORAGE_KEY, storedValue);

    availableRef.current = available;
  }, [available]);

  // Handle user toggle events to update availability.
  useEffect(() => {
    if (trigger == null) { return; }

    const lastAvailable = availableRef.current;
    const nextAvailable = !availableRef.current;

    setInternalEnabled(false);
    setAvailable(nextAvailable);

    AgentChatService.updateAvailability({ available: nextAvailable })
      .catch(error => {
        LoggingService.logError(
          "Something went wrong updating Agent Chat availability.",
          {
            domain: "AppSystem",
            method: "useAvailableStatus",
            flash: true,
            error,
            trackedData: {
              error
            }
          }
        );

        // Revert.
        setAvailable(lastAvailable);
      })
      .finally(() => {
        setInternalEnabled(true);
      });
  }, [trigger]);

  const toggleAvailable = useCallback(() => {
    // @ts-ignore
    setTrigger(+new Date());
  }, []);

  return {
    available,
    toggleAvailable
  };
};

export default useAvailableStatus;
