import React, { useEffect, useState } from "react"
import { string, bool, func, object } from "prop-types"

import { retryPromise } from "@guardian/Utils/util"
import { LoggingService } from "@guardian/Services/Logging"
import { ContentService } from "@guardian/Services/Content"

import VideoPlayer from "@guardian/Components/VideoPlayer/VideoPlayer"
import VideoMenu from "@guardian/Components/VideoPlayer/VideoMenu"

import ContentControls from "./ContentControls"
import style from "./InfoPane.module.css"

const VideoContent = ({
  id,
  onReject,
  onConfirm,
  confirmed,
  onBlock,
  onVerified,
  block,
  verified,
  prompt911,
  onPrompt911,
  content,
}) => {
  const [shouldAck, setShouldAck] = useState(false)
  const [isLandscape, setIsLandscape] = useState(null)

  useEffect(() => {
    // Reset shouldAck status on content change
    setShouldAck(false)
  }, [id])

  useEffect(() => {
    if (!shouldAck) {
      return undefined
    }

    const controller = new AbortController()
    const signal = controller.signal
    const ackContent = () =>
      ContentService.ackContent("video_stream", id, { signal })

    retryPromise(ackContent, 5, 1000, () => !controller.signal.aborted).catch(
      error => {
        if (signal.aborted) {
          return
        }
        LoggingService.logError("Something went wrong acking this content.", {
          domain: "SignalModeration",
          method: "ackContent",
          error,
          flash: true,
          trackedData: { error, videoId: id },
        })
      },
    )

    return () => {
      controller.abort()
    }
  }, [shouldAck, id])

  const {
    broadcastDone,
    hlsDone,
    hlsVodUrl,
    hlsLiveUrl,
    hlsLiveImageUrl,
    userId,
    username,
    durationSeconds,
  } = content.videoStream
  const isDone = broadcastDone || hlsDone
  const endedAt =
    content.videoStream.hlsDoneTiming &&
    content.videoStream.hlsDoneTiming[content.videoStream.hlsDoneReason]
  const shouldAckOnPlay = !endedAt || durationSeconds < 5

  return (
    <>
      <img
        className={style.videoImage}
        src={hlsLiveImageUrl}
        onLoad={event =>
          setIsLandscape(event.target.width >= event.target.height)
        }
      />
      <VideoPlayer
        url={isDone ? hlsVodUrl : hlsLiveUrl}
        isLandscape={isLandscape}
        onPlay={shouldAckOnPlay ? () => setShouldAck(true) : undefined}
        onProgress={
          shouldAckOnPlay
            ? undefined
            : ({ playedSeconds }) => {
                if (playedSeconds > 5) {
                  setShouldAck(true)
                }
              }
        }
        autoPlay
        live={!isDone}
        topActions={
          <ContentControls
            onReject={onReject}
            onConfirm={onConfirm}
            confirmed={confirmed}
            onBlock={onBlock}
            onVerified={onVerified}
            block={block}
            verified={verified}
            id={id}
            prompt911={prompt911}
            onPrompt911={onPrompt911}
            type='video_stream'
          />
        }
        menu={
          <VideoMenu
            id={id}
            username={username}
            userId={userId}
            onReject={onReject}
            location='Signal Tab'
          />
        }
        durationSeconds={durationSeconds}
        originalDuration={durationSeconds}
      />
    </>
  )
}

VideoContent.propTypes = {
  id: string,
  onReject: func,
  onConfirm: func,
  confirmed: bool,
  onBlock: func,
  onVerified: func,
  block: bool,
  verified: bool,
  prompt911: bool,
  onPrompt911: func,
  content: object,
}

export default VideoContent
