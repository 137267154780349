import _get from "lodash/get"

import { IncidentService } from "@guardian/Services/Incident"
import { Select } from "@guardian/UI/Legacy/Select"

export interface Props {
  disabled?: boolean
  readOnly?: boolean
  incident?: any
  autoSet?: boolean
  setSelectStatus?: (value: string) => void
  value?: string
}

const magicMomentOptions = ["Uplifting", "GoodVibes", "Magic"]
const closureOptions = [
  { label: "Active", value: "Active" },
  { value: "Closed", label: "Closed" },
  { value: "Uplifting", label: "💚 Closed" },
  { value: "Magic", label: "💚 Magic" },
  { value: "GoodVibes", label: "💚 Active" },
]

const IncidentStatusSelect = function(props: Props) {
  // @ts-ignore
  const onChange = ({ target: { value } }) => {
    if (props.autoSet) {
      IncidentStatusSelect.setClosureStatus(value, props.incident)
    } else if (props.setSelectStatus) {
      props.setSelectStatus(value)
    }
  }

  return (
    <Select
      disabled={props.disabled || props.readOnly}
      onChange={onChange}
      options={closureOptions}
      value={
        props.value === undefined
          ? IncidentStatusSelect.getClosureStatus(props.incident)
          : props.value
      }
      label='Status'
    />
  )
}

IncidentStatusSelect.getClosureStatus = (incident?: any) => {
  let status = "Active"
  if (
    incident !== undefined &&
    _get(incident, "magicMoment.magicMomentsTag")
  ) {
    switch (_get(incident, "magicMoment.magicMomentsTag")) {
      case "good_news":
        status = "Uplifting"
        break
      case "magic_moments":
        status = "Magic"
        break
      case "good_vibes":
        status = "GoodVibes"
        break
      default:
        break
    }
  } else if (incident !== undefined && incident.closed) {
    status = "Closed"
  }
  return status
}

IncidentStatusSelect.setClosureStatus = async (
  closureStatus: string,
  incident?: any,
) => {
  if (incident == null) {
    return
  }
  const prevClosureStatus = IncidentStatusSelect.getClosureStatus(incident)
  switch (closureStatus) {
    case "Active":
      if (prevClosureStatus === "Closed") {
        await IncidentService.updateIncidentClosedStatus(
          incident.id,
          { closed: false }
        )
      } else if (magicMomentOptions.includes(prevClosureStatus)) {
        await IncidentService.updateIncidentMagicIncident(
          incident.id,
          { magicMomentsTag: "" }
        )
      }
      break
    case "Closed":
      await IncidentService.updateIncidentClosedStatus(
        incident.id,
        { closed: true }
      )
      if (magicMomentOptions.includes(prevClosureStatus)) {
        await IncidentService.updateIncidentMagicIncident(
          incident.id,
          { magicMomentsTag: "" }
        )
      }
      break
    case "Uplifting":
      if (prevClosureStatus === "Active") {
        await IncidentService.updateIncidentClosedStatus(
          incident.id,
          { closed: true }
        )
      }
      await IncidentService.updateIncidentMagicIncident(
        incident.id,
        { magicMomentsTag: "good_news" }
      )
      break
    case "Magic":
      if (prevClosureStatus === "Active") {
        await IncidentService.updateIncidentClosedStatus(
          incident.id,
          { closed: true }
        )
      }
      await IncidentService.updateIncidentMagicIncident(
        incident.id,
        { magicMomentsTag: "magic_moments" }
      )
      break
    case "GoodVibes":
      await IncidentService.updateIncidentMagicIncident(
        incident.id,
        { magicMomentsTag: "good_vibes" }
      )
      break
    default:
      break
  }
}

export default IncidentStatusSelect
