import _ from "lodash"
import { sortClips } from "./clips"

const emptyTime = "0001-01-01T00:00:00Z"

export const getDefaultChannels = channels => {
  const defaults = {}
  return defaults
}

export const currentView = (state, clips) => {
  let {
    pinnedClips,
    multiplayerPinnedClips,
    multiplayerPinOnly,
    unplayedOnly,
    activeClip,
    activeChannels,
    mutes,
    sortingMethod,
  } = state.get().radio

  const { tab } = state.get().global

  const newClips = pinnedClips.reduce((acc, pc) => {
    const clip = acc.find(c => c.id === pc.id)
    if (!clip) {
      acc.push({ ...pc, pin: true })
    }
    return acc
  }, [])

  let filteredClips = multiplayerPinnedClips
  if (!["data_labeling", "audit_tool"].includes(tab)) {
    filteredClips = filteredClips.filter(
      mpc => activeChannels[mpc.channel] && !mutes[mpc.channel],
    )
  }
  filteredClips.forEach(mpc => {
    const clip = newClips.find(c => c.id === mpc.id)
    if (clip && clip.multiplayerPinTime === emptyTime) {
      clip.multiplayerPinTime = mpc.multiplayerPinTime
    } else if (!clip && mpc.clipListenStatus !== "unplayed") {
      const existingClip = clips.find(c => c.id === mpc.id) || mpc
      newClips.push({
        ...existingClip,
        clipListenStatus: mpc.clipListenStatus,
      })
    }
  })

  if (!multiplayerPinOnly) {
    clips.forEach(cl => {
      const clip = newClips.find(c => c.id === cl.id)
      const isActive = activeClip.id === cl.id
      const shouldShow = (unplayedOnly && !cl.played) || !unplayedOnly
      if (!clip && (isActive || shouldShow)) {
        newClips.push(cl)
      }
    })
  }

  // Don't sort audit_tool clips
  if (tab === "audit_tool") {
    return newClips
  }

  let timeSelector = undefined
  if (multiplayerPinOnly) {
    timeSelector = multiplayerPinTimeSelector
  }
  return sortClips(newClips, sortingMethod, timeSelector)
}

const multiplayerPinTimeSelector = c => {
  return c.multiplayerPinTime !== emptyTime ? c.multiplayerPinTime : c.time
}

export const regenClipView = state => {
  state
    .get()
    .radio.set("clipsView", currentView(state, state.get().radio.clips))
}
