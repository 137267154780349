import React, { useCallback, useEffect, useState } from "react"
import _, { get } from "lodash"
import styles from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.module.css"
import classnames from "classnames"
import { Chat } from "@guardian/Components/ModSOS/components/Chat/Chat/Chat"
import { ChatV2 } from "@guardian/Components/ModSOS/components/Chat/Chat/ChatV2"
import { SOSMap } from "@guardian/Components/ModSOS/components/Map/SOSMap/SOSMap"
import { SOSVideo } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo"
import { ActionBar } from "@guardian/Components/ModSOS/components/ActionBar/ActionBar/ActionBar"
import { SOSInfoPane } from "@guardian/Components/ModSOS/components/InfoPane/SOSInfoPane/SOSInfoPane"
import { SOSModularPane } from "@guardian/Components/ModSOS/components/ModularPane/SOSModularPane"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { SOSVideoContextProvider } from "@guardian/Components/ModSOS/store/video/VideoContext"
import { ICall } from "@guardian/Components/ModSOS/components/ModularPane/SOSDialOutPane/SOSDialOutPane.types"
import { IProps } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"

export const SOSDashboard: React.FunctionComponent<IProps> = ({
  videoDeviceId,
}) => {
  const [requested911, setRequested911] = useState(false)
  const [bounds, setBounds] = useState<any>(null)
  const [address, setAddress] = useState("")
  const { state } = useSOSDashboardContext()
  const [call, setCall] = useState<ICall>()

  useEffect(() => {
    setRequested911(false)
  }, [state.activeSessionId])

  const onRequested911 = useCallback(() => {
    setRequested911(true)
  }, [])

  useEffect(() => {
    setRequested911(
      Boolean(state.sessionResp?.data?.outgoingCall) ||
        state.ownerData?.data?.requested911 === "yes",
    )
  }, [
    state.sessionResp?.data?.outgoingCall,
    state.ownerData?.data?.requested911,
  ])

  const onMapBoundsChange = useCallback(
    (bounds: any) => {
      setBounds(bounds)
    },
    [setBounds],
  )

  const onAddressChange = useCallback(
    (address: string) => {
      setAddress(address)
    },
    [setAddress],
  )

  if (!state.activeSessionId) {
    return null
  }

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.column, styles.quarterColumn)}
        style={{ minWidth: 320 }}
      >
        {state.isV2Protect ? (
          <ChatV2
            activeSessionId={state.activeSessionId}
            entryPointType={get(state, "callSession.metadata.entryPointType")}
          />
        ) : (
          <Chat activeSessionId={state.activeSessionId} />
        )}
        <SOSMap
          homeLocation={get(
            state,
            "ownerData.data.safetyProfile.homeLocation",
            undefined,
          )}
          onBoundsChange={onMapBoundsChange}
          onAddressChange={onAddressChange}
          activeSessionId={state.activeSessionId}
          isV2Protect={state.isV2Protect}
          sessionResp={state.sessionResp}
          ownerData={state.ownerData?.data}
        />
      </div>
      <div
        className={classnames(
          styles.column,
          styles.halfColumn,
          styles.fullBackground,
          styles.videoPanel,
        )}
      >
        <SOSVideoContextProvider
          activeSessionId={state.activeSessionId}
          videoControls={_.get(state.sessionResp, "data.videoControl")}
          videoResponse={_.get(state.sessionResp, "data.video")}
          videoDeviceId={videoDeviceId}
        >
          <SOSVideo call={call} ownerData={state.ownerData?.data} />
        </SOSVideoContextProvider>
        <ActionBar
          onRequested911={onRequested911}
          activeSessionId={state.activeSessionId}
          requested911={requested911}
          emergencyContacts={get(state.ownerData, "data.emergencyContacts", [])}
          offPlatformEmergencyContacts={get(
            state.ownerData,
            "data.offPlatformEmergencyContacts",
            [],
          )}
          userLocation={get(
            state.sessionRecords,
            "data.records.0.location",
            undefined,
          )}
          is911InSession={state.is911InSession}
        />
      </div>
      <div
        className={classnames(
          styles.column,
          styles.quarterColumn,
          styles.rightPanel,
        )}
      >
        <SOSInfoPane
          activeSessionId={state.activeSessionId}
          ownerData={state.ownerData?.data}
          activationPoint={get(state.sessionResp, "data.entryPoint", undefined)}
          mode={get(state.sessionResp, "data.mode", undefined)}
          isFirstCall={get(state.callSession, "metadata.isFirstCall", false)}
        />
        <SOSModularPane
          activeSessionId={state.activeSessionId}
          bounds={bounds}
          address={address}
          call={call}
          setCall={setCall}
        />
      </div>
    </div>
  )
}
