import React from "react"
import { object } from "prop-types"

import Stat from "./Stat"

interface IProps {
  incidentsByHour: {[key: number]: number}
}

const IncidentsMetrics = ({ incidentsByHour }: IProps) => {
  const currVal = incidentsByHour[0] ?? 0
  const pastVal = incidentsByHour[1] ?? 0

  return (
    <Stat name='IPH' value={`(${pastVal}) ${currVal}`} centerName />
  )
}

IncidentsMetrics.propTypes = {
  incidentsByHour: object
}

export default IncidentsMetrics
