import React from "react";
import {
  BaseCSS as GridGlobalStyles,
  GridThemeProvider
} from "styled-bootstrap-grid";
import {
  ThemeProvider as StyledThemeProvider
} from "styled-components";

import {
  GlobalStyles,
  gridTheme,
  theme
} from "./Main";

const ThemeProvider = props => {
  const { children } = props;

  return (
    <React.Fragment>
      <StyledThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyles/>
          <GridGlobalStyles/>
          <GridThemeProvider gridTheme={gridTheme.grid}>
            { children }
          </GridThemeProvider>
        </React.Fragment>
      </StyledThemeProvider>
    </React.Fragment>
  );
};

export default ThemeProvider;
