import classnames from "classnames"
import { array, func, object, string } from "prop-types"
import React, { useCallback } from "react"

import styles from "./CityCodes.module.css"

const createServiceAreasPairs = serviceAreas => {
  let saList = []
  for (let i = 0; i < serviceAreas.length; i += 2) {
    let sa = serviceAreas[i]
    let sa2 = serviceAreas[i + 1]

    saList.push([sa, sa2])
  }

  return saList
}

const ServiceAreaTable = ({
  serviceAreas,
  onClick,
  selectedSA,
  serviceAreaCfg,
}) => (
  <div>
    {createServiceAreasPairs(serviceAreas).map(([sa, sa2]) => (
      <div key={sa} className={styles.SARow}>
        <div className={styles.SAColumn}>
          <div
            onClick={() => onClick(sa)}
            className={classnames({
              [styles.selected]: sa === selectedSA,
            })}
          >
            {serviceAreaCfg[sa] && serviceAreaCfg[sa].name}
          </div>
        </div>
        <div className={styles.SAColumn}>
          {!!sa2 && (
            <div
              onClick={() => onClick(sa2)}
              className={classnames({
                [styles.selected]: sa2 === selectedSA,
              })}
            >
              {serviceAreaCfg[sa2] && serviceAreaCfg[sa2].name}
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
)

ServiceAreaTable.propTypes = {
  serviceAreas: array,
  onClick: func,
  selectedSA: string,
  serviceAreaCfg: object,
}

export default ServiceAreaTable
