import { array, object } from "prop-types"
import React, { PureComponent } from "react"

import { eventHub } from "@guardian/State"

import { Radio } from "@guardian/API/Optimus"

import styles from "./CityCodes.module.css"
import CodeSection from "./CodeSection/CodeSection"
import ServiceAreaTable from "./ServiceAreaTable"

class CityCodes extends PureComponent {
  constructor(props) {
    super(props)
    this.timeout = 0
    this.state = {
      open: false,
      input: "",
      serviceArea: this.props.serviceAreas[0],
      codes: {},
      expanded: {},
      prevInput: "",
      searchInput: "",
    }
  }
  componentDidMount() {
    if (this.props.serviceAreas?.length) {
      this.fetchCodes(this.props.serviceAreas[0])
    }
    eventHub.on("toggleDecoder", this.toggleOpen)
  }
  componentWillUnmount() {
    eventHub.off("toggleDecoder", this.toggleOpen)
  }
  componentDidUpdate(oldProps) {
    const { serviceArea } = this.state
    const { serviceAreas } = this.props
    if (
      serviceAreas?.length &&
      (!serviceArea || serviceAreas.indexOf(this.state.serviceArea) === -1)
    ) {
      this.setState({
        input: "",
        serviceArea: this.props.serviceAreas[0],
        expanded: {},
      })
      this.fetchCodes(this.props.serviceAreas[0])
    }
  }

  onChange = ({ target: { value } }) => {
    this.setState({ input: value })
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({ searchInput: value })
    }, 250)
  }

  onKeyDown = e => {
    // Escape
    if (e.keyCode === 27) {
      this.toggleOpen()
    }
  }

  selectSA = serviceArea => {
    this.setState({ serviceArea, expanded: {} })
    this.fetchCodes(serviceArea)
  }
  toggleOpen = () => {
    this.setState({ open: !this.state.open }, () => {
      if (this.input && this.state.open) {
        this.input.focus()
      } else {
        this.setState({ input: "", expanded: {} })
      }
    })
  }
  normalize(text) {
    return text.toLowerCase().replace("-", "")
  }
  async fetchCodes(serviceArea) {
    this.setState({ codes: [] })
    if (!serviceArea) {
      return
    }
    const {
      data: { codes },
    } = await Radio.getCityCodes({ serviceArea })
    const codesBySection = {}
    codes.forEach(row => {
      row.normCode = this.normalize(row.code)
      row.normMeaning = this.normalize(row.meaning)
      if (!codesBySection[row.subheader]) {
        codesBySection[row.subheader] = []
      }
      codesBySection[row.subheader].push(row)
    })
    this.setState({ codes: codesBySection })
  }
  toggleExpandSubsection = subsection => {
    this.setState(st => {
      const newExpanded = { ...st.expanded }
      if (st.expanded[subsection]) {
        newExpanded[subsection] = false
      } else {
        newExpanded[subsection] = true
      }
      return { ...st, expanded: newExpanded }
    })
  }

  render() {
    const { serviceAreas, serviceAreaCfg } = this.props
    let { input, serviceArea, open, codes, expanded, prevInput, searchInput } =
      this.state
    if (!serviceArea) {
      serviceArea = serviceAreas[0]
    }
    let body
    if (open) {
      const codeDivs = []
      const normInput = this.normalize(searchInput)
      Object.keys(codes).forEach(section => {
        const rows = codes[section].filter(
          r =>
            r.normCode.includes(normInput) || r.normMeaning.includes(normInput),
        )
        if (input.length > 0 && prevInput !== input) {
          expanded[section] = true
        }
        if (rows.length > 0) {
          codeDivs.push(
            <CodeSection
              key={section}
              header={section}
              rows={rows}
              expanded={expanded[section]}
              onClick={() => {
                this.toggleExpandSubsection(section)
              }}
            />,
          )
        }
      })
      this.setState({ prevInput: input })
      body = (
        <div className={styles.cityCodesBody}>
          <ServiceAreaTable
            serviceAreas={serviceAreas}
            onClick={this.selectSA}
            selectedSA={serviceArea}
            serviceAreaCfg={serviceAreaCfg}
          />
          <div className={styles.filter}>
            <input
              type='text'
              onChange={this.onChange}
              value={input}
              placeholder='search codes...'
              autoFocus
              ref={input => (this.input = input)}
              onKeyDown={this.onKeyDown}
            />
          </div>
          <div className={styles.codes}>{codeDivs}</div>
        </div>
      )
    }
    return (
      <div className={styles.codeContainer}>
        <div className={styles.cityCodes}>
          <div
            className={
              this.state.open
                ? styles.cityCodesInnerExpanded
                : styles.cityCodesInner
            }
          >
            <div className={styles.header} onClick={this.toggleOpen}>
              Decoder <i className={`fas fa-caret-${open ? "down" : "up"}`} />
            </div>
            {body}
          </div>
        </div>
      </div>
    )
  }
}

CityCodes.propTypes = {
  serviceAreas: array,
  serviceAreaCfg: object,
}

export default CityCodes
