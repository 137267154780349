import React from "react"
import styles from "./Stat.module.css"
import PropTypes, { string, number, bool } from "prop-types"

export interface StatType {
  name: string | number;
  value: string | number;
  centerName?: boolean
}

export const Stat = ({ name, value, centerName }: StatType): JSX.Element => (
  <div className={styles.stat}>
    <div className={styles.value}>{value}</div>
    <div
      className={styles.name}
      // @ts-ignore
      style={{ textAlign: centerName ? "center" : "" }}
    >
      {name}
    </div>
  </div>
)

Stat.propTypes = {
  name: PropTypes.oneOfType([string, number]),
  value: PropTypes.oneOfType([string, number]),
  centerName: bool,
}

export default React.memo(Stat)
