import React from "react"
import { object, bool } from "prop-types"
import { Link } from "react-router-dom"
import classnames from "classnames"

import { shouldReplace } from "@guardian/Utils/history"

import style from "./Markers.module.css"

const HelicopterMarker = ({ model, selected }) => {
  const body = (
    <div className={style.centerContainer}>
      {model.title && (
        <div className={style.tooltip}>
          <h4>{model.title}</h4>
        </div>
      )}
      {model.title.toLowerCase().match("landed") ? (
        <div
          className={classnames(style.helicopter, {
            [style.selected]: selected,
          })}
        />
      ) : (
        <img
          alt=""
          src='https://assets.citizen.com/Helicopter.png'
          height='40px'
          width='40px'
        />
      )}
    </div>
  )
  if (model.link) {
    let pathname = `/incidents/${model.id}`
    if (window.location.href.includes("news")) {
      pathname = `/news${pathname}`
    }

    return (
      <Link to={pathname} replace={shouldReplace(pathname)}>
        {body}
      </Link>
    )
  }

  return body
}

HelicopterMarker.propTypes = {
  model: object.isRequired,
  selected: bool,
}

export default HelicopterMarker
