import classNames from "classnames";
import styled from "styled-components";

const AgentImageWrapper = styled.div.attrs(
  props => {
    const {
      className,
      isOwn
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__agent-image-wrapper", {
          "sos-dms__chat-tab__chat__agent-image-wrapper--is-own": isOwn
        }
      )
    }
  }
)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  // 2px is in accordance with var(--borders-outer-highlight-full), which is
  // the box shadow around the avatar we are wrapping.
  padding: 2px 2px 2px calc(var(--space-x-small) + 2px);

  & > * {
    width: 28px;
    height: 28px;
    box-shadow: var(--borders-outer-highlight-full) var(--colors-black);
  }

  &.sos-dms__chat-tab__chat__agent-image-wrapper--is-own {
    & > * {
      box-shadow: var(--borders-outer-highlight-full) var(--colors-blue-050);
    }
  }
`;

export default AgentImageWrapper;
