import React, { memo } from "react"
import { array, string, func, object } from "prop-types"

import LiveTabVideoPlayer from "./LiveTabVideoPlayer/LiveTabVideoPlayer"
import ServiceAreaSelector from "./ServiceAreaSelector"
import styles from "./StreamPanel.module.css"

const StreamPanel = ({
  streams,
  selectedStreamId,
  selectStream,
  incidents,
  streamHandled,
  serviceAreaFilters,
  setServiceAreaFilters,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.controlsRow}>
        <ServiceAreaSelector
          serviceAreaFilters={serviceAreaFilters}
          setServiceAreaFilters={setServiceAreaFilters}
        />
      </div>
      <div className={styles.streams}>
        {streams.map(({ videoStream, iglStream, noLongerLive }) => {
          let location
          let title
          const incident = incidents[videoStream.incidentId]
          if (incident) {
            location = incident.location.neighborhood
            title = incident.title
          } else {
            location = iglStream && iglStream.address
          }

          return (
            <div
              key={videoStream.id}
              className={styles.video}
              onClick={() => {
                if (selectedStreamId !== videoStream.id)
                  selectStream(videoStream.id)
              }}
            >
              <LiveTabVideoPlayer
                url={
                  videoStream.broadcastDone || videoStream.hlsDone
                    ? videoStream.hlsVodUrl
                    : videoStream.hlsLiveUrl
                }
                live={!(videoStream.broadcastDone || videoStream.hlsDone)}
                stream={videoStream}
                incident={incident}
                title={title || videoStream.reportCategory || videoStream.title}
                durationSeconds={
                  videoStream.durationSeconds ||
                  (new Date().getTime() - Date.parse(videoStream.createdAt)) /
                    1000
                  // Gets the duration for opentok videos
                }
                skipDisabled
                showControls={true}
                autoPlay
                defaultMuted
                location={location}
                selected={selectedStreamId === videoStream.id}
                streamHandled={streamHandled}
                noLongerLive={noLongerLive}
                videoProvider={videoStream.videoProvider}
                videoSessionId={videoStream.videoSessionId}
                openTokToken={videoStream.openTokToken}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

StreamPanel.propTypes = {
  streams: array,
  selectedStreamId: string,
  selectStream: func,
  streamHandled: func,
  incidents: object,
  serviceAreaFilters: array,
  setServiceAreaFilters: func,
}

export default memo(StreamPanel)
