import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledTab = styled.button.attrs(
  props => {
    const {
      className,
      isActive,
      isFocused
    } = props;

    return {
      className: classNames(className, "tab__tab", {
        "tab__tab--is-active": isActive,
        "tab__tab--is-focused": isFocused
      })
    };
  }
)`
  ${ buttonResets }

  align-items: center;
  background: var(--colors-transparent);
  box-shadow: none;
  color: var(--colors-transparent);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-sizes-medium);
  font-weight: var(--font-weights-bold);
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: var(--line-heights-medium);
  outline: none;
  position: relative;
  text-decoration: none;

  padding: 0 calc(var(--space-large) / 2.0);

  &:first-child {
    padding-left: 0;

    &:after {
      left: 0;
    }
  }

  &:last-child {
    padding-right: 0;

    &:after {
      right: 0;
    }
  }

  // NOTE: If you're curious about the use of the pseudo element here, it's
  // because our tab bar links change font weight when active or hovered. As
  // font weight changes adjust the rendered width of the texts, this would
  // cause the tab items to move around when this weight changes. To address
  // this elegantly, we make the tab items have their text, but transparent, so
  // that they are rendered in the browser at the right size for their normal
  // weight text. Then we use the pseudo element, placed _over_ the transparent
  // text, to actually _display_ the text. When font weight changes it simply
  // then sits, centered, over its normal text space.
  &:after {
    align-items: center;
    bottom: 0;
    color: var(--colors-grey-020);
    display: flex;
    content: attr(data-text);
    font-size: var(--font-sizes-medium);
    font-weight: var(--font-weights-normal);
    justify-content: center;
    left: calc(var(--space-large) / 2.0);
    line-height: var(--line-heights-medium);
    position: absolute;
    right: calc(var(--space-large) / 2.0);
    top: 0;
  }

  &:active, &.tab__tab--is-active,
  &:hover
  {
    color: var(--colors-transparent);
    text-decoration: none;

    &:after {
      color: var(--colors-white);
      font-weight: var(--font-weights-bold);
    }
  }

  // :focus managed by KeyboardFocusContainer
  &.tab__tab--is-focused
  {
    color: var(--colors-transparent);
    text-decoration: none;

    &:after {
      color: var(--colors-white);
      font-weight: var(--font-weights-bold);
    }
  }

  &:active, &.tab__tab--is-active
  {
    &:after {
      box-shadow: var(--borders-highlight-bottom) var(--colors-white);
    }
  }
`;

const Tab = React.forwardRef(
  ({ children, isFocused, ...other }, ref) => (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <StyledTab
          {...getFocusProps({
            ref,
            ...other,
            isFocused: keyboardFocused
          })}
        >
          { children }
        </StyledTab>
      )}
    </KeyboardFocusContainer>
  )
);

Tab.propTypes = {
  isActive: PropTypes.bool
};

Tab.defaultProps = {
  isActive: false
};

export default Tab;

