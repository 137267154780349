import React from "react";

import HumanMessageGroup from "./components/HumanMessageGroup";
import SystemMessageGroup from "./components/SystemMessageGroup";

const MessageGroup = props => {
  const { members, messageGroup } = props;

  const renderHumanMessageGroup = (messageGroup) => {
    return (
      <HumanMessageGroup
        members={members}
        messageGroup={messageGroup}
      />
    );
  };

  const renderSystemMessageGroup = (messageGroup) => {
    return (
      <SystemMessageGroup
        messageGroup={messageGroup}
      />
    );
  };

  const renderMessageGroup = (messageGroup) => {
    const { authorId } = messageGroup;

    // NOTE: Currently, if a message's `authorId` is "system" we consider it an
    // automated message from our backend and render accordingly. Since this is
    // how we differentiate such system messages from messages sent by an Agent
    // or a User, we consider _any other_ `authorId` value to mean the message
    // an Agent or a User. If this ever changes, this function body should be
    // updated accordingly.
    let renderedMessageGroup;
    switch (authorId) {
      case "system":
        renderedMessageGroup = renderSystemMessageGroup(messageGroup);
        break;
      default:
        renderedMessageGroup = renderHumanMessageGroup(messageGroup);
    }

    return renderedMessageGroup;
  };

  return (
    <React.Fragment>
      {
        renderMessageGroup(messageGroup)
      }
    </React.Fragment>
  );
};

export default React.memo(MessageGroup);
