import { MenuItem, OutlinedInput, Select } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import _ from "lodash"
import { bool, func, string } from "prop-types"

import { useGlobalState } from "@guardian/State"

import style from "./NotificationTypeSentiment.module.css"
import { inputStyles, selectStyles } from "./NotificationTypeSentiment.styles"

const StyledInput = withStyles(inputStyles)(OutlinedInput)
const StyledSelect = withStyles(selectStyles)(Select)

const sentiments = ["positive", "neutral", "negative"]
const NotificationTypeSentiment = ({
  type,
  setType,
  sentiment,
  setSentiment,
  disabled
}) => {
  const sentimentTypeDefaults = useGlobalState(
    state => state.global.customNotifConfig.sentimentTypeDefaults,
  )

  return (
    <div className={style.container}>
      <StyledSelect
        label='Notification Type'
        value={type}
        style={{ marginRight: "8px" }}
        onChange={e => setType(e.target.value)}
        input={<StyledInput notched label='Notification Type' />}
        variant='outlined'
        renderValue={selected => (
          <div className={style.chip}>{_.startCase(selected)}</div>
        )}
        disabled={disabled}
      >
        {Object.keys(sentimentTypeDefaults).map(t => (
          <MenuItem key={t} value={t}>
            {sentimentTypeDefaults[t].posString}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect
        label='Notification Sentiment'
        value={sentiment}
        onChange={e => setSentiment(e.target.value)}
        input={<StyledInput notched label='Notification Sentiment' />}
        variant='outlined'
        renderValue={selected => (
          <div className={style.chip}>{_.startCase(selected)}</div>
        )}
        disabled={disabled}
      >
        {sentiments.map(t => (
          <MenuItem key={t} value={t}>
            {_.startCase(t)}
          </MenuItem>
        ))}
      </StyledSelect>
    </div>
  )
}

NotificationTypeSentiment.propTypes = {
  type: string,
  sentiment: string,
  setType: func,
  setSentiment: func,
  disabled: bool,
}
export default NotificationTypeSentiment
