import classNames from "classnames";
import styled from "styled-components";

const Fieldset = styled.div.attrs(
  props => ({
    className: classNames("form__fieldset")
  })
)`
  & + & {
    margin-top: var(--space-medium);
  }
`;

Fieldset.propTypes = {};

Fieldset.defaultProps = {};

export default Fieldset;
