import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "@guardian/Components/ModSOS/components/ActionBar/ActionBar/ActionBar.module.css"
import classnames from "classnames"
import { ButtonWithModal } from "@guardian/Components/ModSOS/components/ActionBar/ButtonWithModal/ButtonWithModal"
import {
  analystEndSOSSession,
  dialWMB911,
  inviteWMBEmergencyContacts,
} from "@guardian/API/Optimus/resources/legacy/resources/WMB"
import {
  dialOutProtectSession,
  endProtectSession,
  getProtectSession,
} from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import SOSSessionContext from "@guardian/Components/Contexts/SOSSessionContext"
import { push } from "@guardian/Utils/history"
import { isV2Protect } from "@guardian/Utils/protect"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { SOS_SESSION_MISSED } from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.helper"
import { EmergencyContactsModal } from "@guardian/Components/ModSOS/components/ActionBar/EmergencyContactsModal/EmergencyContactsModal"
import { IProps } from "@guardian/Components/ModSOS/components/ActionBar/ActionBar/types"
import { updateSessionResponse } from "@guardian/Components/ModSOS/store/dashboard/actions/dashboard"

export const ActionBar: React.FunctionComponent<IProps> = ({
  activeSessionId,
  requested911,
  emergencyContacts,
  offPlatformEmergencyContacts,
  userLocation,
  is911InSession,
  onRequested911,
}) => {
  const [showEmergencyContactsModal, setShowEmergencyContactsModal] = useState(
    false,
  )
  const [showRequest911Modal, setShowRequest911Modal] = useState(requested911)
  const dialed911 = useRef(false)
  const disconnected = useContext(SOSSessionContext)
  const { state, dispatch } = useSOSDashboardContext()
  const userDisconnected =
    disconnected ||
    (state.callSession && state.callSession.status === SOS_SESSION_MISSED)

  useEffect(() => {
    dialed911.current = state.sessionResp?.data?.outgoingCall?.to === "911"
  }, [Boolean(state.sessionResp?.data?.outgoingCall)])

  const onClick911 = async () => {
    onRequested911()

    if (isV2Protect(activeSessionId)) {
      await dialOutProtectSession(activeSessionId, "911").toPromise()

      const response = await getProtectSession(activeSessionId).toPromise()
      dispatch(updateSessionResponse(response))
    } else {
      await dialWMB911(activeSessionId).toPromise()
    }
  }

  useEffect(() => {
    if (!!is911InSession && !dialed911.current) {
      setShowRequest911Modal(requested911)
    }
  }, [requested911, is911InSession])

  useEffect(() => {
    dialed911.current = false
  }, [activeSessionId])

  var contactsLength =
    emergencyContacts.length + offPlatformEmergencyContacts.length

  const get911HelperLabel = () => {
    if (dialed911.current) {
      return "In progress"
    }

    if (requested911 && !userDisconnected) {
      return "Waiting..."
    }

    return ""
  }

  const handleClickYes = async () => {
    if (!state.callSession) {
      return push("/sos")
    }

    // TODO: have error state
    if (isV2Protect(activeSessionId)) {
      await endProtectSession(activeSessionId).toPromise()
      push("/sos")
    } else {
      await analystEndSOSSession(activeSessionId).toPromise()
      push("/sos")
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.flexRow}>
        <ButtonWithModal
          confirmText={
            state.callSession
              ? "Resolve call? The session will close."
              : "The session will close."
          }
          label={state.callSession ? "Resolve" : "Close"}
          onClickYes={handleClickYes}
          buttonStyles={classnames(styles.buttonGreen, styles.override)}
        />
        <ButtonWithModal
          confirmText={"End call? The user will leave the session."}
          label={"End call"}
          onClickYes={() => {
            // TODO: have error state
            if (isV2Protect(activeSessionId)) {
              endProtectSession(activeSessionId)
            } else {
              analystEndSOSSession(activeSessionId)
            }
          }}
          buttonStyles={classnames(styles.buttonGreen, styles.override)}
          disabled={!state.callSession}
        />
        <ButtonWithModal
          confirmText={"Call 911?"}
          label={"Call 911"}
          onClickYes={onClick911}
          buttonStyles={classnames({
            [styles.buttonRed]: requested911,
            [styles.override]: requested911,
          })}
          helperLabel={get911HelperLabel()}
          helperLabelStyles={styles.redText}
          disabled={
            Boolean(state.sessionResp?.data?.outgoingCall) ||
            is911InSession ||
            dialed911.current
          }
          onExpanded={expanded => setShowRequest911Modal(expanded)}
          expanded={showRequest911Modal}
        />
        <ButtonWithModal
          confirmText={"Call emergency contacts?"}
          label={
            contactsLength > 0
              ? `Call emergency contacts (${contactsLength})`
              : "No emergency contacts"
          }
          disabled={contactsLength === 0}
          onClickYes={async () => {
            // TODO: have error state if errored call
            await inviteWMBEmergencyContacts(activeSessionId).toPromise()
          }}
          expanded={showEmergencyContactsModal}
          modal={
            isV2Protect(activeSessionId) && contactsLength > 0 ? (
              <EmergencyContactsModal
                onClose={() => setShowEmergencyContactsModal(false)}
                contacts={emergencyContacts}
                offPlatformContacts={offPlatformEmergencyContacts}
                sessionId={activeSessionId}
              />
            ) : (
              undefined
            )
          }
          onExpanded={expanded => setShowEmergencyContactsModal(expanded)}
        />
      </div>
    </div>
  )
}
