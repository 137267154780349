import React, { useEffect, useState } from "react";

import { Item, ItemIcon } from "@guardian/UI/Dropdown";
import { Icon } from "@guardian/UI/Icon";

import { MENU_ITEM_KEY } from "../../constants";

const LiveCallMenuItem = props => {
  return (
    <Item value={{ value: MENU_ITEM_KEY }}>
      <ItemIcon.Start>
        <Icon.PhoneForwardedTwoTone/>
      </ItemIcon.Start>
      Start Emergency Live Call
    </Item>
  );
};

export default LiveCallMenuItem;
