import { createContext, useContext } from "react";

export const SelectContext = createContext(undefined);

// Retrieve Select component context
const useSelectContext = () => {
  const selectContext = useContext(SelectContext);

  if (!selectContext) {
    throw new Error("This component must be rendered within an `Select` component.");
  }

  return selectContext;
};

export default useSelectContext;
