export const inputStyles = {
  root: {
    paddingLeft: "16px",
    height: "53px",
    width: "167px",
    color: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
  },
  notchedOutline: {
    "& > legend": {
      visibility: "visible",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "16px",
      color: "#8C8C8C !important",
    },
  },
}

export const selectStyles = {
  root: { paddingLeft: "0px" },
  icon: { color: "#333333 !important" },
}
