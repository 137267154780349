import { MuteButton } from "../MuteButton"
import React, { FC, useEffect, useState } from "react"
import _ from "lodash"
import { IProps } from "@guardian/Components/ModSOS/components/Video/Twillio/components/AgentAudio/types"

export const AgentAudio: FC<IProps> = ({ twilioRoom, audioTrack }) => {
  // TODO: consider moving to video context?
  const [muted, setMuted] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      if (audioTrack && twilioRoom) {
        if (!_.isEmpty(audioTrack)) {
          audioTrack.stop()
          audioTrack.disable()

          twilioRoom.localParticipant.unpublishTrack(audioTrack)
        }
      }
    }
  }, [twilioRoom, audioTrack])

  const muteAgentSpeaker = (isMuted: boolean) => {
    if (audioTrack) {
      isMuted ? audioTrack.disable() : audioTrack.enable()
    }
  }

  return (
    <MuteButton
      onClick={() => {
        muteAgentSpeaker(!muted)
        setMuted(!muted)
      }}
      isMuted={muted}
      type='mic'
    />
  )
}
