import React from "react"

import { BadgeContainer } from "./styles"

const UploadBadge = props => (
  <BadgeContainer
    bgColor='var(--colors-white)'
    textColor='var(--colors-black)'
    {...props}
  >
    Upload
  </BadgeContainer>
)

export default UploadBadge
