import React, { FC } from "react"
import { Link } from "react-router-dom"
import { IProps } from "@guardian/UI/Map/components/IncidentMarker/types"
import {
  Container,
  Signal,
  Label,
} from "@guardian/UI/Map/components/IncidentMarker/styled"
import { coverageToHealthLevel } from "@guardian/UI/Map/components/IncidentMarker/utils"

export const IncidentMarker: FC<IProps> = ({ incidentId, coverage, title }) => (
  <Link to={`/incidents/${incidentId}`} target='_blank'>
    <Container>
      <Signal health={coverageToHealthLevel(coverage)} />
      <Label>{title}</Label>
    </Container>
  </Link>
)
