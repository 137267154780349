import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

export const WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold"
};

const Paragraph = styled.p.attrs(
  props => {
    const {
      className,
      noHighlight,
      weight,
      withMeasure,
    } = props;

    return {
      className: classNames(className, "paragraph", {
          "paragraph--no-highlight": noHighlight,
          "paragraph--with-measure": withMeasure,

          "paragraph--weight-bold": weight === WEIGHTS.BOLD
        })
    };
  }
)`
  font: inherit;
  font-family: inherit;
  font-weight: var(--font-weights-normal);

  &.paragraph--no-highlight {
    user-select: none;
  }

  &.paragraph--weight-bold {
    font-weight: var(--font-weights-bold);
  }
`;

Paragraph.propTypes = {
  noHighlight: PropTypes.bool,
  weight: PropTypes.oneOf(
    [
      WEIGHTS.NORMAL,
      WEIGHTS.BOLD
    ]
  ),
  withMeasure: PropTypes.bool
};

Paragraph.defaultProps = {
  noHighlight: false,
  weight: WEIGHTS.NORMAL,
  withMeasure: false
};

export default Paragraph;
