import React from "react"
import Container from "@guardian/Components/Common/Container"
import Spacing from "@guardian/Components/Common/Spacing"
import styles from "@guardian/Components/ModSOS/components/ErrorModals/CallTakenModalBody/CallTakenModalBody.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/ErrorModals/CallTakenModalBody/types"

export const CallTakenModalBody = React.forwardRef(
  ({ onClose, metadata }: IProps, ref) => {
    return (
      <Container
        className={styles.modal}
        style={{ backgroundColor: "white", color: "#000" }}
        column
        top={8}
        bottom={6}
        left={8}
        right={8}
        leftJustify
      >
        <Container bottom={6}>
          <h2>Someone has already answered this call.</h2>
        </Container>
        <Container column fullWidth>
          <button onClick={onClose} className={styles.buttonOverride}>
            <Container fullWidth className={styles.button}>
              Return to Call Queue
            </Container>
          </button>
          <Spacing top={4}>
            <button
              onClick={metadata && metadata.joinCall}
              className={styles.secondary}
            >
              <Container fullWidth>Take Over Call</Container>
            </button>
          </Spacing>
        </Container>
      </Container>
    );
  }
);
