import React from "react"

import { BadgeContainer } from "./styles"

const PhotoBadge = props => (
  <BadgeContainer
    bgColor='var(--colors-yellow-050)'
    textColor='var(--colors-black)'
    {...props}
  >
    Photo
  </BadgeContainer>
)

export default PhotoBadge
