import classnames from "classnames"
import dayjs from "dayjs"
import { bool, func, string } from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import { Signal } from "@guardian/API/Optimus"
import combokeys from "@guardian/Utils/hotkeys"
import titleize from "@guardian/Utils/titleize"
import { dateToTimezone } from "@guardian/Utils/util"

import { WarningBadge } from "@guardian/Components/Badges"
import LocationInput from "@guardian/Components/Form/Inputs/LocationInput"
import NumberIteratorInput from "@guardian/Components/Form/Inputs/NumberIteratorInput"
import IncidentStatusSelect from "@guardian/Components/IncidentStatus/IncidentStatusSelect"
import CategoryInput from "@guardian/Components/ModUpdateInputs/CategoryInput"
import { Button, IconButton } from "@guardian/UI/Button"
import { Icon } from "@guardian/UI/Icon"
import { LabeledCheckbox } from "@guardian/UI/Legacy/LabeledCheckbox"
import { MultilineInput } from "@guardian/UI/Legacy/MultilineInput"
import { TextInput } from "@guardian/UI/Legacy/TextInput"

import { useIncidentCreationContext } from "./IncidentCreation.context"
import style from "./IncidentCreation.module.css"

const formatWithTimezone = (time) => {
  return time.format("MM/DD/YYYY hh:mm a (z)")
}

const REPORTED_TIME_THRESHOLD_HOURS = 6

const IncidentCreation = ({
  className,
  verified,
  block,
  username,
  isGoodVibe,
  location,
  prompt911,
  onPrompt911,
  dontNotify,
  toggleDontNotify,
  onClose,
  onSubmit,
}) => {
  const { tags, serviceAreas } = useGlobalState(state => {
    const {
      tags,
      serviceAreaConfig: { serviceAreas },
    } = state.global
    return { tags, serviceAreas }
  })

  const { state, updateProperty } = useIncidentCreationContext()

  const originalTitle = useRef(state.title)
  const originalAddress = useRef(state.location.address)
  const originalReportedTime = useRef(state.occurredAt)

  const [autoFormat, setAutoFormat] = useState(true)
  const [selectVal, setSelectVal] = useState(
    isGoodVibe ? "GoodVibes" : "Active",
  )
  const [serviceAreaName, setServiceAreaName] = useState("")

  const timezone = useMemo(() => {
    const incidentServiceArea = Object.values(serviceAreas).find(
      sa => sa.name === serviceAreaName,
    )
    return incidentServiceArea?.timezone
  }, [serviceAreaName, serviceAreas])

  const occurredAt = useMemo(() => {
    return dateToTimezone(state.occurredAt, timezone)
  }, [state.occurredAt, timezone])

  const reportedTimeDiffHours = useMemo(
    () =>
      originalReportedTime.current
        ? dayjs().diff(dayjs(originalReportedTime.current), "hours")
        : 0,
    [originalReportedTime.current],
  )

  const onTitleBlur = async title => {
    const formattedTitle = autoFormat ? titleize(title) : title

    const {
      data: { results },
    } = await Signal.getAutoSuggestTags(formattedTitle)

    if (results.length) {
      updateProperty("tag", results[0].display_name)
    }
    updateProperty("title", formattedTitle)
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const syncServiceArea = async () => {
      const { data } = await Signal.getServiceAreaCode(
        location.lat,
        location.lng,
        { signal },
      )
      setServiceAreaName(data.name)
    }
    syncServiceArea()
    return () => {
      controller.abort()
    }
  }, [location.lat, location.lng])

  useEffect(() => {
    if (onClose) {
      combokeys.bind("esc", onClose)
    }

    return () => {
      combokeys.unbind("esc")
    }
  }, [onClose])

  useEffect(() => {
    eventHub.emit("set active marker", {
      lat: state.location?.lat,
      lng: state.location?.lng,
    })
    eventHub.emit("set center", {
      lat: state.location?.lat,
      lng: state.location?.lng,
    })
  }, [state.location])

  return (
    <div className={classnames(style.container, className)}>
      <div className={style.title}>
        {titleize(originalTitle.current) || "Incident Creation"}
      </div>
      <div className={style.subheading}>
        {originalAddress.current ||
          "Please create an incident off this confirmed report"}
      </div>
      {!!username && <div className={style.username}>@{username}</div>}
      <div className={style.inputs}>
        <div className={style.selectInputRow}>
          <CategoryInput
            onSetCategory={val => updateProperty("tag", val)}
            category={state.tag}
            categories={["N/A"].concat((tags || []).map(t => t.display_name))}
          />

          <IncidentStatusSelect
            setSelectStatus={setSelectVal}
            value={selectVal}
          />
        </div>

        <LocationInput
          onChange={e => updateProperty("address", e)}
          placeholder='Address or Intersection'
          defaultValue={state.location.address || ""}
        />

        <TextInput
          label='Incident Title'
          notched
          onChange={e => updateProperty("title", e.target.value)}
          placeholder='Suggest a Title'
          value={state.title || ""}
          onBlur={() => onTitleBlur(state.title)}
        />
        <LabeledCheckbox
          active={autoFormat}
          onClick={() => setAutoFormat(val => !val)}
          label='Autoformat Title'
          blue
        />
        <MultilineInput
          label='Update'
          notched
          onChange={e => updateProperty("update", e.target.value)}
          placeholder='Describe what’s Happening…'
          value={state.update || ""}
          minRows={3}
        />
        <div className={style.inputRow}>
          <NumberIteratorInput
            min={0}
            max={5}
            defaultValue={0}
            label='Level'
            onChange={val => updateProperty("level", val)}
          />
          <div className={style.statusContainer}>
            <label>Video Status:</label>
            <div>
              <div
                className={classnames(style.status, {
                  [style.active]: block,
                })}
              >
                Blocked
              </div>
              <div
                className={classnames(style.status, {
                  [style.active]: verified,
                })}
              >
                Verified
              </div>
            </div>
          </div>
        </div>
        {originalReportedTime.current && (
          <TextInput
            label='Reported Incident Time'
            notched
            disabled
            placeholder='Reported Incident Title'
            autoComplete="off"
            value={occurredAt ? formatWithTimezone(occurredAt) : ""}
            endAdornment={
              reportedTimeDiffHours >= REPORTED_TIME_THRESHOLD_HOURS && (
                <WarningBadge>
                  Over {REPORTED_TIME_THRESHOLD_HOURS} hours ago
                </WarningBadge>
              )
            }
          />
        )}
        <div className={style.displayLocation}>
          <TextInput
            notched
            label='Display Location'
            onChange={e => updateProperty("location", e.target.value)}
            placeholder='Address or Intersection'
            value={state.location.location || ""}
          />
          <TextInput
            notched
            label='Precinct'
            placeholder='Precinct'
            value={state.location.police || ""}
            disabled
          />
        </div>
        <LabeledCheckbox
          active={prompt911}
          onClick={() => onPrompt911(!prompt911)}
          label='Prompt user to call 911'
          blue
        />
        <LabeledCheckbox
          active={!dontNotify}
          onClick={toggleDontNotify}
          label='Send Notification'
          blue
        />
        <Button
          disabled={!onSubmit}
          onClick={() => onSubmit(selectVal)}
          isPrimary
          pill
          stretch
        >
          {state.incident ? "Merge" : "Create"}
        </Button>
      </div>
      {!!onClose && (
        <div className={style.close}>
          <IconButton onClick={onClose} size='small'>
            <Icon.Close />
          </IconButton>
        </div>
      )}
    </div>
  )
}

IncidentCreation.propTypes = {
  verified: bool,
  block: bool,
  username: string,
  className: string,
  isGoodVibe: bool,
  prompt911: bool,
  onPrompt911: func,
  dontNotify: bool,
  toggleDontNotify: func,
  onClose: func,
  onSubmit: func,
}

export default IncidentCreation
