import classNames from "classnames";
import styled from "styled-components";

const MessageGroupWrapper = styled.div.attrs(
  props => {
    const {
      className,
      isFirst
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__chat-tab__chat__message-groups__message-group-wrapper", {
          "sos-dms__chat-tab__chat__message-groups__message-group-wrapper--is-first": isFirst
        }
      )
    }
  }
)`
  &.sos-dms__chat-tab__chat__message-groups__message-group-wrapper--is-first {
    padding-bottom: var(--space-x-large);
  }
`;

export default MessageGroupWrapper;
