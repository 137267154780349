import { OptimusAPI } from "@guardian/API/Optimus";

interface ModerationCropThumbnailData {
  source: string
  y: number
};

const Moderation = {
  cropThumbnail: (data: ModerationCropThumbnailData, options: any = {}) => {
    return OptimusAPI.post(
      `/moderation/crop_stream_thumbnail`,
      data,
      options
    );
  }
};

export default Moderation;
