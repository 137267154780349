import _ from "lodash"
import Freezer from "freezer-js"
import { isMobileOnly } from "react-device-detect"
import { INCIDENT_MAX_AGE } from "./consts"

import keyBindings from "./keyBindings"
import channels from "./channels"
import user from "./user"
import clips from "./clips"
import incidents from "./incidents"
import map from "./map"
import app from "./app"
import { Map, List } from "immutable"

let initChannels = {}
let initServiceAreas = []
let initAllowedChannels = {}
let initActiveTimestamp = 0
try {
  initActiveTimestamp =
    new Date(parseInt(window.localStorage["gnet:activeTimestamp"])) || 0
  initChannels = JSON.parse(window.localStorage["signal:activeChannels"])
  initServiceAreas = JSON.parse(
    window.localStorage["signal:activeServiceAreas"],
  )
  initAllowedChannels = JSON.parse(window.localStorage["gnet:allowedChannels"])
} catch (e) {}

const moderation = {
  incidents: [],
  selectedIncident: null,
  mapLocation: {},
  serviceAreas: [],
  incidentTimeRange: { since: INCIDENT_MAX_AGE, until: -10000 },
  incidentSortBy: "ts",
  rejectionChoices: {},
}
try {
  const moderationFromLS = JSON.parse(
    window.localStorage["gnet:searchFilter"] || "{}",
  )
  const since = _.clamp(parseInt(moderationFromLS.since, 10), 0, 72)
  moderation.incidentTimeRange = { since: since * 60 * 60 * 1000, until: -1 }
  moderation.incidentSortBy = moderationFromLS.sortBy
  let selectedIncident =
    moderationFromLS.selectedIncident || _.get(window, "location.pathname", "")
  // in case selectedIncident isnt set, we should check the url
  if (selectedIncident.startsWith("/incidents/-")) {
    // remove the prefix
    selectedIncident = selectedIncident.substring(11)
  }
  moderation.selectedIncident = selectedIncident
} catch (e) {}

const state = new Freezer({
  global: {
    // User
    user: null,
    permissions: {},
    readOnly: true,
    loginErr: "",
    active: false,
    activeTimestamp: initActiveTimestamp,
    mobileMaskOn: isMobileOnly,
    stats: [],
    incidentsByHour: {},
    clipTooltip: null,
    tab: "",
    serviceAreaConfig: {
      serviceAreas: {},
      allowlist: {},
      listeningGroups: {},
    },

    canAutoPlay: false,
    mapInit: false,

    metapod: true,
    tags: [],

    customNotifConfig: {},
    streetTeamUsers: [],
    autoExpansionExperimentCityCodes: [],
    modBroadcasterConfig: {},
    showClipsConfig: {},
    clipPriorityConfig: {},
    transcriptionConfig: {},
    duplicateDetectionConfig: {},
    transcriptionsDisabled: false,
    creators: [],
  },

  radio: {
    // Clips Page
    clips: [],
    clipsView: [],
    duplicateClips: new Set(),
    pendingClipsFetch: false,
    isFetchingClips: false,
    pinnedClips: [],
    multiplayerPinnedClips: [],
    channels: {},
    serviceAreas: {},
    activeChannels: initChannels,
    activeClip: {},
    activeGroups: window.localStorage["guardian:activeGroups"]
      ? new Set(JSON.parse(window.localStorage["guardian:activeGroups"]))
      : new Set(),
    speed: 1,
    cutoff: 3,
    mutes: {},
    preIsolateActiveChannels: {},
    preSuperIsolateActiveChannels: {},
    activeChannelOverride: false,
    activeClipOverride: false,
    kbHandlers: null,
    channelStats: {},
    flags: {
      flags: [],
      otherPlayers: [],
    },
    flaggedOnly: false,
    multiplayerPinOnly: false,
    unplayedOnly: false,
    flaggedConfig: {},
    rateClip: null,
    allowedChannels: new Map(initAllowedChannels),
    activeServiceAreas: initServiceAreas,
    flagAlgorithm: null,
    sortingMethod: null,
  },

  incidentComposer: {
    mapLocation: {},
    incidentFeed: [],
    attachedClips: new List(),
  },

  moderation,
})

// attach actions
if (!window.location.pathname.match("caterpie")) {
  keyBindings(state)
}

channels(state)
user(state)
clips(state)
incidents(state)
map(state)
app(state)

// globals
window.state = state

export default state
