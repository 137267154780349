import { number } from "prop-types"
import React from "react"

import style from "./UnplayedClipsAlert.module.css"

const UnplayedClipsAlert = ({ count }) => {
  return (
    <div className={style.alert}>
      <div className={style.circle}>
        <i className='fas fa-arrow-down' />
      </div>
      <div className={style.text}>{count} new clips</div>
    </div>
  )
}

UnplayedClipsAlert.propTypes = {
  count: number.isRequired,
}

export default UnplayedClipsAlert
