import _ from "lodash"
import { object, string } from "prop-types"
import React, { PureComponent } from "react"

import { eventHub } from "@guardian/State"

import { sortCanonical } from "@guardian/Utils/util"

import Channel from "./Channel"
import "./ChannelMenu.scss"

const canonicalOrder = ["Police", "Fire", "EMS", "Waveguide"]

class ChannelMenu extends PureComponent {
  toggleResponder = responder => () =>
    eventHub.emit("toggleResponderType", {
      listeningGroup: this.props.listeningGroup,
      subArea: this.props.subArea,
      type: responder,
    })

  render() {
    const {
      responders: byResponder,
      mutes,
      activeChannels,
      channelStats,
    } = this.props
    const responders = sortCanonical(
      Object.keys(byResponder),
      canonicalOrder,
    )

    return (
      <div className='channel-menu'>
        {responders.map(resp => {
          const channels = _.sortBy(byResponder[resp], c => {
            return [-c.starred, c.name]
          })
          if (!channels || !channels.length) {
            return null
          }
          const activeCount = channels.filter(c => activeChannels[c.id]).length
          return (
            <div key={resp} className='responder-menu'>
              <div
                className='responder-name'
                onClick={this.toggleResponder(resp)}
              >
                <span>{resp}</span>
                {activeCount > 0 && (
                  <span className='active-count'>({activeCount})</span>
                )}
              </div>
              {channels.map(ch => {
                return (
                  <Channel
                    key={ch.id}
                    {...ch}
                    activeChannels={activeChannels}
                    mutes={mutes}
                    flagged={channelStats[ch.id]}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }
}

ChannelMenu.propTypes = {
  listeningGroup: string,
  subArea: string,
  activeChannels: object,
  channelStats: object,
  responders: object,
  mutes: object,
}

export default ChannelMenu
