import Container from "@guardian/Components/Common/Container"
import { some } from "lodash"
import React, { useState } from "react"
import styles from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/AgentScripts.module.css"
import { TYPES_MAPPING } from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/AgentScripts"
import { IContentObject, IScriptNote } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptNote/types"
import { IProps, IScriptSection } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptSection/types"
import { ISearchMatch } from '@guardian/Components/ModSOS/components/InfoPane/SearchBox/types';

const isChildMatch = (
  script: IScriptSection | IScriptNote | IContentObject,
  matches: { [id: string]: ISearchMatch },
): boolean => {
  return (
    !!matches[script.id] ||
    some(
      (script as IScriptSection).children ||
        (script as IScriptNote).content ||
        [],
      c => isChildMatch(c as any, matches),
    )
  )
}

export const AgentScriptSection = ({
  title,
  children = [],
  matches,
  id,
  type,
  activeSearch,
  forceRender,
  onAction,
}: IScriptSection & IProps) => {
  const [expanded, setExpanded] = useState(false)
  const matched =
    matches && isChildMatch({ title, children, id, type }, matches)

  if (matches && !matched && !forceRender) {
    return null
  }

  let searchedTitle
  if (activeSearch && activeSearch.id === id) {
    searchedTitle = [
      title.slice(0, activeSearch.start),
      <span className={styles.searchMatch}>
        {title.slice(activeSearch.start, activeSearch.end)}
      </span>,
      title.slice(activeSearch.end),
    ].filter(e => !!e)
  }

  return (
    <Container column leftJustify top={2}>
      <Container fullWidth onClick={() => setExpanded(!expanded)} leftJustify>
        <Container right={2}>
          <img
            alt='caret'
            src='/Caret.svg'
            style={expanded ? { transform: "rotate(180deg)" } : undefined}
          />
        </Container>
        {searchedTitle || title}
      </Container>
      {expanded && (
        <Container left={4} leftJustify column>
          {children.map(script => {
            const { Component } = TYPES_MAPPING[script.type] || {}

            if (!Component) {
              return null
            }

            const s = script as any
            return (
              <Component
                {...s}
                matches={matches}
                activeSearch={activeSearch}
                onAction={onAction}
                key={script.id}
              />
            )
          })}
        </Container>
      )}
    </Container>
  )
}
