import styled from "styled-components";

const IconWrapper = styled.div`
  align-items: center;
  background: var(--colors-grey-080);
  border-radius: var(--radii-circle);
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  padding: var(--space-small);
  width: 64px;
`;

export default IconWrapper;
