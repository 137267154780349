import styled from "styled-components";

const TabBar = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 56px;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: calc(-1.0 * var(--space-medium));
  margin-top: var(--space-x-large);
`;

export default TabBar;
