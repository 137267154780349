import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import { objPick } from "@guardian/Utils/obj-pick"

import CityCodes from "@guardian/Components/CityCodes/CityCodes"
import MetapodListenControls from "@guardian/Components/ListenControls/MetapodListenControls"
// Original ListenPanel components
import ClipContextMenu from "@guardian/Components/ListenPanel/ClipContextMenu"
import ClipList from "@guardian/Components/ListenPanel/ClipList"
import "@guardian/Components/ListenPanel/ListenPanel.scss"

import NoFileSelected from "./NoFileSelected"

const LabelingListenPanel = ({ isFetchingClips }) => {
  const {
    groupSettings,
    clipsView: clips,
    channels,
    activeClip,
    clipContextMenu,
    speed,
    activeClipOverride,
    flaggedOnly,
    serviceAreas,
  } = useGlobalState(state => {
    const selectedGlobal = objPick(state.global, ["groupSettings"])
    const selectedRadio = objPick(state.radio, [
      "clipsView",
      "channels",
      "clipContextMenu",
      "speed",
      "activeClipOverride",
      "flaggedOnly",
    ])
    const { serviceAreas } = state.global.serviceAreaConfig
    const activeClip = objPick(state.radio.activeClip, [
      "id",
      "status",
      "animateStatus",
      "loop",
    ])
    return { ...selectedGlobal, ...selectedRadio, serviceAreas, activeClip }
  })

  const [isolatedChannel, setIsolatedChannel] = useState(null)

  useEffect(() => {
    const handleLabelingIsolate = channel => {
      setIsolatedChannel(prevChannel =>
        channel === prevChannel ? null : channel,
      )
      eventHub.emit("showFlash", "isolate (i)")
    }

    eventHub.on("labelingIsolate", handleLabelingIsolate)
    return () => {
      eventHub.off("labelingIsolate", handleLabelingIsolate)
    }
  }, [])

  const handleFlagToggle = () => {
    eventHub.emit("set flagged only", !flaggedOnly)
  }

  const filteredClips = useMemo(() => {
    const isolatedClips = isolatedChannel
      ? clips.filter(c => c.channel === isolatedChannel)
      : clips

    return isolatedClips.map(clip => {
      // NOTE: We delete clip files older than 30 days in staging.
      // In order to allow labeling of clips older than 30 days,
      // we need to point the clip URL directly to the production bucket.
      const clipUrl = `storage.googleapis.com/citizen-production/${clip.id}.wav`
      return { ...clip, url: clipUrl, wav_url: clipUrl }
    })
  }, [clips, isolatedChannel])

  const contextClip = useMemo(() => {
    if (!clipContextMenu || !groupSettings.allowClipContextMenu) {
      return null
    }
    return filteredClips.find(c => c.id === clipContextMenu.id) ?? null
  }, [clipContextMenu, groupSettings.allowClipContextMenu, filteredClips])

  const currentServiceAreas = useMemo(() => {
    const allServiceAreasSet = new Set(
      clips.map(c => channels[c.channel]?.serviceArea).filter(Boolean),
    )
    return Array.from(allServiceAreasSet).sort()
  }, [clips])

  let listBody
  if (clips.length === 0 && !isFetchingClips) {
    listBody = isFetchingClips ? <h2>loading...</h2> : <NoFileSelected />
  } else {
    listBody = (
      <ClipList
        clips={filteredClips}
        channels={channels}
        activeClipId={activeClipOverride ? "0" : activeClip.id}
        activeClipStatus={activeClip.status}
        activeClipAnimateStatus={activeClip.animateStatus}
        activeClipLoop={activeClip.loop}
        speed={speed}
        isolateOn={!!isolatedChannel}
        hideAttachmentControls
      />
    )
  }

  return (
    <div className='listen-panel'>
      {contextClip && <ClipContextMenu {...clipContextMenu} {...contextClip} />}
      <MetapodListenControls
        onFlagToggle={handleFlagToggle}
        flagCount={null}
        multiplayerPinCount={null}
      />
      {listBody}
      <CityCodes
        serviceAreaCfg={serviceAreas}
        serviceAreas={currentServiceAreas}
      />
    </div>
  )
}

LabelingListenPanel.propTypes = {
  isFetchingClips: PropTypes.bool.isRequired,
}

export default LabelingListenPanel
