import { v4 as uuid } from "uuid";

export const generateMessageGroup = (authorId, messages) => {
  return {
    updateId: `assigned-agent-chat-message-group__update-${ uuid() }`,
    authorId: authorId,
    messages: messages
  };
};

// NOTE: If a group is ever modified after the fact, e.g.; when new data is
// loaded and we want to push a group of messages into the first or last
// grouping we have alread generated, due to receiving more messages for that
// logical group, we should also update the `updateId` therein so that react
// can properly re-render that grouping.
//
// NOTE: We should _not_ generate groupings within a react component. This
// should be handled outside of react so that uuids are safe and not
// re-generated for any rendering concern or reason.
//
// TODO: One thing we may want to add, which I believe iMessage does, is to also
// be sensitive to the time between messages when grouping. For example, maybe
// subsequent messages by the same Agent or User, a day apart, shouldn't group
// as the same group.
export const generateMessageGroups = (messages) => {
  const groups = [];
  let lastGroup = {};

  for (var i = 0; i < messages.length; i += 1) {
    const message = messages[i];
    const { authorId, body: { type } = {} } = message;

    // NOTE: "Human" messages (any coming from an Agent or a User), that have
    // a type are currently ignored, as we are only supporting text messages,
    // which currently have no type within their body. Should we add other
    // supported types, e.g.; "image" type, in the future, this function body
    // should be updated accordingly.
    if (authorId !== "system" && type != null) {
      continue;
    }

    // NOTE: For our purposes, we want to group any message from a human (an
    // Agent or a User) by their id (`authorID`) for rendering. For system
    // messages, however, we consider these to each consitute their own group,
    // where these groups will each contain a single message (the system
    // message).
    if (authorId === "system" || authorId !== lastGroup.authorId) {
      lastGroup = generateMessageGroup(authorId, [ message ]);
      groups.push(lastGroup);
    } else {
      lastGroup.messages.push(message);
    }
  }

  return groups;
};

export const prependToMessageGroups = (messageGroups, message) => {
  let newGroups;

  if (messageGroups.length === 0) {
    // If no existing messageGroups, simply create a new group and return it as
    // the only object in our new messageGroups array.
    const newGroup = generateMessageGroup(message.authorId, [message]);
    newGroups = [newGroup];
  } else if (
    messageGroups[0].authorId === message.authorId &&
    messageGroups[0].authorId !== "system"
  ) {
    // If the first group's authorId is the same as the new message's
    // authorId, make a new message group formed by prepending the new
    // message to the previous first group's messages array. Return the
    // previous messageGroups array with the updated first group replacing the
    // previous first group. Doing so ensure's keys are updated and react
    // can properly rerender individual group objects.
    const updatedFirstGroup = generateMessageGroup(
      message.authorId,
      [message, ...messageGroups[0].messages]
    );
    newGroups = [updatedFirstGroup, ...messageGroups.slice(1)];
  } else {
    // Finally, if the new message does not match the authorId of the first
    // group in our messageGroups array, create a new group and prepend it
    // to the messageGroups array.
    const newGroup = generateMessageGroup(message.authorId, [message]);
    newGroups = [newGroup, ...messageGroups];
  }

  return newGroups;
};
