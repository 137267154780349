import * as Sentry from "@sentry/react"
import _ from "lodash"

import * as analytics from "@guardian/Analytics"
import { Radio } from "@guardian/API/Optimus"
import { AgentService } from "@guardian/Services/Agent"
import { AuthService } from "@guardian/Services/Auth"
import { ClipService, RadioService } from "@guardian/Services/Radio"
import sockets from "@guardian/Sockets"
import history from "@guardian/Utils/history"
import { getDefaultChannels } from "@guardian/Utils/state"

async function getPins() {
  try {
    return await ClipService.getPinnedClips()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    return []
  }
}

async function getMultiplayerPins() {
  try {
    return await ClipService.getMultiplayerPinnedClips()
  } catch (e) {
    console.log("error in getMultiplayerPins:", e)
    return []
  }
}

const user = state => {
  state.on("getUser", async () => {
    // NOTE: This accounts for the legacy code calling tons of API endpoints
    // and appending access tokens to requests but only after, in many cases,
    // first checking for the presence of an access token in our local storage.
    // In those cases, and where not present, the code would simply fall through
    // triggering `logout` and throwing the error below. Because our frontend
    // currently always makes a call to `getUser` on the dashboard load, even
    // when logged out, I moved this one check to here for now, as all future
    // requests will automatically trigger logout on auth failure via our
    // interceptors.
    //
    // TODO: Just get rid of calling getUser at all on the dashboard when the
    // user isn't logged in. State sensitive data loading etc should be
    // intentional.
    if (!AgentService.userId) {
      state.emit("logout")
      throw new Error("Not authenticated")
    }

    // TODO:
    // This is legacy code, but we should be catching cancellations and failures
    // in all of these requests.
    //
    // TODO:
    // Overall much of this code could use a lot of cleanup and evaluation. One
    // thing I'm noticing while working here though is that this function
    // appears to get called repeatedly for probably no good reason 6 times
    // following "update" to the user in LoginFlow.js.
    const [user, permissions, stats, features] = await Promise.all([
      AgentService.getAuthenticatedAgent(),
      AgentService.getAuthenticatedAgentPermissions(),
      AgentService.getAuthenticatedAgentStats(),
      AgentService.getAuthenticatedAgentFeatures(),
    ])
    user.features = features
    state.get().global.set("user", user)
    analytics.set({ userId: user.id })
    Sentry.setUser({ id: user.id, username: user.username, email: user.email })
    state.get().global.set("permissions", permissions)
    state
      .get()
      .global.set(
        "readOnly",
        !(permissions.radioDesk || permissions.safetyDesk),
      )
    state
      .get()
      .global.set("allowedPilotProgram", permissions.allowedPilotProgram)
    state.get().global.set("videoDesk", permissions.videoDesk)
    state.get().global.set("canArchive", permissions.canArchive)
    if (permissions.lafd) {
      state.get().global.set("allowlist", { LA: true })
      state.get().global.set("groupSettings", {
        allowEdits: false,
        allowNotifications: false,
        allowSocialShare: false,
        allowClipContextMenu: false,
        center: { lat: 34.025569, lng: -118.426832 },
      })
    } else {
      state.get().global.set("groupSettings", {
        allowEdits: true,
        allowNotifications: true,
        allowSocialShare: true,
        allowClipContextMenu: true,
      })
    }
    const [cfg, pins, multiplayerPins] = await Promise.all([
      RadioService.getRadioConfiguration(),
      getPins(),
      getMultiplayerPins(),
    ])
    state.get().radio.set("speed", parseFloat(cfg.playbackRate))
    state.get().radio.set("cutoff", parseFloat(cfg.cutoff))
    state.get().radio.set("flaggedOnly", cfg.flaggedOnly)
    state.get().radio.set("multiplayerPinOnly", false)
    state.get().global.set("active", cfg.active)
    state.get().radio.set("flaggedConfig", cfg.flaggedConfig || {})
    state.get().global.set("tutorialFlow", cfg.doneTutorial ? undefined : 0)
    state.get().global.set("stats", stats.stats)
    state.get().global.set("incidentsByHour", stats.incidentsByHour)
    state.get().radio.set("pinnedClips", pins)
    state.get().radio.set("multiplayerPinnedClips", multiplayerPins)
    state.get().radio.set("streamsUsers", cfg.streamsUsers)
    if (cfg.channels && !state.get().radio.activeChannelOverride) {
      state
        .get()
        .radio.set(
          "activeChannels",
          _.fromPairs(cfg.channels.map(c => [c, true])),
        )
    }
    if (cfg.mapCenterLat && cfg.mapCenterLng) {
      state.get().incidentComposer.mapLocation.set("center", {
        lat: cfg.mapCenterLat,
        lng: cfg.mapCenterLng,
      })
    }
    if (!sockets.isOpen) {
      sockets.open()
    }
    RadioService.setRadioConfiguration({
      referrerId: state.get().global.referrerId || "nobody",
    })
    state.get().global.set("referrerId", null)
  })

  state.on("loginWithGoogle", async token => {
    // TODO:
    // This is legacy code, but we should be catching cancellations and failures
    // in all of these requests.
    await AuthService.loginWithGoogle({ token })
    await state.emit("getUser")
    const { citMod, trainee } = state.get().global.permissions
    if (citMod || trainee) {
      try {
        Radio.identifyToSegment()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  })

  state.on('setLoginErr', (err) => {
    state.get().global.set("loginErr", err)
  })

  state.on("internalLogout", () => {
    sockets.clearSubs()
    sockets.destroy()
    AuthService.logout()
    delete window.localStorage["signal:speed"]
    delete window.localStorage["signal:activeChannels"]
    delete window.localStorage["signal:activeServiceAreas"]
    delete window.localStorage["gnet:searchFilter"]
    state.get().global.set({
      user: null,
      permissions: {},
    })
    state.get().radio.set({
      pinnedClips: [],
      activeChannels: getDefaultChannels(state.get().radio.channels),
      speed: 1,
      cutoff: 1,
      clips: [],
    })
    analytics.set({ user: null })
    Sentry.setUser(null)
    state.emit("stopUpdatingModIncidents")
  })

  state.on("logout", () => {
    state.emit("internalLogout")
    history.push("/")
  })

  state.on("logoutOAuth2", () => {
      window.location.replace("/oauth2/sign_in")
  })

  state.on("syncUserStats", async () => {
    const {
      stats,
      incidentsByHour,
    } = await AgentService.getAuthenticatedAgentStats()

    state.get().global.set("stats", stats)
    state.get().global.set("incidentsByHour", incidentsByHour)
  })
}

export default user
