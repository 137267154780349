import React from "react";
import PropTypes from "prop-types";

import { TabListContext } from "../hooks/useTabListContext";
import useTabsContext from "../hooks/useTabsContext";

import StyledTabList from "../views/TabList";

const TabList = props => {
  const {
    children
  } = props;

  const {
    onChange,
    selectedTab
  } = useTabsContext();

  return (
    <StyledTabList>
      <TabListContext.Provider value={{ selectedTab, onChange }}>
        { children }
      </TabListContext.Provider>
     </StyledTabList>
  );
};

TabList.propTypes = {
  onChange: PropTypes.func,
  selectedTab: PropTypes.string
};

export default TabList;
