import styled from "styled-components";

import ToggleSwitch from "./ToggleSwitch";

const ToggleSwitchWrapper = styled.div`
  input {
    display: none;

    &:checked + ${ ToggleSwitch } {
      background-color: #0F2614;

      &:before {
        box-shadow: var(--borders-full) #1CB33E;
      }

      &::after {
        background-color: #1CB33E;
        box-shadow: inset 0 0 0 2.5px #0F2614;
        transform: translateX(100%);
      }
    }
  }
`;

export default ToggleSwitchWrapper;
