import styled, { css } from "styled-components"

type ItemProps = {
  padded?: boolean
  center?: boolean
  right?: boolean
}

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid var(--colors-grey-080);
  gap: 10px;
  background-color: #0e151d;
`

export const HeaderContainer = styled(Container)`
  position: sticky;
  top: 0;
  font-weight: var(--font-weights-bold);
  background-color: var(--colors-black);
  z-index: 10;
`

const paddedItem = css`
  padding: 10px 0;
`
const rightAlignedItem = css`
  display: flex;
  justify-content: flex-end;
`

const centerAlignedItem = css`
  display: flex;
  justify-content: center;
`

export const Item = styled.div<ItemProps>`
  flex: 1;
  ${({ padded }: ItemProps) => padded && paddedItem}
  ${({ right }: ItemProps) => right && rightAlignedItem}
  ${({ center }: ItemProps) => center && centerAlignedItem}
`

export const ClipColumn = styled(Item)`
  flex: unset;
  width: var(--clip-column-width);
  background-color: black;
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid var(--colors-grey-080);
  ${({ header }: { header: boolean }) => !header && "padding-top: 5px;"};
`

export const FixedItem = styled(Item)`
  flex: unset;
  width: 180px;
`

export const AuditFieldItem = styled(Item)`
  flex: unset;
  width: 130px;
`
