import React, { memo, useMemo, useRef, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import useClickOutside from "@guardian/Hooks/useClickOutside"
import useKeyBindings, {
  type KeyBindings,
} from "@guardian/Hooks/useKeyBindings"
import { suppressEvent } from "@guardian/Utils/hotkeys"
import { objPick } from "@guardian/Utils/obj-pick"
import { stopProp } from "@guardian/Utils/util"

import IconToggle from "@guardian/Components/IconToggle"
import Sound from "@guardian/Components/Sound"
import { CalloutButton } from "@guardian/UI/Legacy/CalloutButton"

import Pin from "@guardian/Assets/Pin.svg?react"

import FlagOnlyToggle from "./FlagModeControls/FlagOnlyToggle"
import "./ListenControls.scss"
import SortingControls from "./SortingControls"

enum PopUpType {
  CUTOFF,
  SPEED,
}

interface ListenControlsProps {
  onFlagToggle: Function
  flagCount: number | null
  multiplayerPinCount: number | null
}

const ListenControls: React.FC<ListenControlsProps> = props => {
  const {
    activeClip,
    speed,
    cutoff,
    flaggedOnly,
    multiplayerPinOnly,
    hiddenCount,
    readOnly,
  } = useGlobalState(state => {
    const { readOnly } = state.global
    const selectedRadio = objPick(state.radio, [
      "activeClip",
      "speed",
      "cutoff",
      "flaggedOnly",
      "multiplayerPinOnly",
    ])
    const { clips, mutes } = state.radio
    const hiddenCount = clips.filter(
      (clip: { duration_ms: number; channel: string | number }) =>
        clip.duration_ms < selectedRadio.cutoff * 1000 || mutes[clip.channel],
    ).length
    return { ...selectedRadio, hiddenCount, readOnly }
  })

  const cutoffInput = useRef<string>("")
  const cutOffButtonRef = useRef<HTMLButtonElement>(null)
  const cutOffPopupRef = useRef<HTMLDivElement>(null)
  const cutOffSliderRef = useRef<HTMLInputElement>(null)

  const speedInput = useRef<string>("")
  const speedButtonRef = useRef<HTMLButtonElement>(null)
  const speedPopupRef = useRef<HTMLDivElement>(null)
  const speedSliderRef = useRef<HTMLInputElement>(null)

  const [openPopUp, setOpenPopUp] = useState<PopUpType | null>(null)

  const { onFlagToggle, flagCount, multiplayerPinCount } = props

  const isPlaying = activeClip.status === Sound.status.PLAYING
  const roundedSpeed = Math.round(10 * speed) / 10

  useClickOutside(
    [speedPopupRef, speedButtonRef, cutOffPopupRef, cutOffButtonRef],
    () => setOpenPopUp(null),
    openPopUp !== null,
  )

  useKeyBindings(
    useMemo(() => {
      if (readOnly) {
        return {}
      }

      function focusCutoffSlider() {
        setOpenPopUp(PopUpType.CUTOFF)
        setTimeout(() => {
          cutOffSliderRef.current?.focus()
        }, 5)
      }

      function focusSpeedSlider() {
        setOpenPopUp(PopUpType.SPEED)
        setTimeout(() => {
          speedSliderRef.current?.focus()
        }, 5)
      }

      const keybinds: KeyBindings = {
        c: suppressEvent(() => focusCutoffSlider()),
        v: suppressEvent(() => focusSpeedSlider()),
        q: suppressEvent(() => eventHub.emit("radioSpeed:increment", -0.25)),
        e: suppressEvent(() => eventHub.emit("radioSpeed:increment", 0.25)),
        "shift+q": suppressEvent(() => eventHub.emit("radioSpeed:increment", -0.1)),
        "shift+e": suppressEvent(() => eventHub.emit("radioSpeed:increment", 0.1)),
      }
      for (let i = 0; i <= 9; i++) {
        keybinds[`shift+${i}`] = suppressEvent(() =>
          eventHub.emit("radioCutoff:set", i),
        )
      }
      return keybinds
    }, [readOnly]),
  )

  const onChangeSpeed = (e: React.SyntheticEvent) => {
    eventHub.emit("radioSpeed:set", (e.target as HTMLInputElement).value)
  }

  const setCutoff = (e: React.SyntheticEvent) => {
    eventHub.emit("radioCutoff:set", (e.target as HTMLInputElement).value)
  }

  const handleCutoffKey = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault()
    const key = event.key
    const elem = event.target as HTMLElement
    if ((key >= "0" && key <= "9") || key === ".") {
      cutoffInput.current += key
      return
    }
    if (key === "Enter" || (key === " " && cutoffInput.current)) {
      eventHub.emit("radioCutoff:set", cutoffInput.current)
    }
    // Reset the input and close the popup
    cutoffInput.current = ""
    elem.blur()
    setOpenPopUp(null)
  }

  const handleSpeedKey = (event: React.KeyboardEvent) => {
    event.preventDefault()
    const key = event.key
    const elem = event.target as HTMLElement
    if ((key >= "0" && key <= "9") || key === ".") {
      speedInput.current += key === "." ? "" : key
      if (speedInput.current.length > 2) {
        speedInput.current = speedInput.current.substring(1)
      }
      return
    }
    if (key === "Enter" || (key === " " && cutoffInput.current)) {
      let speed = speedInput.current[0]
      if (speedInput.current.length > 1) {
        speed += `.${speedInput.current[1]}`
      }
      eventHub.emit("radioSpeed:set", speed)
    }
    // Reset the input and close the popup
    speedInput.current = ""
    elem.blur()
    setOpenPopUp(null)
  }

  const toggleOpenCutoff = (e: React.MouseEvent) => {
    e.preventDefault()
    stopProp(e)
    setOpenPopUp(prev => (prev === PopUpType.CUTOFF ? null : PopUpType.CUTOFF))
  }

  const toggleOpenSpeed = (e: React.MouseEvent) => {
    e.preventDefault()
    stopProp(e)
    setOpenPopUp(prev => (prev === PopUpType.SPEED ? null : PopUpType.SPEED))
  }

  const toggleMultiplayerPinOnly = (e: React.MouseEvent) => {
    e.preventDefault()
    stopProp(e)
    eventHub.emit("filterMultiplayerPinsOnly", !multiplayerPinOnly)
  }

  const togglePlay = () => {
    eventHub.emit("togglePlaying")
  }

  return (
    <div className='listen-controls'>
      <div className='listen-control listen-control--play' onClick={togglePlay}>
        <i className={`fas fa-${isPlaying ? "pause" : "play"}`} />
      </div>
      <div className='listen-control'>
        {openPopUp === PopUpType.CUTOFF && (
          <div
            ref={cutOffPopupRef}
            className='popup-listen-control popup-listen-control--cutoff'
            onClick={stopProp}
          >
            <div className='popup-listen-control__label'>
              <span>Clip Cutoff Time (c)</span>
              <span>
                {cutoff}s {hiddenCount > 0 && `(${hiddenCount})`}
              </span>
            </div>
            <div>
              <input
                ref={cutOffSliderRef}
                className='popup-listen-control__input'
                type='range'
                min='0'
                max={Math.max(10, cutoff)}
                step='0.5'
                value={cutoff}
                onChange={setCutoff}
                onKeyDown={handleCutoffKey}
                onBlur={() => (cutoffInput.current = "")}
              />
            </div>
          </div>
        )}
        <span className='listen-control__label'>Cutoff</span>
        <CalloutButton
          ref={cutOffButtonRef}
          label={cutoff + "s"}
          onClick={toggleOpenCutoff}
        />
      </div>
      <div className='listen-control'>
        {openPopUp === PopUpType.SPEED && (
          <div
            ref={speedPopupRef}
            className='popup-listen-control popup-listen-control--speed'
            onClick={stopProp}
          >
            <div className='popup-listen-control__label'>
              <span>Playback Speed (v)</span>
              <span>{roundedSpeed}x</span>
            </div>
            <div>
              <input
                ref={speedSliderRef}
                className='popup-listen-control__input'
                type='range'
                min='0.5'
                max='3'
                step='0.1'
                value={speed}
                onChange={onChangeSpeed}
                onKeyDown={handleSpeedKey}
                onBlur={() => (speedInput.current = "")}
              />
            </div>
          </div>
        )}
        <span className='listen-control__label'>Speed</span>
        <CalloutButton
          ref={speedButtonRef}
          label={roundedSpeed + "x"}
          onClick={toggleOpenSpeed}
        />
      </div>
      {multiplayerPinCount !== null && (
        <div className='listen-control'>
          <span className='listen-control__info'>({multiplayerPinCount})</span>
          <IconToggle
            className='icon-toggle'
            icon={Pin}
            onClick={toggleMultiplayerPinOnly}
            isActive={multiplayerPinOnly}
            inactiveColor='#8C8C8C'
            activeColor='#4DA6FF'
            title='Multiplayer Pins Only'
          />
        </div>
      )}
      {flagCount !== null && (
        <div className='listen-control'>
          <span className='listen-control__info'>({flagCount})</span>
          <FlagOnlyToggle
            flaggedOnly={flaggedOnly}
            onFlagToggle={onFlagToggle}
          />
        </div>
      )}
      <div className='listen-control hidden'>
        <SortingControls />
      </div>
    </div>
  )
}

export default memo(ListenControls)
