import classNames from "classnames";
import styled from "styled-components";

const BaseUserInfo = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__base-user-info"
    )
  };
})`
  display: flex;
  flex-direction: row;
  padding: var(--space-medium);
`;

BaseUserInfo.defaultProps = {};

export default BaseUserInfo;
