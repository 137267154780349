import React, { useEffect, useState } from "react"
import OT from "opentok-react"
import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import _ from "lodash"
import { IPropsSubscriber } from "@guardian/Components/ModSOS/components/Video/OTP/types"

export const OTSubscriber: React.FunctionComponent<IPropsSubscriber> = ({
  muteUserMic,
  tokboxSession,
  stream,
  userDisconnected,
  muteUserSpeaker,
  userSpeakerMuted,
  userBackgrounded,
}) => {
  const [hasAudio, setHasAudio] = useState<boolean>(stream.hasAudio)
  const [hasVideo, setHasVideo] = useState<boolean>(stream.hasVideo)

  useEffect(() => {
    if (userDisconnected) {
      return
    }

    tokboxSession.session.on("streamPropertyChanged", (e: any) => {
      if (e.stream.id !== _.get(stream, "id", "")) return
      if (e.changedProperty === "hasAudio") setHasAudio(e.newValue)
      if (e.changedProperty === "hasVideo") setHasVideo(e.newValue)
    })

    return () => {
      if (!tokboxSession.session) {
        return
      }

      tokboxSession.session.off("streamPropertyChanged")
    }
  }, [stream, tokboxSession.session, userDisconnected])

  if (userDisconnected) {
    return <>User hung up</>
  }

  return (
    <>
      {stream && (
        <>
          <button
            className={styles.muteButton}
            style={{ right: "35px" }}
            onClick={() => {
              if (muteUserSpeaker) {
                muteUserSpeaker(!userSpeakerMuted)
              }
            }}
          >
            {userSpeakerMuted ? (
              <img src='/protect-audio-muted.svg' alt='thumbnails' />
            ) : (
              <img src='/protect-audio.svg' alt='thumbnails' />
            )}
          </button>
          <button
            className={styles.muteButton}
            onClick={() => {
              muteUserMic(stream.connection, hasAudio)
            }}
          >
            {hasAudio ? (
              <img src='/protect-mic.svg' alt='thumbnails' />
            ) : (
              <img src='/protect-mic-muted.svg' alt='thumbnails' />
            )}
          </button>
          {userBackgrounded && (
            <span className={styles.videoState}>User backgrounded</span>
          )}
          {!hasVideo && <span className={styles.videoState}>Video is off</span>}
          {!userBackgrounded && (
            <OT.OTSubscriber
              properties={{
                showControls: false,
                height: "100%",
                width: "100%",
                style: {
                  backgroundImageURI:
                    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
                },
              }}
              retry
              maxRetryAttempts={3}
              retryAttemptTimeout={2000}
              session={tokboxSession.session}
              stream={stream}
              key={stream.id}
            />
          )}
        </>
      )}
    </>
  )
}

OTSubscriber.defaultProps = {
  userDisconnected: false,
}
