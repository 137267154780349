import React from "react"
import { object } from "prop-types"

import consts from "@guardian/Constants"
import { getIncidentAuthor } from "@guardian/Utils/incident"

import UnmoderatedBadge from "./UnmoderatedBadge"
import CommunityApprovedBadge from "./CommunityApprovedBadge"
import CitizenApprovedBadge from "./CitizenApprovedBadge"

const P2PIncidentVerificationBadge = ({ incident }) => {
  const author = incident && getIncidentAuthor(incident)
  if (!author) {
    return null
  }

  if (author === consts.AutomatedUsername) {
    return incident.unverifiedIGLIncident ? (
      <UnmoderatedBadge />
    ) : (
      <CommunityApprovedBadge />
    )
  }
  if (!incident.unverifiedIGLIncident) {
    return <CitizenApprovedBadge />
  }

  return null
}

P2PIncidentVerificationBadge.propTypes = {
  stream: object,
  incident: object,
}

export default P2PIncidentVerificationBadge
