import _ from "lodash"
import { array, bool, func, object, string } from "prop-types"
import React, { useEffect, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import { objPick } from "@guardian/Utils/obj-pick"

import ListeningGroupChannelSelector from "@guardian/Components/ListeningGroupChannelSelector/ListeningGroupChannelSelector"
import { Checkbox } from "@guardian/UI/Legacy/Checkbox"

const GroupingSelector = ({
  grouping,
  listeningGroups,
  onSelect,
  selected,
  lastInList,
  sortedMap,
  subAreaMap,
  filterText,
  groupingPassesFilter,
  handleInputChange,
  selectAllSubAreas,
}) => {
  const {
    activeChannels,
    channels,
    channelStats,
    channelSelectionGroup,
    subAreas: subAreaSequence,
    mutes,
  } = useGlobalState(state =>
    objPick(state.radio, [
      "activeChannels",
      "channels",
      "channelStats",
      "channelSelectionGroup",
      "subAreas",
      "mutes",
    ]),
  )

  const allSubAreas = _.flatten(Object.values(sortedMap))

  const [filtered, setFiltered] = useState(
    _.values(listeningGroups).filter(lg => lg.code !== "OTHER"),
  )

  useEffect(() => {
    setFiltered(_.values(listeningGroups).filter(lg => lg.code !== "OTHER"))
  }, [listeningGroups])

  return (
    <>
      <div className='grouping-container'>
        <div
          key={grouping}
          onClick={() => {
            onSelect(grouping)
            eventHub.emit("toggle listening group", null)
          }}
          className='state-text'
        >
          {grouping}
        </div>
        {grouping !== "Other" && (
          <Checkbox
            active={selectAllSubAreas}
            handleClick={() => {
              eventHub.emit("toggle listening group", grouping, allSubAreas)
              eventHub.emit(
                "modifyListeningGroup",
                grouping,
                !selectAllSubAreas ? "add" : "delete",
              )
            }}
          />
        )}
      </div>
      {selected &&
        filtered.map(lg => {
          const subs = sortedMap[lg.code]
          const lgPasses = _.some([lg.name, lg.longName], v =>
            v.toLowerCase().match(filterText),
          )
          const showLg = _.some(subs, sub =>
            sub.toLowerCase().match(filterText),
          )

          if (
            (!showLg && !(lgPasses || groupingPassesFilter)) ||
            !subs.length
          ) {
            return null
          }
          return (
            <ListeningGroupChannelSelector
              key={lg.code}
              code={lg.code}
              name={lg.longName}
              serviceAreas={lg.serviceAreas}
              activeChannels={activeChannels}
              channels={channels}
              channelStats={channelStats}
              selectedListeningGroup={channelSelectionGroup}
              subAreaSequence={subAreaSequence}
              mutes={mutes}
              sorted={sortedMap[lg.code]}
              allSubAreas={subAreaMap[lg.code]}
              subAreasMatchFilter={showLg}
              onlyStateGroup={filtered.length === 1 && lg.longName === grouping}
              handleInputChange={handleInputChange}
              filterText={filterText}
            />
          )
        })}
      {!lastInList && <hr className='separator' />}
    </>
  )
}

GroupingSelector.propTypes = {
  grouping: string,
  listeningGroups: array,
  onSelect: func,
  selected: bool,
  lastInList: bool,
  sortedMap: object,
  subAreaMap: object,
  filterText: string,
  groupingPassesFilter: bool,
  handleInputChange: func,
}

export default GroupingSelector
