import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SwitchTransition } from "@guardian/UI/SwitchTransition";

import ChatTab from "./components/ChatTab";
import MapTab from "./components/MapTab";
import StyledTabs from "./views/Tabs";

const Tabs = props => {
  const { session } = props;

  const { tab } = useParams();

  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    if (tab != null) {
      // When these pages are being navigated away from, the `tab` param will
      // become undefined. In these cases we dont want to cause our tab
      // transitioner logic to trigger any new renders, hence we ignore these
      // updates. When this view is truly in view, it will always have a tab
      // param.
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <StyledTabs>
      <SwitchTransition
        activeChildKey={activeTab}
        transitionMs={Tabs.transitionMs}
      >
        {
          activeTab === "chat" && (
            <ChatTab session={session}/>
          )
        }
        {
          activeTab === "map" && (
            <MapTab session={session}/>
          )
        }
      </SwitchTransition>
    </StyledTabs>
  );
};

Tabs.transitionMs = 250;

export default Tabs;
