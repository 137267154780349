import React from "react";

import useSelectContext from "../../hooks/useSelectContext";
import StyledToggle from "../../views/Select/Toggle";

const Toggle = props => {
  const {
    setIsToggleFocused
  } = useSelectContext();

  const toggleProps = {
    onFocus: e => {
      setIsToggleFocused(true);
    },
    onBlur: e => {
      setIsToggleFocused(false);
    },
    ...props
  };

  return (
    <StyledToggle {...toggleProps} />
  );
};

export default Toggle;
