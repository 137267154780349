import _ from "lodash"
import { Room, LocalAudioTrack } from "twilio-video"

export const selectAudioTrack = (
  twilioRoom?: Room,
): LocalAudioTrack | undefined => {
  const audioTracks = _.get(
    twilioRoom,
    "localParticipant.audioTracks",
    new Map(),
  )

  return _.get(audioTracks.values().next(), "value.track", undefined)
}
