import { cloneDeep, get, isEmpty, keyBy } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  IPropsV1,
  ILocation,
  INearbyIncident,
  ISafetyPOI,
} from "@guardian/Components/ModSOS/components/Map/SOSMap/types"

export const useSOSMap = ({
  updateMapStyles,
  activeSessionId,
  isV2Protect,
  updateLocation,
  updateAddress,
  location,
}: IPropsV1) => {
  const [records, setRecords] = useState<any[]>()
  const [pois, setPois] = useState<ISafetyPOI[]>()
  const [nearbyIncidents, setNearbyIncidents] = useState<INearbyIncident[]>()
  const ref = useRef<{
    records?: any[]
    pois?: ISafetyPOI[]
    nearbyIncidents?: INearbyIncident[]
  }>({ records: undefined, pois: undefined, nearbyIncidents: undefined })

  useEffect(() => {
    if (isV2Protect && records && records.length) {
      const loc = records[0].location
      const newLocation: ILocation = {
        longitude: loc.longitude,
        latitude: loc.latitude,
        addressSpecific: loc.addressSpecific,
        addressGeneral: loc.addressGeneral,
      }

      updateAddress(
        `${newLocation.addressSpecific}, ${newLocation.addressGeneral}`,
      )
      updateLocation(newLocation)
      updateMapStyles((prevStyle: any) => {
        if (location.isNotInRoute || isEmpty(prevStyle)) return prevStyle
        // react-map-gl library is INCREDIBLY finicky and relies on recursive deep comparison to rerender
        const prevLineData = [
          ...prevStyle.sources["wmb-route"].data.geometry.coordinates,
          [location.longitude, location.latitude],
        ]
        const newSource = cloneDeep(prevStyle.sources["wmb-route"])
        newSource.data.geometry.coordinates = prevLineData
        prevStyle.sources["wmb-route"] = newSource
        return { ...prevStyle }
      })
    }
  }, [records])

  const compareAndSetData = (
    r: any[],
    p: ISafetyPOI[],
    n: INearbyIncident[],
  ) => {
    const shouldUpdate = (
      newVals: any[],
      oldVals?: any[],
      idCompare?: (obj: any) => string,
    ) => {
      let update = !oldVals || oldVals.length !== newVals.length

      if (idCompare && !update) {
        const mapping = keyBy(oldVals, idCompare)
        update = newVals.some(v => !mapping[idCompare(v)])
      }

      return update
    }

    if (ref.current) {
      if (shouldUpdate(r, ref.current.records)) {
        ref.current.records = r
        setRecords(r)
      }

      if (shouldUpdate(p, ref.current.pois, poi => poi.id)) {
        ref.current.pois = p
        setPois(p)
      }

      if (
        shouldUpdate(
          n,
          ref.current.nearbyIncidents,
          incident => incident.incidentId,
        )
      ) {
        ref.current.nearbyIncidents = n
        setNearbyIncidents(n)
      }
    }
  }

  const onSuccessV2 = useCallback(
    (data: any) => {
      if (!data) {
        return
      }
      const [recordsResp, feedResp] = data
      let { nearbyIncidents, pois } = get(feedResp, "data", {})

      if (data.length > 1 && isV2Protect) {
        pois = pois.map((poi: any) => ({
          ...poi,
          id: `${poi.name}-${poi.latitude}-${poi.longitude}-${poi.address}`,
        }))
      }

      let nearbyFiltered = []
      if (nearbyIncidents) {
        nearbyFiltered = nearbyIncidents.filter(
          (i: INearbyIncident) => !i.isAreaAlert,
        )
      }
      compareAndSetData(recordsResp.data.records, pois, nearbyFiltered)
    },
    [isV2Protect],
  )

  return {
    onSuccessV2,
    pois,
    nearbyIncidents,
  }
}
