import styled from 'styled-components'

export const Incident = styled.div`
  padding: 16px;
  background: rgb(23, 28, 38);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: white;
  width: 179px;
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;

  & + & {
    margin-left: 8px;
  }
`

export const TextRow = styled.div`
  margin-bottom: 8px;
`

export const Emphasis = styled.span`
  color: #61bff1;
`

export const Time = styled(TextRow)`
  color: rgb(98, 112, 140);
`

export const MergeButton = styled.button`
  background: rgb(55, 65, 82);
  border-radius: 20px;
  height: 24px;
  min-width: 72px;
  margin-top: auto;
  color: rgb(143, 159, 191);
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid rgb(55, 65, 82);
  transition: 0.2s all ease-in-out;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    color: white;
    cursor: not-allowed;
  }
`
