import React, { useState } from "react"
import styles from "@guardian/Components/ModSOS/components/CallQueue/CallNode/CallNode.module.css"
import classnames from "classnames"
import { useInterval } from "@guardian/Hooks/useInterval"
import { toHHMMSS } from "@guardian/Utils/util"
import {
  SOS_SESSION_DISABLED,
  SOS_SESSION_MISSED,
} from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.helper"
import {
  chatOnlyMode,
  SensorType,
  videoCallMode,
} from "@guardian/Components/ModSOS/components/CallQueue/CallNodeLink/CallNodeLink.types"
import { IProps } from "@guardian/Components/ModSOS/components/CallQueue/CallNode/types"

// TODO: refactor

export const CallNode: React.FunctionComponent<IProps> = React.memo(
  ({
    analyst,
    fullName,
    initialDuration,
    is911,
    isFirstCall,
    isActive,
    isFromIncident,
    isFromNonSubscriber,
    status,
    mode,
    sensorType,
    entryPoint,
  }) => {
    const [duration, setDuration] = useState(initialDuration)

    useInterval(() => {
      if (status !== SOS_SESSION_DISABLED) {
        setDuration(duration + 1)
      }
    }, 1000)

    const isPickedUp = analyst.length

    let rightBadge = null
    if (is911) {
      rightBadge = <div className={styles.blockNineOneOne}>911</div>
    } else if (sensorType === SensorType.crash_detection) {
      rightBadge = (
        <div className={styles.blockSensor}>
          <span className={styles.blockLabel}>{sensorType}</span>
        </div>
      )
    } else if (mode === chatOnlyMode) {
      rightBadge = <div className={styles.blockMode}>Chat Only</div>
    } else if (sensorType) {
      rightBadge = (
        <div className={styles.blockSensor}>
          <span className={styles.blockLabel}>{sensorType}</span>
        </div>
      )
    } else if (isFromNonSubscriber) {
      rightBadge = <div className={styles.blockFromNonSub}>Non-Subscriber</div>
    } else if (entryPoint === "bluetooth") {
      rightBadge = <div className={styles.blockMode}>Bluetooth</div>
    } else if (isFirstCall) {
      rightBadge = <div className={styles.blockFirstCall}>FIRST CALL</div>
    } else if (isFromIncident) {
      rightBadge = <div className={styles.blockFromIncident}>Incident</div>
    } else if (mode === videoCallMode) {
      rightBadge = <div className={styles.blockMode}>Video Call</div>
    } else if (entryPoint === "callback") {
      rightBadge = <div className={styles.blockMode}>Callback</div>
    } else if (entryPoint === "shield_session_crash_detection") {
      rightBadge = (
        <div className={styles.blockMode}>Shield Crash Detection</div>
      )
    } else if (entryPoint === "shield_session_safe_phrase_detection") {
      rightBadge = <div className={styles.blockMode}>Shield Safe Phrase</div>
    }

    return (
      <div
        className={classnames(styles.container, {
          [styles.active]: isActive,
          [styles.nineOneOneText]: is911 && !analyst,
          [styles.pickedUp]: isPickedUp,
          [styles.disabled]: status === SOS_SESSION_DISABLED,
          [styles.missed]: status === SOS_SESSION_MISSED,
        })}
      >
        <div className={styles.nameBlock}>
          <div>
            {status === SOS_SESSION_MISSED ? "Missed" : toHHMMSS(duration)}
          </div>
          {rightBadge}
        </div>
        <div className={styles.rightBlock}>
          <div>{fullName}</div>
          <div>
            <span className={styles.analystText}>{analyst}</span>
          </div>
        </div>
      </div>
    )
  },
)
