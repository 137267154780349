import { string } from "prop-types"
import React from "react"

import styles from "./CodeSection.module.css"

const CodeRow = ({ code, meaning }) => (
  <div className={styles.row}>
    <div className={styles.code}>{code}</div>
    <div>{meaning}</div>
  </div>
)

CodeRow.propTypes = {
  code: string,
  meaning: string,
}

export default CodeRow
