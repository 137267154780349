import classNames from "classnames";
import styled from "styled-components";

const InputField = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__input-field"
    )
  };
})`
  flex: 0 0 100px;
  padding: 0 var(--space-medium) var(--space-medium);
`;

InputField.defaultProps = {};

export default InputField;
