import React, { useContext } from "react";
import { withTheme } from "styled-components";

import { AppContext } from "@guardian/Core";
import { Box } from "@guardian/UI/Box";
import { Button } from "@guardian/UI/Button";
import { Icon } from "@guardian/UI/Icon";
import { LargeParagraph, MediumParagraph } from "@guardian/UI/Paragraph";
import { SwitchTransition } from "@guardian/UI/SwitchTransition";

import IconWrapper from "./views/IconWrapper";
import MessageWrapper from "./views/MessageWrapper";
import StyledNoSelectionMessage from "./views/NoSelectionMessage";

const NoSelectionMessage = props => {
  const {
    theme: { colors }
  } = props;

  const {
    agentChat: {
      available,
      toggleAvailable
    }
  } = useContext(AppContext);

  return (
    <StyledNoSelectionMessage>
      <MessageWrapper>
        <IconWrapper>
          <SwitchTransition
            activeChildKey={
              available ? "available" : "unavailable"
            }
            transitionMs={250}
          >
            {
              available ? (
                <Icon.CheckCircleOutlineRounded
                  color={colors.green040}
                  height="100%"
                  width="100%"
                />
              ) : (
                <Icon.ErrorOutlineRounded
                  color={colors.red040}
                  height="100%"
                  width="100%"
                />
              )
            }
          </SwitchTransition>
        </IconWrapper>
        <SwitchTransition
          activeChildKey={
            available ? "available" : "unavailable"
          }
          transitionMs={250}
        >
          {
            available ? (
              <React.Fragment>
                <Box mt="xLarge">
                  <LargeParagraph withMeasure weight="bold">
                    Available for New Chat Sessions
                  </LargeParagraph>
                  <Box mt="small">
                    <MediumParagraph withMeasure>
                      You will be assigned new Chat Sessions from users seeking
                      help. Chat Sessions that are assigned to you will appear
                      on the left.
                    </MediumParagraph>
                  </Box>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box mt="xLarge">
                  <LargeParagraph withMeasure weight="bold">
                    Unavailable for New Chat Sessions
                  </LargeParagraph>
                  <Box mt="small">
                    <MediumParagraph withMeasure>
                      You will not be assigned new Chat Sessions. Please use
                      sparingly.
                    </MediumParagraph>
                  </Box>
                  <Box mt="large">
                    <Button onClick={toggleAvailable}>
                      Go Available
                    </Button>
                  </Box>
                </Box>
              </React.Fragment>
            )
          }
        </SwitchTransition>
      </MessageWrapper>
    </StyledNoSelectionMessage>
  );
};

export default withTheme(NoSelectionMessage);
