import React, { useReducer, useEffect, useCallback } from "react"
import { array, string, object } from "prop-types"
import Clip from "./Clip"
import { get } from "lodash"
import classnames from "classnames"
import Sound from "@guardian/Components/Sound"
import reducer from "./clipListReducer"
import style from "./ClipList.module.css"
import combokeys, { suppressEvent } from "@guardian/Utils/hotkeys"

const ClipList = ({ className, clips = [], channels }) => {
  const [state, dispatch] = useReducer(reducer, { clipPositions: {}, clips })

  useEffect(() => {
    dispatch({ type: "new clips", data: clips || [] })
  }, [clips])

  useEffect(() => {
    combokeys.bind(
      "up",
      suppressEvent(() => dispatch({ type: "next clip" })),
    )
    combokeys.bind(
      "down",
      suppressEvent(() => dispatch({ type: "previous clip" })),
    )
    combokeys.bind(
      "space",
      suppressEvent(() => dispatch({ type: "toggle active status" })),
    )

    return () => {
      combokeys.unbind("up")
      combokeys.unbind("down")
      combokeys.unbind("space")
    }
  }, [])

  const togglePlay = useCallback((clipId) => {
    if (state.active !== clipId) {
      dispatch({ type: "setNewActive", data: clipId })
      dispatch({ type: "play" })
    } else if (state.status === Sound.status.PLAYING) {
      dispatch({ type: "pause" })
    } else {
      dispatch({ type: "play" })
    }
  }, [])

  const handleOnSeek = useCallback((position, clipId) => {
    if (state.active !== clipId) {
      dispatch({ type: "setNewActive", data: clipId })
    }

    dispatch({ type: "setPosition", data: position })
    dispatch({ type: "play" })
  }, [])

  const handleOnPlay = useCallback((position, clipId) => {
    dispatch({
      type: "setPosition",
      data: { id: clipId, position },
    })
  }, [])

  const handleOnFinishPlaying = useCallback(() => {
    dispatch({ type: "finished" })
    dispatch({ type: "next clip" })
  }, [])

  const getClipStatus = useCallback((clipId) => {
    if (state.active === clipId) {
      return state.status
    }
    return state.clipPositions[clipId] ? Sound.status.PAUSED : Sound.status.STOPPED
  }, [state.active, state.status, state.clipPositions])

  return (
    <div className={classnames(style.container, className)}>
      <h4>Audio Clips</h4>
      <div className={style.list}>
        {clips?.map(clip => (
          <div
            key={clip.id}
            className={classnames("clip-container", {
              "active-clip": clip.id === state.active,
            })}
            onClick={() => togglePlay(clip.id)}
          >
            <div className='clip-container-top'>
              <div className='clip-content'>
                <Clip
                  id={clip.id}
                  wav_url={clip.url}
                  channelName={get(channels[clip.channel], "name")}
                  spectrogram={clip.spectrogram}
                  duration_ms={clip.duration_ms}
                  onSeek={(position) => handleOnSeek(position, clip.id)}
                  status={getClipStatus(clip.id)}
                  time={clip.time}
                  onTogglePlay={() => togglePlay(clip.id)}
                  position={state.clipPositions[clip.id] || 0}
                  onPlaying={({ position }) => handleOnPlay(position, clip.id)}
                  onFinishedPlaying={handleOnFinishPlaying}
                  active={clip.id === state.active}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

ClipList.propTypes = {
  className: string,
  clips: array,
  channels: object,
}

export default ClipList
