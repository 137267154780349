import React from "react";

import { Message as FormMessage } from "@guardian/UI/Form";

// Must be nested with a `<Field>` component.
const Message = React.forwardRef((props, ref) => {
  return (
    <FormMessage ref={ref} {...props}/>
  );
});

export default Message;
