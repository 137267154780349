import classNames from "classnames";
import styled from "styled-components";

import { IconButton as GenericIconButton } from "@guardian/UI/Button";

const IconButton = styled(GenericIconButton).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__base-user-info__icon-button"
    )
  };
})`
  color: var(--colors-grey-030);
  height: var(--line-heights-x-large)!important;
  margin-left: var(--space-x-small);
  width: var(--line-heights-x-large)!important;

  &:active, &.button--is-active,
  &:hover, &.button--is-hovered
  {
    color: var(--colors-white);
  }

  &.button--is-focused
  {
    color: var(--colors-white);
  }
`;

IconButton.defaultProps = {
  outline: false,
  size: "small"
};

export default IconButton;
