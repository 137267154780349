import { array } from "prop-types"

import Map from "@guardian/Components/Map/Map"

import style from "./MapContent.module.css"
import ModerationLog from "./ModerationLog"

const DEFAULT_CENTER = { lat: 40.73143691741254, lng: -74.0129354316012 }

const MapContent = ({ log }) => (
  <div className={style.subpane}>
    <div className={style.map}>
      <Map
        draggable
        allowControls
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={18}
        defaultStyle
        streetView
      />
    </div>
    {log && log.length && <ModerationLog log={log} />}
  </div>
)

MapContent.propTypes = {
  log: array,
}

export default MapContent
