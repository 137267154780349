import React from "react"
import { string, any, number } from "prop-types"
import ProgressSpectrogram from "@guardian/Components/ProgressSpectrogram"
import "./Tooltip.scss"

import { dateToUTC } from "@guardian/Utils/util"

const Tooltip = ({
  channelHeader,
  time,
  spectrogram,
  durationMs,
  position,
  status,
}) => (
  <div
    className='clip-tooltip'
    style={{ top: spectrogram ? "-90px" : "-40px" }}
  >
    <div className='header placed-apart'>
      <div>
        <b>{channelHeader}</b>
      </div>
      <div className='time'>
        <span className='duration'>{Math.round(durationMs / 100) / 10}s</span>{" "}
        {dateToUTC(time).format("HH:mm:ss")}
      </div>
    </div>
    {spectrogram && (
      <ProgressSpectrogram
        width={200}
        spectrogram={spectrogram}
        durationMs={durationMs}
        status={status}
        position={position}
      />
    )}
    <svg className='callout-tip' height={8} width={16}>
      <polygon points='0,0 16,0 8,8 0,0' style={{ fill: "#1d2026" }} />
    </svg>
  </div>
)

Tooltip.propTypes = {
  channelHeader: string,
  time: any,
  spectrogram: any,
  durationMs: number,
  position: number,
  status: string,
}

export default Tooltip
