import { createContext, useContext } from "react";

export const MenuContext = createContext(undefined);

// Retrieve Menu component context
const useMenuContext = () => {
  const menuContext = useContext(MenuContext);

  if (!menuContext) {
    throw new Error("This component must be rendered within a `Menu` component.");
  }

  return menuContext;
};

export default useMenuContext;
