import React from "react"

import { BadgeContainer } from "./styles"

const UserReportedBadge = props => (
  <BadgeContainer bgColor='#DD001A4D' {...props}>
    <span>!</span>
    User Reported
  </BadgeContainer>
)

export default UserReportedBadge
