import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const TRANSITION_NAME = `switch-transition`;

export const TRANSITION_TYPES = {
  FADE_IN_OUT: "fade-in-out"
};

const StyledSwitchTransition = styled.div.attrs(
  props => {
    const {
      className,
      transitionType
    } = props;

    return {
      className: classNames("switch-transition", className, {
        "switch-transition--transition-fade-in-out":
            transitionType == TRANSITION_TYPES.FADE_IN_OUT,
      })
    };
  }
)`
  ${({ transitionMs }) => `
    flex-grow: 1;
    height: 100%;

    &.switch-transition--transition-fade-in-out {
      transition:
        opacity ${ transitionMs }ms cubic-bezier(0.39, 0.575, 0.565, 1);
      transition-property: opacity;

      &.${ TRANSITION_NAME }-appear,
      &.${ TRANSITION_NAME }-enter {
        opacity: 0;
      };

      &.${ TRANSITION_NAME }-appear.${ TRANSITION_NAME }-appear-active,
      &.${ TRANSITION_NAME }-enter.${ TRANSITION_NAME }-enter-active,
      &.${ TRANSITION_NAME }-appear-done,
      &.${ TRANSITION_NAME }-enter-done,
      &.${ TRANSITION_NAME }-exit {
        opacity: 1;
      };

      &.${ TRANSITION_NAME }-exit.${ TRANSITION_NAME }-exit-active,
      &.${ TRANSITION_NAME }-exit-done {
        opacity: 0;
      };
    }
  `}
`;

const SwitchTransitionInnerWrapper = styled.div.attrs(
  props => {
    return {
      className: "switch-transition__inner-wrapper"
    };
  }
)`
  flex-grow: 1;
  height: 100%;
`;

const SwitchTransition = props => {
  const { children, transitionMs, transitionType, ...passdownProps } = props;

  return (
    <CSSTransition
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive: `${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      timeout={{
        appear: transitionMs, enter: transitionMs, exit: transitionMs
      }}
      {...passdownProps}
    >
      <StyledSwitchTransition
        transitionMs={transitionMs}
        transitionType={transitionType}
      >
        <SwitchTransitionInnerWrapper>
          { children }
        </SwitchTransitionInnerWrapper>
      </StyledSwitchTransition>
    </CSSTransition>
  );
};

SwitchTransition.propTypes = {
  transitionMs: PropTypes.number,
  transitionType: PropTypes.oneOf(
    [
      TRANSITION_TYPES.FADE_IN_OUT
    ]
  )
};

SwitchTransition.defaultProps = {
  transitionMs: 125,
  transitionType: TRANSITION_TYPES.FADE_IN_OUT
};

export default SwitchTransition;
