import React, { useState } from "react";

import {
  Dropdown,
  Item,
  ItemIcon,
  Menu,
  MenuHeader,
  MenuSeparator,
  Trigger,
  ToggleButton
} from "@guardian/UI/Dropdown";
import { Icon } from "@guardian/UI/Icon";

import {
  GeoNumbersHandler,
  GeoNumbersMenuItems
} from "./components/GeoNumbers";
import { LiveCallHandler, LiveCallMenuItem } from "./components/LiveCall";
import { ReassignHandler, ReassignMenuItem } from "./components/Reassign";
import { MenuContext } from "./hooks/useMenuContext";

const ActionsButton = props => {
  const { session } = props;

  const [selection, setSelection] = useState(null);

  const handleSelect = (selection) => {
    setSelection(selection);
  };

  const isResolved = session.endedAt != null;

  return (
    <MenuContext.Provider value={ { selection, session } }>
      <Dropdown
        onSelect={handleSelect}
        downshiftProps={{
          itemToString: item => item && item.value
        }}
      >
        <ToggleButton disabled={isResolved}>
          Actions
        </ToggleButton>
        <Menu placement="bottom-end" size="medium">
          <LiveCallMenuItem/>
          <MenuSeparator/>
          <ReassignMenuItem/>
          <MenuSeparator/>
          <MenuHeader>
            Geo Numbers
          </MenuHeader>
          <GeoNumbersMenuItems/>
        </Menu>
      </Dropdown>
      <LiveCallHandler/>
      <ReassignHandler/>
      <GeoNumbersHandler/>
    </MenuContext.Provider>
  );
};

export default ActionsButton;
