import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const StyledAnchor = styled.a.attrs(
  props => {
    const {
      className,
      active,
      focused,
      forceUnderline,
      hovered
    } = props;

    return {
      className: classNames(className, "anchor", {
        "anchor--is-active": active,
        "anchor--is-focused": focused,
        "anchor--is-hovered": hovered,

        "anchor--force-underline": forceUnderline
      })
    }
  }
)`
  color: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:visited {
    color: inherit;
  }

  &:active, &.anchor--is-active,
  &:hover, &.anchor--is-hovered
  {
    text-decoration: underline;
  }

  // :focus managed by KeyboardFocusContainer
  &.anchor--is-focused
  {
    text-decoration: underline;
  }

  &.anchor--force-underline {
    text-decoration: underline;
  }
`;

const Anchor = React.forwardRef(({ focused, ...other }, ref) => {
  return (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <StyledAnchor
          {...getFocusProps({
            ref,
            ...other,
            focused: focused || keyboardFocused
          })}
        />
      )}
    </KeyboardFocusContainer>
  );
});

Anchor.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  forceUnderline: PropTypes.bool,
  hovered: PropTypes.bool
};

Anchor.defaultProps = {
  active: false,
  focused: false,
  forceUnderline: false,
  hovered: false
};

export default Anchor;
