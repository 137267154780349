import React, { useState } from "react"
import checkboxStyle from "./Input.module.css"
import classnames from "classnames"
import { string, bool, func, object, oneOfType, node } from "prop-types"

const CheckboxInput = ({
  label,
  defaultValue,
  onChange,
  value,
  className,
  disabled,
  style,
}) => {
  const [checked, setChecked] = useState(defaultValue)

  return (
    <div
      className={classnames(
        checkboxStyle.checkbox,
        checkboxStyle.input,
        className,
        {
          [checkboxStyle.checked]: checked || value,
        },
      )}
      style={style || {}}
    >
      <button
        className={checkboxStyle.check}
        disabled={disabled}
        onClick={() => {
          if (!onChange) {
            setChecked(!checked)
          } else {
            onChange(!value)
          }
        }}
      >
        <div className={checkboxStyle.box} />
        {(checked || value) && <i className='fas fa-check' />}
      </button>
      {label && <h5 className={checkboxStyle.label}>{label}</h5>}
    </div>
  )
}

CheckboxInput.propTypes = {
  label: oneOfType([string, node]),
  defaultValue: bool,
  onChange: func,
  value: bool,
  className: string,
  disabled: bool,
  style: object,
}

export default CheckboxInput
