import React from "react"
import { object, array, string, func, arrayOf } from "prop-types"

import consts from "@guardian/Constants"
import ServiceAreaDropdown from "@guardian/Components/Form/ServiceAreaDropdown/ServiceAreaDropdown"
import ContentPreview from "@guardian/Components/ModIGL/OverviewPane/ContentPreview"
import { getContentId } from "@guardian/Components/ModIGL/ModIGL.helper"
import useSmoothScrollToActive from "@guardian/Hooks/useSmoothScrollToActive"

import { getContentIncidentId } from "../IncidentContentMod.helpers"
import style from "./QueuePane.module.css"

const QueuePane = ({
  serviceAreaConfig,
  content,
  incidents,
  activeContentId,
  selectedServiceAreas,
  onServiceAreaSelect,
}) => {
  const { listRef, activeContentRef } = useSmoothScrollToActive(activeContentId)

  return (
    <div className={style.pane}>
      <ServiceAreaDropdown
        serviceAreaConfig={serviceAreaConfig}
        selectedServiceAreas={selectedServiceAreas}
        onServiceAreaSelect={onServiceAreaSelect}
      />
      <div ref={listRef} className={style.list}>
        {content.map(c => {
          if (c.type === "video_stream") {
            const contentId = getContentId(c)
            const incident = incidents[getContentIncidentId(c)]
            const isActive = activeContentId === contentId
            return (
              <ContentPreview
                key={contentId}
                ref={isActive ? activeContentRef : null}
                id={contentId}
                title={incident?.title || "[Error: Incident Has No Title]"}
                incident={incident}
                type={c.type}
                createdAt={c.videoStream.createdAt}
                active={isActive}
                username={c.ugcMeta?.username || c.videoStream.username}
                thumbUrl={c.videoStream.hlsLiveThumbUrl}
                durationSeconds={c.videoStream.durationSeconds}
                contentUrl={`/content-moderation/streams/${contentId}`}
                isReported={c.videoStream?.userReport?.resolved === false}
                isDone={
                  !!(c.videoStream.broadcastDone || c.videoStream.hlsDone)
                }
                isVerified={!!c.videoStream?.verifiedLive}
                isSelfMerged={c.videoStream.mergeTag === "selfMerged"}
                isUpload={c.ugcMeta?.label === consts.CreatorUploadLabel}
                isCreator={!!c.videoStream.isReporter}
                isAnonymous={!!c.ugcMeta?.isAnonymous}
              />
            )
          }
          if (c.type === "image") {
            const contentId = getContentId(c)
            const incident = incidents[getContentIncidentId(c)]
            const isActive = activeContentId === contentId
            return (
              <ContentPreview
                key={contentId}
                ref={isActive ? activeContentRef : null}
                id={contentId}
                title={incident?.title || "[Error: Incident Has No Title]"}
                type={c.type}
                createdAt={c.image.createdAt}
                active={isActive}
                username={c.image.username}
                thumbUrl={c.image.thumbUrl}
                contentUrl={`/content-moderation/images/${contentId}`}
              />
            )
          }
        })}
      </div>
    </div>
  )
}

QueuePane.propTypes = {
  serviceAreaConfig: object,
  content: array,
  incidents: object,
  activeContentId: string,
  selectedServiceAreas: arrayOf(object),
  onServiceAreaSelect: func,
}

export default QueuePane
