import { useState, useEffect } from "react"

import { ServiceAreaService } from "@guardian/Services/ServiceArea";

interface CitywideArea {
  name: string
  value: string
}

export default function useCitywideAreas() {
  const [citywideAreas, setCitywideAreas] = useState<{ [k: string]: string }>(
    {},
  )

  useEffect(() => {
    ServiceAreaService.getGeographyPushes().then((config: any) => {
      if (!config) return
      let areas: any = config
        .filter((area: CitywideArea) => area.name.includes("Service Area"))
        .reduce(
          (acc: any, area: any) => ({
            ...acc,
            [area.value.replace("service_areas:code:", "")]: area.name.replace(
              "Service Area: ",
              "",
            ),
          }),
          {},
        )
      setCitywideAreas(areas)
    })
  }, [])

  return citywideAreas
}
