import { OptimusAPI } from "@guardian/API/Optimus";

const Geo = {
  getPrecincts: (lat: number, long: number, options: any = {}) => {
    return OptimusAPI.get(
      `/geo/precincts`,
      {
        ...options,
        params: {
          ...options.params,
          lat,
          long
        }
      }
    );
  },

  getNeighborhoods: (lat: number, long: number, options: any = {}) => {
    return OptimusAPI.get(
      `/geo/neighborhoods`,
      {
        ...options,
        params: {
          ...options.params,
          lat,
          long
        }
      }
    );
  },

  getGeoJson: (type: string, name: string, code: string, options: any = {}) => {
    return OptimusAPI.get(
      `/geo/geojson`,
      {
        ...options,
        params: {
          ...options.params,
          type,
          name,
          code
        }
      }
    );
  }
};

export default Geo;
