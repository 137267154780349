import React from "react";

import StyledMessageStatus from "./views/MessageStatus";

const MessageStatus = React.memo(function MessageStatus(props) {
  const {
    session: {
      isTyping,
      unreadStatus
    }
  } = props;

  const renderUnreadStatus = () => {
    let status = "";
    switch (unreadStatus) {
      case "chatStarted":
        status = "Chat Started";
        break;
      case "sentMessage":
        status = "Sent a Message";
        break;
      case "shieldActivated":
        status = "Shield Activated";
        break;
      default:
        status = "";
    }

    return status;
  };

  const renderStatus = () => {
    // If the user is typing, let this take priority for the shown message
    // status, else fall back to the unread status.
    let status = "";
    if (isTyping) {
      status = "typing...";
    } else {
      status = renderUnreadStatus();
    }

    return status;
  };

  return (
    <StyledMessageStatus>
      { renderStatus() }
    </StyledMessageStatus>
  );
});

export default MessageStatus;
