import React from "react"

const LoadingScreen = () => {
  return (
    <div className='loading-container'>
      <div className='loading-banner'>
        <img src='/ProtectOS-Loading.svg' className='citizen-logo' alt='' />
      </div>
    </div>
  )
}

export default LoadingScreen
