import classnames from "classnames"
import { object } from "prop-types"

import consts from "@guardian/Constants"
import { useGlobalState } from "@guardian/State"

import style from "./Badge.module.css"

const OnAirBadge = () => {
  return (
    <div className={classnames(style.badgeBase, style.onAirBadge)}>
      <img alt="" src='/StreetTeamSymbol.svg' />
      ONAIR
    </div>
  )
}

const StreetTeamBadge = () => {
  return (
    <div className={classnames(style.badgeBase, style.streetTeamBadge)}>
      <img alt="" src='/StreetTeamSymbol.svg' />
      TEAM
    </div>
  )
}

const IGLIncident = () => {
  return (
    <div className={classnames(style.badgeBase, style.iglIncident)}>
      <img alt="" src='/IGL-Incident.svg' />
      IGL - Incident
    </div>
  )
}

const IGLGoodVibes = () => {
  return (
    <div className={classnames(style.badgeBase, style.iglGoodVibe)}>
      <img alt="" src='/IGL-GoodVibes.svg' />
      IGL - Good Vibes
    </div>
  )
}

const Badge = ({ stream }) => {
  const streetTeamUsers = useGlobalState(state => state.global.streetTeamUsers)

  const userIsStreetTeam = streetTeamUsers.includes(stream.userId)
  const isSignal = !stream.incidentId
  const isOnAir = stream.onAir
  const isGoodVibe = stream.reportCategory === consts.GoodVibeCategory

  if (!(userIsStreetTeam || isSignal || isOnAir)) {
    return null
  }

  if (isOnAir) {
    return <OnAirBadge />
  }

  if (userIsStreetTeam) {
    return <StreetTeamBadge />
  }

  if (isGoodVibe) {
    return <IGLGoodVibes />
  }

  return <IGLIncident />
}

Badge.propTypes = {
  stream: object,
}

export default Badge
