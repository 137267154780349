import React, { useState, memo } from "react"
import style from "./PhotoMenu.module.css"
import { string, func, bool } from "prop-types"

import { Users } from "@guardian/API/Optimus";
import { ContentService } from "@guardian/Services/Content";

import ConfirmModal from "@guardian/Components/Modals/ConfirmModal"

const PhotoMenu = ({ id, userId, onReject, username, igl }) => {
  const [confirmProps, setConfirmProps] = useState(null)
  const blockBroadcast = async () => {
    try {
      await ContentService.rejectContent(
        "image",
        id,
        "",
        { reason: "Broadcast blocked." }
      )
      await Users.updateUserPermissions(
        userId,
        { permissions: { blockedBroadcast: 1 } }
      )
      onReject(id)
    } catch (e) {
      console.error(e)
    }
  }

  const ban = async () => {
    try {
      await ContentService.rejectContent(
        "image",
        id,
        "",
        { reason: "User banned." }
      )
      await Users.updateUserPermissions(
        userId,
        { permissions: { banned: 1 } }
      )
      onReject(id)
    } catch (e) {
      console.error(e)
    }
  }

  const styles = !igl
    ? { position: "absolute", right: "11px", bottom: "11px" }
    : {}

  return (
    <div className={style.container} style={styles}>
      <button
        onClick={() => {
          setConfirmProps({
            text: `Are you sure you want to ban ${username}?`,
            onConfirm: ban,
            onCancel: () => setConfirmProps(null),
          })
        }}
      >
        Ban User
      </button>
      <button
        onClick={() => {
          setConfirmProps({
            text: `Are you sure you want to block broadcast access for ${username}?`,
            onConfirm: blockBroadcast,
            onCancel: () => setConfirmProps(null),
          })
        }}
      >
        Block Broadcast Access
      </button>
      {confirmProps && <ConfirmModal {...confirmProps} />}
    </div>
  )
}

PhotoMenu.propTypes = {
  id: string,
  userId: string,
  onReject: func,
  username: string,
  igl: bool,
}

export default memo(PhotoMenu)
