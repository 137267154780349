// TODO: Remove ts-ignore comments and update UI components to use TypeScript
import React from "react"

import { useGlobalState } from "@guardian/State"

import { Select } from "@guardian/UI/Legacy/Select"
import { IconButton } from "@guardian/UI/Button"
import { Icon } from "@guardian/UI/Icon"

import style from "./CategoryInput.module.css"

interface IProps {
  onSetCategory: (displayName: string, manual: boolean) => void
  disabled: boolean
  category: string
  categories: Array<string>
  manualCategorySet?: boolean
  toggleManualCategorySet?: () => void
}

const CategoryInput = ({
  onSetCategory,
  disabled,
  category,
  categories,
  manualCategorySet,
  toggleManualCategorySet,
}: IProps) => {
  const readOnly = useGlobalState(state => state.global.readOnly)

  const options = (categories || []).map(cat => {
    return {
      label: cat,
      value: cat,
    }
  })

  return (
    <div className={style.container}>
      <div className={style.selectContainer}>
        <Select
          disabled={disabled || readOnly}
          onChange={e => onSetCategory(e.target.value as string, true)}
          options={options}
          value={category || "N/A"}
          label='Category'
        />
      </div>
      {!!toggleManualCategorySet && (
        // @ts-ignore
        <IconButton
          onClick={toggleManualCategorySet}
          size='small'
          outline={false}
          disabled={disabled || readOnly}
          tightenX
          tightenY
        >
          {/* @ts-ignore */}
          {manualCategorySet ? <Icon.SyncDisabled /> : <Icon.Sync />}
        </IconButton>
      )}
    </div>
  )
}

export default CategoryInput
