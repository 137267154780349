import React, { useContext, useEffect, useState } from "react"
import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import classnames from "classnames"
import _ from "lodash"
import OT from "opentok-react"
import { OTPublisher } from "@guardian/Components/ModSOS/components/Video/OTP/OTPublisher"
import { TwilioSubscriber } from "@guardian/Components/ModSOS/components/Video/Twillio/TwilioSubscriber"
import { SOSVideoSubscriber } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideoSubscriber"
import { TwilioPublisher } from "@guardian/Components/ModSOS/components/Video/Twillio/TwilioPublisher"
import SOSSessionContext from "@guardian/Components/Contexts/SOSSessionContext"
import { SOSVideoMain } from "@guardian/Components/ModSOS/components/Video/SOSVideoMain/SOSVideoMain"
import { SOSVideoCallbackPrompt } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideoCallbackPrompt"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { SOS_SESSION_MISSED } from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.helper"
import { useSOSVideoContext } from "@guardian/Components/ModSOS/store/video/VideoContext"
import { IProps } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.types"
import { update911InSession } from "@guardian/Components/ModSOS/store/dashboard/actions/dashboard"
import { Debugger } from "@guardian/Utils/debugger"

const videoDebugger = new Debugger("SOSVideo")

// TODO: rename ownerData to props and refactor
export const SOSVideo: React.FunctionComponent<IProps> = ownerData => {
  videoDebugger.startMeasure()

  const userDisconnected = useContext(SOSSessionContext)
  const { state, dispatch } = useSOSDashboardContext()
  const { state: videoState } = useSOSVideoContext()
  const [showCallbackPrompt, setShowCallbackPrompt] = useState(false)

  useEffect(() => {
    dispatch(update911InSession(!_.isEmpty(videoState.tokbox911Stream)))
  }, [videoState.tokbox911Stream, dispatch])

  const createAgentStream: () => JSX.Element = () => {
    if (videoState.videoProvider === "tokbox") {
      return (
        <OTPublisher
          tokboxSession={videoState.tokboxSession}
          videoDeviceId={videoState.videoDeviceId}
          userDisabledAgentAudio={videoState.userDisabledAgentAudio}
          userDisabledAgentVideo={videoState.userDisabledAgentVideo}
        />
      )
    } else if (videoState.videoProvider === "twilio") {
      return (
        <TwilioPublisher
          twilioRoom={videoState.twilioRoom}
          userDisabledAgentAudio={videoState.userDisabledAgentAudio}
          userDisabledAgentVideo={videoState.userDisabledAgentVideo}
        />
      )
    } else {
      return <React.Fragment />
    }
  }

  const get911Stream: () => JSX.Element = () => {
    if (
      videoState.videoProvider === "twilio" &&
      !_.isEmpty(videoState.twilioDialOutParticipant)
    ) {
      return (
        <TwilioSubscriber
          participant={videoState.twilioDialOutParticipant!}
          callToNumber={ownerData.call?.to}
          isUserMicrophoneEnabled={_.get(
            ownerData,
            "ownerData.userSettings.microphone_allowed",
          )}
          isUserCameraEnabled={_.get(
            ownerData,
            "ownerData.userSettings.camera_allowed",
          )}
        />
      )
    } else if (
      videoState.videoProvider === "tokbox" &&
      !_.isEmpty(videoState.tokbox911Stream)
    ) {
      return (
        <OT.OTSubscriber
          properties={{
            showControls: false,
            height: "100%",
            width: "100%",
          }}
          retry={true}
          maxRetryAttempts={3}
          retryAttemptTimeout={2000}
          session={videoState.tokboxSession.session}
          stream={videoState.tokbox911Stream}
          key={videoState.tokbox911Stream.id}
        />
      )
    } else {
      return <React.Fragment />
    }
  }

  const createWatcherStream: (contactNo: number) => JSX.Element = contactNo => {
    const tbStream = _.get(
      videoState.tokboxWatcherStreams,
      contactNo,
      undefined,
    )
    const twilioPart = _.get(videoState.twilioContacts, contactNo, undefined)

    return (
      // TODO need to fix userMicDisabled
      <SOSVideoSubscriber
        tokboxSession={videoState.tokboxSession}
        tokboxStream={tbStream}
        twilioParticipant={twilioPart}
        userMicDisabled={false}
        passthroughProps={{ ownerData: ownerData.ownerData }}
      />
    )
  }

  useEffect(() => {
    const isDroppedCall =
      userDisconnected || !!_.get(state.sessionResp, "error", "").match("404")
    const isMissedCall =
      state.callSession && state.callSession.status === SOS_SESSION_MISSED
    setShowCallbackPrompt(isMissedCall || isDroppedCall)
  }, [userDisconnected, state.callSession])

  if (_.isEqual(videoState.tokbox911Stream, {})) {
    videoDebugger.addBreakPoint("tokbox911Stream")
  }

  if (_.isEqual(videoState.twilioDialOutParticipant, {})) {
    videoDebugger.addBreakPoint("twilioDialOutParticipant")
  }

  if (
    showCallbackPrompt &&
    _.isEqual(videoState.tokbox911Stream, {}) &&
    _.isEqual(videoState.twilioDialOutParticipant, {})
  ) {
    videoDebugger.addBreakPoint("loadingDisappeared")
    videoDebugger.logResult(
      "tokbox911Stream or twilioDialOutParticipant loaded",
    )
    return (
      <div className={styles.container} key={state.activeSessionId}>
        <SOSVideoCallbackPrompt />
      </div>
    )
  }

  if (!_.isEmpty(videoState.tokboxSession)) {
    videoDebugger.addBreakPoint("tokboxSession")
  }

  if (!_.isEmpty(videoState.twilioRoom)) {
    videoDebugger.addBreakPoint("twilioRoom")
  }

  if (
    !_.isEmpty(videoState.tokboxSession) ||
    !_.isEmpty(videoState.twilioRoom)
  ) {
    videoDebugger.addBreakPoint("loadingDisappeared")
    videoDebugger.logResult("tokboxSession or twilioRoom loaded")
    return (
      <div className={styles.container} key={state.activeSessionId}>
        <div className={styles.flexRow}>
          {showCallbackPrompt ? (
            <SOSVideoCallbackPrompt />
          ) : (
            <SOSVideoMain
              userDisconnected={userDisconnected}
              ownerData={{ ownerData: ownerData.ownerData }}
            />
          )}

          <div className={classnames(styles.fillFlex, styles.flexCol)}>
            <div className={styles.agent}>
              <img alt='' src='/head-guide.svg' className={styles.headGuide} />
              {createAgentStream()}
            </div>
            <div
              className={classnames(
                styles.fillFlex,
                styles.flexRow,
                styles.contact,
              )}
            >
              <div
                className={classnames(
                  styles.halfWidth,
                  styles.flexCol,
                  styles.borderRight,
                )}
              >
                <div
                  className={classnames(
                    styles.halfHeight,
                    styles.borderBottom,
                    styles.contactGeneral,
                  )}
                >
                  {createWatcherStream(0)}
                </div>
                <div
                  className={classnames(
                    styles.fillFlex,
                    styles.contactSW,
                    styles.contactGeneral,
                  )}
                >
                  {createWatcherStream(2)}
                </div>
              </div>
              <div className={classnames(styles.fillFlex, styles.flexCol)}>
                <div
                  className={classnames(
                    styles.halfHeight,
                    styles.borderBottom,
                    styles.contactGeneral,
                  )}
                >
                  {createWatcherStream(1)}
                </div>
                <div
                  className={classnames(
                    styles.fillFlex,
                    styles.contact911,
                    styles.contactGeneral,
                  )}
                >
                  {(!_.isEqual(videoState.tokbox911Stream, {}) ||
                    !_.isEqual(videoState.twilioDialOutParticipant, {})) &&
                    get911Stream()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container} key={state.activeSessionId}>
      <div>Loading</div>
    </div>
  )
}
