import React, { useState } from "react"
import Modal from "@guardian/Components/Modals/Modal"
import style from "./IncidentModal.module.css"
import TextInput from "@guardian/Components/Form/Inputs/TextInput"
import { func } from "prop-types"
import { first } from "lodash"
import NearbyIncident from "@guardian/Components/NearbyIncident/NearbyIncident"

import { IncidentService } from "@guardian/Services/Incident";

const IncidentModal = ({ onClose, onMerge }) => {
  const [id, setId] = useState("")
  const [incident, setIncident] = useState(null)
  const searchIncident = async () => {
    const data = await IncidentService.getIncidents({ incidentIds: [id] })
    setIncident(first(data))
  }

  return (
    <Modal>
      <div className={style.modal}>
        <h4>Enter Incident</h4>
        <div className={style.container}>
          <h5 className={style.label}>Incident ID</h5>
          <div className={style.input}>
            <TextInput
              onChange={val => setId(val)}
              placeholder='Incident ID...'
              defaultValue={""}
              key='title'
              value={id || ""}
              onBlur={searchIncident}
            />
            {id && (
              <button className={style.search} onClick={searchIncident}>
                <i className='fas fa-search'></i>
              </button>
            )}
          </div>
        </div>
        {incident && (
          <NearbyIncident
            key={incident.id}
            address={incident.location.address}
            title={incident.title}
            distance={incident.distance}
            time={incident.time}
            id={incident.id}
            onMerge={() => onMerge(incident)}
            merging={false}
          />
        )}
        {!!onClose && (
          <button className={style.close} onClick={onClose}>
            <i className='fas fa-times' />
          </button>
        )}
      </div>
    </Modal>
  )
}

IncidentModal.propTypes = {
  onClose: func.isRequired,
  onMerge: func.isRequired,
}

export default IncidentModal
