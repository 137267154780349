import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const ChatDetails = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__chat-details"
      )
    };
  }
)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  padding: 0 0 var(--space-xx-small) var(--space-small);
`;

ChatDetails.propTypes = {};

ChatDetails.defaultProps = {};

export default ChatDetails;
