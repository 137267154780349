import React from "react"
import { ResizableBox } from "react-resizable"
import classnames from "classnames"
import Container from "@guardian/Components/Common/Container"
import { AgentScriptSection } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptSection/AgentScriptSection"
import styles from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/AgentScripts.module.css"
import { SearchBox } from "@guardian/Components/ModSOS/components/InfoPane/SearchBox/SearchBox"
import { AgentScriptsButton } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsButton/AgentScriptsButton"
import { AgentScriptNote } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptNote/AgentScriptNote"
import { AgentScriptZingtree } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptZingtree/AgentScriptZingtree"
import { TScriptType } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"
import { useAgentScripts } from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/AgentScripts.hooks"
import { IProps } from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/types"

export const TYPES_MAPPING = {
  [TScriptType.Section]: {
    Component: AgentScriptSection,
  },
  [TScriptType.Note]: {
    Component: AgentScriptNote,
  },
  [TScriptType.Zingtree]: {
    Component: AgentScriptZingtree,
  },
}

const AgentScriptsRaw = ({
  onClose,
  width,
  height,
  onAction,
  sessionId,
  onResize,
}: IProps) => {
  const {
    setSearching,
    setQuery,
    searchMatches,
    setActiveSearch,
    searching,
    scripts,
    matches,
    activeSearch,
  } = useAgentScripts(sessionId)

  return (
    <ResizableBox
      width={width}
      height={height}
      handle={handle => {
        return <div className={classnames(styles.resize, styles[handle])}></div>
      }}
      resizeHandles={["sw", "s", "se"]}
      axis='both'
      onResize={onResize}
      minConstraints={[500, 500]}
    >
      <Container
        column
        fullWidth
        fill
        leftJustify
        className={styles.container}
        bottom={1}
        topAlign
        fullHeight
      >
        <div
          style={{ cursor: "pointer", width: "100%" }}
          className='drag-handle'
        >
          <Container
            fullWidth
            leftJustify
            className={styles.heading}
            bottom={2}
            top={2}
            left={2}
            right={2}
          >
            <Container right={3} left={2}>
              Scripts
            </Container>
            <SearchBox
              onSearch={(val: string) => {
                setSearching(true)
                setQuery(val)
              }}
              onSearchEnd={() => {
                setSearching(false)
              }}
              searchMatches={searchMatches}
              onActiveSearchChange={search => setActiveSearch(search)}
            />
            {!searching && (
              <Container left={2}>
                <AgentScriptsButton onClick={onClose} src='close' />
              </Container>
            )}
          </Container>
        </div>
        <Container
          className={styles.scripts}
          column
          leftJustify
          fullWidth
          topAlign
          left={2}
          right={2}
          fill
        >
          {scripts.map(script => {
            const { Component } = TYPES_MAPPING[script.type] || {}

            if (!Component) {
              return null
            }

            const s = script as any
            return (
              <Component
                {...s}
                matches={searching ? matches : undefined}
                activeSearch={searching ? activeSearch : undefined}
                onAction={onAction}
                key={script.id}
                sessionId={sessionId}
              />
            )
          })}
        </Container>
      </Container>
    </ResizableBox>
  )
}

export const AgentScripts = React.memo(AgentScriptsRaw)
