import { Message } from "./types"

export const getAutomatedMessages = (
  username: string,
  messages: { nowLiveMessage: string; automatedModMessage: string },
) => {
  const AUTOMATED_MESSAGES: Message[] = [
    {
      text: messages.nowLiveMessage.replace("%username%", username),
      sender: "system",
      secondsSinceStart: 1,
    },
    {
      text: messages.automatedModMessage,
      sender: "system",
      secondsSinceStart: 4,
    },
  ]
  return AUTOMATED_MESSAGES
}
