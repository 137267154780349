import classNames from "classnames";
import styled from "styled-components";

const Tabs = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__tabs")
    }
  }
)`
  background: var(--colors-black);
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
`;

export default Tabs;
