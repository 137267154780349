import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles"
import _ from "lodash"
import { bool, func, object, string } from "prop-types"
import React, { Component, forwardRef } from "react"
import Autosuggest from "react-autosuggest"

import { useGlobalState } from "@guardian/State"

import { Signal } from "@guardian/API/Optimus"
import { GeocodingService } from "@guardian/Services/Geocoding"

import { withErrorBoundary } from "@guardian/Components/ErrorBoundary"
import EndAdornmentButton from "@guardian/Components/ModUpdateInputs/EndAdornmentButton"

import "./styles.scss"

const Input = styled(TextField)`
  width: 100%;
  color: white !important;

  .MuiInputBase-root {
    height: 42px;
    width: 100%;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 12px;
    color: white !important;
    border-color: #333333;
    margin-top: 2px;
  }

  & input.MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: #8c8c8c !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
  }

  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
    color: #8c8c8c !important;
  }

  &:hover &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
  }

  & label {
    visibility: visible;
    opacity: 1;
    font-weight: 540;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13.5px;
    line-height: 16px;
    color: #8c8c8c !important;
    padding-top: 3px;
  }
`

class LocationSuggest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.initialValue || "",
      suggestions: [],
      useFuzzySearch: false,
    }
  }
  clear() {
    this.setState({ value: "", suggestions: [], useFuzzySearch: false })
  }

  getPlaces() {
    return window.google.maps.places
  }
  initializeServices() {
    const p = this.getPlaces()
    this.autocomplete = new p.AutocompleteService()
    this.sessionToken = new p.AutocompleteSessionToken()
    this.placesService = new p.PlacesService(document.createElement("div"))
    this.geocoder = new window.google.maps.Geocoder()
  }
  componentDidUpdate(prevProps) {
    const { initialValue, mapInit } = this.props

    if (prevProps.initialValue !== initialValue) {
      this.setState({ value: initialValue })
    }

    if (mapInit && !prevProps.mapInit) {
      this.initializeServices()
    }

    if (prevProps.useFuzzySearch !== this.props.useFuzzySearch) {
      this.setState({ useFuzzySearch: this.props.useFuzzySearch })
    }
  }
  componentDidMount() {
    if (this.props.mapInit) {
      this.initializeServices()
    }
  }
  async fetchLocationSuggestions(value) {
    let boostLocation
    let channel
    const attached = this.props.attachedClips
      ? this.props.attachedClips.toArray()
      : []
    if (attached.length > 0) {
      channel = this.props.channels[attached[0].channel]
      if (channel.centerLat && channel.centerLng) {
        boostLocation = {
          lat: channel.centerLat,
          lng: channel.centerLng,
        }
      } else {
        const serviceArea = this.props.serviceAreas[channel.serviceArea]
        if (serviceArea && serviceArea.center) {
          boostLocation = {
            lat: serviceArea.center[1],
            lng: serviceArea.center[0],
          }
        }
      }
    }

    if (this.state.useFuzzySearch) {
      const candidates = await GeocodingService.emergencyGeocode(
        this.state.value,
        boostLocation ? parseFloat(boostLocation.lat) : undefined,
        boostLocation ? parseFloat(boostLocation.lng) : undefined,
      )
      return candidates.map(c => {
        c.location.lat = c.location.y
        c.location.lng = c.location.x
        return {
          type: "geocoded",
          suggestion: c.address,
          location: c.location,
          attributes: c.attributes,
          address: c.address,
        }
      })
    } else {
      const {
        data: { suggestions },
      } = await Signal.getIncidentLocationSuggestions(
        value,
        channel?.id,
        channel?.serviceArea,
        boostLocation ? parseFloat(boostLocation.lat) : undefined,
        boostLocation ? parseFloat(boostLocation.lng) : undefined,
      )
      if (suggestions) {
        return suggestions
          .filter(s => !!s)
          .map(s => {
            return {
              type: "address",
              suggestion: s.text,
              magicKey: s.magicKey,
            }
          })
      }
    }

    return []
  }

  async geocodeSuggestion(suggestion) {
    const {
      data: { candidates },
    } = await Signal.getGeocodeLocationSuggestions(
      suggestion.suggestion,
      suggestion.magicKey,
    )

    const candidate = candidates[0]

    if (candidate.attributes.Addr_type === "POI") {
      candidate.address = candidate.attributes.Place_addr
    }
    const { location } = candidate
    // Convert to lat/lng pair for interoperability with other code
    location.lat = location.y
    location.lng = location.x

    this.props.onSelect({
      type: "address",
      value: candidate,
    })
  }

  async fetchFeatureSuggestions(value) {
    const features = await GeocodingService.queryFeatureLayer(
      this.props.featureId,
      value,
    )
    return features.map(feature => ({
      suggestion: feature.attributes.search_field,
      type: "feature",
      ...feature.attributes,
      location: {
        lat: feature.attributes.latitude,
        lng: feature.attributes.longitude,
      },
    }))
  }

  fetchSuggests = async ({ value }) => {
    let results = []
    const { suggestionProviders } = this.props
    const addressOnly = _.isEmpty(suggestionProviders)
    for (let provider in suggestionProviders) {
      const sugs = await suggestionProviders[provider](value)
      if (sugs.length > 0) {
        results.push({ title: provider, sugs })
      }
    }

    const esriSugs = this.props.featureLayer
      ? await this.fetchFeatureSuggestions(value)
      : await this.fetchLocationSuggestions(value)
    if (esriSugs.length > 0) {
      if (addressOnly) {
        results = esriSugs
      } else {
        results.push({
          title: "Address",
          sugs: esriSugs,
        })
      }
    }

    this.setState({ suggestions: results })
  }
  clearSuggests = () => {
    this.setState({ suggestions: [] })
  }
  getSugValue = sug => sug.suggestion
  renderSug = sug => <div>{sug.suggestion}</div>

  onChange = (e, { newValue }) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(newValue)
    }
    this.setState({ value: newValue })
  }
  onKey = async e => {
    const { stopPropagation } = this.props

    if (stopPropagation) {
      e.stopPropagation()
    }
  }
  renderSec = sec => <div>{sec?.title}</div>
  getSecSugs = sec => sec?.sugs || []
  onSelectSug = (e, { suggestion }) => {
    if (suggestion?.type === "address") {
      this.geocodeSuggestion(suggestion)
    } else {
      this.props.onSelect({ type: suggestion.type, value: suggestion })
    }
  }
  focus = () => {
    this.input.focus()
  }

  renderInputComponent = ({ inIncidentCreation, ...inputProps }) => (
    <div style={{ width: "100%" }}>
      <Input
        label={inIncidentCreation ? "Address or Location" : "Search"}
        {...inputProps}
        inputProps={{ ref: input => (this.input = input) }}
        // Gross hack to stop autofilling, idk why it wouldnt work before
        autoComplete='new-password'
        InputProps={{
          notched: true,
          endAdornment: inIncidentCreation && (
            <div style={{ width: "24px", height: "24px" }}>
              <EndAdornmentButton
                label='Fs'
                active={this.state.useFuzzySearch}
                onClick={this.props.onFuzzyChange}
              />
            </div>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        variant='outlined'
      />
    </div>
  )

  throttledSuggest = _.throttle(this.fetchSuggests, 250)

  render() {
    const { value, suggestions } = this.state
    const {
      disabled,
      autoFocus,
      placeholder,
      mapInit,
      suggestionProviders,
      location,
    } = this.props

    if (!mapInit) {
      return null
    }

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.fetchSuggests}
        onSuggestionsClearRequested={this.clearSuggests}
        onSuggestionSelected={this.onSelectSug}
        getSuggestionValue={this.getSugValue}
        renderSuggestion={this.renderSug}
        inputProps={{
          value,
          onChange: this.onChange,
          onKeyDown: this.onKey,
          placeholder: placeholder || "search for location or precinct",
          disabled,
          inIncidentCreation: location === "IncidentCreation",
          autoFocus,
        }}
        renderSectionTitle={this.renderSec}
        getSectionSuggestions={this.getSecSugs}
        multiSection={!_.isEmpty(suggestionProviders)}
        highlightFirstSuggestion
        renderInputComponent={this.renderInputComponent}
        focusInputOnSuggestionClick={false}
      />
    )
  }
}

LocationSuggest.propTypes = {
  bounds: object,
  onSelect: func,
  disabled: bool,
  autoFocus: bool,
  initialValue: string,
  placeholder: string,
  featureLayer: bool,
  featureId: string,
  stopPropagation: bool,
  onChange: func,
  suggestionProviders: object,
  attachedClips: object,
  useFuzzySearch: bool,
  onFuzzyChange: func,
  location: string,
}

const LocationSuggestHOC = forwardRef((props, ref) => {
  const stateProps = useGlobalState(state => {
    const {
      mapInit,
      serviceAreaConfig: { serviceAreas },
    } = state.global
    const { channels } = state.radio
    return { mapInit, serviceAreas, channels }
  })

  return (
    <LocationSuggest
      ref={ref}
      {...props}
      {...stateProps}
    />
  )
})
LocationSuggestHOC.displayName = "LocationSuggestHOC"

export default withErrorBoundary(LocationSuggestHOC, {
  domain: "LocationSuggest",
  method: "LocationSuggestComponent",
})
