export enum AuditToolEntryType {
  TITLE_UPDATE,
  ADDRESS,
}

export interface AuditToolEntryKey {
  clipId: string
  incidentId: string
}

interface BaseAuditToolEntry extends AuditToolEntryKey {
  auditedBy: string
  createdAt: string
  updatedAt: string
}

export interface AuditToolTitleUpdateEntry extends BaseAuditToolEntry {
  // CSV Data
  title: string
  update: string
  // Audit Data
  isInaccurate: boolean
  isPrivacyViolated: boolean
  isBadCopy: boolean
  isNotReportable: boolean
}

export interface AuditToolAddressEntry extends BaseAuditToolEntry {
  // CSV Data
  address: string
  displayLocation: string
  aaAddress: string
  // Audit Data
  isIncorrect: boolean
}

export type AuditToolEntryTypeMap = {
  [T in AuditToolEntryType]: {
    [AuditToolEntryType.TITLE_UPDATE]: AuditToolTitleUpdateEntry
    [AuditToolEntryType.ADDRESS]: AuditToolAddressEntry
  }[T]
}

export type AuditToolEntry = AuditToolEntryTypeMap[keyof AuditToolEntryTypeMap]
