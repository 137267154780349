import React, { useEffect, useState } from "react"
import { func, object } from "prop-types"
import { OutlinedInput } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"
import dayjs from "dayjs"

import { inputStyles } from "./MergeIncidentInput.styles"
import style from "./MergeIncidentInput.module.css"

import { IncidentService } from "@guardian/Services/Incident";

const StyledInput = withStyles(inputStyles)(OutlinedInput)

const MergeIncidentInput = ({ setMerging, mergingIncident }) => {
  const [id, setId] = useState("")
  const [incident, setIncident] = useState(undefined)

  useEffect(() => {
    const getIncident = async incidentId => {
      const data = await IncidentService.getIncidents({ incidentIds: [incidentId] })
      if (data.length > 0) {
        setIncident(data[0])
      }
    }
    if (id) {
      getIncident(id)
    }
  }, [id])

  return (
    <>
      <StyledInput
        label='Incident ID'
        notched
        value={id}
        onChange={e => setId(e.target.value)}
      />
      {incident && (
        <div className={style.container}>
          <div className={style.title}>{incident.title}</div>
          <div className={style.location}>{incident.location.address}</div>
          <div className={style.location}>{incident.location.neighborhood}</div>
          <div className={style.date}>
            {dayjs(incident.updatedAt).format("MMM D, h:mm A")}
          </div>
          <button
            className={classnames(style.mergeButton, {
              [style.on]: mergingIncident && mergingIncident.id === incident.id,
            })}
            onClick={() => {
              if (mergingIncident && mergingIncident.id === incident.id) {
                setMerging({})
              } else {
                setMerging(incident)
              }
            }}
          >
            Merge
          </button>
        </div>
      )}
    </>
  )
}

MergeIncidentInput.propTypes = {
  setMerging: func,
  mergingIncident: object,
}

export default MergeIncidentInput
