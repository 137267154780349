import React, { useState } from "react";

import { Tabs, TabList, Tab, TabPanel } from "@guardian/UI/Tabs";

import ActivityTab from "./components/ActivityTab";
import NotesTab from "./components/NotesTab";
import SessionTab from "./components/SessionTab";
import StyledTabsPanel from "./views/TabsPanel";

const TabsPanel = props => {
  const { session } = props;

  const [selectedTab, setSelectedTab] = useState("session");

  return (
    <StyledTabsPanel>
      <Tabs selectedTab={selectedTab} onChange={setSelectedTab}>
        <TabList>
          <Tab tab="session" text="Session Info"/>
{/*          <Tab tab="activity" text="Activity"/>*/}
          <Tab tab="notes" text="Notes"/>
        </TabList>
        <TabPanel tab="session">
          <SessionTab session={session}/>
        </TabPanel>
{/*        <TabPanel tab="activity">
          <ActivityTab session={session}/>
        </TabPanel>*/}
        <TabPanel tab="notes">
          <NotesTab session={session}/>
        </TabPanel>
      </Tabs>
    </StyledTabsPanel>
  );
};

export default TabsPanel;
