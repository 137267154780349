import styled from "styled-components";

const StatusItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`;

export default StatusItem;
