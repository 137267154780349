import classnames from "classnames"
import React, { useMemo } from "react"

import { useGlobalState } from "@guardian/State"
import type { ClipMetadata } from "@guardian/Types/Clip"

import { hasKeyword } from "@guardian/Utils/util"

import "./ClipPriority.scss"

interface ClipPriorityProps {
  raw?: ClipMetadata["raw"]
  isMedicalIncident: boolean
}

const ClipPriority: React.FC<ClipPriorityProps> = ({
  raw,
  isMedicalIncident,
}) => {
  const highPriorityKeywords = useGlobalState(
    state => state.global.clipPriorityConfig.highPriorityKeywords,
  )

  const transcriptionText = useMemo<string>(() => {
    if (!raw) {
      return ""
    }

    try {
      const parsedRaw = JSON.parse(raw)
      return parsedRaw?.transcription?.text || ""
    } catch (e) {
      return ""
    }
  }, [raw])

  const isHighPriority = useMemo<boolean>(
    () => hasKeyword(transcriptionText, highPriorityKeywords),
    [transcriptionText, highPriorityKeywords],
  )

  if (!isHighPriority && !isMedicalIncident) {
    return null
  }

  return (
    <div className='clip-priority-container'>
      <div
        className={classnames("clip-priority-indicator", {
          "clip-priority-indicator--high": isHighPriority,
          "clip-priority-indicator--medical": isMedicalIncident,
        })}
      />
    </div>
  )
}

export default React.memo(ClipPriority)
