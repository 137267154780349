import React, { useRef, useEffect, HTMLAttributes } from "react";
import PropTypes from "prop-types";
import { Reference } from "react-popper";

import { Button, ButtonIcon } from "@guardian/UI/Button";

import useDropdownContext from "../../hooks/useDropdownContext";
import StyledInput from "../../views/Input";
import StyledToggle from "../../views/ToggleButton/Toggle";

// Applies state and a11y attributes to its children. Must be nested within a
// `<Dropdown>` component.
const ToggleButton = ({ children, refKey, ...buttonProps }) => {
  const {
    downshift: {
      getMenuProps,
      getRootProps,
      getToggleButtonProps,
      getInputProps,
      isOpen
    }
  } = useDropdownContext();

  const hiddenInputRef = useRef(null);
  const triggerRef = useRef(null);
  const previousIsOpenRef = useRef(undefined);

  useEffect(() => {
    // Focus internal input when Menu is opened
    if (isOpen && !previousIsOpenRef.current) {
      hiddenInputRef.current && hiddenInputRef.current.focus();
    }

    // Focus trigger when Menu is closed
    if (!isOpen && previousIsOpenRef.current) {
      triggerRef.current && triggerRef.current.focus();
    }

    previousIsOpenRef.current = isOpen;
  }, [isOpen]);

  const renderButton = (popperProps) => {
    // Destructuring the `ref` argument lets us share it with PopperJS
    const { ref: rootPropsRefCallback, ...rootProps } = getRootProps();

    // Provide:
    //  - Necessary downshift props
    //  - Ref collector based on `refKey` prop
    const props = {
      ...getToggleButtonProps({
        ...rootProps,
        // Trigger usages do no include an associated label
        "aria-labelledby": undefined,
        ...buttonProps
      }),
      [refKey]: childRef => {
        // Pass ref to popperJS for positioning
        popperProps.ref(childRef);

        // Store ref locally to return focus on close
        triggerRef.current = childRef;

        // Pass ref to getRootProps()
        rootPropsRefCallback(childRef);
      }
    };

    return (
      <Button {...props}>
        { children }
        <ButtonIcon.End>
          <StyledToggle isOpen={isOpen}/>
        </ButtonIcon.End>
      </Button>
    )
  };

  return (
    <Reference>
      {popperProps => (
        <React.Fragment>
          { renderButton(popperProps) }
          <StyledInput
            {
              ...getInputProps({
                readOnly: true,
                isHidden: true,
                tabIndex: -1,
                ref: hiddenInputRef,
                value: ""
              })
            }
          />
        </React.Fragment>
      )}
    </Reference>
  );
};

ToggleButton.displayName = "ToggleButton";

ToggleButton.propTypes = {
  ...Button.propTypes,
  children: PropTypes.any,
  refKey: PropTypes.string
};

ToggleButton.defaultProps = {
  ...Button.defaultProps,
  refKey: "ref"
};

export default ToggleButton;

