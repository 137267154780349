import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const LargeTextStyles = css`
  font-size: var(--font-sizes-large);
  line-height: var(--line-heights-large);
`;

const LargeText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__large-text")
    };
  }
)`
  ${ LargeTextStyles }
`;

LargeText.propTypes = {
  ...Text.propTypes
};

LargeText.defaultProps = {};

export default LargeText;
