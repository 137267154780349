import React, { useContext } from "react";

import { Col } from "@guardian/UI/Grid";
import { SwitchTransition } from "@guardian/UI/SwitchTransition";

import { DMTabContext } from "@guardian/Tabs/ModSOSDirectMessaging";

import Active from "./components/Active";
import Empty from "./components/Empty";

const MessagingPanel = () => {
  const {
    activeAgentChatSession,
    activeAgentChatSessionId
  } = useContext(DMTabContext);

  return (
    <Col
      style={
        {
          background: "var(--colors-grey-085)",
          height: "100%"
        }
      }
    >
      <SwitchTransition
        activeChildKey={
          activeAgentChatSessionId ?
          "active" :
          "not-active"
        }
        transitionMs={MessagingPanel.transitionMs}
      >
        {
          activeAgentChatSessionId ? (
            <Active
              session={activeAgentChatSession}
            />
          ) : (
            <Empty/>
          )
        }
      </SwitchTransition>
    </Col>
  );
};

MessagingPanel.transitionMs = 250;

export default MessagingPanel;
