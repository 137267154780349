import PropTypes from "prop-types"
import React from "react"

import { BadgeContainer, LiveIndicator } from "./styles"

const LiveBadge = ({ slim, ...props }) => (
  <BadgeContainer
    bgColor='linear-gradient(225deg, #EA384D 0%, #D31027 100%)'
    {...props}
  >
    {!slim && <LiveIndicator />}
    Live
  </BadgeContainer>
)

LiveBadge.defaultProps = {
  slim: false
}

LiveBadge.propTypes = {
  slim: PropTypes.bool
}

export default LiveBadge
