import React from "react"
import { string, func, bool, object } from "prop-types"
import LocationSuggest from "@guardian/Components/LocationSuggest"
import MapContext from "@guardian/Components/Contexts/MapContext"

import { suggestionToLocation } from "@guardian/Utils/util"

const LocationInput = ({
  onChange,
  placeholder,
  mapInit,
  defaultValue,
  disabled,
}) => {
  if (!mapInit) {
    return null
  }

  const handleSelectedLocation = async ({ value: suggestion }) => {
    try {
      const locState = await suggestionToLocation(suggestion)
      onChange(locState)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <div>
      <LocationSuggest
        placeholder={placeholder}
        onSelect={handleSelectedLocation}
        initialValue={defaultValue}
        mapInit={mapInit}
      />
    </div>
  )
}

LocationInput.propTypes = {
  label: string,
  onChange: func,
  placeholder: string,
  mapInit: bool,
  area: object,
  defaultValue: string,
  disabled: bool,
}

const Wrapper = props => {
  return (
    <MapContext.Consumer>
      {mapInit => <LocationInput {...props} mapInit={mapInit} />}
    </MapContext.Consumer>
  )
}

export default Wrapper
