import React, { useEffect, useState } from "react";

import { AgentChat } from "@guardian/Lib/QueryClient/hooks";
import { Avatar } from "@guardian/UI/Avatar";
import { Tooltip } from "@guardian/UI/Tooltip";

import MobileStateIndicator from "./views/MobileStateIndicator";
import UserAvatarWrapper from "./views/UserAvatarWrapper";

const getCircleHoleClipPath = (radius) => {
  const inner_path = [];
  const circumference = Math.PI * radius;
  const step = Math.PI * 2 / circumference;

  // Start from top-left corner.
  const start_step = circumference * (5 / 8);

  for (let i = start_step; i < circumference + start_step; i++) {
    const angle = step * i;
    const x = radius * Math.cos( angle );
    const y = radius * Math.sin( angle );

    // NOTE:
    // The 87% here roughly places the circle clip path at the bottom right of
    // our avatars. Not sure that this is the best and most accurate way to do
    // this, but it works. Would be nicer to have it be like "100% minus xyz"
    // to place it more purposefully.
    const str = `
      calc(
        (87%) + ${ x }px
      )
      calc(
        (87%) + ${ y }px
      )
    `;
    inner_path.push( str );
  }

  // Avoid rounding issues.
  inner_path.push( inner_path[ 0 ] );

  return `
    polygon(evenodd,
      /* outer rect */
      0 0,       /* top - left */
      100% 0,    /* top - right */
      100% 100%, /* bottom - right */
      0% 100%,   /* bottom - left */
      0 0,       /* and top - left again */
      ${ inner_path.join( "," ) }
    )
  `;
};

const UserAvatar = React.memo(props => {
  const {
    session: {
      id: sessionId,
      members,
      userId
    }
  } = props;

  const {
    data: records,
    error: recordsFetchError
  } = AgentChat.useGetAssignedChatSessionUserRecords(
    sessionId,
    {
      refetchInterval: 5000,
    }
  );

  const user = members && members[userId] ? members[userId] : {
    fullName: "Unknown User",
    avatarURL: ""
  };

  const record = (records && records.length > 0) ? records[0] : null;
  const presence = record?.presence;

  const [mobileState, setMobileState] = useState(null);

  useEffect(() => {
    if (presence == null) { return; }

    const clipPath = getCircleHoleClipPath(6);

    setMobileState({
      avatarClipPath: clipPath,
      isForegrounded: presence !== "backgroundedApp"
    });

    return () => {
      setMobileState(null);
    };
  }, [presence]);

  return (
    <UserAvatarWrapper
      avatarClipPath={
        mobileState && mobileState.avatarClipPath
      }
    >
      <Avatar
        id={user.userId}
        fullName={user.fullName}
        size={"44px"}
        src={user.avatarURL}
      />
      {
        mobileState && (
          <Tooltip
            content={
              mobileState.isForegrounded ?
                "App in Foreground" :
                "App in Background"
            }
            placement="end"
          >
            <MobileStateIndicator
              isForegrounded={mobileState.isForegrounded}
            />
          </Tooltip>
        )
      }
    </UserAvatarWrapper>
  );
});

export default UserAvatar;
