import classNames from "classnames";
import styled from "styled-components";

import { Col } from "@guardian/UI/Grid"

const TabsPanel = styled(Col).attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__tabs-panel")
    }
  }
)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

TabsPanel.defaultProps = {
  noGutter: true
};

export default TabsPanel;
