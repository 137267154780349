import React, { PureComponent } from "react"
import axios from "axios"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { bool, object, oneOfType, shape, string } from "prop-types"

import { useGlobalState, eventHub } from "@guardian/State"
import { objPick } from "@guardian/Utils/obj-pick"

import Clips from "@guardian/Components/Clips"
import DeviceConfig from "@guardian/Components/DeviceConfig/DeviceConfig"
import IncidentContentMod from "@guardian/Components/IncidentContentMod/IncidentContentMod"
import LiveVideoTab from "@guardian/Components/LiveVideoTab/LiveVideoTab"
import ModIGL from "@guardian/Components/ModIGL"
import ModSOS from "@guardian/Components/ModSOS/ModSOS"
import RejectedStream from "@guardian/Components/RejectedStream/RejectedStream"
import StreamList from "@guardian/Components/StreamList/StreamList"
import AuditTool from "@guardian/Components/AuditTool"
import DataLabeling from "@guardian/Components/DataLabeling/DataLabeling"

import ModSOSDirectMessaging from "@guardian/Tabs/ModSOSDirectMessaging"

import ContentMask from "./ContentMask"
import LoadingScreen from "./LoadingScreen"

class ContentPane extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    const { pathname } = this.props
    if (!pathname.startsWith("/device/") && !pathname.startsWith("/sos/")) {
      const promises = [
        eventHub.emit("setChannels"),
        eventHub.emit("fetchServiceAreaConfig"),
        eventHub.emit("fetch incident tags"),
        eventHub.emit("setupEsri"),
        eventHub.emit("fetchRejectionChoices"),
        eventHub.emit("fetchPOSConfig"),
        eventHub.emit("fetchCreators"),
      ]
      if (pathname.match("/modalpha")) {
        eventHub.emit("unset metapod")
      }

      if (this.props.permissions.citMod || this.props.permissions.radioDesk) {
        if (!pathname.match("/livestreams")) {
          await eventHub.emit("modFetchIncidents")
          eventHub.emit("startUpdatingModIncidents")
        }
      }
      await Promise.all(promises)
    }

    // preload sos beep sound
    axios.get("/sos-beep.mp3")
    this.setState({ loading: false })
  }

  componentWillUnmount() {
    eventHub.emit("stopUpdatingModIncidents")
  }

  render() {
    if (this.state.loading) {
      return <LoadingScreen />
    }

    const { permissions, readOnly } = this.props
    return (
      <div className='content-pane'>
        <Helmet>
          <meta name='viewport' content='user-scalable=1' />
        </Helmet>
        <ContentMask on={!!this.props.dragging} />
        <Switch>
          <Route
            exact
            path='/'
            render={() => {
              if (permissions.citMod || permissions.radioDesk || readOnly) {
                return <Redirect to='/metapod' />
              } else {
                eventHub.emit("setTab", "clips")
                return <Clips />
              }
            }}
          />
          <Route
            exact
            path='/clips'
            render={() => {
              eventHub.emit("setTab", "clips")
              return <Clips />
            }}
          />
          {(permissions.citMod || permissions.lafd) && (
            <Route
              exact
              path='/metapod'
              render={() => {
                eventHub.emit("setTab", "modalpha")
                return <Clips />
              }}
            />
          )}
          {(permissions.citMod || permissions.lafd) &&
            !permissions.radioDesk &&
            !readOnly && (
              <Route
                exact
                path='/psyduck'
                render={() => <ModIGL lafd={permissions.lafd} />}
              />
            )}
          {permissions.citMod && !permissions.radioDesk && !readOnly && (
            <Route
              exact
              path='/content-moderation'
              render={() => <IncidentContentMod />}
            />
          )}
          {permissions.citMod && !permissions.radioDesk && (
            <Route
              exact
              path='/content-moderation/streams/:id'
              render={() => <IncidentContentMod />}
            />
          )}
          {permissions.citMod && !permissions.radioDesk && !readOnly && (
            <Route
              exact
              path='/content-moderation/images/:id'
              render={() => <IncidentContentMod />}
            />
          )}
          {(permissions.citMod || permissions.lafd) &&
            !permissions.radioDesk &&
            !readOnly && (
              <Route
                exact
                path='/psyduck/streams/:id'
                render={() => <ModIGL lafd={permissions.lafd} />}
              />
            )}
          {(permissions.citMod || permissions.lafd) &&
            !permissions.radioDesk &&
            !readOnly && (
              <Route
                exact
                path='/psyduck/images/:id'
                render={() => <ModIGL lafd={permissions.lafd} />}
              />
            )}
          {permissions.citMod && !permissions.radioDesk && (
            <Route
              exact
              path='/livestreams'
              render={() => <LiveVideoTab lafd={permissions.lafd} />}
            />
          )}
          {permissions.citMod && !permissions.radioDesk && !readOnly && (
            <Route
              exact
              path='/streams/:id'
              render={() => <RejectedStream />}
            />
          )}
          {permissions.lafd && (
            <Route exact path='/streams' render={() => <StreamList />} />
          )}
          <Route exact path='/device/:id' render={() => <DeviceConfig />} />
          <Route
            path='/incidents/:id'
            render={routeProps => {
              return (
                <Clips
                  selectedIncident={routeProps.match.params.id}
                  preserveAttachedClips={
                    !!routeProps.location.state &&
                    !!routeProps.location.state.preserveAttachedClips
                  }
                  panOff={
                    !!routeProps.location.state &&
                    !!routeProps.location.state.panOff
                  }
                />
              )
            }}
          />
          {permissions.citMod &&
            !permissions.radioDesk &&
            permissions.sosMod && (
              <Route
                path='/sos_dms/:sessionId/:tab'
                render={() => <ModSOSDirectMessaging />}
              />
            )}
          {permissions.citMod &&
            !permissions.radioDesk &&
            permissions.sosMod && (
              <Route path='/sos_dms' render={() => <ModSOSDirectMessaging />} />
            )}
          {permissions.citMod &&
            !permissions.radioDesk &&
            permissions.sosMod && (
              <Route path='/sos/:id' render={() => <ModSOS />} />
            )}
          {permissions.citMod &&
            !permissions.radioDesk &&
            permissions.sosMod && (
              <Route path='/sos' render={() => <ModSOS />} />
            )}

          {permissions.dataLabeling && (
            <Route
              path='/data_labeling'
              render={() => {
                eventHub.emit("setTab", "data_labeling")
                return <DataLabeling />
              }}
            />
          )}

          {permissions.qaTool && (
            <Route
              path='/audit_tool'
              render={() => {
                eventHub.emit("setTab", "audit_tool")
                return <AuditTool />
              }}
            />
          )}
        </Switch>
      </div>
    )
  }
}

ContentPane.propTypes = {
  pathname: string,
  permissions: object,
  dragging: oneOfType([string, bool]),
  readOnly: bool,
}

const ContentPaneHOC = () => {
  const { pathname } = useLocation()
  const { permissions, dragging, readOnly } = useGlobalState(state => {
    return objPick(state.global, ["permissions", "dragging", "readOnly"])
  })

  return (
    <ContentPane
      pathname={pathname}
      permissions={permissions}
      dragging={dragging}
      readOnly={readOnly}
    />
  )
}

export default ContentPaneHOC
