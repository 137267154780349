import classnames from "classnames"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { array } from "prop-types"
import { Link } from "react-router-dom"

import consts from "@guardian/Constants"
import { useGlobalState } from "@guardian/State"

import calendar from "@guardian/Utils/dayjs/calendar"
import { shouldReplace } from "@guardian/Utils/history"
import { dateToUTC } from "@guardian/Utils/util"

import style from "./ModerationLog.module.css"

dayjs.extend(advancedFormat)
dayjs.extend(calendar)

const ACTION_COPY = {
  [consts.ResolvedStatus.Rejected]: "Reject",
  [consts.ResolvedStatus.Merged]: "Confirm and Merge",
  [consts.ResolvedStatus.Confirmed]: "Confirm and Create",
}

const getPathname = content => {
  let prefix
  if (content.ugcMeta.resolvedStatus !== consts.ResolvedStatus.Rejected) {
    prefix = "incidents"
  } else if (content.contentType === "video_stream") {
    prefix = "streams"
  } else {
    prefix = "images"
  }
  return `/${prefix}/${content.incidentId}`
}

const ModerationLog = ({ log }) => {
  const rejectionCopy = useGlobalState(state => {
    const { rejectionChoices } = state.moderation
    return Object.keys(rejectionChoices).reduce((rejectionCopy, c) => {
      rejectionCopy[rejectionChoices[c]] = c
      return rejectionCopy
    }, {})
  })

  return (
    <div className={style.log}>
      <h5>My Moderation Log</h5>
      <div className={style.list}>
        {log.map(content => {
          const pathname = getPathname(content)

          return (
            <Link
              to={pathname}
              replace={shouldReplace(pathname)}
              target='_blank'
              key={content.contentId}
            >
              <div className={style.stream}>
                <div className={style.info}>
                  <div
                    className={classnames(style.action, {
                      [style.merged]:
                        consts.ResolvedStatus.Merged ===
                        content.ugcMeta.resolvedStatus,
                      [style.created]:
                        consts.ResolvedStatus.Confirmed ===
                        content.ugcMeta.resolvedStatus,
                      [style.rejected]:
                        consts.ResolvedStatus.Rejected ===
                        content.ugcMeta.resolvedStatus,
                    })}
                  >
                    <span>{ACTION_COPY[content.ugcMeta.resolvedStatus]}</span>
                    {content.ugcMeta.resolvedStatus ===
                    consts.ResolvedStatus.Rejected
                      ? ` a report by @${content.reportingUsername}`
                      : " an incident"}
                  </div>
                  {dateToUTC(content.resolvedAt).calendar(",")}
                </div>
                <div
                  className={classnames(style.details, {
                    [style.rejected]:
                      consts.ResolvedStatus.Rejected ===
                      content.ugcMeta.resolvedStatus,
                  })}
                >
                  {consts.ResolvedStatus.Rejected ===
                  content.ugcMeta.resolvedStatus
                    ? rejectionCopy[content.rejectionReason]
                    : content.incidentTitle}
                  <span className={style.arrow}>
                    <i className='fas fa-long-arrow-alt-up' />
                  </span>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

ModerationLog.propTypes = {
  log: array,
}

export default ModerationLog
