import React from "react"
import { array, string, arrayOf, bool, func, number, object } from "prop-types"
import ContentList from "./ContentList"
import style from "./OverviewPane.module.css"
import ServiceAreaDropdown from "@guardian/Components/Form/ServiceAreaDropdown/ServiceAreaDropdown"

const OverviewPane = ({
  content,
  activeContentId,
  selectedServiceAreas,
  serviceAreaConfig,
  onServiceAreaSelect,
}) => {
  return (
    <div className={style.pane}>
      <ServiceAreaDropdown
        selectedServiceAreas={selectedServiceAreas}
        serviceAreaConfig={serviceAreaConfig}
        onServiceAreaSelect={onServiceAreaSelect}
      />
      <ContentList content={content} activeContentId={activeContentId} />
    </div>
  )
}

OverviewPane.propTypes = {
  content: array,
  activeContentId: string,
  selectedServiceAreas: arrayOf(object),
  serviceAreaConfig: object,
  onServiceAreaSelect: func,
}

export default OverviewPane
