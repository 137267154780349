import React, { useEffect, useRef, useState } from "react";

import { LoggingService } from "@guardian/Services/Logging";
import { Flash } from "@guardian/UI/Alert";
import { ButtonGroup, ButtonIcon, IconButton } from "@guardian/UI/Button";
import {
  AbstractItem,
  MenuRow,
  MenuRowChild
} from "@guardian/UI/Dropdown";
import { Icon } from "@guardian/UI/Icon";
import { copyToClipboard } from "@guardian/Utils/Window";

import useMenuContext from "../../../../hooks/useMenuContext";
import {
  MENU_ITEM_KEY,
  MENU_ITEM_CALL_KEY,
  MENU_ITEM_COPY_KEY
} from "../../constants";

import GeoNumberModal from "./components/GeoNumberModal";

const GeoNumbersHandler = props => {
  const { selection } = useMenuContext();

  const [callModalLoading, setCallModalLoading] = useState(null);
  const [callModalLocked, setCallModalLocked] = useState(null);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [callTrigger, setCallTrigger] = useState(null);

  const [copyTrigger, setCopyTrigger] = useState(null);

  const [selectedNumber, setSelectedNumber] = useState({});

  const selectedNumberRef = useRef(selectedNumber);
  const selectionRef = useRef(selection);

  useEffect(() => {
    selectionRef.current = selection;

    if (selection == null) { return; }

    if (selection.menuItemKey === MENU_ITEM_KEY) {
      setSelectedNumber({
        name: selection.label,
        number: selection.value
      });

      if (selection.actionKey === MENU_ITEM_CALL_KEY) {
        setCallModalOpen(true);
      }

      if (selection.actionKey === MENU_ITEM_COPY_KEY) {
        setCopyTrigger(+new Date());
      }
    }
  }, [selection]);

  useEffect(() => {
    selectedNumberRef.current = selectedNumber;
  }, [selectedNumber]);

  useEffect(() => {
    if (callTrigger == null) { return; }

    const selectedNumber = selectedNumberRef.current;

    if (selectedNumber == null || selectedNumber.number == null) {
      LoggingService.logError(
        "Unable to call Geo Number.",
        {
          domain: "GeoNumbersHandler",
          flash: true,
          trackedData: {
            selection: selectionRef.current,
            selectedNumber: selectedNumber
          }
        }
      );
      return;
    }

    let artificialDelay;

    const submit = () => {
      setCallModalLoading(true);
      setCallModalLocked(true);

      artificialDelay = setTimeout(() => {
        const encodedNumber = encodeURIComponent(selectedNumber.number);
        const voiceUrl =
          `https://voice.google.com/u/0/calls?a=nc,${encodedNumber}`;

        window.open(voiceUrl, "_blank");

        setCallModalOpen(false);
        setCallModalLoading(false);
      }, 250);
    };

    submit();

    return () => {
      clearTimeout(artificialDelay);
    }
  }, [callTrigger]);

  useEffect(() => {
    if (copyTrigger == null) { return; }

    const selectedNumber = selectedNumberRef.current;

    const handleCopied = () => {
      Flash.generic(
        `The ${ selectedNumber.name } number was copied to your clipboard.`,
        {
          title: "Copied"
        }
      );
    };

    const handleFailedToCopy = () => {
      LoggingService.logError(
        "Unable to copy Geo Number.",
        {
          domain: "GeoNumbersHandler",
          flash: true,
          trackedData: {
            selection: selectionRef.current,
            selectedNumber: selectedNumber
          }
        }
      );
    };

    if (selectedNumber == null || selectedNumber.number == null) {
      handleFailedToCopy();
      return;
    }

    const copy = () => {
      if (copyToClipboard(selectedNumber.number)) {
        handleCopied();
      } else {
        handleFailedToCopy();
      }
    };

    copy();
  }, [copyTrigger]);

  const handleCloseCallModal = () => {
    setCallModalOpen(false);
  };

  const handleConfirmCall = () => {
    setCallTrigger(+new Date());
  };

  const handleExitCallModal = () => {
    setCallModalLocked(false);
  };

  return (
    <GeoNumberModal
      number={selectedNumber}
      locked={callModalLocked}
      loading={callModalLoading}
      onClose={handleCloseCallModal}
      onConfirm={handleConfirmCall}
      onExit={handleExitCallModal}
      open={callModalOpen}
    />
  );
};

export default GeoNumbersHandler;
