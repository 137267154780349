import { Participant, Room } from "twilio-video"

export interface IDimensions {
  height: number
  width: number
}

export interface IState {
  activeSessionId?: string

  ownerID: string
  videoDeviceId: string
  videoProvider: string
  userSpeakerMuted: boolean
  userDisabledAgentAudio: boolean
  userDisabledAgentVideo: boolean
  userMicDisabled: boolean

  // twilio
  twilioRoom?: Room
  twilioOwnerParticipant?: Participant
  twilioDialOutParticipant?: Participant
  twilioContacts: Array<Participant>

  //tokbox
  tokboxSession?: any
  tokboxOwnerStream?: any
  tokbox911Stream?: any
  tokboxWatcherStreams: Array<any>
}

export enum TSOSVideoContextActionType {
  UpdateUserSpeakerMuted = "update user speaker muted",
  UpdateUserDisabledAgentAudio = "update user disabled agent audio",
  UpdateUserDisabledAgentVideo = "update user disabled agent video",
  UpdateUserMicDisabled = "update user mic disabled",
  UpdateVideoProvider = "update video provider",
  UpdateVideoDeviceId = "update video device id",
  UpdateTwilioRoom = "update twilio room",
  UpdateTwilioOwnerParticipant = "update twilio owner particpant",
  UpdateTwilioDialOutParticipant = "update twilio dial out particpant",
  AddTwilioContact = "add twilio contact",
  RemoveTwilioContact = "remove twilio contact",

  UpdateTokboxSession = "update tokbox session",
  UpdateTokboxOwnerStream = "update tokbox owner stream",
  UpdateTokbox911Stream = "update tokbox 911 stream",
  AddTokboxWatcherStream = "add tokbox watcher stream",
  RemoveTokboxWatcherStream = "add tokbox watcher stream",

  Reset = "reset",
}

export interface IAction {
  type: TSOSVideoContextActionType
  data?: any
}
