import { any, array, bool, number, object, string } from "prop-types"
import React, { useMemo } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import {
  getFlaggingAlgorithm,
  isClipFlagged,
  isMedicalIncidentClip,
  isUpdateClip,
} from "@guardian/Utils/clips"
import { objPick } from "@guardian/Utils/obj-pick"

import Clip from "@guardian/Components/Clip/Clip"

import UnplayedClipsAlert from "./UnplayedClipsAlert"

const updateActiveClip = payload => {
  eventHub.emit("setActiveClip", payload)
}

const ClipList = ({
  clips,
  channels,
  activeClipId,
  activeClipStatus,
  activeClipLoop,
  activeClipAnimateStatus,
  mutes,
  speed,
  attachedClips,
  hideAttachmentControls,
  unplayedClipsCount,
  unplayedOnly,
  isolateOn,
}) => {
  const {
    dragging,
    permissions,
    user,
    metapod,
    clipPriorityConfig,
    flaggedConfig,
    flagAlgorithm,
  } = useGlobalState(state => {
    const selectedGlobal = objPick(state.global, [
      "dragging",
      "permissions",
      "user",
      "metapod",
      "clipPriorityConfig",
    ])
    const selectedRadio = objPick(state.radio, [
      "flaggedConfig",
      "flagAlgorithm",
    ])
    return { ...selectedGlobal, ...selectedRadio }
  })

  const attachedClipIds = useMemo(() => {
    return new Set(attachedClips?.map(c => c.id) || [])
  }, [attachedClips])

  const filteredClips = useMemo(
    () => clips.filter(clip => channels[clip.channel]),
    [clips, channels],
  )
  const [pinned, nonPinned] = useMemo(() => {
    const lastUnpinnedClipIndex = filteredClips.findLastIndex(clip => !clip.pin)
    return [
      filteredClips.slice(lastUnpinnedClipIndex + 1),
      filteredClips.slice(0, lastUnpinnedClipIndex + 1),
    ]
  }, [filteredClips])

  const hasMutes = Object.keys(mutes || {}).length > 0

  const renderClip = clip => {
    const channel = channels[clip.channel]
    const isActive = activeClipId === clip.id
    const flaggingAlgorithm = getFlaggingAlgorithm(channel, flaggedConfig, flagAlgorithm)

    return (
      <Clip
        key={clip.id}
        id={clip.id}
        durationMs={clip.duration_ms}
        played={clip.played}
        time={clip.time}
        spectrogram={clip.spectrogram}
        wavUrl={clip.wav_url}
        pin={clip.pin}
        clipListenStatus={clip.clipListenStatus}
        metadata={clip.metadata || {}}
        channelHeader={channel.name}
        activeClipId={isActive ? activeClipId : "0"}
        activeClipLoop={isActive ? activeClipLoop : false}
        activeClipStatus={isActive ? activeClipStatus : null}
        activeClipAnimateStatus={isActive ? activeClipAnimateStatus : null}
        updateActiveClip={updateActiveClip}
        attached={attachedClipIds.has(clip.id)}
        isolateOn={isolateOn ?? (hasMutes || !!unplayedOnly)}
        serviceArea={channel.serviceArea}
        subArea={channel.subArea}
        speed={isActive ? speed : 1}
        dragging={dragging}
        permissions={permissions}
        user={user}
        metapod={metapod}
        hideAttachmentControls={hideAttachmentControls}
        isFlagged={isClipFlagged(clip, flaggingAlgorithm, clipPriorityConfig)}
        isUpdateClip={isUpdateClip(clip)}
        isMedicalIncident={isMedicalIncidentClip(clip, clipPriorityConfig)}
      />
    )
  }

  return (
    <div className='list-container'>
      <div id='clip-list' className='clip-list styled-scrolls'>
        {nonPinned.map(renderClip)}
        {pinned.length > 0 && <div className='panel-header'>Pinned Clips</div>}
        {pinned.map(renderClip)}
      </div>
      {!!unplayedClipsCount && (
        <UnplayedClipsAlert count={unplayedClipsCount} />
      )}
    </div>
  )
}

ClipList.propTypes = {
  clips: array,
  channels: object,
  activeClipId: string,
  activeClipStatus: string,
  activeClipLoop: any,
  activeClipAnimateStatus: bool,
  mutes: any,
  speed: number,
  attachedClips: object,
  hideAttachmentControls: bool,
  unplayedClipsCount: number,
  unplayedOnly: bool,
  isolateOn: bool,
}

export default React.memo(ClipList)
