import React, { useEffect, useState } from "react"
import combokeys from "@guardian/Utils/hotkeys"
import style from "./ContentControls.module.css"
import classnames from "classnames"
import RejectModal from "./RejectModal"
import { bool, func, string } from "prop-types"

const ContentControls = ({
  confirmed,
  onConfirm,
  onReject,
  onBlock,
  onVerified,
  block,
  verified,
  id,
  prompt911,
  onPrompt911,
  type,
}) => {
  const [showReject, setShowReject] = useState(false)

  useEffect(() => {
    if (!confirmed) {
      combokeys.bind("r", () => {
        setShowReject(true)
      })
      combokeys.bind("a", onConfirm)

      combokeys.unbind("v")
      combokeys.unbind("b")
    } else {
      combokeys.unbind("r")
      combokeys.unbind("a")

      combokeys.bind("v", onVerified)
      combokeys.bind("b", onBlock)
    }
  }, [confirmed, onConfirm, onVerified, onBlock])

  useEffect(() => {
    if (showReject) {
      combokeys.bind("esc", () => setShowReject(false))
    } else {
      combokeys.unbind("esc")
    }
  }, [showReject])

  useEffect(() => {
    setShowReject(false)
  }, [confirmed])

  return (
    <div
      className={classnames(style.container, {
        [style.confirmed]: confirmed,
      })}
    >
      {!confirmed && (
        <button
          className={classnames(style.large, style.confirm)}
          onClick={onConfirm}
        >
          Accept (a)
        </button>
      )}
      {!confirmed && (
        <div className={style.rejectContainer}>
          <button
            className={classnames(style.large, style.reject)}
            onClick={() => setShowReject(true)}
          >
            Reject (r)
          </button>
          {showReject && (
            <RejectModal onReject={onReject} id={id} contentType={type} />
          )}
        </div>
      )}
      {confirmed && (
        <button
          className={classnames(style.small, { [style.active]: block })}
          onClick={onBlock}
        >
          Block (b)
        </button>
      )}
      {confirmed && (
        <button
          className={classnames(style.small, {
            [style.active]: verified,
          })}
          onClick={onVerified}
        >
          Verify (v)
        </button>
      )}
      {confirmed && (
        <button
          className={classnames(style.small, {
            [style.active]: prompt911,
          })}
          onClick={onPrompt911}
        >
          Call 911
        </button>
      )}
    </div>
  )
}

ContentControls.propTypes = {
  confirmed: bool,
  onConfirm: func,
  onReject: func,
  onBlock: func,
  onVerified: func,
  block: bool,
  verified: bool,
  id: string,
  prompt911: bool,
  onPrompt911: func,
  type: string,
}

export default ContentControls
