import { any, func, number, string } from "prop-types"
import React from "react"

import config from "@guardian/Config"
import { getSpectroWidth } from "@guardian/Utils/util"

import Sound from "@guardian/Components/Sound"

import "./styles.scss"

const ProgressSpectrogram = ({
  width,
  spectroRef,
  spectrogram,
  seek,
  position,
  durationMs,
  status,
}) => {
  const spectroWidthPx = getSpectroWidth(width, durationMs)
  const spectroWidth = { width: spectroWidthPx + "px" }
  const spectrogramImg =
    (config.secure ? "https://" + spectrogram :
      (config.production ? "https://assets.citizen.com/" : "https://assets.staging.sp0n.io/") + spectrogram)
  const overlayWidth = (position / durationMs) * spectroWidthPx
  const seekBar = seek ? (
    <div onClick={seek} className='seek-bar'>
      <div className='visible-bar' />
    </div>
  ) : null

  return (
    <div className='audio-images'>
      <div ref={spectroRef} className='audio-spectrogram' style={spectroWidth}>
        {spectrogram && (
          <img
            draggable={false}
            src={spectrogramImg}
            alt=''
            style={spectroWidth}
          />
        )}
        {seekBar}
      </div>
      {status !== Sound.status.STOPPED && (
        <div
          className='audio-spectrogram-overlay'
          style={{ width: `${overlayWidth}px` }}
        >
          {spectrogram && (
            <img
              draggable={false}
              src={spectrogramImg}
              alt=''
              style={spectroWidth}
            />
          )}
          {seekBar}
        </div>
      )}
    </div>
  )
}

ProgressSpectrogram.propTypes = {
  width: number,
  spectroRef: any,
  spectrogram: any,
  seek: func,
  position: number,
  duration_ms: number,
  status: string,
}

export default ProgressSpectrogram
