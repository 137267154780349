import { ServiceAreas } from "@guardian/API/Optimus";

const ServiceAreaService = {
  getGeographyPushes: (options?: any) => {
    return new Promise((resolve, reject) => {
      ServiceAreas.getGeographyPushes(options)
        .then(({ data: config }) => {
          resolve(config);
        })
        .catch(reject);
    });
  },

  getPoliceAreas: (code: string, options?: any) => {
    return new Promise((resolve, reject) => {
      ServiceAreas.getPoliceAreas(code, options)
        .then(({ data: config }) => {
          resolve(config);
        })
        .catch(reject);
    });
  },

  getPoliceSubAreas: (code: string, options?: any) => {
    return new Promise((resolve, reject) => {
      ServiceAreas.getPoliceSubAreas(code, options)
        .then(({ data: config }) => {
          resolve(config);
        })
        .catch(reject);
    });
  },

  getServiceAreas: (options?: any) => {
    return new Promise((resolve, reject) => {
      ServiceAreas.getServiceAreas(options)
        .then(({ data: config }) => {
          // NOTE: Legacy implementation imported during API cleanup. Improve if
          // working on a feature using this data. The lists should probably at
          // least be a (default) argument to this function call.
          for (const list of ["allowlist", "blocklist"]) {
            for (const area in config[list]) {
              // Coerce polygon (coords) into latitude/longitude format.
              config[list][area].polygon = ServiceAreaService.coordsToLatLong(
                config[list][area].polygon
              )
            }
          }

          resolve(config);
        })
        .catch(reject);
    });
  },

  coordsToLatLong: (coords: any[]) => {
    return coords.map(coord => ({ latitude: coord[1], longitude: coord[0] }));
  },
};

export default ServiceAreaService;
