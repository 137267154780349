import React, { useEffect, useMemo } from "react"

import FileInput from "@guardian/Components/Form/Inputs/FileInput"
import { Icon } from "@guardian/UI/Icon"
import { Spinner } from "@guardian/UI/Loader"

import { useAuditToolContext } from "@guardian/Components/AuditTool/store"
import { useAuditIncidentSync } from "@guardian/Components/AuditTool/hooks"
import {
  AuditToolEntryType,
  type AuditToolEntry,
} from "@guardian/Components/AuditTool/types"
import {
  createAddressEntry,
  createTitleUpdateEntry,
  isAddressEntry,
} from "@guardian/Components/AuditTool/utils"
import { useCSVUpload } from "@guardian/Hooks/useCSVUpload"

import {
  FileInputContainer,
  FileInputError,
  UploadControlsContainer,
  UploadControlsDescription,
  UploadControlsTitle,
} from "./styles"

type AddressCSVRow = {
  Clip_id: string
  Incident_id: string
  Address: string
  DisplayLocation: string
  AA_address: string
}
type TitleUpdateCSVRow = {
  Clip_id: string
  Incident_id: string
  Title: string
  Update: string
}
type CSVRow = AddressCSVRow | TitleUpdateCSVRow

const mapCSVRow = (row: CSVRow): AuditToolEntry => {
  if ("Address" in row) {
    return createAddressEntry({
      clipId: row.Clip_id?.trim(),
      incidentId: row.Incident_id?.trim(),
      address: row.Address?.trim(),
      displayLocation: row.DisplayLocation?.trim(),
      aaAddress: row.AA_address?.trim(),
    })
  }
  return createTitleUpdateEntry({
    clipId: row.Clip_id?.trim(),
    incidentId: row.Incident_id?.trim(),
    title: row.Title?.trim(),
    update: row.Update?.trim(),
  })
}

const UploadControls = () => {
  const {
    state: { isFetchingClips, isSyncingEntries },
  } = useAuditToolContext()

  const { isUploading, csvError, csvData, handleUpload } =
    useCSVUpload<CSVRow>()
  const { mutate } = useAuditIncidentSync()

  const csvDataParsed = useMemo(
    () => csvData?.map(row => mapCSVRow(row)) || [],
    [csvData],
  )

  useEffect(() => {
    if (!csvDataParsed?.length) {
      return
    }
    mutate({
      type: isAddressEntry(csvDataParsed[0])
        ? AuditToolEntryType.ADDRESS
        : AuditToolEntryType.TITLE_UPDATE,
      entries: csvDataParsed,
    })
  }, [csvDataParsed])

  if (isFetchingClips || isSyncingEntries) {
    return (
      <UploadControlsContainer>
        <Spinner />
      </UploadControlsContainer>
    )
  }

  return (
    <UploadControlsContainer>
      <UploadControlsTitle>
        <Icon.FileUpload height={24} width={24} />
        Upload CSV
      </UploadControlsTitle>
      <UploadControlsDescription>
        NOTE: File should be in a CSV format, with the first row as headers.
        <br />
        <strong>Required headers:</strong>
        <ul>
          <li>
            <code>Clip_id</code>
          </li>
          <li>
            <code>Incident_id</code>
          </li>
          <li>
            <code>Address</code> (for Address CSV)
          </li>
          <li>
            <code>DisplayLocation</code> (for Address CSV)
          </li>
          <li>
            <code>AA_address</code> (for Address CSV)
          </li>
          <li>
            <code>Title</code> (for Title & Update CSV)
          </li>
          <li>
            <code>Update</code> (for Title & Update CSV)
          </li>
        </ul>
      </UploadControlsDescription>
      <FileInputContainer>
        <FileInput
          accept='.csv'
          onChange={handleUpload}
          disabled={isUploading}
        />
        {isUploading && <Spinner />}
      </FileInputContainer>
      {!!csvError && <FileInputError>{csvError}</FileInputError>}
    </UploadControlsContainer>
  )
}

export default UploadControls
