import _ from "lodash"
import dayjs from "dayjs"
import { Map } from "immutable"

import * as analytics from "@guardian/Analytics"
import { RadioService } from "@guardian/Services/Radio";
import { readFlaggedConfig } from "@guardian/Utils/clips"
import { getDefaultChannels, regenClipView } from "@guardian/Utils/state"

function removeKey(obj, key) {
  const newObj = Object.assign({}, obj)
  delete newObj[key]
  return obj.reset(newObj)
}

const channels = state => {
  const dbFetchIncidentFeed = _.debounce(
    () => state.emit("modFetchIncidents"),
    500,
  )

  state.on("clearStaleChannels", () => {
    const activeChannels = Object.assign({}, state.get().radio.activeChannels)
    let changed
    Object.keys(activeChannels).forEach(ch => {
      if (!state.get().radio.channels[ch]) {
        changed = true
        delete activeChannels[ch]
      }
    })
    if (changed) {
      state.emit("saveActiveChannels", activeChannels)
    }
  })

  state.on("saveActiveServiceAreas", activeServiceAreas => {
    window.localStorage["signal:activeServiceAreas"] = JSON.stringify(
      activeServiceAreas,
    )
    state.get().radio.set("activeServiceAreas", activeServiceAreas)
  })

  const dbSaveChannels = _.debounce(RadioService.setRadioConfiguration, 500)
  state.on("saveActiveChannels", activeChannels => {
    window.localStorage["signal:activeChannels"] = JSON.stringify(
      activeChannels,
    )
    state.get().radio.set("activeChannels", activeChannels)

    const activeServiceAreas = state.emit(
      "getActiveServiceAreas",
      activeChannels,
    )
    state.emit("saveActiveServiceAreas", activeServiceAreas)

    // Set flag to suppress message that channels are empty
    state.get().radio.set({
      pendingClipsFetch: true,
      isFetchingClips: false,
    })
    // Clear last fetch latest time since channel set is different now
    state.get().radio.set("lastClipsFetchLatest", null)
    if (state.get().global.user) {
      dbSaveChannels({ channels: Object.keys(activeChannels) })
    }
  })

  state.on("toggleChannel", channel => {
    if (channel.includes("_NOSELECT_")) {
      // TODO there's gotta be a better way?
      return
    }
    const activeChannels = Object.assign({}, state.get().radio.activeChannels)
    Object.keys(activeChannels).forEach(ch => {
      if (!state.get().radio.channels[ch]) {
        delete activeChannels[ch]
      }
    })
    const { mutes, preIsolateActiveChannels } = state.get().radio
    if (mutes[channel]) {
      removeKey(mutes, channel)
      if (_.isEmpty(state.get().radio.mutes)) {
        // Nothing muted, leave isolate state
        state.get().radio.preIsolateActiveChannels.reset({})
      }
      return
    } else if (activeChannels[channel]) {
      if (preIsolateActiveChannels[channel]) {
        // Return back to muted state
        state.get().radio.mutes.set(channel, true)
      } else {
        delete activeChannels[channel]
      }
    } else {
      activeChannels[channel] = true
    }
    if (
      _.every(Object.keys(activeChannels), cid => state.get().radio.mutes[cid])
    ) {
      // Everything is muted, leave isolate state
      state.get().radio.preIsolateActiveChannels.reset({})
      state.get().radio.mutes.reset({})
    }
    // Refetch incident feed if we gain/lose a service area
    if (!_.isEqual(
        state.emit("getActiveServiceAreas", activeChannels),
        state.emit("getActiveServiceAreas", state.get().radio.activeChannels),
    )) {
      dbFetchIncidentFeed()
    }
    state.emit("saveActiveChannels", activeChannels)
  })

  state.on("setChannels", async () => {
    const channelMap = await state.emit("updateChannels")
    await state.emit("updateChannelStats")
    await state.emit("updateChannelAllowlist")

    if (Object.keys(state.get().radio.activeChannels).length === 0) {
      state.get().radio.set("activeChannels", getDefaultChannels(channelMap))
    }
  })

  state.on("updateChannelAllowlist", () => {
    const {
      allowedChannels,
      channels: channelCfg,
      flaggedConfig,
    } = state.get().radio
    let filtered = new Map()
    let updated = false
    allowedChannels.forEach((val, key) => {
      const { ttl } = readFlaggedConfig(
        flaggedConfig,
        channelCfg[key].serviceArea,
        channelCfg[key].subArea,
      )
      if (dayjs(val).isBefore(dayjs().subtract(ttl, "second"))) {
        updated = true
      } else {
        filtered.set(key, val)
      }
    })
    if (updated) {
      state.get().radio.set("allowedChannels", filtered)
      window.localStorage["gnet:allowedChannels"] = JSON.stringify(
        filtered.toObject(),
      )
    }
  })

  const skipChannelStats = [
    "/modalpha",
    "/news",
    "/psyduck",
    "/content-moderation",
    "/livestreams",
  ]
  state.on("updateChannelStats", async () => {
    const { metapod } = state.get().global
    if (metapod && !skipChannelStats.includes(window.location.pathname)) {
      const stats = await RadioService.getRadioChannelFlagStats()
      state.get().radio.set("channelStats", stats)
    }
  })

  state.on("updateChannels", async () => {
    const channels = await RadioService.getRadioChannels()
    const subAreas = await RadioService.getRadioSubAreas()

    const channelMap = {}
    const serviceAreas = {}
    for (let channel of channels) {
      if (
        (state.get().global.allowlist &&
          state.get().global.allowlist[channel.serviceArea]) ||
        !state.get().global.allowlist
      ) {
        channelMap[channel.id] = channel
        if (!serviceAreas[channel.serviceArea]) {
          serviceAreas[channel.serviceArea] = {}
        }
        const serviceArea = serviceAreas[channel.serviceArea]
        if (!serviceArea[channel.subArea]) {
          serviceArea[channel.subArea] = {}
        }
        const subArea = serviceArea[channel.subArea]
        if (!subArea[channel.department]) {
          subArea[channel.department] = []
        }
        const dept = subArea[channel.department]
        dept.push(channel)
      }
    }
    state.get().radio.set({
      channels: channelMap,
      serviceAreas,
      subAreas: subAreas.map(s => s.sub_area),
    })
    state.emit("clearStaleChannels")

    const activeChans = state.get().radio.activeChannels

    const activeServiceAreas = state.emit("getActiveServiceAreas", activeChans)
    state.emit("saveActiveServiceAreas", activeServiceAreas)

    return channelMap
  })

  state.on("setIsolate", (channels, single) => {
    if (channels) {
      const actives = { ...state.get().radio.activeChannels }
      state.get().radio.set("preIsolateActiveChannels", { ...actives })
      channels.forEach(c => {
        delete actives[c]
      })
      if (_.isEmpty(actives)) {
        return
      }
      state.get().radio.set("mutes", actives)
      analytics.event({
        category: "Clips",
        action: "Isolate",
      })
      if (single) {
        state.emit("showFlash", "isolate (i)")
      } else {
        state.emit("showFlash", "super isolate (o)")
      }
    } else {
      state.get().radio.set("mutes", {})
      state.get().radio.preIsolateActiveChannels.reset({})
    }
    regenClipView(state)
    setTimeout(() => state.emit("scrollToActive"), 0)
  })

  state.on("toggleResponderType", ({ subArea, listeningGroup, type }) => {
    const { listeningGroups } = state.get().global.serviceAreaConfig
    const { activeChannels, mutes, serviceAreas } = state.get().radio

    const areaChans = _.flatten(
      Object.keys(listeningGroups[listeningGroup].serviceAreas).map(sa => {
        if (
          serviceAreas.hasOwnProperty(sa) &&
          serviceAreas[sa].hasOwnProperty(subArea)
        )
          return _.get(serviceAreas[sa][subArea], type, [])
        return []
      }),
    )
    if (_.every(areaChans, c => activeChannels[c.id] && !mutes[c.id])) {
      // All active, disable all
      areaChans.forEach(channel => {
        state.emit("toggleChannel", channel.id)
      })
    } else {
      // Some active, enable all
      areaChans.forEach(channel => {
        if (!activeChannels[channel.id] || mutes[channel.id]) {
          state.emit("toggleChannel", channel.id)
        }
      })
    }
  })

  state.on("allowlist channel", id => {
    const { allowedChannels } = state.get().radio
    if (!allowedChannels.has(id)) {
      const allowedChannelsUpdate = allowedChannels.set(id, Date.now())
      state.get().radio.set("allowedChannels", allowedChannelsUpdate)
      window.localStorage["gnet:allowedChannels"] = JSON.stringify(
        allowedChannelsUpdate.toObject(),
      )
    }
  })

  state.on("closeChannelSelection", () => {
    state.get().radio.set("channelSelectionGroup", null)
  })

  state.on("toggle listening group", (code, subAreas) => {
    if (state.get().radio.channelSelectionGroup === code) {
      state.emit("closeChannelSelection")
    } else {
      state.get().radio.set("channelSelectionGroup", code)
    }
    if (subAreas) {
      subAreas.forEach(subArea => {
        state.emit("toggleSubArea", subArea)
      })
    }
  })

  state.on("toggleSubArea", subArea => {
    const { activeChannels, mutes, channels } = state.get().radio
    const chans = _.values(channels).filter(
      c => c.subArea === subArea && c.starred,
    )
    if (_.every(chans, c => activeChannels[c.id] && !mutes[c.id])) {
      // All active, disable all
      chans.forEach(c => {
        state.emit("toggleChannel", c.id)
      })
    } else {
      // Some active, enable all
      chans.forEach(c => {
        if (!activeChannels[c.id] || mutes[c.id]) {
          state.emit("toggleChannel", c.id)
        }
      })
    }
  })

  state.on("superIsolate", channels => {
    const activeChannels = state.get().radio.activeChannels
    const preSuperIsolateActiveChannels = {
      ...state.get().radio.preSuperIsolateActiveChannels,
    }
    if (_.isEmpty(preSuperIsolateActiveChannels)) {
      state.get().radio.set("preSuperIsolateActiveChannels", activeChannels)
      channels.forEach(c => {
        if (!activeChannels[c]) {
          state.emit("toggleChannel", c)
        }
      })
      state.emit("setIsolate", channels, false)
      analytics.event({
        category: "Clips",
        action: "Super Isolate",
      })
    } else {
      state.emit("setIsolate", null)
      state.emit("saveActiveChannels", preSuperIsolateActiveChannels)
      state.get().radio.preSuperIsolateActiveChannels.reset({})
    }

    regenClipView(state)
    setTimeout(() => state.emit("scrollToActive"), 0)
  })
}

export default channels
