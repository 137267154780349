import { MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { array, bool, func, oneOfType, string } from "prop-types";

import { useGlobalState } from "@guardian/State";

import style from "./DistributionType.module.css";
import {
  inputStyles,
  radiusStyles,
  selectStyles,
} from "./DistributionType.styles";
import GeographySelector from "./GeographySelector";

const StyledInput = withStyles(inputStyles)(OutlinedInput)
const StyledSelect = withStyles(selectStyles)(Select)

const options = [
  { label: "Citywide", value: "standard" },
  { label: "Radius", value: "radius" },
  { label: "Geography", value: "geography" },
  { label: "Reply All", value: "reply_all" },
]

const DistributionType = ({
  distributionType,
  distributionValue,
  setDistributionType,
  setDistributionValue,
  disabled,
}) => {
  const geoConfig = useGlobalState(state => state.global.geographyPushesConfig)

  const styles = distributionType === "radius" ? radiusStyles : {}

  return (
    <div className={style.container}>
      <div className={style.row}>
        <StyledSelect
          label='Distribution Type'
          value={distributionType}
          style={styles}
          onChange={e => setDistributionType(e.target.value)}
          input={<StyledInput notched label='Distribution Type' />}
          variant='outlined'
          disabled={disabled}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
        {distributionType === "radius" && (
          <StyledInput
            label='Radius'
            style={{ width: "84px", paddingLeft: "0px" }}
            notched
            value={distributionValue}
            onChange={e => setDistributionValue(e.target.value)}
            disabled={disabled}
          />
        )}
      </div>
      {distributionType === "geography" && (
        <div className={style.geoContainer}>
          <GeographySelector
            distributionValue={distributionValue}
            setDistributionValue={setDistributionValue}
            geoConfig={geoConfig}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  )
}

DistributionType.propTypes = {
  distributionType: string,
  distributionValue: oneOfType([string, array]),
  setDistributionType: func,
  setDistributionValue: func,
  disabled: bool,
}

export default DistributionType
