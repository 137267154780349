import React, { useState } from "react"

import { useGlobalState } from "@guardian/State"

import styles from "./Dashboard.module.css"
import LabelingListenPanel from "./LabelingListenPanel"
import UploadControls from "./UploadControls"
import LabelingCounts from "./LabelingCounts"

const Dashboard = () => {
  const [isFetchingClips, setIsFetchingClips] = useState(false)

  return (
    <div className={styles.container}>
      <LabelingListenPanel isFetchingClips={isFetchingClips} />
      <div>
        <UploadControls setIsFetchingClips={setIsFetchingClips} />
        <LabelingCounts />
      </div>
    </div>
  )
}

export default Dashboard
