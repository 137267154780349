import React, { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

import { useGlobalState } from "@guardian/State"
import { objPick } from "@guardian/Utils/obj-pick"

import useGetAgentChatUnreadCount from "./hooks/useGetAgentChatUnreadCount"
import useGetPendingRadioIncidentsCount from "./hooks/useGetPendingRadioIncidentsCount"
import usePollLiveStreamCount from "./hooks/usePollLiveStreamCount"
import usePollUnresolvedIGLCount from "./hooks/usePollUnresolvedIGLCount"
import useSubscribeSOSFeedCount from "./hooks/useSubscribeSOSFeedCount"
import Logo from "./views/Logo"
import StyledNavigationBar from "./views/NavigationBar"
import NavigationBarTrailingContent from "./views/NavigationBarTrailingContent"
import NavigationItem from "./views/NavigationItem"
import NavigationItemNotification from "./views/NavigationItemNotification"
import NavigationItems from "./views/NavigationItems"
import NavigationLink from "./views/NavigationLink"

import UserDropdown from "./components/UserDropdown"
import UserStats from "./components/UserStats"
import StatusConfig from "./components/StatusConfig"
import { usePlaySOSAudio } from "@guardian/Components/Dashboard/components/NavigationBar/hooks/usePlaySOSAudio"
import { usePlayProtectAudio } from "@guardian/Components/Dashboard/components/NavigationBar/hooks/usePlayProtectAudio"

const NavigationBar = () => {
  const { pathname } = useLocation()

  const {
    readOnly,
    active: agentIsToggledActive,
    permissions,
    stats,
    incidentsByHour,
    tab,
    user,
  } = useGlobalState(state => {
    return objPick(state.global, [
      "readOnly",
      "active",
      "permissions",
      "stats",
      "incidentsByHour",
      "tab",
      "user",
    ])
  })

  const [agentChatUnreadCount] = useGetAgentChatUnreadCount()

  const [liveStreamCount] = usePollLiveStreamCount({
    enabled: permissions.citMod || permissions.lafd,
  })

  const [radioIncidentsCount] = useGetPendingRadioIncidentsCount()

  const [sosFeedCount] = useSubscribeSOSFeedCount({
    enabled: permissions.sosMod,
  })

  const [unresolvedIGLCount] = usePollUnresolvedIGLCount(
    permissions.lafd ? import.meta.env.REACT_APP_LAFD_PSN : undefined,
    {
      enabled: permissions.citMod || permissions.lafd,
    },
  )

  const showStats = useMemo(() => {
    if (
      pathname.startsWith("/sos") ||
      pathname.startsWith("/sos_dms") ||
      !permissions.citMod
    ) {
      return false
    }

    return true
  }, [pathname])

  usePlaySOSAudio()
  usePlayProtectAudio({
    enabled: permissions.sosMod,
  })

  const warn = !agentIsToggledActive

  return (
    <StyledNavigationBar warn={warn}>
      <Link to='/metapod'>
        <Logo />
      </Link>
      {user && (
        <React.Fragment>
          <NavigationItems>
            {(permissions.citMod || permissions.lafd) && (
              <React.Fragment>
                <NavigationLink
                  to='/metapod'
                  isActive={() => {
                    const isHome = pathname === "/"
                    const isIncidents =
                      pathname.startsWith("/incidents") && tab === "modalpha"
                    const isMetapod = pathname === "/metapod"
                    const active = isHome || isIncidents || isMetapod

                    return active
                  }}
                >
                  <NavigationItem warn={warn}>
                    Radio
                    <NavigationItemNotification
                      visible={radioIncidentsCount > 0}
                    >
                      {radioIncidentsCount}
                    </NavigationItemNotification>
                  </NavigationItem>
                </NavigationLink>

                {!permissions.radioDesk && !readOnly && (
                  <React.Fragment>
                    <NavigationLink to='/livestreams'>
                      <NavigationItem warn={warn}>
                        Live
                        <NavigationItemNotification
                          visible={liveStreamCount > 0}
                        >
                          {liveStreamCount}
                        </NavigationItemNotification>
                      </NavigationItem>
                    </NavigationLink>
                    <NavigationLink to='/psyduck'>
                      <NavigationItem warn={warn}>
                        Signal Moderation
                        <NavigationItemNotification
                          visible={unresolvedIGLCount > 0}
                        >
                          {unresolvedIGLCount}
                        </NavigationItemNotification>
                      </NavigationItem>
                    </NavigationLink>
                    <NavigationLink to='/content-moderation'>
                      <NavigationItem warn={warn}>
                        Content Moderation
                      </NavigationItem>
                    </NavigationLink>
                    {permissions.lafd && (
                      <NavigationLink to='/streams'>
                        <NavigationItem warn={warn}>Streams</NavigationItem>
                      </NavigationLink>
                    )}
                  </React.Fragment>
                )}

                {permissions.citMod && permissions.sosMod && (
                  <React.Fragment>
                    <NavigationLink to='/sos_dms'>
                      <NavigationItem warn={warn}>
                        Agent Chat
                        <NavigationItemNotification
                          visible={agentChatUnreadCount > 0}
                        >
                          {agentChatUnreadCount}
                        </NavigationItemNotification>
                      </NavigationItem>
                    </NavigationLink>
                    <NavigationLink to='/sos'>
                      <NavigationItem warn={warn}>
                        Protect
                        <NavigationItemNotification visible={sosFeedCount > 0}>
                          {sosFeedCount}
                        </NavigationItemNotification>
                      </NavigationItem>
                    </NavigationLink>
                  </React.Fragment>
                )}

                {permissions.dataLabeling && (
                  <NavigationLink to='/data_labeling'>
                    <NavigationItem warn={warn}>Data Labeling</NavigationItem>
                  </NavigationLink>
                )}

                {permissions.qaTool && (
                  <NavigationLink to='/audit_tool'>
                    <NavigationItem warn={warn}>Audit Tool</NavigationItem>
                  </NavigationLink>
                )}
              </React.Fragment>
            )}
          </NavigationItems>

          <NavigationBarTrailingContent>
            {showStats && (
              <UserStats stats={stats} incidentsByHour={incidentsByHour} />
            )}
            <StatusConfig />
            <UserDropdown />
          </NavigationBarTrailingContent>
        </React.Fragment>
      )}
    </StyledNavigationBar>
  )
}

export default NavigationBar
