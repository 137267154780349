import classNames from "classnames";
import styled from "styled-components";

const SectionHeder = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__session__section-header"
    )
  };
})`
  color: var(--colors-white);
  font-size: var(--font-sizes-medium);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-medium);
  padding: var(--space-small) var(--space-medium);
`;

SectionHeder.defaultProps = {};

export default SectionHeder;
