import classNames from "classnames";
import styled from "styled-components";

const TabBar = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(className, "sos-dms__tab-bar")
    }
  }
)`
  align-items: center;
  background: var(--colors-grey-095);
  box-shadow: var(--borders-highlight-bottom) var(--colors-grey-090);
  display: flex;
  flex: 0 0 56px;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 var(--space-small);
`;

export default TabBar;
