import React from "react";

import useFieldContext from "../hooks/useFieldContext";
import StyledLabel from "../views/Label";

const Label = React.forwardRef((props, ref) => {
  const fieldContext = useFieldContext();

  let combinedProps = {
    ref,
    ...props
  };

  if (fieldContext) {
    combinedProps = fieldContext.getLabelProps(combinedProps);
  }

  return (
    <StyledLabel ref={ref} {...combinedProps}/>
  );
});

export default Label;
