import React from "react";
import ReactDOMServer from "react-dom/server";

import { svg } from "@guardian/UI/Utils";

import Icon from "../views/Icon";

/*
 * IconURL
 *
 * The IconURL object wraps icons provided by components to return the icons as
 * simple data URLs. The IconURL object is not a component itself, and should
 * not be used directly. Rather, icons should be referenced by name off this
 * object.
 *
 * Example, for our "X" icon:
 *
 * // Don't do this:
 * <Close/>;
 *
 * // Do this:
 * <IconURL.Close/>;
 *
 */
const IconURL = Object.entries(Icon)
  .reduce((memo, [name, IconComponent]) => {
    const RenderedIcon = ReactDOMServer.renderToString(
      <IconComponent/>
    );

    memo[name] = svg.toDataURL(RenderedIcon);

    return memo;
  }, {});

export default IconURL;
