const fonts = {};

fonts.sansSerif =
  "-apple-system, BlinkMacSystemFont, 'helvetica neue', helvetica, arial, sans-serif";
fonts.monospace = "Consolas, 'Liberation Mono', courier, monospace";

export const fontsCSSVariables = `
  :root {
    --fonts-sans-serif: ${ fonts.sansSerif };
    --fonts-monospace: ${ fonts.monospace };
  }
`;

export default fonts;
