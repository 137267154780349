import styled from "styled-components"

export const ErrorContainer = styled.div`
  padding: 2rem;
  background-color: rgba(255, 87, 87, 0.1);
  border: 1px solid rgba(255, 87, 87, 0.3);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ErrorMessage = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: #ff5757;
  margin-bottom: 1rem;
  width: 100%;
`

export const ErrorStack = styled.pre`
  padding: 1rem;
  flex: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 0.875rem;
  color: #e0e0e0;
  max-width: 100%;
  overflow: auto;
  white-space: pre-wrap;
`
