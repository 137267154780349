import React from "react";

import { Avatar } from "@guardian/UI/Avatar";

import UserMessage from "./components/UserMessage";
import UserImageWrapper from "./views/UserImageWrapper";
import StyledUserMessageGroup from "./views/UserMessageGroup";
import UserMessages from "./views/UserMessages";
import UserName from "./views/UserName";

const UserMessageGroup = props => {
  const {
    messageGroup: {
      messages
    },
    userId,
    userInfo: {
      avatarThumbURL,
      fullName,
    }
  } = props;

  return (
    <StyledUserMessageGroup>
      <UserImageWrapper>
        <Avatar
          id={userId}
          fullName={fullName}
          src={avatarThumbURL}
        />
      </UserImageWrapper>
      <UserMessages>
        {
          messages.map((message) => (
            <UserMessage
              key={`user-message-${ message.id }`}
              message={message}
            />
          ))
        }
        <UserName>
          {fullName}
        </UserName>
      </UserMessages>
    </StyledUserMessageGroup>
  );
};

export default React.memo(UserMessageGroup);
