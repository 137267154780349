export const SOS_SESSION_DISABLED = "disabled"
export const SOS_SESSION_ACTIVE = "active"
export const SOS_SESSION_MISSED = "missed"

export const getInitialSeconds: (createdAt: string) => number = createdAt => {
  if (!createdAt) return 0
  const oldTime = new Date(createdAt)
  if (oldTime.getTime() < 0) return 0
  const currTime = new Date(Date.now())
  const diff = currTime.getTime() - oldTime.getTime()
  return Math.abs(diff / 1000)
}
