import classNames from "classnames";
import styled from "styled-components";

const ContentRow = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__list-item__content-row"
    )
  };
})`
  colors: var(--colors-white);
`;

ContentRow.defaultProps = {};

export default ContentRow;
