import { object } from "prop-types"

import * as incUtil from "@guardian/Utils/incident"

import style from "./ActionPaneHeader.module.css"

const ActionPaneHeader = ({ stream, incident, state }) => {
  let author = ""
  if (incident) {
    const { updateStatus: status } = incUtil.incidentStatus(incident)
    author = `@${status.author}`
  }

  const isOnAir = stream.videoStream.onAir

  return (
    <div className={style.container}>
      {!!stream.noLongerLive && (
        <div className={style.noLongerLive}>
          <img alt="" src='/NoLongerLiveIndicator.svg' />
          No Longer Live
        </div>
      )}
      {!!stream.videoStream.verifiedLive && (
        <div className={style.verifiedBadge}>Verified</div>
      )}

      <div className={style.incidentTitle}>
        {isOnAir
          ? "OnAir Broadcast"
          : incident?.title || stream.videoStream.reportCategory || stream.videoStream.title}
      </div>
      <div className={style.incidentLocation}>
        {isOnAir ? "Citizen HQ" : state.location?.neighborhood || ""}
      </div>
      <div className={style.usersArea}>
        {!isOnAir && (
          <div className={style.iconText}>
            <img alt="" src='/StreamerIcon.svg' />@{stream.videoStream.username}
          </div>
        )}
        {!!incident && (
          <div className={style.iconText}>
            <img alt="" src='/AnalystIcon.svg' />
            {author}
          </div>
        )}
      </div>
    </div>
  )
}

ActionPaneHeader.propTypes = {
  stream: object,
  incident: object,
  state: object,
}

export default ActionPaneHeader
