export const actions = {
  SET_INCIDENT_ID: "setIncidentId",
  SET_INCIDENT: "setIncident",
  SET_IS_LOADING_INCIDENT: "setIsLoadingIncident",
  SET_IS_MERGING: "setIsMerging",
  SET_ERROR_MESSAGE: "setErrorMessage",
}

/**
 * @type {{
 *  incidentId: string | null,
 *  incident: import('@guardian/Types/Incident').EnrichedIncident2 | null,
 *  isLoadingIncident: boolean,
 *  isMerging: boolean,
 *  errorMessage: string | null,
 * }}
 */
export const initialState = {
  incidentId: null,
  incident: null,
  isLoadingIncident: false,
  isMerging: false,
  errorMessage: null,
}

/**
 * @param {typeof initialState} state
 * @param {{ type: string, payload: any}} action
 * @returns {typeof initialState}
 */
const reducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_INCIDENT_ID:
      return { ...state, incidentId: payload, errorMessage: null }
    case actions.SET_IS_LOADING_INCIDENT:
      return { ...state, isLoadingIncident: payload }
    case actions.SET_IS_MERGING:
      return { ...state, isMerging: payload }
    case actions.SET_INCIDENT:
      return { ...state, incident: payload, errorMessage: null }
    case actions.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: payload }
    default:
      return state
  }
}

export default reducer
