import { isEqual } from "lodash"
import { isV2Protect } from "@guardian/Utils/protect"
import {
  IAction,
  IState,
  TSOSDashboardContextActionType,
} from "@guardian/Components/ModSOS/store/dashboard/types"

export const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case TSOSDashboardContextActionType.UpdateActiveSessionId:
      return {
        activeSessionId: action.data,
        isV2Protect: isV2Protect(action.data),
      }
    case TSOSDashboardContextActionType.UpdateSessionResp:
      return {
        ...state,
        sessionResp: action.data,
      }
    case TSOSDashboardContextActionType.UpdateCallSession:
      return {
        ...state,
        callSession: action.data,
      }
    case TSOSDashboardContextActionType.UpdateSessionRecords:
      if (isEqual(state.sessionRecords, action.data)) {
        return state
      }

      return {
        ...state,
        sessionRecords: action.data,
      }
    case TSOSDashboardContextActionType.Reset:
      return {}
    case TSOSDashboardContextActionType.ShowErrorModal:
      return {
        ...state,
        errorModal: action.data,
      }
    case TSOSDashboardContextActionType.CloseErrorModal:
      return {
        ...state,
        errorModal: undefined,
      }
    case TSOSDashboardContextActionType.UpdateOwnerData:
      if (isEqual(state.ownerData, action.data)) {
        return state
      }
      return {
        ...state,
        ownerData: action.data,
      }
    case TSOSDashboardContextActionType.Update911InSession:
      return {
        ...state,
        is911InSession: action.data,
      }
    default:
      return state
  }
}
