import React, { useCallback } from "react"
import { object, func, bool } from "prop-types"
import classnames from "classnames"

import { dateToUTC } from "@guardian/Utils/util"
import * as incUtil from "@guardian/Utils/incident"

import style from "./styles.module.css"

const NearbyIncidentPreview = ({
  incident,
  mergingIncident,
  setMerging,
  disabled
}) => {
  const latest = incUtil.latestRev(incident)
  const timeLatest = dateToUTC(latest.occurredAt).format("H:mm UTC")

  const isMergingIncident = mergingIncident && mergingIncident.id === incident.id

  const handleIncidentMerge = useCallback(() => {
    if (isMergingIncident) {
      setMerging({})
    } else {
      setMerging(incident)
    }
  }, [isMergingIncident, incident, setMerging])

  return (
    <div
      className={classnames(style.preview, {
        [style.merging]: isMergingIncident,
      })}
    >
      <div className={style.topRow}>
        <div className={style.blueText}>{incident.distance}m</div>
        <div className={style.greyText}>{timeLatest}</div>
      </div>
      <div className={style.incidentTitle}>{incident.title}</div>

      <button
        className={classnames(style.mergeButton, {
          [style.on]: isMergingIncident,
        })}
        onClick={handleIncidentMerge}
        disabled={disabled}
      >
        Merge
      </button>
    </div>
  )
}

NearbyIncidentPreview.propTypes = {
  incident: object,
  mergingIncident: object,
  setMerging: func,
  disabled: bool,
}

export default NearbyIncidentPreview
