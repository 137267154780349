import classNames from "classnames";
import styled from "styled-components";

const LeadingContent = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__leading-content"
      )
    };
  }
)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 0;
`;

LeadingContent.propTypes = {};

LeadingContent.defaultProps = {};

export default LeadingContent;
