import classNames from "classnames";
import styled from "styled-components";

import { SmallText } from "@guardian/UI/Typography";

const InfoGroupBody = styled(SmallText).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__session__info-group-body"
    )
  };
})`
  color: var(--colors-white);
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-small);
`;

InfoGroupBody.defaultProps = {};

export default InfoGroupBody;
