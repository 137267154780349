import React from "react";

import MessageStatus from "./components/MessageStatus";
import TimeAndPlaceStatus from "./components/TimeAndPlaceStatus";
import UnreadCount from "./components/UnreadCount";
import UserAvatar from "./components/UserAvatar";
import UserName from "./components/UserName";
import BottomRow from "./views/BottomRow";
import ChatDetails from "./views/ChatDetails";
import LeadingContent from "./views/LeadingContent";
import StyledQueueListItem from "./views/QueueListItem";
import TopRow from "./views/TopRow";
import TrailingContent from "./views/TrailingContent";

const QueueListItem = props => {
  const {
    isActive,
    session
  } = props;

  const {
    id: sessionId,
    userId
  } = session;

  return (
    <StyledQueueListItem
      isActive={isActive}
      to={`/sos_dms/${ sessionId }/chat`}
    >
      <UserAvatar session={session}/>
      <ChatDetails>
        <TopRow>
          <LeadingContent>
            <UserName session={session}/>
          </LeadingContent>
          <TrailingContent>
            <TimeAndPlaceStatus session={session}/>
          </TrailingContent>
        </TopRow>
         <BottomRow>
          <LeadingContent>
            <MessageStatus session={session}/>
          </LeadingContent>
          <TrailingContent>
            <UnreadCount session={session}/>
          </TrailingContent>
        </BottomRow>
      </ChatDetails>
    </StyledQueueListItem>
  );
};

export default QueueListItem;
