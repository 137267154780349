import { from } from "rxjs";

import { OptimusAPI } from "@guardian/API/Optimus";

export async function getPOSConfig() {
  const resp = await OptimusAPI.get(`/get_pos_config`)
  return resp.data
}

export function getProtectSession(sessionId) {
  return from(
    OptimusAPI.get(`/v1/protect/session/${sessionId}`),
  )
}

export function endProtectSession(sessionId) {
  return from(
    OptimusAPI.delete(`/v1/protect/session/${sessionId}`),
  )
}

export function dialOutProtectSession(sessionId, number) {
  return from(
    OptimusAPI.patch(
      `/v1/protect/session/${sessionId}/agent/action`,
      {
        dialOut: number && number.replaceAll('-', '')
      },
    ),
  )
}

export function sendDTMFToneToProtectSession(sessionId, dtmfToneParams={}) {
  return from(
    OptimusAPI.patch(
      `/v1/protect/session/${sessionId}/agent/action`,
      dtmfToneParams,
    ),
  )
}

export function agentJoinSession(sessionId) {
  return from(
    OptimusAPI.post(
      `/v1/protect/session/${sessionId}/agent`,
      {},
    ),
  )
}

export function getProtectSessionRecords(sessionId) {
  return from(
    OptimusAPI.get(`/v1/protect/session/${sessionId}/records`),
  )
}

export function getProtectSessionOwner(sessionId) {
  return from(
    OptimusAPI.get(`/v1/protect/session/${sessionId}/owner`),
  )
}

export function endProtectCall(sessionId) {
  return from(
    OptimusAPI.patch(
      `/v1/protect/session/${sessionId}/agent/action`,
      {
        endCall: true,
      },
    ),
  )
}

export function getSessionNotes(sessionId) {
  return from(
    OptimusAPI.get(
      `/v1/protect/session/${sessionId}/notes`
    )
  )
}

export function postSessionNote(sessionId, text) {
  return from(
    OptimusAPI.post(
      `/v1/protect/session/${sessionId}/notes`,
      { text }
    )
  )
}

export function getProtectSafetyFeed(sessionId) {
  return from(
    OptimusAPI.get(
      `/v1/protect/session/${sessionId}/safety_feed`
    )
  )
}

export function postProtectCallbackNotif(sessionId) {
  return from(
    OptimusAPI.patch(
      `/v1/protect/session/${sessionId}/agent/action`,
      { sendCallbackNotif: true }
    )
  )
}

export function notifyProtectEmergencyContacts(
  sessionId,
  contacts,
  offPlatformContacts,
) {
  return from(
    OptimusAPI.patch(
      `/v1/protect/session/${sessionId}/agent/action`,
      {
        emergencyContactsToNotify: contacts,
        offPlatformContactsToNotify: offPlatformContacts,
      }
    )
  )
}

export function getAgentScripts(sessionId) {
  return from(
    OptimusAPI.get(
      `/v1/protect/scripts?${
        sessionId ? `sessionID=${sessionId}&` : ""
      }include=zingtree`
    )
  )
}

export function patchAgentScriptsAction(sessionId, action) {
  return from(
    OptimusAPI.patch(
      `/v1/protect/session/${sessionId}/agent/action`,
      action
    )
  )
}

export function sendCallbackToUserID(userID) {
  return from(
    OptimusAPI.patch(
      "/v1/protect/agent_actions",
      {
        callUserId: userID,
      },
    ),
  )
}

export const patchVideoControl = (sessionId, data) => {
  return from(
    OptimusAPI.patch(`/v1/protect/session/${sessionId}/video_control`, data),
  )
}

export default {
  getPOSConfig,
  getProtectSession,
  endProtectSession,
  dialOutProtectSession,
  sendDTMFToneToProtectSession,
  agentJoinSession,
  getProtectSessionRecords,
  getProtectSessionOwner,
  endProtectCall,
  getSessionNotes,
  postSessionNote,
  getProtectSafetyFeed,
  postProtectCallbackNotif,
  notifyProtectEmergencyContacts,
  getAgentScripts,
  patchAgentScriptsAction,
  sendCallbackToUserID,
  patchVideoControl
};
