import { OptimusAPI } from "@guardian/API/Optimus";

const Signal = {
  getServiceAreaCode: (lat: number, long: number, options: any = {}) => {
    return OptimusAPI.get(
      `/signal/service_area_code`,
      {
        ...options,
        params: {
          ...options.params,
          lat,
          long
        }
      }
    );
  },

  getAutoSuggestTags: (title: string, options: any = {}) => {
    return OptimusAPI.get(
      `/v1/tags/auto_suggest`,
      {
        ...options,
        params: {
          ...options.params,
          title
        }
      }
    );
  },

  getTags: (options: any = {}) => {
    return OptimusAPI.get(
      `/signal/tags`,
      {
        ...options,
        params: {
          ...options.params,
          type: "ml"
        }
      }
    );
  },

  getFullSuggestedRadius: (
    lat: number,
    long: number,
    level: number,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/signal/full_suggested_radius`,
      {
        ...options,
        params: {
          ...options.params,
          lat,
          long,
          level
        }
      }
    );
  },

  getIncidentLocationSuggestions: (
    text: string,
    channel?: string,
    serviceArea?: string,
    lat?: number,
    long?: number,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/signal/incident_location_suggestion`,
      {
        ...options,
        params: {
          ...options.params,
          text,
          channel,
          serviceArea,
          lat,
          long
        }
      }
    );
  },

  getLocationSuggestions: (
    text: string,
    lat?: number,
    long?: number,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/signal/location_suggestion`,
      {
        ...options,
        params: {
          ...options.params,
          text,
          lat,
          long
        }
      }
    );
  },

  getGeocodeLocationSuggestions: (
    text: string,
    magicKey?: string,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/signal/geocode_location_suggestion`,
      {
        ...options,
        params: {
          ...options.params,
          text,
          magicKey
        }
      }
    );
  },

  getFeatureLayers: (options: any = {}) => {
    return OptimusAPI.get(`/signal/feature_layers`, options);
  },

  queryFeatureLayer: (layerId: string, query: string, options: any = {}) => {
    return OptimusAPI.get(
      `/signal/feature_layers/${ layerId }/query`,
      {
        ...options,
        params: {
          ...options.params,
          query
        }
      }
    );
  },

  reverseGeocode: (lat: number, long: number, options: any = {}) => {
    return OptimusAPI.get(
      `/signal/reverse_geocode`,
      {
        ...options,
        params: {
          ...options.params,
          lat,
          long
        }
      }
    );
  },

  emergencyGeocode: (
    address: string,
    lat: number,
    long: number,
    options: any = {}
  ) => {
    return OptimusAPI.get(
      `/signal/geocode_location_suggestion`,
      {
        ...options,
        params: {
          ...options.params,
          address,
          lat,
          long
        }
      }
    );
  }
};

export default Signal;
