import classnames from "classnames"
import { array, bool, func, object } from "prop-types"
import React, { useRef } from "react"
import { Link } from "react-router-dom"

import useClickOutside from "@guardian/Hooks/useClickOutside"
import { shouldReplace } from "@guardian/Utils/history"

import LinkHelicopterMenu from "../LinkHelicopterMenu"
import style from "./Markers.module.css"

const IncidentMarker = ({
  model,
  selected,
  helicopterIncidents,
  showMenu,
  setShowMenu,
}) => {
  const menuRef = useRef()

  useClickOutside([menuRef], () => setShowMenu(""), !!showMenu)

  const showContextMenu = e => {
    e.preventDefault()
    setShowMenu(model.id)
    return false
  }

  const body = (
    <div
      ref={menuRef}
      className={style.centerContainer}
      onContextMenu={showContextMenu}
    >
      {model.title && (
        <div className={style.tooltip}>
          <h4>{model.title}</h4>
        </div>
      )}
      <div
        className={classnames(style.incident, {
          [style.selected]: selected,
          [style.active]: model.active,
          [style.pending]: model.pending,
        })}
      />
      {showMenu && (
        <LinkHelicopterMenu
          helicopterIncidents={helicopterIncidents}
          baseIncident={model}
        />
      )}
    </div>
  )
  if (model.link) {
    let pathname = `/incidents/${model.id}`
    if (window.location.href.includes("news")) {
      pathname = `/news${pathname}`
    }

    return (
      <Link to={pathname} replace={shouldReplace(pathname)}>
        {body}
      </Link>
    )
  }

  return body
}

IncidentMarker.propTypes = {
  model: object.isRequired,
  selected: bool,
  helicopterIncidents: array,
  setShowMenu: func,
  showMenu: bool,
}

export default IncidentMarker
