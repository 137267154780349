import classNames from "classnames";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  AUTO: "auto"
};

const Menu = styled.ul.attrs(
  props => {
    const {
      className,
      isAnimated,
      size
    } = props;

    return {
      className: classNames(className, "dropdown__menu", {
        "dropdown__menu--is-animated": isAnimated,
        "dropdown__menu--small": size == SIZES.SMALL,
        "dropdown__menu--medium": size == SIZES.MEDIUM
      })
    };
  }
)`
  ${({ maxHeight }) => css`
    max-height: ${ maxHeight };
    overflow-y: auto;
    position: static !important;

    &.dropdown__menu--small {
      width: 180px;
    }

    &.dropdown__menu--medium {
      width: 360px;
    }
  `}
`;

Menu.propTypes = {
  size: PropTypes.oneOf([
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.AUTO
  ]).isRequired
}

Menu.defaultProps = {
  size: "auto"
};

export default Menu;
