import { func, number, object, string } from "prop-types"
import { useCallback, useMemo } from "react"

import consts from "@guardian/Constants"
import { useGlobalState } from "@guardian/State"

import useKeyBindings from "@guardian/Hooks/useKeyBindings"
import { ContentService } from "@guardian/Services/Content"
import { IncidentService } from "@guardian/Services/Incident"

import style from "./LiveTabVideoPlayer.module.css"

const RejectionMenu = ({
  stream,
  incidentAuthor,
  incidentStreamersCount,
  streamHandled,
}) => {
  const rejectionChoices = useGlobalState(
    state => state.moderation.rejectionChoices,
  )

  const rejectOrBlockStream = useCallback(
    async option => {
      const rejectionReason = rejectionChoices[option]
      if (stream.incidentId) {
        await ContentService.blockContent(
          "video_stream",
          stream.id,
          "Live Tab",
          { reason: rejectionReason },
        )
        if (
          incidentAuthor === consts.AutomatedUsername &&
          incidentStreamersCount <= 1
        ) {
          // Also archive the incident if it's a P2P incident and not Citizen Approved
          // (i.e.: Unmoderated OR Community Approved)
          try {
            await IncidentService.updateIncident(stream.incidentId, {
              deleted: true,
            })
          } catch (error) {
            console.error(error)
          }
        }
        streamHandled("Blocked")
      } else {
        await ContentService.rejectContent(
          "video_stream",
          stream.id,
          "Live Tab",
          { reason: rejectionReason },
        )
        streamHandled("Rejected")
      }
    },
    [
      rejectionChoices,
      stream.id,
      stream.incidentId,
      incidentAuthor,
      incidentStreamersCount,
      streamHandled,
    ],
  )

  useKeyBindings(
    useMemo(() => {
      return Object.keys(rejectionChoices).reduce((acc, option, index) => {
        acc[`${index + 1}`] = () => rejectOrBlockStream(option)
        return acc
      }, {})
    }, [rejectionChoices, rejectOrBlockStream]),
  )

  return (
    <div className={style.rejectionMenu}>
      <div className={style.divider} />
      <div className={style.rejectionChoicesContainer}>
        <div className={style.titleText}>Select Block Reason:</div>
        {Object.keys(rejectionChoices).map((option, index) => (
          <div
            key={option}
            className={style.rejectionRow}
            onClick={() => rejectOrBlockStream(option)}
          >
            {`${option} (${index + 1})`}
          </div>
        ))}
      </div>
    </div>
  )
}

RejectionMenu.propTypes = {
  stream: object,
  incidentAuthor: string,
  incidentStreamersCount: number,
  streamHandled: func,
}

export default RejectionMenu
