export const getContentId = c => {
  switch (c.type) {
    case "image":
      return c.image.id
    case "video_stream":
      return c.videoStream.id
    default:
      console.error(`Unknown type ${c.type}`)
  }
}

export const composeUrl = (type, id) => {
  switch (type) {
    case "image":
      return `/psyduck/images/${id}`
    case "video_stream":
      return `/psyduck/streams/${id}`
    default:
      console.error(`Unknown type ${type}`)
  }
}
