import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

import StyledTimeAndPlaceStatus from "./views/TimeAndPlaceStatus";

// Extend dayjs to retrieve time ago, relative to user.
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "Now",
    m: "1 min",
    mm: "%d mins",
    h: "1 hr",
    hh: "%d hrs",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years"
  }
});

const TimeAndPlaceStatus = (props) => {
  const {
    session: {
      lastMessageAt,
      lastMessageBetweenAgentAndUserAt,
      locationName
    }
  } = props;

  const [formattedLastMessageAt, setFormattedLastMessageAt] = useState(() => {
    if (lastMessageAt == null) { return ""; }
    return dayjs(lastMessageAt).fromNow(true);
  });

  useEffect(() => {
    if (lastMessageAt == null) { return; }

    let timeout;

    const lastMessageAtDate = dayjs(lastMessageAt);

    const update = () => {
      setFormattedLastMessageAt(lastMessageAtDate.fromNow(true));
    }

    const loop = (delay=1000) => {
      timeout = setTimeout(() => {
        update();

        const age = dayjs().diff(lastMessageAtDate, "minute");

        let nextDelay;
        if (age < 1) {
          // Update every second if less than a minute old.
          nextDelay = 1000;
        } else if (age < 60) {
          // Update every minute if less than an hour old.
          nextDelay = 1000 * 60;
        } else {
          // Update every hour if older than an hour old.
          nextDelay = 1000 * 60 * 60;
        }

        loop(nextDelay);
      }, delay);
    }

    update();
    loop();

    return () => {
      clearTimeout(timeout);
    }
  }, [lastMessageAt]);

  const renderStatus = () => {
    let status = "";

    // If the user and agent have not yet directly communicated, but there is a
    // location name (e.g.; Shield is active), set status to the location name.
    if (locationName && lastMessageBetweenAgentAndUserAt == null) {
      status = locationName;
    } else if (formattedLastMessageAt) {
      status = formattedLastMessageAt;
    }

    return status;
  }

  return (
    <StyledTimeAndPlaceStatus>
      {
        renderStatus()
      }
    </StyledTimeAndPlaceStatus>
  );
};

export default TimeAndPlaceStatus;
