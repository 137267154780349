import classNames from "classnames";
import styled from "styled-components";

const TabList = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "tab__tab-list")
    };
  }
)`
  align-items: center;
  box-shadow: var(--borders-bottom) var(--colors-grey-085);
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  padding: 0 var(--space-medium);
  width: 100%;
`;

TabList.defaultProps = {};

export default TabList;
