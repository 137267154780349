import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

const VALIDATION_TYPES = {
  ERROR: "error",
  GENERIC: "generic",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning"
};

const Message = styled.div.attrs(
  props => {
    const {
      validation
    } = props;

    return {
      className: classNames("form__message", {
          "form__message--validation-error":
            validation == VALIDATION_TYPES.ERROR,
          "form__message--validation-generic":
            validation == VALIDATION_TYPES.GENERIC,
          "form__message--validation-info":
            validation == VALIDATION_TYPES.INFO,
          "form__message--validation-success":
            validation == VALIDATION_TYPES.SUCCESS,
          "form__message--validation-warning":
            validation == VALIDATION_TYPES.WARNING,
        })
    };
  }
)`
  color: var(--colors-grey-050);
  display: block;
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-small);
  margin-top: var(--space-x-small);
  max-width: var(--measures-small);

  &.form__message--validation-error {
    color: var(--colors-red-040);
  }

  &.form__message--validation-info {
    color: var(--colors-blue-040);
  }

  &.form__message--validation-success {
    color: var(--colors-green-040);
  }

  &.form__message--validation-warning {
    color: var(--colors-yellow-040);
  }
`;

Message.propTypes = {
  validation: PropTypes.oneOf(
    [
      VALIDATION_TYPES.ERROR,
      VALIDATION_TYPES.GENERIC,
      VALIDATION_TYPES.INFO,
      VALIDATION_TYPES.SUCCESS,
      VALIDATION_TYPES.WARNING
    ]
  )
};

Message.defaultProps = {
  validation: VALIDATION_TYPES.GENERIC
};

export default Message;
