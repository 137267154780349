import React from "react"
import classnames from "classnames"

import { Text } from "@guardian/UI/Legacy/Text"

import style from "./EndAdornmentButton.module.css"

interface IProps {
  onClick: () => void
  label: string
  active: boolean
}

const EndAdornmentButton = ({ onClick, label, active }: IProps) => (
  <div
    className={classnames(style.container, { [style.active]: active })}
    onClick={onClick}
  >
    <Text
      size='small'
      weight='semibold'
      color={active ? "lightblue" : "#666666"}
    >
      {label}
    </Text>
  </div>
)

export default EndAdornmentButton
