import React, { useMemo } from "react";

import StyledUnreadCount from "./views/UnreadCount";

const UnreadCount = React.memo(function UnreadCount(props) {
  const {
    session: {
      unreadCount
    }
  } = props;

  const count = useMemo(() => {
    let count = unreadCount;

    if (count > 99) {
      count = "99+";
    }

    return count;
  }, [unreadCount]);

  if (count === 0) {
    return null;
  }

  return (
    <StyledUnreadCount>
      { count }
    </StyledUnreadCount>
  );
});

export default UnreadCount;
