import React from "react";

import useDropdownContext from "../../hooks/useDropdownContext";
import useSelectContext from "../../hooks/useSelectContext";
import StyledClear from "../../views/Select/Clear";

const Clear = props => {
  const {
    downshift: { clearSelection }
  } = useDropdownContext();
  const {
    setIsClearHovered
  } = useSelectContext();

  const clearProps = {
    onClick: e => {
      e.preventDefault();
      e.stopPropagation();

      clearSelection();
    },
    onMouseEnter: e => {
      setIsClearHovered(true);
    },
    onMouseLeave: e => {
      setIsClearHovered(false);
    },
    ...props
  };

  return (
    <StyledClear {...clearProps} />
  );
};

export default Clear;
