// All line heights are 1.2 times their respective font size.
const lineHeights = [
  "15px", // xSmall
  "18px", // small
  "21px", // medium
  "24px", // large
  "27px", // xLarge
];

// Aliases
lineHeights.xSmall = lineHeights[0];
lineHeights.small = lineHeights[1];
lineHeights.medium = lineHeights[2];
lineHeights.large = lineHeights[3];
lineHeights.xLarge = lineHeights[4];

export const lineHeightsCSSVariables = `
  :root {
    --line-heights-x-small: ${ lineHeights.xSmall };
    --line-heights-small: ${ lineHeights.small };
    --line-heights-medium: ${ lineHeights.medium };
    --line-heights-large: ${ lineHeights.large };
    --line-heights-x-large: ${ lineHeights.xLarge };
  }
`;

export default lineHeights;
