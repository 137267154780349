import React, { useContext } from "react";
import queryString from "query-string";

import { Box } from "@guardian/UI/Box";
import { Anchor, Button, ButtonIcon, ButtonGroup } from "@guardian/UI/Button";
import { Icon } from "@guardian/UI/Icon";
import { Spinner } from "@guardian/UI/Loader";
import {
  Body,
  Close,
  Footer,
  Header,
  Modal
} from "@guardian/UI/Modal";
import { MediumParagraph } from "@guardian/UI/Paragraph";
import { LargeText } from "@guardian/UI/Typography";
import { DMTabContext } from '@guardian/Tabs/ModSOSDirectMessaging'

const FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSdI_U0Co9HR24KVWWyZP0YIJrB3d2PA8VHuGNaMmQOutzemGQ/viewform"
const FORM_SESSION_ID_PARAM = "entry.723717549"

const ResolveConfirmationModal = props => {
  const {
    locked,
    loading,
    onClose,
    onConfirm,
    onExit,
    open
  } = props;

  const {
    activeAgentChatSessionId
  } = useContext(DMTabContext);

  const query = {
    usp: "pp_url",
    [FORM_SESSION_ID_PARAM]: activeAgentChatSessionId,
  }
  const parsedFormUrl = queryString.stringifyUrl({ url: FORM_URL, query })

  return (
    <Modal
      backdropProps={
        {
          closeDisabled: locked
        }
      }
      onClose={onClose}
      onExit={onExit}
      open={open}
    >
      <Header>Resolve Agent Chat Session</Header>
      <Body>
        <LargeText>
          Confirm Intention to Resolve this DM Session
        </LargeText>
        <Box mt="small">
          <MediumParagraph>
            Resolving an Agent Chat Session is irreversible and will immediately
            end your Agent Chat Session with the user. Please confirm your
            intention to proceed.
          </MediumParagraph>
          <MediumParagraph>
            Pleae remember to{" "}
            <Anchor
              forceUnderline
              href={parsedFormUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              fill out the Google Form
            </Anchor>
            {" "}when resolving Agent Chat Sessions.
          </MediumParagraph>
        </Box>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button disabled={locked} isLink onClick={onClose} tightenY>
            Cancel
          </Button>
          <Button disabled={locked} isPrimary onClick={onConfirm} tightenY>
            Confirm
            <ButtonIcon.End>
              {
                loading ? (
                  <Spinner/>
                ) : (
                  <Icon.Check/>
                )
              }
            </ButtonIcon.End>
          </Button>
        </ButtonGroup>
      </Footer>
      <Close disabled={locked}/>
    </Modal>
  );
};

export default ResolveConfirmationModal;
