import styled from "styled-components";

interface NavigationBarProps {
  warn: boolean
};

const NavigationBar = styled.nav<NavigationBarProps>`
  background-color: var(--colors-black);
  display: flex;
  flex-direction: row;
  height: 60px;
  min-width: 100%;
  position: relative;
  width: max-content;

  ${({ warn }: NavigationBarProps) => warn && `
    background-color: var(--colors-red-095);

    &::after {
      bottom: 0;
      box-shadow: var(--borders-highlight-bottom) var(--colors-red-040);
      content: "";
      height: 2px;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  `}
`;

export default NavigationBar;
