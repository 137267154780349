import React from "react";

import { Row } from "@guardian/UI/Grid";
import { SwitchTransition } from "@guardian/UI/SwitchTransition";

import TabsPanel from "./components/TabsPanel";
import UserInfoPanel from "./components/UserInfoPanel";

const Active = props => {
  const { session } = props;

  return (
    <SwitchTransition
      activeChildKey={session.id}
      transitionMs={Active.transitionMs}
    >
      <Row
        style={{
          height: "100%",
          flexWrap: "no-wrap"
        }}
      >
        <TabsPanel session={session}/>
        <UserInfoPanel session={session}/>
      </Row>
    </SwitchTransition>
  );
};

Active.transitionMs = 250;

export default Active;
