import { List } from "immutable"
import _ from "lodash"

import consts from "@guardian/Constants"

import { track } from "@guardian/Analytics/segment"
import { Signal } from "@guardian/API/Optimus"
import { IncidentService } from "@guardian/Services/Incident"
import { readFlaggedConfig } from "@guardian/Utils/clips"
import { push, replace } from "@guardian/Utils/history"
import * as incUtil from "@guardian/Utils/incident"
import { cleanIncidentUpdate } from "@guardian/Utils/util"

const incidents = state => {

  state.on("createUpdateRev", async spec => {
    if (spec.text) {
      spec.text = cleanIncidentUpdate(spec.text)
    }

    // This is a new incident coming from an IGL, send to sickmint
    if (spec.source === consts.Source.IGL && !spec.rootRevId) {
      track("IGL Incident Creation", {
        videoStreamId: spec.iglStreamId || spec.content.contentId,
      })
    }

    if (spec.clipIds && spec.clipIds.length) {
      const attachedClips = state.get().incidentComposer.attachedClips
      spec.clipIds.forEach(clipId => {
        const { clips, flaggedConfig, channels } = state.get().radio
        let clip = clips.find(c => c.id === clipId)
        if (!clip) {
          // If clip has exited visual queue, it should still be findable and atachable
          clip = attachedClips.find(c => {
            return c.id === clipId
          })
        }
        const channel = channels[clip.channel]

        const { flaggingSupport } = readFlaggedConfig(
          flaggedConfig,
          channel.serviceArea,
          channel.subArea,
        )

        if (clip && flaggingSupport) {
          state.emit("allowlist channel", clip.channel)
        }
      })
    }

    if (spec.text === "") {
      state.emit(
        "show error modal",
        new Error(
          "Invalid updates are not allowed. Check to make sure your update is valid.",
        ),
      )
      return
    }

    return await IncidentService.updateIncidentRevision(spec)
  })

  async function fetchIncident2s({ ids, filterPending }) {
    const currentlySelectedId = state.get().moderation.selectedIncident
    const incidents = state.get().moderation.incidents
    let currentSelected = null
    if (currentlySelectedId && currentlySelectedId.startsWith("-")) {
      ids.push(currentlySelectedId)
      ids = _.uniq(ids)
      currentSelected = _.find(incidents, { id: currentlySelectedId })
    }

    let incs = await IncidentService.getIncidents({ incidentIds: ids })
    const { user } = state.get().global
    incs = incs.filter(
      i =>
        i && (!filterPending || incUtil.isPublicOrAuthors(i, user && user.id)),
    )
    incs.forEach(inc => {
      inc.updates = inc.updates.filter(incUtil.isRelevantUpdate)
    })
    let newSelectedIncidentId

    const finalIncidents = incs.filter(i => {
      if (
        (i.location &&
          i.location.cityCode &&
          state.get().global.allowlist &&
          !state.get().global.allowlist[i.location.cityCode.toUpperCase()]) ||
        i.deleted
      ) {
        return false
      }
      const { title, location } = i
      if (!i.location || (i.location && (!i.location.lat || !i.location.lng))) {
        return false
      }

      return title && location
    })

    // avoid flickering if optimus is feeling lazy and laggy
    if (
      currentSelected &&
      !_.find(
        finalIncidents,
        i => incUtil.invariantId(i) === incUtil.invariantId(currentSelected),
      )
    ) {
      finalIncidents.push(currentSelected)
    }

    incUtil.sortIncidents(finalIncidents, state.get().moderation.incidentSortBy)
    return { incidents: finalIncidents, newSelectedIncidentId }
  }

  state.on("modFetchIncidents", async () => {
    let {
      selectedIncident,
      serviceAreas,
      incidentTimeRange: { since, until },
      outstandingIncidentsFetch
    } = state.get().moderation
    const now = new Date()
    const ts = now.getTime() / 1000
    if (
      outstandingIncidentsFetch != null &&
      ts - outstandingIncidentsFetch < 30
    ) {
      // If there's another outstanding call to get incidents, we should wait on that to finish before refreshing
      state.get().moderation.set("earlyExitIncidentsFetch", ts)
      return
    }
    try {
      const query = {
        since: new Date(Date.now() - since),
        until: new Date(Date.now() - until),
        serviceAreas: Object.keys(serviceAreas),
      }
      const ids = await IncidentService.queryIncidents(query, true)
      state.get().moderation.set("outstandingIncidentsFetch", ts)
      let { incidents, newSelectedIncidentId } = await fetchIncident2s({
        ids,
      })
      state.get().moderation.set({ incidents, outstandingIncidentsFetch: null })

      let { earlyExitIncidentsFetch } = state.get().moderation
      if (earlyExitIncidentsFetch != null && earlyExitIncidentsFetch > ts) {
        state.emit("modFetchIncidents")
      }

      if (
        newSelectedIncidentId &&
        newSelectedIncidentId !== selectedIncident &&
        _.get(window, "location.href", "").includes("incidents")
      ) {
        // since old id is invalid, remove oldId path from history stack
        replace(`/incidents/${newSelectedIncidentId}`)
        return
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  })

  state.on("insertModIncident", incident => {
    const oldIncs = state.get().moderation.incidents
    const newIncs = _.uniqBy(
      [...oldIncs, incident].filter(inc => !!inc),
      incUtil.invariantId,
    )
    incUtil.sortIncidents(newIncs, state.get().moderation.incidentSortBy)
    state.get().moderation.set("incidents", newIncs)
    if (incident) {
      push(`/incidents/${incident.id}`)
    }
  })

  state.on("setModIncidentTimeRange", async params => {
    const old = state.get().moderation.incidentTimeRange
    if (!_.isEqual(old, params)) {
      state.get().moderation.set("incidentTimeRange", params)
      await state.emit("modFetchIncidents")
    }
  })

  state.on("setModIncidentSortBy", async params => {
    const old = state.get().moderation.incidentSortBy
    if (!_.isEqual(old, params)) {
      state.get().moderation.set("incidentSortBy", params)
      await state.emit("modFetchIncidents")
    }
  })

  let modFetchIncidentsInterval
  state.on("startUpdatingModIncidents", () => {
    clearInterval(modFetchIncidentsInterval)
    modFetchIncidentsInterval = setInterval(
      () => state.emit("modFetchIncidents"),
      10000,
    )
    state.emit("modFetchIncidents")
  })
  state.on("stopUpdatingModIncidents", () => {
    clearInterval(modFetchIncidentsInterval)
  })

  state.on("loadIncident2", async id => {
    if (id === "tutorial-incident") {
      return state.get().incidentComposer.tutorialIncident
    }
    const incs = await IncidentService.getIncidents({ incidentIds: [id] })
    let incident
    if (incs.length > 0) {
      incident = incs[0]
    }
    const previous = state
      .get()
      .incidentComposer.incidentFeed.find(i => i.id === id)
    if (previous) {
      previous.reset(incident)
    }
    return incident
  })

  state.on("attach clip", clipId => {
    let attachedClips = state.get().incidentComposer.attachedClips
    const index = attachedClips.findIndex(c => clipId === c.id)
    const clip = state.get().radio.clipsView.find(c => c.id === clipId)

    if (!clip) {
      return
    }

    if (index === -1) {
      const channel = state.get().radio.channels[clip.channel]
      const attachedClip = {
        ...clip,
        channelHeader: channel.name,
        serviceArea: channel.serviceArea,
        durationMs: clip.duration_ms,
      }
      attachedClips = attachedClips.push(attachedClip)
    }

    state.get().incidentComposer.set("attachedClips", attachedClips)
  })

  state.on("toggle attach clip", clipId => {
    let attachedClips = state.get().incidentComposer.attachedClips
    const index = attachedClips.findIndex(c => clipId === c.id)
    const clip = state.get().radio.clipsView.find(c => c.id === clipId)

    if (!clip) {
      return
    }

    if (index === -1) {
      state.emit("attach clip", clipId)
    } else {
      state.emit("remove attached clip", clipId)
    }
  })

  state.on("resetAttachedClips", () => {
    state.get().incidentComposer.set("attachedClips", new List())
  })

  state.on("remove attached clip", id => {
    let attachedClips = state.get().incidentComposer.attachedClips
    const index = attachedClips.findIndex(c => c.id === id)

    if (index === -1) {
      return
    }

    state
      .get()
      .incidentComposer.set("attachedClips", attachedClips.delete(index))
  })

  state.on("selectIncident", async incId => {
    if (incId === undefined) {
      state.get().incidentComposer.set("selectedIncident", undefined)
      return
    }
    let incident = state
      .get()
      .incidentComposer.incidentFeed.find(inc => inc.id === incId)
    if (!incident) {
      incident = await state.emit("loadIncident2", incId)
      state
        .get()
        .incidentComposer.incidentFeed.set(
          state.get().incidentComposer.incidentFeed.length,
          incident,
        )
    }
    state.get().incidentComposer.set("selectedIncident", incId)
  })

  state.on("modSelectIncident", async (incId, preserveAttachedClips) => {
    const old = state.get().moderation.selectedIncident
    if (old === incId) {
      return
    }
    state.emit("remove polygon", old)
    state.emit("remove polygon", "geography")
    state.get().moderation.set("selectedIncident", incId)

    if (!incId) {
      return
    }
    let inc = state.get().moderation.incidents.find(inc => inc.id === incId)
    if (!inc) {
      inc = await state.emit("loadIncident2", incId)
      state.emit("insertModIncident", inc)
    } else {
      state.emit("set map", {
        center: { lat: inc.location.lat, lng: inc.location.lng },
        zoom: 17,
      })
    }
  })

  state.on("updateSelectedIncident", async () => {
    const incidentId = state.get().moderation.selectedIncident
    if (incidentId) {
      const incident = await state.emit("loadIncident2", incidentId)
      const index = state
        .get()
        .moderation.incidents.findIndex(i => i.id === incidentId)
      if (index > -1) {
        state.get().moderation.incidents.set(index, incident)
      }
    }
  })

  state.on("fetch incident tags", async () => {
    const { data: { results: tags } } = await Signal.getTags()

    state.get().global.set("tags", tags)
  })

  state.on("deleteCitywide", async incidentId => {
    IncidentService.deleteIncidentCitywide(incidentId)
  })
}

export default incidents
