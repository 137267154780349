import React from "react";

import InputField from "./components/InputField";
import StyledInputArea from "./views/InputArea";

const InputArea = props => {
  const {
    session: {
      endedAt,
      groupId,
      members,
      userId
    }
  } = props;

  const userName = members && members[userId] ? members[userId].shortName : '[unknown member for userId ' + userId + ']'
  const isResolved = endedAt != null;

  return (
    <StyledInputArea>
      <InputField groupId={groupId} isResolved={isResolved} />
    </StyledInputArea>
  );
};

export default InputArea;
