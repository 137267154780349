import React from "react"

const EmptyPrompt = () => (
  <div className='add-prompt'>
    <img src='/emptyset.svg' alt='point-up' />
    <div className='add-prompt-text'>
      These channels have been quiet for a while. Try adding more channels to
      discover what’s going on.
    </div>
  </div>
)

export default EmptyPrompt
