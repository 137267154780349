import Container from "@guardian/Components/Common/Container"
import React from "react"
import styles from "@guardian/Components/ModSOS/components/Map/NearbyModal/NearbyModal.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/Map/NearbyModal/types"

const NearbyModalRaw = ({ usersNearby }: IProps) => {
  return (
    <Container left={2} right={2} top={2} fullWidth>
      <Container
        className={styles.container}
        top={3}
        bottom={3}
        left={3}
        right={3}
        fullWidth
      >
        <Container className={styles.title}>
          <span>{usersNearby} Members</span> in 0.25 mi
        </Container>
      </Container>
    </Container>
  )
}

export const NearbyModal = React.memo(NearbyModalRaw)
