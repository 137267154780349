import React, { useCallback, useEffect, useRef } from "react";

import { ProtectSession } from "@guardian/Lib/QueryClient/hooks";

import NotesListItem from "./components/NotesListItem";
import StyledNotesList from "./views/NotesList";
import NotesListOuterWrapper from "./views/NotesListOuterWrapper";

const NotesList = props => {
  const { sessionId } = props;

  const {
    data: notes = [],
    isError,
    isLoading
  } = ProtectSession.useGetNotes(sessionId);

  const notesListRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    const notesList = notesListRef.current;

    if (notesList) {
      notesList.scrollTop = notesList.scrollHeight;
    }
  }, []);

  const setNotesListRef = useCallback((node) => {
    notesListRef.current = node;
    scrollToBottom();
  }, []);

  // Scroll to bottom when notes are added.
  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  return (
    <NotesListOuterWrapper>
      <StyledNotesList ref={notesListRef}>
        {
          notes.map((note, i) => (
            <NotesListItem note={note} key={`notes-list-item-${i}`}/>
          ))
        }
      </StyledNotesList>
    </NotesListOuterWrapper>
  );
};

export default NotesList;
