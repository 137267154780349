import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Spinner = props => {
  const { className, delayMs } = props;

  const [isDelayed, setIsDelayed] = useState(delayMs > 0);

  useEffect(() => {
    let timeout;

    if (delayMs) {
      timeout = setTimeout(() => {
        setIsDelayed(false);
      }, delayMs);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [delayMs]);

  if (isDelayed) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      <i className='spinner fas fa-spinner'/>
    </div>
  );
};

Spinner.propTypes = {
  delayMs: PropTypes.number
};

Spinner.defaultProps = {
  delayMs: 0
};

export default Spinner;
