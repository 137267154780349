import config from "@guardian/Config"
import _ from "lodash"

export const DEFAULT_SESSION = {
  apiKey: "",
  sessionId: "",
  token: "",
  onStreamsUpdated: () => {},
}

export const TOKBOX_API_KEY = config.tokboxApiKey!

export const OWNER = "owner"
export const OWNER_NONE = "NONE"

export const sendTwilioMessage = async (room: any, message: any) => {
  const dataTrack = _.get(
    room.localParticipant.dataTracks.values().next(),
    "value.track",
    {},
  )
  if (_.isEmpty(dataTrack)) return

  if (typeof message === "object") {
    message = JSON.stringify(message)
  }
  await dataTrack.send(message)
}
