import React, { useState } from "react";
import GoogleLogin from "react-google-login";

import config from "@guardian/Config";
import { LoggingService } from "@guardian/Services/Logging";
import { eventHub } from "@guardian/State";

import "./LoginFlow.scss";

const LoginFlow = props => {
  const [error, setError] = useState(null);

  const onGoogleSignIn = async googleUser => {
    const token = googleUser.getAuthResponse().id_token;

    try {
      await eventHub.emit("loginWithGoogle", token);
    } catch (e) {
      LoggingService.logError("Failed to authenticate after google sign in.", {
        domain: "LoginFlow",
        trackedData: {
          event: e,
          url: this.url,
        },
      });

      setError("Failed to authenticate after google sign in.");
    }
  }

  const onGoogleSignInFail = ({ error, details }) => {
    LoggingService.logError("Failed to sign in with google.", {
      domain: "LoginFlow",
      trackedData: {
        event: error,
        details,
        url: url,
      },
    });

    setError(`Failed to sign in with google: ${details}`);
  };

  return (
    <div className='login-flow'>
      <div className='logo-box'>
        <img src='/ProtectOS-Loading.svg' className='logo-img' alt='' />
      </div>
      <GoogleLogin
        clientId={config.googleClientId}
        buttonText='Citizen Sign In'
        onSuccess={onGoogleSignIn}
        onFailure={onGoogleSignInFail}
      />
      <div className='error'>{error}</div>
    </div>
  );
};

export default LoginFlow;

