import classnames from "classnames"
import dayjs from "dayjs"
import { bool, func, number, string } from "prop-types"

import calendar from "@guardian/Utils/dayjs/calendar"
import { dateToUTC } from "@guardian/Utils/util"

import style from "./NearbyIncident.module.css"

dayjs.extend(calendar)

const Incident = ({ title, distance, address, time, onMerge, id, merging }) => {
  return (
    <div
      className={classnames(style.incident, {
        [style.active]: merging,
      })}
    >
      <div className={style.title}>{title}</div>
      <div className={style.location}>
        ({distance}m) <span className={style.address}>{address}</span>
      </div>
      <div className={style.timestamp}>{dateToUTC(time).calendar(",")}</div>
      {onMerge && (
        <button
          className={classnames(style.merge, { [style.merging]: merging })}
          onClick={() => onMerge(id)}
        >
          {merging ? "Merging" : "Merge"}
        </button>
      )}
    </div>
  )
}

Incident.propTypes = {
  title: string,
  distance: number,
  address: string,
  time: string,
  onMerge: func,
  id: string,
  merging: bool,
}

export default Incident
