import React, { useEffect, useState } from "react"
import style from "./StreamList.module.css"
import { timer, zip, defer } from "rxjs"
import Stream from "@guardian/Components/Stream"
import { keyBy, flatten, get } from "lodash"
import { latestRev } from "@guardian/Utils/incident"
import { useGlobalState } from "@guardian/State"
import ToggleButton from "@guardian/Components/Form/ToggleButton"
import classnames from "classnames"

import { Users } from "@guardian/API/Optimus";
import { IncidentService } from "@guardian/Services/Incident";

const StreamList = () => {
  const users = useGlobalState(state => state.radio.streamsUsers || [])

  const [streams, setStreams] = useState([])
  const [incidents, setIncidents] = useState([])
  const [liveOnly, setLiveOnly] = useState(true)
  const [lastId, setLastId] = useState("")
  const [isLastPage, setLastPage] = useState(true)
  const PAGE_SIZE = 10

  const getStreamBatch = () => {
    const firstIndex = getFirstIndex()
    const lastIndex = firstIndex + PAGE_SIZE

    return streams
      .filter(({ videoStream }) => {
        return liveOnly ? !videoStream.hlsDone && !videoStream.hlsVodDone : true
      })
      .slice(firstIndex, lastIndex)
      .map(({ videoStream }) => {
        const incident = incidents[videoStream.incidentId]
        const latest = incident && latestRev(incident)
        const isLive = !videoStream.hlsDone && !videoStream.hlsVodDone

        return (
          <Stream
            key={videoStream.id}
            id={videoStream.id}
            url={isLive ? videoStream.hlsLiveUrl : videoStream.hlsVodUrl}
            isLive={isLive}
            verified={videoStream.confirmed}
            blocked={videoStream.blocked}
            incidentTitle={incident && incident.title}
            serviceArea={incident && incident.location.cityCode}
            analyst={latest && latest.authorUsername}
            incidentId={videoStream.incidentId}
            broadcaster={videoStream.username}
            allowEditing={false}
            showPublicUrl
          />
        )
      })
  }

  const nextStreams = () => {
    const firstIndex = getFirstIndex()
    const lastIndex = firstIndex + PAGE_SIZE
    if (lastIndex < streams.length) {
      const last = get(streams, `[${lastIndex}].videoStream.id`)
      setLastId(last)
      setLastPage(computeLastPage(last))
    }
  }

  const prevStreams = () => {
    const lastIndex = lastId
      ? Math.max(
          0,
          streams.findIndex(s => s.videoStream.id === lastId) - PAGE_SIZE,
        )
      : 0
    let last = ""
    if (lastIndex) {
      last = get(streams, `[${lastIndex}].videoStream.id`)
    }
    setLastId(last)
    setLastPage(computeLastPage(last))
  }

  const computeLastPage = last => {
    const count = streams.filter(({ videoStream }) =>
      liveOnly ? !videoStream.hlsDone && !videoStream.hlsVodDone : true,
    ).length

    return (
      count < PAGE_SIZE + 1 ||
      (last &&
        streams.findIndex(s => s.videoStream.id === last) + PAGE_SIZE > count)
    )
  }

  const getFirstIndex = () => {
    return lastId ? streams.findIndex(s => s.videoStream.id === lastId) : 0
  }

  useEffect(() => {
    let subscription
    if (users.length) {
      subscription = timer(0, 5000).subscribe(() =>
        zip(...users.map(id => defer(() => Users.getUserStreams(id)))).subscribe(
          ({ data }) => {
            const sorted = flatten(data.map(s => s.videoStreams))
              .filter(v => !!v.videoStream)
              .sort(
                (a, b) =>
                  new Date(b.videoStream.createdAt) -
                  new Date(a.videoStream.createdAt),
              )
            setStreams(sorted)
          },
        ),
      )
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [users])

  useEffect(() => {
    const subscription = defer(() =>
      IncidentService.getIncidents({
        incidentIds: streams.map(({ videoStream }) => videoStream.incidentId)
      })
    ).subscribe(inc => setIncidents(keyBy(inc, "id")))

    setLastPage(computeLastPage(lastId))

    return () => {
      subscription.unsubscribe()
    }
  }, [streams])

  return (
    <div className={style.container}>
      <div className={style.top}>
        <ToggleButton
          onLabel={liveOnly ? "Live Only" : "All Videos"}
          onChange={() => {
            setLiveOnly(!liveOnly)
            setLastId("")
            // want opposite; liveOnly state has not updated yet
            setLastPage(!computeLastPage(""))
          }}
          on={liveOnly}
        />
      </div>
      <div className={style.list}>
        {getStreamBatch()}
        <div className={style.buttonContainer}>
          <button
            className={classnames(style.buttonBase, style.buttonColor)}
            onClick={prevStreams}
            disabled={!lastId}
          >
            Previous
          </button>
          <button
            className={classnames(style.buttonBase, style.buttonColor)}
            onClick={nextStreams}
            disabled={isLastPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default StreamList
