import PubSub from "pubsub-js";

import { TYPES } from "../views/FlashMessages/FlashMessage";

const error = (message, options) =>
  PubSub.publish(TYPES.ERROR, { message, options });
const generic = (message, options) =>
  PubSub.publish(TYPES.GENERIC, { message, options });
const info = (message, options) =>
  PubSub.publish(TYPES.INFO, { message, options });
const success = (message, options) =>
  PubSub.publish(TYPES.SUCCESS, { message, options });
const warning = (message, options) =>
  PubSub.publish(TYPES.WARNING, { message, options });

const Flash = {
  error: error,
  generic: generic,
  info: info,
  success: success,
  warning: warning
};

export default Flash;
