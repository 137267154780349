import React, { useEffect, useRef, useState } from "react"
import { get, isObject } from "lodash"
import queryString from "query-string"
import dayjs from "dayjs"

import styles from "@guardian/Components/ModSOS/components/InfoPane/SOSInfoPane/SOSInfoPane.module.css"
import titleCase from "@guardian/Utils/titleize"
import Container from "@guardian/Components/Common/Container"
import { AgentScriptsButtonExpandable } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsButtonExpandable/AgentScriptsButtonExpandable"
import { AgentScriptsDraggable } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsDraggable/AgentScriptsDraggable"
import { IProps } from "@guardian/Components/ModSOS/components/InfoPane/SOSInfoPane/types"
import { useSOSDashboardContext } from '@guardian/Components/ModSOS/store/dashboard/DashboardContext'

const TITLE = "User Info"

const REQUESTED_911 = "requested911"
const SESSION_ID = "session id"
const SUBSCRIPTION_TYPE = "subscription type"
const SUBSCRIPTION_STATUS = "subscription status"
const SUBSCRIBER_SINCE = "subscriber since"
const SUBSCRIPTION_PRICE = "subscription price"
const SUBSCRIPTION_BILLING_PERIOD = "subscription billing period"
const SUBSCRIPTION_EXPIRES_DATE = "subscription expires date"
const PREVIOUS_CALLS = "previous calls"
const JOIN_DATE = "join date"
const SAFETY_PROFILE = "safety profile"
const NAME = "name"
const PHONE = "phone"
const PLATFORM = "platform"
const SERVICE_AREA = "service area"
const HAS_LOCATION_PERMISSION = "has location permission"

const FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSdI_U0Co9HR24KVWWyZP0YIJrB3d2PA8VHuGNaMmQOutzemGQ/viewform"
const FORM_SESSION_ID_PARAM = "entry.723717549"

// "enum" for these purposes; can't have empty string key in real enum
const Requested911Types: any = Object.freeze({
  "": "Unanswered",
  yes: "Yes",
  cancel: "Unanswered",
  no: "No",
})

const SubscriptionTypes: any = Object.freeze({
  free_beta: "Free Beta",
  individual: "Personal",
  family_admin: "Family Manager",
  family_member: "Family Member",
})

const SubscriptionStatues: any = Object.freeze({
  paid: "Paid",
  free_trial: "Free Trial",
  cancelled: "Cancelled",
  none: "None",
})

export const SOSInfoPane: React.FunctionComponent<IProps> = ({
  activeSessionId,
  ownerData = {},
  activationPoint,
  mode,
  isFirstCall,
}) => {
  const { state } = useSOSDashboardContext()
  const [agentScriptsShown, setAgentScriptsShown] = useState(isFirstCall)
  const containerRef = useRef<HTMLDivElement>(null)
  const rect = containerRef.current
    ? containerRef.current.getBoundingClientRect()
    : undefined

  const generateFormLink: () => React.ReactNode = () => {
    const query = {
      usp: "pp_url",
      [FORM_SESSION_ID_PARAM]: activeSessionId,
    }
    const parsedFormUrl = queryString.stringifyUrl({ url: FORM_URL, query })
    return (
      <div className={styles.links}>
        <a
          href={parsedFormUrl}
          target='_blank'
          rel='noopener noreferrer'
        >
          Fill out report
        </a>
      </div>
    )
  }

  const formatOwnerData: () => Array<React.ReactNode> = () => {
  const entriesList = Object.entries(ownerData)
    const items: Map<string, React.ReactNode> = new Map([])
  items.set(
      "version",
      <div key='version' className={styles.textLine}>
        <div className={styles.label}>Version:</div>
        <div className={styles.labeledText}>{state.sessionResp?.data?.config?.minorVersion}</div>
      </div>,
    )
  items.set(
  	"video provider",
  	<div key='video provider' className={styles.textLine}>
    <div className={styles.label}>Video provider:</div>
    <div className={styles.labeledText}>{state.sessionResp?.data?.video?.videoProvider}</div>
  	</div>,
  )
    items.set(
      "mode",
      <div key='mode' className={styles.textLine}>
        <div className={styles.label}>Mode:</div>
        <div className={styles.labeledText}>{mode || "default"}</div>
      </div>,
    )
    items.set(
      "activation point",
      <div key='activation-point' className={styles.textLine}>
        <div className={styles.label}>Activation Source:</div>
        <div className={styles.labeledText}>
          {get(activationPoint, "type", "default")}
        </div>
      </div>,
    )

    const sortOrder = [
      "version",
      "video provider",
      "mode",
      NAME,
      PHONE,
      PLATFORM,
      "activation point",
      HAS_LOCATION_PERMISSION,
      SESSION_ID,
      SERVICE_AREA,
      SUBSCRIBER_SINCE,
      SUBSCRIPTION_TYPE,
      SUBSCRIPTION_PRICE,
      SUBSCRIPTION_BILLING_PERIOD,
      SUBSCRIPTION_EXPIRES_DATE,
      PREVIOUS_CALLS,
    ]

    const allowList = [
      REQUESTED_911,
      SESSION_ID,
      SUBSCRIPTION_STATUS,
      SUBSCRIPTION_TYPE,
      SAFETY_PROFILE,
    ]

    entriesList
      .filter(
        ([k, v]) =>
          !isObject(v) ||
          allowList.indexOf(
            k
              .split(/(?=[A-Z])/)
              .join(" ")
              .toLowerCase(),
          ) !== -1,
      )
      .forEach(([k, v]) => {
        let val = v as string
        let additionalRow: React.ReactNode
        k = k.split(/(?=[A-Z])/).join(" ")
        let kl = k.toLowerCase()
        let customTitle
        let skipRender = false

        if (kl === REQUESTED_911) {
          val = Requested911Types[val]
        }

        if (kl === SESSION_ID) {
          additionalRow = generateFormLink()
          val = val.replace("pv2_", "pv2(") + ")"
        }

        if (kl === SUBSCRIPTION_STATUS) {
          val = SubscriptionStatues[val]
        }

        if (kl === SUBSCRIPTION_TYPE) {
          val = SubscriptionTypes[val]
        }

        if (kl === JOIN_DATE) {
          val = dayjs(val).format("MMM DD, YYYY h:mmA")
        }

        if (kl === SAFETY_PROFILE && (val as any).medicalDetails) {
          customTitle = "Medical Details"
          val = (val as any).medicalDetails
        } else if (kl === SAFETY_PROFILE) {
          skipRender = true
        }

        if (kl === SERVICE_AREA) {
          if (!val) {
            val = "No"
          }
        }

        if (kl === HAS_LOCATION_PERMISSION) {
          if (!val) {
            val = "No"
          } else {
            val = "Yes"
          }
        }

        if (!skipRender) {
          items.set(
            kl,
            <div key={k} className={styles.textLine}>
              <div className={styles.label}>
                {customTitle || titleCase(k)}:{" "}
              </div>
              <div className={styles.labeledText}>
                {val}
                {additionalRow}
              </div>
            </div>,
          )
        }
      })

    const agg: Array<React.ReactNode> = []

    sortOrder.forEach(key => {
      if (items.has(key)) {
        agg.push(items.get(key))
        items.delete(key)
      }
    })

    items.forEach((value: React.ReactNode) => {
      agg.push(value)
    })

    return agg
  }

  useEffect(() => {
    setAgentScriptsShown(isFirstCall)
  }, [isFirstCall])

  return (
    <div className={styles.container} ref={containerRef}>
      <Container fullWidth className={styles.titleContainer}>
        <Container fill leftJustify>
          {TITLE}
        </Container>
        <AgentScriptsButtonExpandable
          onClose={() => setAgentScriptsShown(false)}
          onExpand={() => setAgentScriptsShown(true)}
          agentScriptsShown={agentScriptsShown}
        />
      </Container>
      <div className={styles.innerContainer}>{formatOwnerData()}</div>
      {agentScriptsShown && (
        <AgentScriptsDraggable
          rect={rect}
          onClose={() => setAgentScriptsShown(false)}
          sessionId={activeSessionId}
        />
      )}
    </div>
  )
}
