import classNames from "classnames";
import styled from "styled-components";

const UnreadCount = styled.span.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item__unread-count"
      )
    };
  }
)`
  align-items: center;
  align-self: center;
  aspect-ratio: 1 / 1;
  background: var(--colors-green-040);
  border-radius: var(--radii-circle);
  color: var(--colors-white);
  display: flex;
  font-size: var(--font-sizes-x-small);
  font-weight: var(--font-weights-bold);
  justify-content: center;
  min-width: 16px;
  padding:
    var(--space-xxx-small)
    var(--space-xxx-small)
    calc(var(--space-xxx-small) + 1px)
    var(--space-xxx-small);
`;

UnreadCount.propTypes = {};

UnreadCount.defaultProps = {};

export default UnreadCount;
