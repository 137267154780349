const EMPTY_LOCATION = { location: "", address: "", police: "" }

export const initialState = {
  location: EMPTY_LOCATION,
  title: "",
  update: "",
  level: 0,
  incident: undefined,
  occurredAt: undefined,
}

const ACTIONS = {
  TOGGLE_MERGING_INCIDENT: "toggle merging incident",
  UPDATE_PROPERTY: "update property",
  UPDATE_LOCATION: "update location",
}

export const toggleMergingIncident = data => ({
  type: ACTIONS.TOGGLE_MERGING_INCIDENT,
  data,
})

export const updateProperty = (property, value) => ({
  type: ACTIONS.UPDATE_PROPERTY,
  data: { property, value },
})

export const updateLocation = data => ({
  type: ACTIONS.UPDATE_LOCATION,
  data,
})

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_MERGING_INCIDENT:
      if (state.incident === action.data.id) {
        return {
          ...state,
          incident: undefined,
        }
      }
      return {
        ...state,
        incident: action.data.id,
        title: action.data.title,
        update: action.data.update,
        location: action.data.location,
        level: action.data.level,
      }
    case ACTIONS.UPDATE_PROPERTY: {
      const { property, value } = action.data
      switch (property) {
        case "address": {
          const location = value || EMPTY_LOCATION
          return {
            ...state,
            location,
          }
        }
        case "location":
        case "police":
          return {
            ...state,
            location: {
              ...state.location,
              [property]: value,
            },
          }
        default:
          return {
            ...state,
            [property]: value,
          }
      }
    }
    case ACTIONS.UPDATE_LOCATION:
      return {
        ...state,
        location: action.data,
      }
    default:
      return state
  }
}

export default reducer
