import _ from "lodash"
import { CLIP_SMALL_SKIP_MS } from "./consts"
import { push } from "@guardian/Utils/history"
import combokeys from "@guardian/Utils/hotkeys"

import { event } from "@guardian/Analytics"
import Events from "@guardian/Analytics/events"

const keyBindings = state => {
  const kbAction = fn => {
    return e => {
      e.preventDefault()
      fn()
    }
  }

  state.on("bindClipKeys", () => {
    const { readOnly } = state.get().global
    if (readOnly) {
      return
    }

    combokeys.bind(
      "1",
      kbAction(() => {
        if (state.get().radio.rateClip) {
          state.emit("rateClip", 1)
        } else {
          state.emit("radioSpeed:set", 1)
        }
      }),
    )
    combokeys.bind(
      "2",
      kbAction(() => {
        if (state.get().radio.rateClip) {
          state.emit("rateClip", 2)
        } else {
          state.emit("radioSpeed:set", 1.5)
        }
      }),
    )
    combokeys.bind(
      "3",
      kbAction(() => {
        if (state.get().radio.rateClip) {
          state.emit("rateClip", 3)
        } else {
          state.emit("radioSpeed:set", 2)
        }
      }),
    )
    combokeys.bind(
      "4",
      kbAction(() => {
        if (state.get().radio.rateClip) {
          state.emit("rateClip", 4)
        } else {
          state.emit("radioSpeed:set", 2.5)
        }
      }),
    )
    combokeys.bind(
      "5",
      kbAction(() => {
        if (state.get().radio.rateClip) {
          state.emit("rateClip", 5)
        }
      }),
    )
    combokeys.bind(
      "space",
      kbAction(() => state.emit("kbTogglePlaying")),
    )

    combokeys.bind(
      "left",
      kbAction(() => state.emit("rewindClip")),
    )
    combokeys.bind(
      "a",
      kbAction(() => state.emit("rewindClip")),
    )

    combokeys.bind(
      "right",
      kbAction(() => state.emit("advanceClip")),
    )
    combokeys.bind(
      "d",
      kbAction(() => state.emit("advanceClip")),
    )
    combokeys.bind(
      "shift+left",
      kbAction(() => state.emit("rewindClip", CLIP_SMALL_SKIP_MS)),
    )
    combokeys.bind(
      "shift+right",
      kbAction(() => state.emit("advanceClip", CLIP_SMALL_SKIP_MS)),
    )
    combokeys.bind(
      "shift+a",
      kbAction(() => state.emit("rewindClip", CLIP_SMALL_SKIP_MS)),
    )
    combokeys.bind(
      "shift+d",
      kbAction(() => state.emit("advanceClip", CLIP_SMALL_SKIP_MS)),
    )

    combokeys.bind(
      "up",
      kbAction(() => state.emit("kbNextClip")),
    )
    combokeys.bind(
      "w",
      kbAction(() => state.emit("kbNextClip")),
    )

    combokeys.bind(
      "down",
      kbAction(() => state.emit("kbPrevClip")),
    )
    combokeys.bind(
      "s",
      kbAction(() => state.emit("kbPrevClip")),
    )
    combokeys.bind(
      "shift+up",
      kbAction(() => state.emit("nextClip", { unplayed: true })),
    )
    combokeys.bind(
      "shift+down",
      kbAction(() => state.emit("prevClip", { unplayed: true })),
    )
    combokeys.bind(
      "shift+w",
      kbAction(() => state.emit("nextClip", { unplayed: true })),
    )
    combokeys.bind(
      "shift+s",
      kbAction(() => state.emit("prevClip", { unplayed: true })),
    )

    combokeys.bind(
      "n",
      kbAction(() => {
        if (state.get().global.permissions.citMod) {
          push("/metapod")
        }
      }),
    )
    combokeys.bind(
      "esc",
      kbAction(() => state.emit("toggleDecoder")),
    )

    combokeys.bind(
      "-",
      kbAction(() => state.emit("levelDecrease", -1)),
    )
    combokeys.bind(
      "+",
      kbAction(() => state.emit("levelIncrease", 1)),
    )

    combokeys.bind("f", () => state.emit("attach current clip", true))
    combokeys.bind("shift+f", () => state.emit("attach current clip"))

    const pin = kbAction(() => {
      const { activeClip } = state.get().radio
      if (activeClip.id) {
        state.emit("togglePin", activeClip.id)
      }
    })
    combokeys.bind("p", pin)

    const pinWithParam = (eventName, ...params) =>
      kbAction(() => {
        const { activeClip } = state.get().radio
        if (activeClip.id) {
          state.emit(eventName, activeClip.id, ...params)
        }
      })

    combokeys.bind("z", pinWithParam("toggleMultiplayerPin", "new"))
    combokeys.bind("x", pinWithParam("toggleMultiplayerPin", "update"))

    const isolate = kbAction(() => {
      const { activeClip, clips, mutes } = state.get().radio

      if (activeClip.id) {
        const clip = clips.find(c => c.id === activeClip.id)

        // Override isolate shortcut for data labeling
        if (state.get().global.tab === 'data_labeling') {
          state.emit("labelingIsolate", clip ? clip.channel : null)
          return
        }

        state.emit(
          "setIsolate",
          _.isEmpty(mutes) && clip ? [clip.channel] : null,
          true,
        )
      }
    })
    combokeys.bind("i", isolate)

    combokeys.bind("r", () => {
      const { activeClip, clips } = state.get().radio
      if (activeClip.id) {
        const clip = clips.find(c => c.id === activeClip.id)
        if (clip) {
          state.get().radio.set("rateClip", clip)
        }
      }
    })
    combokeys.bind(
      "g",
      kbAction(() => {
        state.emit("toggle unplayed only")
      }),
    )

    const loop = kbAction(() => state.emit("toggleLoop"))
    combokeys.bind("l", loop)

    combokeys.bind(
      "t",
      kbAction(() => {
        if (state.get().global.metapod) {
          state.emit("set flagged only", !state.get().radio.flaggedOnly)
        }
      }),
    )
  })

  state.on("unbindKeys", () => {
    combokeys.reset()
  })

  state.on("kbNextClip", opts => {
    const handlers = state.get().radio.kbHandlers

    event({
      category: Events.CATEGORY.INCIDENT_CREATION,
      action: Events.ACTION.NEXT_CLIP,
      label: Events.LABEL.KEYBOARD,
    })
    if (handlers) {
      handlers.nextClip(opts)
    } else {
      state.emit("nextClip", opts)
    }
  })

  state.on("kbPrevClip", opts => {
    const handlers = state.get().radio.kbHandlers
    if (handlers) {
      handlers.prevClip(opts)
    } else {
      state.emit("prevClip", opts)
    }
  })

  state.on("kbTogglePlaying", () => {
    const { kbHandlers } = state.get().radio
    if (kbHandlers) {
      return kbHandlers.pause()
    }
    state.emit("togglePlaying")
  })
}

export default keyBindings
