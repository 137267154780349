import React, { useMemo } from "react";
import dayjs from "dayjs";

import StyledSystemMessage from "./views/SystemMessage";
import SystemMessageBody from "./views/SystemMessageBody";
import SystemMessageTimestamp from "./views/SystemMessageTimestamp";

// NOTE: Formatted timestamps for these views will only include the year if the
// timestamp occured at a year before this one. Hence the use of dayjs's `diff`
// function, scoped only to the year.
const formatTimestamp = timestamp => {
  const wrappedNow = dayjs();
  const wrappedTimestamp = dayjs(timestamp);

  let formattedTimestamp;
  if (wrappedNow.diff(wrappedTimestamp, "year") > 0) {
    formattedTimestamp = dayjs(timestamp).format("MMM D YYYY, h:mmA");
  } else {
    formattedTimestamp = dayjs(timestamp).format("MMM D, h:mmA");
  }

  return formattedTimestamp;
};

const SystemMessage = props => {
  const {
    message: {
      body: {
        text
      },
      createdAt
    }
  } = props;

  const formattedTimestamp = useMemo(() => {
    return formatTimestamp(createdAt);
  }, [createdAt]);

  return (
    <StyledSystemMessage>
      <SystemMessageBody>{ text }</SystemMessageBody>
      <SystemMessageTimestamp>
        {
          formattedTimestamp
        }
      </SystemMessageTimestamp>
    </StyledSystemMessage>
  );
};

export default SystemMessage;
