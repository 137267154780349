import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledQueueListItem = styled(
  ({ isActive, isDisabled, isFocused, isHovered, ...props }) => {
    // Ensure Link component doesn't receive the props for our styled component.
    return <Link {...props}/>
  }
).attrs(
  props => {
    const {
      className,
      isActive,
      isDisabled,
      isFocused,
      isHovered
    } = props;

    return {
      "aria-disabled": isDisabled,
      className: classNames(
        className,
        "sos-dms__messaging-queue-panel__item", {
          "sos-dms__messaging-queue-panel__item--is-active": isActive,
          "sos-dms__messaging-queue-panel__item--is-disabled": isDisabled,
          "sos-dms__messaging-queue-panel__item--is-focused": isFocused,
          "sos-dms__messaging-queue-panel__item--is-hovered": isHovered,
        })
    };
  }
)`
  ${ buttonResets }

  color: var(--colors-white);
  display: flex;
  flex-direction: row;
  height: 68px;
  padding: var(--space-small) var(--space-medium);
  width: 100%;

  &:hover, &.sos-dms__messaging-queue-panel__item--is-hovered
  {
    background: var(--colors-grey-080);
  }

  &:active, &.sos-dms__messaging-queue-panel__item--is-active
  {
    background: var(--colors-grey-080);
  }

  // :focus managed by KeyboardFocusContainer
  &.sos-dms__messaging-queue-panel__item--is-focused
  {
    background: var(--colors-grey-080);
  }

  &.sos-dms__messaging-queue-panel__item--is-disabled {
    pointer-events: none;
  }
`;

const QueueListItem = React.forwardRef(
  ({ children, isFocused, ...other }, ref) => (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <StyledQueueListItem
          {...getFocusProps({
            ref,
            ...other,
            isFocused: isFocused || keyboardFocused
          })}
        >
          { children }
        </StyledQueueListItem>
      )}
    </KeyboardFocusContainer>
  )
);

QueueListItem.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isHovered: PropTypes.bool
};

QueueListItem.defaultProps = {
  isActive: false,
  isDisabled: false,
  isFocused: false,
  isHovered: false
};

export default QueueListItem;
