import classNames from "classnames";
import styled, { css } from "styled-components";

import { getTooltipPosition } from "../utils/placements";
import { positionMargin } from "../utils/tooltipStyles";

const TooltipWrapper = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "tooltip__tooltip-wrapper")
    };
  }
)`
  ${({ placement, zIndex }) => css`
    font-size: 0.01px;
    z-index: ${ zIndex || 0 };

    ${ positionMargin(getTooltipPosition(placement)) }
  `}
`;

TooltipWrapper.defaultProps = {};

export default TooltipWrapper;
