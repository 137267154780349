import classNames from "classnames";
import styled from "styled-components";

const Tabs = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "tab__tabs")
    };
  }
)`
  width: 100%;
`;

Tabs.defaultProps = {};

export default Tabs;
