import _flatten from "lodash/flatten"

const Polygon = ({ map: { map, maps }, polygon }) => {
  let paths = []
  try {
    paths = polygon.get("coordinates").map(value => {
      return value.map(p => {
        if (typeof p[0] === "number") {
          return { lng: p[0], lat: p[1] }
        } else {
          return p.map(val => {
            return { lng: val[0], lat: val[1] }
          })
        }
      })
    })
  } catch(e) {}

  const poly = new maps.Polygon({
    paths: _flatten(paths),
    strokeColor: polygon.get("strokeColor") || polygon.get("color") || "#FFF",
    strokeOpacity: polygon.get("strokeOpacity") || 0.35,
    strokeWeight: 2,
    fillColor: polygon.get("color") || "#FFF",
    fillOpacity: 0.35,
  })
  poly.setMap(map)

  return poly
}

export default Polygon
