import classNames from "classnames";
import styled from "styled-components";

const Section = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__session__section"
    )
  };
})`
`;

Section.defaultProps = {};

export default Section;
