import classNames from "classnames";
import styled from "styled-components";

const InputActionsWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__input-field__input-actions-wrapper"
    )
  };
})`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  left: 0;
  padding: 0 var(--space-medium) var(--space-medium) var(--space-medium);
  position: absolute;
  width: 100%;
`;

InputActionsWrapper.defaultProps = {};

export default InputActionsWrapper;
