import classNames from "classnames";
import styled from "styled-components";

interface NavigationItemProps {
  warn: boolean
};

// Note: `active` styles are handled by `NavigationLink`, which includes `warn`
// styles.
const NavigationItem = styled.span.attrs(
  (props: NavigationItemProps) => {
    const {
      warn
    } = props;

    return {
      className: classNames("navigation-item", {
        "navigation-item--warn": warn
      })
    };
  }
)`
  align-items: center;
  color: white;
  display: inline-flex;
  font-size: var(--font-sizes-small);
  font-weight: var(--font-weights-bold);
  height: 100%;
  justify-content: center;
  padding: 0 var(--space-x-large);
  position: relative;
  text-align: center;
`;

export default NavigationItem;
