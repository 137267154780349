import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import useSmoothScrollToActive from "@guardian/Hooks/useSmoothScrollToActive"
import type { Clip } from "@guardian/Types/Clip"
import { objPick } from "@guardian/Utils/obj-pick"

import { useAuditIncidentPatch } from "@guardian/Components/AuditTool/hooks"
import { useAuditToolContext } from "@guardian/Components/AuditTool/store"
import {
  AuditToolEntryType,
  type AuditToolEntry,
  type AuditToolEntryTypeMap,
} from "@guardian/Components/AuditTool/types"
import { getEntryType } from "@guardian/Components/AuditTool/utils"
import CityCodes from "@guardian/Components/CityCodes/CityCodes"
import MetapodListenControls from "@guardian/Components/ListenControls/MetapodListenControls"
// Original ListenPanel component
import ClipContextMenu from "@guardian/Components/ListenPanel/ClipContextMenu"
import { IconButton } from "@guardian/UI/Button"
import { Icon } from "@guardian/UI/Icon"

import { ContentRow, HeaderRow } from "./AuditRow"
import {
  AuditPanelContainer,
  AuditPanelGrid,
  AuditPanelItemStart,
  FloatingControlsContainer,
} from "./styles"

function getRowFieldNames<T extends AuditToolEntryType>(
  type: T,
): Array<keyof AuditToolEntryTypeMap[T]> {
  switch (type) {
    case AuditToolEntryType.ADDRESS:
      return ["address", "displayLocation", "aaAddress"] as Array<keyof AuditToolEntryTypeMap[T]>
    case AuditToolEntryType.TITLE_UPDATE:
      return ["title", "update"] as Array<keyof AuditToolEntryTypeMap[T]>
    default:
      const _exhaustiveCheck: never = type
      return _exhaustiveCheck
  }
}

function getAuditFieldNames<T extends AuditToolEntryType>(
  type: T,
): Array<keyof AuditToolEntryTypeMap[T]> {
  switch (type) {
    case AuditToolEntryType.ADDRESS:
      return ["isIncorrect"] as Array<keyof AuditToolEntryTypeMap[T]>
    case AuditToolEntryType.TITLE_UPDATE:
      return [
        "isInaccurate",
        "isPrivacyViolated",
        "isBadCopy",
        "isNotReportable",
      ] as Array<keyof AuditToolEntryTypeMap[T]>
    default:
      const _exhaustiveCheck: never = type
      return _exhaustiveCheck
  }
}

function getEntryFields<T extends AuditToolEntry>(
  entry: T,
  fields: Array<keyof T>,
): { key: keyof T; value: T[keyof T] }[] {
  return fields.map(key => ({ key, value: entry[key] }))
}

export const AuditPanel: React.FC = () => {
  const {
    activeClip,
    clipsView: clips,
    channels,
    clipContextMenu,
    flaggedOnly,
    serviceAreas,
    groupSettings,
  } = useGlobalState(state => {
    const {
      groupSettings,
      serviceAreaConfig: { serviceAreas },
    } = state.global
    const selectedRadio = objPick(state.radio, [
      "clipsView",
      "channels",
      "clipContextMenu",
      "activeClip",
      "flaggedOnly",
    ])

    return { ...selectedRadio, serviceAreas, groupSettings }
  })

  const {
    state: { entries, clips: clipsMap },
    setEntries,
  } = useAuditToolContext()

  const { mutateAsync } = useAuditIncidentPatch()

  const headerRowRef = useRef<HTMLDivElement | null>(null)

  const [isolatedChannel, setIsolatedChannel] = useState<string | null>(null)

  useEffect(() => {
    if (entries.length === 0 || Object.keys(clipsMap).length === 0) {
      return
    }
    const clips = entries.map(e => {
      const clip = clipsMap[e.clipId]
      if (!clip) {
        return null
      }
      // NOTE: We delete clip files older than 30 days in staging.
      // In order to allow labeling of clips older than 30 days,
      // we need to point the clip URL directly to the production bucket.
      const clipUrl = `storage.googleapis.com/citizen-production/${clip.id}.wav`
      return { ...clip, url: clipUrl, wav_url: clipUrl }
    }).filter(Boolean)
    eventHub.emit("updateClips", clips)
  }, [entries, clipsMap])

  useEffect(() => {
    eventHub.on("labelingIsolate", (channel: string) => {
      setIsolatedChannel(prevChannel =>
        channel === prevChannel ? null : channel,
      )
      eventHub.emit("showFlash", "isolate (i)")
    })

    return () => {
      eventHub.off("labelingIsolate")
    }
  }, [])

  const handleFlagToggle = () => {
    eventHub.emit("set flagged only", !flaggedOnly)
  }

  const { listRef, activeContentRef } = useSmoothScrollToActive(
    activeClip?.id ?? null,
    headerRowRef.current?.getBoundingClientRect()?.height ?? 0,
  )

  const filteredClips = useMemo(() => {
    if (!clips) {
      return []
    }
    return isolatedChannel
      ? (clips as Clip[]).filter(c => c.channel === isolatedChannel)
      : (clips as Clip[])
  }, [clips, isolatedChannel])

  const contextClip = useMemo(() => {
    if (!clipContextMenu || !groupSettings.allowClipContextMenu) {
      return null
    }
    return filteredClips.find(c => c.id === clipContextMenu.id) ?? null
  }, [clipContextMenu, groupSettings.allowClipContextMenu, filteredClips])

  const currentServiceAreas = useMemo(() => {
    const allServiceAreasSet = new Set(
      (clips as Clip[])
        .map(c => channels[c.channel]?.serviceArea)
        .filter(Boolean),
    )
    return Array.from(allServiceAreasSet).sort()
  }, [clips])

  const { entryType, rowFieldNames, auditFieldNames } = useMemo(() => {
    const _entryType = getEntryType(entries[0])
    return {
      entryType: _entryType,
      rowFieldNames: getRowFieldNames(_entryType),
      auditFieldNames: getAuditFieldNames(_entryType),
    } as const
  }, [])

  const handleAuditFieldChange = useCallback(
    async (clipId: string, incidentId: string, key: string, value: boolean) => {
      await mutateAsync({
        type: entryType,
        incidentKey: { clipId, incidentId },
        payload: {
          [key]: value,
        },
      })
    },
    [entryType, mutateAsync],
  )

  return (
    <AuditPanelContainer>
      {contextClip && <ClipContextMenu {...clipContextMenu} {...contextClip} />}
      <AuditPanelItemStart>
        <MetapodListenControls
          onFlagToggle={handleFlagToggle}
          flagCount={null}
          multiplayerPinCount={null}
        />
      </AuditPanelItemStart>
      <AuditPanelGrid ref={listRef}>
        <HeaderRow
          ref={headerRowRef}
          rowFieldNames={rowFieldNames}
          auditFieldNames={auditFieldNames}
        />
        {entries.map(entry => (
          <ContentRow
            key={entry.clipId}
            ref={activeClip?.id === entry.clipId ? activeContentRef : null}
            clip={(clips as Clip[]).find(c => c.id === entry.clipId) as Clip}
            incidentId={entry.incidentId}
            rowFields={getEntryFields(entry, rowFieldNames)}
            auditFields={getEntryFields(entry, auditFieldNames).map(
              ({ key, value }) => ({ key, value: !!value }),
            )}
            handleAuditFieldChange={handleAuditFieldChange}
            isolatedChannel={isolatedChannel}
          />
        ))}
      </AuditPanelGrid>
      <AuditPanelItemStart>
        <CityCodes
          serviceAreaCfg={serviceAreas}
          serviceAreas={currentServiceAreas}
        />
      </AuditPanelItemStart>
      <FloatingControlsContainer>
        {/* @ts-ignore */}
        <IconButton onClick={() => setEntries([])} size='small'>
          <Icon.Close />
        </IconButton>
      </FloatingControlsContainer>
    </AuditPanelContainer>
  )
}
