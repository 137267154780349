export default {
  clientSecret: import.meta.env.REACT_APP_CLIENT_SECRET,
  googleClientId: import.meta.env.REACT_APP_GOOGLE_CLIENT_ID,
  lighthouse: import.meta.env.REACT_APP_LIGHTHOUSE_HOST,
  megatronHost: import.meta.env.REACT_APP_DEVICE_HOST,
  optimusHost: import.meta.env.REACT_APP_OPTIMUS_HOST,
  production:
    import.meta.env.PROD &&
    (window.location.hostname.endsWith("protect.citizen.com") ||
      window.location.hostname.endsWith("protect.secure.sp0n.io")),
  secure: window.location.hostname === "protect.secure.sp0n.io",
  profileBaseURL: "https://storage.googleapis.com/citizen-profile-pictures/",
  tokboxApiKey:
    import.meta.env.PROD &&
    (window.location.hostname.endsWith("protect.citizen.com") ||
      window.location.hostname.endsWith("protect.secure.sp0n.io"))
      ? import.meta.env.REACT_APP_TOKBOX_API_KEY_PRODUCTION
      : import.meta.env.REACT_APP_TOKBOX_API_KEY_STAGING,
  sentryKey: import.meta.env.SENTRY_KEY || "6f65ddebf940452884e4db9c45bdcefc",
  sosMapUrl: import.meta.env.REACT_APP_SOS_MAP,
  webSockets: import.meta.env.REACT_APP_WEBSOCKET_URL,
}
