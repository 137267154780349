import React, { useRef } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import useClickOutside from "@guardian/Hooks/useClickOutside"

interface FlagContextMenuProps {
  x: number
  y: number
  onClose: () => void
}

const handleAlgorithmChange = (
  algorithm: string | null,
  callback?: () => any,
) => {
  eventHub.emit("setFlagAlgorithm", algorithm)
  callback?.()
}

const FlagContextMenu: React.FC<FlagContextMenuProps> = ({ x, y, onClose }) => {
  const menuRef = useRef<HTMLDivElement>(null)

  const flagAlgorithm = useGlobalState(state => state.radio.flagAlgorithm)

  useClickOutside([menuRef], onClose)

  return (
    <div
      ref={menuRef}
      className='clip-context-menu'
      style={{
        position: "fixed",
        top: y,
        left: x,
        zIndex: 100,
      }}
    >
      <ul>
        <li className='menu-title'>
          Algorithm: {flagAlgorithm || "Dynaconf default"}
        </li>
        <li
          className='menu-item'
          onClick={() => handleAlgorithmChange("v3_gpt", onClose)}
        >
          Override: V3 - GPT
        </li>
        <li
          className='menu-item'
          onClick={() => handleAlgorithmChange("legacy", onClose)}
        >
          Override: legacy
        </li>
        <li
          className='menu-item'
          onClick={() => handleAlgorithmChange(null, onClose)}
        >
          Use default
        </li>
      </ul>
    </div>
  )
}

export default FlagContextMenu
