import styled from "styled-components";
import classNames from "classnames";

import { StyledButton } from "./Button";

const ButtonGroup = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "button-group")
  }
})`
  display: inline-flex;
  flex-direction: row;

  & ${ StyledButton } + ${ StyledButton } {
    margin-left: var(--space-x-small);
  }
`;

ButtonGroup.propTypes = {};

ButtonGroup.defaultProps = {};

export default ButtonGroup;
