export const textfieldStyles = {
  root: {
    height: "0px",
    "& label": {
      fontWeight: "600",
      color: "#8C8C8C",
    },
    "& label.Mui-focused": {
      fontWeight: "600",
      color: "#8C8C8C",
    },
    "& span": {
      color: "#8C8C8C",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#333333",
      },
      "&:hover fieldset": {
        borderColor: "#333333",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#333333",
      },
      "& input": {
        backgroundColor: "transparent",
      },
    },
  },
}

export const otherStyles = {
  inputRoot: {
    paddingLeft: "12px !important",
  },
  input: {
    // hacky
    height: "0px !important",
    width: "0px !important",
    minWidth: "0px !important",
  },
  clearIndicator: {
    color: "#333333",
  },
  popupIndicator: {
    color: "#333333",
  },
  listbox: {
    "&::-webkit-scrollbar": { display: "none" },
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
    paddingTop: "8px",
    paddingBottom: "8px",
    "& > *": {
      padding: "8px 24px",
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "-0.25px",
      color: "#8c8c8c",
    },
    "& > *:hover": {
      backgroundColor: "#1a2633",
      cursor: "pointer",
      color: "white",
    },
  },
  paper: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.5)",
    background: "#0e151d",
    borderRadius: "0px 0px 4px 4px",
  },
}
