import classNames from "classnames";
import styled from "styled-components";

const NotesList = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__list"
    )
  };
})`
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  width: 100%;

  & > :first-child {
    padding-bottom: var(--space-x-large);
  }
`;

NotesList.defaultProps = {};

export default NotesList;
