import classnames from "classnames"
import { List } from "immutable"
import _ from "lodash"
import { array, bool, func, object, string } from "prop-types"
import { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"

import consts from "@guardian/Constants"
import { eventHub, useGlobalState } from "@guardian/State"

import { track } from "@guardian/Analytics/segment"
import { ContentService } from "@guardian/Services/Content"
import { GeocodingService } from "@guardian/Services/Geocoding"
import combokeys from "@guardian/Utils/hotkeys"
import { initialRevId } from "@guardian/Utils/incident"
import { reverseGeoToLocation } from "@guardian/Utils/util"

import IncidentStatusSelect from "@guardian/Components/IncidentStatus/IncidentStatusSelect"
import NearbyIncident from "@guardian/Components/NearbyIncident/NearbyIncident"

import IncidentCreation from "./IncidentCreation/IncidentCreation"
import {
  useIncidentCreationContext,
  withIncidentCreationContext,
} from "./IncidentCreation/IncidentCreation.context"
import IncidentModal from "./IncidentModal"
import style from "./IncidentPane.module.css"

const IncidentPane = ({
  confirmed,
  incidents,
  block,
  verified,
  onClose,
  activeContentId,
  activeContent,
  onSubmit,
  location,
  prompt911,
  onPrompt911,
  psnId,
}) => {
  const tags = useGlobalState(state => state.global.tags)

  const {
    state,
    toggleMergingIncident,
    updateLocation,
    updateProperty,
  } = useIncidentCreationContext()

  const [incidentMap, setIncidentMap] = useState({})
  const [mapInit, setMapInit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [override, setOverride] = useState(null)
  const [dontNotify, setDontNotify] = useState(false)

  const canSubmit =
    state.title &&
    state.location &&
    state.location.location &&
    state.location.address &&
    state.location.cityCode &&
    (state.incident || state.update)

  const isGoodVibe =
    activeContent.type === "video_stream" &&
    _.get(activeContent, "videoStream.reportCategory") === consts.GoodVibeCategory

  const submit = async closureStatus => {
    let incident = state.incident
      ? incidents.find(i => i.id === state.incident)
      : undefined
    if (override && state.incident === override.id) {
      incident = override
    }
    const tag = tags.find(t => t.display_name === state.tag)

    const spec = {
      id: uuid(),
      title:
        !incident || state.title !== incident.title ? state.title : undefined,
      text: state.update || null,
      level:
        !incident || state.level !== incident.level ? state.level : undefined,
      location:
        !incident || !_.isEqual(state.location, incident.location)
          ? state.location
          : undefined,
      occurredAt: state.occurredAt ? new Date(state.occurredAt) : new Date(),
      dontNotify: dontNotify,
      visibility: consts.Visibility.Public,
      content: state.incident
        ? undefined
        : {
            contentType: activeContent.type,
            contentId:
              activeContent.type === "image"
                ? activeContent.image.id
                : activeContent.videoStream.id,
          },

      updateSequence: state.incident ? 0 : undefined,
      incidentId: state.incident,
      rootRevId: incident ? initialRevId(incident) : undefined,
      source: consts.Source.IGL,
      prompt911,
      psnId,
      clearanceLevel: psnId ? 1 : undefined,
      tagIds: tag && tag.id ? [tag.id] : [],
      tags: tag && tag.id ? [tag] : [],
    }

    if (spec.text) {
      let incident = await eventHub.emit("createUpdateRev", spec)
      IncidentStatusSelect.setClosureStatus(closureStatus, incident)
    }

    // TODO: THESE
    if (state.incident) {
      await ContentService.mergeContent(
        activeContent.type,
        activeContentId,
        "Signal Mod",
        { incidentId: state.incident },
      )
    }

    if (verified) {
      await ContentService.verifyContent(activeContent.type, activeContentId, {
        confirmed: true,
      })
      if (activeContent.type === "video_stream") {
        track("Live Video Verify", {
          videoStreamId: activeContentId,
          location: "Signal Mod",
          type: "verified_live", // VL, EC, Custom
        })
      }
    }

    if (block) {
      await ContentService.blockContent(
        activeContent.type,
        activeContentId,
        "Signal Mod",
        { reason: "N/A" },
      )
    }

    onSubmit(activeContentId)
  }

  useEffect(() => {
    const onMapInit = () => {
      setMapInit(true)
    }
    eventHub.on("mapInit", onMapInit)

    return () => {
      eventHub.off("mapInit", onMapInit)
    }
  }, [])

  useEffect(() => {
    const syncMultimediaMetadata = async () => {
      try {
        const metadata = await ContentService.getMultimediaUploadMetadata(
          activeContentId,
        )
        if (metadata.title) {
          updateProperty("title", metadata.title)
        }
        if (metadata.textUpdate) {
          updateProperty("update", metadata.textUpdate)
        }
        if (metadata.createdAt) {
          updateProperty("occurredAt", metadata.createdAt)
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (activeContentId) {
      syncMultimediaMetadata()
    }
  }, [activeContentId])

  useEffect(() => {
    const updated = _.some(incidents, i => !incidentMap[i.id])
    if (updated) {
      setIncidentMap(
        _.reduce(
          incidents,
          (res, i) => {
            res[i.id] = {
              ...i,
              center: [i.location.lng, i.location.lat],
            }
            return res
          },
          {},
        ),
      )
    }
  }, [incidents, incidentMap])

  useEffect(() => {
    eventHub.emit("set map", {
      markers: new List(_.values(incidentMap)),
      markerType: "incident",
    })
  }, [incidentMap, mapInit])

  useEffect(() => {
    updateProperty("address", location)
    eventHub.emit("set active marker", {
      lat: location?.lat,
      lng: location?.lng,
    })
    eventHub.emit("set center", {
      lat: location?.lat,
      lng: location?.lng,
    })
  }, [updateProperty, location])

  useEffect(() => {
    const getLocFromLatLng = async latLng => {
      const geocodeResp = await GeocodingService.reverseGeocode(
        latLng.lat,
        latLng.lng,
      )
      if (geocodeResp) {
        const locState = await reverseGeoToLocation(latLng, geocodeResp)
        updateLocation(locState)
      }
    }

    eventHub.on("updateIncidentLocation", getLocFromLatLng)
    return () => {
      eventHub.off("updateIncidentLocation", getLocFromLatLng)
    }
  }, [updateLocation])

  useEffect(() => {
    combokeys.bind("m", () => {
      if (state.incident && canSubmit) {
        submit()
      }
    })
    return () => {
      combokeys.unbind("m")
    }
  }, [canSubmit])

  return (
    <div className={style.pane}>
      {!confirmed && (
        <div className={style.subpane}>
          <div className={style.empty}>
            <h5>No Incident Linked to this video report</h5>
            <p>Confirm the report to create an incdient</p>
          </div>
        </div>
      )}
      {confirmed && (
        <IncidentCreation
          key={state.incident}
          verified={verified}
          block={block}
          username={activeContent.ugcMeta?.username}
          isGoodVibe={isGoodVibe}
          location={location}
          prompt911={prompt911}
          onPrompt911={onPrompt911}
          dontNotify={dontNotify}
          toggleDontNotify={() => setDontNotify(val => !val)}
          onClose={() => {
            if (state.incident) {
              toggleMergingIncident({ id: state.incident })
            }
            onClose()
          }}
          onSubmit={canSubmit ? submit : undefined}
        />
      )}
      <div
        className={classnames(style.subpane, {
          [style.emptyPane]: !incidents.length,
        })}
      >
        {confirmed && (
          <button onClick={() => setShowModal(true)}>
            <p>Enter a farther incident</p>
          </button>
        )}
        <h5>
          {incidents.length
            ? `Nearby Incidents Detected`
            : `No nearby Incident detected over the past hour`}
        </h5>
        <div className={style.list}>
          {incidents.map(i => (
            <NearbyIncident
              key={i.id}
              address={i.location.address}
              title={i.title}
              distance={i.distance}
              time={i.time}
              id={i.id}
              onMerge={
                confirmed
                  ? id => {
                      const incident = incidents.find(i => i.id === id)
                      toggleMergingIncident(incident)
                    }
                  : undefined
              }
              merging={state.incident === i.id}
            />
          ))}
        </div>
      </div>
      {showModal && (
        <IncidentModal
          onClose={() => setShowModal(false)}
          onMerge={incident => {
            setOverride(incident)
            toggleMergingIncident(incident)
            setShowModal(false)
          }}
        />
      )}
    </div>
  )
}

IncidentPane.propTypes = {
  confirmed: bool,
  incidents: array,
  block: bool,
  verified: bool,
  onClose: func,
  activeContentId: string,
  activeContent: object,
  onSubmit: func,
  location: object,
  prompt911: bool,
  onPrompt911: func,
  psnId: string,
}

export default withIncidentCreationContext(IncidentPane)
