import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const TabPanel = styled.div.attrs(
  props => {
    const {
      className,
      isActive
    } = props;

    return {
      className: classNames(className, "tab__tab-panel", {
        "tab__tab-panel--is-active": isActive
      })
    };
  }
)`
  width: 100%;
`;

TabPanel.propTypes = {
  isActive: PropTypes.bool
};

TabPanel.defaultProps = {
  isActive: false
};

export default TabPanel;
