import { createContext, useContext } from "react";

export const FieldContext = createContext(undefined);

const useFieldContext = () => {
  const fieldContext = useContext(FieldContext);

  return fieldContext;
};

export default useFieldContext;
