import React from "react";

import { Row } from "@guardian/UI/Grid";

import MessagingPanel from "./components/MessagingPanel";
import MessagingQueuePanel from "./components/MessagingQueuePanel";

const DirectMessaging = props => {
  return (
    <Row style={{ height: "100%" }}>
      <MessagingQueuePanel/>
      <MessagingPanel/>
    </Row>
  );
};

export default DirectMessaging;
