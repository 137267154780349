import React, { useMemo } from "react"
import classNames from "classnames"

import { useGlobalState } from "@guardian/State"

import { objPick } from "@guardian/Utils/obj-pick"

import styles from "./styles.module.css"

const EMPTY_LABEL = "unplayed"
const NOISE_LABEL = "update"

const LabelingCounts = () => {
  const { clipsView, multiplayerPinnedClips } = useGlobalState(state =>
    objPick(state.radio, ["clipsView", "multiplayerPinnedClips"]),
  )

  const allClipIds = useMemo(
    () => new Set(clipsView.map((clip: any) => clip.clipId)),
    [clipsView],
  )

  const { labeledCount, noiseCount } = useMemo(() => {
    const filteredMultiplayerPinnedClips = multiplayerPinnedClips?.filter(
      (c: any) => allClipIds.has(c.clipId),
    )
    return {
      labeledCount:
        filteredMultiplayerPinnedClips?.filter(
          (c: any) => c.clipListenStatus && c.clipListenStatus !== EMPTY_LABEL,
        ).length || 0,
      noiseCount:
        filteredMultiplayerPinnedClips?.filter(
          (c: any) => c.clipListenStatus === NOISE_LABEL,
        ).length || 0,
    }
  }, [multiplayerPinnedClips, allClipIds])

  return (
    <div className={styles.container}>
      <h1>
        <i className={classNames("fas fa-calculator", styles.icon)} />
        Labeling Counts
      </h1>
      <h2>Total count: {labeledCount}</h2>
      <h2>Incident/update count (z key): {labeledCount - noiseCount}</h2>
      <h2>Noise count (x key): {noiseCount}</h2>
    </div>
  )
}

export default LabelingCounts
