import { useCallback, useState } from "react"

import { parseCSV } from "@guardian/Utils/csv"

export function useCSVUpload<T>(header: boolean = true) {
  const [csvData, setCsvData] = useState<T[]>([])
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [csvError, setCsvError] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)

  const handleUpload = useCallback(async (file: File) => {
    setCsvError(null)
    setCsvFile(file)
    setIsUploading(true)

    try {
      const data = await parseCSV<T>(file, { header })
      setCsvData(data)
    } catch (error: any) {
      console.error(error)
      setCsvError(
        error
          ? error?.message || error.toString()
          : "An error occurred while parsing the CSV file",
      )
    } finally {
      setIsUploading(false)
    }
  }, [])

  const reset = useCallback(() => {
    setCsvData([])
    setCsvFile(null)
    setCsvError(null)
  }, [])

  return {
    csvData,
    csvFile,
    csvError,
    isUploading,
    handleUpload,
    reset,
  }
}
