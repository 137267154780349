import { MenuItem, OutlinedInput, Select } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"
import { bool, func, string } from "prop-types"

import { useGlobalState } from "@guardian/State"

import { ActionTypes } from "../ActionPane.reducer"
import style from "./CustomNotifSwitches.module.css"
import { inputStyles, selectStyles } from "./CustomNotifSwitches.styles"

const StyledInput = withStyles(inputStyles)(OutlinedInput)
const StyledSelect = withStyles(selectStyles)(Select)

const CustomNotifSwitches = ({
  notifSoundOn,
  renotify,
  showIncidentMetadata,
  sendNotifInternalOnly,
  notifTurnedOn,
  onAir,
  sound,
  dispatch,
  disabled,
}) => {
  const notifSounds = useGlobalState(
    state => state.global.customNotifConfig.notifSounds,
  )

  return (
    <>
      <div className={style.container}>
        <div className={style.toggleContainer}>
          Metadata
          <div
            className={classnames(
              style.toggle,
              { [style.toggleOn]: showIncidentMetadata },
              { [style.toggleOff]: !showIncidentMetadata },
            )}
            onClick={() => {
              if (!disabled) {
                dispatch({
                  type: ActionTypes.SetShowIncidentMetadata,
                  payload: { showIncidentMetadata: !showIncidentMetadata },
                })
              }
            }}
          >
            <div className={style.toggleIndicator}></div>
          </div>
        </div>
        <div className={style.toggleContainer}>
          Renotify
          <div
            className={classnames(
              style.toggle,
              { [style.toggleOn]: renotify },
              { [style.toggleOff]: !renotify },
            )}
            onClick={() => {
              if (!disabled) {
                dispatch({
                  type: ActionTypes.SetRenotify,
                  payload: { renotify: !renotify },
                })
              }
            }}
          >
            <div className={style.toggleIndicator}></div>
          </div>
        </div>
      </div>
      <div className={style.row}>
        <div className={style.toggleContainer}>
          Sound
          <div
            className={classnames(
              style.toggle,
              { [style.toggleOn]: notifSoundOn },
              { [style.toggleOff]: !notifSoundOn },
            )}
            onClick={() => {
              if (!disabled) {
                dispatch({
                  type: ActionTypes.SetNotifSoundOn,
                  payload: { soundOn: !notifSoundOn },
                })
              }
            }}
            disabled={disabled}
          >
            <div className={style.toggleIndicator}></div>
          </div>
        </div>
        <StyledSelect
          value={sound}
          variant='outlined'
          onChange={e => {
            dispatch({
              type: ActionTypes.SetNotifSound,
              payload: { sound: e.target.value },
            })
          }}
          label='Sound Type'
          input={<StyledInput notched label='Sound Type' />}
          disabled={disabled}
        >
          {notifSounds.map(sound => (
            <MenuItem key={sound} value={sound}>
              {sound.includes("nearby_notification_c") ? "Standard" : sound}
            </MenuItem>
          ))}
        </StyledSelect>
      </div>
      {onAir && (
        <div className={style.row}>
          <div className={style.toggleContainer}>
            Internal Notif Only
            <div
              className={classnames(
                style.toggle,
                { [style.toggleOn]: sendNotifInternalOnly },
                { [style.toggleOff]: !sendNotifInternalOnly },
              )}
              onClick={() =>
                dispatch({
                  type: ActionTypes.SetSendNotifInternalOnly,
                  payload: { send: !sendNotifInternalOnly },
                })
              }
            >
              <div className={style.toggleIndicator}></div>
            </div>
          </div>
          <div className={style.toggleContainer}>
            Send Notif
            <div
              className={classnames(
                style.toggle,
                { [style.toggleOn]: notifTurnedOn },
                { [style.toggleOff]: !notifTurnedOn },
              )}
              onClick={() =>
                dispatch({
                  type: ActionTypes.SetNotifTurnedOn,
                  payload: { turnedOn: !notifTurnedOn },
                })
              }
            >
              <div className={style.toggleIndicator}></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

CustomNotifSwitches.propTypes = {
  notifSoundOn: bool,
  renotify: bool,
  showIncidentMetadata: bool,
  sendNotifInternalOnly: bool,
  onAir: bool,
  sound: string,
  dispatch: func,
  disabled: bool,
}

export default CustomNotifSwitches
