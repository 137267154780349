import styled from "styled-components";

const InnerWrapper = styled.div`
  background: var(--colors-black);
  border-radius: var(--radii-small);
  height: 100%;
  overflow-y: auto;
  scroll-padding: 0px 0px var(--space-small) 0px;
  width: 100%;
`;

InnerWrapper.defaultProps = {};

export default InnerWrapper;
