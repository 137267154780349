import { func, string } from "prop-types"

import { eventHub, useGlobalState } from "@guardian/State"

import { ContentService } from "@guardian/Services/Content"

import Dropdown from "@guardian/Components/Form/Dropdown"

import style from "./RejectModal.module.css"

const RejectModal = ({ id, onReject, contentType }) => {
  const rejectionChoices = useGlobalState(
    state => state.moderation.rejectionChoices,
  )

  const reject = async reason => {
    await ContentService.rejectContent(contentType, id, "Signal Tab", {
      reason,
    })
    onReject(id)
    eventHub.emit("fetch mod log")
  }

  return (
    <div className={style.modal}>
      <div className={style.circle} />
      <div className={style.actions}>
        <p>Reject This Community Alert for:</p>
        <Dropdown
          options={Object.keys(rejectionChoices)}
          placeholder='Select Rejection Reason'
          onChange={async r => {
            await reject(rejectionChoices[r])
          }}
          autoOpen
        />
      </div>
    </div>
  )
}

RejectModal.propTypes = {
  id: string,
  onReject: func,
  contentType: string,
}

export default RejectModal
