import usePortal from "@guardian/Hooks/usePortal"
import React, { useState } from "react"
import ReactDOM from "react-dom"
import Draggable from "react-draggable"
import { AgentScripts } from "@guardian/Components/ModSOS/components/InfoPane/AgentScripts/AgentScripts"
import { ResizeCallbackData } from "react-resizable"
import { patchAgentScriptsAction } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { IProps } from "@guardian/Components/ModSOS/components/InfoPane/AgentScriptsDraggable/types"

export const AgentScriptsDraggable = ({ rect, onClose, sessionId }: IProps) => {
  const div = usePortal("scripts-root") as any
  const [x, setX] = useState(0)
  const [prevWidth, setPrevWidth] = useState(rect ? rect.width : 0)

  const onScriptsResize = (
    _e: React.SyntheticEvent,
    data: ResizeCallbackData,
  ) => {
    if (data.handle === "sw") {
      setX(x - (data.size.width - prevWidth))
      setPrevWidth(data.size.width)
    }
  }

  return ReactDOM.createPortal(
    <Draggable
      defaultPosition={rect ? { x: rect.left, y: rect.top } : undefined}
      handle='.drag-handle'
      positionOffset={{ x, y: 0 }}
    >
      <div>
        <AgentScripts
          onAction={action =>
            sessionId && patchAgentScriptsAction(sessionId, action)
          }
          onClose={onClose}
          width={rect ? rect.width : 0}
          height={rect ? rect.height : 0}
          sessionId={sessionId}
          onResize={onScriptsResize}
        />
      </div>
    </Draggable>,
    div,
  )
}
