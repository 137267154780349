import React from "react"
import { object } from "prop-types"

import { coverageToHealthLevel } from "@guardian/Utils/util"

import style from "./Markers.module.css"

const CoverageAreaMarker = ({ model }) => {
  const health = coverageToHealthLevel(model.coverage)

  return (
    <div className={style.coverageArea}>
      <div className={style.fullWidth}>
        <div className={style.city} data-health={health} />
        <div className={style.text}>{model.name}</div>
      </div>
    </div>
  )
}

CoverageAreaMarker.propTypes = {
  model: object,
}

export default CoverageAreaMarker
