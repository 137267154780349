import * as jose from "jose";

const SessionService = {
  accessTokenKey: "signal:token:citizen",

  get accessToken() {
    return localStorage.getItem(SessionService.accessTokenKey) || null;
  },

  get userId() {
    const accessToken = SessionService.accessToken;

    if (accessToken == null) {
      return null;
    }

    const { uid: userId } = jose.decodeJwt(accessToken);

    return (userId as any) || null;
  },

  setAccessToken: (accessToken: string) => {
    localStorage.setItem(SessionService.accessTokenKey, accessToken);
  },

  clearAccessToken: () => {
    localStorage.removeItem(SessionService.accessTokenKey);
  }
};

export default SessionService;
