import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";

import StyledToggleSwitch from "./views/ToggleSwitch";
import ToggleSwitchWrapper from "./views/ToggleSwitchWrapper";

interface Props {
  onChange: () => void
  toggled: boolean
};

const ToggleSwitch = (props: Props) => {
  const {
    onChange,
    toggled
  } = props;

  const id = useMemo(() => `toggle-switch-${ uuid() }`, []);

  return (
    <ToggleSwitchWrapper>
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          checked={toggled}
          onChange={onChange}
        />
        <StyledToggleSwitch/>
      </label>
    </ToggleSwitchWrapper>
  );
};

export default ToggleSwitch;
