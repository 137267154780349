import classNames from "classnames";
import styled from "styled-components";


const Loading = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__chat-tab__chat__loading"
    )
  };
})`
  align-items: center;
  color: var(--colors-grey-060);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

Loading.defaultProps = {};

export default Loading;
