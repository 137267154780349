import classNames from "classnames";
import styled from "styled-components";

const VirtualScrollList = styled.div.attrs(
  props => {
    const {
      className,
      reverse,
      reverseStartAtTop
    } = props;

    return {
      className: classNames(
        className,
        "virtual-scroll-list", {
          "virtual-scroll-list--reverse": reverse,
          "virtual-scroll-list--reverse-start-at-top": reverseStartAtTop
        }
      )
    }
  }
)`
  width: 100%;
  position: relative;

  ${ ({ height }) => `
    height: ${ height }px;
  `}

  &.virtual-scroll-list--reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    flex-shrink: 0;
  }

  &.virtual-scroll-list--reverse-start-at-top {
    margin-bottom: auto;
  }
`;

export default VirtualScrollList;
