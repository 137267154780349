import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";

import { event as eventUtils } from "@guardian/UI/Utils";

import { ModalContext } from "../hooks/useModalContext";
import Backdrop from "../components/Backdrop";
import StyledModal from "../views/Modal";

const Modal = props => {
  const {
    backdropProps,
    children,
    onClose,
    onEnter,
    onExit,
    open,
    ...modalProps
  } = props;

  const [entered, setEntered] = useState(open);
  const [mounted, setMounted] = useState(open);

  const lastOpenStateRef = useRef()

  useEffect(() => {
    const lastOpenState = lastOpenStateRef.current;

    let toggleTimeout;

    const closeModal = () => {
      setEntered(false);

      toggleTimeout = setTimeout(() => {
        setMounted(false);
        onExit();
      }, Modal.TRANSITION_MS);
    };

    const openModal = () => {
      // Note: You can ignore the discrepency between how `entered` and `mounted`
      // are handled in this method compared to within the `closeModal` method,
      // and how each handle their "notify" calls. These state values are
      // important for enter/exit behaviour of the modal, but not to when user
      // componants may want to know that the modal has _actually_ entered or
      // exited.
      setEntered(true);
      setMounted(true);

      toggleTimeout = setTimeout(() => {
        onEnter();
      }, Modal.TRANSITION_MS);
    };

    if (lastOpenState !== open) {
      open ? openModal() : closeModal();
    }

    lastOpenStateRef.current = open;

    return () => {
      clearTimeout(toggleTimeout);
    };
  }, [open]);

  const getBackdropProps = ({ closeDisabled, onClick, ...passdownProps } = {}) => {
    return {
      closeDisabled,
      entered,
      onClick: eventUtils.callAllEventHandlers(onClick, event => {
        if (!closeDisabled) {
          onClose();
        }
      }),
      transitionMs: Modal.TRANSITION_MS,
      ...passdownProps
    };
  };

  const getCloseProps = ({ disabled, onClick, ...passdownProps } = {}) => {
    return {
      disabled,
      onClick: eventUtils.callAllEventHandlers(onClick, event => {
        if (!disabled) {
          onClose();
        }
      }),
      ...passdownProps
    };
  };

  const getModalProps = ({ onClick, ...passdownProps } = {}) => {
    return {
      entered,
      onClick: eventUtils.callAllEventHandlers(onClick, event => {
        // Prevent Modal onClick from triggering Backdrop onClick event.
        event.stopPropagation();
      }),
      transitionMs: Modal.TRANSITION_MS,
      ...passdownProps
    };
  };

  const modalContextProps = {
    getBackdropProps,
    getCloseProps,
    getModalProps
  };

  if (!mounted) {
    return null;
  }

  return (
    <ModalContext.Provider value={modalContextProps}>
      <Portal>
        <Backdrop { ...getBackdropProps({ ...backdropProps }) }>
          <StyledModal { ...getModalProps({ ...modalProps }) }>
            { children }
          </StyledModal>
        </Backdrop>
      </Portal>
    </ModalContext.Provider>
  );
};

Modal.TRANSITION_MS = 225;

Modal.propTypes = {
  backdropProps: PropTypes.object,
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  onEnter: PropTypes.func,
  open: PropTypes.bool
};

Modal.defaultProps = {
  backdropProps: {},
  onClose: () => {},
  onExit: () => {},
  onEnter: () => {},
  open: false
};

export default Modal;
