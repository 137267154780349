import classNames from "classnames";
import styled from "styled-components";

const Field = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "form__field")
    };
  }
)`
`;

Field.propTypes = {};

Field.defaultProps = {};

export default Field;
