import React, { useEffect, useState } from "react";

import { AgentChatService } from "@guardian/Services/AgentChat";
import { Flash } from "@guardian/UI/Alert";
import { Button } from "@guardian/UI/Button";

import ResolveConfirmationModal from "./components/ResolveConfirmationModal";

const ResolveButton = props => {
  const { session } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(null);

  useEffect(() => {
    if (submitTrigger == null) {
      return;
    }

    let artificialDelay;

    const controller = new AbortController();
    const signal = controller.signal;

    const submit = () => {
      const options = { signal };

      setError(null);
      setLoading(true);
      setLocked(true);

      artificialDelay = setTimeout(() => {
        AgentChatService.endAssignedChatSession(session.id, options)
          .then(() => {
            setConfirmationOpen(false);
            setLoading(false);
          })
          .catch(error => {
            if (signal.aborted) {
              return;
            }

            setError(error);
            setLoading(false);
            setLocked(false);
          });
      }, 250);
    };

    submit();

    return () => {
      clearTimeout(artificialDelay);
      controller.abort();
    }
  }, [submitTrigger]);

  useEffect(() => {
    if (error == null) {
      return;
    }

    Flash.error(
      "Something went wrong while resolving DM Session.",
      {
        title: "Error"
      }
    );
  }, [error]);

  const handleClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirm = () => {
    setSubmitTrigger(+new Date());
  };

  const handleExit = () => {
    setError(null);
    setLoading(false);
    setLocked(false);
  };

  const handleOpen = () => {
    setConfirmationOpen(true);
  };

  const isResolved = session.endedAt != null;

  return (
    <React.Fragment>
      <Button onClick={handleOpen} disabled={isResolved}>
        {isResolved ? "Resolved" : "Resolve"}
      </Button>
      <ResolveConfirmationModal
        locked={locked}
        loading={loading}
        onClose={handleClose}
        onConfirm={handleConfirm}
        onExit={handleExit}
        open={confirmationOpen}
      />
    </React.Fragment>
  );
};

export default ResolveButton;
