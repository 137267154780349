import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react"
import dayjs from "dayjs"
import { find, get, isEqual, sortBy } from "lodash"
import { from, timer } from "rxjs"
import { v1 as uuidv1 } from "uuid"
import * as Sentry from "@sentry/react"

import { DirectMessages } from "@guardian/API/Optimus";

import {
  getProtectSession,
} from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { timeString } from "@guardian/Utils/util"

import styles from "@guardian/Components/ModSOS/components/Chat/Chat/Chat.module.css"
import { webSockets as socket } from "@guardian/Sockets";
import SOSSessionContext from "@guardian/Components/Contexts/SOSSessionContext"
import {
  MessageType,
  replaceIdsWithNames,
  Message,
  PLACEHOLDER_TEXT,
  DISABLED_TEXT,
  getMappedProtectUsers,
  getFormattedProtectMessages,
} from "@guardian/Components/ModSOS/components/Chat/Chat/Chat.helper"
import Container from "@guardian/Components/Common/Container"
import { useCountdown } from "@guardian/Components/ModSOS/hooks/useCountdown"
import { INCIDENT_UPSELL } from "@guardian/Components/ModSOS/components/CallQueue/CallNodeLink/data"
import { Message as MessageComponent } from "@guardian/Components/ModSOS/components/Chat/Message"
import { SessionDivider } from '@guardian/Components/ModSOS/components/Chat/Chat/ChatV2.SessionDivider';

interface IProps {
  activeSessionId: string
  entryPointType?: string
}

const NUM_TO_SORT = 6

export const ChatV2: React.FunctionComponent<IProps> = ({
  activeSessionId,
  entryPointType,
}) => {
  const messagesDiv = useRef<HTMLDivElement>(null)
  const [currentText, setCurrentText] = useState("")
  const userDisconnected = useContext(SOSSessionContext)
  const [messages, setMessages] = useState<Array<Message>>([])
  const [polledMessages, setPolledMessages] = useState<Array<Message>>([])
  const [userMap, setUserMap] = useState<Record<string, string>>({})
  const [activeGroupId, setActiveGroupId] = useState("")
  const {
    isOn: isTyping,
    startCountDown: turnOnIsTyping,
    clearCountDown: turnOffIsTyping,
  } = useCountdown()
  const [userId, setUserId] = useState<string>()

  const refreshMessages = async (initializeMessageState: boolean) => {
    try {
      // Note: The use of rjxs's `from` here is due to a prior legacy
      // implementation of this code. It is highly likely this is not necessary.
      // If you work on this code, please review and remove if possible.
      const { data } =
        await from(DirectMessages.getGroupDMs(activeGroupId)).toPromise()
      const dataMessages = get(data, "history", [])
      if (initializeMessageState) {
        setMessages(getFormattedProtectMessages(dataMessages))
      }
      setPolledMessages(getFormattedProtectMessages(dataMessages))
    } catch (error: any) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    for (let polledMessage of polledMessages) {
      const socketMessage = find(messages, { id: polledMessage.id })

      const dateSame = dayjs(socketMessage?.timestamp).isSame(
        dayjs(polledMessage.timestamp),
        "second",
      )

      if (socketMessage && dateSame) {
        socketMessage.timestamp = polledMessage.timestamp
      }

      if (!isEqual(socketMessage, polledMessage)) {
        setMessages([...polledMessages])
      }
    }
  }, [polledMessages])

  const handleSOSMessageEvent: (e: any) => void = useCallback(
    ({ payload }) => {
      if (payload.value.type === "isTyping") {
        turnOnIsTyping()
      }

      if (payload.value.type !== "newGroupMessage") {
        return
      }
      const { id, groupId, authorId, body, createdAt } = payload.value.value
      if (groupId !== activeGroupId) {
        return
      }

      setMessages(prev => {
        turnOffIsTyping()

        const exists = prev.findIndex(item => item.id === id) !== -1

        if (!exists) {
          return prev.slice(0, prev.length - NUM_TO_SORT).concat(
            sortBy(
              prev.slice(prev.length - NUM_TO_SORT).concat([
                {
                  id,
                  message: body.text,
                  timestamp: createdAt,
                  type: "chat",
                  userId: authorId,
                },
              ]),
              message => {
                return dayjs(message.timestamp).valueOf()
              },
            ),
          )
        }

        return prev
      })
    },
    [activeGroupId],
  )

  useEffect(() => {
    if (userDisconnected || activeGroupId === "") {
      return
    }

    refreshMessages(true)
    const subscription = timer(5000, 5000).subscribe(() => {
      refreshMessages(false)
    })

    // Note: The use of rjxs's `from` here is due to a prior legacy
    // implementation of this code. It is highly likely this is not necessary.
    // If you work on this code, please review and remove if possible.
    from(DirectMessages.getGroupDMInfo(activeGroupId))
      .toPromise()
      .then(({ data }) => {
        const members = get(data, "members", [])
        const userId = members.find(
          (member: any) => !member.userId.startsWith("agent_id"),
        )
        setUserId(userId?.userId)

        setUserMap(getMappedProtectUsers(members))
      })

    // Note: The use of rjxs's `from` here is due to a prior legacy
    // implementation of this code. It is highly likely this is not necessary.
    // If you work on this code, please review and remove if possible.
    from(DirectMessages.getGroupDMs(activeGroupId))
      .toPromise()
      .then(({ data }) => {
        const dataMessages = get(data, "history", [])
        setMessages(getFormattedProtectMessages(dataMessages))
      })

    if (!socket.isOpen && !socket.isOpening) {
      console.log("emergency socket open")
      socket.open()
    }
    socket.sub({ method: "agentSubscribeDirectMessage" })
    socket.onEvent("dmEvent", handleSOSMessageEvent)
    return () => {
      socket.removeEvent("agentSubscribeDirectMessage", handleSOSMessageEvent)
      subscription.unsubscribe()
    }
  }, [activeGroupId, userDisconnected, handleSOSMessageEvent])

  useEffect(() => {
    if (userDisconnected) {
      return
    }

    getProtectSession(activeSessionId)
      .toPromise()
      .then(({ data }) => {
        setActiveGroupId(data.groupId)
      })
  }, [activeSessionId, userDisconnected])

  useEffect(() => {
    if (messagesDiv.current) {
      messagesDiv.current.scrollTop = messagesDiv.current.scrollHeight
    }
  }, [messages, isTyping])

  const onChangeText: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void = useCallback(({ target }) => {
    setCurrentText(target.value)
  }, [])

  const onSubmit = async (error: React.FormEvent<HTMLFormElement>) => {
    error.preventDefault()
    if (currentText === "") {
      return
    }

    const data = {
      id: uuidv1(),
      groupId: activeGroupId,
      body: {
        text: currentText
      }
    };

    // Note: The use of rjxs's `from` here is due to a prior legacy
    // implementation of this code. It is highly likely this is not necessary.
    // If you work on this code, please review and remove if possible.
    await from(DirectMessages.sendGroupDMMessage(data)).toPromise()
    setCurrentText("")
  }

  const onAuthorUndefined: () => void = () => {
    // Note: The use of rjxs's `from` here is due to a prior legacy
    // implementation of this code. It is highly likely this is not necessary.
    // If you work on this code, please review and remove if possible.
    from(DirectMessages.getGroupDMInfo(activeGroupId))
      .toPromise()
      .then(({ data }) => {
        const members = get(data, "members", [])
        setUserMap(getMappedProtectUsers(members))
      })
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerBody} ref={messagesDiv}>
        {entryPointType === INCIDENT_UPSELL && (
          <Container leftJustify>
            <Container
              top={1}
              bottom={1}
              left={2}
              right={2}
              className={styles.badge}
            >
              Incident
            </Container>
          </Container>
        )}
        {messages.map((message: Message) => {
          const divider = message.message === 'Your agent is connecting' ? <SessionDivider message={message} /> : null

          return (
            <>
              {divider}
              <MessageComponent
                key={message.id}
                message={replaceIdsWithNames(message.message, userMap)}
                timestamp={timeString(message.timestamp)}
                author={message.userId ? userMap[message.userId] : ""}
                onAuthorUndefined={onAuthorUndefined}
                type={message.type as MessageType}
              />
            </>
          )
        })}
        {isTyping && (
          <div className={styles.typingIndicator}>
            {userId ? userMap[userId] : "User"} is typing
            <span className={styles.one}>.</span>
            <span className={styles.two}>.</span>
            <span className={styles.three}>.</span>
          </div>
        )}
      </div>
      <form onSubmit={onSubmit}>
        <input
          type='text'
          className={styles.message}
          placeholder={PLACEHOLDER_TEXT}
          value={userDisconnected ? DISABLED_TEXT : currentText}
          onChange={onChangeText}
          disabled={userDisconnected}
        />
      </form>
    </div>
  )
}
