import { useMutation } from "@tanstack/react-query"

import { AuditIncidentService } from "@guardian/Services/AuditIncident"

import { useAuditToolContext } from "@guardian/Components/AuditTool/store"
import {
  AuditToolEntryKey,
  AuditToolEntryType,
  AuditToolEntryTypeMap,
} from "@guardian/Components/AuditTool/types"
import { getEntryKey } from "@guardian/Components/AuditTool/utils"

type MutationParams<T extends AuditToolEntryType> = {
  type: T
  entries: AuditToolEntryTypeMap[T][]
}

const BATCH_SYNC_MAP: {
  [T in AuditToolEntryType]: (
    incidentKeys: AuditToolEntryKey[],
  ) => Promise<AuditToolEntryTypeMap[T][]>
} = {
  [AuditToolEntryType.ADDRESS]: AuditIncidentService.batchSyncAddress,
  [AuditToolEntryType.TITLE_UPDATE]:
    AuditIncidentService.batchSyncTitleAndUpdate,
}

export const useAuditIncidentSync = <T extends AuditToolEntryType>() => {
  const { setIsSyncingEntries, setEntries } = useAuditToolContext()

  const auditIncidentMutation = useMutation<
    AuditToolEntryTypeMap[T][],
    unknown,
    MutationParams<T>
  >({
    mutationFn: ({ type, entries }) => {
      const incidentKeys = entries.map(entry => ({
        clipId: entry.clipId,
        incidentId: entry.incidentId,
      }))
      return BATCH_SYNC_MAP[type](incidentKeys)
    },
    onMutate: () => {
      setIsSyncingEntries(true)
    },
    onSuccess: (syncedEntries, { entries }) => {
      const keyedEntries = entries.reduce(
        (acc, entry) => {
          acc[getEntryKey(entry)] = entry
          return acc
        },
        {} as Record<string, AuditToolEntryTypeMap[T]>,
      )
      // Merge synced data with local data
      const data = syncedEntries.map(syncedEntry => {
        const localEntry = keyedEntries[getEntryKey(syncedEntry)]
        return { ...localEntry, ...syncedEntry }
      })
      setEntries(data)
    },
    onSettled: () => {
      setIsSyncingEntries(false)
    },
  })

  return auditIncidentMutation
}
