import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import _ from "lodash"
import { cleanIncidentUpdate } from "@guardian/Utils/util"
import { IProps } from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/types"
import { CreateIncidentForm } from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/components/CreateIncidentForm"
import { CreateIncidentError } from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/components/CreateIncidentError"
import { CreateIncidentSuccess } from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/components/CreateIncidentSuccess"
import styles from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/SOSIncidentPane.module.css"
import {
	ICreateIncidentLocation,
  ICreateIncidentSelectedTag,
} from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/components/CreateIncidentForm/types"

import consts from "@guardian/Constants"
import { IncidentService } from "@guardian/Services/Incident";

export const SOSIncidentPane: React.FunctionComponent<IProps> = ({
  bounds,
  address,
}) => {
  const [error, setError] = useState("")
  const [incidentId, setIncidentId] = useState<string>()

  const handleSubmit = async (
    level: number,
    dontNotify: boolean,
    dontShowClips: boolean,
    title?: string,
    location?: ICreateIncidentLocation,
    update?: string,
    selectedTag?: ICreateIncidentSelectedTag,
  ) => {
    if (
      !(
        title &&
        location &&
        location.location &&
        location.address &&
        location.cityCode &&
        update
      )
    ) {
      return
    }

    const id = uuid()
    const spec = {
      id,
      title,
      text: update,
      level,
      location,
      occurredAt: new Date(),
      visibility: consts.Visibility.Public,
      clipIds: [],
      tagIds: [],
      tags: selectedTag ? [selectedTag] : [],
      dontNotify,
      dontShowClips,
    }

    spec.text = cleanIncidentUpdate(spec.text)

    if (spec.text === "") {
      setError("Make sure your update is valid")
      return
    }

    try {
      const incident = await IncidentService.updateIncidentRevision(spec)
      setIncidentId(incident.id)
    } catch (error: any) {
      const msg = _.get(error, "response.data.error", error.message)
      setError(msg)
    }
  }

  return (
    <div className={styles.container}>
      <CreateIncidentError error={error} />
      {incidentId ? (
        <CreateIncidentSuccess incidentId={incidentId} />
      ) : (
        <CreateIncidentForm
          onSubmit={handleSubmit}
          bounds={bounds}
          address={address}
        />
      )}
    </div>
  )
}
