import _get from "lodash/get"
import { array, bool, func, number, object, string } from "prop-types"
import React, { PureComponent, forwardRef } from "react"

import { eventHub, useGlobalState } from "@guardian/State"

import AddressOrAreaInput from "@guardian/Components/Form/Inputs/AddressOrAreaInput"
import IncidentStatusSelect from "@guardian/Components/IncidentStatus/IncidentStatusSelect"
import { LabeledCheckbox } from "@guardian/UI/Legacy/LabeledCheckbox"
import { MultilineInput } from "@guardian/UI/Legacy/MultilineInput"
import { TextInput } from "@guardian/UI/Legacy/TextInput"

import CategoryInput from "./CategoryInput"
import EndAdornmentButton from "./EndAdornmentButton"
import LevelInput from "./LevelInput"
import style from "./styles.module.css"

function editClass(value, incValue, revValue) {
  value = value || null
  incValue = incValue || null
  revValue = revValue || null
  if (!value) {
    return ""
  }
  if (value === revValue) {
    return "pending"
  } else if (value === incValue) {
    return ""
  } else {
    return "edited"
  }
}

const DISABLE_NOTIFS_FOR = ["Missing Person"]
const DEFAULT_DISABLE_CLIPS_CATEGORIES = [
  "Police Related",
  "Harassment",
  "Barricade",
  "Protest",
]

class ModUpdateInputs extends PureComponent {
  constructor(props) {
    super(props)
    this.shouldFocus = props.autoFocus
    this.state = {
      useFuzzySearch: false,
      initialMountDone: false,
    }
  }

  handleFocus = () => {
    this.shouldFocus = true
  }

  onKeyDown = e => {
    const { onSubmit } = this.props
    e.stopPropagation()
    if (e.key === "Enter") {
      e.preventDefault()
      onSubmit()
    }
  }

  componentDidMount() {
    eventHub.on("focusOnAttach", this.handleFocus)
    eventHub.on("levelIncrease", this.props.onChangeLevel)
    eventHub.on("levelDecrease", this.props.onChangeLevel)

    this.setState({ isShowClipDisabled: this.isShowClipDisabled(this.props) })
    this.props.onChangeDontShowClips(this.getDefaultDontShowClips(this.props))
  }

  componentWillUnmount() {
    eventHub.off("focusOnAttach", this.handleFocus)
    eventHub.off("levelIncrease")
    eventHub.off("levelDecrease")
  }

  componentDidUpdate(prevProps) {
    const {
      newIncident,
      disabled,
      clipsLength,
      incident,
      onChangeDontNotify,
      onChangeDontShowClips,
      tag,
    } = this.props
    const shouldFocus =
      this.shouldFocus && clipsLength > prevProps.clipsLength && !disabled

    if (!newIncident && shouldFocus) {
      this.update.focus()
    } else if (newIncident && shouldFocus) {
      this.geosuggest.focus()
    }

    this.shouldFocus = false

    if (_get(incident, "id") !== _get(prevProps.incident, "id")) {
      onChangeDontNotify(undefined)
      onChangeDontShowClips(undefined)
    }

    if (DISABLE_NOTIFS_FOR.includes(tag) && !this.state.initialMountDone) {
      onChangeDontNotify(true)
      this.setState({ initialMountDone: true })
    }

    if (tag !== prevProps.tag) {
      this.props.onChangeDontShowClips(this.getDefaultDontShowClips(this.props))
    }
  }

  isShowClipDisabled(props) {
    const { showClipsConfig, attachedClips } = props
    const showClipsServiceAreas = showClipsConfig?.showClipsServiceAreas ?? null
    if (this.hasAttachedClips(attachedClips) && showClipsServiceAreas != null) {
      try {
        // Assuming that all clips are from the same service area
        const clipServiceArea = attachedClips
          .get(0)
          .channel.split("/")[0]
          .toUpperCase()
        if (!showClipsServiceAreas.includes(clipServiceArea)) {
          return true
        }
      } catch (e) {
        console.error(e)
      }
    }
    return false
  }

  hasAttachedClips(attachedClips) {
    return attachedClips && attachedClips.size > 0
  }

  getDefaultDontShowClips(props) {
    const { showClipsConfig, tag } = props
    // if show clips is disabled, set true
    // if tag is in the disabled category, set true
    // undefined otherwise
    if (this.isShowClipDisabled(props)) {
      return true
    }
    const showClipsDisabledCategories =
      showClipsConfig?.showClipsDisabledCategories ??
      DEFAULT_DISABLE_CLIPS_CATEGORIES
    if (showClipsDisabledCategories?.includes(tag)) {
      return true
    }
    return undefined
  }

  onSelectLoc = ({ value: geo }) => {
    this.props.onChooseLocation(geo)
  }

  onFuzzyChange = () => {
    this.setState({ useFuzzySearch: !this.state.useFuzzySearch })
  }

  render() {
    const {
      title,
      location,
      update,
      level,
      incident,
      pendRev,
      disabled,
      newIncident,
      mapInit,
      tags,
      tag,
      autoFormatTitle,
      dontNotify,
      dontShowClips,
      citywideGeoCode,
      canCitywide,
      attachedClips,
      canBypassUpdate,
      setBypassUpdate,
      bypassUpdate,
      onChangeTag,
      onToggleManualCategorySet,
      onChangeTitle,
      onChangeUpdate,
      onChangeLevel,
      onChangeDisplayLocation,
      onChangeGeo,
      onBlurTitle,
      onBlurUpdate,
      onAutoFormatTitleChange,
      onChangeDontNotify,
      onChangeDontShowClips,
      onChangeServiceArea,
      showAddressWarning,
      buttons,
      isLoadingUpdate,
      isManualCategorySet,
    } = this.props
    const hasClips = this.hasAttachedClips(attachedClips)

    return (
      <div className='mod-form inputs' onKeyDown={this.onKeyDown}>
        <div className={style.categoryRow}>
          <CategoryInput
            onSetCategory={onChangeTag}
            category={tag}
            categories={["N/A"].concat((tags || []).map(t => t.display_name))}
            manualCategorySet={isManualCategorySet}
            toggleManualCategorySet={onToggleManualCategorySet}
            disabled={disabled}
          />
          <LevelInput
            level={level}
            onChangeLevel={onChangeLevel}
            disabled={disabled}
          />
          {incident && (
            <div className={style.closureContainer}>
              <IncidentStatusSelect {...this.props} autoSet />
            </div>
          )}
        </div>
        <div className=''>
          <AddressOrAreaInput
            disabled={disabled}
            locClassName={
              "address-search " +
              editClass(
                location.address,
                _get(incident, "location.address"),
                _get(pendRev, "location.address"),
              )
            }
            locInitialValue={location.address || ""}
            locOnSelect={this.onSelectLoc}
            locOnChange={onChangeGeo}
            autoFocus={newIncident && this.shouldFocus}
            locRef={geosuggest => (this.geosuggest = geosuggest)}
            mapInit={mapInit}
            onChangeServiceArea={onChangeServiceArea}
            citywideGeoCode={citywideGeoCode}
            incidentId={_get(incident, "id", "")}
            canCitywide={canCitywide}
            attachedClips={attachedClips}
            showAddressWarning={showAddressWarning}
            useFuzzySearch={this.state.useFuzzySearch}
            onFuzzyChange={this.onFuzzyChange}
          />
        </div>
        <div className={style.locationRow}>
          <div className={style.displayLocation}>
            <TextInput
              type='text'
              value={location.location}
              placeholder='-'
              notched
              label='Display Location'
              onChange={onChangeDisplayLocation}
              disabled={disabled}
            />
          </div>
          <div className={style.police}>
            <TextInput
              type='text'
              value={location.police || ""}
              placeholder='-'
              notched
              label='Police'
              disabled={true}
            />
          </div>
        </div>
        <div className={style.title}>
          <TextInput
            multiline
            minRows={1}
            type='text'
            className={editClass(
              title,
              _get(incident, "title"),
              _get(pendRev, "title"),
            )}
            value={title || ""}
            onChange={onChangeTitle}
            onBlur={onBlurTitle}
            placeholder='Title'
            disabled={disabled}
            onKeyDown={e => {
              if (e.key === "Tab") {
                e.preventDefault()
                this.update.focus()
              }
            }}
            label='Title'
            notched
            endAdornment={
              <div style={{ width: "24px", height: "24px" }}>
                <EndAdornmentButton
                  label='Aa'
                  active={autoFormatTitle}
                  onClick={() => onAutoFormatTitleChange(!autoFormatTitle)}
                />
              </div>
            }
          />
        </div>
        <div className={style.updateRow}>
          <div className={style.update}>
            <MultilineInput
              minRows={2}
              name='update'
              value={update}
              multiline
              onChange={onChangeUpdate}
              disabled={disabled || isLoadingUpdate}
              placeholder='Update'
              onKeyDown={this.onKeyDown}
              inputRef={update => (this.update = update)}
              onBlur={onBlurUpdate}
              autoComplete='off'
            />
          </div>
          {isLoadingUpdate && (
            <i
              className='spinner fas fa-spinner'
              title='Update is being loaded'
            />
          )}
        </div>
        <div className={style.checkboxesTop}>
          <LabeledCheckbox
            active={!dontNotify}
            onClick={() => onChangeDontNotify(!dontNotify)}
            label='Send Notification'
            disabled={disabled}
            blue
          />
          {hasClips && (
            <LabeledCheckbox
              active={!dontShowClips}
              onClick={() => onChangeDontShowClips(!dontShowClips)}
              label='Show Clips'
              disabled={disabled || this.state.isShowClipDisabled}
              blue
            />
          )}
        </div>
        {!!canBypassUpdate && hasClips && (
          <LabeledCheckbox
            label='Attach clips without creating update'
            active={bypassUpdate}
            onClick={() => setBypassUpdate(!bypassUpdate)}
            blue
            disabled={disabled}
          />
        )}
        <div className={style.buttons}>{buttons}</div>
      </div>
    )
  }
}

ModUpdateInputs.propTypes = {
  onSubmit: func,
  disabled: bool,
  clipsLength: number,
  title: string,
  location: object,
  update: string,
  level: number,
  incident: object,
  pendRev: object,
  onChooseLocation: func,
  onChangeTitle: func,
  onChangeUpdate: func,
  onChangeLevel: func,
  onChangeDisplayLocation: func,
  onChangeGeo: func,
  onChangeTag: func,
  onToggleManualCategorySet: func,
  onBlurTitle: func,
  onBlurUpdate: func,
  newIncident: bool,
  mapInit: bool,
  autoFocus: bool,
  tags: array,
  tag: string,
  onAutoFormatTitleChange: func,
  autoFormatTitle: bool,
  onChangeServiceArea: func,
  citywideGeoCode: string,
  attachedClips: object,
  canBypassUpdate: bool,
  setBypassUpdate: func,
  bypassUpdate: bool,
  canCitywide: bool,
  showAddressWarning: bool,
  buttons: object,
}

ModUpdateInputs.defaultProps = {
  canCitywide: true,
}

const ModUpdateInputsHOC = forwardRef((props, ref) => {
  const { showClipsConfig } = useGlobalState(state => ({
    showClipsConfig: state.global.showClipsConfig,
  }))
  return <ModUpdateInputs ref={ref} {...props} showClipsConfig={showClipsConfig} />
})

export default ModUpdateInputsHOC
