import { v4 as uuid } from "uuid";

export const generateAssignedAgentChatSession = (session) => {
  // The ID of the dedicated group that the client can use it to load chat
  // history, etc. as needed, even before a session is established.
  const groupId  = `agentChat_${ session.userId }`;
  // When a chat session payload is modified we update the `updateId` so that
  // react can properly re-render the session.
  const updateId = `assigned-agent-chat-session__update-id-${ uuid() }`;

  return { ...session, groupId, updateId };
};

export const generateAssignedAgentChatSessions = (sessions) => {
  const assignedAgentChatSessions =
    sessions.map(generateAssignedAgentChatSession);

  return assignedAgentChatSessions;
};
