import classnames from "classnames"
import { keyBy, sum } from "lodash"
import { array, bool, func, object, string } from "prop-types"
import React, { memo, useEffect, useState } from "react"

import { eventHub } from "@guardian/State"

import style from "./ListeningGroupChannelSelector.module.css"
import SubArea from "./SubArea/SubArea"

const ListeningGroupChannelSelector = ({
  name,
  code,
  serviceAreas,
  activeChannels,
  channels,
  channelStats,
  selectedListeningGroup,
  mutes,
  sorted,
  allSubAreas,
  onlyStateGroup,
  subAreasMatchFilter,
  handleInputChange,
  filterText,
}) => {
  const [selectedSubArea, setSelectedSubArea] = useState()
  const serviceAreaMapping = keyBy(serviceAreas, "code")
  const activeCount = Object.keys(activeChannels).filter(
    cid => channels[cid] && !!serviceAreaMapping[channels[cid].serviceArea],
  ).length
  const flaggedCount = sum(
    Object.keys(channelStats)
      .filter(
        cid => channels[cid] && !!serviceAreaMapping[channels[cid].serviceArea],
      )
      .map(cid => channelStats[cid]),
  )

  useEffect(() => {
    if (onlyStateGroup) {
      eventHub.emit("toggle listening group", code)
    }
  }, [onlyStateGroup, code])

  useEffect(() => {
    if (selectedListeningGroup !== code) {
      setSelectedSubArea(null)
    }
  }, [selectedListeningGroup, code])

  return (
    <div className={style.container}>
      <button
        className={classnames(style.button, { hidden: onlyStateGroup })}
        onClick={() => {
          eventHub.emit("toggle listening group", code, sorted)
          handleInputChange("")
        }}
      >
        <span>{name}</span>
        {activeCount > 0 && (
          <>
            <span className={style.activeCount}>({activeCount})</span>
          </>
        )}
        {flaggedCount > 0 && (
          <span className={style.flaggedCount}>
            {flaggedCount} <i className='fas fa-arrow-up' />
          </span>
        )}
      </button>
      {(selectedListeningGroup === code || subAreasMatchFilter) && (
        <div
          className={style.menu}
          onMouseLeave={() => {
            setSelectedSubArea(null)
            eventHub.emit("setDialogMask", false)
          }}
        >
          {sorted
            .filter(subArea => {
              return (
                !filterText ||
                subArea.toLowerCase().match(filterText.toLowerCase())
              )
            })
            .map((subArea, idx) => (
              <SubArea
                key={subArea}
                idx={idx}
                onSelect={code => {
                  setSelectedSubArea(code)
                  eventHub.emit("setDialogMask", true)
                }}
                subArea={subArea}
                listeningGroup={code}
                selected={selectedSubArea === subArea}
                activeChannels={activeChannels}
                channels={channels}
                channelStats={channelStats}
                responders={allSubAreas[subArea]}
                mutes={mutes}
              />
            ))}
        </div>
      )}
    </div>
  )
}

ListeningGroupChannelSelector.propTypes = {
  name: string,
  code: string,
  serviceAreas: object,
  activeChannels: object,
  channels: object,
  channelStats: object,
  selectedListeningGroup: string,
  sorted: array,
  mutes: object,
  responders: object,
  allSubAreas: object,
  onlyStateGroup: bool,
  subAreasMatchFilter: bool,
  handleInputChange: func,
}

export default memo(ListeningGroupChannelSelector)
