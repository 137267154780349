import { OptimusAPI } from "@guardian/API/Optimus";

interface GNetUpdateMetricsData {
  component: string
  label: string
  value: string
};

const GNet = {
  getClipFlags: (clipId: string, options: any = {}) => {
    return OptimusAPI.get(
      `/gnet/clip_flags`,
      {
        ...options,
        params: {
          ...options.params,
          clipId
        }
      }
    );
  },

  getVideoSize: (url: string, options: any = {}) => {
    return OptimusAPI.get(
      `/gnet/video_size`,
      {
        ...options,
        params: {
          ...options.params,
          url
        }
      }
    );
  },

  sendMetrics: (data: GNetUpdateMetricsData, options?: any) => {
    return OptimusAPI.post(`/gnet/metrics`, data, options);
  }
};

export default GNet;
