import styled from "styled-components";

const ToggleSwitch = styled.div`
  background-color: #331C1F;
  border-radius: var(--radii-pill);
  cursor: pointer;
  height: 14px;
  position: relative;
  width: 28px;
  will-change: background-color;
  z-index: 2;

  &:before {
    border-radius: var(--radii-pill);
    bottom: 0;
    box-shadow: var(--borders-full) #FF1732;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    will-change: box-shadow;
    z-index: 3;
  }

  &:after {
    background-color: #ff1732;
    border-radius: var(--radii-circle);
    box-shadow: inset 0 0 0 2.5px #331C1F;
    content: "";
    height: calc(100%);
    left: 0;
    padding-left: calc(50%);
    position: absolute;
    top: 0;
    transition: transform 125ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 0;
    will-change: background-color, box-shadow, transform;
    z-index: 2;
  }
`;

export default ToggleSwitch;
