import React, { useCallback, useEffect, useRef, useState } from "react";
import { isForwardRef } from "react-is";
import PropTypes from "prop-types";
import { Manager, Popper, Reference } from "react-popper";

import { getPopperPlacement } from "../utils/placements";

import TooltipContent from "./TooltipContent";

const Tooltip = props => {
  const {
    children,
    content,
    placement,
    popperModifiers,
    refKey,
    zIndex
  } = props;

  const [hovering, setHovering] = useState(false);
  const [open, setOpen] = useState(false);

  const openRef = useRef(open);
  const scheduleUpdateRef = useRef(undefined);
  const triggerElRef = useRef(undefined);

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  // Recalculate popper placement while open to allow animations to complete.
  // This must be ran every render to allow for the number of items to change
  // and still be placed correctly.
  useEffect(() => {
    const open = openRef.current;

    if (open) {
      scheduleUpdateRef.current && scheduleUpdateRef.current();
    }
  });

  // Handle toggling open state of tooltip when mouse enters or leaves the
  // trigger element.
  useEffect(() => {
    if (hovering) {
      setOpen(true);
    } else {
      setOpen(false)
    }
  }, [hovering]);

  // Handle setting listeners on our trigger element. This will be where we set
  // listeners to control the open state of our tooltip based on user
  // interaction with the tooltip wrapped element (the "trigger").
  const setTriggerElRef = useCallback(node => {
    const onMouseEnter = () => {
      setHovering(true);
    };

    const onMouseLeave = () => {
      setHovering(false);
    };

    const handleMount = (triggerEl) => {
      triggerEl.addEventListener("mouseenter", onMouseEnter);
      triggerEl.addEventListener("mouseleave", onMouseLeave);
    };

    const handleUnmount = (triggerEl) => {
      triggerEl.removeEventListener("mouseenter", onMouseEnter);
      triggerEl.removeEventListener("mouseleave", onMouseLeave);
    };

    if (triggerElRef.current) {
      handleUnmount(triggerElRef.current);
    }

    triggerElRef.current = node;

    if (triggerElRef.current) {
      handleMount(triggerElRef.current);
    }
  }, []);

  const renderTriggerElement = useCallback(popperRef => {
    if (!isForwardRef(children)) {
      throw new Error("Components wrapped in a Tooltip must accept a forward ref.");
    }

    const trigger = React.cloneElement(React.Children.only(children), {
      [refKey]: originalChildRef => {
        // Pass ref to popperJS for positioning.
        popperRef(originalChildRef);

        // Set up event listeners on tooltip wrapped element (the "trigger").
        setTriggerElRef(originalChildRef);
      }
    });

    return trigger;
  }, []);

  const modifiers = {
    preventOverflow: {
      boundariesElement: "window"
    },
    ...popperModifiers
  };

  const popperPlacement = getPopperPlacement(placement);

  return (
    <Manager>
      <Reference>
        {
          ({ ref: popperRef }) => renderTriggerElement(popperRef)
        }
      </Reference>
      <Popper
        modifiers={modifiers}
        placement={popperPlacement}
      >
        {
          ({ ref, scheduleUpdate, style }) => {
            scheduleUpdateRef.current = scheduleUpdate;

            return (
              <TooltipContent
                ref={open ? ref : undefined}
                open={open}
                placement={popperPlacement}
                style={style}
                zIndex={zIndex}
              >
                { content }
              </TooltipContent>
            );
          }
        }
      </Popper>
    </Manager>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    "auto",
    "bottom",
    "bottom-end",
    "bottom-start",
    "end",
    "end-bottom",
    "end-top",
    "start",
    "start-bottom",
    "start-top",
    "top",
    "top-end",
    "top-start"
  ]),
  refKey: PropTypes.string,
  zIndex: PropTypes.number
};

Tooltip.defaultProps = {
  placement: "top",
  refKey: "ref",
  zIndex: 1000
};

export default Tooltip;
