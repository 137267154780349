import React, { useState, useRef, useEffect, useCallback } from "react"
import FilePlayer from "react-player/file"
import screenfull from "screenfull"
import VisibilitySensor from "react-visibility-sensor"
import { css } from "@emotion/css"
import classnames from "classnames"
import useVideoPlayerKeyBindings from "@guardian/Hooks/useVideoPlayerKeyBindings"
import { secondsToMin } from "@guardian/Utils/util"
import combokeys from "@guardian/Utils/hotkeys"
import VideoTrimming from "./VideoTrimming"
import style from "./VideoPlayer.module.css"

interface IProps {
  url: string
  originalUrl: string
  autoPlay: boolean
  onPlay: () => void
  live: boolean
  topActions?: React.ReactNode
  actions?: React.ReactNode
  menu?: React.ReactNode
  onProgress: (state: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) => void
  onTrimStream: (trimStart: number, trimEnd: number) => void
  durationSeconds: number
  originalDuration: number
  defaultMuted: boolean
  trimmed: boolean
  trimStartingPoint: number
  isTrimmable: boolean
  isLandscape?: boolean
}

const VideoPlayer = ({
  url,
  originalUrl,
  autoPlay,
  onPlay,
  live,
  actions,
  topActions,
  menu,
  onProgress,
  onTrimStream,
  durationSeconds,
  originalDuration,
  defaultMuted,
  trimStartingPoint,
  isTrimmable,
  isLandscape,
}: IProps) => {
  const [playing, setPlaying] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)
  const [muted, setMuted] = useState(defaultMuted)
  const [seeking, setSeeking] = useState(false)
  const [ready, setReady] = useState(false)
  const [seekValue, setSeekValue] = useState(0)
  const [showMenu, setShowMenu] = useState(false)
  const [trimStart, setTrimStart] = useState(trimStartingPoint || 0)
  const [trimEnd, setTrimEnd] = useState(Math.round(originalDuration))
  const [trimMode, setTrimMode] = useState(false)

  // This is the current time of the player, NOT player.current.getCurrentTime()...
  const [progress, setProgress] = useState(0.0)
  const [playerURL, setURL] = useState(
    isTrimmable && originalUrl !== "" ? originalUrl : url,
  )
  const [previousTime, setPreviousTime] = useState(0.0)

  const playerRef = useRef<FilePlayer>(null)

  const trimStartPercent = (trimStartingPoint / originalDuration) * 100
  const trimEndPercent =
    ((durationSeconds + trimStartingPoint) / originalDuration) * 100
  const allowTrimming = isTrimmable && !live && !!onTrimStream

  useVideoPlayerKeyBindings(playerRef, setPlaying)

  const turnOffTrimMode = useCallback(() => {
    if (playerRef.current) {
      setTrimMode(false)
      setPreviousTime(playerRef.current.getCurrentTime() - trimStartingPoint)
      setSeekValue(previousTime)
    }
  }, [trimStartingPoint])

  useEffect(() => {
    if (allowTrimming) {
      combokeys.bind("t", () => {
        if (playerRef.current) {
          setPreviousTime(playerRef.current.getCurrentTime())
          if (!trimMode) {
            setTrimMode(true)
          } else {
            turnOffTrimMode()
          }
        }
      })
      combokeys.bind("esc", () => {
        if (trimMode) {
          turnOffTrimMode()
        }
      })
      combokeys.bind("i", () => {
        if (trimMode && playerRef.current) {
          setTrimStart(playerRef.current.getCurrentTime())
        }
      })

      combokeys.bind("o", () => {
        if (trimMode && playerRef.current) {
          setTrimEnd(playerRef.current.getCurrentTime())
          playerRef.current.seekTo(trimStart, "seconds")
        }
      })
    }

    return () => {
      combokeys.unbind("t")
      combokeys.unbind("i")
      combokeys.unbind("o")
      combokeys.unbind("esc")
    }
  }, [trimStart, trimEnd, allowTrimming, trimMode, turnOffTrimMode])

  useEffect(() => {
    const trimEndingPoint = trimStartingPoint + durationSeconds
    if (
      playerRef.current &&
      trimEndingPoint !== 0 &&
      (progress > trimEndingPoint || progress < trimStartingPoint) &&
      !trimMode
    ) {
      playerRef.current.seekTo(trimStartingPoint, "seconds")
    }
  }, [progress])

  useEffect(() => {
    if (
      ready &&
      !live &&
      playerRef.current &&
      progress === 0 &&
      previousTime !== 0
    ) {
      setProgress(previousTime + trimStart)
    }
  }, [ready, live, progress, previousTime, trimStart])

  useEffect(() => {
    if (trimMode) {
      setURL(originalUrl)
    } else {
      setTrimEnd(trimStartingPoint + durationSeconds)
    }
  }, [trimMode, originalUrl, durationSeconds, trimStartingPoint])

  const play = (visible: boolean) => {
    setPlaying(visible && autoPlay)
  }

  const handleTrimStream = async () => {
    await onTrimStream(trimStart, trimEnd)
    setTrimStart(trimStart)
    turnOffTrimMode()
  }

  const startTrimming = () => {
    if (playerRef.current) {
      setPreviousTime(playerRef.current.getCurrentTime())
      setTrimMode(true)
    }
  }

  let positionString
  if (ready && !live && playerRef.current) {
    const currPosition = progress
    const duration = playerRef.current.getDuration()
    if (currPosition !== null && duration !== null) {
      positionString = `${secondsToMin(currPosition)} / ${secondsToMin(
        duration,
      )}`
    }
  }

  return (
    <VisibilitySensor onChange={play}>
      <div className={classnames(style.container, { [style.landscape]: isLandscape })}>
        <FilePlayer
          ref={playerRef}
          url={playerURL}
          className={style.player}
          width="100%"
          height="100%"
          controls={false}
          playing={playing}
          onPlay={onPlay}
          progressInterval={75}
          onProgress={args => {
            setProgress(args.playedSeconds)
            if (onProgress) {
              onProgress(args)
            }
          }}
          onDuration={() => {
            playerRef.current?.seekTo(
              ((trimMode && trimStart) || 0) + previousTime,
              "seconds",
            )
          }}
          muted={muted}
          onReady={() => setReady(true)}
          config={{
            attributes: {
              volume: 0,
              loop: !allowTrimming,
              playsInline: true,
            },
          }}
          pip={false}
        />
        {!!topActions && (
          <div className={style.topControls}>
            {topActions}
          </div>
        )}
        <div className={style.controls}>
          <div className={style.fullscreen}>
            <button
              onClick={() => {
                if (playerRef.current) {
                  screenfull.toggle(
                    playerRef.current.getInternalPlayer() as HTMLElement,
                  )
                  setFullscreen(!fullscreen)
                }
              }}
              className={style.rounded}
            >
              <img src='/Fullscreen.svg' alt='fullscreen' />
            </button>
          </div>
          <div className={style.buttons}>
            <button
              className={style.play}
              onClick={() => setPlaying(isPlaying => !isPlaying)}
            >
              {playing ? (
                <i className='fas fa-pause' />
              ) : (
                <i className='fas fa-play' />
              )}
            </button>
            <div className={style.time}>{positionString}</div>
            <button
              className={style.volume}
              onClick={() => setMuted(isMuted => !isMuted)}
            >
              {muted ? (
                <i className='fas fa-volume-mute' />
              ) : (
                <i className='fas fa-volume-up' />
              )}
            </button>
            {menu && (
              <div className={style.menuContainer}>
                {showMenu && <div className={style.menu}>{menu}</div>}
                <button
                  className={style.rounded}
                  onClick={() => setShowMenu(isShowingMenu => !isShowingMenu)}
                >
                  <i className='fas fa-ellipsis-h' />
                </button>
              </div>
            )}
          </div>
          <div className={style.positionContainer}>
            {playerRef.current && allowTrimming && trimMode && (
              <VideoTrimming
                value={
                  seeking
                    ? seekValue
                    : playerRef.current.getCurrentTime() ||
                      previousTime + trimStart
                }
                onChange={(key, val) => {
                  switch (key) {
                    case "position":
                      if (trimEnd <= val) {
                        setPlaying(false)
                        playerRef.current?.seekTo(trimStart, "seconds")
                      }
                      break
                    case "trimStart":
                      setTrimStart(val)
                      break
                    case "trimEnd":
                      setTrimEnd(val)
                      break
                    default:
                      break
                  }
                }}
                onSeekStart={() => setSeeking(true)}
                onSeekEnd={val => {
                  setSeeking(false)
                  setSeekValue(val)
                  playerRef.current?.seekTo(val, "seconds")
                }}
                trimStart={trimStart}
                trimEnd={trimEnd}
                domain={[0, originalDuration]}
              />
            )}
            {playerRef.current && !trimMode && (
              <div
                className={css`
                  input[type="range"]::-webkit-slider-runnable-track {
                    height: 3px;
                    background: linear-gradient(
                      to right,
                      #ffffff 0%,
                      #ffffff ${trimStartPercent}%,
                      #00b7b7 ${trimStartPercent}%,
                      #00b7b7 ${trimEndPercent}%,
                      #ffffff ${trimEndPercent}%
                    );
                  }
                `}
              >
                <input
                  className={style.scrubber}
                  type='range'
                  min={0}
                  max={
                    !live ? originalDuration : playerRef.current.getDuration()
                  }
                  value={seeking ? seekValue : progress}
                  readOnly={!seeking}
                  step={0.075}
                  onChange={e => setSeekValue(Number(e.target.value))}
                  onMouseDown={() => setSeeking(true)}
                  onMouseUp={e => {
                    const val = Number((e.target as HTMLInputElement).value)
                    playerRef.current?.seekTo(val, "seconds")
                    setProgress(val)
                    setSeeking(false)
                  }}
                />
              </div>
            )}
          </div>
          {actions}
        </div>
        {allowTrimming && (
          <div className={style.trimContainer}>
            <button
              className={style.trim}
              onClick={() => {
                if (trimMode) {
                  handleTrimStream()
                } else {
                  startTrimming()
                }
              }}
            >
              {trimMode ? `Trim Stream` : `Trim Mode (t)`}
            </button>
            {trimMode && (
              <button onClick={() => turnOffTrimMode()}>
                Exit Trim Mode (t)
              </button>
            )}
          </div>
        )}
      </div>
    </VisibilitySensor>
  )
}

export default VideoPlayer
