import classNames from "classnames";
import styled, { css} from "styled-components";

import menuStyles from "../../utils/menuStyles";
import { getMenuPosition } from "../../utils/placements";

const MenuWrapper = styled.div.attrs(
  props => {
    const {
      className,
      isAnimated
    } = props;

    return {
      className: classNames(className, "dropdown__menu-wrapper", {
        "dropdown__menu-wrapper--is-animated": isAnimated
      })
    };
  }
)`
  ${
    props => {
      const {
        isAnimated,
        isHidden,
        placement,
        theme,
        zIndex
      } = props;

      const { radii, space } = theme;

      return css`
        border-radius: ${ radii.medium };

        ${
          css`
            ${
              menuStyles(theme, getMenuPosition(placement), {
                hidden: isHidden,
                margin: `${ space.xSmall }`,
                zIndex: zIndex,
                animationModifier: isAnimated ?
                  ".dropdown__menu-wrapper--is-animated" : undefined
              })
            }
          `
        }
      `;
    }
  }
`;

MenuWrapper.defaultProps = {};

export default MenuWrapper;
