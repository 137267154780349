import classNames from "classnames";
import styled from "styled-components";

import { Col } from "@guardian/UI/Grid"

const Error = styled(Col).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__error"
    )
  };
})`
  align-items: center;
  background: var(--colors-black);
  color: var(--colors-red-040);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

Error.defaultProps = {};

export default Error;
