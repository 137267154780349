import React, { useReducer } from "react"
import { func, string } from "prop-types"

import { latestRev } from "@guardian/Utils/incident"

import { ContentService } from "@guardian/Services/Content"
import { IncidentService } from "@guardian/Services/Incident"

import { Spinner } from "@guardian/UI/Loader"
import Modal from "@guardian/Components/Modals/Modal"
import TextInput from "@guardian/Components/Form/Inputs/TextInput"
import P2PNearbyIncident from "@guardian/Components/VideoMod/P2PNearbyIncident"

import reducer, { actions, initialState } from "./reducer"
import {
  Container,
  CloseButton,
  InputLabel,
  InputWrapper,
  SearchButton,
  ErrorMessage,
  IncidentWrapper,
} from './styles'

const P2PMergeIncidentModal = ({ currentStreamId, currentIncidentId, onClose, onSuccess }) => {
  const [{
    incidentId,
    incident,
    isLoadingIncident,
    isMerging,
    errorMessage,
  }, dispatch] = useReducer(reducer, initialState)

  const searchIncident = async () => {
    if (!incidentId) {
      return
    }

    if (incidentId === currentIncidentId) {
      dispatch({ type: actions.SET_ERROR_MESSAGE, payload: "Cannot merge with itself" })
      return
    }

    try {
      dispatch({ type: actions.SET_IS_LOADING_INCIDENT, payload: true })
      dispatch({ type: actions.SET_ERROR_MESSAGE, payload: null })
      const data = await IncidentService.getIncidents({ incidentIds: [incidentId] })

      const newIncident = data?.[0]
      if (!newIncident) {
        dispatch({ type: actions.SET_ERROR_MESSAGE, payload: "Valid Incident not found" })
      } else {
        dispatch({ type: actions.SET_INCIDENT, payload: newIncident || null })
      }
    } catch (e) {
      console.error(e)
      dispatch({
        type: actions.SET_ERROR_MESSAGE,
        payload: 'Error fetching incident',
      })
    } finally {
      dispatch({ type: actions.SET_IS_LOADING_INCIDENT, payload: false })
    }
  }

  const handleP2PMerge = async () => {
    dispatch({ type: actions.SET_IS_MERGING, payload: true })
    dispatch({ type: actions.SET_ERROR_MESSAGE, payload: null })

    try {
      await ContentService.mergeP2PContent(currentStreamId, {
        childIncidentId: currentIncidentId,
        parentIncidentId: incident.id,
      })
      if (onSuccess && typeof onSuccess === 'function') {
        await onSuccess(incident.id)
      }
    } catch (e) {
      console.error(e)
      dispatch({
        type: actions.SET_ERROR_MESSAGE,
        payload: 'Error merging incidents',
      })
    } finally {
      dispatch({ type: actions.SET_IS_MERGING, payload: false })
    }
  }

  let content = null
  if (isLoadingIncident) {
    content = <Spinner />
  } else if (incident) {
    const latest = latestRev(incident)
    const timeLatest = latest?.occurredAt ?? incident.createdAt
    content = (
      <IncidentWrapper>
        <P2PNearbyIncident
          distance={incident.distance}
          address={incident.location.address}
          title={incident.title}
          time={timeLatest}
          onMerge={handleP2PMerge}
          isMerging={isMerging}
        />
      </IncidentWrapper>
    )
  }

  return (
    <Modal>
      <Container>
        <h4>Enter Incident</h4>
        <InputLabel>Incident ID</InputLabel>
        <InputWrapper>
          <TextInput
            onChange={val => dispatch({ type: actions.SET_INCIDENT_ID, payload: val })}
            placeholder="Incident ID..."
            defaultValue=""
            value={incidentId || ""}
            onBlur={searchIncident}
          />
          {incidentId && (
            <SearchButton onClick={searchIncident}>
              <i className='fas fa-search'></i>
            </SearchButton>
          )}
        </InputWrapper>
        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {content}
        {!!onClose && (
          <CloseButton onClick={onClose}>
            <i className='fas fa-times' />
          </CloseButton>
        )}
      </Container>
    </Modal>
  )
}

P2PMergeIncidentModal.propTypes = {
  currentStreamId: string.isRequired,
  currentIncidentId: string.isRequired,
  onClose: func.isRequired,
  onSuccess: func,
}

export default P2PMergeIncidentModal
