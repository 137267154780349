import classNames from "classnames";
import styled from "styled-components";

const InputWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__input-field__input-wrapper"
    )
  };
})`
  height: auto;
  width: 100%;
`;

InputWrapper.defaultProps = {};

export default InputWrapper;
