import classNames from "classnames";
import styled from "styled-components";

import { Input as GenericInput } from "@guardian/UI/Form";

const Input = styled(GenericInput).attrs(
  props => {
    const {
      className,
      isHidden
    } = props;

    return {
      className: classNames(className, "dropdown__input", {
        "dropdown__input--is-hidden": isHidden
      })
    };
  }
)`
  &.dropdown__input--is-hidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

Input.defaultProps = {};

export default Input;
