import React, { useMemo } from "react";
import { get } from "lodash";

import {
  useSOSDashboardContext
} from "@guardian/Components/ModSOS/store/dashboard/DashboardContext";

import styles from "./SOSLocationBasedNumbers.module.css";
import { IProps } from "./SOSLocationBasedNumbers.types";
import SOSLocationBasedNumber from "./components/SOSLocationBasedNumber";

const getGeocodedNumbers = (ownerData: any) => {
  const numbers = get(ownerData, "data.geocodedNumbers", []).map((num: any) => {
    return {
      title: num.name,
      phoneNumber: num.number
    }
  });

  return numbers;
};

const SOSLocationBasedNumbers = (props: IProps) => {
  const { setPhoneNumber } = props;

  const { state: { ownerData } } = useSOSDashboardContext();

  const geocodedNumbers = useMemo(() => {
    return getGeocodedNumbers(ownerData);
  }, [ownerData]);

  if (geocodedNumbers.length === 0) {
    // TODO: An empty state would be nice.
    return null;
  }

  return (
    <div className={styles.container}>
      <h5 className={styles.header}>Location Based Numbers</h5>
      {
        geocodedNumbers.map((keyNum: any, i: number) => {
          return (
            <SOSLocationBasedNumber
              key={`${keyNum.phoneNumber}__number__${i}`}
              keyNum={keyNum}
              setPhoneNumber={setPhoneNumber}
            />
          );
        })
      }
    </div>
  );
};

export default SOSLocationBasedNumbers;
