import classNames from "classnames";
import styled from "styled-components";

const VirtualScroll = styled.div.attrs(
  props => {
    const {
      className,
      reverse
    } = props;

    return {
      className: classNames(
        className,
        "virtual-scroll", {
          "virtual-scroll--reverse": reverse
        }
      )
    }
  }
)`
  height: 100%;
  width: 100%;
  overflow: auto;

  &.virtual-scroll--reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    min-height: 0;
  }
`;

export default VirtualScroll;
