import React from "react";

import { Box } from "@guardian/UI/Box";
import { Anchor, Button, ButtonIcon, ButtonGroup } from "@guardian/UI/Button";
import { Icon } from "@guardian/UI/Icon";
import { Spinner } from "@guardian/UI/Loader";
import {
  Body,
  Close,
  Footer,
  Header,
  Modal
} from "@guardian/UI/Modal";
import { MediumParagraph } from "@guardian/UI/Paragraph";
import { LargeText } from "@guardian/UI/Typography";

const GeoNumberModal = props => {
  const {
    number,
    locked,
    loading,
    onClose,
    onConfirm,
    onExit,
    open
  } = props;

  const { name = "" } = number;

  return (
    <Modal
      backdropProps={
        {
          closeDisabled: locked
        }
      }
      onClose={onClose}
      onExit={onExit}
      open={open}
    >
      <Header>Call { name } number?</Header>
      <Body>
        <LargeText>
          This will automatically begin the call.
        </LargeText>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button disabled={loading} onClick={onClose} tightenY>
            Cancel
          </Button>
          <Button disabled={loading} isPrimary onClick={onConfirm} tightenY>
            Yes, Start Call
            <ButtonIcon.End>
              {
                loading ? (
                  <Spinner/>
                ) : (
                  <Icon.Check/>
                )
              }
            </ButtonIcon.End>
          </Button>
        </ButtonGroup>
      </Footer>
      <Close/>
    </Modal>
  );
};

export default GeoNumberModal;
