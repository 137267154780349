import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { sortBy } from "lodash"
import { v4 as uuid } from "uuid"

import {
  latestRevWithText,
  initialRevId,
  latestRev,
  getIncidentAuthor,
} from "@guardian/Utils/incident"
import consts from "@guardian/Constants"
import { useGlobalState, eventHub } from "@guardian/State"

import { VideoStreams } from "@guardian/API/Optimus"
import { ContentService } from "@guardian/Services/Content"
import { IncidentService } from "@guardian/Services/Incident"
import { push } from "@guardian/Utils/history"
import combokeys from "@guardian/Utils/hotkeys"

import { getContentId } from "@guardian/Components/ModIGL/ModIGL.helper"
import ActionPane from "@guardian/Components/LiveVideoTab/ActionPane/ActionPane"

import QueuePane from "./QueuePane/QueuePane"
import IncidentContentPane from "./IncidentContentPane/IncidentContentPane"
import IncidentPane from "./IncidentPane/IncidentPane"
import { getContentIncidentId } from "./IncidentContentMod.helpers"
import style from "./IncidentContentMod.module.css"

const IncidentContentMod = () => {
  const location = useLocation()
  const { id: active } = useParams()

  const serviceAreaConfig = useGlobalState(state => state.global.serviceAreaConfig.serviceAreas)

  const [content, setContent] = useState([])
  const [incidents, setIncidents] = useState({})
  const [selectedServiceAreas, setSelectedServiceAreas] = useState([])

  const contentView = useMemo(() => {
    // TODO: Multiple checks
    const filterFn = c => {
      const incidentId = getContentIncidentId(c)
      return (
        incidents[incidentId] &&
        (selectedServiceAreas.length === 0 ||
          selectedServiceAreas.some(
            serviceArea =>
              incidents[incidentId].location.cityCode.toLowerCase() ===
              serviceArea.code.toLowerCase(),
          ))
      )
    }

    return sortBy(content.filter(filterFn), [
      c =>
        c.videoStream && !c.videoStream.broadcastDone && !c.videoStream.hlsDone
          ? -1
          : 1,
      c => -incidents[getContentIncidentId(c)].level,
      c => -new Date(latestRev(incidents[getContentIncidentId(c)]).createdAt),
      c =>
        -new Date(
          c.type === "video_stream"
            ? c.videoStream.createdAt
            : c.image.createdAt,
        ),
    ])
  }, [content, incidents, selectedServiceAreas])

  const refreshContent = useCallback(async () => {
    try {
      const [data, activeStream] = await Promise.all([
        ContentService.getUnacknowledgedContent(),
        active && location.pathname.includes("streams") ? VideoStreams.getStream(active) : null,
      ])

      if (activeStream) {
        const foundActiveStreamIdx = data.findIndex(
          c => getContentId(c) === activeStream.videoStream?.id,
        )
        if (foundActiveStreamIdx !== -1) {
          data[foundActiveStreamIdx] = activeStream
        } else {
          data.push(activeStream)
        }
      }
      setContent(data)
    } catch (e) {
      console.error("Error fetching unacknowledged content: ", e)
    }
  }, [active, location.pathname])

  const nextContent = useCallback(() => {
    refreshContent()
    const index = contentView.findIndex(c => getContentId(c) === active)
    if (index !== -1) {
      const next = contentView[Math.min(contentView.length - 1, index + 1)]
      if (next) {
        const typeText = next.type === "video_stream" ? "streams" : "images"
        push(`/content-moderation/${typeText}/${getContentId(next)}`)
      }
    }
  }, [contentView, active])

  const prevContent = useCallback(() => {
    const index = contentView.findIndex(c => getContentId(c) === active)
    if (index !== -1) {
      const prev = contentView[Math.max(0, index - 1)]
      if (prev) {
        const typeText = prev.type === "video_stream" ? "streams" : "images"

        push(`/content-moderation/${typeText}/${getContentId(prev)}`)
      }
    }
  }, [contentView, active])

  useEffect(() => {
    combokeys.bind("up", e => {
      if (!e.target.className.match("Modal")) {
        prevContent()
      }
    })
    combokeys.bind("down", e => {
      if (!e.target.className.match("Modal")) {
        nextContent()
      }
    })

    return () => {
      combokeys.unbind("up")
      combokeys.unbind("down")
    }
  }, [nextContent, prevContent])

  useEffect(() => {
    refreshContent()
    const intervalId = setInterval(refreshContent, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [refreshContent])

  useEffect(() => {
    const fetchIncidents = async () => {
      try {
        const ids = content.map(content => getContentIncidentId(content))
        const fetchedIncidents = await IncidentService.getIncidents({ incidentIds: ids })
        const mapping = fetchedIncidents.reduce((acc, incident) => {
          if (!incident.psnId) {
            acc[incident.id] = incident
          }
          return acc
        }, {})
        setIncidents(mapping)
      } catch (error) {
        // Handle errors
        console.error(error)
      }
    }

    fetchIncidents()
  }, [content])

  const activeContent = content.find(c => getContentId(c) === active)
  const incident =
    activeContent && incidents[getContentIncidentId(activeContent)]
  const latestWithText = incident && latestRevWithText(incident)

  const incidentAuthor = getIncidentAuthor(incident)

  let rightPane = null
  if (activeContent?.type === 'video_stream' && incident?.unverifiedIGLIncident === true) {
    rightPane = (
      <ActionPane
        stream={activeContent}
        incident={incident}
        activeStreamId={getContentId(activeContent)}
        refreshVideoStreams={nextContent}
      />
    )
  } else if (incident) {
    rightPane = (
      <IncidentPane
        title={incident.title}
        level={incident.level}
        address={incident.location.address}
        lastUpdateText={latestWithText && latestWithText.text}
        id={incident.id}
        streamersCount={incident.liveStreamers?.length || 0}
        author={incidentAuthor}
        activeContent={activeContent}
        activeContentId={getContentId(activeContent)}
        key={incident.id}
        onResolve={nextContent}
        selectedThumb={
          incident.verticalThumbnail ||
          incident.featuredStreamImage ||
          incident.preferredStreamImage
        }
        onSubmit={async update => {
          const spec = {
            id: uuid(),
            text: update,
            occurredAt: new Date(),
            visibility: consts.Visibility.Public,
            updateSequence: 0,
            incidentId: incident.id,
            rootRevId: initialRevId(incident),
            source: consts.Source.Radio,
          }

          await eventHub.emit("createUpdateRev", spec)
        }}
        resolved={
          activeContent.type === "image"
            ? activeContent.image.resolved
            : activeContent.videoStream.resolved
        }
        featuredStreamImageCropY={incident.featuredStreamImageCropY}
        activeContentType={activeContent.type}
      />
    )
  }

  return (
    <div className={style.wrapper}>
      <QueuePane
        serviceAreaConfig={serviceAreaConfig}
        content={contentView}
        incidents={incidents}
        activeContentId={active}
        selectedServiceAreas={selectedServiceAreas}
        onServiceAreaSelect={value => setSelectedServiceAreas(value)}
      />
      {activeContent && incident && (
        <IncidentContentPane
          activeContent={activeContent}
          onResolve={nextContent}
          incidentId={incident.id}
          incidentThumb={
            incident.featuredStreamImage || incident.preferredStreamImage
          }
          incidentStreamersCount={incident.liveStreamers?.length || 0}
          incidentAuthor={incidentAuthor}
          unverifiedIGLIncident={incident?.unverifiedIGLIncident}
        />
      )}
      {rightPane}
    </div>
  )
}

export default IncidentContentMod
