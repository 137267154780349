import { useEffect, useRef } from "react"

export default function useSmoothScrollToActive(
  activeContentId: string | null,
  topOffset: number = 0,
) {
  const topOffsetRef = useRef(topOffset)

  const listRef = useRef<HTMLDivElement | null>(null)
  const activeContentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    topOffsetRef.current = topOffset
  }, [topOffset])

  useEffect(() => {
    if (!activeContentId || !listRef.current || !activeContentRef.current) {
      return
    }

    const listBounding = listRef.current.getBoundingClientRect()
    const streamBounding = activeContentRef.current.getBoundingClientRect()

    const topOffsetValue = topOffsetRef.current || 0
    const offsetTop = activeContentRef.current.offsetTop - topOffsetValue

    if (offsetTop < listRef.current.scrollTop) {
      listRef.current.scrollTo({
        top: Math.max(0, offsetTop),
        left: 0,
        behavior: "smooth",
      })
    } else if (
      activeContentRef.current.offsetTop + streamBounding.height >
      listRef.current.scrollTop + listBounding.height
    ) {
      listRef.current.scrollTo({
        top:
          activeContentRef.current.offsetTop +
          streamBounding.height -
          listBounding.height,
        left: 0,
        behavior: "smooth",
      })
    }
  }, [activeContentId])

  return { listRef, activeContentRef }
}
