import { OptimusAPI } from "@guardian/API/Optimus";

interface ProtectSessionsSendNoteData {
  sessionId: string
  text: string
};

const ProtectSessions = {

  getNotes: (sessionId: string, options: any = {}) => {
    return OptimusAPI.get(
      `/v1/protect/session/${ sessionId }/notes`,
      options
    );
  },

  sendNote: (data: ProtectSessionsSendNoteData, options: any = {}) => {
    const { sessionId, ...restData } = data;
    return OptimusAPI.post(
      `/v1/protect/session/${ sessionId }/notes`,
      restData,
      options
    );
  }

};

export default ProtectSessions;
