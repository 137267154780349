import { css, keyframes } from "styled-components";

const marginSpace = css`var(--space-x-small)`;
const translateSpace = css`var(--space-xx-small)`;

const styles = {
  bottom: {
    margin:
      css`margin-top: ${ marginSpace };`,
    transform:
      css`transform: translate3d(-50%, calc(-1 * ${ translateSpace }), 0);`,
    transformDefault:
      css`transform: translate3d(-50%, 0%, 0);`,
  },
  left: {
    margin:
      css`margin-right: ${ marginSpace };`,
    transform:
      css`transform: translate3d(calc(-100% + ${ translateSpace }), -50%,  0);`,
    transformDefault:
      css`transform: translate3d(-100%, -50%,  0);`,
  },
  right: {
    margin:
      css`margin-left: ${ marginSpace };`,
    transform:
      css`transform: translate3d(calc(-1 * ${ translateSpace }), -50%,  0);`,
    transformDefault:
      css`transform: translate3d(0%, -50%, 0);`,
  },
  top: {
    margin:
      css`margin-bottom: ${ marginSpace };`,
    transform:
      css`transform: translate3d(-50%, calc(-100% + ${ translateSpace }), 0);`,
    transformDefault:
      css`transform: translate3d(-50%, -100%, 0);`,
  }
};

export const positionMargin = (position = "top") => {
  return styles[position].margin;
};

export const positionTransform = (position = "top") => {
  return styles[position].transform;
};

export const positionTransformDefault = (position = "top") => {
  return styles[position].transformDefault;
};
