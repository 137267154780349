// NOTE: Needed a quick and configurable sorting function so copied this from
// here, https://stackoverflow.com/a/26759127. Not beholden to it so if anyone
// feels like replacing, please do so. Works for our needs though.
const sortBy = (function () {
  var toString = Object.prototype.toString,
      // default parser function
      parse = function (x) { return x; },
      // gets the item to be sorted
      getItem = function (x) {
        var isObject = x != null && typeof x === "object";
        var isProp = isObject && this.prop in x;
        return this.parser(isProp ? x[this.prop] : x);
      };

  /**
   * Sorts an array of elements.
   *
   * @param {Array} array: the collection to sort
   * @param {Object} cfg: the configuration options
   * @property {String}   cfg.prop: property name (if it is an Array of objects)
   * @property {Boolean}  cfg.desc: determines whether the sort is descending
   * @property {Function} cfg.parser: function to parse the items to expected type
   * @return {Array}
   */
  return function sortby (array, cfg) {
    if (!(array instanceof Array && array.length)) return [];
    if (toString.call(cfg) !== "[object Object]") cfg = {};
    if (typeof cfg.parser !== "function") cfg.parser = parse;
    cfg.desc = !!cfg.desc ? -1 : 1;
    return array.sort(function (a, b) {
      a = getItem.call(cfg, a);
      b = getItem.call(cfg, b);
      return cfg.desc * (a < b ? -1 : +(a > b));
    });
  };
}());

const sortAssignedAgentChatSessions = (sessions) => {
  const sortedSessions = sortBy(sessions, {
    prop: "lastMessageAt",
    desc: true,
    parser: (timestamp) => {
      return Date.parse(timestamp);
    }
  });

  return sortedSessions;
};

export default sortAssignedAgentChatSessions;
