import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { v4 as uuid } from "uuid";

import { AgentChat } from "@guardian/Lib/QueryClient/hooks";
import { Button } from "@guardian/UI/Button";
import {
  FauxInput,
  Field
} from "@guardian/UI/Form";

import MacroButton from "./components/MacroButton";
import Input from "./views/Input";
import InputActionsWrapper from "./views/InputActionsWrapper";
import InputWrapper from "./views/InputWrapper";

const InputField = props => {
  const { groupId, isResolved } = props;

  const performSubmit = AgentChat.useSendGroupMessage();

  const [message, setMessage] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHighlightTrigger, setInputHighlightTrigger] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(null);

  const abortControllerRef = useRef(null);
  const groupIdRef = useRef(groupId);
  const inputFieldRef = useRef(null);
  const inputWrapperRef = useRef(null);

  const adjustInputHeight = useCallback(() => {
    const inputField = inputFieldRef.current;

    if (inputField) {
      // The minimum scrollHeight of a textarea is always going to be the
      // height. To get an accurate scrollHeight, set the height to 0 first.
      inputField.style.height = "0";
      inputField.style.height = `${inputField.scrollHeight}px`;
    }
  }, []);

  const setInputFieldRef = useCallback((node) => {
    inputFieldRef.current = node;
    adjustInputHeight();
  });

  useLayoutEffect(() => {
    adjustInputHeight();
  }, [message]);

  useLayoutEffect(() => {
    if (inputHighlightTrigger == null) {
      return;
    }

    const inputField = inputFieldRef.current;

    if (inputField) {
      inputField.select();
      inputField.scrollTop = inputField.scrollHeight;
    }
  }, [inputHighlightTrigger]);

  useEffect(() => {
    const controller = new AbortController();
    abortControllerRef.current = controller;

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const updateSubmitDisabled = () => {
      const disableSubmit = message.trim().length == 0;

      setSubmitDisabled(disableSubmit);
    };

    updateSubmitDisabled();
  }, [message]);

  useEffect(() => {
    if (submitTrigger == null) {
      return;
    };

    const data = {
      id: uuid(),
      groupId: groupIdRef.current,
      body: {
        text: submitTrigger.message
      }
    };

    const options = {
      signal: abortControllerRef.current?.signal
    };

    performSubmit.mutate({ data, options });
  }, [submitTrigger]);

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleInputChanged = ({ target: { value: text } }) => {
    setMessage(text);
  };

  const handleInputFocused = () => {
    setInputFocused(true);
  };

  const handleInputKeyDown = (event) => {
    // If enter key was input without using shift+enter, submit.
    if (
      (event.code === "Enter" || event.code === "NumpadEnter") &&
      !event.shiftKey
    ) {
      event.preventDefault();
      event.stopPropagation();

      handleSubmitMessage();
    }
  };

  const handleMacroSelected = (macro) => {
    if (isResolved) {
      return;
    }
    setMessage(macro);
    setInputHighlightTrigger(+new Date());
  };

  const handleSubmitMessage = () => {
    if (!submitDisabled && !isResolved) {
      const formattedMessage = message.trim();

      setSubmitTrigger({
        message: formattedMessage,
        trigger: +new Date()
      });
      setMessage("");
    }
  };

  return (
    <Field>
      <FauxInput
        style={{ height: "auto" }}
        focused={inputFocused}
        disabled={isResolved}
      >
        <InputWrapper ref={inputWrapperRef}>
          <Input
            ref={inputFieldRef}
            onBlur={handleInputBlur}
            onChange={handleInputChanged}
            onFocus={handleInputFocused}
            onKeyDown={handleInputKeyDown}
            placeholder={"Send message..."}
            value={message}
            disabled={isResolved}
          />
          <InputActionsWrapper>
            <MacroButton onSelect={handleMacroSelected} disabled={isResolved} />
            <Button
              disabled={submitDisabled || isResolved}
              onClick={handleSubmitMessage}
              isPrimary
              tightenX
              tightenY
            >
              Send
            </Button>
          </InputActionsWrapper>
        </InputWrapper>
      </FauxInput>
    </Field>
  );
};

export default InputField;
