import React, { useCallback, useEffect, useRef, useState } from "react"
import classnames from "classnames"
import styles from "@guardian/Components/ModSOS/components/ModularPane/SOSModularPane.module.css"
import { SOSIncidentPane } from "@guardian/Components/ModSOS/components/ModularPane/SOSIncidentPane/SOSIncidentPane"
import { SOSNotesPane } from "@guardian/Components/ModSOS/components/ModularPane/SOSNotesPane/SOSNotesPane/SOSNotesPane"
import SOSDialOutPane from "@guardian/Components/ModSOS/components/ModularPane/SOSDialOutPane"
import config from "@guardian/Config"
import _ from "lodash"
import { getProtectSession } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import Initializer from "@guardian/Components/Common/Initializer"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import {
  IProps,
  ITab,
} from "@guardian/Components/ModSOS/components/ModularPane/types"
import { updateSessionResponse } from "@guardian/Components/ModSOS/store/dashboard/actions/dashboard"

const CALL_TAB = "dialOut"

const SOSModularPaneRaw: React.FunctionComponent<IProps> = ({
  activeSessionId,
  bounds,
  address,
  call,
  setCall,
}) => {
  const [activeTab, setActiveTab] = useState("notes")
  const prevCall = useRef()
  const { dispatch } = useSOSDashboardContext()

  const initializer = useCallback(() => {
    return getProtectSession(activeSessionId)
  }, [activeSessionId])

  const onGetProtectSessionSuccess = useCallback(({ data }: any) => {
    const newCall = _.get(data, "outgoingCall", {})
    if (!_.isEqual(prevCall.current, newCall)) {
      prevCall.current = newCall
      setCall(newCall)
    }
  }, [])

  const onCallChange = useCallback(() => {
    getProtectSession(activeSessionId)
      .toPromise()
      .then(async data => {
        onGetProtectSessionSuccess(data)

        dispatch(updateSessionResponse(data))
      })
  }, [activeSessionId, onGetProtectSessionSuccess])

  useEffect(() => {
    if (!call?.connectionId) {
      getProtectSession(activeSessionId)
        .toPromise()
        .then(data => {
          dispatch(updateSessionResponse(data))
        })
    }
  }, [call?.connectionId])

  const Tabs: Record<string, ITab> = {
    notes: {
      Component: SOSNotesPane,
      name: "Customer notes",
      extraProps: {
        activeSessionId,
      },
      prod: true,
    },
    dialOut: {
      Component: SOSDialOutPane,
      name: "Add a call",
      extraProps: { activeSessionId, call, onCallChange },
      prod: true,
    },
    createIncident: {
      Component: SOSIncidentPane,
      name: "Create incident",
      extraProps: {
        bounds,
        address,
      },
      prod: true,
    },
  }

  const tabs = _.map(Tabs, (v, k) => {
    if (config.production) {
      if (!v.prod) {
        return
      }
    }

    return (
      <div
        key={k}
        className={classnames(styles.tab, {
          [styles.active]: activeTab === k,
          [styles.activeCall]: CALL_TAB === k && call && call.connectionId,
        })}
        onClick={() => {
          setActiveTab(k)
        }}
      >
        {_.startCase(v.name)}
      </div>
    )
  })

  const components = _.map(Tabs, ({ Component, extraProps, prod }, k) => {
    if (config.production) {
      if (!prod) {
        return
      }
    }
    return (
      <div
        key={k}
        className={styles.wrapper}
        style={{ display: activeTab === k ? "" : "none" }}
      >
        <Component {...(extraProps as any)} />
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <Initializer
        initializer={initializer}
        onSuccess={onGetProtectSessionSuccess}
        interval={3000}
        ignoreLoading
      >
        <div className={styles.tabs}>{tabs}</div>
        {components}
      </Initializer>
    </div>
  )
}

export const SOSModularPane = React.memo(SOSModularPaneRaw)
