import classNames from "classnames";
import styled from "styled-components";

const Input = styled.textarea.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__chat-tab__input-area__input-field__input"
    )
  };
})`
  align-items: center;
  appearance: textfield;
  background: var(--colors-transparent);
  border: none;
  color: var(--colors-white);
  display: block;
  font-size: var(--font-sizes-medium);
  max-height: 195px;
  line-height: var(--line-heights-medium);
  outline: none;
  padding:
    var(--space-medium)
    var(--space-medium)
    // Add 40px to account for absolute positioning of actions.
    calc(var(--space-x-small) + 45px)
    var(--space-medium);
  resize: none;
  scroll-padding-right: var(--space-small);
  scroll-padding-bottom: calc(53px);
  width: 100%;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--colors-grey-030);
    font-family: var(--fonts-sans-serif);
  }
  &::-ms-input-placeholder {
    color: var(--colors-grey-030);
    font-family: var(--fonts-sans-serif);
  }
`;

Input.defaultProps = {};

export default Input;
