import { createContext, useContext } from "react";

export const DropdownContext = createContext(undefined);

// Retrieve Dropdown component context.
const useDropdownContext = () => {
  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("This component must be rendered within a `Dropdown` component.");
  }

  return dropdownContext;
};

export default useDropdownContext;
