import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

export const TYPES = {
  ERROR: "error",
  GENERIC: "generic",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning"
};

const Alert = styled.div.attrs(
  props => {
    const {
      type
    } = props;

    return {
      className: classNames("alert", {
          "alert--error":
            type == TYPES.ERROR,
          "alert--generic":
            type == TYPES.GENERIC,
          "alert--info":
            type == TYPES.INFO,
          "alert--success":
            type == TYPES.SUCCESS,
          "alert--warning":
            type == TYPES.WARNING,
        })
    };
  }
)`
  background: var(--colors-grey-085);
  border-radius: var(--radii-medium);
  color: var(--colors-white);
  display: block;
  display: box;
  display: -webkit-box;
  font-size: var(--font-sizes-x-small);
  line-height: var(--line-heights-x-small);
  position: relative;

  &.alert--error {
    background: var(--colors-red-050);
  }

  &.alert--info {
    background: var(--colors-blue-050);
  }

  &.alert--success {
    background: var(--colors-green-050);
  }

  &.alert--warning {
    background: var(--colors-yellow-050);
  }
`;

Alert.propTypes = {
  type: PropTypes.oneOf(
    [
      TYPES.ERROR,
      TYPES.GENERIC,
      TYPES.INFO,
      TYPES.SUCCESS,
      TYPES.WARNING
    ]
  )
};

Alert.defaultProps = {
  type: TYPES.GENERIC
};

export default Alert;
