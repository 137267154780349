import { OutlinedInput } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { bool, func, object } from "prop-types"
import { useEffect, useMemo, useState } from "react"

import { useGlobalState } from "@guardian/State"

import { IncidentService } from "@guardian/Services/Incident"
import { objPick } from "@guardian/Utils/obj-pick"

import IncidentNotif from "@guardian/Components/ModIncident/IncidentNotif"
import { Checkbox } from "@guardian/Components/ui/Legacy/Checkbox"

import { ActionTypes } from "../ActionPane.reducer"
import CustomNotifContainer from "./CustomNotifContainer"
import CustomNotifSwitches from "./CustomNotifSwitches"
import DistributionType from "./DistributionType"
import { inputStyles } from "./DistributionType.styles"
import style from "./NotifArea.module.css"
import NotificationCategory from "./NotificationCategory"
import NotificationTypeSentiment from "./NotificationTypeSentiment"

const StyledInput = withStyles(inputStyles)(OutlinedInput)

const NotifArea = ({ state, dispatch, onAir, stream, incident }) => {
  const { readOnly, tags, sentimentTypeDefaults } = useGlobalState(state => {
    const selectedGlobal = objPick(state.global, ["readOnly", "tags"])
    const {
      customNotifConfig: { sentimentTypeDefaults },
    } = state.global
    return { ...selectedGlobal, sentimentTypeDefaults }
  })

  const [notifs, setNotifs] = useState([])

  const tag = useMemo(() => {
    return tags.find(t => t.display_name === state.incidentCategory)
  }, [state.incidentCategory, tags])

  useEffect(() => {
    const getNotifs = async () => {
      if (incident && incident.id && stream && stream.verifiedLive) {
        const notifications = await IncidentService.getIncidentNotifications(
          incident.id,
        )
        const filtered = notifications.filter(
          notif => notif.stream_id === stream.id,
        )
        setNotifs(filtered)
      }
    }
    getNotifs()
  }, [incident, stream])
  return (
    <>
      <NotificationCategory
        category={state.notificationCategory}
        verifiedLive={stream.verifiedLive}
        onAir={onAir}
        setCategory={type =>
          dispatch({
            type: ActionTypes.SetNotificationCategory,
            payload: { type },
          })
        }
        disabled={readOnly}
      />
      {state.notificationCategory === "custom" && (
        <StyledInput
          label='Radius (miles)'
          style={{ width: "100%", paddingLeft: "0px", marginTop: "16px" }}
          notched
          value={state.radiusOverride}
          onChange={e =>
            dispatch({
              type: ActionTypes.setRadiusOverride,
              payload: { radius: e.target.value },
            })
          }
          disabled={readOnly}
        />
      )}
      {onAir && (
        <>
          <DistributionType
            distributionType={state.distributionType}
            distributionValue={state.distributionValue}
            setDistributionType={val =>
              dispatch({
                type: ActionTypes.SetDistributionType,
                payload: { type: val },
              })
            }
            setDistributionValue={val =>
              dispatch({
                type: ActionTypes.SetDistributionValue,
                payload: { value: val },
              })
            }
            disabled={readOnly}
          />
          <div className={style.sendToInactive}>
            <Checkbox
              active={state.sendToInactive}
              handleClick={() =>
                dispatch({
                  type: ActionTypes.SetSendToInactive,
                  payload: { send: !state.sendToInactive },
                })
              }
              disabled={readOnly}
            />
            <div className={style.sendToInactiveText}>
              Include Inactive Users
            </div>
          </div>
        </>
      )}
      <NotificationTypeSentiment
        type={state.notifType}
        setType={type =>
          dispatch({
            type: ActionTypes.SetNotifType,
            payload: { type, defaults: sentimentTypeDefaults },
          })
        }
        sentiment={state.notifSentiment}
        setSentiment={sentiment =>
          dispatch({
            type: ActionTypes.SetNotifSentiment,
            payload: { sentiment },
          })
        }
        disabled={readOnly}
      />
      <CustomNotifContainer
        tagName={tag?.name || "N/A"}
        notifTitle={state.notifTitle}
        notifMessage={state.notifMessage}
        notifSoundOn={state.notifSoundOn}
        showIncidentMetadata={state.showIncidentMetadata}
        dispatch={dispatch}
        disabled={readOnly}
      />
      <CustomNotifSwitches
        notifSoundOn={state.notifSoundOn}
        renotify={state.renotify}
        showIncidentMetadata={state.showIncidentMetadata}
        sendNotifInternalOnly={state.sendNotifInternalOnly}
        notifTurnedOn={state.notifTurnedOn}
        onAir={onAir}
        sound={state.notifSound}
        dispatch={dispatch}
        disabled={readOnly}
      />
      {notifs.length > 0 && (
        <div className={style.notifHistory}>
          <hr className={style.notifHistoySeparator} />
          <div className={style.sentText}> Sent Notifications</div>
          {notifs.map(notif => (
            <>
              <IncidentNotif
                key={notif.id}
                notif={notif}
                expandNotifs={true}
                inLiveTab
              />
              <hr className={style.notifSeparator} />
            </>
          ))}
        </div>
      )}
    </>
  )
}

NotifArea.propTypes = {
  state: object,
  dispatch: func,
  onAir: bool,
  incident: object,
  stream: object,
}

export default NotifArea
