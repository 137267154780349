import type { Clip } from "@guardian/Types/Clip"

import type { AuditToolEntry } from "@guardian/Components/AuditTool/types"

import { AuditToolActionType } from "./types"

export const Actions = {
  setIsFetchingClips: (isFetchingClips: boolean) => ({
    type: AuditToolActionType.SET_FETCHING_CLIPS as const,
    data: isFetchingClips,
  }),

  setIsSyncingEntries: (isSyncingEntries: boolean) => ({
    type: AuditToolActionType.SET_SYNCING_ENTRIES as const,
    data: isSyncingEntries,
  }),

  setEntries: (entries: AuditToolEntry[]) => ({
    type: AuditToolActionType.SET_ENTRIES as const,
    data: entries,
  }),

  setClips: (clips: Clip[]) => ({
    type: AuditToolActionType.SET_CLIPS as const,
    data: Object.fromEntries(clips.map(clip => [clip.id, clip])),
  }),
}

export type AuditToolAction = ReturnType<(typeof Actions)[keyof typeof Actions]>
