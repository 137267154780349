import classNames from "classnames";
import styled from "styled-components";

const Fallback = styled.div.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "avatar__fallback"
      )
    }
  }
)`
  align-items: center;
  border-radius: var(--radii-circle);
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export default Fallback;
