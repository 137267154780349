import { createContext, useContext } from "react";

export const TabsContext = createContext(undefined);

const useTabsContext = () => {
  const tabsContext = useContext(TabsContext);

  if (!tabsContext) {
    throw new Error("This component must be rendered within a `Tabs` component.");
  }

  return tabsContext;
};

export default useTabsContext;
