import classNames from "classnames";
import styled from "styled-components";

const Header = styled.div.attrs(
  props => {
    const { className } = props;
    return {
      className: classNames(className, "alert__flash-message__header")
    };
  }
)`
  align-items: center;
  display: flex;
  font-size: var(--font-sizes-x-small);
  font-weight: var(--font-weights-bold);
  line-height: var(--line-heights-x-small);
  padding:
    var(--space-x-small)
    calc(var(--line-heights-medium) + (var(--space-x-small) * 2.0))
    var(--space-x-small)
    var(--space-small);
`;

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
