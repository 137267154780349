import React from "react";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    },
  },
});

const QueryClientSystem = (props) => {
  const {
    children
  } = props;

  return (
    <QueryClientProvider client={queryClient}>
      { children }
    </QueryClientProvider>
  );
};

export default QueryClientSystem;
