import React, { useEffect } from "react";

import { AgentChat } from "@guardian/Lib/QueryClient/hooks";
import { LoggingService } from "@guardian/Services/Logging";

import { MediumText } from "@guardian/UI/Typography";

import PhoneNumber from "./components/PhoneNumber";
import UserName from "./components/UserName";
import SubInfo from "./views/SubInfo";

const UserInfo = props => {
  const {
    session: {
      id: sessionId,
      members,
      userId
    }
  } = props;

  // TODO: This check should not occur here. This should fail much earlier in
  // the agent chat flow, likely at the moment we set members on each session
  // as they come in.
  const user = members && members[userId] ? members[userId] : {
    fullName: '[unknown member for userId ' + userId + ']'
  };

  // Fetch basic user info (e.g.; phone, email).
  const {
    data: userInfo,
    error: userInfoFetchError,
    isInitialLoading: userInfoIsLoadingInitial,
  } = AgentChat.useGetAssignedChatSessionUserInfo(
    sessionId
  );

  useEffect(() => {
    if (userInfoFetchError == null) {
      return;
    }

    if (userInfoFetchError.name === 'AbortError') {
      return;
    }

    LoggingService.logError(
      "Error fetching user info.",
      {
        domain: "AgentChat",
        method: "UserInfo",
        flash: true,
        trackedData: {
          userId: userId,
          members: members,
          sessionId: sessionId,
          error: userInfoFetchError
        }
      }
    );
  }, [userInfoFetchError]);

  const renderPhone = () => {
    if (userInfoIsLoadingInitial) {
      return "--";
    } else if (info.phone) {
      return (
        <PhoneNumber number={info.phone}/>
      );
    } else {
      return (
        <i>Phone Number Unknown</i>
      );
    }
  };

  // Set default values on userInfo.
  const info = {
    address: (<i>Address Unknown</i>),
    email: (<i>Email Unknown</i>),
    phone: undefined,
    ...userInfo
  };

  return (
    <React.Fragment>
      <UserName id={userId} fullName={user.fullName}/>
      <SubInfo>
        <MediumText>
          { userInfoIsLoadingInitial ? "--" : info.address }
        </MediumText>
        <MediumText>
          { userInfoIsLoadingInitial ? "--" : info.email }
        </MediumText>
        <MediumText>
          { renderPhone() }
        </MediumText>
      </SubInfo>
    </React.Fragment>
  );
};

export default UserInfo;
