import React, { useEffect } from "react"
import Modal from "@guardian/Components/Modals/Modal"
import { func, string } from "prop-types"
import style from "./BlockModal.module.css"
import Dropdown from "@guardian/Components/Form/Dropdown"
import { useGlobalState } from "@guardian/State"
import combokeys from "@guardian/Utils/hotkeys"

const BlockModal = ({ onBlock, onCancel, contentType }) => {
  const rejectionChoices = useGlobalState(state => state.moderation.rejectionChoices)

  useEffect(() => {
    combokeys.bind("esc", onCancel)

    return () => {
      combokeys.unbind("esc")
    }
  }, [onCancel])

  return (
    <>
      <div className={style.overlay} />
      <Modal>
        <div className={style.modal}>
          <div className={style.circle} />
          <div>
            <h4>
              Are you sure you want to block this{" "}
              {contentType === "video_stream" ? "video" : "image"}?
            </h4>
            <Dropdown
              className={style.dropdown}
              placeholder='Select Blocking Reason'
              options={Object.keys(rejectionChoices)}
              onChange={r => onBlock(rejectionChoices[r])}
              autoOpen
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

BlockModal.propTypes = {
  onBlock: func,
  onCancel: func,
  contentType: string,
}

export default BlockModal
