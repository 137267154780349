import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const MenuRow = styled.li.attrs(
  props => {
    const {
      className,
      contentsSelectable
    } = props;

    return {
      className: classNames(className, "dropdown__menu__row", {
        "dropdown__menu__row--contents-selectable": contentsSelectable
      })
    };
  }
)`
  align-items: center;
  color: var(--colors-white);
  display: flex;
  flex-direction: row;
  font-size: var(--font-sizes-small);
  line-height: var(--line-heights-medium);
  padding: var(--space-small) var(--space-medium);
  text-decoration: none;
  user-select: none;
  word-wrap: break-word;

  z-index: 0;

  &.dropdown__menu__row--contents-selectable {
    .dropdown__menu__row__child--toggle-with-row-contents-selectable {
      opacity: 0;
    }

    &:focus,
    &:hover {
      background-color: var(--colors-grey-085);

      .dropdown__menu__row__child--toggle-with-row-contents-selectable {
        opacity: 1;
      }
    }
  }
`;

MenuRow.propTypes = {
  contentsSelectable: PropTypes.bool
};

MenuRow.defaultProps = {
  contentsSelectable: false
};

export default MenuRow;
