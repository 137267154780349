import { OptimusAPI } from "@guardian/API/Optimus";

declare interface Geography {
  type: string
  code: string
  name: string
}

declare interface RedisDedupeCfg {
  key: string
  count: number
  ttl: number
}

export interface NotificationsSendCustomNotificationData {
  incidentId: string
  notificationId: string
  type: string
  message: string
  sound: boolean
  title?: string
  cohort?: string
  renotify: boolean
  hideIncidentMetadata: boolean
  radius?: number
  triggerType: string
  tag?: string
  geography?: Geography
  onCreation: boolean
  streamId?: string
  thumbnail?: string
  transcriberId?: string
  dryRun?: boolean
  news?: boolean
  inventoryType?: string
  cohortBegin?: number
  cohortEnd?: number
  totalCohort?: number
  citycode?: string
  expireAt?: number
  notifType?: string
  notifSentiment?: string
  notifSound?: string
  redis_dedupe_cfg?: RedisDedupeCfg
  overrideFilterUsers: boolean
}

const Notifications = {
  sendCustomNotification: (
    data: NotificationsSendCustomNotificationData,
    options: any = {}
  ) => {
    return OptimusAPI.post(`/custom_notification`, data, options)
  }
};

export default Notifications;
