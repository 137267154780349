import React from "react"
import _ from "lodash"

import { Text } from "@guardian/UI/Legacy/Text"
import { secondsToMin } from "@guardian/Utils/util"

import { Message } from "./types"
import style from "./ModBroadcasterCommunication.module.css"

const ModMessage = ({ text, sender, secondsSinceStart }: Message) => {
  const color = sender === "system" ? "#4DA6FF !important" : "white !important"
  return (
    <div className={style.message}>
      <Text size='small' color={color}>
        {text}
      </Text>
      <Text size='small' color={color} style={{ float: "right" }}>
        {_.padStart(secondsToMin(secondsSinceStart), 5, "0")}
      </Text>
    </div>
  )
}

export default ModMessage
