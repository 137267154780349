import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { dialOutProtectSession, endProtectCall } from "@guardian/API/Optimus/resources/legacy/resources/Protect";
import { DialPad } from "@guardian/Components/DialPad";

import styles from "./SOSDialOutPane.module.css"
import { IProps } from "./SOSDialOutPane.types";
import SOSCallStatus from "./components/SOSCallStatus";
import SOSLocationBasedNumbers from "./components/SOSLocationBasedNumbers";

const SOSDialOutPane = (props: IProps) => {
  const {
    activeSessionId,
    call,
    onCallChange,
  } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [startCallTrigger, setStartCallTrigger] = useState<number | null>(null);
  const [endCallTrigger, setEndCallTrigger] = useState<number | null>(null);

  const activeSessionIdRef = useRef(activeSessionId);
  const phoneNumberRef = useRef(phoneNumber);

  // Update activeSessionIdRef without rerender on activeSessionId change.
  useEffect(() => {
    activeSessionIdRef.current = activeSessionId;
  }, [activeSessionId]);

  // Update phoneNumberRef without rerender on phoneNumber change.
  useEffect(() => {
    phoneNumberRef.current = phoneNumber;
  }, [phoneNumber]);

  // Because the current architecture for this set of component views requires
  // that we call callbacks to parent components in order to update the
  // `outgoingCall` state from the protect session itself, through the
  // `onCallChange` prop, which is called both when starting and ending calls,
  // for the time being we wait on changes to the `call` prop before proceeding
  // to flag our internal state as no longer loading.
  useEffect(() => {
    const { to="" } = call || {};

    // Update phone number within UI to reflect the format it has been
    // been converted to in the backend and within the protect session.
    setPhoneNumber(to);
    setLoading(false);
  }, [call]);

  useEffect(() => {
    if (startCallTrigger == null) {
      return;
    }

    const activeSessionId = activeSessionIdRef.current;
    const phoneNumber = phoneNumberRef.current;

    if (!activeSessionId || !phoneNumber) {
      return;
    }

    setError("");
    setLoading(true);

    dialOutProtectSession(activeSessionId, phoneNumber)
      .toPromise()
      .then(() => {
        onCallChange();
      })
      .catch(() => {
        setError("Failed to start call. Please try again.");
        setLoading(false);
      });
  }, [startCallTrigger]);

  useEffect(() => {
    if (endCallTrigger == null) {
      return;
    }

    const activeSessionId = activeSessionIdRef.current;

    if (!activeSessionId) {
      return;
    }

    setError("");
    setLoading(true);

    endProtectCall(activeSessionId)
      .toPromise()
      .then(() => {
        onCallChange();
      })
      .catch(() => {
        setError("Failed to end call. Please contact internal support.");
        setLoading(false);
      });
  }, [endCallTrigger]);

  const handlePhoneNumberChanged = ({ currentTarget: { value } }: any) => {
    setPhoneNumber(value);
  };

  const handlePhoneNumberKeyDown = ({ key, preventDefault }: any) => {
    switch (key) {
      case "Enter":
        preventDefault();
        startCall();
    }
  };

  const startCall = () => {
    setStartCallTrigger(+new Date());
  };

  const endCall = () => {
    setEndCallTrigger(+new Date());
  };

  return (
    <div className={styles.container}>
      <div>
        <h5 className={styles.label}>Phone Number</h5>
        <div className={styles.input}>
          <input
            type="text"
            disabled={loading}
            onChange={handlePhoneNumberChanged}
            onKeyDown={handlePhoneNumberKeyDown}
            placeholder="(123) 456-7890"
            value={phoneNumber}
          />
          {
            call && call.connectionId ? (
              <button
                className={
                  classnames(styles.buttonDefault, styles.endCallButton)
                }
                disabled={loading}
                onClick={endCall}
              >
                End Call
              </button>
            ) : (
              <button
                className={
                  classnames(styles.buttonDefault, styles.callButton)
                }
                disabled={loading}
                onClick={startCall}
              >
                { loading ? "Dialing" : "Start Call" }
              </button>
            )
          }
        </div>
        {
          error && (
            <span className={styles.error}>
              { error }
            </span>
          )
        }
      </div>
      <SOSCallStatus
        activeSessionId={activeSessionId}
        call={call}
      />
      {
        call && call.connectionId ? (
          <DialPad/>
        ) : (
          <SOSLocationBasedNumbers setPhoneNumber={setPhoneNumber}/>
        )
      }
    </div>
  );
};

export default SOSDialOutPane;
