import React, { useEffect, useRef } from "react";

import { useVirtualizer } from "../hooks";
import StyledVirtualScroll from "../views/VirtualScroll";
import VirtualScrollList from "../views/VirtualScrollList";
import VirtualScrollListItem from "../views/VirtualScrollListItem";

const VirtualScroll = props => {
  const {
    // Note: Will add one to total indices when calling renderRow. renderRow
    // should account for this to show loader, a last message item, etc.
    canLoadMore,
    estimateSize,
    getRowKey,
    rowCount,
    renderRow,
    overscanSize,
    onScrolledToEnd,
    reverse,
    reverseOptions = {},
    scrollToIndex // hmmmm
  } = props;

  const rowVirtualizer = useVirtualizer({
    count: canLoadMore ? rowCount + 1 : rowCount,
    estimateSize: () => estimateSize,
    getScrollElement: () => parentRef.current,
    overscan: overscanSize,
    reverse: reverse
  });

  const parentRef = useRef(null);
  const rowVirtualizerRef = useRef(rowVirtualizer);

  useEffect(() => {
    rowVirtualizerRef.current = rowVirtualizer;
  }, [rowVirtualizer]);

  // Detect when the last item is coming into view. Note that this doesn't
  // exactly mean it is _in_ view, as whatever overscanSize setting has been
  // declared may cause items to be rendered above the fold. This is however
  // not a concern, because this function is basically used to call
  // onScrolledToEnd, which is useful mainly for loading more content as the
  // user scrolls _nearer_ the "end" (top or bottom, depending on if the list
  // is in reverse mode - it will be the top when in reverse).
  useEffect(() => {
    const [lastItem] = [
      ...rowVirtualizerRef.current.getVirtualItems()
    ].reverse();

    if (!lastItem || lastItem.index < rowCount - 1) {
      return;
    }

    if (typeof onScrolledToEnd === "function") {
      onScrolledToEnd();
    }
  }, [rowVirtualizer.getVirtualItems()]);

  useEffect(() => {
    if (scrollToIndex == null) {
      return;
    }
    rowVirtualizer.scrollToIndex(scrollToIndex);
  }, [scrollToIndex, rowVirtualizer]);

  const handleGetRowKey = (index) => {
    if (typeof getRowKey === "function") {
      return getRowKey(index);
    } else {
      return index;
    }
  };

  return (
    <StyledVirtualScroll ref={parentRef} reverse={reverse}>
      <VirtualScrollList
        height={rowVirtualizer.getTotalSize()}
        reverse={reverse}
        reverseStartAtTop={reverseOptions.startAtTop}
      >
        {
          rowVirtualizer.getVirtualItems().map((virtualRow) => (
            <VirtualScrollListItem
              key={handleGetRowKey(virtualRow.index)}
              ref={ref => virtualRow.measureElement(ref)}
              reverse={reverse}
              translateY={reverse ? virtualRow.end : virtualRow.start}
            >
              {
                renderRow(virtualRow.index)
              }
            </VirtualScrollListItem>
          ))
        }
      </VirtualScrollList>
    </StyledVirtualScroll>
  );
};

export default VirtualScroll;
