import React, { useState, memo } from "react"
import style from "./VideoMenu.module.css"
import { string, func } from "prop-types"
import ConfirmModal from "@guardian/Components/Modals/ConfirmModal"

import { Users } from "@guardian/API/Optimus";
import { ContentService } from "@guardian/Services/Content";

const VideoMenu = ({ id, userId, onReject, username, location }) => {
  const [confirmProps, setConfirmProps] = useState(null)
  const blockBroadcast = async () => {
    try {
      await ContentService.rejectContent(
        "video_stream",
        id,
        location,
        {
          reason: "Broadcast blocked."
        }
      )
      await Users.updateUserPermissions(
        userId,
        { permissions: { blockedBroadcast: 1 } }
      )
      onReject(id)
    } catch (e) {
      console.error(e)
    }
  }

  const ban = async () => {
    try {
      await ContentService.rejectContent(
        "video_stream",
        id,
        location,
        {
          reason: "User banned."
        }
      )
      await Users.updateUserPermissions(
        userId,
        { permissions: { banned: 1 } }
      )
      onReject(id)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={style.container}>
      <button
        className={style.option}
        onClick={() => {
          setConfirmProps({
            text: `Are you sure you want to ban ${username}?`,
            onConfirm: ban,
            onCancel: () => setConfirmProps(null),
          })
        }}
      >
        Ban User
      </button>
      <button
        className={style.option}
        onClick={() => {
          setConfirmProps({
            text: `Are you sure you want to block broadcast access for ${username}?`,
            onConfirm: blockBroadcast,
            onCancel: () => setConfirmProps(null),
          })
        }}
      >
        Block Broadcast Access
      </button>
      {confirmProps && <ConfirmModal {...confirmProps} />}
    </div>
  )
}

VideoMenu.propTypes = {
  id: string,
  userId: string,
  onReject: func,
  username: string,
  location: string,
}

export default memo(VideoMenu)
