import styled from "styled-components";

const Logo = styled.img.attrs(
  () => {
    return {
      alt: "Protect OS",
      src: "/ProtectOS-Logo.svg"
    };
  }
)`
  box-sizing: content-box;
  display: inline-block;
  height: 100%;
  padding: 0 var(--space-x-large);
  width: var(--space-xx-large);
`;

export default Logo;
