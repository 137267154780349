import { useEffect, useState } from "react";

import { useGlobalState } from "@guardian/State";
import type { Incident2General } from "@guardian/Types/Incident";
import * as incUtil from "@guardian/Utils/incident";

const useGetPendingRadioIncidentsCount = () => {
  // TODO: We only use the incidents to get the count. This is likely a heavy
  // lift due to size of incidents payloads and frequency of updates. It would
  // likely be beneficial to have a separate state for this count and other
  // similar counts (e.g.; pending, non-pending, etc.). Then our components
  // using this sort of data could opt to only rerender when changes to such
  // counts occur.
  const incidents = useGlobalState(state => (state.moderation.incidents));

  const [count, setCount] = useState(0);

  useEffect(() => {
    const count = incidents.filter((i: Incident2General) => {
      return i &&
        !incUtil.isRejectedIncident(i) &&
        incUtil.analyzeIncident(i).pendRevs.length > 0;
    }).length;

    setCount(count);
  }, [incidents]);

  return [
    count
  ];
};

export default useGetPendingRadioIncidentsCount;
