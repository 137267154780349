import React from "react";
import PropTypes from "prop-types";
import {
  SwitchTransition as ReactSwitchTransition
} from "react-transition-group";

import StyledSwitchTransition from "../views/SwitchTransition";

const SwitchTransition = props => {
  const {
    activeChildKey,
    children,
    transitionMs,
    transitionType,
    ...viewProps
  } = props;

  return (
    <ReactSwitchTransition>
      <StyledSwitchTransition
        key={activeChildKey}
        transitionMs={transitionMs}
        transitionType={transitionType}
        { ...viewProps }
      >
        { children }
      </StyledSwitchTransition>
    </ReactSwitchTransition>
  );
};

SwitchTransition.propTypes = {
  activeChildKey: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
  ...StyledSwitchTransition.propTypes
};

SwitchTransition.defaultProps = {
  ...StyledSwitchTransition.defaultProps
};

export default SwitchTransition;
