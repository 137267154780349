import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

export const StyledButton = styled.button.attrs(
  props => {
    const {
      active,
      disabled,
      focused,
      hovered,
      isDanger,
      isPrimary,
      isFilled,
      isLink,
      outline,
      pill,
      stretch,
      tightenX,
      tightenY
    } = props;

    return {
      "aria-disabled": disabled,
      className: classNames("button", {
          "button--is-active": active,
          "button--is-disabled": disabled,
          "button--is-focused": focused,
          "button--is-hovered": hovered,

          "button--is-danger": isDanger,
          "button--is-link": isLink,
          "button--is-primary": isPrimary,
          "button--is-filled": isFilled,

          "button--outline": outline,

          "button--pill": pill,
          "button--stretch": stretch,

          "button--tighten-x": tightenX,

          "button--tighten-y": tightenY,
        })
    };
  }
)`
  ${ buttonResets }

  align-items: center;
  background: var(--colors-transparent);
  border-radius: var(--radii-medium);
  color: var(--colors-white);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-sizes-medium);
  font-weight: var(--font-weights-normal);
  justify-content: center;
  line-height: var(--line-heights-medium);
  overflow: hidden;
  padding: var(--space-x-small) var(--space-medium);
  position: relative;
  transition:
    background 100ms cubic-bezier(0.39, 0.575, 0.565, 1),
    box-shadow 100ms cubic-bezier(0.39, 0.575, 0.565, 1),
    color 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
  user-select: none;
  z-index: 2;

  // Background color layer.
  &:before {
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  // Highlight layover for hover/focus events.
  &:after {
    background: var(--colors-white);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition:
      opacity 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
    top: 0;
    z-index: 1;
  }

  &.button--outline {
    box-shadow: var(--borders-full) var(--colors-grey-080);
  }

  &.button--stretch {
    width: 100%;
  }

  &.button--pill {
    border-radius: var(--radii-pill);
  }

  &.button--tighten-x {
    padding-left: var(--space-small);
    padding-right: var(--space-small)
  }

  &.button--tighten-y {
    padding-bottom: var(--space-xx-small);
    padding-top: var(--space-xx-small)
  }

  &:active, &.button--is-active,
  &:hover, &.button--is-hovered
  {
    &:after {
      opacity: 0.1;
    }
  }

  // :focus managed by KeyboardFocusContainer
  &.button--is-focused
  {
    &:after {
      opacity: 0.1;
    }
  }

  &.button--is-disabled {
    color: var(--colors-grey-030);
    pointer-events: none;

    &.button--outline {
      box-shadow: var(--borders-full) var(--colors-grey-085);
    }
  }

  &.button--is-danger {
    color: var(--colors-white);

    // Set background color layer color.
    &:before {
      background: var(--colors-red-050);
    }

    &.button--is-disabled {
      color: var(--colors-red-030);
      // Set background color layer color.
      &:before {
        background: var(--colors-red-070);
      }

      &.button--outline {
        color: var(--colors-white);
        box-shadow: var(--borders-full) var(--colors-red-040);

        &:before {
          background: var(--colors-transparent);
        }
      }
    }
  }

  &.button--is-primary {
    color: var(--colors-white);

    // Set background color layer color.
    &:before {
      background: var(--colors-blue-050);
    }

    &.button--is-disabled {
      color: var(--colors-blue-030);
      // Set background color layer color.
      &:before {
        background: var(--colors-blue-070);
      }
    }
  }

  &.button--is-filled {
    // Set background color layer color.
    &:before {
      background: color-mix(in srgb, currentColor 10%, transparent);
    }

    &.button--is-disabled {
      // Set background color layer color.
      &:before {
        background: color-mix(in srgb, currentColor 5%, transparent);
      }
    }
  }

  // Give link styles priority.
  && {
    &.button--is-link {
      background: var(--colors-transparent);
      box-shadow: none;
      color: var(--colors-white);
      cursor: pointer;
      outline: none;
      text-decoration: none;

      // Remove background color layer from button.
      &:before {
        content: none;
      }

      // Remove hover/focus highlight layover from button.
      &:after {
        content: none;
      }

      &:active, &.button--is-active,
      &:hover, &.button--is-hovered
      {
        background: var(--colors-transparent);
        box-shadow: none;
        color: var(--colors-white);
        text-decoration: underline;
      }

      // :focus managed by KeyboardFocusContainer
      &.button--is-focused
      {
        background: var(--colors-transparent);
        box-shadow: none;
        color: var(--colors-white);
        text-decoration: underline;
      }

      &.button--is-disabled {
        background: var(--colors-transparent);
        box-shadow: none;
        color: var(--colors-grey-030);
        pointer-events: none;
      }

      && {
        &.button--is-danger {
          color: var(--colors-red-050);

          &:active, &.button--is-active,
          &:hover, &.button--is-hovered
          {
            color: var(--colors-red-050);
          }

          // :focus managed by KeyboardFocusContainer
          &.button--is-focused
          {
            color: var(--colors-red-050);
          }

          &.button--is-disabled {
            color: var(--colors-red-070);
          }
        }

        &.button--is-primary {
          color: var(--colors-blue-050);

          &:active, &.button--is-active,
          &:hover, &.button--is-hovered
          {
            color: var(--colors-blue-050);
          }

          // :focus managed by KeyboardFocusContainer
          &.button--is-focused
          {
            color: var(--colors-blue-050);
          }

          &.button--is-disabled {
            color: var(--colors-blue-070);
          }
        }
      }
    }
  }
`;

const StyledButtonTextWrapper = styled.span`
  align-items: center;
  display: inline-flex;
  z-index: 1;
`;

/**
 * Button
 *
 * The Button component is a styled button element that supports a variety of
 * visual styles and states.
 *
 * @type {typeof StyledButton}
 */
const Button = React.forwardRef(
  ({ children, focused, loading, ...other }, ref) => (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <StyledButton
          {...getFocusProps({
            ref,
            ...other,
            focused: focused || keyboardFocused
          })}
        >
          <StyledButtonTextWrapper>
            { children }
          </StyledButtonTextWrapper>
        </StyledButton>
      )}
    </KeyboardFocusContainer>
  )
);

Button.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool,
  isDanger: PropTypes.bool,
  isLink: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isFilled: PropTypes.bool,
  outline: PropTypes.bool,
  pill: PropTypes.bool,
  stretch: PropTypes.bool,
  tightenX: PropTypes.bool,
  tightenY: PropTypes.bool
};

Button.defaultProps = {
  active: false,
  disabled: false,
  focused: false,
  hovered: false,
  isDanger: false,
  isLink: false,
  isPrimary: false,
  isFilled: false,
  outline: true,
  pill: false,
  stretch: false,
  tightenX: false,
  tightenY: false
};

export default Button;
