import classNames from "classnames";
import styled from "styled-components";

const NotesListOuterWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__list__outer-wrapper"
    )
  };
})`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`;

NotesListOuterWrapper.defaultProps = {};

export default NotesListOuterWrapper;
