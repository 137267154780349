import _ from "lodash"
import { isPointInside, getCenterOfBounds } from "geolib"

import { ServiceAreaService } from "@guardian/Services/ServiceArea";

export async function getSuggests(value, serviceAreas) {
  let precinctData = _.flatten(
    await Promise.all(serviceAreas.map(s => ServiceAreaService.getPoliceAreas(s)))
  )
  precinctData = precinctData.map(p => ({ ...p, precinct: true }))
  precinctData = precinctData.concat(_.flatten(
    await Promise.all(serviceAreas.map(s => ServiceAreaService.getPoliceSubAreas(s)))
  ))

  for (const i in precinctData) {
    const p = precinctData[i]
    p.nameLower = p.name.toLowerCase()
    p.suggestion = p.name
    p.type = "precinct"
    p.polygonType = p.precinct ? "police precinct" : "reporting district"
    p.geometry_json = {}
    p.geometry_json.polygons = _.flatten(p.geometry.coordinates)
    p.geometry_json.center = getCenterOfBounds(
      p.geometry_json.polygons.map(arr => ({
        longitude: arr[0],
        latitude: arr[1],
      })),
    )
  }

  const vLower = value.toLowerCase()

  return _.take(
    precinctData.filter(p => {
      return p.nameLower.includes(vLower) || p.polygonType.includes(vLower)
    }),
    4,
  )
}

export function pointInPrecinct(point, precinct) {
  return isPointInside(
    { longitude: point[0], latitude: point[1] },
    precinct.geometry_json.polygons.map(coord => ({
      latitude: coord[1],
      longitude: coord[0],
    })),
  )
}
