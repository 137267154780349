import { createContext, useContext } from "react";

export const TabListContext = createContext(undefined);

const useTabListContext = () => {
  const tabListContext = useContext(TabListContext);

  if (!tabListContext) {
    throw new Error("This component must be rendered within a `TabList` component.");
  }

  return tabListContext;
};

export default useTabListContext;
