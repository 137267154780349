import classNames from "classnames";
import styled from "styled-components";

import { Icon } from "@guardian/UI/Icon";

const LinkIcon = styled(Icon.Link).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__base-user-info__link-icon"
    )
  };
})`
  color: var(--colors-grey-050);
  // Custom rotation for this icon.
  transform: rotate(-45deg);
`;

LinkIcon.defaultProps = {};

export default LinkIcon;
