import classnames from "classnames"
import _ from "lodash"
import { bool, func, number, object, string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { eventHub } from "@guardian/State"

import ChannelMenu from "./ChannelMenu/ChannelMenu"
import "./SubArea.scss"

const SubArea = props => {
  const {
    idx,
    listeningGroup,
    subArea,
    selected,
    onSelect,
    activeChannels,
    channels,
    channelStats,
    responders,
    mutes,
  } = props
  const activeCount = Object.keys(activeChannels).filter(
    cid => channels[cid].subArea === subArea,
  ).length
  const [flaggedCount] = useState(
    _.sum(
      Object.keys(channelStats)
        .filter(cid => channels[cid] && channels[cid].subArea === subArea)
        .map(cid => channelStats[cid]),
    ),
  )

  const subAreaRef = useRef(null)
  const [menuStyle, setMenuStyle] = useState({
    maxHeight: "600px",
  })

  useEffect(() => {
    let distFromTop = 0
    if (subAreaRef.current) {
      distFromTop = Math.floor(subAreaRef.current.getBoundingClientRect().top)
    }

    // Responder name 24px height
    // Each channel is 32px height
    let sumChannels = 0
    let sumResponders = 0
    Object.entries(responders).forEach(([resp, arr]) => {
      sumResponders += 1
      sumChannels += arr.length
    })

    const menuHeightCalc = Math.min(24 * sumResponders + 32 * sumChannels, 600)
    const halfChannelMenuHeight = menuHeightCalc / 2
    const listenPanelDistFromTop = document
      .getElementsByClassName("listen-panel")[0]
      .getBoundingClientRect().top

    if (halfChannelMenuHeight >= distFromTop - listenPanelDistFromTop) {
      setMenuStyle({ maxHeight: "600px", top: "8px" })
    } else {
      setMenuStyle({
        maxHeight: "600px",
        bottom: `max(8px, calc(100vh - ${distFromTop}px - ${halfChannelMenuHeight}px))`,
      })
    }
  }, [subAreaRef, responders])

  return (
    <div
      className={classnames("sub-area-container", { selected })}
      onMouseOver={() => onSelect(subArea)}
      ref={subAreaRef}
    >
      <div
        className='sub-area-header'
        onMouseOver={() => onSelect(subArea)}
        onClick={() => eventHub.emit("toggleSubArea", subArea)}
      >
        <span>{subArea}</span>
        {activeCount > 0 && (
          <>
            <span className='active-count'>({activeCount})</span>
          </>
        )}
        {flaggedCount > 0 && (
          <span className='flagged-count'>
            {flaggedCount} <i className='fas fa-arrow-up' />
          </span>
        )}
      </div>
      {selected && (
        <div
          className='menu-padding'
          style={menuStyle}
          onMouseLeave={() => {
            onSelect(null)
            eventHub.emit("setDialogMask", false)
          }}
        >
          <div
            className='pad'
            style={{ flexBasis: `${24 * idx}px` }}
            onClick={() => eventHub.emit("closeChannelSelection")}
          />
          <ChannelMenu
            listeningGroup={listeningGroup}
            subArea={subArea}
            responders={responders}
            mutes={mutes}
            activeChannels={activeChannels}
            channelStats={channelStats}
          />
        </div>
      )}
    </div>
  )
}

SubArea.propTypes = {
  idx: number,
  listeningGroup: string,
  subArea: string,
  selected: bool,
  onSelect: func,
  activeChannels: object,
  channels: object,
  channelStats: object,
  responders: object,
  mutes: object,
}

export default SubArea
