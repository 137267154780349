import { useState, MouseEvent, memo } from "react"

import { CalloutButton } from "@guardian/UI/Legacy/CalloutButton"

import SortingContextMenu from "./SortingContextMenu"

const SortingControls = () => {
  const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false)
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })

  const openContextMenu = (e: MouseEvent) => {
    e.preventDefault()
    setContextMenuVisible(true)
    setContextMenuPosition({ x: e.clientX, y: e.clientY })
  }

  const closeContextMenu = () => {
    setContextMenuVisible(false)
  }

  return (
    <>
      <CalloutButton onClick={openContextMenu} title='Change Sorting'>
        <i className='fas fa-sort-amount-down'></i>
      </CalloutButton>
      {contextMenuVisible && (
        <SortingContextMenu
          x={contextMenuPosition.x}
          y={contextMenuPosition.y}
          onClose={closeContextMenu}
        />
      )}
    </>
  )
}

export default memo(SortingControls)
