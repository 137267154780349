import React, { useState } from "react"
import { Link } from "react-router-dom"

import { shouldReplace } from "@guardian/Utils/history"
import { serviceArea as serviceAreaType } from "@guardian/Utils/models/geo"
import { coverageToHealthLevel } from "@guardian/Utils/util"

import ProgressCircle from "@guardian/Components/Loaders/ProgressCircle"

import style from "./Markers.module.css"

const ServiceAreaMarker = ({ model }) => {
  const [hovered, setHover] = useState(false)
  const pathname = `/caterpie/cities/${model.code}`
  const health = coverageToHealthLevel(model.coverage)

  return (
    <Link to={pathname} replace={shouldReplace(pathname)}>
      {!hovered && (
        <div className={style.fullWidth}>
          <div
            className={style.city}
            data-health={health}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
          <div className={style.text}>{model.name}</div>
        </div>
      )}
      {hovered && (
        <div className={style.fullWidth}>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <ProgressCircle percent={model.coverage} width={32} />
          </div>

          <div className={style.tooltip}>
            <h5 className={style.heading}>{model.name}</h5>
            <div>
              <b>{model.guardians}</b> active Guardians
            </div>
          </div>
        </div>
      )}
    </Link>
  )
}

ServiceAreaMarker.propTypes = {
  model: serviceAreaType.isRequired,
}

export default ServiceAreaMarker
