import React, { Fragment, useState } from "react"
import { number, func, array } from "prop-types"
import style from "./VideoTrimming.module.css"
import classnames from "classnames"
import { Slider, Handles, Tracks, Rail } from "react-compound-slider"

const VideoPosition = ({
  value,
  onChange,
  onSeekStart,
  onSeekEnd,
  trimStart,
  trimEnd,
  domain,
}) => {
  const [seeking, setSeeking] = useState(false)
  const [updates, setUpdates] = useState(0)
  return (
    <div className={style.position}>
      <Slider
        rootStyle={{
          position: "relative",
          width: "100%",
        }}
        mode={(values, update) => update}
        domain={domain}
        values={[trimStart, value, trimEnd]}
        onUpdate={values => {
          setUpdates(updates + 1)

          if (updates % 5 === 0) {
            if (seeking && values[0] !== trimStart) {
              onChange("trimStart", values[0])
            }

            if (seeking && values[1] !== value) {
              onChange("position", values[1])
            }

            if (seeking && values[2] !== trimEnd) {
              onChange("trimEnd", values[2])
            }
          }
        }}
        onSlideStart={(values, { activeHandleID }) => {
          const seeker = activeHandleID === "$$-1"
          if (seeker) {
            setSeeking(true)
          }
        }}
        onSlideEnd={(values, { activeHandleID }) => {
          const seeker = activeHandleID === "$$-1"
          if (seeker) {
            onSeekEnd(values[1])
          } else if (activeHandleID === "$$-2") {
            onChange("trimEnd", values[2])
            setSeeking(false)
          } else {
            onChange("trimStart", values[0])
            setSeeking(false)
          }
        }}
      >
        <Rail>
          {({ getRailProps }) => (
            <Fragment>
              <div className={style.outerRail} {...getRailProps()} />
              <div className={style.innerRail} />
            </Fragment>
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className='slider-handles'>
              {handles.map(handle => {
                const seeker = handle.id === "$$-1"

                return (
                  <Fragment key={handle.id}>
                    <div
                      style={{
                        left: `${handle.percent}%`,
                      }}
                      className={classnames(style.handle, {
                        [style.seeker]: seeker,
                      })}
                      {...getHandleProps(handle.id)}
                    />
                  </Fragment>
                )
              })}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className='slider-tracks'>
              {tracks.map(({ id, source, target }) => {
                const played = id === "$$-0-$$-1"

                return (
                  <div
                    key={id}
                    className={classnames(style.track, {
                      [style.played]: played,
                    })}
                    style={{
                      left: `${source.percent}%`,
                      width: `${target.percent - source.percent}%`,
                    }}
                    {...getTrackProps()}
                  />
                )
              })}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  )
}

VideoPosition.propTypes = {
  value: number,
  onChange: func,
  trimStart: number,
  trimEnd: number,
  domain: array,
  onSeekStart: func,
  onSeekEnd: func,
}

export default VideoPosition
