import classNames from "classnames";
import styled from "styled-components";

const NotesListItem = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__tabs-panel__notes__list-item"
    )
  };
})`
  padding: var(--space-small) var(--space-medium);
  white-space: pre-wrap; // Support line breaks in text.
`;

NotesListItem.defaultProps = {};

export default NotesListItem;
