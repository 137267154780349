import React from "react"
import { bool } from "prop-types"

const ContentMask = ({ on }) => (
  <div
    className={on ? "content-pane-mask" : ""}
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()
    }}
  />
)

ContentMask.propTypes = {
  on: bool,
}

export default ContentMask
