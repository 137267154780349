import React, { useEffect, useState } from "react"
import { array, object } from "prop-types"

import { isNearby } from "@guardian/Utils/search_filter"

import style from "./LinkHelicopterMenu.module.css"
import { linkHelicopterToIncident } from "./HelicopterHelpers"

const MILE = 1600
const MILES_MULTIPLE = 2

const LinkHelicopterMenu = ({ helicopterIncidents, baseIncident }) => {
  const [nearbyHelicopters, setNearbyHelicopters] = useState([])
  const [linkedHelis, setLinkedHelis] = useState([])

  useEffect(() => {
    const filtered = helicopterIncidents.filter(heli => {
      const heliLoc = [heli.location.lng, heli.location.lat]
      const inciLoc = [baseIncident.location.lng, baseIncident.location.lat]
      return isNearby(heliLoc, inciLoc, MILES_MULTIPLE * MILE)
    })
    setNearbyHelicopters(filtered)
  }, [helicopterIncidents, baseIncident])

  return (
    <div className={style.linkMenu}>
      <div className={style.menuTitle}>Nearby Helicopters</div>
      {nearbyHelicopters.length === 0 && (
        <div className={style.emptyList}>
          No helicopters within {MILES_MULTIPLE} miles of this incident{" "}
        </div>
      )}
      {nearbyHelicopters.length >= 0 &&
        nearbyHelicopters.map(heli => (
          <div className={style.helicopterRow} key={heli.id}>
            <div className={style.helicopterRowTitle}>{heli.title}</div>
            <button
              className={style.helicopterRowButton}
              onClick={async () => {
                await linkHelicopterToIncident(heli, baseIncident)
                setLinkedHelis([...linkedHelis, heli.id])
              }}
            >
              {linkedHelis.includes(heli.id) ? "Linked!" : "Link"}
            </button>
          </div>
        ))}
    </div>
  )
}

LinkHelicopterMenu.propTypes = {
  helicopterIncidents: array,
  baseIncident: object,
}

export default LinkHelicopterMenu
