import lodash from "lodash";
import { w3cwebsocket } from "websocket";
import EventEmitter from "events";

import config from "@guardian/Config";
import { LoggingService } from "@guardian/Services/Logging";
import { SessionService } from "@guardian/Services/Session";

export class Sockets extends EventEmitter {
  constructor(url) {
    super();
    this.subs = [];
    this.url = url;

    this.needsReconnect = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;

    this.setupNetworkHandlers();

    LoggingService.logMessage(
      `Initializing socket connection.`,
      {
        domain: "Sockets",
        trackedData: { url: this.url }
      }
    );
  }

  setupNetworkHandlers() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    if (this.needsReconnect) {
      LoggingService.logMessage(
        "Network online again - attempting to reconnect WebSocket.", {
          domain: "Sockets"
        }
      );

      this.attemptReconnect();
    }
  }

  handleOffline = () => {
    if (this.wsClient) {
      this.needsReconnect = true;

      LoggingService.logError(
        "Network offline - closing WebSocket connection.", {
          domain: "Sockets",
          flash: true,
          flashOptions: {
            title: "Socket Connection Closed - Network Offline",
          },
        }
      );

      this.close();
    }
  }

  attemptReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      setTimeout(() => {
        LoggingService.logMessage(
          `Attempting to reconnect, attempt #${this.reconnectAttempts + 1}`, {
            domain: "Sockets"
          }
        );
        this.open();
        this.reconnectAttempts++;
      }, Math.pow(2, this.reconnectAttempts) * 1000); // Exponential backoff
    } else {
      LoggingService.logError(
        "Max reconnect attempts reached, stopping reconnection attempts.", {
          domain: "Sockets"
        }
      );
    }
  }

  startPingInterval() {
    return setInterval(() => {
      if (this.wsClient) {
        this.wsClient.send(JSON.stringify({ method: "ping" }));
      }
    }, 3000);
  }

  close() {
    LoggingService.logMessage(
      "Closing socket connection.", {
        domain: "Sockets",
        trackedData: {
          url: this.url
        }
    });

    if (this.wsClient) {
      this.wsClient.close();
      this.wsClient = null;
    }

    clearTimeout(this.pongWait);
    clearInterval(this.pingInterval);

    this.isOpen = false;
    this.ready = false;
    this.closing = true;
  }

  open() {
    // Prevent multiple open calls.
    if (
      this.wsClient &&
      [
        WebSocket.OPEN,
        WebSocket.CONNECTING
      ].includes(this.wsClient.readyState)
    ) {
      return;
    }

    this.isOpening = true;

    this.close(); // Ensures a clean state before opening a new connection
    this.ready = false;
    this.needsReconnect = false;
    this.closing = false;

    const citizenJWT = SessionService.accessToken;
    const client = new w3cwebsocket(
      this.url + "?token=" + encodeURIComponent(citizenJWT)
    );

    client.onopen = (e) => {
      LoggingService.logMessage(
        "Socket connection opened.", {
          domain: "Sockets",
          trackedData: {
            event: JSON.stringify(e),
            url: this.url
          }
        }
      );

      this.reconnectAttempts = 0;
      this.pingInterval = this.startPingInterval();
      this.initSubs();
      this.isOpen = true;
    };

    client.onmessage = ({ data }) => {
      this.handleMessages(data);
    };

    client.onerror = (e) => {
      this.handleError(e);
    };

    client.onclose = (e) => {
      LoggingService.logMessage(
        "Socket connection closed.", {
          domain: "Sockets",
          flash: true,
          flashOptions: {
            title: "Socket Connection Closed",
          },
          trackedData: {
            event: JSON.stringify(e),
            url: this.url
          }
        }
      );

      this.isOpen = false;

      if (!this.closing) {
        this.attemptReconnect();
      }
    };

    this.isOpening = false;

    this.wsClient = client;
  }

  async initSubs() {
    this.ready = true;
    for (let sub of this.subs) {
      this.wsClient.send(JSON.stringify(sub));
    }
  }

  handleMessages(data) {
    let payload;
    try {
      payload = JSON.parse(data);
    } catch (e) {
      this.handleError(e);
      return;
    }

    let messages = payload.messages || [payload];
    for (let msg of messages) {
      if (msg.type === "error") {
        this.emit("responseError", msg);
      } else {
        this.emit(msg.type, msg);
      }
    }
  }

  handleError(e) {
    LoggingService.logError(
      "Socket connection encountered an error!", {
        domain: "Sockets",
        flash: true,
        flashOptions: {
          title: "Socket Connection Error",
        },
        trackedData: {
          event: JSON.stringify(e),
          url: this.url
        }
      }
    );

    this.emit("socketError", e);
  }

  sub(subscription) {
    if (!lodash.find(this.subs, s => lodash.isEqual(s, subscription))) {
      this.subs.push(subscription);
      if (this.ready) {
        this.wsClient.send(JSON.stringify(subscription));
      }
    }
  }

  removeSub(subscription, unsubRequest) {
    this.subs = lodash.filter(this.subs, s => !lodash.isEqual(s, subscription));
    if (unsubRequest && this.ready) {
      this.wsClient.send(JSON.stringify(unsubRequest));
    }
  }

  sendMessage(message) {
    if (this.wsClient) {
      this.wsClient.send(JSON.stringify(message));
    }
  }

  onEvent(event, handler) {
    this.on(event, handler);
  }

  removeEvent(event, handler) {
    this.off(event, handler);
  }

  clearSubs() {
    this.subs = [];
  }

  destroy() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
    this.close();
  }
}

export const webSockets = new Sockets(config.webSockets);
export default webSockets;
