export const inputStyle = {
  root: {
    marginTop: "16px",
    paddingLeft: "8px",
    paddingTop: "8px",
    paddingRight: "8px",
    height: "157px",
    width: "100%",
    color: "white",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    "& .char-count": {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "16px",
      color: "#8c8c8c !important",
      float: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333 !important",
    },
  },
  notchedOutline: {
    "& > legend": {
      visibility: "visible",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "16px",
      color: "#8C8C8C !important",
    },
  },
}
