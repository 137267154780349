import React, { useEffect, useState } from "react"
import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import classnames from "classnames"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { postProtectCallbackNotif } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { SOS_SESSION_MISSED } from "@guardian/Components/ModSOS/components/CallQueue/CallQueue/CallQueue.helper"

export const SOSVideoCallbackPrompt: React.FunctionComponent = () => {
  const { state } = useSOSDashboardContext()
  const [callbackNotifSent, setCallbackNotifSent] = useState(false)

  const isMissedCall =
    state.callSession && state.callSession.status === SOS_SESSION_MISSED

  const sendCallbackNotif = async () => {
    if (state.activeSessionId) {
      await postProtectCallbackNotif(state.activeSessionId)

      setCallbackNotifSent(true)
    }
  }

  useEffect(() => {
    setCallbackNotifSent(false)
  }, [state.activeSessionId])

  return (
    <div className={styles.missed}>
      <h1>{isMissedCall ? "Missed call" : "User left call"}</h1>
      <button
        className={classnames(styles.missedButton, {
          [styles.notifSent]: callbackNotifSent,
        })}
        disabled={callbackNotifSent}
        onClick={sendCallbackNotif}
      >
        {callbackNotifSent
          ? "Notification sent. You can resolve this call."
          : "Send a callback notification"}
      </button>
    </div>
  )
}
