import { Analytics, AnalyticsBrowser } from "@segment/analytics-next"

let analytics: Analytics | undefined

export const loadAnalytics = async (env: string) => {
  const host = `metrics${env === "STAGING" ? ".staging" : ""}.sp0n.io/v1`
  const [response] = await AnalyticsBrowser.load(
    { writeKey: "y2m86pI883NcYfQmNB9IUeScDdNIsthP" },
    {
      integrations: {
        "Segment.io": {
          apiHost: host,
        },
      },
    },
  )

  analytics = response
}

export const track = (eventName: string, properties: object) => {
  analytics?.track(eventName, properties)
}
