import { TextField } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import classnames from "classnames"
import { array, func } from "prop-types"
import { useMemo, useState } from "react"

import { useGlobalState } from "@guardian/State"

import { otherStyles, textfieldStyles } from "./ServiceAreaSelector.styles"
import styles from "./StreamPanel.module.css"

const CustomTextField = withStyles(textfieldStyles)(TextField)

const useStyles = makeStyles(otherStyles)

const ServiceAreaSelector = ({ serviceAreaFilters, setServiceAreaFilters }) => {
  const classes = useStyles()

  const serviceAreaConfig = useGlobalState(
    state => state.global.serviceAreaConfig.serviceAreas,
  )

  const [selectedAll, setSelectedAll] = useState(true)

  const options = useMemo(() => {
    const options = Object.values(serviceAreaConfig).map(sa => ({
      code: sa.code,
      title: sa.name,
    }))
    options.unshift({ code: "_all", title: "All Service Areas" })
    return options
  }, [serviceAreaConfig])

  const handleOnChange = (_, values) => {
    const allChosen = values.find(sa => sa.code === "_all")
    if (allChosen && !selectedAll) {
      setSelectedAll(true)
      setServiceAreaFilters([allChosen])
    } else if (!allChosen && selectedAll) {
      setServiceAreaFilters([])
      setSelectedAll(false)
    } else {
      const newVals = values.filter(sa => sa.code !== "_all")
      if (newVals.length === 0) {
        newVals.unshift({ code: "_all", title: "All Service Areas" })
        setSelectedAll(true)
      } else {
        setSelectedAll(false)
      }
      setServiceAreaFilters(newVals)
    }
  }

  const removeValue = valueCode => {
    const newVals = serviceAreaFilters.filter(sa => sa.code !== valueCode)
    if (newVals.length === 0) {
      newVals.unshift({ code: "_all", title: "All Service Areas" })
      setSelectedAll(true)
    } else {
      setSelectedAll(false)
    }
    setServiceAreaFilters(newVals)
  }

  return (
    <Autocomplete
      multiple
      classes={classes}
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option.title}
      getOptionSelected={(option, value) => option.code === value.code}
      onChange={handleOnChange}
      limitTags={2}
      size='medium'
      renderOption={(option, { selected }) => <div>{option.title}</div>}
      renderTags={(value, _) => {
        return value.map(v => (
          <div key={v.code} className={styles.chip}>
            {v.title}
            <i
              onClick={() => removeValue(v.code)}
              className={classnames("fas fa-times", styles.clearIcon)}
            ></i>
          </div>
        ))
      }}
      value={serviceAreaFilters}
      renderInput={params => (
        <CustomTextField
          {...params}
          variant='outlined'
          label='Coverage Selection'
        />
      )}
    />
  )
}

ServiceAreaSelector.propTypes = {
  serviceAreaFilters: array,
  setServiceAreaFilters: func,
}

export default ServiceAreaSelector
