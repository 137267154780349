import React, { Children } from "react";
import styled from "styled-components";
import classNames from "classnames";

const AnchorIcon = styled(({ children, ...props }) =>
  React.cloneElement(Children.only(children), props)
).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "anchor__icon")
  }
})`
  font-size: inherit!important;
  line-height: inherit!important;
  transition:
    color 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
  vertical-align: top;
`;

const End = styled(AnchorIcon)`
  margin-left: var(--space-xx-small);
`;

const Start = styled(AnchorIcon)`
  margin-right: var(--space-xx-small);
`;

AnchorIcon.propTypes = {};

AnchorIcon.defaultProps = {};

export default {
  End,
  Start
};
