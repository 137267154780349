import { useContext, useEffect, useRef, useState } from "react";

import { webSockets } from "@guardian/Sockets";
import { AgentChat } from "@guardian/Lib/QueryClient/hooks";
import { AppContext } from "@guardian/Core";

import {
  generateMessageGroups,
  prependToMessageGroups
} from "./utils/generateMessageGroup";

const sendIsMessageRead = (id, groupId) => {
  webSockets.sendMessage({
    method: "agentAckGroupMessage",
    value: {
      id: id,
      groupId: groupId,
      status: "READ",
    },
  });
};

const useAgentChatFeed = ({ groupId, sessionId }) => {
  const {
    agentChat: {
      setVisibleSessionId
    }
  } = useContext(AppContext);

  const [canLoadMore, setCanLoadMore] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    messageGroups,
    setMessageGroups
  ] = useState([]);
  const [visible, setVisible] = useState(false);

  const messageGroupsRef = useRef(messageGroups);
  const groupIdRef = useRef(groupId);
  const sessionIdRef = useRef(sessionId);
  const unreadMessagesRef = useRef([]);
  const visibleRef = useRef(visible);

  const {
    data: queryData,
    error: queryError,
    isFetching: isLoadingGeneric,
    isFetchingNextPage: isLoadingMore,
    fetchNextPage: loadMore,
  } = AgentChat.useGetGroupMessagesInfinite(
    groupId,
    useAgentChatFeed.loadLimit
  );

  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  useEffect(() => {
    groupIdRef.current = groupId;
  }, [groupId]);

  useEffect(() => {
    messageGroupsRef.current = messageGroups;
  }, [messageGroups]);

  useEffect(() => {
    const groupId = groupIdRef.current;
    const sessionId = sessionIdRef.current;
    const unreadMessages = unreadMessagesRef.current;

    const clearUnreads = () => {
      unreadMessagesRef.current = [];
      unreadMessages.forEach(message => sendIsMessageRead(message.id, groupId));
    };

    visibleRef.current = visible;

    if (visible) {
      setVisibleSessionId(sessionId);
      clearUnreads();
    } else {
      setVisibleSessionId(null);
    }

    return () => {
      setVisibleSessionId(null);
    }
  }, [visible, setVisibleSessionId]);

  useEffect(() => {
    if (queryData == null) {
      return;
    }

    const messageGroups = messageGroupsRef.current;
    const lastFetchedDataGroup = queryData.pages[queryData.pages.length - 1];
    const newMessageGroups = generateMessageGroups(lastFetchedDataGroup);
    setMessageGroups([...messageGroups, ...newMessageGroups]);

    // We assume we can load more if the length of the loaded data matches the
    // length of the requested _limit_ for new data. This has the edge case of
    // possibly doing one unnecessary request, but it's a trade off with the
    // backend not sending details for "end of records".
    const canLoadMore =
      lastFetchedDataGroup.length == useAgentChatFeed.loadLimit;

    setCanLoadMore(canLoadMore)
  }, [queryData]);

  useEffect(() => {
    setIsLoading(isLoadingGeneric && !isLoadingMore);
  }, [isLoadingGeneric, isLoadingMore]);

  useEffect(() => {
    if (queryError == null) {
      return;
    }

    if (queryError.name === 'AbortError') {
      return;
    }

    console.error({
      message: "AgentChat#useAgentChatFeed: an error occured fetching initial assigned chat session messages",
      error: queryError
    });

    setIsError(true);
  }, [queryError]);

  useEffect(() => {
    const groupId = groupIdRef.current;

    const handleMessageUnreadStatus = message => {
      // If we are currently set to visible state (i.e.; if we are currently
      // scrolled to the bottom of our messages window), immediately let the
      // backend know that the message is read. If not not, push message to
      // unread messages array for clearing once we are back in the visible
      // state.
      if (visibleRef.current) {
        sendIsMessageRead(message.id, groupId);
      } else {
        unreadMessagesRef.current.push(message);
      }
    };

    const handleNewMessage = ({ payload: { value: { type, value } } }) => {
      // TODO: Handle constants. isTyping should come eventually as well and be
      // handled here (most likely).
      if (type !== "newGroupMessage") {
        return;
      }

      const message = value;

      // Messages that come in from the agentSubscribeDirectMessage socket feed
      // will be for all group conversations the agent is a part of at any given
      // time. Hence, we need to filter out messages that aren't part of the
      // group for whose messages we are currently viewing.
      if (message.groupId !== groupId) {
        return;
      }

      const messageGroups = messageGroupsRef.current;
      const newMessageGroups = prependToMessageGroups(messageGroups, message);

      handleMessageUnreadStatus(message);
      setMessageGroups(newMessageGroups);
    }

    const subscription = {
      method: "agentSubscribeDirectMessage"
    };

    const listenForNewRecords = () => {
      webSockets.sub(subscription);
      webSockets.onEvent("dmEvent", handleNewMessage);
    };

    listenForNewRecords();

    return () => {
      // Close listeners.
      webSockets.removeEvent("dmEvent", handleNewMessage)
      webSockets.removeSub(subscription, subscription)
    }
  }, []);

  return [
    {
      messageGroups,
      visible
    },
    canLoadMore,
    isError,
    isLoading,
    isLoadingMore,
    loadMore,
    setVisible
  ];
};

useAgentChatFeed.loadLimit = 100;

export default useAgentChatFeed;
