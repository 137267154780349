const transparent = "transparent";
const white = "#FFFFFF";
const black = "#000000";

const blue000 = "#FFFFFF";
const blue005 = "#E6ECFE";
const blue010 = "#CDD9FD";
const blue015 = "#B4C6FC";
const blue020 = "#9BB3FB";
const blue030 = "#698CF9";
const blue040 = "#3766F7";
const blue050 = "#0540F5";
const blue060 = "#0433C4";
const blue070 = "#032693";
const blue080 = "#021A62";
const blue085 = "#02134A";
const blue090 = "#010D31";
const blue095 = "#010619";
const blue100 = "#000000";

const green000 = "#FFFFFF";
const green005 = "#E7FFDC";
const green010 = "#CFFFB8";
const green015 = "#B2FF8E";
const green020 = "#92FF5E";
const green030 = "#61D928";
const green040 = "#33A000";
const green050 = "#236D00";
const green060 = "#1A5200";
const green070 = "#143E00";
const green080 = "#0E2C00";
const green085 = "#0B2200";
const green090 = "#081900";
const green095 = "#051000";
const green100 = "#000000";

const grey000 = "#FFFFFF";
const grey005 = "#EFEFEF";
const grey010 = "#E1E1E1";
const grey015 = "#D2D2D2";
const grey020 = "#C4C4C4";
const grey030 = "#9F9F9F";
const grey040 = "#7C7C7C";
const grey050 = "#666666";
const grey060 = "#5A5A5A";
const grey070 = "#484848";
const grey080 = "#323232";
const grey085 = "#292929";
const grey090 = "#1E1E1E";
const grey095 = "#121212";
const grey100 = "#000000";

const red000 = "#FFFFFF";
const red005 = "#FFEBEB";
const red010 = "#FFD8D9";
const red015 = "#FFC4C6";
const red020 = "#FFB0B2";
const red030 = "#FF7578";
const red040 = "#EA393D";
const red050 = "#CA191D";
const red060 = "#B60509";
const red070 = "#910003";
const red080 = "#660002";
const red085 = "#530002";
const red090 = "#3C0001";
const red095 = "#240001";
const red100 = "#000000";

const yellow000 = "#FFFFFF";
const yellow005 = "#FFFAE6";
const yellow010 = "#FFF4CC";
const yellow015 = "#FFEFB3";
const yellow020 = "#FFE999";
const yellow030 = "#FFDE66";
const yellow040 = "#FFD333";
const yellow050 = "#FFC800";
const yellow060 = "#CCA000";
const yellow070 = "#997800";
const yellow080 = "#665000";
const yellow085 = "#4D3C00";
const yellow090 = "#332800";
const yellow095 = "#1A1400";
const yellow100 = "#000000";

const background = white;
const foreground = black;

const colors = {
  transparent: transparent,
  white: white,
  black: black,

  blue000: blue000,
  blue005: blue005,
  blue010: blue010,
  blue015: blue015,
  blue020: blue020,
  blue030: blue030,
  blue040: blue040,
  blue050: blue050,
  blue060: blue060,
  blue070: blue070,
  blue080: blue080,
  blue085: blue085,
  blue090: blue090,
  blue095: blue095,
  blue100: blue100,

  green000: green000,
  green005: green005,
  green010: green010,
  green015: green015,
  green020: green020,
  green030: green030,
  green040: green040,
  green050: green050,
  green060: green060,
  green070: green070,
  green080: green080,
  green085: green085,
  green090: green090,
  green095: green095,
  green100: green100,

  grey000: grey000,
  grey005: grey005,
  grey010: grey010,
  grey015: grey015,
  grey020: grey020,
  grey030: grey030,
  grey040: grey040,
  grey050: grey050,
  grey060: grey060,
  grey070: grey070,
  grey080: grey080,
  grey085: grey085,
  grey090: grey090,
  grey095: grey095,
  grey100: grey100,

  red000: red000,
  red005: red005,
  red010: red010,
  red015: red015,
  red020: red020,
  red030: red030,
  red040: red040,
  red050: red050,
  red060: red060,
  red070: red070,
  red080: red080,
  red085: red085,
  red090: red090,
  red095: red095,
  red100: red100,

  yellow000: yellow000,
  yellow005: yellow005,
  yellow010: yellow010,
  yellow015: yellow015,
  yellow020: yellow020,
  yellow030: yellow030,
  yellow040: yellow040,
  yellow050: yellow050,
  yellow060: yellow060,
  yellow070: yellow070,
  yellow080: yellow080,
  yellow085: yellow085,
  yellow090: yellow090,
  yellow095: yellow095,
  yellow100: yellow100,

  background: background,
  foreground: foreground
};

export const colorsCSSVariables = `
  :root {
    --colors-transparent: ${ colors.transparent };
    --colors-white: ${ colors.white };
    --colors-black: ${ colors.black };

    --colors-blue-000: ${ colors.blue000 };
    --colors-blue-005: ${ colors.blue005 };
    --colors-blue-010: ${ colors.blue010 };
    --colors-blue-015: ${ colors.blue015 };
    --colors-blue-020: ${ colors.blue020 };
    --colors-blue-030: ${ colors.blue030 };
    --colors-blue-040: ${ colors.blue040 };
    --colors-blue-050: ${ colors.blue050 };
    --colors-blue-060: ${ colors.blue060 };
    --colors-blue-070: ${ colors.blue070 };
    --colors-blue-080: ${ colors.blue080 };
    --colors-blue-085: ${ colors.blue085 };
    --colors-blue-090: ${ colors.blue090 };
    --colors-blue-095: ${ colors.blue095 };
    --colors-blue-100: ${ colors.blue100 };

    --colors-green-000: ${ colors.green000 };
    --colors-green-005: ${ colors.green005 };
    --colors-green-010: ${ colors.green010 };
    --colors-green-015: ${ colors.green015 };
    --colors-green-020: ${ colors.green020 };
    --colors-green-030: ${ colors.green030 };
    --colors-green-040: ${ colors.green040 };
    --colors-green-050: ${ colors.green050 };
    --colors-green-060: ${ colors.green060 };
    --colors-green-070: ${ colors.green070 };
    --colors-green-080: ${ colors.green080 };
    --colors-green-085: ${ colors.green085 };
    --colors-green-090: ${ colors.green090 };
    --colors-green-095: ${ colors.green095 };
    --colors-green-100: ${ colors.green100 };

    --colors-grey-000: ${ colors.grey000 };
    --colors-grey-005: ${ colors.grey005 };
    --colors-grey-010: ${ colors.grey010 };
    --colors-grey-015: ${ colors.grey015 };
    --colors-grey-020: ${ colors.grey020 };
    --colors-grey-030: ${ colors.grey030 };
    --colors-grey-040: ${ colors.grey040 };
    --colors-grey-050: ${ colors.grey050 };
    --colors-grey-060: ${ colors.grey060 };
    --colors-grey-070: ${ colors.grey070 };
    --colors-grey-080: ${ colors.grey080 };
    --colors-grey-085: ${ colors.grey085 };
    --colors-grey-090: ${ colors.grey090 };
    --colors-grey-095: ${ colors.grey095 };
    --colors-grey-100: ${ colors.grey100 };

    --colors-red-000: ${ colors.red000 };
    --colors-red-005: ${ colors.red005 };
    --colors-red-010: ${ colors.red010 };
    --colors-red-015: ${ colors.red015 };
    --colors-red-020: ${ colors.red020 };
    --colors-red-030: ${ colors.red030 };
    --colors-red-040: ${ colors.red040 };
    --colors-red-050: ${ colors.red050 };
    --colors-red-060: ${ colors.red060 };
    --colors-red-070: ${ colors.red070 };
    --colors-red-080: ${ colors.red080 };
    --colors-red-085: ${ colors.red085 };
    --colors-red-090: ${ colors.red090 };
    --colors-red-095: ${ colors.red095 };
    --colors-red-100: ${ colors.red100 };

    --colors-yellow-000: ${ colors.yellow000 };
    --colors-yellow-005: ${ colors.yellow005 };
    --colors-yellow-010: ${ colors.yellow010 };
    --colors-yellow-015: ${ colors.yellow015 };
    --colors-yellow-020: ${ colors.yellow020 };
    --colors-yellow-030: ${ colors.yellow030 };
    --colors-yellow-040: ${ colors.yellow040 };
    --colors-yellow-050: ${ colors.yellow050 };
    --colors-yellow-060: ${ colors.yellow060 };
    --colors-yellow-070: ${ colors.yellow070 };
    --colors-yellow-080: ${ colors.yellow080 };
    --colors-yellow-085: ${ colors.yellow085 };
    --colors-yellow-090: ${ colors.yellow090 };
    --colors-yellow-095: ${ colors.yellow095 };
    --colors-yellow-100: ${ colors.yellow100 };

    --colors-background: ${ colors.background };
    --colors-foreground: ${ colors.foreground };
  }
`;

export default colors;
