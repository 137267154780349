import React, { useEffect, useState } from "react";

import { Item, ItemIcon } from "@guardian/UI/Dropdown";
import { Icon } from "@guardian/UI/Icon";

import { MENU_ITEM_KEY } from "../../constants";

const ReassignMenuItem = props => {
  return (
    <Item value={{ value: MENU_ITEM_KEY }}>
      <ItemIcon.Start>
        <Icon.MarkChatRead/>
      </ItemIcon.Start>
      Reassign Chat
    </Item>
  );
};

export default ReassignMenuItem;
