import React, { useEffect } from "react"
import _ from "lodash"
import classnames from "classnames"
import styles from "@guardian/Components/ModSOS/components/Video/Twillio/Twilio.module.css"
import { IProps } from "@guardian/Components/ModSOS/components/Video/Twillio/components/UserVideo/types"

export const UserVideo: React.FunctionComponent<IProps> = ({
  videoTrack,
  videoRef,
}) => {
  useEffect(() => {
    let $video = videoRef.current

    if ($video && !_.isEmpty(videoTrack)) {
      videoTrack.attach($video)

      $video.setAttribute("muted", "true")
      $video.play().catch((error: any) => {
        console.error("error playing video", error)
      })
    }

    return () => {
      if ($video && !_.isEmpty(videoTrack)) {
        videoTrack.detach($video)
      }
    }
  }, [videoTrack])

  return <video ref={videoRef} className={classnames(styles.twilioVideo)} />
}
