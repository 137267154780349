import { Modal } from "@material-ui/core"
import React from "react"
import { IProps } from "@guardian/Components/ModSOS/components/ErrorModals/Modal/types"
import { CallTakenModalBody } from "@guardian/Components/ModSOS/components/ErrorModals/CallTakenModalBody"
import { UserInteractionModalBody } from "@guardian/Components/ModSOS/components/ErrorModals/UserInteractionModalBody"

// TODO: refactor and merge into single Modal component

export const CallTakenModal = ({ open, onClose, metadata }: IProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CallTakenModalBody onClose={onClose} metadata={metadata} />
    </Modal>
  )
}

export const PromptUserInteractionModal = ({
  open,
  onClose,
}: IProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <UserInteractionModalBody onClose={onClose} />
    </Modal>
  )
}
