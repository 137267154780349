import React from "react"
import style from "./ErrorMessage.module.css"
import { string } from "prop-types"

const ErrorMessage = ({ message }) => {
  return (
    <div className={style.container}>
      <i className='fas fa-lightbulb'></i>
      {message}
    </div>
  )
}

ErrorMessage.propTypes = {
  message: string,
}

export default ErrorMessage
