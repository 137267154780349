import { TSOSVideoContextActionType } from "@guardian/Components/ModSOS/store/video/types"

export const updateUserMicDisabled = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateUserMicDisabled,
  data,
})

export const updateTwilioOwnerParticipant = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateTwilioOwnerParticipant,
  data,
})

export const updateTwilioDialOutParticipant = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateTwilioDialOutParticipant,
  data,
})

export const addTwilioContact = (data: any) => ({
  type: TSOSVideoContextActionType.AddTwilioContact,
  data,
})

export const removeTwilioContact = (data: any) => ({
  type: TSOSVideoContextActionType.RemoveTwilioContact,
  data,
})

export const updateTwilioRoom = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateTwilioRoom,
  data,
})

export const updateTokboxOwnerStream = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateTokboxOwnerStream,
  data,
})

export const updateTokbox911Stream = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateTokbox911Stream,
  data,
})

export const addTokboxWatcherStream = (data: any) => ({
  type: TSOSVideoContextActionType.AddTokboxWatcherStream,
  data,
})

export const updateUserSpeakerMuted = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateUserSpeakerMuted,
  data,
})

export const updateUserDisabledAgentAudio = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateUserDisabledAgentAudio,
  data,
})

export const updateUserDisabledAgentVideo = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateUserDisabledAgentVideo,
  data,
})

export const updateTokboxSession = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateTokboxSession,
  data,
})

export const updateVideoDeviceId = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateVideoDeviceId,
  data,
})

export const updateVideoProvider = (data: any) => ({
  type: TSOSVideoContextActionType.UpdateVideoProvider,
  data,
})

export const resetVideoContext = (data: any = {}) => ({
  type: TSOSVideoContextActionType.Reset,
  data,
})
