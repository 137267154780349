import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Dialog, Flash } from "@guardian/UI/Alert";
import usePrevious from "@guardian/Hooks/usePrevious";
import { LoggingService } from "@guardian/Services/Logging";

import useSubscribeSOSFeedCount from
  "@guardian/Components/Dashboard/components/NavigationBar/hooks/useSubscribeSOSFeedCount";

const AUDIO = new Audio("/sos-beep.mp3");

export const usePlayProtectAudio = ({ enabled }) => {
  const location = useLocation();

  const [_, sosFeedCount] = useSubscribeSOSFeedCount({ enabled });

  const hasDisplayedInteractionWarning = useRef(false);
  const previousCount = usePrevious<number>(sosFeedCount, 0)!;

  const isActiveSession = () => Boolean(location.pathname.includes("sos/pv2_"));

  const playAudioAlert = () => {
    if (!isActiveSession()) {
      AUDIO.play().catch(error => {
        let flashThisError = false;

        if (error.message.match("the user didn't interact")) {
          if (hasDisplayedInteractionWarning.current === false) {
            Dialog.error(
              "Failed to play audio alert for new Protect messages. You must " +
              "first interact with the webpage (e.g.; clicking anywhere) in " +
              "order for the browser to allow audio to play automatically!",
              {
                title: "Failed to Play Protect Audio Alert!"
              }
            );

            // Only display this dialog once. Dialogs do not disappear until the
            // user closes them, which will count as an interaction.
            hasDisplayedInteractionWarning.current = true;
          }
        } else {
          // Only flash this error if we didn't already show a Dialog error
          // above.
          flashThisError = true;
        }

        LoggingService.logError(
          `Failed to Play Protect Audio Alert!`,
          {
            domain: "NavigationBar",
            method: "usePlayProtectAudio",
            error,
            flash: flashThisError,
            trackedData: {
              error: error.message
            }
          }
        );
      });
    }
  };

  useEffect(() => {
    if (sosFeedCount > previousCount) {
      playAudioAlert();
    }
  }, [sosFeedCount]);
};
