import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";

const VALIDATION_TYPES = {
  ERROR: "error",
  GENERIC: "generic",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning"
};

const Textarea = styled.textarea.attrs(
  props => {
    const {
      active,
      disabled,
      focused,
      hovered,
      validation
    } = props;

    return {
      "aria-disabled": disabled,
      className: classNames("form__textarea", {
        "form__textarea--is-active": active,
        "form__textarea--is-disabled": disabled,
        "form__textarea--is-focused": focused,
        "form__textarea--is-hovered": hovered,

        "form__textarea--validation-error":
          validation == VALIDATION_TYPES.ERROR,
        "form__textarea--validation-generic":
          validation == VALIDATION_TYPES.GENERIC,
        "form__textarea--validation-info":
          validation == VALIDATION_TYPES.INFO,
        "form__textarea--validation-success":
          validation == VALIDATION_TYPES.SUCCESS,
        "form__textarea--validation-warning":
          validation == VALIDATION_TYPES.WARNING,
      })
    };
  }
)`
  background: var(--colors-grey-095);
  border: none;
  box-shadow:
    0px 0px 0px transparent,
    var(--borders-full) var(--colors-grey-080);
  border-radius: var(--radii-large);
  color: var(--colors-white);
  display: block;
  font-size: var(--font-sizes-medium);
  line-height: var(--line-heights-medium);
  min-height: 40px;
  outline: none;
  padding:
    calc((40px - var(--line-heights-medium)) / 2.0)
    var(--space-small)
    calc((40px - var(--line-heights-medium)) / 2.0)
    var(--space-small);
  transition:
    background 125ms cubic-bezier(0.39, 0.575, 0.565, 1),
    box-shadow 125ms cubic-bezier(0.39, 0.575, 0.565, 1),
    color 125ms cubic-bezier(0.39, 0.575, 0.565, 1);
  width: 100%;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--colors-grey-030);
    font-family: var(--fonts-sans-serif);
  }
  &::-ms-input-placeholder {
    color: var(--colors-grey-030);
    font-family: var(--fonts-sans-serif);
  }

  &:active, &.form__textarea--is-active,
  &:hover, &.form__textarea--is-hovered
  {
    box-shadow: var(--borders-full) var(--colors-grey-030);
  }

  &:focus, &.form__textarea--is-focused
  {
    box-shadow:
      var(--borders-outer-highlight-full) var(--colors-grey-070),
      var(--borders-full) var(--colors-grey-030);
  }

  &.form__textarea--validation-error {
    box-shadow:
      0px 0px 0px transparent,
      var(--borders-full) var(--colors-red-040);;

    &:active, &.form__textarea--is-active,
    &:hover, &.form__textarea--is-hovered
    {
      box-shadow:
        0px 0px 0px transparent,
        var(--borders-full) var(--colors-red-030);
    }

    &:focus, &.form__textarea--is-focused
    {
      box-shadow:
        var(--borders-outer-highlight-full) var(--colors-red-040),
        var(--borders-full) var(--colors-red-030);
    }
  }

  &.form__textarea--validation-info {
    box-shadow:
      0px 0px 0px transparent,
      var(--borders-full) var(--colors-blue-040);;

    &:active, &.form__textarea--is-active,
    &:hover, &.form__textarea--is-hoveblue
    {
      box-shadow:
        0px 0px 0px transparent,
        var(--borders-full) var(--colors-blue-030);
    }

    &:focus, &.form__textarea--is-focused
    {
      box-shadow:
        var(--borders-outer-highlight-full) var(--colors-blue-040),
        var(--borders-full) var(--colors-blue-030);
    }
  }

  &.form__textarea--validation-success {
    box-shadow:
      0px 0px 0px transparent,
      var(--borders-full) var(--colors-green-040);;

    &:active, &.form__textarea--is-active,
    &:hover, &.form__textarea--is-hovered
    {
      box-shadow:
        0px 0px 0px transparent,
        var(--borders-full) var(--colors-green-030);
    }

    &:focus, &.form__textarea--is-focused
    {
      box-shadow:
        var(--borders-outer-highlight-full) var(--colors-green-040),
        var(--borders-full) var(--colors-green-030);
    }
  }

  &.form__textarea--validation-warning {
    box-shadow:
      0px 0px 0px transparent,
      var(--borders-full) var(--colors-yellow-040);;

    &:active, &.form__textarea--is-active,
    &:hover, &.form__textarea--is-hovered
    {
      box-shadow:
        0px 0px 0px transparent,
        var(--borders-full) var(--colors-yellow-030);
    }

    &:focus, &.form__textarea--is-focused
    {
      box-shadow:
        var(--borders-outer-highlight-full) var(--colors-yellow-040),
        var(--borders-full) var(--colors-yellow-030);
    }
  }

  // Give disabled styling priority.
  && {
    &.form__textarea--is-disabled  {
      background: var(--colors-grey-090);
      box-shadow:
        0px 0px 0px transparent,
        var(--borders-full) var(--colors-grey-085);
      color: var(--colors-grey-070);
      cursor: default;
    }
  }
`;

Textarea.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool,
  validation: PropTypes.oneOf(
    [
      VALIDATION_TYPES.ERROR,
      VALIDATION_TYPES.GENERIC,
      VALIDATION_TYPES.INFO,
      VALIDATION_TYPES.SUCCESS,
      VALIDATION_TYPES.WARNING
    ]
  )
};

Textarea.defaultProps = {
  active: false,
  disabled: false,
  focused: false,
  hovered: false,
  validation: VALIDATION_TYPES.GENERIC
};

export default Textarea;
