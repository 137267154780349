import React, { FC } from "react"
import { IProps } from "@guardian/UI/Map/components/PointOfInterestMarker/types"
import {
  Container,
  Signal,
  Label,
} from "@guardian/UI/Map/components/IncidentMarker/styled"

export const PointOfInterestMarker: FC<IProps> = ({ address, name }) => {
  const handleOnClick = () =>
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${name}%20${address}`,
      "_blank",
    )

  return (
    <Container onClick={handleOnClick}>
      <Signal />
      <Label>{name}</Label>
    </Container>
  )
}
