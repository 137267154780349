import React from "react";

import StyledAgentMessage from "./views/AgentMessage";

const AgentMessage = props => {
  const {
    message: {
      body: {
        text
      }
    }
  } = props;

  return (
    <StyledAgentMessage>
      { text }
    </StyledAgentMessage>
  );
};

export default React.memo(AgentMessage);
