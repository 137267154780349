import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from "@tanstack/react-query";

import { AgentChatService } from "@guardian/Services/AgentChat";

interface UseGetGroupMessagesParams {
  limit?: number
  before?: number
}

interface UseSendGroupMesssageMutation {
  data: {
    id: string
    groupId: string
    body: { [key: string]: any }
  }
  options: any
}

interface UseEndAssignedChatSessionMutation {
  data: {
    sessionId: string
  }
  options: any
}

const useEndAssignedChatSession = () => {
  return useMutation((mutation: UseEndAssignedChatSessionMutation) => {
    return AgentChatService.endAssignedChatSession(
      mutation.data.sessionId,
      mutation.options,
    )
  })
}

const useGetAssignedChatSessions = (queryOptions: any = {}) => {
  return useQuery(
    ["agentChatSessions"],
    ({ signal }) => {
      return AgentChatService.getAssignedChatSessions({ signal })
    },
    {
      ...queryOptions,
    },
  )
}

const useGetAssignedChatSessionUserInfo = (
  sessionId: string,
  queryOptions: any = {},
) => {
  return useQuery(
    ["agentChatSessionUserInfo", sessionId],
    ({ signal }) => {
      return AgentChatService.getAssignedChatSessionUserInfo(
        sessionId,
        { signal }
      );
    },
    {
      ...queryOptions
    }
  );
};

const useGetAssignedChatSessionUserRecords = (
  sessionId: string,
  queryOptions: any = {}
) => {
  return useQuery(
    ["agentChatSessionUserRecords", sessionId],
    ({ signal }) => {
      return AgentChatService.getAssignedChatSessionUserRecords(
        sessionId,
        { signal }
      );
    },
    {
      ...queryOptions
    }
  );
};

const useGetGroupInfo = (
  groupId: string,
  queryOptions: any = {}
) => {
  return useQuery(
    ["agentChatSessionGroupInfo", groupId],
    ({ signal }) => {
      return AgentChatService.getGroupInfo(
        groupId,
        { signal }
      );
    },
    {
      ...queryOptions
    }
  );
};

const useGetGroupMessages = (
  groupId: string,
  params: UseGetGroupMessagesParams,
  queryOptions: any = {}
) => {
  return useQuery(
    ["agentChatSessionGroupMessages", groupId],
    ({ signal }) => {
      return AgentChatService.getGroupMessages(
        groupId,
        params,
        { signal }
      );
    },
    {
      ...queryOptions
    }
  );
};

const useGetGroupMessagesInfinite = (
  groupId: string,
  limit: number = 50,
  queryOptions: any = {}
) => {
  return useInfiniteQuery(
    ["agentChatSessionGroupMessagesInfinite", groupId],
    ({ signal, pageParam: before }: { signal?: any, pageParam?: number | undefined }) => {
      const params: { [key: string]: any } = { limit: limit };

      if (before != null) {
        params.before = before;
      }

      return AgentChatService.getGroupMessages(
        groupId,
        params,
        { signal }
      );
    },
    {
      // NOTE: For the time being we have decided to disable all cacheing for
      // this query. The reason is that the data we get here may be heavily
      // paginated and may need to include prepended data in the case that an
      // agent is sending outbound messages - handled via another query - or a
      // user is sending inbound messages - handled via sockets. In the case of
      // heavy pagination. This may cause react query to refetch the entire
      // history of content every tab switch, which can be a lot of network
      // overhead. In the case of appended message data, this adds significant
      // work towards appending data manually using `setQueryData` in various
      // places across the app. This seems to call for a more unified service
      // to handle this kind of complicated workflow and keep data fresh and
      // accurate (e.g.; data updates from react query would also need to set
      // new keys in our chat... even more overhead). As such, and to move this
      // along, we simply dont cache data for this. Any load of group chat
      // messages will fetch the latest and leave it to the component to request
      // more, possibly again.
      //
      // One caveat here is if we want to make optimistic updates such as when
      // an agent sends a message - appending it using setQueryData and then
      // waiting for the data to come back that matches that message's ID to do
      // things like mark it as "delivered". In this case, we should consider
      // just breaking the logic out for getting messages, sending messages, and
      // listening for new messages into some kind of unified service.
      cacheTime: 0,
      keepPreviousData: false,
      getNextPageParam: (
        lastPage: any[],
        pages
      ) => {
        const lastRecord = lastPage && lastPage[lastPage.length - 1];

        if (lastRecord != null) {
          // Convert ISO date to Unix timestamp, in seconds.
          const timestamp = Math.floor(
            new Date(lastRecord.createdAt).getTime() / 1000
          );

          return timestamp;
        }
      },
      ...queryOptions
    }
  );
};

const useGetMacros = (
  queryOptions: any = {}
) => {
  return useQuery(
    ["agentChatMacros"],
    ({ signal }) => {
      return AgentChatService.getMacros({ signal });
    },
    {
      ...queryOptions
    }
  );
};

const useSendGroupMessage = () => {
  return useMutation((mutation: UseSendGroupMesssageMutation) => {
    return AgentChatService.sendGroupMessage(
      mutation.data,
      mutation.options
    );
  });
};

const useGetSafetyFeed = (sessionId: string) => {
  return useQuery(
    ["safetyFeed"],
    () => AgentChatService.getSafetyFeed(sessionId),
  );
};

const AgentChat = {
  useEndAssignedChatSession,
  useGetAssignedChatSessions,
  useGetAssignedChatSessionUserInfo,
  useGetAssignedChatSessionUserRecords,
  useGetGroupMessages,
  useGetGroupMessagesInfinite,
  useGetGroupInfo,
  useGetMacros,
  useSendGroupMessage,
  useGetSafetyFeed,
};

export default AgentChat;
