import React, { useEffect, useState } from "react"
import _ from "lodash"
import styles from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.module.css"
import { sendTwilioMessage } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideo.helper"
import { SOSVideoSubscriber } from "@guardian/Components/ModSOS/components/Video/SOSVideo/SOSVideoSubscriber"
import { patchVideoControl } from "@guardian/API/Optimus/resources/legacy/resources/Protect"
import { IProps } from "@guardian/Components/ModSOS/components/Video/SOSVideoMain/types"
import {
  updateUserDisabledAgentAudio,
  updateUserSpeakerMuted,
} from "@guardian/Components/ModSOS/store/video/actions/video"
import { useSOSVideoContext } from "@guardian/Components/ModSOS/store/video/VideoContext"
import { useSOSDashboardContext } from "@guardian/Components/ModSOS/store/dashboard/DashboardContext"
import { VideoStats } from "@guardian/Components/ModSOS/components/Video/VideoStats/VideoStats"
import { handleAudiError } from '@guardian/Components/ModSOS/store/helpers';

export const SOSVideoMain: React.FunctionComponent<IProps> = ({
  userDisconnected,
  ownerData,
}) => {
  const { state, dispatch } = useSOSVideoContext()
  const { state: dashboardState } = useSOSDashboardContext()
  const [userBackgrounded, setUserBackgrounded] = useState(false)
  const [userNetworkQuality, setUserNetworkQuality] = useState(0);

  useEffect(() => {
    const latestRecord = _.get(
      dashboardState,
      "sessionRecords.data.records.0",
      undefined,
    )
    if (latestRecord === undefined) {
      setUserBackgrounded(false)
    }

    let newState = _.get(latestRecord, "presence") === "background"
    if (newState !== userBackgrounded) {
      setUserBackgrounded(newState)
    }
  }, [dashboardState.sessionRecords])

  useEffect(() => {
    if (state == null || state.twilioOwnerParticipant == null) { return }

    const participant = state.twilioOwnerParticipant

    // Note:
    // For future referencee, the `networkQualityLevelChanged` event listener,
    // "updateNetworkQualityStats" in our case, may also receive a second
    // argument, "networkQualityStats". This will only be non-null if Network
    // Quality verbosity is 2 (moderate) or greater, and will otherwise be an
    // object holding network quality stats more fine grained and specific to
    // packets sent and received for individual media data layers, such as
    // video data or audio data. This value will also exist on the participant
    // and can be used in the initial call we make to
    // "updateNetworkQualityStats". If we decide this would be useful, look to
    // this argument.
    const updateNetworkQualityStats = (networkQualityLevel: number | null) => {
      setUserNetworkQuality(networkQualityLevel || 0)
    }

    // Update the initial Network Quality Level.
    updateNetworkQualityStats(participant.networkQualityLevel)

    // Listen for changes to Network Quality Level.
    participant.on("networkQualityLevelChanged", updateNetworkQualityStats)
  }, [state.twilioOwnerParticipant]);

  const muteUserSpeaker: (
    isSpeakerMuted: boolean,
  ) => void = async isSpeakerMuted => {
    const message = {
      type: "muteSpeaker",
      data: isSpeakerMuted ? "disabled" : "enabled",
    }

    if (state.videoProvider === "twilio") {
      await sendTwilioMessage(state.twilioRoom, message)
    } else if (state.videoProvider === "tokbox") {
      state.tokboxSession.session.signal(message)
    }

    patchVideoControl(state.activeSessionId, {
      userSpeakerOn: !isSpeakerMuted,
    }).toPromise().catch(handleAudiError('speaker', !isSpeakerMuted))

    dispatch(updateUserSpeakerMuted(isSpeakerMuted))
    dispatch(updateUserDisabledAgentAudio(isSpeakerMuted))
  }

  if (
    _.isEmpty(state.twilioOwnerParticipant) &&
    _.isEmpty(state.tokboxOwnerStream)
  ) {
    return (
      <div className={styles.owner}>
        <span className={styles.videoState}>
          User not connected to audio/video (likely force quit)
        </span>
      </div>
    )
  }

  return (
    <div className={styles.owner}>
      <SOSVideoSubscriber
        tokboxSession={state.tokboxSession}
        tokboxStream={state.tokboxOwnerStream}
        twilioParticipant={state.twilioOwnerParticipant}
        userMicDisabled={state.userMicDisabled}
        passthroughProps={{
          isUserDisconnected: userDisconnected,
          muteUserSpeaker,
          isUserSpeakerMuted: state.userSpeakerMuted,
          isUserBackgrounded: userBackgrounded,
          ownerData,
        }}
      />
      <VideoStats networkQuality={userNetworkQuality}/>
    </div>
  )
}
