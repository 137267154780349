import React from "react"

import { ErrorContainer, ErrorMessage, ErrorStack } from "./styles"

type ErrorViewProps = {
  error: Error
}

const ErrorView: React.FC<ErrorViewProps> = ({ error }) => (
  <ErrorContainer>
    <ErrorMessage>
      {error.name}: {error.message}
    </ErrorMessage>
    {!!error.stack && <ErrorStack>{error.stack}</ErrorStack>}
  </ErrorContainer>
)

export default React.memo(ErrorView)
