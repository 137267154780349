import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const MediumParagraphStyles = css`
  font-size: var(--font-sizes-medium);
  line-height: var(--line-heights-medium);

  & + & {
    margin-top: var(--line-heights-medium);
  }

  &.paragraph--with-measure {
    max-width: var(--measures-medium);
    width: 100%;
  }
`;

const MediumParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__medium-paragraph")
    };
  }
)`
  ${ MediumParagraphStyles }
`;

MediumParagraph.propTypes = {
  ...Paragraph.propTypes
};

MediumParagraph.defaultProps = {};

export default MediumParagraph;
