import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react"
import styles from "@guardian/Components/ModSOS/components/Chat/Chat/Chat.module.css"
import { webSockets } from "@guardian/Sockets"
import SOSSessionContext from "@guardian/Components/Contexts/SOSSessionContext"
import { postWMBMessage, getWMBPayload } from "@guardian/API/Optimus/resources/legacy/resources/WMB"
import { get } from "lodash"
import { timeString } from "@guardian/Utils/util"
import {
  MessageType,
  replaceIdsWithNames,
  Message,
  PLACEHOLDER_TEXT,
  DISABLED_TEXT,
  getMappedUsers,
  getFormattedMessages,
} from "@guardian/Components/ModSOS/components/Chat/Chat/Chat.helper"
import { useCountdown } from "@guardian/Components/ModSOS/hooks/useCountdown"
import { Message as MessageComponent } from "@guardian/Components/ModSOS/components/Chat/Message"

const socket = webSockets

interface IProps {
  activeSessionId: string
}

export const Chat: React.FunctionComponent<IProps> = ({ activeSessionId }) => {
  const messagesDiv = useRef<HTMLDivElement>(null)
  const [currentText, setCurrentText] = useState("")
  const userDisconnected = useContext(SOSSessionContext)
  const [messages, setMessages] = useState<Array<Message>>([])
  const [userMap, setUserMap] = useState<Record<string, string>>({})
  const {
    isOn: isTyping,
    startCountDown: turnOnIsTyping,
    clearCountDown: turnOffIsTyping,
  } = useCountdown()
  const [userId, setUserId] = useState<string>()

  useEffect(() => {
    if (userDisconnected) return

    getWMBPayload(activeSessionId).then(({ data }) => {
      const users = get(data, "users", [])
      const userId = users.find(
        (member: any) => !member?.userId?.startsWith("agent_id"),
      )
      setUserId(userId?.userId)

      setUserMap(getMappedUsers(users))

      const dataMessages = get(data, "messages", [])
      setMessages(getFormattedMessages(dataMessages))
    })

    const socketSub = {
      method: "subscribeSOSMessage",
      value: {
        sessionId: activeSessionId,
      },
    }
    socket.sub(socketSub)
    socket.onEvent("sosMessageEvent", handleSOSMessageEvent)
    return () => {
      socket.removeEvent("sosMessageEvent", handleSOSMessageEvent)
      const unsubReq = {
        method: "unsubscribeSOSMessage",
        value: {
          sessionId: activeSessionId,
        },
      }
      socket.removeSub(socketSub, unsubReq)
    }
  }, [activeSessionId, userDisconnected])

  useEffect(() => {
    if (messagesDiv.current) {
      messagesDiv.current.scrollTop = messagesDiv.current.scrollHeight
    }
  }, [messages, isTyping])

  const onChangeText: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void = useCallback(({ target }) => {
    setCurrentText(target.value)
  }, [])

  const onSubmit = async (error: React.FormEvent<HTMLFormElement>) => {
    error.preventDefault()
    if (currentText === "") {
      return
    }

    await postWMBMessage(currentText, activeSessionId).toPromise()
    setCurrentText("")
  }

  const onAuthorUndefined = async () => {
    const { data } = await getWMBPayload(activeSessionId)
    const users = get(data, "users", [])
    setUserMap(getMappedUsers(users))
  }

  const handleSOSMessageEvent: (e: any) => void = ({ payload }) => {
    const { id, message, timestamp, type, userId } = payload.value

    if (type === "isTyping") {
      return turnOnIsTyping()
    }

    setMessages(prev => {
      turnOffIsTyping()

      const exists = prev.findIndex(item => item.id === id) !== -1

      if (!exists) {
        prev.push({
          id,
          message,
          timestamp,
          type,
          userId,
        })
      }

      return [...prev]
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerBody} ref={messagesDiv}>
        {messages.map((message: Message) => {
          return (
            <MessageComponent
              key={message.id}
              message={replaceIdsWithNames(message.message, userMap)}
              timestamp={timeString(message.timestamp)}
              author={message.userId ? userMap[message.userId] : ""}
              onAuthorUndefined={onAuthorUndefined}
              type={message.type as MessageType}
            />
          )
        })}
        {isTyping && (
          <div className={styles.typingIndicator}>
            {userId ? userMap[userId] : "User"} is typing
            <span className={styles.one}>.</span>
            <span className={styles.two}>.</span>
            <span className={styles.three}>.</span>
          </div>
        )}
      </div>
      <form onSubmit={onSubmit}>
        <input
          type='text'
          className={styles.message}
          placeholder={PLACEHOLDER_TEXT}
          value={userDisconnected ? DISABLED_TEXT : currentText}
          onChange={onChangeText}
          disabled={userDisconnected}
        />
      </form>
    </div>
  )
}
