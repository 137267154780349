import classNames from "classnames";
import styled from "styled-components";

const Image = styled.img.attrs(
  props => {
    const {
      className,
    } = props;

    return {
      className: classNames(
        className,
        "avatar__image"
      )
    }
  }
)`
  border-radius: var(--radii-circle);
  flex: 1;
  height: 100%;
  vertical-align: bottom;
  width: 100%;
`;

export default Image;
