import { memo } from "react"

import { useGlobalState } from "@guardian/State"

import Tooltip from "./Tooltip"

const ClipTooltip = () => {
  const { clip, elem, position, status } = useGlobalState(
    state => state.global.clipTooltip || {},
  )

  if (!clip) {
    return null
  }

  const { top, left } = elem.getBoundingClientRect()
  return (
    <div style={{ height: 0, position: "fixed", top, left, zIndex: 300 }}>
      <Tooltip {...clip} position={position} status={status} />
    </div>
  )
}

export default memo(ClipTooltip)
