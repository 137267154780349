import React, { PureComponent } from "react"
import { bool, object, shape, string } from "prop-types"
import { useLocation } from "react-router-dom"

import config from "@guardian/Config"
import { AppSystem } from "@guardian/Core"
import { eventHub, useGlobalState } from "@guardian/State"
import { objPick } from "@guardian/Utils/obj-pick"

import LandingPage from "@guardian/Components/LandingPage/LandingPage"
import Flash from "@guardian/Components/Flash"
import Banner from "@guardian/Components/Banner"
import MapContext from "@guardian/Components/Contexts/MapContext"
import ErrorModal from "@guardian/Components/Modals/ErrorModal"

import "./styles.scss"
import NavigationBar from "./components/NavigationBar"
import LoadingScreen from "./LoadingScreen"
import LogoutScreen from "./LogoutScreen"
import ContentPane from "./ContentPane"
import styles from "./Dashboard.module.css"

class Dashboard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const { pathname } = this.props
    if (!pathname.startsWith("/device/")) {
      try {
        await eventHub.emit("getUser")
        this.setState({ loading: false })
      } catch (err) {}
    }
    this.setState({ loading: false })
  }

  closeErrorModal = () => {
    eventHub.emit("close error modal")
  }

  render() {
    const { loading } = this.state
    const { mapInit, username, errorBanner, error, pathname } = this.props

    let body
    if (loading) {
      body = <LoadingScreen />
    } else if (!username && ["/", "/metapod"].includes(pathname)) {
      body = <LandingPage />
    } else if (pathname === '/logout') {
      body = <LogoutScreen />
    } else {
      let banner
      let stageBanner

      if (!config.production) {
          stageBanner = (
            <Banner
              title='You are on staging!'
              style={{ background: "indigo" }}
            />
          )
      } else if (config.secure) {
          stageBanner = (
            <Banner
              title='Secure environment'
              style={{background: "blue"}}
            />
          )
      }

      if (errorBanner) {
        banner = (
          <Banner title={errorBanner.message} style={{ background: "red" }} />
        )
      }

      body = (
        <AppSystem>
          <div className={styles.dashboard}>
            {stageBanner}
            {banner}
            <Flash />
            <NavigationBar />
            <ContentPane />
            <ErrorModal error={error} onClose={this.closeErrorModal} />
          </div>
        </AppSystem>
      )
    }
    return <MapContext.Provider value={mapInit}>{body}</MapContext.Provider>
  }
}

Dashboard.propTypes = {
  pathname: string,
  mapInit: bool.isRequired,
  username: string,
  errorBanner: object,
  error: object,
}

const DashboardHOC = () => {
  const { pathname } = useLocation()
  const { mapInit, username, errorBanner, error } =
    useGlobalState(state => {
      const selectedGlobal = objPick(state.global, [
        "mapInit",
        "errorBanner",
        "error",
      ])
      const username = state.global.user?.username
      return { ...selectedGlobal, username }
    })

  return (
    <Dashboard
      pathname={pathname}
      mapInit={mapInit}
      username={username}
      errorBanner={errorBanner}
      error={error}
    />
  )
}

export default DashboardHOC
