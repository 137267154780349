import { get, sortBy } from "lodash"
import dayjs from "dayjs"

export enum MessageType {
  System = "system",
  Chat = "chat",
}

export interface Message {
  id: string
  userId?: string
  message: string
  timestamp: string
  type: string
}

const CURLY_BRACE_REGEXP = /\{\{[^}]*}}/g

export const PLACEHOLDER_TEXT = "Type a message"
export const DISABLED_TEXT = "User ended session"
const ANALYST = "analyst"
const AGENT = "Agent"
const SYSTEM = "System"

export const replaceIdsWithNames: (
  message: string,
  userMap: Record<string, string>,
) => string = (message, userMap) => {
  const parsedMessage = message.replace(CURLY_BRACE_REGEXP, match => {
    const noBraces = match.replace(/{/g, "").replace(/}/g, "")
    return userMap[noBraces]
  })
  return parsedMessage
}

export const getMappedUsers: (
  users: Array<any>,
) => Record<string, string> = users => {
  // could use reduce() here, but typescript makes it difficult
  const mappedUsers: Record<string, string> = {}
  users.forEach((user: any) => {
    if (get(user, "type") === ANALYST) {
      mappedUsers[user.id] = AGENT
      return
    }

    const firstName = get(user, "name", "Unknown")
    // could be null
    const lastName = get(user, "lastName") || ""
    mappedUsers[user.id] = firstName + lastName
  })
  return mappedUsers
}

export const getFormattedMessages: (
  messages: Array<any>,
) => Array<Message> = messages => {
  const formattedMessages: Array<Message> = []
  messages.forEach((msg: any) => {
    formattedMessages.push({
      id: msg.id,
      userId: msg.userId,
      message: msg.message,
      timestamp: msg.timestamp,
      type: msg.type,
    })
  })
  return formattedMessages
}

export const getMappedProtectUsers: (
  users: Array<any>,
) => Record<string, string> = users => {
  // could use reduce() here, but typescript makes it difficult
  const mappedUsers: Record<string, string> = { system: SYSTEM }
  users.forEach((user: any) => {
    const firstName = get(user, "shortName", "Unknown")
    // could be null
    const lastName = get(user, "lastName") || ""
    mappedUsers[user.userId] = `${firstName} ${lastName}`
  })
  return mappedUsers
}

const groupDMNameSubstitution: (
  message: string,
  userIDs: Array<string>,
) => string = (message, userIDs) => {
  let re = /%.*_(.*)%/
  let result = message.match(re)
  if (result) {
    return message.replace(re, `{{${userIDs[parseInt(result[1]) - 1]}}}`)
  }
  return message
}

export const getFormattedProtectMessages: (
  messages: Array<any>,
) => Array<Message> = messages => {
  const mapped = messages.map(msg => ({
    id: msg.id,
    userId: msg.authorId,
    message: groupDMNameSubstitution(msg.body.text, msg.body.userIds),
    timestamp: msg.createdAt,
    type: "chat",
  }))
  return sortBy<Message>(mapped, message => dayjs(message.timestamp).valueOf())
}
