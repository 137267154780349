const copyToClipboard = value => {
  const input = document.createElement("input");
  input.value = value;

  input.style.top = "0";
  input.style.left = "0";
  input.style.position = "fixed";

  document.body.appendChild(input);
  input.focus();
  input.select();

  let copied;

  try {
    copied = document.execCommand("copy");
  } catch {
    copied = false;
  }

  document.body.removeChild(input);

  return copied;
};

export default copyToClipboard;
