import styled from "styled-components";

const ActionsPanel = styled.div`
  background: var(--colors-grey-090);
  color: var(--colors-white);
  flex: 0 0 268px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--space-small);
`;

export default ActionsPanel;
