import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { IconURL } from "@guardian/UI/Icon";
import { svg } from "@guardian/UI/Utils";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledClose = styled.button.attrs(
  props => {
    const {
      active,
      focused,
      hovered
    } = props;

    return {
      className: classNames("alert__close-button", {
          "alert__close-button--is-active": active,
          "alert__close-button--is-focused": focused,
          "alert__close-button--is-hovered": hovered
        })
    };
  }
)`
  ${ buttonResets }

  ${ ({ theme: { colors } }) => `
    background-image: url(${
      svg.replaceColors(IconURL.Close, { fill: colors.white })
    });
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: var(--line-heights-x-small);
    border-radius: 50%;
    box-sizing: content-box;
    height: var(--line-heights-x-small);
    padding: var(--space-x-small);
    position: absolute;
    right: 0;
    top: 0;
    transition:
      background-color 100ms cubic-bezier(0.39, 0.575, 0.565, 1),
      background-image 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
    width: var(--line-heights-medium);

    &:active, &.modal__close-button--is-active,
    &:hover, &.modal__close-button--is-hovered
    {
      background-color: var(--colors-transparent);
      background-image: url(${
        svg.replaceColors(IconURL.Close, { fill: colors.white })
      });
    }

    &.modal__close-button--is-disabled {
      background-color: var(--colors-transparent);
      background-image: url(${
        svg.replaceColors(IconURL.Close, { fill: colors.grey070 })
      });

      &:hover, &.modal__close-button--is-hovered {
        background-color: var(--colors-transparent);
        background-image: url(${
          svg.replaceColors(IconURL.Close, { fill: colors.grey070 })
        });
        cursor: default;
      }
    }

    // :focus managed by KeyboardFocusContainer
    &.modal__close-button--is-focused
    {
      box-shadow: var(--borders-full) var(--colors-grey-070);
      background-image: url(${
        svg.replaceColors(IconURL.Close, { fill: colors.white })
      });
    }
  `}
`;

const Close = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledClose
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

Close.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

Close.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

export default Close;
