import classNames from "classnames";
import styled from "styled-components";

const UserName = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "sos-dms__user-info-panel__base-user-info__user-name"
    )
  };
})`
`;

UserName.defaultProps = {};

export default UserName;
