import React from "react"
import styles from "@guardian/Components/ModSOS/components/Video/Twillio/Twilio.module.css"
import { IPropsSubscriber } from "@guardian/Components/ModSOS/components/Video/Twillio/types"
import { UserVideo } from "@guardian/Components/ModSOS/components/Video/Twillio/components/UserVideo"
import { UserAudio } from "@guardian/Components/ModSOS/components/Video/Twillio/components/UserAudio"
import { useHandleTwilioSubscriptions } from "@guardian/Components/ModSOS/components/Video/Twillio/hooks/useHandleTwilioSubscriptions"
import { VideoBannerMessage } from "./components/VideoBannerMessage"
import { VideoStateMessage } from "./components/VideoStateMessage"
import { VideoControls } from "./components/VideoControls"

export const TwilioSubscriber: React.FunctionComponent<IPropsSubscriber> = ({
  participant,
  isUserDisconnected,
  isUserMicrophoneDisabled,
  isUserSpeakerMuted,
  isUserBackgrounded,
  muteUserSpeaker,
  muteUserMicrophone,
  callToNumber,
  isUserMicrophoneEnabled,
  isUserCameraEnabled,
}) => {
  const {
    audioRef,
    videoRef,
    videoTrack,
    videoDisabled,
  } = useHandleTwilioSubscriptions({
    participant,
    isUserDisconnected,
  })

  if (isUserDisconnected) {
    return (
      <div className={styles.videoStateContainer}>
        <VideoStateMessage>
          <span>User hung up</span>
        </VideoStateMessage>
      </div>
    )
  }

  const createDevicePermissionsMessage = () => {
    if (
      !isUserMicrophoneEnabled &&
      isUserCameraEnabled &&
      !callToNumber &&
      !videoDisabled
    ) {
      return "Microphone permission off"
    }
  }

  const createVideoStateMessage = () => {
    const messages = []
    if (callToNumber) {
      messages.push(callToNumber)
    } else {
      if (isUserBackgrounded) {
        messages.push("User backgrounded")
      }

      if (videoDisabled) {
        messages.push("Video is off")
      }

      if (!videoDisabled && !isUserCameraEnabled) {
        messages.push("Camera Permission is off")
      }

      if (!videoDisabled && !isUserMicrophoneEnabled && !isUserCameraEnabled) {
        messages.push("Microphone Permission is off")
      }
    }

    return messages.map(line => <div>{line}</div>)
  }

  return (
    <div className={styles.userContainer}>
      <VideoControls
        muteUserSpeaker={muteUserSpeaker}
        isUserSpeakerMuted={isUserSpeakerMuted}
        muteUserMicrophone={muteUserMicrophone}
        isUserMicrophoneDisabled={isUserMicrophoneDisabled}
        participant={participant}
      />
      {Boolean(createDevicePermissionsMessage()) && (
        <VideoBannerMessage>
          {createDevicePermissionsMessage()}
        </VideoBannerMessage>
      )}
      {Boolean(createVideoStateMessage()) && (
        <VideoStateMessage>{createVideoStateMessage()}</VideoStateMessage>
      )}
      {!videoDisabled && !isUserBackgrounded && (
        <UserVideo videoRef={videoRef} videoTrack={videoTrack!} />
      )}
      {<UserAudio audioRef={audioRef} />}
    </div>
  )
}
